import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";
export const SelectIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Select</title>
    <path
      d="M5.09175717,13.1992192 C6.5953125,11.6611786 8.68856499,11.5404804 10.5266269,
  13.1992192 L24.0034485,26.1208289 L37.4802701,13.1992192 C39.3183321,11.5404804 41.4150331,
  11.6611786 42.9082428,13.1992192 C44.4117982,14.7338112 44.3152396,17.3270993 42.9082428,
  18.7685812 C41.5081431,20.2100632 26.7174349,34.2938246 26.7174349,34.2938246 C25.9691057,
  35.0628448 24.9862771,35.4490792 24.0034485,35.4490792 C23.0206199,35.4490792 22.0377913,
  35.0628448 21.2825651,34.2938246 C21.2825651,34.2938246 6.4987539,20.2100632 5.09175717,
  18.7685812 C3.68476044,17.3270993 3.58820184,14.7338112 5.09175717,13.1992192 Z"
    ></path>
  </IconBase>
);
