import { APIFetch } from "./APIFetch";
import { IGetNotificationSettingsResponse } from "online-services-types";
import { initializeApp, FirebaseApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read http://bit.ly/CRA-PWA

let firebaseApp: FirebaseApp;

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
export const supportsServiceWorker = (): boolean => {
  return process.env.NODE_ENV === "production" && "serviceWorker" in navigator && "PushManager" in window;
};

interface IConfig {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
  onMessage?: (payload: any) => void;
}

interface IDeviceToken {
  deviceToken: string;
  userAgent: string;
  id?: string;
}

export async function register(config?: IConfig) {
  if (supportsServiceWorker()) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    let swUrl = `${process.env.PUBLIC_URL}firebase-messaging-sw.js`;

    const configuration = await getNotificationCredentials();

    if (configuration) {
      const { settings } = configuration;

      if (settings) {
        swUrl += `?apiKey=${settings.apiKey}&appId=${settings.appId}&projectId=${settings.projectId}&messagingSenderId=${settings.messagingSenderId}&measurementId=${settings.measurementId}`;
      }

      const firebaseVapidKey = configuration.vapidKey;

      await initPushNotification(configuration);

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config, firebaseVapidKey);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          // tslint:disable-next-line: no-console
          console.debug(
            "This web app is being served cache-first by a service " +
              "worker. To learn more, visit http://bit.ly/CRA-PWA"
          );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config, firebaseVapidKey);
      }
    }
  }
}

function registerValidSW(swUrl: string, config?: IConfig, vapidKey?: string) {
  navigator.serviceWorker
    .register(swUrl)
    .then(async (registration) => {
      const messaging = getMessaging(firebaseApp);

      await getToken(messaging, {
        vapidKey,
        serviceWorkerRegistration: registration,
      });

      updateFirebaseToken();

      if (config?.onMessage) {
        onMessage(messaging, (payload: any) => {
          config.onMessage?.(payload);
        });
      }

      window.addEventListener("fetch", (event: any) => {
        event.respondWith(fetch(event.request));
      });

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      // tslint:disable-next-line: no-console
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: IConfig, firebaseVapidKey?: string) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config, firebaseVapidKey);
      }
    })
    .catch(() => {
      // tslint:disable-next-line: no-console
      console.log("No internet connection found. App is running in offline mode.");
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}

export const askPermission = async () => {
  const permission = await Notification.requestPermission();
  return permission === "granted";
};

export async function initPushNotification({ settings, status }: IGetNotificationSettingsResponse) {
  if (supportsServiceWorker()) {
    try {
      if (status === "ok" && settings) {
        firebaseApp = initializeApp({
          ...settings,
        });
      }
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log("An error occurred while retrieving token. ", err);
    }
  }
}
/*
  Updates Firebase device token id.
*/
export const updateFirebaseToken = async () => {
  if (Notification.permission === "granted") {
    try {
      const currentToken = await getCurrentToken();
      const deviceTokens = await new APIFetch<IDeviceToken[]>("service/devicetokens").get();

      // If the current device token is not already registered then register it to api.
      if (deviceTokens && !deviceTokens.find((element) => element.deviceToken === currentToken)) {
        await new APIFetch<IDeviceToken>("service/devicetokens").post({
          deviceToken: currentToken,
          userAgent: navigator.userAgent,
        });
      }
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log("An error occurred while retrieving token. ", err);
    }
  }
};

/*
  Removes current instances notification device token from backend system.
*/
export const revokeFirebaseToken = async () => {
  try {
    const currentToken = await getCurrentToken();
    const deviceTokens = await new APIFetch<IDeviceToken[]>("service/devicetokens").get();

    // Find if currentToken is registered and among deviceTokens.
    const thisToken = deviceTokens.find((element) => element.deviceToken === currentToken);

    if (deviceTokens && thisToken && currentToken) {
      await new APIFetch<IDeviceToken>("service/devicetokens").delete(currentToken);
    }
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.log("An error occurred while retrieving token. ", err);
  }
};

const getNotificationCredentials = async () => {
  try {
    const { settings, vapidKey, status } = await new APIFetch<IGetNotificationSettingsResponse>(
      "notification/notification-settings"
    ).get();
    return { settings, vapidKey, status };
  } catch {
    return null;
  }
};

const getCurrentToken = async (): Promise<string> => {
  const messaging = getMessaging(firebaseApp);
  const credentials = await getNotificationCredentials();
  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  const currentToken = await getToken(messaging, { vapidKey: credentials?.vapidKey });

  return currentToken;
};
