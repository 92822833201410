import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { H3, H4 } from "src/design-system/Tokens/typography";
import { getFileTransferStatus, startFileTransfer } from "src/redux/actions";
import { redirectBrowserToURL } from "src/routes";
import { displayError } from "src/util/error";
import { translateString } from "src/util/localization";
import styled from "styled-components";
import { getArticleAndFileByEquipment } from "./getArticle";
import { logDocumentView, logCMReportView } from "src/util/viewlog";

interface IRouteParams {
  fileId: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface IDownloadViewComponentState {
  status: string;
}

export type IDownloadViewComponentProps = RouteComponentProps<IRouteParams>;

export class DownloadViewComponent extends React.Component<IDownloadViewComponentProps, IDownloadViewComponentState> {
  public state = {
    status: "busy",
  };

  private readonly pollingIntervalInMillis = 1000;
  private dataUrl: string;

  public componentDidMount() {
    this.download();
  }

  public render() {
    const params = this.getParams();
    const lengthyDownloadInfo =
      params.requestType === "MergedPDF" ? (
        <div>
          <H4>
            <LocalizedString id="download.lengthyDownload" />
          </H4>
        </div>
      ) : null;
    return (
      <Wrapper>
        {this.state.status === "busy" && (
          <div>
            <H3>
              <LocalizedString id="download.preparingDownload" />
            </H3>
            <LoadingSpinner />
          </div>
        )}
        {this.state.status === "ready" && (
          <div>
            <H3>
              <LocalizedString id="download.downloadReady" />
            </H3>
          </div>
        )}
        {this.state.status === "error" && (
          <div>
            <H3>
              <LocalizedString id="download.error" />
            </H3>
          </div>
        )}
        {lengthyDownloadInfo}
      </Wrapper>
    );
  }

  private readonly getParams = () => {
    const params = new URLSearchParams(this.props.location.search);
    return {
      fileIds: params.get("fileIds") || undefined,
      articleId: params.get("articleId") || undefined,
      articleName: params.get("articleName") || undefined,
      searchText: params.get("searchText") || undefined,
      requestId: params.get("requestId") || undefined,
      requestType: params.get("requestType") || undefined,
      equipmentId: params.get("equipmentId") || undefined,
      cmReportId: params.get("cmReportId") || undefined,
      courseId: params.get("courseId") || undefined,
      energyDocumentId: params.get("energyDocumentId") || undefined,
      chapter: params.get("chapter") || undefined,
      anchor: params.get("anchor") || undefined,
      openSearch: params.get("openSearch") || undefined,
      delimiter: params.get("delimiter") || undefined,
      from: params.get("from") || undefined,
      to: params.get("to") || undefined,
      exportAll: params.get("exportAll") || undefined,
    };
  };

  private readonly download = async () => {
    const params = this.getParams();
    let fileId = null;
    let articleId = params.articleId;

    if (this.props.match.params.fileId) {
      fileId = this.props.match.params.fileId;
    } else {
      const article = await getArticleAndFileByEquipment(params.equipmentId || "", params.chapter || "");

      if (!article) {
        displayError(translateString("download.equipmentNotFound"));
        return;
      }

      articleId = article.articleId;
      fileId = article.fileId;
    }

    if (articleId) {
      logDocumentView(articleId);
    } else if (params.cmReportId) {
      logCMReportView(params.cmReportId);
    }

    const res = await startFileTransfer({ ...params, fileId, articleId });

    if (res && res.url) {
      this.dataUrl = res.url;
      this.setPollTimeout(res.id, params.anchor);
    } else {
      displayError(translateString("download.error"));
    }
  };

  private readonly pollDocumentStatus = async (transferId: string, anchor?: string) => {
    const status = await getFileTransferStatus(transferId);

    switch (status) {
      case "success":
        this.setState({
          status: "ready",
        });
        redirectBrowserToURL(`${this.dataUrl}${anchor ? `#${anchor}` : ""}`);
        break;
      case "error":
        this.setState({
          status: "error",
        });
        displayError(translateString("download.error"));
        break;
      case "busy":
      default:
        // Try again
        this.setPollTimeout(transferId, anchor);
        break;
    }
  };

  private readonly setPollTimeout = (transferId: string, anchor?: string) => {
    window.setTimeout(() => this.pollDocumentStatus(transferId, anchor), this.pollingIntervalInMillis);
  };
}

export default DownloadViewComponent;
