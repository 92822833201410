import { ArrowLeftIcon, ArrowRightIcon, IconSize } from "src/icons";
import { Button, ButtonStyle } from "src/design-system/Button";
import {
  ISFOCData,
  ISFOCQuarter,
  Status,
  StatusCell,
  StatusTable,
  TUnderlinedBody,
  statusColors,
} from "src/util/sfoc/sfocCharts";
import React, { useCallback, useEffect, useState } from "react";

import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";
import { FlexContainer } from "src/design-system/Container";
import { IEquipment } from "online-services-types";
import { LocalizedString } from "src/components/Localization";
import { Reason } from "./MeasurementStatusChart";
import styled from "styled-components";
import { translateString } from "src/util/localization";

const getPercentage = (quarters: ISFOCQuarter[], totalQuarterCount: number) => {
  return (quarters?.reduce((prev, q) => prev + (q.testValidation === "Approved" ? 1 : 0), 0) * 100) / totalQuarterCount;
};

const Name = styled.td`
  width: 4em;
`;

const THead = styled.thead`
  tr:first-child td {
    border-top: none;
    border-bottom: none;
  }

  tr:first-child td:first-child {
    border-bottom: none;
  }

  td:last-child {
    border-right: none;
  }
`;
const TFoot = styled.tfoot`
  td {
    border: none;
  }
`;

const EquipmentRow = (props: {
  name: string;
  equipment: IEquipment;
  data: ISFOCData[];
  quarters: string[];
  totalQuarters: number;
}) => {
  const { data } = props;
  const [visibleTooltip, setVisibleTooltip] = useState<string>();

  return (
    <>
      <tr>
        <Name>{props.name}</Name>
        <GridCell>
          {Math.floor(
            getPercentage(
              data
                .filter((d) => d.equipmentId === props.equipment.id)
                .reduce((prev, curr) => prev.concat(curr.quarters), new Array<ISFOCQuarter>()),
              props.totalQuarters
            )
          )}
          %
        </GridCell>
        {props.quarters?.map((qNr) => {
          const sfocEquipment = data.find((d) => d.equipmentId === props.equipment.id);
          const quarter = sfocEquipment?.quarters?.find((q) => q.quarter === qNr);
          const status = quarter?.testValidation || "Not received";
          const rejected = status === "Rejected";

          if (rejected) {
            return (
              <StatusCell
                status={status}
                onExit={() => setVisibleTooltip(undefined)}
                onEnter={() => setVisibleTooltip(quarter?.quarter)}
                onClick={() => setVisibleTooltip(visibleTooltip === quarter?.quarter ? undefined : quarter?.quarter)}
              >
                {visibleTooltip === quarter?.quarter ? (
                  <Reason
                    reason={`${quarter?.quarter} ${props.equipment?.nickName}: ${quarter?.reasonForRejection}` || ""}
                  />
                ) : null}
              </StatusCell>
            );
          }

          return <StatusCell key={qNr} width="3em" status={status} />;
        })}
      </tr>
    </>
  );
};

const Vessel = styled.td`
  font-weight: bold;
`;

const InstallationRow = (props: { data: ISFOCData[]; quarters: string[]; totalQuarters: number }) => {
  const { data } = props;
  return (
    <>
      <tr>
        <td />
        <td>
          <LocalizedString id="sfoc.approved" />
        </td>
        <td colSpan={2 + props.quarters?.length} />
      </tr>
      <tr>
        <Vessel>
          <LocalizedString id="sfoc.engine" />
        </Vessel>
        <GridCell>
          {Math.floor(data.reduce((prev, d) => prev + getPercentage(d.quarters, props.totalQuarters), 0))}%
        </GridCell>
        <td colSpan={1 + props.quarters?.length} />
      </tr>
    </>
  );
};

const GridCell = styled.td`
  width: 2em;
  overflow: visible;
`;

const YearWrapper = styled.div`
  font-size: 9px;
  margin-top: 1em;
`;

const NextPrev = styled.span<{ direction: "left" | "right" }>`
  position: absolute;

  button {
    width: 4em;
    height: 3em;
    margin-left: -50%;
    top: -0.75em;
    ${(props) => (props.direction === "left" ? "left: -2.5em" : "right: -2.5em")};
  }
`;

const LegendWrapper = styled.div`
  padding-top: 20px;
  font-size: 12px;
`;

const Legend = () => (
  <LegendWrapper>
    <FlexContainer $centered={true} $justified={true}>
      {["Approved", "Suspended", "Rejected", "Not received"].map((key) => (
        <Status key={key} name={key} color={statusColors[key]} />
      ))}
    </FlexContainer>
  </LegendWrapper>
);

export const PerformanceHistoryChart = (props: {
  currentQuarter: string;
  chartData: ISFOCData[];
  equipments: IEquipment[];
  installationId: string;
}) => {
  const maxQuarter = parseInt(props.currentQuarter.replace("Q", "")) - 1;
  const [lastQuarter, setLastQuarter] = useState(maxQuarter);
  const visibleQuarters: string[] = [];
  const quarterSpan = 7;
  for (let q = lastQuarter - quarterSpan; q <= lastQuarter; ++q) {
    visibleQuarters.push(`Q${q}`);
  }
  const setQuarter = useCallback(
    (q: number) => {
      setLastQuarter(Math.min(maxQuarter, Math.max(quarterSpan + 1, q)));
    },
    [setLastQuarter]
  );
  const [quarterYears, setQuarterYears] = useState<{ [q: string]: number }>({});

  // Set up a map of quarter -> year for easy lookup if the previous year is different (and so we need to render the new year)
  useEffect(() => {
    const years: { [q: string]: number } = {};
    props.chartData.forEach((data) => data.quarters?.forEach((q) => (years[q.quarter] = q.year)));
    setQuarterYears(years);
  }, [props.chartData, setQuarterYears]);

  return (
    <DashboardItem title={translateString("sfoc.sfocTestPerformanceHistory")}>
      <StatusTable>
        <THead>
          <InstallationRow
            data={props.chartData.filter((data) => data.installationId === props.installationId)}
            quarters={visibleQuarters}
            totalQuarters={
              maxQuarter *
              props.equipments.filter((equipment) => equipment.installationId === props.installationId).length
            }
          />
        </THead>
        <TUnderlinedBody>
          {props.equipments
            .filter((equipment) => equipment.installationId === props.installationId)
            .map((equipment) => (
              <EquipmentRow
                name={equipment.nickName || equipment.description || ""}
                key={equipment.id}
                equipment={equipment}
                data={props.chartData.filter((eq) => eq.equipmentId === equipment.id)}
                quarters={visibleQuarters}
                totalQuarters={maxQuarter}
              />
            ))}
        </TUnderlinedBody>
        <TFoot>
          <tr>
            <td colSpan={2} />
            {visibleQuarters.map((q, index, array) => (
              <GridCell>
                {index === 0 && (
                  <NextPrev direction="left">
                    <Button
                      buttonStyle={ButtonStyle.Icon}
                      icon={<ArrowLeftIcon size={IconSize.Small} />}
                      onClick={() => setQuarter(lastQuarter - 1)}
                    />
                  </NextPrev>
                )}
                <div>{q}</div>
                <YearWrapper>
                  {quarterYears[array[index - 1]] !== quarterYears[array[index]] && quarterYears[array[index]]}
                </YearWrapper>
                {index === visibleQuarters.length - 1 && (
                  <NextPrev direction="right">
                    <Button
                      buttonStyle={ButtonStyle.Icon}
                      icon={<ArrowRightIcon size={IconSize.Small} />}
                      onClick={() => setQuarter(lastQuarter + 1)}
                    />
                  </NextPrev>
                )}
              </GridCell>
            ))}
          </tr>
        </TFoot>
      </StatusTable>
      <Legend />
    </DashboardItem>
  );
};
