import { Dispatch, bindActionCreators } from "redux";
import {
  IPartsCatalogueListDispatchProps,
  IPartsCatalogueListOwnProps,
  IPartsCatalogueListStateProps,
  PartsCatalogueListComponent,
} from "./PartsCatalogueListComponent";

import { APIFetchStatus } from "src/APIFetch";
import { APIResourceState } from "src/redux/api";
import { IAppState } from "src/redux";
import { addItem } from "src/redux/basket";
import { connect } from "react-redux";
import { fetchMultiplePartAvailability } from "src/redux/partsAvailability";
import { getPartsCatalogSectionViewModel } from "src/models/catalogueSections";
import { getSparePartOnboardingMockCatalogue } from "../CommerceList/mocks/onboardingMockPurchases";

const mapStateToProps = (state: IAppState, ownProps: IPartsCatalogueListOwnProps): IPartsCatalogueListStateProps => {
  const freeText = ownProps.filters.freeText;
  const isFilteringByFreetext = !!freeText || !!ownProps.manualInput;
  const viewModelResource = getPartsCatalogSectionViewModel(
    state.catalogueSections,
    state.catalogueParts,
    state.sectionImages,
    state.equipments,
    isFilteringByFreetext,
    freeText ? freeText.toLowerCase().trim() : null,
    state.partsAvailability,
    ownProps.externalBasket ? ownProps.externalBasket : state.basket,
    ownProps.filters.equipment
  );

  const partsForFilteringAreLoading = isFilteringByFreetext && state.catalogueParts.status !== APIFetchStatus.Success;
  const isReady = viewModelResource.status === APIFetchStatus.Success && !partsForFilteringAreLoading;
  const realSections = isReady ? viewModelResource.data : [];

  return {
    isReady: isReady || ownProps.useOnboardingMockData,
    sections: ownProps.useOnboardingMockData ? getSparePartOnboardingMockCatalogue() : realSections,
    partsAvailability: state.partsAvailability,
    equipments: state.equipments.data || [],
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IPartsCatalogueListDispatchProps =>
  bindActionCreators(
    {
      addItem: (item, quantity) => addItem(item, quantity),
      getSections: APIResourceState.catalogueSections.getWithParams,
      getParts: APIResourceState.catalogueParts.getWithParams,
      getMultiplePartAvailability: fetchMultiplePartAvailability,
      getImages: APIResourceState.sectionImages.getWithParams,
    },
    dispatch
  );

const PartsCatalogueListContainer = connect<
  IPartsCatalogueListStateProps,
  IPartsCatalogueListDispatchProps,
  IPartsCatalogueListOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(PartsCatalogueListComponent);
export { PartsCatalogueListContainer };
