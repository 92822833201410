import { IError, IResource } from "online-services-types";
import { AnyAction, Dispatch } from "redux";
import { APIFetch, APIFetchStatus, IAPICallParams, IAPIResource } from "src/APIFetch";
import { IAppState } from "src/redux";
import { displayError } from "src/util/error";
import { action, createCustomAction } from "typesafe-actions";
import { isArray } from "util";

interface IRestPayload<T> {
  data: T | T[];
  params?: IAPICallParams;
}

interface IRESTAction<T> extends AnyAction {
  id?: string;
  payload?: IRestPayload<T>;
  errorMessage?: string;
}

export enum APIResourceErrorDisplay {
  Hide,
  Autohide,
  Display,
}

export class APIResource<T extends IResource> implements IAPIResource<T> {
  public status: APIFetchStatus;
  public errorMessage?: string;
  public data: T[];
  public stateKey?: string;

  public readonly doneAction: (result: Array<Partial<T>> | T) => IRESTAction<T>;
  public readonly patchAction: (result: Array<Partial<T>>) => IRESTAction<T>;
  public readonly errorAction: (errorMessage: string) => IRESTAction<T>;
  public readonly safeErrorAction: (errorMessage: string) => IRESTAction<T>;
  public readonly deleteAction: (id: string) => IRESTAction<T>;
  public readonly deleteDoneAction: (id: string) => IRESTAction<T>;
  public readonly resetAction: () => IRESTAction<T>;

  protected readonly resourceCombo: string;
  protected readonly resourceName: string;
  protected readonly childResourceName?: string;
  protected readonly getAction: (id?: string) => IRESTAction<T>;
  protected readonly fetcher: APIFetch<T>;

  /**
   * Cache can only be enabled for resources that never get fetches by ID.
   */
  private readonly enableCache: boolean;
  private readonly displayErrors: APIResourceErrorDisplay;
  private readonly disableTokenKeepAlive: boolean;

  constructor(
    resourceName: string,
    childResourceName?: string,
    enableCache = false,
    displayErrors: APIResourceErrorDisplay = APIResourceErrorDisplay.Display,
    disableTokenKeepAlive = false,
    disableRetryPatch = true
  ) {
    this.enableCache = enableCache;
    this.displayErrors = displayErrors;
    this.disableTokenKeepAlive = disableTokenKeepAlive;
    this.resourceName = resourceName;
    this.childResourceName = childResourceName;
    this.fetcher = new APIFetch<T>(
      resourceName,
      childResourceName,
      false,
      disableRetryPatch,
      undefined,
      this.disableTokenKeepAlive
    );
    this.resourceCombo = !this.childResourceName ? this.resourceName : `${this.resourceName}_${this.childResourceName}`;
    this.getAction = () => action(`API_RESOURCE_${this.resourceCombo}_BUSY`);
    this.doneAction = (result: T) => action(`API_RESOURCE_${this.resourceCombo}_SUCCESS`, { data: result });
    this.deleteAction = createCustomAction(
      `API_RESOURCE_${this.resourceCombo}_DELETE`,
      (id: string): IRESTAction<T> => ({
        id,
        type: `API_RESOURCE_${this.resourceCombo}_DELETE`,
      })
    );
    this.deleteDoneAction = createCustomAction(
      `API_RESOURCE_${this.resourceCombo}_DELETE_DONE`,
      (id: string): IRESTAction<T> => ({
        id,
        type: `API_RESOURCE_${this.resourceCombo}_DELETE_DONE`,
      })
    );
    this.errorAction = createCustomAction(
      `API_RESOURCE_${this.resourceCombo}_FAILED`,
      (errorMessage: string): IRESTAction<T> => ({
        errorMessage,
        type: `API_RESOURCE_${this.resourceCombo}_FAILED`,
      })
    );
    this.safeErrorAction = createCustomAction(
      `API_RESOURCE_${this.resourceCombo}_FAILED_SAFE`,
      (errorMessage: string): IRESTAction<T> => ({
        errorMessage,
        type: `API_RESOURCE_${this.resourceCombo}_FAILED_SAFE`,
      })
    );
    this.patchAction = (payload: T[]): IRESTAction<T> =>
      action(`API_RESOURCE_${this.resourceCombo}_PATCH`, { data: payload });
    this.resetAction = () => action(`API_RESOURCE_${this.resourceCombo}_IDLE`);
  }

  public handleSuccess(state: APIResource<T>, action: IRESTAction<T>): APIResource<T> {
    state.status = APIFetchStatus.Success;
    if (action.payload) {
      // The payload is always there unless it's an error action
      state.data = action.payload.data as T[];
    }
    state.errorMessage = undefined;
    return state;
  }

  public handlePatch(state: APIResource<T>, action: IRESTAction<T>) {
    state.status = APIFetchStatus.Success;
    // Patch the new s in the existing objects
    if (action.payload && isArray(action.payload.data)) {
      if (!isArray(state.data)) {
        state.data = [];
      }
      const data = action.payload.data;

      data.forEach((newData) => {
        if (!state.data.find((oldData) => oldData.id === newData.id)) {
          state.data.push(newData);
        }
      });

      state.data = state.data.map((item) => {
        const patchItem = data.find((changedItem) => changedItem.id === item.id);

        if (patchItem) {
          return Object.assign({}, item, patchItem);
        }

        return item;
      });
    }

    return state;
  }

  public getDefaultState(previousState: APIResource<T>) {
    return Object.assign({}, previousState) || { status: APIFetchStatus.Idle, data: undefined };
  }

  public createReducer(stateKey: string): (state: APIResource<T>, action: IRESTAction<T>) => IAPIResource<T> {
    this.stateKey = stateKey;
    return (previousState: APIResource<T>, action: IRESTAction<T>): IAPIResource<T> => {
      const state = this.getDefaultState(previousState);

      switch (action.type) {
        case `API_RESOURCE_${this.resourceCombo}_IDLE`:
          state.status = APIFetchStatus.Idle;
          return state;

        case `API_RESOURCE_${this.resourceCombo}_BUSY`:
        case `API_RESOURCE_${this.resourceCombo}_DELETE`:
          state.status = APIFetchStatus.Busy;
          return state;

        case `API_RESOURCE_${this.resourceCombo}_SUCCESS`:
          return this.handleSuccess(state, action);

        case `API_RESOURCE_${this.resourceCombo}_PATCH`:
          return this.handlePatch(state, action);

        case `API_RESOURCE_${this.resourceCombo}_DELETE_DONE`:
          state.status = APIFetchStatus.Success;
          // Remove the deleted item
          state.data = state.data.filter((item) => item.id !== action.id);

          return state;

        case `API_RESOURCE_${this.resourceCombo}_FAILED`:
          state.status = APIFetchStatus.Failed;
          state.errorMessage = action.errorMessage;
          return state;

        case `API_RESOURCE_${this.resourceCombo}_FAILED_SAFE`:
          // Non-fatal failure
          state.errorMessage = action.errorMessage;
          return state;

        default:
          return state;
      }
    };
  }

  public get = (id?: string) => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
      if (id && this.enableCache) {
        throw new Error("Fetch cache can't be enabled when fetching by ID");
      }

      const state = getState();

      // this.stateKey will be defined at this point
      if (
        !this.enableCache ||
        !state[this.stateKey || ""].status ||
        state[this.stateKey || ""].status === APIFetchStatus.Idle
      ) {
        dispatch(this.getAction(id));
        try {
          const result = await this.fetcher.get(id);
          dispatch(this.doneAction(result));
        } catch (error) {
          if (this.displayErrors !== APIResourceErrorDisplay.Hide) {
            displayError(
              error,
              undefined,
              this.displayErrors === APIResourceErrorDisplay.Autohide ? { autoClose: 2000 } : { autoClose: false }
            );
          }
          dispatch(this.errorAction((error as IError).message));
        }
      }
    };
  };

  public delete = (id: string) => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
      try {
        await this.fetcher.delete(id);
        dispatch(this.deleteDoneAction(id));
      } catch (error) {
        if (this.displayErrors !== APIResourceErrorDisplay.Hide) {
          displayError(
            error,
            undefined,
            this.displayErrors === APIResourceErrorDisplay.Autohide ? { autoClose: 2000 } : { autoClose: false }
          );
        }
        dispatch(this.safeErrorAction((error as IError).message));
      }
    };
  };

  public invalidateCache = () => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
      dispatch(this.resetAction());
    };
  };
}
