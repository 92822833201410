// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_en_US: ITranslations = {
  en_US: {
    "reports.attachments": "Attachments",
    "reports.conditionMonitorinReports": "Maintenance and Condition Reports",
    "reports.newReportAvailable": "New report available",
    "reports.noReportsAvailable": "No reports available",
    "reports.rating": "Rating",
    "reports.reportPeriodStartDate": "Period",
    "reports.reportPublishedDate": "Date of issue",
    "reports.title": "Title",
    "reports.type": "Report type",
  },
};
