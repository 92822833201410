import { IInstallation } from "online-services-types";
import * as React from "react";
import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import { LocalizedString } from "src/components/Localization";
import colors from "src/design-system/Tokens/colors";
import { P } from "src/design-system/Tokens/typography";
import { ButtonLinkMenu } from "src/design-system/Button/ButtonLink";
import { translateString } from "src/util/localization";
import styled from "styled-components";
import { InstallationSelectionDialog } from "../InstallationSelectionDialog";
import { InstallationRequestComponent } from "./InstallationRequestComponent";

const InstallationChangeInfo = styled(P)`
  margin-top: 15px;
  color: ${colors.primary.black};
`;

const InstallationChangeInfoLink = styled.span`
  cursor: pointer;
  color: ${colors.primary.blue};
  font-weight: 700;
`;

const AddInstallationButtonLefMargin = 37;
export const AddInstallationButton = styled.div`
  margin-top: 40px;
  width: calc(100% - ${AddInstallationButtonLefMargin}px);
  height: 45px;
  margin-left: ${AddInstallationButtonLefMargin}px;
  margin-bottom: 20px;
  border-top: 1px solid ${colors.primary.white};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.primary.blue};
  color: ${colors.primary.white};
  cursor: pointer;
`;

export interface IDispatchProps {
  getInstallations(): void;
  setInstallationVisibility(installations: IInstallation[]): void;
}

export interface IComponentProps {
  installations: IAPIResource<IInstallation>;
}

export interface IReduxStateProps {
  toggleMenu(): void;
}

export interface IVisibleInstallationsPreferencesState {
  visibleInstallationsPreferencesOpen: boolean;
  displayedInstallations: IInstallation[];
  isSelectAllRowsChecked: boolean;
  newInstallationFormOpen: boolean;
  isInstallationDataLoaded: boolean;
}

export type IVisibleInstallationsPreferencesProps = IComponentProps & IDispatchProps & IReduxStateProps;

export const getChangedInstallations = (allInstallations: IInstallation[], selectedInstallationsIds: string[]) => {
  const changedInstallations = new Array<IInstallation>();
  allInstallations.forEach((installation) => {
    const currentlyHidden = !selectedInstallationsIds.includes(installation.id);
    if (currentlyHidden !== installation.hidden) {
      changedInstallations.push(Object.assign({}, installation, { hidden: currentlyHidden }));
    }
  });

  return changedInstallations;
};

export class VisibleInstallationsPreferences extends React.Component<
  IVisibleInstallationsPreferencesProps,
  IVisibleInstallationsPreferencesState
> {
  constructor(props: IVisibleInstallationsPreferencesProps) {
    super(props);

    this.state = {
      displayedInstallations: [],
      isSelectAllRowsChecked: false,
      newInstallationFormOpen: false,
      visibleInstallationsPreferencesOpen: false,
      isInstallationDataLoaded: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.dialogCancel = this.dialogCancel.bind(this);
    this.getSelectedInstallationsIds = this.getSelectedInstallationsIds.bind(this);
  }

  public render() {
    if (this.state.newInstallationFormOpen) {
      return (
        <InstallationRequestComponent
          installations={this.props.installations}
          isFormOpen={true}
          formClose={() =>
            this.setState({ newInstallationFormOpen: false, visibleInstallationsPreferencesOpen: false })
          }
        />
      );
    }

    return (
      <>
        <ButtonLinkMenu
          onClick={() => {
            this.props.toggleMenu();
            setTimeout(() => {
              this.setState({
                visibleInstallationsPreferencesOpen: true,
                displayedInstallations: this.props.installations.data ? this.props.installations.data : [],
              });
            }, 450);
          }}
        >
          <LocalizedString id="myInstallations" />
        </ButtonLinkMenu>

        {this.state.isInstallationDataLoaded && (
          <InstallationSelectionDialog
            title={translateString("visibleInstallations")}
            isDialogOpen={this.state.visibleInstallationsPreferencesOpen}
            onCancel={this.dialogCancel}
            buttonTitle={translateString("saveVisibilitySelection")}
            onSubmit={this.onSubmit}
            installations={this.props.installations.data}
            initialSelectedInstallationIds={this.getSelectedInstallationsIds(this.props.installations.data)}
            initialDisplayedInstallations={this.state.displayedInstallations}
            extraInfo={
              <InstallationChangeInfo>
                <LocalizedString id="visibleInstallations.changeLabel" />
                <InstallationChangeInfoLink onClick={() => this.setState({ newInstallationFormOpen: true })}>
                  <LocalizedString id="visibleInstallations.changeAction" />
                </InstallationChangeInfoLink>
              </InstallationChangeInfo>
            }
          />
        )}
      </>
    );
  }

  public componentDidUpdate(prevProps: IVisibleInstallationsPreferencesProps) {
    if (
      this.props.installations.status === APIFetchStatus.Success &&
      prevProps.installations.status !== APIFetchStatus.Success
    ) {
      this.setState({
        displayedInstallations: this.props.installations.data,
        isInstallationDataLoaded: true,
      });
    }
  }

  public componentDidMount() {
    this.props.getInstallations();
  }

  // if the user is checked any boxes and then pressed 'cancel', reset the checked boxes as it was before
  private dialogCancel() {
    this.setState({
      visibleInstallationsPreferencesOpen: false,
    });
  }

  private getSelectedInstallationsIds(installations: IInstallation[]) {
    return installations.filter((installation) => !installation.hidden).map((installation) => installation.id);
  }

  private onSubmit(selectedInstallations: string[]) {
    const changedInstallations = getChangedInstallations(this.props.installations.data, selectedInstallations);

    if (changedInstallations.length > 0) {
      this.props.setInstallationVisibility(changedInstallations);
    }

    this.setState({ visibleInstallationsPreferencesOpen: false });
  }
}
