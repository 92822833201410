import * as React from "react";
// Styled Components
import * as s from "./styles";
// Types and Interfaces
import * as t from "./types";

// Components
import { Container, FlexContainer } from "src/design-system/Container";
import { DEFAULT_MATERIAL_ID, deliveryModeByCode, patchDraft } from "src/util/commerce";
import { Disclaimer, IDisclaimerTheme } from "src/design-system/Tokens/typography";
import { FixedWidthContainer, ResponsiveDeliveryTree } from "./ResponsiveDeliveryTree";
import { IDelivery, IInvoice, IInvoiceAttachment, INotification, IPurchase } from "online-services-types";
import { IRoute, IRouteParams, getPathWithParams, getRoutes } from "src/routes";
import { IconSize, QuantiPartsIcon, SubscriptionIcon, TriangleWarningIcon, WarningIcon } from "src/icons";
import { Link, LinkWrapper } from "src/design-system/Link";
import { capitalize, get, sortBy } from "lodash";
import { capitalizeFirstLetter, formatCurrency, formatDate } from "src/util/formatters";
import { displayError, displayInfo } from "src/util/error";

import { AttachmentIconDownload } from "src/views/InvoicesView/AttachmentIconDownload";
import { AttachmentList } from "src/components/SupportList/AttachmentList";
import { AttachmentSource } from "src/models/attachments";
import { Button } from "src/design-system/Button";
import { ExportCSV } from "./utils";
import { ExportPartModel } from "../BasketExportDocument";
import { IResponsiveTableColumn } from "src/components/ResponsiveTable/interfaces";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { NotificationSubscriptionLink } from "./styles";
import { PurchasePdf } from "src/components/PurchasePDF/PurchasePdf";
import { PurchaseStatus } from "src/services/commerce/commerce.purchase-status";
import { ResponsiveItems } from "./ResponsiveItems";
import { TextLoader } from "src/components/LoadingSpinner";
import colors from "src/design-system/Tokens/colors";
import { getCurrencySymbol } from "src/util/currencyMapper";
import styled from "styled-components";
// Utils
import { translateString } from "src/util/localization";

const totalSum: string = translateString("spareParts.totalSum");

export const LabelIconWrapper = styled.div`
  svg {
    height: 40px;
    width: 40px;
    margin-bottom: -20px;
    margin-left: 10px;
  }
`;

const DisclaimerWithIconWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 10px 0;
`;

const DisclaimerIconWrapper = styled.div`
  position: absolute;
  left: -25px;
`;

const statusMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "status",
  label: translateString("status"),
  plannedWidth: 160,
  priority: 10,
  backgroundColor: colors.secondary.gray10,
  valueRenderer: ({ row }) => <div>{row && capitalizeFirstLetter(row.status)}</div>,
  labelRenderer: ({ row }) => <s.OrderTypeContainer>{row && row.type}</s.OrderTypeContainer>,
});

const checkQuantipartsPlant = (row: IPurchase) => {
  return row.deliveryPlant === "NL27";
};

const referenceMetadata = (
  props: t.IPurchaseHistoryListOwnProps
): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "wartsilaReference",
  label: props.isQuantipartsDistributor
    ? translateString("spareParts.QPReference")
    : translateString("spareParts.wartsilaReference"),
  plannedWidth: 175,
  priority: 5,
  valueRenderer: ({ row }) => {
    if (checkQuantipartsPlant(row) && !props.isQuantipartsDistributor) {
      return (
        <FlexContainer>
          {row.wartsilaReference ? row.wartsilaReference : "-"}
          <LabelIconWrapper>
            <QuantiPartsIcon />
          </LabelIconWrapper>
        </FlexContainer>
      );
    }

    return row.wartsilaReference ? row.wartsilaReference : "-";
  },
});

const installationNameMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "installationName",
  label: translateString("installation"),
  plannedWidth: 200,
  priority: 3,
  valueRenderer: ({ value }) => (!value ? "-" : value),
});

const yourReferenceMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "yourReference",
  label: translateString("spareParts.yourReference"),
  plannedWidth: 250,
  staticWidth: true,
  priority: 1,
  valueRenderer: ({ value }) => (!value ? "-" : value),
});

const accountNameMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "accountName",
  label: translateString("spareParts.account"),
  plannedWidth: 200,
  priority: 3,
  valueRenderer: ({ value }) => (!value ? "-" : value),
});

const totalMetadata: IResponsiveTableColumn<t.PurchaseWithNotification> = {
  key: "goodsTotal",
  label: totalSum,
  plannedWidth: 100,
  priority: 4,
  labelRenderer: ({ row }) => {
    if (
      (row &&
        row.type === t.itemTypes.quotation &&
        row.status !== t.notificationStatuses.quoted &&
        row.status !== t.notificationStatuses.ordered) ||
      row.type === t.itemTypes.draft ||
      (row.type === t.itemTypes.order && row.status === t.notificationStatuses.new)
    ) {
      return totalSum;
    }
    const currency = row.currencyCode ? getCurrencySymbol(row.currencyCode) : "EUR";
    return `${totalSum} (${getCurrencySymbol(currency)})`;
  },
  valueRenderer: ({ row, value }) => {
    if (
      row &&
      row.type === t.itemTypes.quotation &&
      row.status !== t.notificationStatuses.quoted &&
      row.status !== t.notificationStatuses.ordered
    ) {
      return "-";
    }
    return value ? formatCurrency(value as number) : "-";
  },
};

const lastModifiedMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "lastModifiedDate",
  label: translateString("spareParts.lastModified"),
  plannedWidth: 150,
  priority: 1,
  valueRenderer: ({ value }) => (value as string) && formatDate(value as string),
});

const creationDateMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "creationDate",
  label: translateString("spareParts.creationDate"),
  plannedWidth: 150,
  priority: 1,
  valueRenderer: ({ value }) => (value as string) && formatDate(value as string),
});

const buttonsMetadata = (props: t.IPurchaseHistoryListOwnProps): IResponsiveTableColumn<t.PurchaseWithNotification> => {
  const [activeAccount] = props.accounts.filter((account) => account.selected);
  return {
    key: "buttons",
    label: "",
    plannedWidth: 100,
    priority: 2,
    alwaysHidden: true,
    renderer: ({ row }) => {
      if (row && row.type === t.itemTypes.draft) {
        return (
          <Container $margin={[4, 0, 4, 0]}>
            <s.FlexWrapper>
              <Button
                disabled={!row.lineItems && !row.lineItemsFromSF}
                onClick={() => {
                  props.history.push(getPathWithParams(getRoutes().CommerceShoppingCart), {
                    draft: row,
                  });
                }}
              >
                {translateString("spareParts.loadBasketDraft")}
              </Button>
              <Button
                onClick={async () => {
                  const draftBody = {
                    id: row.id,
                    name: row.yourReference,
                    status: row.status,
                    lastModifiedDate: row.lastModifiedDate,
                    installationSAPId: row.installationId || "",
                    lineItems: row.lineItemsFromSF || [],
                    deleted: true,
                  };
                  try {
                    await patchDraft(draftBody);
                    props.setRecentlyDeletedPurchases(row.id, () =>
                      displayInfo(translateString("spareParts.draftDeleteSuccess"))
                    );
                  } catch {
                    displayError(translateString("spareParts.draftDeleteFailure"));
                  }
                }}
              >
                {translateString("spareParts.deleteBasketDraft")}
              </Button>
            </s.FlexWrapper>
          </Container>
        );
      } else if (
        row &&
        row.type === t.itemTypes.quotation &&
        row.status === t.notificationStatuses.quoted &&
        (row.lineItems || row.lineItemsFromSF) &&
        !row.assosiatedOrder &&
        activeAccount.id === row.accountSAPCustomerId
      ) {
        if (!props.customerIsBlocked) {
          return (
            <Container $margin={[1, 0, 0, 0]}>
              <Button
                onClick={() => {
                  props.history.push(getPathWithParams(getRoutes().CommerceShoppingCart), {
                    quotation: row,
                  });
                }}
              >
                {translateString("routes.proceedToOrder")}
              </Button>
            </Container>
          );
        } else {
          return <Container $margin={[1, 0, 0, 0]}>{translateString("spareParts.blockedCustomerMessage")}</Container>;
        }
      } else if (row && row.lineItems && activeAccount.id === row.accountSAPCustomerId) {
        return (
          <Container $margin={[4, 0, 4, 0]}>
            <s.FlexWrapper>
              {!row.lineItems.find((lineItem) => lineItem.materialNumber === DEFAULT_MATERIAL_ID) ? (
                <Button
                  onClick={() => {
                    //Because we copy, any references to previous quotation should be erased
                    let quotation = {
                      ...row,
                      id: "",
                      wartsilaReference: "",
                    };
                    // If the quotation has expired, strip out all id:s and prices to create a new quotation draft
                    if (row.status === PurchaseStatus.Expired) {
                      quotation = {
                        ...row,
                        id: "",
                        wartsilaReference: "",
                        lineItemsFromSF: (row.lineItemsFromSF || []).map((item) => ({
                          ...item,
                          sfId: undefined,
                          netPrice: undefined,
                          totNetPrice: undefined,
                          weight: undefined,
                          deliveries: [],
                          attachmentIds: [],
                          attachments: [],
                        })),
                      };
                    }
                    props.history.push(getPathWithParams(getRoutes().CommerceShoppingCart), {
                      quotation,
                    });
                  }}
                >
                  {`${translateString("spareParts.copy")} ${capitalize(row.type)}`}
                </Button>
              ) : (
                <FlexContainer $centered={true}>
                  <FlexContainer $noShrink={true}>
                    <WarningIcon size={IconSize.Small} />
                  </FlexContainer>
                  <Container $margin={[1, 2]}>{translateString("spareParts.quotationCannotBeCopied")}</Container>
                </FlexContainer>
              )}
            </s.FlexWrapper>
          </Container>
        );
      } else {
        return "";
      }
    },
  };
};

const getAttachmentSourceId = (purchase: t.PurchaseWithNotification) => {
  // User-submitted attachments should be fetched with the PO/RFQ ID, as using the row ID will also return SAP-generated files
  if (purchase.type === t.itemTypes.order) return purchase.poSFId || "";
  if (purchase.type === t.itemTypes.quotation) return purchase.rfqSFId || "";
  return "";
};

const attachmentsMetadata = (
  props: t.IPurchaseHistoryListOwnProps
): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "attachments",
  label: "",
  plannedWidth: 100,
  priority: 4,
  alwaysHidden: true,
  isEnabled: (row) => row.type !== t.itemTypes.draft,
  renderer: ({ row }) => {
    return (
      <Container $margin={[1, 0, 0, 0]}>
        <Disclaimer>{translateString("attachments")}</Disclaimer>
        <AttachmentList
          user={props.user}
          sourceId={getAttachmentSourceId(row)}
          isUploaderBusy={false}
          isAttachmentUploadDisabled={true}
          attachmentSource={row.status === "Sent to distributor" ? AttachmentSource.Draft : AttachmentSource.Commerce}
        />
      </Container>
    );
  },
});

const accountAdditionalNameMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "accountAdditionalName",
  label: translateString("spareParts.accountAdditionalName"),
  hideEmptyValues: true,
  plannedWidth: 200,
  priority: 3,
  alwaysHidden: true,
  valueRenderer: ({ value }) => (!value ? "-" : value),
});

const relatedOrderMetadata = (
  setDisplayed: (id?: string) => void
): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "orderSFId",
  label: translateString("spareParts.relatedOrder"),
  plannedWidth: 200,
  priority: 3,
  hideEmptyValues: true,
  alwaysHidden: true,
  valueRenderer: ({ row }) => {
    if (row.orderSFId && row.orderSAPExternalId) {
      return (
        <div onClick={() => setDisplayed(row.orderSFId)}>
          <LinkWrapper>{row.orderSAPExternalId}</LinkWrapper>
        </div>
      );
    } else {
      return "";
    }
  },
});

const relatedQuoteMetadata = (
  setDisplayed: (id?: string) => void
): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "quoteSFId",
  label: translateString("spareParts.relatedQuote"),
  plannedWidth: 200,
  priority: 3,
  hideEmptyValues: true,
  alwaysHidden: true,
  valueRenderer: ({ row }) => {
    if (row.quoteSFId && row.quoteSAPExternalId) {
      return (
        <div onClick={() => setDisplayed(row.quoteSFId)}>
          <LinkWrapper>{row.quoteSAPExternalId}</LinkWrapper>
        </div>
      );
    } else {
      return "";
    }
  },
});

const relatedSPCMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "spcSFId",
  label: translateString("spareParts.relatedSPC"),
  plannedWidth: 200,
  priority: 3,
  hideEmptyValues: true,
  alwaysHidden: true,
  valueRenderer: ({ row }) => {
    if (row.spcSFId && row.spcSAPExternalId) {
      const params: IRouteParams = { requestId: row.spcSFId };
      const route: IRoute = getRoutes().Requests;
      return <Link to={getPathWithParams(route, params)}>{row.spcSAPExternalId}</Link>;
    } else {
      return "";
    }
  },
});

const accountVATRegistrationMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "accountVATRegistration",
  label: translateString("spareParts.accountVATRegistration"),
  plannedWidth: 200,
  priority: 3,
  alwaysHidden: true,
  hideEmptyValues: true,
  valueRenderer: ({ value }) => (!value ? "-" : value),
});

const registerClaim = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "registerClaim",
  plannedWidth: 200,
  priority: 4,
  alwaysHidden: true,
  valueRenderer: ({ row }) => {
    if (row.type === t.itemTypes.order && row.deliveries?.length) {
      if (
        row.status === PurchaseStatus.Delivered ||
        row.status === PurchaseStatus.PartiallyDelivered ||
        row.status === PurchaseStatus.Collected ||
        row.status === PurchaseStatus.PartiallyCollected
      ) {
        const routes = getRoutes();
        return (
          <Container $marginBottom={3} $marginTop={2}>
            <Link
              to={{
                state: { order: row.wartsilaReference },
                pathname: getPathWithParams(routes.CreateWarrantyClaim, { phase: 2, requestType: "SparePartClaim" }),
              }}
            >
              <Button>{translateString("spareParts.registerClaim")}</Button>
            </Link>
          </Container>
        );
      } else {
        return "";
      }
    }

    return "";
  },
});

const commentMetadata = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "freeComment",
  label: translateString("spareParts.freeComment"),
  hideEmptyValues: true,
  plannedWidth: 100,
  priority: 1,
  alwaysHidden: true,
});

const distributorName = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "distributorName",
  label: "",
  plannedWidth: 100,
  priority: 3,
  hideEmptyValues: true,
  alwaysHidden: true,
  valueRenderer: ({ value, row }) => {
    if (value && row && row.type) {
      return (
        <Container $margin={[1, 0, 0, 0]}>
          <Container $margin={[1, 0]}>
            <s.Dist>
              <s.WIcon size={IconSize.Small} />
              <LocalizedStringComponent id="spareParts.handleByDistributor" values={{ purchase: row.type }} />
            </s.Dist>
          </Container>
          <Disclaimer forcedTheme={1}>{translateString("spareParts.distributor")}</Disclaimer>
          <Container>{value}</Container>
        </Container>
      );
    } else {
      return "";
    }
  },
});

const distributorEmail = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "distributorEmail",
  label: translateString("spareParts.distributorInfo"),
  plannedWidth: 200,
  priority: 1,
  hideEmptyValues: true,
  alwaysHidden: true,
});

const deliveries = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  label: "",
  priority: 1,
  key: "deliveries",
  plannedWidth: 100,
  alwaysHidden: true,
  renderer: ({ row }) =>
    row.deliveries ? (
      <ResponsiveDeliveryTree
        deliveries={row.deliveries}
        notifications={row.notifications as INotification<IDelivery>[]}
        customerId={row.accountSAPCustomerId}
      />
    ) : (
      ""
    ),
});

const lineItemsMetadata = (
  props: t.IPurchaseHistoryListOwnProps
): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "lineItems",
  label: "",
  plannedWidth: 100,
  priority: 1,
  alwaysHidden: true,
  renderer: ({ row }) => {
    if (row && row.type === t.itemTypes.draft && row.lineItemsFromSF) {
      return (
        <ResponsiveItems
          header={row}
          rowType={row.type}
          rowStatus={row.status}
          isMarine={props.isMarine}
          parts={row.lineItemsFromSF}
          equipments={props.equipments}
          notifications={row.notifications || []}
        />
      );
    }

    if (row && row.status === t.notificationStatuses.sentToDistributor && row.lineItemsFromSF) {
      return (
        <ResponsiveItems
          header={row}
          rowType={row.type}
          rowStatus={row.status}
          isMarine={props.isMarine}
          parts={row.lineItemsFromSF}
          equipments={props.equipments}
          notifications={row.notifications || []}
        />
      );
    }

    if (row && row.lineItemsFromSF) {
      return (
        <ResponsiveItems
          header={row}
          rowType={row.type}
          rowStatus={row.status}
          isMarine={props.isMarine}
          parts={row.lineItemsFromSF}
          equipments={props.equipments}
          hideAvailability={row.type !== t.itemTypes.quotation}
          partsAvailability={props.partsAvailability.availability}
          notifications={row.notifications || []}
          hidePrices={
            row.type === t.itemTypes.quotation &&
            row.status !== t.notificationStatuses.quoted &&
            row.status !== t.notificationStatuses.ordered
          }
        />
      );
    }

    return (
      <Container $margin={[0, 0, 4, 0]}>
        <TextLoader />
      </Container>
    );
  },
});

const requestPDF = (props: t.IPurchaseHistoryListOwnProps): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "salesDocumentPDF",
  label: "",
  plannedWidth: 100,
  priority: 3,
  alwaysHidden: true,
  renderer: ({ row }) => {
    const isExportCSVVisible =
      row.type === t.itemTypes.quotation &&
      Boolean(row.lineItemsFromSF?.length) &&
      (row.status === t.notificationStatuses.quoted || row.status === t.notificationStatuses.ordered);

    const partsWithAvailability = row.lineItemsFromSF?.map((item) => {
      const availability = props.partsAvailability.availability?.find((partAvailability) => {
        if (partAvailability.equipmentId !== item.equipmentId) return false;

        if (partAvailability.id && item.sparePartNumber) {
          return partAvailability.id === item.sparePartNumber;
        } else {
          return partAvailability.materialId === item.materialId;
        }
      });

      if (!availability) return item;

      return {
        ...item,
        leadText: availability.leadText,
        quantityInStock: availability.quantityInStock,
      };
    });

    const basketExportParts =
      partsWithAvailability?.map((item) => new ExportPartModel(item, { installation: row.installationName })) ?? [];
    const basketExportHeaders = basketExportParts.length > 0 ? basketExportParts[0].headers : [];

    if (isPDFVisibleForQuote(row) || isPDFVisibleForOrder(row)) {
      return (
        <Container>
          <Disclaimer>
            {translateString(
              row.type === t.itemTypes.order ? "spareParts.orderAcknowledgement" : "spareParts.quotation"
            )}
          </Disclaimer>
          <FlexContainer $gap={1} $centered $wrap $padding={[1, 0]}>
            {isExportCSVVisible ? (
              <ExportCSV
                data={basketExportParts}
                headers={basketExportHeaders}
                enabled={props.partsAvailability.isReady}
                filename={`Quotation ${row.wartsilaReference}.csv`}
              />
            ) : null}
            <PurchasePdf details={row} />
          </FlexContainer>
        </Container>
      );
    } else {
      if (isExportCSVVisible) {
        return (
          <Container>
            <Disclaimer>{translateString("spareParts.quotation")}</Disclaimer>
            <ExportCSV
              data={basketExportParts}
              headers={basketExportHeaders}
              enabled={props.partsAvailability.isReady}
              filename={`Quotation ${row.wartsilaReference}.csv`}
            />
          </Container>
        );
      }

      return "";
    }
  },
});

const quotationValidUntil = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  priority: 3,
  key: "validUntil",
  plannedWidth: 200,
  alwaysHidden: true,
  isEnabled: (row) => row.type === t.itemTypes.quotation && row.status === t.notificationStatuses.quoted,
  renderer: ({ value }) => (
    <FixedWidthContainer $margin={[2, 0]}>
      <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.quotationValidUntil")}</Disclaimer>
      <div>{typeof value === "string" ? formatDate(value) : ""}</div>
    </FixedWidthContainer>
  ),
});

const deliveryInfo = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  label: "",
  priority: 3,
  key: "deliveryInfo",
  plannedWidth: 200,
  alwaysHidden: true,
  renderer: ({ row }) =>
    row.type === t.itemTypes.draft ? (
      ""
    ) : (
      <FlexContainer $margin={[4, 6, 2, 0]} $wrap>
        {row.shippingAddress ? (
          <FixedWidthContainer width={210} $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.shippingAddress")}</Disclaimer>
            <div>{row.shippingAddress}</div>
          </FixedWidthContainer>
        ) : null}
        {row.consolidationPoint ? (
          <FixedWidthContainer width={210} $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
              {translateString("spareParts.consolidationPoint")}
            </Disclaimer>
            <div>{row.consolidationPoint}</div>
          </FixedWidthContainer>
        ) : null}
        {row.incoterms ? (
          <FixedWidthContainer width={200} $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.incoterms")}</Disclaimer>
            <div>{row.incoterms}</div>
          </FixedWidthContainer>
        ) : null}
        {row.deliveryMode ? (
          <FixedWidthContainer width={250} $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.deliveryMode")}</Disclaimer>
            <div>{get(deliveryModeByCode, row.deliveryMode || "", row.deliveryMode)}</div>
          </FixedWidthContainer>
        ) : null}
        {row.paymentTerm ? (
          <FixedWidthContainer width={200} $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.paymentTerms")}</Disclaimer>
            <div>{row.paymentTerm}</div>
          </FixedWidthContainer>
        ) : null}
        {row.weight ? (
          <FixedWidthContainer width={200} $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.partWeight")} (kg)</Disclaimer>
            <div>{row.weight}</div>
          </FixedWidthContainer>
        ) : null}
      </FlexContainer>
    ),
});

const proformaInfo = ({
  invoices,
  getInvoice,
}: {
  getInvoice: (id: string) => void;
  invoices: Record<string, { status: "loading" | "success" | "error"; data?: IInvoice }>;
}): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  label: "",
  priority: 2,
  key: "proformaInfo",
  plannedWidth: 200,
  alwaysHidden: true,
  renderer: ({ row }) => {
    if (row.type === t.itemTypes.draft) return;
    if (!row.proformaReference) return;

    const supportedBillingTypes = ["F5", "F8"];
    const isSupportedBillingType = row.billingType ? supportedBillingTypes.includes(row.billingType) : false;
    if (!isSupportedBillingType) return;

    const invoice = get(invoices, row.proformaReference);
    if (!invoice) {
      getInvoice(row.proformaReference);
      return;
    }

    const hasNoData = !invoice.data;
    const error = invoice.status === "error";
    const loading = invoice.status === "loading";
    const succeeded = invoice.status === "success";

    if (error) return;
    if (succeeded && hasNoData) return;

    if (loading) {
      return (
        <Container $margin={[0, 0, 4, 0]}>
          <TextLoader />
        </Container>
      );
    }

    const allowedProformaTypes = ["ZRD0", "ZRC0", "ZRP0", "ZRD1", "ZRC1", "ZRP1"];
    const allowedProformas = invoice.data?.attachments.filter((attachment) =>
      allowedProformaTypes.includes(attachment.type)
    );

    let prioritizedProforma: IInvoiceAttachment | null = null;
    if (allowedProformas?.length) {
      [prioritizedProforma] = sortBy(allowedProformas, (attachment) => allowedProformaTypes.indexOf(attachment.type));
    }

    if (!prioritizedProforma) return;

    if (row.type === t.itemTypes.order) {
      if (
        row.status === t.notificationStatuses.created ||
        row.status === t.notificationStatuses.cancelled ||
        row.status === t.notificationStatuses.toBeConfirmed
      ) {
        return;
      }

      return (
        <FixedWidthContainer $marginBottom={2}>
          <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.proformaDocument")}</Disclaimer>
          <AttachmentIconDownload invoiceId={invoice.data?.id} type={prioritizedProforma.type} />
        </FixedWidthContainer>
      );
    }

    if (row.type === t.itemTypes.quotation) {
      if (row.status !== t.notificationStatuses.quoted) return;
      return (
        <FixedWidthContainer $marginBottom={2}>
          <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.proformaDocument")}</Disclaimer>
          <AttachmentIconDownload invoiceId={invoice.data?.id} type={prioritizedProforma.type} />
        </FixedWidthContainer>
      );
    }

    return;
  },
});

const notificationSubscription = (
  openSubscriptionDialog: (purchase: IPurchase | null) => void
): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "subscription",
  label: "",
  plannedWidth: 300,
  priority: 1,
  alwaysHidden: true,
  isEnabled: (row) =>
    (row.type === t.itemTypes.order || row.type === t.itemTypes.quotation) &&
    row.status !== t.notificationStatuses.sentToDistributor,
  renderer: ({ row }) => {
    return (
      <DisclaimerWithIconWrapper>
        <DisclaimerIconWrapper>
          <SubscriptionIcon color={row.subscription?.id ? "#ed731a" : colors.primary.gray} size={IconSize.Small} />
        </DisclaimerIconWrapper>
        {row.lineItemsFromSF?.length ? (
          <NotificationSubscriptionLink onClick={() => openSubscriptionDialog(row)}>
            {translateString(
              row.subscription?.id ? "notifications.subscriptions.subscribed" : "notifications.subscriptions.subscribe"
            )}
          </NotificationSubscriptionLink>
        ) : (
          <span>
            {translateString(
              row.subscription?.id ? "notifications.subscriptions.subscribed" : "notifications.subscriptions.subscribe"
            )}
          </span>
        )}
      </DisclaimerWithIconWrapper>
    );
  },
});

const dgrItemDisclaimer = (): IResponsiveTableColumn<t.PurchaseWithNotification> => ({
  key: "lineItemsFromSF",
  label: "",
  plannedWidth: 300,
  priority: 2,
  alwaysHidden: true,
  renderer: ({ row }) => {
    if (
      (row.type === t.itemTypes.order || row.type === t.itemTypes.quotation) &&
      (row.lineItemsFromSF || []).some((line) => line.dangerousGoodsIndicator)
    ) {
      return (
        <DisclaimerWithIconWrapper>
          <DisclaimerIconWrapper>
            <TriangleWarningIcon color="#ed731a" size={IconSize.Small} />
          </DisclaimerIconWrapper>
          <span>
            {translateString(row.type === t.itemTypes.order ? "spareParts.DGROrder" : "spareParts.DGRQuotation")}
          </span>
        </DisclaimerWithIconWrapper>
      );
    }
    return;
  },
});

export const columns = (
  props: t.IPurchaseHistoryListOwnProps,
  setDisplayed: (id?: string) => void,
  setRowInvoice: (id?: string) => void,
  openSubscriptionDialog: (row: IPurchase | null) => void,
  invoices: Record<string, { status: "loading" | "success" | "error"; data?: IInvoice }>
): Array<IResponsiveTableColumn<t.PurchaseWithNotification>> => {
  return [
    statusMetadata(),
    notificationSubscription(openSubscriptionDialog),
    dgrItemDisclaimer(),
    referenceMetadata(props),
    installationNameMetadata(),
    yourReferenceMetadata(),
    accountNameMetadata(),
    totalMetadata,
    lastModifiedMetadata(),
    creationDateMetadata(),
    buttonsMetadata(props),
    quotationValidUntil(),
    deliveryInfo(),
    proformaInfo({ invoices, getInvoice: setRowInvoice }),
    requestPDF(props),
    attachmentsMetadata(props),
    accountAdditionalNameMetadata(),
    relatedSPCMetadata(),
    relatedOrderMetadata(setDisplayed),
    relatedQuoteMetadata(setDisplayed),
    accountVATRegistrationMetadata(),
    registerClaim(),
    commentMetadata(),
    distributorName(),
    distributorEmail(),
    deliveries(),
    lineItemsMetadata(props),
    // The ResponsiveTable always aligning the last column to the right side of the row
    // probably it is a bug and when it will be fixed this column can be deleted
    {
      priority: 1,
      key: "hidden",
      plannedWidth: 1,
      renderer: () => <div />,
    },
  ];
};

const isPDFVisibleForQuote = (row: t.PurchaseWithNotification) => {
  return (
    row &&
    row.type === t.itemTypes.quotation &&
    (row.status === PurchaseStatus.Quoted || row.status === PurchaseStatus.Ordered)
  );
};

const isPDFVisibleForOrder = (row: t.PurchaseWithNotification) => {
  return row && row.type === t.itemTypes.order && row.glsStatus === t.orderStatusToShowPDF;
};
