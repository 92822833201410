import * as React from "react";
import { useContext } from "react";
import { LocalizedString } from "src/components/Localization";
import { Button } from "src/design-system/Button";
import { spacer } from "src/design-system/Tokens/tokens";
import { H4 } from "src/design-system/Tokens/typography";
import { getRoutes, redirectBrowser } from "src/routes";
import { translateString } from "src/util/localization";
import { IReleaseNote, ReleaseNoteComponent } from "src/views/ReleaseNotesView/ReleaseNote";
import { VersionContext } from "src/views/ReleaseNotesView/VersionContext";
import styled from "styled-components";

const ReleaseNotesSection = styled.div`
  padding: ${spacer(2)} ${spacer(4)};
`;

const ReadMoreButtonWrapper = styled.div`
  margin-top: ${spacer(4)};
`;
export const ReleaseNoteSection = () => {
  const version = useContext(VersionContext);
  const releaseNotesPerPage = 3;
  const visibleReleaseNotes = version.isReady ? version.releaseNotes.slice(0, releaseNotesPerPage) : [];

  return (
    <ReleaseNotesSection>
      <H4>{translateString("routes.releaseNotes")}</H4>
      {visibleReleaseNotes.map((releaseNote: IReleaseNote, index) => (
        <ReleaseNoteComponent
          // If we have unseen release notes and this is the first note then this should be displayed as unseen
          key={releaseNote.releaseVersion}
          isUnseen={version.hasNewReleaseNotes && index === 0}
          releaseNote={releaseNote}
          hideDivider={index === visibleReleaseNotes.length - 1}
        />
      ))}
      <ReadMoreButtonWrapper>
        <Button
          onClick={() => {
            redirectBrowser(getRoutes().ReleaseNotes);
            version.setIsSidebarOpen(false);
          }}
        >
          <LocalizedString id="releaseNotes.readMore" />
        </Button>
      </ReadMoreButtonWrapper>
    </ReleaseNotesSection>
  );
};
