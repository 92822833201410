import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const SwitchIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Switch</title>
    <path d="M25,8.17a15.77,15.77,0,0,0-8.25,1.65h0l0,0h0A16,16,0,0,0,9.32,30.33L4.24,32.92,18,42.76l.1-16.89-5.19,2.64a12,12,0,0,1,5.66-15.12A11.8,11.8,0,0,1,25,12.17Z" />
    <path d="M43.8,15.08,30.07,5.25,30,22.14l5.09-2.6a12,12,0,0,1-5.61,15.23A11.87,11.87,0,0,1,23,36v4a15.73,15.73,0,0,0,8.24-1.65h.05a16,16,0,0,0,7.33-20.59Z" />
  </IconBase>
);
