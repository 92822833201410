import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";
import React from "react";
import { whenDesktopOrLarger, whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";

export const defaultLegendColors: ReadonlyArray<string> = [
  colors.graphnode.blue,
  colors.graphnode.pink,
  colors.graphnode.purple,
  colors.graphnode.beige,
  colors.graphnode.yellow,
  colors.graphnode.lightblue,
  colors.graphnode.lightgreen,
  colors.graphnode.lightgray,
  colors.graphnode.gray,
];

const LegendWrapper = styled.div`
  display: grid;
  gap: 0.5em 1em;
  font-size: 12px;
  margin-bottom: 4em;
  grid-template-columns: 1fr 1fr;

  ${whenTabletOrLarger(`
    gap: 0.5em 3em;
    grid-template-columns: 1fr 1fr 1fr;
  `)};

  ${whenDesktopOrLarger(`
    gap: 0.5em 3em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `)};
`;

const LegendItemWrapper = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LegendLabel = styled.span`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ColorSwatch = styled.div`
  width: 1em;
  height: 0.5em;
  margin-right: 1em;
  display: inline-block;
  background: ${(props: { color: string }) => props.color};
`;

const LegendItem = (props: { color: string; label: string }) => (
  <LegendItemWrapper>
    <ColorSwatch color={props.color} />
    <LegendLabel title={props.label}>{props.label}</LegendLabel>
  </LegendItemWrapper>
);

export const Legend = (props: { categories: string[]; colors?: string[] }) => {
  const colorMap = props.colors || defaultLegendColors;
  return (
    <LegendWrapper>
      {props.categories.map((category, index) => (
        <LegendItem key={category} color={colorMap[index % colorMap.length]} label={category} />
      ))}
    </LegendWrapper>
  );
};
