import * as React from "react";
import { IBaseStep, pauseOnboarding } from "src/redux/onboarding";
import { OnboardingStepWrapper } from "./styles";
import { H2, P } from "src/design-system/Tokens/typography";
import { LocalizedString } from "../Localization";
import { redirectBrowser, getRoutes } from "src/routes";
import { SparePartsCatalogueViewComponent } from "src/views/SparePartsCatalogueView/SparePartsCatalogueViewComponent";

const commonCartSteps = [
  {
    className: "spareparts-onboarding-part-details",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepPartDetails.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepPartDetails.content" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepPartDetails.content2" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepPartDetails.content3" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "spareparts-onboarding-add-parts-to-cart",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepAddPartsToCart.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepAddPartsToCart.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
];

export const onBoardingSparePartsSteps: IBaseStep[] = [
  {
    className: "spareparts-onboarding-your-spareparts",
    isFixed: true,
    disableOverlayClose: true,
    disableBeacon: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepYourSpareParts.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepYourSpareParts.content" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepYourSpareParts.content2" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "spareparts-onboarding-orders-and-quotations",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepOrdersAndQuotations.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepOrdersAndQuotations.content" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepOrdersAndQuotations.content2" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "spareparts-onboarding-new-order-or-quotation",
    disableOverlayClose: true,
    onStepNext() {
      pauseOnboarding();
      redirectBrowser(getRoutes().SparePartsCatalogue);
    },
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepNewOrdersAndQuotations.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepNewOrdersAndQuotations.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "spareparts-onboarding-browse-parts",
    disableOverlayClose: true,
    onStepPrevious(component?: React.Component) {
      const spComponent = component as {} as SparePartsCatalogueViewComponent;
      spComponent.pauseOnboarding();
      redirectBrowser(getRoutes().SpareParts);
    },
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.content" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.content2" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.content3" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  ...commonCartSteps,
  {
    className: "spareparts-onboarding-shopping-cart",
    disableOverlayClose: true,
    onStepNext() {
      redirectBrowser(getRoutes().SpareParts);
    },
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepShoppingCart.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepShoppingCart.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
];

export const onBoardingSparePartsCatalogueSteps: IBaseStep[] = [
  {
    className: "spareparts-onboarding-browse-parts",
    isFixed: true,
    disableOverlayClose: true,
    disableBeacon: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.content" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.content2" />
        </P>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepBrowseParts.content3" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  ...commonCartSteps,
  {
    className: "spareparts-onboarding-shopping-cart",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="sparePartsOnboarding.stepShoppingCart.title" />
        </H2>
        <P>
          <LocalizedString id="sparePartsOnboarding.stepShoppingCart.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
];
