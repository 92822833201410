import * as React from "react";

import { createPortal } from "react-dom";

export const Portal = ({ children }: { children: React.ReactNode }) => {
  const portal = document.getElementById("portal-root");
  const el = document.createElement("div");

  React.useEffect(() => {
    portal?.appendChild(el);
    return () => {
      portal?.removeChild(el);
    };
  }, [el, portal]);

  return createPortal(children, el);
};

const initialState = {
  top: 0,
  left: 0,
  hidden: true,
};

export const PortalTooltip = ({
  children,
  component,
  alignment = "right",
}: {
  alignment?: "right" | "top";
  children: React.ReactElement;
  component: React.ReactElement;
}) => {
  const [state, setState] = React.useState(initialState);

  const onMouseOver = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect();

    if (alignment === "right") {
      setState({
        hidden: false,
        left: rect.left + rect.width,
        top: rect.top + rect.height / 2,
      });
    }

    if (alignment === "top") {
      setState({
        hidden: false,
        top: rect.top - rect.height,
        left: rect.left + rect.width / 2,
      });
    }
  }, []);

  const onMouseOut = React.useCallback(() => {
    setState(initialState);
  }, []);

  return (
    <>
      {React.cloneElement(children, { onMouseOver, onMouseOut })}
      {!state.hidden ? (
        <Portal>
          {React.cloneElement(component, { style: { left: state.left, top: state.top, position: "fixed" } })}
        </Portal>
      ) : null}
    </>
  );
};
