// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_pt_BR: ITranslations = {
  pt_BR: {
    "purchaseStatus.confirmed": "Confirmado",
    "purchaseStatus.draft": "Esboço",
    "purchaseStatus.expired": "Expirado",
    "purchaseStatus.inProcess": "Em processamento",
    "purchaseStatus.ordered": "Pedido",
    "purchaseStatus.quoted": "Cotado",
    "spareParts": "Peças sobressalentes",
    "spareParts.addLineItems": "Adicionar itens de linha",
    "spareParts.addManually": "Adicionar manualmente",
    "spareParts.addMultipleLineItems": "Adicionar vários itens de linha",
    "spareParts.addressSaveFailure": "Erro ao salvar o endereço. Tente novamente.",
    "spareParts.addressSaveSuccess": "Endereço salvo com sucesso",
    "spareParts.addressWillBeInformedLater": "O endereço será informado depois",
    "spareParts.agreeToTermsAndConditionsIsRequired": "Você deve concordar com os termos e condições",
    "spareParts.agreeWith": "Concordo com os",
    "spareParts.allowPartiaDelivery": "Permitir entrega parcial",
    "spareParts.askForQuotation": "Solicitar cotação",
    "spareParts.askForQuote": "Solicitar cotação",
    "spareParts.availability": "Disponibilidade",
    "spareParts.availabilitySubjectDisclamer": "Disponibilidade sujeita a vendas anteriores.",
    "spareParts.basketCreatedBy": "Cesta criada por",
    "spareParts.basketPriceDisclaimer": "O fornecedor cobrará o valor mínimo de fatura de 200€ caso a soma total de seu pedido esteja abaixo do valor equivalente a 200€.",
    "spareParts.basketWillBeDiscarded": "Existem alterações na cesta que não foram salvas. A cesta será esvaziada caso deixe esta página. Tem certeza de que deseja continuar?",
    "spareParts.blockedCustomerMessage": "Infelizmente não é possível continuar. Entre em contato com a Wärtsilä.",
    "spareParts.browseSpareParts": "Explorar peças de reposição",
    "spareParts.chooseProduct": "Selecione o produto...",
    "spareParts.contactInformation": "Informações de contato",
    "spareParts.copy": "Copiar",
    "spareParts.copyItemsPrompt": "Copie os números de peça no formulário abaixo",
    "spareParts.createdBy": "Criado por",
    "spareParts.createfirstOrder": "Criar seu primeiro pedido",
    "spareParts.creationDate": "Data da criação",
    "spareParts.currency": "Moeda",
    "spareParts.deleteBasketDraft": "Excluir rascunho",
    "spareParts.deliveryAddress": "Endereço de entrega",
    "spareParts.deliveryAddressIsFinalDestination": "O endereço de entrega é o destino final",
    "spareParts.deliveryInformation": "Informações de entrega",
    "spareParts.deliveryLocation": "Local de entrega",
    "spareParts.deliveryLocationIsRequired": "É necessário um local de entrega",
    "spareParts.deliveryMode": "Modo de entrega",
    "spareParts.deliveryModeIsRequired": "É necessário um modo de entrega",
    "spareParts.deliveryTerms": "Termos de entrega",
    "spareParts.deliveryTermsIsRequired": "São necessários termos de entrega",
    "spareParts.deliverymode.AirFreight": "Frete aéreo",
    "spareParts.deliverymode.Courier": "Courier",
    "spareParts.deliverymode.SeaFreight": "Frete marítimo",
    "spareParts.deliverymode.ToBeCollected": "A ser coletado",
    "spareParts.deliverymode.Truck": "Caminhão",
    "spareParts.description": "Descrição",
    "spareParts.discard": "Descartar",
    "spareParts.dispatchingInformation": "Informações de envio",
    "spareParts.draft": "Esboço",
    "spareParts.draftDeleteFailure": "Erro ao excluir o rascunho",
    "spareParts.draftDeleteSuccess": "Rascunho da cesta excluído com sucesso",
    "spareParts.draftName": "Nome do rascunho",
    "spareParts.draftNameIsRequired": "O nome do rascunho é obrigatório",
    "spareParts.draftSaveFailure": "Erro ao salvar o rascunho da cesta.",
    "spareParts.draftSaveSuccess": "Rascunho da cesta salvo com sucesso",
    "spareParts.draftUpdateSuccess": "Rascunho da cesta atualizado com sucesso",
    "spareParts.editInformation": "Editar informações",
    "spareParts.emailAddresses": "Endereços de e-mail",
    "spareParts.emptyBasket": "Sua cesta de compras está vazia",
    "spareParts.errorFetchingPDF": "Erro ao buscar detalhes do pdf",
    "spareParts.estimatedWeight": "Peso estimado das peças",
    "spareParts.etaDate": "Data prevista de chegada solicitada",
    "spareParts.etaDateIsRequired": "É necessária a data prevista de chegada",
    "spareParts.export": "Exportar",
    "spareParts.exportAsCSV": "Exportar como CSV",
    "spareParts.exportAsPDF": "Exportar como PDF",
    "spareParts.failedFetchingManualParts": "Erro de rede",
    "spareParts.filterPurchases": "Compras de filtros",
    "spareParts.finalDestination": "Destino final",
    "spareParts.finalDestinationIsDifferent": "O endereço do destino final é diferente",
    "spareParts.freeComment": "Informações adicionais",
    "spareParts.freeCommentPlaceholder": "Por ex. instruções especiais de embalagem, solicitações de documentação, etc.",
    "spareParts.freightCosts": "Custo do frete",
    "spareParts.freightCostsOnlyAddedAtInvoicing": "O custo do frete só será adicionado na fatura",
    "spareParts.generalInformation": "Informações gerais",
    "spareParts.goodsTotal": "Total das mercadorias",
    "spareParts.howToDispatchOrder": "Como deseja que o pedido seja enviado?",
    "spareParts.identificationRequired": "A identificação técnica é obrigatória",
    "spareParts.inAccordanceWithITCode": "Em conformidade com os artigos 1341 e 1342 do Código Civil Italiano.",
    "spareParts.inStock": "EM ESTOQUE",
    "spareParts.isDeliveryAddressFinalDestinationAddress": "O endereço de entrega é o destino final do pedido?",
    "spareParts.lastItemDialogText": "Tem certeza de que deseja remover este item de sua cesta?",
    "spareParts.lastItemDialogTitle": "Removendo item",
    "spareParts.lastModified": "Última modificação",
    "spareParts.leadTime": "Prazo de entrega",
    "spareParts.lineItems": "Itens da linha",
    "spareParts.loadBasketDraft": "Carregar rascunho",
    "spareParts.loadMore": "Carregar mais",
    "spareParts.location": "Local",
    "spareParts.makeAnotherOrder": "Fazer outro pedido",
    "spareParts.makeAnotherQuote": "Fazer outra cotação",
    "spareParts.markings.optional": "Marcações (opcional)",
    "spareParts.minimumPossibleBusinessDateLimit": "A melhor data possível pode ser de 3 dias úteis a partir de agora",
    "spareParts.nextStep": "Próximo passo",
    "spareParts.noCatalogueFound": "Desculpe - o catálogo de produtos ainda não está disponível para",
    "spareParts.noDescription": "Nenhuma descrição disponível",
    "spareParts.noFilteredPartFound": "Desculpe - não foi encontrada peça para",
    "spareParts.noItemsAvailable": "Nenhum item disponível",
    "spareParts.noPDFinSAP": "O PDF não está disponível, entre em contato com a Equipe de peças de reposição.",
    "spareParts.noPicturesFound": "Não foram encontradas imagens",
    "spareParts.noQuotationsOrOrders": "Você ainda não criou qualquer cotação ou pedido.",
    "spareParts.noSalesDocumentsFound": "Não foram encontrados documentos de venda",
    "spareParts.notOfficialQuotationDisclaimer": "Não é uma cotação oficial. Os preços estão sujeitos aos preços da lista global de acordo com a lista de preços na data da compra. Disponibilidade sujeita a vendas anteriores.",
    "spareParts.on": "Em",
    "spareParts.onlyAllowCompleteOrder": "Só é permitido o pedido completo",
    "spareParts.onlyNumberOrNameRequired": "Nesta tabela é necessário apenas o número da peça de reposição ou o nome da peça. A tela abaixo contém opções para adicionar mais detalhes sobre a peça.",
    "spareParts.order": "Pedido",
    "spareParts.orderConfirmed": "Seu pedido foi confirmado",
    "spareParts.orderDetails": "Detalhes do pedido",
    "spareParts.orderFullDispatch": "Seu pedido foi despachado totalmente",
    "spareParts.orderNew": "Foi criado um novo pedido",
    "spareParts.orderPartialDispatch": "Seu pedido foi despachado parcialmente",
    "spareParts.orderReceived": "Pedido recebido",
    "spareParts.partDescription": "Descrição de peças",
    "spareParts.partDescriptionIsRequired": "A descrição da peça é obrigatória",
    "spareParts.partNameOrNumber": "Nome/número da peça",
    "spareParts.partNotFound": "A peça não foi encontrada. Especifique mais informações.",
    "spareParts.partNumber": "Número da peça",
    "spareParts.partOrder.pleaseNoteMayTakeUpMinutes": "Poderá demorar alguns minutos para que seu pedido apareça no item Minhas peças de reposição.",
    "spareParts.partOrder.useReferenceNumber": "Você pode usar sua referência para encontrar o pedido em Minhas peças de reposição.",
    "spareParts.partOrder.weHaveReceived": "Recebemos seu pedido.",
    "spareParts.partOrder.weWillGetBackToYouOrder": "Entraremos em contato assim que seu pedido for processado.",
    "spareParts.partWeight": "Peso",
    "spareParts.partWeightUnit": "Unidade de peso",
    "spareParts.pictures": "Imagens",
    "spareParts.price": "Preço",
    "spareParts.priceNoVatDisclaimer": "Os preços não incluem o VAT, que será adicionado com base na legislação aplicável do VAT no momento do faturamento.",
    "spareParts.product": "Produto",
    "spareParts.productId": "Id do produto",
    "spareParts.productIsNotSelected": "O produto não está selecionado",
    "spareParts.purchases": "Compras",
    "spareParts.qtySection": "Quantidade nessa seção",
    "spareParts.quantity": "Quantidade",
    "spareParts.quantityUnit": "Unidade de quantidade",
    "spareParts.quotation": "Cotação",
    "spareParts.quotationCannotBeCopied": "Uma cotação com peças desconhecidas não pode ser copiada",
    "spareParts.quotationCreated": "Foi criada uma nova cotação",
    "spareParts.quotationQuoted": "Os preços da sua cotação foram confirmados",
    "spareParts.quotationReceived": "Cotação recebida",
    "spareParts.quotationRequest.pleaseNoteMayTakeUpMinutes": "Poderá demorar alguns minutos para que sua cotação apareça no item Minhas peças de reposição.",
    "spareParts.quotationRequest.useReferenceNumber": "Você pode usar sua referência para encontrar a cotação em Minhas peças de reposição.",
    "spareParts.quotationRequest.weHaveReceived": "Recebemos sua cotação.",
    "spareParts.quotationRequest.weWillGetBackToYouQuotation": "Entraremos em contato assim que sua cotação for processada.",
    "spareParts.quoteDetails": "Detalhes da cotação",
    "spareParts.referenceType": "Tipo de referência",
    "spareParts.requestPDF": "Solicitar PDF",
    "spareParts.rows": "Filas",
    "spareParts.saveAddress": "Salvar endereço",
    "spareParts.saveInformation": "Salvar informações",
    "spareParts.saveProgressForLater": "Você deseja salvar seu trabalho para mais tarde?",
    "spareParts.selectProduct": "Selecione o produto",
    "spareParts.serialNumber.optional": "Número de série (opcional)",
    "spareParts.serviceGridButtonTitle": "Comércio",
    "spareParts.shoppingCart": "Carrinho de compras",
    "spareParts.shoppingCartEmpty": "Carrinho de compras vazio",
    "spareParts.sizeAndDimensions.optional": "Tamanho e dimensões (opcional)",
    "spareParts.sorting.purchaseTypeAZ": "Tipo de compra (A-Z)",
    "spareParts.specificClauses": "Cláusulas específicas",
    "spareParts.subtotal": "Subtotal",
    "spareParts.summary": "Resumo",
    "spareParts.termsAndConditions": "Termos e condições",
    "spareParts.termsConditionsDisclaimerPDF": "Todas as vendas de peças deverão estar sujeitas aos Termos e condições gerais - peças (2021). Caso o valor a ser faturado pelo fornecedor esteja abaixo ao equivalente a 200 EUR, o fornecedor deverá cobrar a mais e o valor mínimo a ser faturado deverá ser 200 EUR ou valor equivalente na moeda aplicável adicionado à taxa dos custos de transporte, caso exista.",
    "spareParts.termsDisclaimer": "Caso tenha assinado um Contrato de fornecimento de peças de reposição com a Wärtsilä que esteja em vigor quando o pedido de compra tenha sido feito, serão aplicados os termos e condições desse contrato.",
    "spareParts.thankYou": "Obrigado",
    "spareParts.thereWasErrorWhenSendingOrder": "Ocorreu um erro ao enviar seu pedido.",
    "spareParts.thereWasErrorWhenSendingQuotation": "Ocorreu um erro ao enviar sua cotação.",
    "spareParts.total": "Total",
    "spareParts.totalSum": "Soma total",
    "spareParts.typeInEmails": "Digite os endereços de e-mail",
    "spareParts.typePartNameOrNumber": "Digite o nome ou o número da peça",
    "spareParts.unsavedChanges": "As alterações não foram salvas",
    "spareParts.usageTarget.optional": "Uso/sistema/unidade (opcional)",
    "spareParts.vatDisclaimer": "Os preços não incluem o VAT, que será adicionado com base na legislação aplicável do VAT no momento do faturamento.",
    "spareParts.vendorCode.optional": "Código de peça do fornecedor (opcional)",
    "spareParts.wartsilaReference": "Referência Wärtsilä",
    "spareParts.youCanAddPartsUsingFollowingAction": "Também é possível adicionar peças de reposição usando a seguinte ação",
    "spareParts.yourReference": "Sua referência",
    "spareParts.yourReferenceIsRequired": "É necessária sua referência",
    "spareparts.errorFetchingDetails": "Erro ao buscar detalhes do pedido",
  },
};
