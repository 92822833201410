// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const onboarding_en_US: ITranslations = {
  en_US: {
    "documentsOnboarding.stepFive.content": "View document information and see details to what equipment the document applies to.",
    "documentsOnboarding.stepFive.title": "Document information",
    "documentsOnboarding.stepFour.content": "Easy access to preview by clicking the document pages.",
    "documentsOnboarding.stepFour.content2": "Used search words will be highlighted in the preview.",
    "documentsOnboarding.stepFour.title": "Preview",
    "documentsOnboarding.stepOne.content": "View, search, filter and sort all your documents easily in one page.",
    "documentsOnboarding.stepOne.title": "Your Documents",
    "documentsOnboarding.stepThree.content": "Sort your view to have most relevant documents on top.",
    "documentsOnboarding.stepThree.title": "Sort",
    "documentsOnboarding.stepTwo.content": "Use different search values to find what you need.",
    "documentsOnboarding.stepTwo.content2": "You can also filter your search with document types.",
    "documentsOnboarding.stepTwo.title": "Search and Filter",
    "mainOnboarding.stepFive.content": "Thank you for taking the tour with us. If you have any questions or need help our Customer Support is always here to help you. We hope you enjoy the new environment.",
    "mainOnboarding.stepFive.title": "You are ready to start!",
    "mainOnboarding.stepFour.content": "All available services are listed below.",
    "mainOnboarding.stepFour.title": "Your services",
    "mainOnboarding.stepOne.content": "We are going to walk you through the basic features with a quick tour. This will take less than a minute.",
    "mainOnboarding.stepOne.title": "Welcome to your new Wärtsilä Online experience!",
    "mainOnboarding.stepThree.content": "All the installations you have chosen to see from your profile are easily available in your front page. By clicking a specific installation you can find products related to it.",
    "mainOnboarding.stepThree.content:energy": "All the power plants you have chosen to see from your profile are easily available in your front page. By clicking a specific power plant you can find products related to it.",
    "mainOnboarding.stepThree.content:marine": "All the vessels you have chosen to see from your profile are easily available in your front page. By clicking a specific vessel you can find products related to it.",
    "mainOnboarding.stepThree.title": "Your installations",
    "mainOnboarding.stepThree.title:energy": "Your power plants",
    "mainOnboarding.stepThree.title:marine": "Your vessels",
    "mainOnboarding.stepTwo.content": "From the profile you can choose which installations you want to see in your front page, update your basic information, and digest email settings.",
    "mainOnboarding.stepTwo.content2": "You can always repeat this quick tour from your profile.",
    "mainOnboarding.stepTwo.content:energy": "From the profile you can choose which power plants you want to see in your front page, update your basic information, and digest email settings.",
    "mainOnboarding.stepTwo.content:marine": "From the profile you can choose which vessels you want to see in your front page, update your basic information, and digest email settings.",
    "mainOnboarding.stepTwo.title": "Your profile",
    "sparePartsOnboarding.stepAddPartsToCart.content": "By clicking the + and – buttons or by typing in quantity you can add or remove items to the cart and see the subtotal.",
    "sparePartsOnboarding.stepAddPartsToCart.title": "Add parts to shopping cart",
    "sparePartsOnboarding.stepBrowseParts.content": "Select a product and browse spare parts.",
    "sparePartsOnboarding.stepBrowseParts.content2": "You can filter spare parts with a part name or number.",
    "sparePartsOnboarding.stepBrowseParts.content3": "You can also add spare parts manually with multiple line items at once.",
    "sparePartsOnboarding.stepBrowseParts.title": "Browse Spare Parts",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content": "Place your order or create a quotation. Select an installation and start browsing your spare parts.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:energy": "Place your order or create a quotation. Select a power plant and start browsing your spare parts.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:marine": "Place your order or create a quotation. Select a vessel and start browsing your spare parts.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.title": "New Order or Quotation",
    "sparePartsOnboarding.stepOrdersAndQuotations.content": "View statuses and details of your orders and quotations.",
    "sparePartsOnboarding.stepOrdersAndQuotations.content2": "From this view, you can easily place an order from a quotation.",
    "sparePartsOnboarding.stepOrdersAndQuotations.title": "Orders and Quotations",
    "sparePartsOnboarding.stepPartDetails.content": "Expand the view to see spare part details.",
    "sparePartsOnboarding.stepPartDetails.content2": "From the picture, you can see expanded drawing of the spare part showing more details.",
    "sparePartsOnboarding.stepPartDetails.content3": "You will also find price, weight, and lead time for the part.",
    "sparePartsOnboarding.stepPartDetails.title": "Spare Parts details",
    "sparePartsOnboarding.stepShoppingCart.content": "You can view selected spare parts and the total price in your shopping cart.",
    "sparePartsOnboarding.stepShoppingCart.title": "Shopping cart",
    "sparePartsOnboarding.stepYourSpareParts.content": "Overview of all your orders and quotations easily on one page. You can filter your view and see the full history.",
    "sparePartsOnboarding.stepYourSpareParts.content2": "Browse spare parts, create new orders or quotations, or create a spare part claim.",
    "sparePartsOnboarding.stepYourSpareParts.title": "The Commerce page",
    "supportOnboarding.stepFive.content": "Anyone from your organization can add comments related to the request and be in contact with Wärtsilä.",
    "supportOnboarding.stepFive.title": "Comments",
    "supportOnboarding.stepFour.content": "Attachments can be added the whole time the request is in progress.",
    "supportOnboarding.stepFour.title": "Attachments",
    "supportOnboarding.stepOne.content": "Overview of all your requests easily on one page. You can filter or sort your view and see request statuses and the full history.",
    "supportOnboarding.stepOne.title": "Your Support page",
    "supportOnboarding.stepSeven.content": "To get all your requests in one document, you can easily export those.",
    "supportOnboarding.stepSeven.title": "Export requests",
    "supportOnboarding.stepSix.content": "When a request has been completed, you can rate it and give feedback, or re-open the request.",
    "supportOnboarding.stepSix.title": "Rate the service",
    "supportOnboarding.stepThree.content": "Expand to see the request details and provide additional information",
    "supportOnboarding.stepThree.title": "Your requests",
    "supportOnboarding.stepTwo.content": "Create all your requests in one place:",
    "supportOnboarding.stepTwo.title": "Create new request",
  },
};
