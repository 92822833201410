import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const TriangleWarningIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props} viewBox="0 0 48 48">
    <title>triangle warning</title>
    <path d="M22 22h4v14h-4z"></path>
    <circle cx="24" cy="40" r="2"></circle>
    <path d="M24 7 4 42h16.56a3.85 3.85 0 0 1 0-4h-9.77L24 14.92 37.21 38h-9.77a3.85 3.85 0 0 1 0 4H44Z"></path>
    <circle cx="24" cy="40" r="2"></circle>
  </IconBase>
);
