import * as React from "react";
import { Link } from "react-router-dom";
import { getPathWithParams, getRoutes } from "src/routes";
import styled from "styled-components";

import logo from "./quantiparts_logo_color.png";

const Img = styled.img`
  width: 130px;
  margin-top: 1em;
  margin-left: 10px;
`;

export const QuantiPartsLogo: React.FC = () => (
  <Link to={getPathWithParams(getRoutes().Index)}>
    <Img src={logo} alt="QuantiParts logo" />
  </Link>
);
