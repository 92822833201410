import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAppState } from "src/redux";
import { resetCurrentUser } from "src/redux/actions";
import {
  IWOViewSelectorDispatchProps,
  IWOViewSelectorStateProps,
  WOViewSelectorComponent,
} from "./WOViewSelectorComponent";

const mapStateToProps = (state: IAppState): IWOViewSelectorStateProps => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch): IWOViewSelectorDispatchProps =>
  bindActionCreators(
    {
      resetCurrentUser,
    },
    dispatch
  );

const WOViewSelectorContainer = connect<IWOViewSelectorStateProps, IWOViewSelectorDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(WOViewSelectorComponent);
export { WOViewSelectorContainer };
