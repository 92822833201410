import * as React from "react";

import { defaultBorderRadius, spacer } from "src/design-system/Tokens/tokens";

import { Details } from "src/design-system/Tokens/typography";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";

export const TrafficLight = styled.div`
  border-radius: 9px;
  min-width: 11px;
  height: 18px;
  line-height: 18px;
  padding: 0 4px 0 3px;
  color: ${colors.primary.white};
  background-color: ${colors.primary.gray};
  text-align: center;
  letter-spacing: -1px;

  &.red {
    background-color: ${colors.notification.error};
  }
  &.yellow {
    background-color: ${colors.notification.warning};
  }
  &.green {
    background-color: ${colors.notification.ok};
  }
  &.none {
    display: none;
  }
`;

interface ITrafficLightWithLabelProps {
  label: string;
  color?: string;
  bold?: boolean;
  info?: string;
  children?: React.ReactNode;
}

interface ITrafficLightWithLabelState {
  tooltipOpen: boolean;
}

const AlignmentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacer(1)};
`;

const WithLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LabelWrapper = styled.div`
  margin-left: ${spacer(1)};
  max-width: 182px;
`;

const InfoCircle = styled.div`
  color: ${colors.primary.white};
  background-color: ${colors.primary.blue};
  width: 20px;
  height: 20px;
  margin-left: ${spacer(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const InfoBox = styled.div`
  position: relative;
  z-index: 2;
  bottom: 5px;
  left: -10px;
  border-radius: ${defaultBorderRadius};
  padding: 5px;
  color: ${colors.primary.white};
  background-color: ${colors.primary.blue};
`;

export class TrafficLightWithLabel extends React.Component<ITrafficLightWithLabelProps, ITrafficLightWithLabelState> {
  constructor(props: ITrafficLightWithLabelProps) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };

    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
  }

  public handleEnter() {
    this.setState({ tooltipOpen: true });
  }

  public handleLeave() {
    this.setState({ tooltipOpen: false });
  }
  public render() {
    return (
      !!this.props.color && (
        <>
          {!!this.props.info && (
            <InfoBox style={{ backgroundColor: this.state.tooltipOpen ? colors.primary.blue : "transparent" }}>
              <Details>{this.state.tooltipOpen && this.props.info}</Details>
            </InfoBox>
          )}
          <AlignmentWrapper>
            <WithLabelWrapper>
              <TrafficLight className={this.props.color} />
              <LabelWrapper>
                <Details style={{ fontWeight: this.props.bold ? "bold" : "normal" }}>{this.props.label}</Details>
              </LabelWrapper>
              {!!this.props.info && (
                <InfoCircle onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
                  <Details style={{ fontWeight: "bold" }}>i</Details>
                </InfoCircle>
              )}
            </WithLabelWrapper>
            {this.props.children}
          </AlignmentWrapper>
        </>
      )
    );
  }
}
