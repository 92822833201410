import * as React from "react";

import { Button, ButtonStyle } from "src/design-system/Button/Button";
import { Input, InputLabel } from "src/design-system/TextInput";

import { Container } from "src/design-system/Container";
import { ErrorComponent } from "src/design-system/Error";
import { FieldProps } from "formik";
import { FlexContainer } from "src/design-system/Container";
import { IconSize } from "src/design-system/Tokens/icons";
import { SearchIcon } from "src/icons";
import colors from "../Tokens/colors";
import { getObjectProperty } from "src/util/helpers";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";

export const CustomStyledTextInput = styled(Input)`
  width: 100%;
  padding-right: ${spacer(6)};

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 0.85em;
    width: 0.85em;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTAgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBzdHJva2U9Im5vbmUiPgogICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMjk5MjUwLCAwLjgwMDI1MCkiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik05LjA0ODc1LDEwLjA0ODc1IEM4LjU3OTc1LDEwLjUxNzc1IDcuODE5NzUsMTAuNTE3NzUgNy4zNTE3NSwxMC4wNDg3NSBMNC43MDA3NSw3LjAxODc1IEwyLjA0OTc1LDEwLjA0Nzc1IEMxLjU4MDc1LDEwLjUxNjc1IDAuODIwNzUsMTAuNTE2NzUgMC4zNTI3NSwxMC4wNDc3NSBDLTAuMTE2MjUsOS41Nzg3NSAtMC4xMTYyNSw4LjgxODc1IDAuMzUyNzUsOC4zNTA3NSBMMy4xMTA3NSw1LjIwMDc1IEwwLjM1MTc1LDIuMDQ4NzUgQy0wLjExNzI1LDEuNTc5NzUgLTAuMTE3MjUsMC44MjA3NSAwLjM1MTc1LDAuMzUxNzUgQzAuODIwNzUsLTAuMTE3MjUgMS41Nzk3NSwtMC4xMTcyNSAyLjA0ODc1LDAuMzUxNzUgTDQuNzAwNzUsMy4zODI3NSBMNy4zNTE3NSwwLjM1MTc1IEM3LjgyMDc1LC0wLjExNzI1IDguNTc5NzUsLTAuMTE3MjUgOS4wNDg3NSwwLjM1MTc1IEM5LjUxNzc1LDAuODIwNzUgOS41MTc3NSwxLjU4MDc1IDkuMDQ4NzUsMi4wNDg3NSBMNi4yOTA3NSw1LjIwMDc1IEw5LjA0ODc1LDguMzUwNzUgQzkuNTE3NzUsOC44MTk3NSA5LjUxNzc1LDkuNTc5NzUgOS4wNDg3NSwxMC4wNDg3NSBMOS4wNDg3NSwxMC4wNDg3NSBaIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=)
      no-repeat 50% 50%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
  }

  :focus::-webkit-search-cancel-button {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
`;

export const SearchButton = styled(Button)`
  margin-left: -${spacer(6)};

  svg {
    fill: ${colors.primary.black};
  }
`;

interface ISearchInputProps extends FieldProps {
  label: string;
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  width?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchInput: React.FC<ISearchInputProps> = ({
  form,
  field,
  label,
  placeholder,
  width,
  onSubmit,
  onChange,
}) => {
  const fieldTouched = getObjectProperty(form.touched, field.name);
  const fieldErrors = getObjectProperty(form.errors, field.name);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement> & React.ChangeEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(e);
    }
  };

  return (
    <Container>
      <InputLabel>{label}</InputLabel>
      <FlexContainer $column={true} $marginBottom={2}>
        <FlexContainer $centered={true}>
          <CustomStyledTextInput
            type="search"
            placeholder={placeholder}
            $width={width}
            onKeyPress={onKeyPress}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e);

              // Catching the click on the X
              if (!e.target.value) onSubmit(e);
            }}
          />
          <SearchButton
            onClick={() =>
              onSubmit({
                target: {
                  value: field.value,
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }
            buttonStyle={ButtonStyle.Icon}
            icon={<SearchIcon size={IconSize.Small} />}
          />
        </FlexContainer>
        {fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
      </FlexContainer>
    </Container>
  );
};
