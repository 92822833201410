import { IAccountUser } from "online-services-types/index";
import * as React from "react";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { Button, ButtonIconPlacement, ButtonStyle, ButtonType } from "src/design-system/Button/Button";
import { SwitchIcon } from "src/icons";
import { IUserState } from "src/redux/user";
import { getRoutes, redirectBrowser } from "src/routes";
import { translateString } from "src/util/localization";
import { getUserProfile } from "src/views/ProfileView/userProfile";

export interface IWOViewSelectorStateProps {
  userInfo: IUserState;
}

export interface IWOViewSelectorDispatchProps {
  resetCurrentUser(user: IAccountUser, userInfo: IUserState): void;
}

export interface IWOViewSelectorState {
  user: IAccountUser | null;
}

type IWOViewSelectorProps = IWOViewSelectorStateProps & IWOViewSelectorDispatchProps;

export class WOViewSelectorComponent extends React.Component<IWOViewSelectorProps, IWOViewSelectorState> {
  constructor(props: IWOViewSelectorProps) {
    super(props);
    this.state = {
      user: null,
    };
    this.onViewChange = this.onViewChange.bind(this);
  }

  public async componentDidMount() {
    const currentUser = await getUserProfile();
    this.setState({ user: currentUser });
  }

  public render() {
    const isQPDistributorViewDisabled = this.props.userInfo?.isQPDistributorViewDisabled;
    if (this.props.userInfo.isQPAndLifecycleUser) {
      if (this.state.user == null) {
        return <LoadingSpinner size={spinnerSize.sm} />;
      } else {
        return (
          <Button
            buttonType={ButtonType.Outline}
            buttonStyle={ButtonStyle.Transparent}
            iconPlacement={ButtonIconPlacement.Before}
            icon={<SwitchIcon />}
            onClick={() => this.onViewChange()}
          >
            {isQPDistributorViewDisabled ? translateString("switchToQP") : translateString("switchToWO")}
          </Button>
        );
      }
    } else {
      return null;
    }
  }

  // if user is in distributor view - put normal on and vice versa
  private readonly onViewChange = async () => {
    if (this.state.user) {
      const newTheme =
        this.state.user.theme.toLowerCase() === "dark" || this.state.user.theme.toLowerCase() === "light"
          ? "quantiparts"
          : "dark";

      const updatedUser = {
        ...this.state.user,
        isQPDistributorViewDisabled: !this.state.user.isQPDistributorViewDisabled,
        theme: newTheme,
      };
      const updatedUserInfo = {
        ...this.props.userInfo,
        isQPDistributorViewDisabled: !this.state.user.isQPDistributorViewDisabled,
        theme: newTheme,
      };

      this.props.resetCurrentUser(updatedUser, updatedUserInfo);
      this.setState({ user: updatedUser });
    }
    redirectBrowser(getRoutes().Index);
  };
}
