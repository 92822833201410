import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const BasketIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Basket</title>
    <polygon points="38.93 11 14.79 11 13.76 7 13.75 7 12.02 7 10.93 7 8.93 7 5.07 7 6.1 11 8.93 11 10.66 11 16.82 34.83 16.83 34.83 16.88 35.01 18.96 35.01 20.96 35.01 24.82 35.01 24.82 35 24.93 35 25.24 35 27.93 35 28.07 35 30.07 35 31.93 35 33.93 35 33.93 31 32.9 31 31.93 31 30.07 31 28.07 31 27.93 31 24.93 31 24.2 31 19.97 31 15.82 15 38.93 15 38.93 23 36.93 23 35.93 23 20.07 23 21.1 27 35.93 27 36.93 27 38.93 27 40.93 27 42.93 27 42.93 15 42.93 11 38.93 11" />
    <rect x="17.93" y="37" width="4" height="4" />
    <rect x="29.93" y="37" width="8" height="4" />
  </IconBase>
);
