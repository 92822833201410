import * as React from "react";

import { IFluidReport, IFluidReportRules, IInstallation, IInstallationContract, Service } from "online-services-types";
import { IconSize, MachineLubricantIcon } from "src/icons";
import {
  PageSection,
  SummaryDate,
  SummaryItemWrapper,
  SummaryLabel,
  SummaryNumber,
  SummaryText,
} from "src/design-system/Container";
import { Sector, translateString } from "src/util/localization";
import { getPathWithParams, getRoutes } from "src/routes";

import { Button } from "src/design-system/Button";
import { ButtonIconPlacement } from "src/design-system/Button/Button";
import { Container } from "src/design-system/Container";
import { Heading } from "src/components/Heading";
import { IAPIResource } from "src/APIFetch";
import { ILinkPassedData } from "src/design-system/NavigationButton/NavigationButton";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import colors from "src/design-system/Tokens/colors";
import { getSelectedColorTheme } from "src/util/localstorage";
import moment from "moment";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";
import { themes } from "src/design-system/Theme/theme";
import { whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";

export interface IInstallationDetailsComponentStateProps {
  installationContracts: IInstallationContract[];
  installations: IInstallation[];
  isReady: boolean;
  selectedInstallationId?: string;
  isContractsEnabled: boolean;
  isFluidReportingEnabled: boolean;
  fluidReportDrafts: IFluidReport[];
  isFluidDraftsReady: boolean;
  services: ReadonlyArray<Service>;
  fluidReportRules: IAPIResource<IFluidReportRules>;
  isQuantipartsDistributor: boolean;
}

export interface IInstallationDetailsComponentDispatchProps {
  getFluidReportRules(): void;
  getFluidReportDrafts(): void;
  getInstallationContracts(selectedInstallationId?: string): void;
}

interface ISummaryItemProps {
  label: string;
  textValue?: string;
  dateValue?: Date | string;
  numberValue?: number;
}

type IInstallationDetailsComponentProps = IInstallationDetailsComponentStateProps &
  IInstallationDetailsComponentDispatchProps;

const SummaryItem: React.FC<ISummaryItemProps> = ({ label, textValue, dateValue, numberValue }) => (
  <SummaryItemWrapper>
    <SummaryLabel>{label}</SummaryLabel>
    {textValue && <SummaryText>{textValue}</SummaryText>}
    {dateValue && <SummaryDate>{moment(dateValue).format("ll")}</SummaryDate>}
    {numberValue && <SummaryNumber>{numberValue}</SummaryNumber>}
  </SummaryItemWrapper>
);

const ButtonLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const ButtonWrapper = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;
  margin-bottom: 1em;
  width: 100%;
  min-height: 75px;

  ${whenTabletOrLarger("max-width: 320px;")}
`;

export const DetailColumn = styled(Container)`
  ${(props: { flex: number }) => `flex: ${props.flex};`}
  margin-right: ${spacer(3)};
  &:last-child {
    margin-right: 0;
  }
`;

export const DetailsWrapper = styled(Container)`
  ${whenTabletOrLarger(`
    display: flex;
  `)}
`;

const ReportLinkWrapper = styled.div`
  width: 300px;
`;

export const ReportLink = (props: { to: string | ILinkPassedData; label: string; withoutIcon?: boolean }) => {
  const theme = getSelectedColorTheme(); //TODO: get name from context

  return (
    <ReportLinkWrapper>
      <ButtonLink to={props.to}>
        <ButtonWrapper
          style={
            theme === "light"
              ? { color: themes.light.primary2, background: themes.light.navigationButton.background }
              : { background: colors.secondary.bluegray100, borderColor: colors.primary.white }
          }
          icon={props.withoutIcon ? undefined : <MachineLubricantIcon size={IconSize.Large} />}
          iconPlacement={props.withoutIcon ? undefined : ButtonIconPlacement.Before}
        >
          {props.label}
        </ButtonWrapper>
      </ButtonLink>
    </ReportLinkWrapper>
  );
};
export class InstallationDetailsComponent extends React.Component<IInstallationDetailsComponentProps> {
  public componentDidMount() {
    this.props.getInstallationContracts(this.props.selectedInstallationId);
    if (this.props.isFluidReportingEnabled && !this.props.isQuantipartsDistributor) {
      this.props.getFluidReportDrafts();
      if (!this.props.fluidReportRules.data?.length) {
        this.props.getFluidReportRules();
      }
    }
  }

  public getHasNoFluidReportDrafts = () => {
    return this.props.isFluidDraftsReady && this.props.fluidReportDrafts.length === 0;
  };

  public render() {
    const routes = getRoutes();

    const selectedInstallation = this.props.installations.find(
      (installation) => installation.id === this.props.selectedInstallationId
    );

    if (!selectedInstallation) {
      return null;
    }

    let showNewFluidReport = false;
    if (this.props.fluidReportRules.data && this.props.selectedInstallationId) {
      for (const fluidRule of this.props.fluidReportRules.data) {
        if (fluidRule.installations.includes(this.props.selectedInstallationId)) {
          showNewFluidReport = true;
        }
      }
    }

    const numberOfContracts = this.props.installationContracts?.length;
    const newFluidReportRoute = this.getHasNoFluidReportDrafts() ? routes.CreateFluidReport : routes.FluidReportDraft;

    const containsSFOCContract = this.props.installationContracts?.some((ic) => ic.isSFOCContract);

    return (
      <PageSection>
        <Heading text={translateString("index.installationDetails")} />
        {this.props.isReady ? (
          <DetailsWrapper>
            <DetailColumn flex={1}>
              <Container $margin={[3, 0, 0, 0]}>
                {newFluidReportRoute.isEnabled(this.props.services) && showNewFluidReport && (
                  <ReportLink
                    to={getPathWithParams(newFluidReportRoute)}
                    label={translateString("routes.newFluidReport")}
                  />
                )}
                {routes.NewSFOCReport.isEnabled(this.props.services) && containsSFOCContract && (
                  <ReportLink
                    to={{
                      pathname: getPathWithParams(routes.NewSFOCReport),
                      state: { selectedInstallationId: this.props.selectedInstallationId },
                    }}
                    label={translateString("routes.newSFOCReport")}
                  />
                )}
              </Container>
            </DetailColumn>
            <DetailColumn flex={1}>
              {selectedInstallation.applicationCategory === Sector.Marine && (
                <>
                  <SummaryItem label={translateString("installation.imo")} textValue={selectedInstallation.imoNumber} />
                  <SummaryItem
                    label={translateString("installation.classificationSociety")}
                    textValue={selectedInstallation.classificationSocietyName}
                  />
                </>
              )}

              {selectedInstallation.applicationCategory === Sector.Energy && !this.props.isQuantipartsDistributor && (
                <>
                  <SummaryItem
                    label={`${translateString("installation.nextPlannedMaintenance")} / ${translateString(
                      "installation.inspectionDate"
                    )}`}
                    dateValue={selectedInstallation.nextPlannedMaintenance}
                  />
                </>
              )}
              <SummaryItem label={translateString("installation.type")} textValue={selectedInstallation.type} />
            </DetailColumn>
            <DetailColumn flex={1}>
              <SummaryItem
                label={translateString("installation.deliveryDate")}
                dateValue={selectedInstallation.commissioningDate}
              />
              <SummaryItem label={translateString("status")} textValue={selectedInstallation.statusName} />
              {this.props.isContractsEnabled && numberOfContracts > 0 && (
                <>
                  <SummaryItem label={translateString("routes.contracts")} numberValue={numberOfContracts} />
                  <ButtonLink to={getPathWithParams(routes.Contract)}>
                    <Button>
                      <LocalizedString id="viewContracts" />
                    </Button>
                  </ButtonLink>
                </>
              )}
            </DetailColumn>
          </DetailsWrapper>
        ) : (
          <LoadingSpinner disableText />
        )}
      </PageSection>
    );
  }
}
