import { IError, IResource } from "online-services-types";
import { AnyAction, Dispatch } from "redux";
import { APIFetchStatus, IAPICallParams, IAPIResource } from "src/APIFetch";
import { APIAbortHandle } from "src/redux/APIAbortHandle";
import { APIResource, APIResourceErrorDisplay } from "src/redux/APIResource";
import { displayError } from "src/util/error";
import { action } from "typesafe-actions";
import { isArray } from "util";

interface IRestPayload<T> {
  data: T | T[];
  params?: IAPICallParams;
}

interface IRESTAction<T> extends AnyAction {
  type: string;
  id?: string;
  payload?: IRestPayload<T>;
  errorMessage?: string;
}

export class ParamsAPIResource<T extends IResource> extends APIResource<T> {
  public readonly doneWithParamsAction: (params: IAPICallParams, result: Array<Partial<T>> | T) => IRESTAction<T>;
  public readonly clearAction: () => IRESTAction<{}>;
  private readonly appendNewItems: boolean;

  constructor(
    resourceName: string,
    childResourceName?: string,
    enableCache = false,
    appendNewItems = false,
    displayErrors: APIResourceErrorDisplay = APIResourceErrorDisplay.Display
  ) {
    super(resourceName, childResourceName, enableCache, displayErrors);

    this.appendNewItems = appendNewItems;
    this.doneWithParamsAction = (params: IAPICallParams, result: T): IRESTAction<T> =>
      action(`API_RESOURCE_${this.resourceCombo}_SUCCESS_WITH_PARAMS`, { data: result, params });
    this.clearAction = () => action(`API_RESOURCE_${this.resourceCombo}_CLEAR`);
  }

  public handleSuccessWithParams(state: ParamsAPIResource<T>, action: IRESTAction<T>): ParamsAPIResource<T> {
    state.status = APIFetchStatus.Success;
    if (action.payload) {
      if (this.appendNewItems && isArray(action.payload.data) && state.data) {
        // Append and filter out diplicates
        for (const el of action.payload.data) {
          state.data = state.data.filter((stateEl) => stateEl.id !== el.id);
        }
        state.data = [...state.data, ...action.payload.data];
      } else {
        state.data = action.payload.data as T[];
      }
    }
    state.errorMessage = undefined;
    return state;
  }

  public getDefaultState(previousState: ParamsAPIResource<T>) {
    return Object.assign({}, previousState) || { status: APIFetchStatus.Idle, data: undefined };
  }

  public createReducer(stateKey: string): (state: ParamsAPIResource<T>, action: IRESTAction<T>) => IAPIResource<T> {
    this.stateKey = stateKey;
    const superReducer = super.createReducer(stateKey);
    return (previousState: ParamsAPIResource<T>, action: IRESTAction<T>): IAPIResource<T> => {
      const state = this.getDefaultState(previousState);

      if (action.type === `API_RESOURCE_${this.resourceCombo}_SUCCESS_WITH_PARAMS`) {
        return this.handleSuccessWithParams(state, action);
      }
      if (action.type === `API_RESOURCE_${this.resourceCombo}_CLEAR`) {
        state.data = [];
        return state;
      }

      return superReducer(state, action);
    };
  }

  public getWithParams = (params: IAPICallParams, abortHandle?: APIAbortHandle, callback?: (result?: any) => void) => {
    return async (dispatch: Dispatch) => {
      dispatch(this.getAction());
      try {
        const result = await this.fetcher.getWithParams(params);
        if (abortHandle && abortHandle.isAborted()) {
          return;
        }
        dispatch(this.doneWithParamsAction(params, result));
        if (callback) {
          callback(result);
        }
      } catch (error) {
        displayError(error);
        dispatch(this.errorAction((error as IError).message));
      }
    };
  };
  public clear = () => {
    return async (dispatch: Dispatch) => {
      dispatch(this.clearAction());
    };
  };
}
