import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const VisibleIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Visible</title>
    <path d="M44,23.4A20.67,20.67,0,0,0,24.1,9,20.67,20.67,0,0,0,4.19,23.4L4,24l.18.6A20.67,20.67,0,0,0,24.1,39,20.67,20.67,0,0,0,44,24.6l.19-.6ZM24.1,35A16.69,16.69,0,0,1,8.21,24,16.69,16.69,0,0,1,24.1,13,16.68,16.68,0,0,1,40,24,16.68,16.68,0,0,1,24.1,35Zm-9-13a9,9,0,1,0,9-9A9,9,0,0,0,15.1,22Zm9-5a5,5,0,1,1-5,5A5,5,0,0,1,24.1,17Z" />
  </IconBase>
);

