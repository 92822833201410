import { IEquipment } from "online-services-types";
import React, { useState } from "react";
import { LocalizedString } from "src/components/Localization";
import { Tooltip } from "src/design-system/Tooltip";
import {
  ISFOCData,
  ISFOCQuarter,
  sortByNickname,
  StatusCell,
  Statuses,
  StatusTable,
  THead,
  TUnderlinedBody,
  YesNo,
} from "src/util/sfoc/sfocCharts";
import styled from "styled-components";
import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";

const Name = styled.td`
  text-align: left;
`;

export const TFoot = styled.tfoot`
  & td {
    vertical-align: top;
    text-align: left;
  }

  & td:first-child {
    border: none;
  }
`;

const EquipmentRow = styled.tr``;

interface IMeasurementStatusChart {
  installationId: string;
  equipments: IEquipment[];
  quarter: string;
  chartData: ISFOCData[];
  enableFilter?: boolean;
}

export const Reason = (props: { reason: string }) => (
  <div style={{ overflow: "visible", position: "absolute" }}>
    <Tooltip title={props.reason} />
  </div>
);

export const MeasurementStatusChart = (props: IMeasurementStatusChart) => {
  const [visibleTooltip, setVisibleTooltip] = useState<string>();

  const installationEquipments: (Partial<ISFOCQuarter> & IEquipment)[] = props.equipments
    .filter((equipment) => equipment.installationId === props.installationId)
    .map((equipment) => {
      const quarterInfo = props.chartData
        .find((item) => item.equipmentId === equipment.id)
        ?.quarters?.find((quarter) => quarter.quarter === `Q${props.quarter}`);
      return { ...equipment, ...(quarterInfo || {}) };
    })
    .sort(sortByNickname);

  return (
    <DashboardItem title={<LocalizedString id="sfoc.quarterMeasurementStatus" values={{ quarter: props.quarter }} />}>
      <StatusTable>
        <THead>
          <tr>
            <th />
            <th>
              <LocalizedString id="sfoc.measurementPhase.measurementReceived" />
            </th>
            <th>
              <LocalizedString id="sfoc.measurementPhase.fuelSampleReceived" />
            </th>
            <th>
              <LocalizedString id="sfoc.measurementPhase.validation" />
            </th>
          </tr>
        </THead>
        <TUnderlinedBody>
          {installationEquipments.map((equipment) => (
            <EquipmentRow key={equipment.id} data-testid={equipment.id}>
              <Name>{equipment.nickName || equipment.description}</Name>
              <StatusCell
                status={
                  equipment?.quarter &&
                  equipment?.testValidation !== "Suspended" &&
                  equipment?.testValidation !== "Not received"
                    ? "Yes"
                    : "No"
                }
              />
              <StatusCell status={equipment?.isFuelSampleReceived ? "Yes" : "No"} />
              <StatusCell
                onEnter={() => setVisibleTooltip(equipment.id)}
                onExit={() => setVisibleTooltip(undefined)}
                onClick={() => setVisibleTooltip(visibleTooltip === equipment.id ? undefined : equipment.id)}
                status={equipment?.testValidation || "Not received"}
              >
                {equipment?.testValidation === "Rejected" && visibleTooltip === equipment.id && (
                  <Reason reason={`Q${props.quarter} ${equipment.nickName}: ${equipment?.reasonForRejection}` || ""} />
                )}
              </StatusCell>
            </EquipmentRow>
          ))}
        </TUnderlinedBody>
        <TFoot>
          <tr>
            <td />
            <td colSpan={2}>
              <YesNo onClick={() => {}} filters={[]} enableFilter={false} />
            </td>
            <td>
              <Statuses onClick={() => {}} filters={[]} enableFilter={false} />
            </td>
          </tr>
        </TFoot>
      </StatusTable>
    </DashboardItem>
  );
};
