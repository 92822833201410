import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const NewsIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>News</title>
    <g>
      <path d="M24,4A20,20,0,1,0,44,24,20,20,0,0,0,24,4ZM8,24a15.92,15.92,0,0,1,.61-4.31L15,27H11L21.87,39.84A16,16,0,0,1,8,24ZM30,38.82V34h1l-6-6V27H19l-5-5V18h5v2h3V12l3.88-3.88A15.86,15.86,0,0,1,30,9.18V10l4,4v5h4V16.27A15.88,15.88,0,0,1,39.71,21H36v4l3.38,3.38A16.06,16.06,0,0,1,30,38.82Z" />
      <rect x="19" y="22" width="3" height="3" />
    </g>
  </IconBase>
);
