import { IInvoice, IInvoiceDispute, IInvoiceDisputeRequest, IInvoicesData } from "online-services-types";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "./error";
import { doSearchTermsMatchResourceFields, getSearchTermArrayFromString } from "src/util/helpers";

import { APIFetch } from "src/APIFetch";
import moment from "moment";
import { translateString } from "./localization";

export enum Status {
  Paid = "Paid",
  Overdue = "Overdue",
  Open = "Open",
  CreditMemo = "Credit memo",
}

export const getStatuses = (): string[] => [
  Status.Open,
  Status.Overdue,
  Status.CreditMemo, // Paid removed, as stakeholders have requested not to show paid invoices.
];

export enum Type {
  Other = "Other invoice",
  SpareParts = "Spare parts invoice",
  FieldServices = "Field services invoice",
  Agreements = "Agreements invoice",
  Projects = "Projects invoice",
  Warranty = "Warranty",
}

export const getTypes = (): string[] => [
  Type.SpareParts,
  Type.FieldServices,
  Type.Agreements,
  Type.Projects,
  Type.Other, // Warranty removed, as it is not currently used in getInvoices.ts.
];

export async function getInvoiceDetail(invoice: IInvoice) {
  return new APIFetch<IInvoice>("spareparts/invoices").get(invoice.id);
}

export async function getInvoices() {
  return new APIFetch<IInvoicesData>("spareparts/invoices").get();
}

const getStatusRank = (status: string) => {
  const statusRanks = {
    [Status.Overdue]: 1,
    [Status.Open]: 2,
    [Status.Paid]: 3,
  };
  return statusRanks[status] || 4;
};

type IInvoiceWithNotificationsIndicator = IInvoice & { hasNotifications: boolean };
export const sortByRelevancy = (a: IInvoiceWithNotificationsIndicator, b: IInvoiceWithNotificationsIndicator) => {
  if (a.hasNotifications && !b.hasNotifications) {
    return -1;
  }

  if (!a.hasNotifications && b.hasNotifications) {
    return 1;
  }

  if (a.hasNotifications && b.hasNotifications) {
    if (a.daysUntilDueDate < b.daysUntilDueDate) {
      return -1;
    }

    if (a.daysUntilDueDate > b.daysUntilDueDate) {
      return 1;
    }
  }

  if (getStatusRank(a.status) < getStatusRank(b.status)) {
    return -1;
  }

  if (getStatusRank(a.status) > getStatusRank(b.status)) {
    return 1;
  }

  return sortByDueDate(a, b);
};

export const sortByDueDate = (a: IInvoice, b: IInvoice) => {
  const dueDateA = moment(a.dueDate);
  const dueDateB = moment(b.dueDate);
  return dueDateA.diff(dueDateB, "days");
};

export const doesSearchTextMatchInvoiceFields = (searchText: string, invoice: IInvoice): boolean => {
  const searchTerms: string[] = getSearchTermArrayFromString(searchText);

  const fieldsToMatch: Array<keyof IInvoice> = ["id", "salesDocument", "poReference"];

  return doSearchTermsMatchResourceFields<IInvoice>(searchTerms, invoice, fieldsToMatch);
};

const daysInWeek = 7;

export const isDueInWeek = (invoice: IInvoice) => {
  if (invoice.status !== Status.Open) {
    return false;
  }
  const dueDate = moment(invoice.dueDate);
  const currentDate = moment().startOf("day");
  return dueDate.diff(currentDate, "days") <= daysInWeek;
};

export const isCreditMemo = (invoice: IInvoice) => {
  return invoice.status === Status.CreditMemo;
};

export const isDueInPastAndPaid = (invoice: IInvoice) => {
  if (invoice.status !== Status.Paid) {
    return false;
  }
  const dueDate = moment(invoice.dueDate);
  const currentDate = moment().startOf("day");
  return dueDate.diff(currentDate, "days") < 0;
};

export async function getInvoice(invoiceId: string) {
  return new APIFetch<IInvoice>(`spareparts/invoices/${invoiceId}`).get();
}

export async function getInvoiceAttachmentUrl(invoiceId: string, type: string) {
  return new APIFetch<string>(`spareparts/invoices/${invoiceId}/attachments/${type}`).get();
}

export async function getDunningLetterUrl() {
  return new APIFetch<string>(`spareparts/invoices/dunning-letter`).get();
}

export async function postDispute(dispute: IInvoiceDisputeRequest) {
  const toastId = displayProgress(translateString("request.sending"));
  try {
    const savedDispute = (await new APIFetch<IInvoiceDisputeRequest>("spareparts/invoicedisputes").post(
      dispute
    )) as IInvoiceDispute;
    dismissMessage(toastId);
    displaySuccess(translateString("spareParts.invoice.dispute.requestSuccess"));
    return savedDispute;
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      translateString("spareParts.invoice.dispute.requestError", { error }),
      undefined,
      undefined,
      error.awsRequestId
    );
    return null;
  }
}
