import * as React from "react";
import { ILineItem, IPartModel } from "online-services-types";

import { translateString } from "src/util/localization";
import { getCurrencySymbol } from "src/util/currencyMapper";

import * as s from "./styles";
import { InfoBox } from "./InfoBox";
import { Dialog } from "src/components/Dialog";
import { IconSize, MinusIcon, PlusIcon, TrashIcon } from "src/icons";
import { ModalSize } from "src/components/Dialog/Modal";

import { IProps, IState } from "./types";
import { formatCurrency, formatPartIdValue } from "src/util/formatters";
import { findItemInBasket } from "src/util/helpers";


export class AddToBasketButtonsComponent extends React.Component<IProps, IState> {
  public readonly state = {
    aboutToDeleteWarning: false,
    confirmItemRemove: false,
  };

  getCurrency(part: IPartModel, basketItem?: ILineItem){
      if(part && part.currency){
        return "(" + getCurrencySymbol(part.currency) + ")";
      } else if(basketItem && basketItem.currencyCode){
        return "(" + getCurrencySymbol(basketItem.currencyCode) + ")";
      }
      return '';
  }

  public render() {
    const { basket, part, showTrashButton = false, inactive } = this.props;
    const basketItem = findItemInBasket(part, basket);

    let price = 0;
    const isAddButtonDisabled = false;

    if (basketItem && part.netPrice) {
      price = part.netPrice * basketItem.quantity;
    }

    return (
      <s.Wrapper showTrashButton={showTrashButton} onClick={this.preventBubbling} className={this.props.className}>
        <s.Button type="button" disabled={inactive} onClick={() => this.decreaseItemNum(basketItem)}>
          <MinusIcon size={IconSize.XSmall} />
        </s.Button>

        <InfoBox
          title={translateString("spareParts.quantity")}
          value={basketItem ? basketItem.quantity : 0}
          inBasket={!!basketItem}
          inputHandler={Boolean(inactive) ? undefined : this.addMultipleItems}
        />

        <s.Button
          type="button"
          disabled={isAddButtonDisabled || inactive}
          onClick={() => this.increaseItemNum(basketItem)}
        >
          <PlusIcon size={IconSize.XSmall} />
        </s.Button>

        {!this.props.hidePrices && (
          <InfoBox
            title={`${translateString("spareParts.subtotal")} ${
              this.getCurrency(part, basketItem)
            }`}
            value={part.netPrice === 0 ? "-" : formatCurrency(Math.round(price * 100) / 100)}
            inBasket={!!basketItem}
          />
        )}

        {showTrashButton && (
          <s.Button isTrashButton={true} onClick={() => {
            this.removeItem(this.props.part)
          }}>
            <TrashIcon size={IconSize.Small} />
          </s.Button>
        )}

        <Dialog
          size={ModalSize.Small}
          title={`${translateString("spareParts.lastItemDialogTitle")}`}
          isDialogOpen={this.state.aboutToDeleteWarning}
          onCancel={() => this.setState({ aboutToDeleteWarning: false })}
          submit={{
            buttonTitle: translateString("yes"),
            callback: () =>
              this.setState({ confirmItemRemove: true, aboutToDeleteWarning: false }, () =>
                this.removeItem(this.props.part)
              ),
          }}
        >
          <>
            <p>{translateString("spareParts.lastItemDialogText")}</p>
            <p>{part.spnDescription}</p>
            <p>{formatPartIdValue(part)}</p>
          </>
        </Dialog>
      </s.Wrapper>
    );
  }

  private readonly removeItem = (basketItem: IPartModel) => {
    if (this.props.onChangeItemNumber) {
      if (basketItem) {
        this.props.onChangeItemNumber(basketItem, 0);
      }
    } else {
      this.props.removeItem(this.props.part);
      if(basketItem.isAddedFromRecommendations && this.props.setRecommendations) {
        this.props.setRecommendations((prev) => {
          return prev.concat(this.props.part);
        })
      }
    }
  };

  private readonly decreaseItemNum = (basketItem: ILineItem | undefined) => {
    if (basketItem) {
      if (basketItem.quantity === 1) {
        !this.state.aboutToDeleteWarning && this.setState({ aboutToDeleteWarning: true });
      } else {
        if (this.props.onChangeItemNumber) {
          this.props.onChangeItemNumber(this.props.part, basketItem.quantity - 1);
        } else {
          this.props.decreaseItemNum(this.props.part);
        }
      }
    }
  };

  private readonly increaseItemNum = (basketItem: ILineItem | undefined) => {
    if (this.props.onChangeItemNumber) {
      this.props.onChangeItemNumber(this.props.part, basketItem ? basketItem.quantity + 1 : 1);
    } else {
      this.props.addItem(this.props.part, basketItem ? basketItem.quantity + 1 : 1);
    }
  };

  private readonly addMultipleItems = (quantity: number, inBasket: boolean) => {
    if (quantity === 0) {
      inBasket && this.setState({ aboutToDeleteWarning: true });
    } else {
      if (this.props.onChangeItemNumber) {
        this.props.onChangeItemNumber(this.props.part, quantity);
      } else {
        this.props.addItem(this.props.part, quantity);
      }
    }
  };

  private readonly preventBubbling = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
}
