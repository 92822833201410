import { Link } from "react-router-dom";
import { Button } from "src/design-system/Button";
import { spacer } from "src/design-system/Tokens/tokens";
import { H2 } from "src/design-system/Tokens/typography";
import { RadioMinusIcon, RadioPlusIcon } from "src/icons";
import styled from "styled-components";

export const ListContainer = styled.div`
  flex: 1 1 auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & button {
    margin-right: 10px;
  }
`;

export const ExtraRowWithMargin = styled.div`
  margin-left: ${spacer(7)};
  padding-bottom: ${spacer(2)};
`;

export const ButtonLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const OrangeButton = styled(Button)``;

export const Wrapper = styled.div`
  & ${H2} {
    text-transform: uppercase;
    margin-top: 0;
  }
`;

export const IconWrap = styled.div`
  text-decoration: none;
`;
export const Plus = styled(RadioPlusIcon)`
  margin-top: auto;
  margin-right: 1em;
  cursor: pointer;
`;
export const Minus = styled(RadioMinusIcon)`
  margin-top: auto;
  margin-left: 5em;
  cursor: pointer;
`;

export const ParticipantHeader = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ButtonWrap = styled.div`
  padding: 0 ${spacer(2)};
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.p`
  font-weight: 700;
  margin: 0.25em 0;
  padding: 0;
`;

export const FieldsWrap = styled.div`
  margin-bottom: 2em;
`;
