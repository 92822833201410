// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_zh_CN: ITranslations = {
  zh_CN: {
    "contracts.attachmentDocumentTypes": "文档 ({types})",
    "contracts.attachmentImageTypes": "图像 ({types})",
    "contracts.attachmentsMessage": "您可上传下列类型的附件，文件大小不超过{maxSize}。",
    "contracts.cbmExpertName": "资产诊断",
    "contracts.contact": "联系方式",
    "contracts.contactInformation": "联系信息",
    "contracts.contactPerson": "联系人",
    "contracts.contractManager": "合同经理",
    "contracts.contractNumber": "协议编号",
    "contracts.contractType": "类型",
    "contracts.equipmentContracts": "产品协议",
    "contracts.expertiseCenter": "技术中心",
    "contracts.expiry": "到期日",
    "contracts.installationContracts": "设施协议",
    "contracts.installationContracts:energy": "发电厂协议",
    "contracts.installationContracts:marine": "船舶协议",
    "contracts.installationsInScope": "设施供货范围",
    "contracts.installationsInScope:energy": "发电厂供货范围",
    "contracts.installationsInScope:marine": "船舶供货范围",
    "contracts.invalidContract": "无效的协议",
    "contracts.maintenancePlannerName": "维护保养规划",
    "contracts.noContactPerson": "无联系人",
    "contracts.noEquipmentContractsAvailable": "无产品协议可用",
    "contracts.noInstallationContractsAvailable": "无设施协议可用",
    "contracts.noInstallationContractsAvailable:energy": "无发电厂协议可用",
    "contracts.noInstallationContractsAvailable:marine": "无船舶协议可用",
    "contracts.productNumbers": "产品编号",
    "contracts.productReferenceTypes": "产品参考类型",
    "contracts.remoteSupporterName": "远程支持系统",
    "contracts.startDate": "起始日",
    "contracts.technicalExperts": "技术专家",
    "contracts.validation.messageIsRequired": "需要填写消息",
    "contracts.validation.subjectIsRequired": "需要填写主题",
  },
};
