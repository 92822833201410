import { IEquipment, IInstallation, INotification } from "online-services-types";
import * as React from "react";
import { Container, FlexContainer } from "src/design-system/Container";
import { ITheme, themes } from "src/design-system/Theme/theme";
import { fixedFontSizes, H4 } from "src/design-system/Tokens/typography";
import { CheckIcon, IconSize } from "src/icons";
import { INotificationContext } from "src/models/notifications";
import { translateString } from "src/util/localization";
import { getSelectedColorTheme } from "src/util/localstorage";
import styled from "styled-components";
import { LocalizedString } from "../Localization";
import { filterDiscardableNotifications } from "./NotificationListComponent";
import { NotificationListItem } from "./NotificationListItem";

export type ITimeSpan = "today" | "yesterday" | "thisWeek" | "lastWeek" | "older";

const timespanNames = {
  today: translateString("notifications.today"),
  yesterday: translateString("notifications.yesterday"),
  thisWeek: translateString("notifications.thisWeek"),
  lastWeek: translateString("notifications.lastWeek"),
  older: translateString("notifications.older"),
};

interface INotificationListTimeSpan {
  context: INotificationContext;
  notifications: INotification[];
  installations: IInstallation[];
  equipments: IEquipment[];
  timespan: ITimeSpan;
  onRequestCloseSidebar(): void;
  discardAndSetNotificationAsRead(ids: string[], notificationContext: INotificationContext): void;
}

interface INotificationListTimeSpanState {
  previousDiscardedNotifications: INotification[];
}

const TimeSpan = styled(H4).attrs({ $uppercase: true })``;
const HeadingWrapper = styled(FlexContainer).attrs({ $spaceBetween: true })``;

type MarkReadButtonProps = {
  disabled?: boolean;
};

const MarkReadButton = styled.div.attrs({ tabIndex: 0 })<MarkReadButtonProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  user-select: none;
`;

export const MarkedNotificationsAsRead = (props: { count: number }) => (
  <LocalizedString id="notificationList.markedNotificationsAsRead" values={{ count: props.count }} />
);

export class NotificationListTimeSpan extends React.Component<
  INotificationListTimeSpan,
  INotificationListTimeSpanState
> {
  constructor(props: INotificationListTimeSpan) {
    super(props);

    this.state = {
      previousDiscardedNotifications: [],
    };
  }

  public render() {
    const theme = themes[getSelectedColorTheme()] as ITheme;
    const uncheckedColor = getSelectedColorTheme() === "dark" ? "#567080" : theme.navigationButton.background;
    return (
      <Container $marginBottom={4}>
        {timespanNames[this.props.timespan] && (
          <HeadingWrapper>
            <TimeSpan>{timespanNames[this.props.timespan]}</TimeSpan>
            <MarkReadButton disabled={!this.hasDiscardableNotifications()} onClick={this.markAllAsRead}>
              <span>
                {translateString(
                  this.hasDiscardableNotifications() ? "notifications.markAsRead" : "notifications.markedAsRead"
                )}
              </span>
              <CheckIcon
                size={IconSize.Small}
                color={this.hasDiscardableNotifications() ? uncheckedColor : theme.icon.defaultColor}
              />
            </MarkReadButton>
          </HeadingWrapper>
        )}
        {this.props.notifications.map((notification) => (
          <NotificationListItem
            key={notification.id}
            notification={notification}
            installations={this.props.installations}
            equipments={this.props.equipments}
            notificationContext={this.props.context}
            discardAndSetNotificationAsRead={this.props.discardAndSetNotificationAsRead}
            onRequestCloseSidebar={this.props.onRequestCloseSidebar}
          />
        ))}
      </Container>
    );
  }

  private readonly markAllAsRead = () => {
    const notificationsToBeDiscarded = filterDiscardableNotifications(this.props.notifications);
    this.props.discardAndSetNotificationAsRead(
      notificationsToBeDiscarded.map((notification) => notification.id),
      this.props.context
    );

    this.setState({ previousDiscardedNotifications: notificationsToBeDiscarded });
  };

  private readonly hasDiscardableNotifications = () => {
    return (
      filterDiscardableNotifications(this.props.notifications).filter((notification) => !notification.discarded)
        .length > 0
    );
  };
}
