// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fieldServices_ja: ITranslations = {
  ja: {
    "fieldServices.actualFinishDate": "実際の終了日",
    "fieldServices.actualStartDate": "実際の開始日",
    "fieldServices.description": "作業内容",
    "fieldServices.estimatedFinishDate": "終了予定日",
    "fieldServices.estimatedStartDate": "開始予定日",
    "fieldServices.poNumber": "PO番号",
    "fieldServices.productReferenceType": "製品参照タイプ",
    "fieldServices.recommendations": "推奨",
    "fieldServices.serviceWorkCoordinator": "サービス作業コーディネーター",
  },
};
