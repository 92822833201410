import * as React from "react";

import { IAccount, Service } from "online-services-types";

import { RouteId } from "./util/routeId";
import { RouteType } from "src/routeType";
import { createBrowserHistory } from "history";
import { matchPath } from "react-router-dom";
import { translateString } from "src/util/localization";

export interface IRouteWithComponent extends IRoute {
  component: React.ComponentType;
}

export interface IRoute {
  id: RouteId;
  exact?: boolean;
  path: string;
  // set this to FALSE if the route contains "route transitions" inside
  enableMainTransition: boolean;
  parentRoute?: IRoute;
  routeName?: string;
  isEnabled: (
    services: ReadonlyArray<Service>,
    isManager?: boolean,
    isEnergyConsultant?: boolean,
    isDesigner?: boolean,
    account?: IAccount,
    hasRunningHours?: boolean
  ) => boolean;
  disableAuthentication?: boolean;
  useExternalHeaderTitle?: boolean;
  alwaysDisplayHeaderTitle?: boolean;
  cantGoBack?: boolean;
}

export interface IRoutes {
  [routeName: string]: IRoute;
}

export interface IRouteParams {
  [param: string]: string | number | boolean;
}

export const getRoutes = (type: RouteType = RouteType.Regular): IRoutes => {
  const IndexRoute = {
    id: RouteId.Index,
    path: "/",
    exact: true,
    enableMainTransition: true,
    isEnabled: () => true,
  };
  if (type === RouteType.Standalone) {
    const DownloadRoute = {
      id: RouteId.Download,
      path: "/download/:fileId?",
      enableMainTransition: true,
      parentRoute: IndexRoute,
      exact: true,
      isEnabled: () => true,
      routeName: translateString("routes.download"),
    };
    return {
      Download: DownloadRoute,
    };
  }

  const RequestsRoute = {
    id: RouteId.Requests,
    path: "/requests/:requestId?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.requests"),
    isEnabled: () => true,
  };
  const CreateWarrantyClaimRoute = {
    id: RouteId.CreateWarrantyClaim,
    path: "/requests/new-request/:phase?/:requestType?/:draftId?",
    enableMainTransition: false,
    parentRoute: RequestsRoute,
    exact: true,
    routeName: translateString("routes.newRequest"),
    isEnabled: () => true,
  };

  const DocumentsRoute = {
    id: RouteId.Documents,
    path: "/documents/:documentId?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.technicalDocuments"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Technical Knowledge"),
  };

  const ContactRoute = {
    id: RouteId.Contact,
    path: "/contact",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("contact"),
    isEnabled: () => true,
  };

  const ContractRoute = {
    id: RouteId.Contract,
    path: "/contracts",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.contracts"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Contracts"),
  };

  const InstallationRoute = {
    id: RouteId.Installation,
    path: "/installation/:installationSalesforceId?/:projectId?/:shipmentNumber?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.installation"),
    isEnabled: () => true,
  };

  const InstallationsRoute = {
    id: RouteId.Installations,
    path: "/installations",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.installations"),
    isEnabled: () => true,
  };

  const InstallationInformationRoute = {
    id: RouteId.InstallationInformation,
    path: "/information",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.installationInformation"),
    isEnabled: () => true,
  };

  const EquipmentsRoute = {
    id: RouteId.Equipments,
    path: "/installations/:installationId/equipments/:equipmentType/:equipmentSalesforceId?",
    enableMainTransition: true,
    parentRoute: InstallationRoute,
    exact: true,
    routeName: translateString("routes.equipment"),
    isEnabled: () => true,
  };

  const EquipmentsRouteFallbackRoute = {
    id: RouteId.Equipments,
    path: "/equipments/:equipmentType/:equipmentSalesforceId",
    enableMainTransition: true,
    parentRoute: InstallationRoute,
    exact: true,
    routeName: translateString("routes.equipment"),
    isEnabled: () => true,
  };

  const UsersRoute = {
    id: RouteId.Users,
    path: "/users",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    isEnabled: () => true,
    routeName: translateString("routes.users"),
  };
  const ProfileRoute = {
    id: RouteId.Profile,
    path: "/profile",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    isEnabled: () => true,
    routeName: translateString("myProfile"),
  };
  const ReportsRoute = {
    id: RouteId.Reports,
    path: "/reports/:reportId?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.reports"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Monitoring"),
  };
  const ContactCMRoute = {
    id: RouteId.ContactCM,
    path: "/contact/contract-manager",
    enableMainTransition: true,
    parentRoute: ContractRoute,
    exact: true,
    isEnabled: () => true,
    routeName: translateString("routes.contactContractManager"),
  };
  const CreateFluidReportRoute = {
    id: RouteId.CreateFluidReport,
    path: "/requests/fluid-report/create",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.newFluidReport"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("FluidReporting"),
  };
  const RunningHoursRoute = {
    id: RouteId.RunningHours,
    path: "/runninghours/:installationId?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    isEnabled: (
      services: ReadonlyArray<Service>,
      isManager: boolean,
      isEnergyConsultant: boolean,
      isDesigner: boolean,
      account: IAccount,
      hasRunningHours: boolean
    ) => hasRunningHours,
    routeName: translateString("routes.runningHours"),
  };
  const FluidReportDraftRoute = {
    id: RouteId.FluidReportDraft,
    path: "/requests/fluid-report/drafts",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.fluidReportDraft"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("FluidReporting"),
  };
  // NOTE: these are separate routes to make it possible to have links to
  // "/spareparts/{id}" (old emails might have old links). The main commerce
  // view uses "/commerce".
  const SparePartsItemRoute = {
    id: RouteId.SpareParts,
    path: "/spareparts/:orderId?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
    routeName: translateString("routes.spareParts"),
  };
  const SparePartsRoute = {
    id: RouteId.SpareParts,
    path: "/commerce/:installationId?/:type?/:search?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
    routeName: translateString("routes.spareParts"),
  };
  const SparePartsCatalogueRoute = {
    id: RouteId.SparePartsCatalogue,
    path: "/commerce/catalogue",
    enableMainTransition: true,
    parentRoute: SparePartsRoute,
    exact: true,
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
    routeName: translateString("routes.partsCatalogue"),
  };
  const InstallationSelectionRoute = {
    id: RouteId.InstallationSelection,
    path: "/commerce/select-installation",
    enableMainTransition: true,
    parentRoute: SparePartsRoute,
    exact: true,
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
    routeName: translateString("routes.installationSelection"),
  };
  const ChartsRoute = {
    id: RouteId.Charts,
    path: "/charts/:reportType?/:periodType?/:equipmentId?",
    enableMainTransition: true,
    parentRoute: InstallationRoute,
    exact: true,
    routeName: translateString("routes.performance"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Uptime"),
  };
  const NewsRoute = {
    id: RouteId.News,
    enableMainTransition: true,
    path: "/news",
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.news"),
    isEnabled: () => true,
  };

  const ReleaseNotesRoute = {
    id: RouteId.ReleaseNotes,
    enableMainTransition: true,
    path: "/release-notes",
    parentRoute: NewsRoute,
    exact: true,
    routeName: translateString("routes.releaseNotes"),
    isEnabled: () => true,
  };

  const RequestQuotationRoute = {
    id: RouteId.RequestQuotation,
    enableMainTransition: true,
    path: "/news/:newsItemId/quotation",
    parentRoute: NewsRoute,
    exact: true,
    routeName: translateString("routes.requestQuotation"),
    isEnabled: () => true,
  };

  const LoadingRoute = {
    id: RouteId.Loading,
    path: "/loading/:action",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("loading"),
    cantGoBack: true,
    isEnabled: () => true,
  };

  const ShoppingCartRoute = {
    id: RouteId.CommerceShoppingCart,
    path: "/commerce/catalogue/cart",
    enableMainTransition: false,
    parentRoute: SparePartsCatalogueRoute,
    exact: true,
    routeName: translateString("routes.shoppingCart"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
  };

  const OrderRoute = {
    id: RouteId.CommerceOrder,
    path: "/commerce/catalogue/order/:phase?",
    enableMainTransition: false,
    parentRoute: ShoppingCartRoute,
    exact: true,
    routeName: translateString("routes.placeOrder"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
  };

  const QuoteRoute = {
    id: RouteId.CommerceQuote,
    path: "/commerce/catalogue/quote/:phase?",
    enableMainTransition: false,
    parentRoute: ShoppingCartRoute,
    exact: true,
    routeName: translateString("spareParts.askForQuotation"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Parts"),
  };

  const CommerceOrderSuccessRoute = {
    id: RouteId.CommerceOrderSuccess,
    path: "/commerce/order-received",
    enableMainTransition: true,
    cantGoBack: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("spareParts.orderReceived"),
    isEnabled: () => true,
  };

  const CommerceQuoteSuccessRoute = {
    id: RouteId.CommerceQuoteSuccess,
    path: "/commerce/quote-received",
    enableMainTransition: true,
    cantGoBack: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("spareParts.quotationReceived"),
    isEnabled: () => true,
  };

  const OfflineRatingRoute = {
    id: RouteId.OfflineRating,
    enableMainTransition: true,
    path: "/offline-feedback/:requestType/:ticketId/:offlineToken",
    parentRoute: undefined,
    exact: false,
    routeName: translateString("routes.requestQuotation"),
    isEnabled: () => true,
    disableAuthentication: true,
    useExternalHeaderTitle: true,
    alwaysDisplayHeaderTitle: true,
  };

  const NewSFOCReportRoute = {
    id: RouteId.NewSFOCReport,
    enableMainTransition: true,
    path: "/new-sfoc-report/:phase?/:draftId?",
    parentRoute: IndexRoute,
    exact: false,
    routeName: translateString("routes.newSFOCReport"),
    isEnabled: () => true, // services.includes('SFOC'),
    disableAuthentication: false,
    useExternalHeaderTitle: false,
    alwaysDisplayHeaderTitle: true,
  };
  const SFOCHistoryRoute = {
    id: RouteId.SFOCHistory,
    enableMainTransition: true,
    path: "/sfoc-history",
    parentRoute: IndexRoute,
    exact: false,
    routeName: translateString("routes.sfocPerformanceDataHistory"),
    isEnabled: () => true, // services.includes('SFOC'),
    disableAuthentication: false,
    useExternalHeaderTitle: false,
    alwaysDisplayHeaderTitle: true,
  };
  const InvoicesRoute = {
    id: RouteId.Invoices,
    path: "/invoices",
    enableMainTransition: true,
    parentRoute: SparePartsRoute,
    exact: true,
    routeName: translateString("routes.invoices"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Invoices"),
  };
  const NewInvoiceRoute = {
    id: RouteId.Invoices,
    path: "/invoices/:id",
    enableMainTransition: true,
    parentRoute: SparePartsRoute,
    exact: true,
    routeName: translateString("routes.invoices"),
    isEnabled: (services: ReadonlyArray<Service>) => services.includes("Invoices"),
  };
  const NewRFQRoute = {
    id: RouteId.NewRFQ,
    path: "/commerce/rfq",
    enableMainTransition: true,
    parentRoute: SparePartsRoute,
    exact: true,
    routeName: translateString("routes.rfq"),
    isEnabled: () => true,
  };
  const TrainingsRoute = {
    id: RouteId.Trainings,
    path: "/trainings/:installationId?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    isEnabled: () => false,
    routeName: translateString("routes.trainings"),
  };
  const TrainingRegisterRoute = {
    id: RouteId.TrainingRegister,
    path: "/trainings/register",
    enableMainTransition: true,
    parentRoute: TrainingsRoute,
    exact: true,
    routeName: translateString("routes.trainings.register"),
    isEnabled: () => false,
  };
  const TrainingContactRoute = {
    id: RouteId.TrainingContact,
    path: "/trainings/contact",
    enableMainTransition: true,
    parentRoute: TrainingsRoute,
    exact: true,
    routeName: translateString("contact"),
    isEnabled: () => true,
  };
  const ManagerDashboardRoute = {
    id: RouteId.ManagerDashboard,
    path: "/manager-dashboard",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.managerDashboard"),
    isEnabled: (_: ReadonlyArray<Service>, isManager = false) => isManager,
  };
  const EnergyDocumentsRoute = {
    id: RouteId.EnergyDocuments,
    path: "/energy-documents/:documentType?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.otherDocuments"),
    isEnabled: (services: ReadonlyArray<Service>, isManager: boolean, isEnergyConsultant = false) => isEnergyConsultant,
  };
  const MarineDocumentsRoute = {
    id: RouteId.MarineDocuments,
    path: "/marine-documents/:sfiMainGroup?",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.otherDocuments"),
    isEnabled: (
      services: ReadonlyArray<Service>,
      isManager: boolean,
      isEnergyConsultant: boolean,
      isDesigner = false
    ) => isDesigner,
  };

  const VATStatementsRoute = {
    id: RouteId.VATStatements,
    path: "/vat-statements",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.VATStatements"),
    isEnabled: (
      services: ReadonlyArray<Service>,
      isManager: boolean,
      isEnergyConsultant: boolean,
      isDesigner: boolean,
      account: IAccount
    ) => account?.sector === "Marine",
  };

  const NewVATStatementRoute = {
    id: RouteId.NewVATStatement,
    path: "/new-vat-statement",
    enableMainTransition: true,
    parentRoute: VATStatementsRoute,
    exact: true,
    routeName: translateString("routes.newVATStatement"),
    isEnabled: () => true,
  };

  const SFOCVesselDashboardRoute = {
    id: RouteId.SFOCVesselDashboard,
    path: "/sfoc-vessel-dashboard",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.SFOCVesselDashboard"),
    isEnabled: (
      services: ReadonlyArray<Service>,
      isManager: boolean,
      isEnergyConsultant: boolean,
      isDesigner: boolean
    ) => services.includes("SFOC"),
  };

  const SFOCFleetDashboardRoute = {
    id: RouteId.SFOCFleetDashboard,
    path: "/sfoc-fleet-dashboard",
    enableMainTransition: true,
    parentRoute: IndexRoute,
    exact: true,
    routeName: translateString("routes.SFOCFleetDashboard"),
    isEnabled: (
      services: ReadonlyArray<Service>,
      isManager: boolean,
      isEnergyConsultant: boolean,
      isDesigner: boolean
    ) => services.includes("SFOC"),
  };

  const SparePartPackagesRoute = {
    id: RouteId.SparePartPackages,
    path: "/commerce/packages",
    enableMainTransition: true,
    parentRoute: SparePartsRoute,
    exact: true,
    routeName: translateString("routes.sparePartPackages"),
    isEnabled: (services: ReadonlyArray<string>) => services.includes("Spare Part Packages"),
  };

  return {
    FluidReportDraft: FluidReportDraftRoute,
    CreateFluidReport: CreateFluidReportRoute,
    CreateWarrantyClaim: CreateWarrantyClaimRoute,
    Requests: RequestsRoute,
    Documents: DocumentsRoute,
    ContactCM: ContactCMRoute,
    Contact: ContactRoute,
    Contract: ContractRoute,
    Equipments: EquipmentsRoute,
    EquipmentsRouteFallback: EquipmentsRouteFallbackRoute,
    Loading: LoadingRoute,
    Users: UsersRoute,
    Invoices: InvoicesRoute,
    NewInvoice: NewInvoiceRoute,
    NewRFQ: NewRFQRoute,

    CommerceQuote: QuoteRoute,
    CommerceOrder: OrderRoute,
    CommerceShoppingCart: ShoppingCartRoute,
    CommerceOrderSuccess: CommerceOrderSuccessRoute,
    CommerceQuoteSuccess: CommerceQuoteSuccessRoute,

    VATStatements: VATStatementsRoute,
    NewVATStatement: NewVATStatementRoute,

    Profile: ProfileRoute,
    Reports: ReportsRoute,
    Installation: InstallationRoute,
    InstallationInformation: InstallationInformationRoute,
    RunningHours: RunningHoursRoute,
    TrainingRegister: TrainingRegisterRoute,
    TrainingContact: TrainingContactRoute,
    Trainings: TrainingsRoute,
    ManagerDashboard: ManagerDashboardRoute,
    EnergyDocuments: EnergyDocumentsRoute,
    MarineDocuments: MarineDocumentsRoute,

    // /commerce/select-installation and */catalogue need to come before /commerce/{orderId} o
    // else "select-installation"/"catalogue" will be caught as the parameter
    InstallationSelection: InstallationSelectionRoute,
    SparePartsCatalogue: SparePartsCatalogueRoute,
    SparePartPackages: SparePartPackagesRoute,
    SpareParts: SparePartsRoute,
    SparePartsItem: SparePartsItemRoute,
    Charts: ChartsRoute,
    RequestQuotation: RequestQuotationRoute,
    News: NewsRoute,
    ReleaseNotes: ReleaseNotesRoute,
    OfflineRating: OfflineRatingRoute,
    NewSFOCReport: NewSFOCReportRoute,
    SFOCVesselDashboard: SFOCVesselDashboardRoute,
    SFOCFleetDashboard: SFOCFleetDashboardRoute,
    SFOCHistory: SFOCHistoryRoute,
    Installations: InstallationsRoute,
    /**
     * Note: IndexRoute has to be last or path parent lookup will fail
     */
    Index: IndexRoute,
  };
};

export const history = createBrowserHistory();

const allRoutes = getRoutes();
export const routesToDisableTransition: string[] = Object.keys(allRoutes)
  .map((routeKey: string) => {
    if (!allRoutes[routeKey].enableMainTransition) {
      const path = allRoutes[routeKey].path;
      return path.slice(0, path.indexOf(":") - 1);
    }
    return "";
  })
  .filter((path: string) => path.length > 0);

const stripParamPlaceHolders = (path: string) => {
  return path.replace(new RegExp("\\/:.+$", "g"), "");
};

export const getCurrentRoute = (): IRoute | null => {
  const pathname = window.location.pathname;
  const routes = getRoutes();

  for (const route of Object.keys(routes)) {
    if (matchPath(pathname, routes[route])) {
      return routes[route];
    }
  }
  return null;
};

export function getHeaderNavigationInfo(pathName: string): IRoute | null {
  const routes = getRoutes();

  for (const key of Object.keys(routes)) {
    const route = routes[key];

    if (matchPath(pathName, route)) {
      return route;
    }
  }
  return null;
}

export function getPathWithParams(route: IRoute, params: IRouteParams = {}) {
  let path = route.path;
  const keys = Object.keys(params);

  if (keys.length === 0) {
    // Get path with all :params stripped out
    return stripParamPlaceHolders(path);
  }

  // Get path with all :params replaced with values
  Object.keys(params).forEach((key) => {
    path = path.replace(new RegExp(`\\/:${key}\\??`, "g"), `/${encodeURIComponent(params[key])}`);
  });

  // Strip all optional params that were not substituted in the last pass
  Object.keys(params).forEach(() => {
    path = path.replace(new RegExp(`\\/:[^/]+\\?`, "g"), "");
  });

  return path;
}

export function redirectBrowser<T extends Record<string, any>>(route: IRoute, params: IRouteParams = {}, state?: T) {
  history.push(getPathWithParams(route, params), state);
}

export function redirectBrowserToURL(url: string, openNewWindow = false) {
  if (openNewWindow) {
    window.open(url, "_blank");
  } else {
    window.location.assign(url);
  }
}

export function getAvailableRoutes(
  services: ReadonlyArray<Service>,
  isManager: boolean,
  isEnergyConsultant: boolean,
  isDesigner: boolean,
  account: IAccount,
  hasRunningHours?: boolean
) {
  const routes = getRoutes();
  return Object.keys(routes)
    .map((key) => routes[key])
    .filter((route) => route.isEnabled(services, isManager, isEnergyConsultant, isDesigner, account, hasRunningHours));
}

export function shouldRedirectToLogin(pathname: string) {
  const route = getHeaderNavigationInfo(pathname);
  if (!route) {
    return true;
  }

  return !route.disableAuthentication;
}
