import { IAuth } from "online-services-types";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import * as thunk from "redux-thunk";
import { IApplicationContextState } from "src/redux/applicationContext";
import { IBasketState } from "src/redux/basket";
import { IPartAvailabilityState } from "src/redux/partsAvailability";
import { IUpdateRunningHoursState } from "src/redux/runningHours";
import { IUserState } from "src/redux/user";
import { IAPIResourceState } from "./api";
import { IHeaderState } from "./header";
import { IOnboardingState } from "./onboarding";
import { ILanguageState } from "./localization";
import reducers from "./reducers";

export interface IAppState extends IAPIResourceState {
  userInfo: IUserState;
  auth: IAuth;
  basket: IBasketState;
  applicationContext: IApplicationContextState;
  runningHours: IUpdateRunningHoursState;
  onboarding: IOnboardingState;
  partsAvailability: IPartAvailabilityState;
  header: IHeaderState;
  language: ILanguageState;
}

export const configureStore = () => {
  const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk.default)));

  // Workaround due to a type confusion
  const hotModule = module as any;

  if (hotModule.hot) {
    hotModule.hot.accept("./reducers", () => {
      store.replaceReducer(reducers);
    });
  }

  return store;
};
