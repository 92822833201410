import { filter, isNumber, map, sortBy } from "lodash";
import moment from "moment";
import { IAccountUser, IEquipment, IInstallation, Service } from "online-services-types";
import * as React from "react";

import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import { AccountSelector } from "src/components/AccountSelector";
import { Heading } from "src/components/Heading";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";

import { Container, FlexContainer } from "src/design-system/Container";
import { DropdownStyle } from "src/design-system/Select/Select";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { Grid, GridRowExtraLargeItems, GridRowLargeItems, GridSpacing } from "src/design-system/Tokens/grid";
import { defaultBorderRadius, spacer } from "src/design-system/Tokens/tokens";
import {
  baseTextStyles,
  Disclaimer,
  fixedFontSizes,
  fluidTypography,
  fontSizes,
  H4,
} from "src/design-system/Tokens/typography";
import { StaticTooltip } from "src/design-system/Tooltip";
import { IconSize, InformationIcon, MinusIcon, PlusIcon } from "src/icons";
import { getRoutes, IRoute, redirectBrowser } from "src/routes";
import { formatCurrency, formatDate } from "src/util/formatters";
import { translateString } from "src/util/localization";
import styled from "styled-components";
import { IconWithTooltip } from "../CreateFluidReportView/phases/IconWithTooltip";
import { RequestsChart } from "./charts/RequestsChart";
import { RFQChart } from "./charts/RFQChart";
import { SparePartOrderChart } from "./charts/SparePartOrderChart";
import { getReadStatusForCMReports, IDocumentOpenStatus } from "./dashboard";
import {
  useDocuments,
  useInvoices,
  usePersonnelPercentage,
  useReports,
  useRFQS,
  useSPOS,
  useSubmittedRequests,
  useTimeframe,
} from "./hooks";
import { InstallationFilter } from "./InstallationFilter";
import { Table } from "./Table";

interface IDashboardItemWrapperProps {
  minHeight?: number;
  isClickable: boolean;
}

export const DashboardItem = (props: {
  title: React.ReactNode;
  children: React.ReactNode;
  route?: IRoute;
  minHeight?: number;
}) => {
  return (
    <DashboardItemWrapper
      minHeight={props.minHeight}
      isClickable={!!props.route}
      onClick={() => props.route && redirectBrowser(props.route)}
    >
      <DashboardItemInner>
        <Title>{props.title}</Title>
        <Content>{props.children}</Content>
      </DashboardItemInner>
    </DashboardItemWrapper>
  );
};

export interface IManagerDashboardViewComponentStateProps {
  users?: IAccountUser[];
  partsBlockingStatus: boolean;
  services: ReadonlyArray<Service>;
  equipment: IAPIResource<IEquipment>;
  installations: IAPIResource<IInstallation>;
  currency: string;
  isReady: boolean;
}

export interface IManagerDashboardViewComponentDispatchProps {
  getInstallations(): void;
  getUsers(): void;
  getEquipment(): void;
}

type IManagerDashboardViewComponentProps = IManagerDashboardViewComponentStateProps &
  IManagerDashboardViewComponentDispatchProps;

const maxNumberOfInstallationsToSelect = 15;

const ManagerDashboardViewComponent = ({
  installations,
  users,
  getUsers,
  partsBlockingStatus,
  services,
  equipment,
  currency,
}: IManagerDashboardViewComponentProps) => {
  const timeframe = useTimeframe();
  const [installationIds, setInstallationIds] = React.useState<string[]>();

  const rfqs = useRFQS(installationIds, timeframe.days);
  const spos = useSPOS(installationIds, timeframe.days);
  const submittedRequests = useSubmittedRequests(
    (installations.data || []).map((installation) => installation.id),
    timeframe.days
  );

  const personnelLoggedInPercentage = usePersonnelPercentage(users, timeframe.start);

  const [isInstallationDialogOpen, setInstallationDialogOpen] = React.useState(false);
  const toggleInstallationDialogOpen = React.useCallback(
    () => setInstallationDialogOpen(!isInstallationDialogOpen),
    [isInstallationDialogOpen]
  );

  const invoices = useInvoices({ enabled: services.includes("Invoices") });
  const reports = useReports(installationIds, timeframe.days, { enabled: services.includes("Monitoring") });
  const docs = useDocuments(installationIds, timeframe.days, { enabled: services.includes("Technical Knowledge") });

  const [openedRows, setOpenRows] = React.useState<string[]>([]);
  const [tooltipOpenFor, setTooltipOpenFor] = React.useState({ table: "", installationId: "", id: "" });
  const toggleRow = (row: string) => {
    setOpenRows((s) => (s.includes(row) ? s.filter((r) => r !== row) : s.concat(row)));
  };

  React.useEffect(() => {
    if (!installationIds && installations.status === APIFetchStatus.Success && installations.data) {
      if (installations.data.length <= maxNumberOfInstallationsToSelect) {
        setInstallationIds(installations.data.map((installation) => installation.id));
      } else {
        setInstallationIds([]);
        toggleInstallationDialogOpen();
      }
    }
  }, [installations.data]);

  React.useEffect(() => {
    getUsers();
  }, []);

  const routes = getRoutes();
  const selectedInstallations = installations.data
    ? installations.data.filter((installation) => (installationIds || []).includes(installation.id))
    : [];

  const renderStatus = (table: string, id: string, document?: IDocumentOpenStatus) => {
    const numberOfUnreadDocs = document?.unreadDocuments.length;
    if (document) {
      return (
        <>
          <td>{document.readCount ? <ReadCounter color={colors.sfoc.green}>{document.readCount}</ReadCounter> : ""}</td>
          <td style={{ position: "relative" }}>
            {numberOfUnreadDocs ? (
              <ReadCounter
                color={colors.sfoc.red}
                onMouseLeave={() => setTooltipOpenFor({ table: "", installationId: "", id: "" })}
                onMouseEnter={() => setTooltipOpenFor({ table, installationId: document.installationId, id })}
              >
                {numberOfUnreadDocs}

                {tooltipOpenFor.table === table &&
                tooltipOpenFor.installationId === document.installationId &&
                tooltipOpenFor.id === id ? (
                  <StaticTooltip
                    width={220}
                    arrow={false}
                    xOffset={-200}
                    title={translateString("managerDashboard.unopenedDocuments")}
                  >
                    {map(document.unreadDocuments, (document) => {
                      return (
                        <UnreadDocument>
                          <TooltipTitle>{formatDate(document.date)}</TooltipTitle>
                          <div>{document.subject}</div>
                        </UnreadDocument>
                      );
                    })}
                  </StaticTooltip>
                ) : null}
              </ReadCounter>
            ) : (
              ""
            )}
          </td>
        </>
      );
    }

    return (
      <>
        <td></td>
        <td></td>
      </>
    );
  };

  return (
    <div>
      <IconWithTooltip
        top="20%"
        width="200px"
        iconProps={{ position: "absolute", width: "auto" }}
        icon={<InformationIcon color={colors.primary.white} />}
      >
        <Container>
          <div>{translateString("managerDashboard.currencyInfoTooltip")}</div>
        </Container>
      </IconWithTooltip>
      <span style={{ paddingLeft: "1.5em", marginBottom: "50px" }}>
        <LocalizedString id="managerDashboard.currencyInfo" />
      </span>
      <Heading text={translateString("managerDashboard.accountSummary")}>
        <AccountSelector
          dropdownStyle={DropdownStyle.Default}
          disableRedirect
        />
      </Heading>
      <Grid
        spacing={GridSpacing.airy}
        itemsPerRow={GridRowLargeItems}
        fallbackWidth={{ min: 150, max: 350 }}
        $margin={[0, 0, 3]}
      >
        <DashboardItem route={routes.Users} title={translateString("managerDashboard.section.personnelLoggedIn")}>
          {isNumber(personnelLoggedInPercentage) ? (
            <FatNumber>{Math.floor(personnelLoggedInPercentage)}%</FatNumber>
          ) : (
            <LoadingSpinner disableText size={spinnerSize.sm} />
          )}
          <ItemFooter>
            <LocalizedString id="managerDashboard.lastNDays" values={{ days: timeframe.days }} />
          </ItemFooter>
        </DashboardItem>
        <DashboardItem route={routes.Requests} title={translateString("managerDashboard.section.submittedRequests")}>
          {installations.status === 1 || submittedRequests.loading ? (
            <LoadingSpinner disableText size={spinnerSize.sm} />
          ) : (
            <FatNumber>{submittedRequests.data?.length || "-"}</FatNumber>
          )}
          <ItemFooter>
            <LocalizedString id="managerDashboard.lastNDays" values={{ days: timeframe.days }} />
          </ItemFooter>
        </DashboardItem>
        {services.includes("Invoices") && (
          <>
            <DashboardItem route={routes.Invoices} title={translateString("managerDashboard.section.overdueInvoices")}>
              {invoices.loading ? (
                <LoadingSpinner disableText size={spinnerSize.sm} />
              ) : (
                <FatNumber>{invoices.data ? `${formatCurrency(invoices.data.overdue)} ${currency}` : "-"}</FatNumber>
              )}

              {partsBlockingStatus && (
                <ItemFooter>
                  <WarningCircle />
                  <LocalizedString id="spareParts.invoice.purchaseProhibition" />
                </ItemFooter>
              )}
            </DashboardItem>
            <DashboardItem
              route={routes.Invoices}
              title={translateString("managerDashboard.section.totalBalance")}
            >
              {invoices.loading ? (
                <LoadingSpinner disableText size={spinnerSize.sm} />
              ) : (
                <FatNumber>{invoices.data ? `${formatCurrency(invoices.data.balance)} ${currency}` : "-"}</FatNumber>
              )}
            </DashboardItem>
          </>
        )}
      </Grid>

      <Heading text={translateString("managerDashboard.statisticsForInstallations")}>
        <Disclaimer style={{ whiteSpace: "pre-line" }}>
          {translateString("managerDashboard.section.guideText", { numGraphs: maxNumberOfInstallationsToSelect })}
        </Disclaimer>
        <Container $marginLeft={4}>
          <InstallationFilter
            onChange={setInstallationIds}
            opened={isInstallationDialogOpen}
            toggle={toggleInstallationDialogOpen}
            installations={installations.data || []}
            selectedInstallationIds={installationIds || []}
            maxNumberOfInstallations={maxNumberOfInstallationsToSelect}
          />
        </Container>
      </Heading>
      <Grid spacing={GridSpacing.airy} itemsPerRow={GridRowExtraLargeItems} fallbackWidth={{ min: 500, max: 640 }}>
        <DashboardItem
          minHeight={200}
          route={routes.SpareParts}
          title={translateString("managerDashboard.section.submittedRFQs")}
        >
          {rfqs.loading ? (
            <FlexContainer $centered $stretchHeight>
              <LoadingSpinner disableText />
            </FlexContainer>
          ) : (
            <RFQChart
              end={timeframe.end}
              rfqs={rfqs.data || []}
              start={timeframe.start}
              installations={selectedInstallations}
            />
          )}
        </DashboardItem>

        <DashboardItem
          minHeight={200}
          route={routes.SpareParts}
          title={translateString("managerDashboard.section.purchasedSpareParts")}
        >
          {spos.loading ? (
            <FlexContainer $centered $stretchHeight>
              <LoadingSpinner disableText />
            </FlexContainer>
          ) : (
            <SparePartOrderChart
              end={timeframe.end}
              start={timeframe.start}
              installations={selectedInstallations}
              sparePartOrders={spos.data?.sparePartOrders || []}
              sparePartOrderCurrency={currency || ""}
            />
          )}
        </DashboardItem>

        {services.includes("Technical Knowledge") && (
          <>
            <DashboardItem
              minHeight={200}
              route={routes.Documents}
              title={translateString("managerDashboard.newlyPublishedDocuments")}
            >
              {docs.loading ? (
                <FlexContainer $centered $stretchHeight>
                  <LoadingSpinner disableText />
                </FlexContainer>
              ) : (
                <Table<IInstallation>
                  headersColSpans={[1, 2, 2]}
                  headers={[
                    "",
                    translateString("managerDashboard.bulletinsForImmediateAttention"),
                    translateString("managerDashboard.technicalDocuments"),
                  ]}
                  footersColSpans={[1, 4]}
                  footers={["", translateString("managerDashboard.lastNDays", { days: timeframe.days })]}
                  columns={[
                    "",
                    translateString("managerDashboard.documents.opened"),
                    translateString("managerDashboard.documents.unopened"),
                    translateString("managerDashboard.documents.opened"),
                    translateString("managerDashboard.documents.unopened"),
                  ]}
                  rows={selectedInstallations}
                  row={(installation) => {
                    const bulletin = docs.data?.bulletins.find((b) => b.installationId === installation.id);
                    const techDocuments = docs.data?.techDocuments.find((b) => b.installationId === installation.id);

                    return (
                      <tr key={installation.id}>
                        <Name>{installation.name}</Name>
                        {renderStatus("newlyPublishedDocuments", "bulletin", bulletin)}
                        {renderStatus("newlyPublishedDocuments", "techDocuments", techDocuments)}
                      </tr>
                    );
                  }}
                />
              )}
            </DashboardItem>
          </>
        )}

        <DashboardItem
          minHeight={200}
          route={routes.Requests}
          title={translateString("managerDashboard.section.submittedRequests")}
        >
          {submittedRequests.loading ? (
            <FlexContainer $centered $stretchHeight>
              <LoadingSpinner disableText />
            </FlexContainer>
          ) : (
            <RequestsChart
              end={timeframe.end}
              start={timeframe.start}
              submittedRequests={(submittedRequests.data || []).filter((request) =>
                installationIds?.includes(request.installationId)
              )}
            />
          )}
        </DashboardItem>

        <DashboardItem minHeight={200} title={translateString("managerDashboard.section.runningHours")}>
          <Table<IInstallation>
            rows={selectedInstallations}
            columns={[
              "",
              translateString("lastUpdate"),
              translateString("daysFromLastUpdate"),
              translateString("mostRecentUpdatedEngine"),
              translateString("runningHours"),
            ]}
            row={(installation) => {
              const [lastUpdatedEngine] = sortBy(
                filter(
                  equipment?.data || [],
                  (engine) =>
                    engine.installationId === installation.id &&
                    engine.productCategory === "Engines" &&
                    Boolean(engine.runningHoursDate)
                ),
                "runningHoursDate"
              ).reverse();

              const engines = filter(
                equipment.data,
                (engine) => engine.installationId === installation.id && engine.productCategory === "Engines"
              );

              return (
                <>
                  <tr key={installation.id}>
                    <Name onClick={() => toggleRow(installation.id)}>
                      {openedRows.includes(installation.id) ? (
                        <MinusIcon size={IconSize.XSmall} />
                      ) : (
                        <PlusIcon size={IconSize.XSmall} />
                      )}
                      {installation.name}
                    </Name>
                    <td>
                      {lastUpdatedEngine?.runningHoursDate ? formatDate(lastUpdatedEngine?.runningHoursDate) : null}
                    </td>
                    <td>
                      {lastUpdatedEngine?.runningHoursDate
                        ? moment().diff(lastUpdatedEngine?.runningHoursDate, "days")
                        : null}
                    </td>
                    <td>{lastUpdatedEngine?.description || lastUpdatedEngine?.serialNumber}</td>
                    <td
                      style={{ position: "relative" }}
                      onMouseLeave={() => setTooltipOpenFor({ table: "", installationId: "", id: "" })}
                      onMouseEnter={() =>
                        setTooltipOpenFor({
                          id: "runningHours",
                          table: "runningHours",
                          installationId: installation.id,
                        })
                      }
                    >
                      {lastUpdatedEngine?.runningHours}
                      {lastUpdatedEngine?.runningHours &&
                      tooltipOpenFor.table === "runningHours" &&
                      tooltipOpenFor.installationId === installation.id ? (
                        <StaticTooltip
                          width={220}
                          arrow={false}
                          xOffset={-200}
                          title={translateString("latestUpdates")}
                        >
                          <TooltipGrid>
                            <TooltipTitle>{translateString("updated")}</TooltipTitle>
                            <TooltipTitle>{translateString("runningHours")}</TooltipTitle>
                            {map(sortBy(engines, "runningHoursDate").reverse().slice(0, 5), (engine) =>
                              engine.runningHours ? (
                                <>
                                  {engine.runningHoursDate ? <span>{formatDate(engine.runningHoursDate)}</span> : null}
                                  <span>{engine.runningHours}</span>
                                </>
                              ) : null
                            )}
                          </TooltipGrid>
                        </StaticTooltip>
                      ) : null}
                    </td>
                  </tr>

                  {openedRows.includes(installation.id)
                    ? map(engines, (engine) => (
                        <EquipmentRow>
                          <Name>{engine.description || engine.serialNumber}</Name>
                          <td>{engine.runningHoursDate ? formatDate(engine.runningHoursDate) : null}</td>
                          <td>{engine.runningHoursDate ? moment().diff(engine.runningHoursDate, "days") : null}</td>
                          <td></td>
                          <td>{engine.runningHours}</td>
                        </EquipmentRow>
                      ))
                    : null}
                </>
              );
            }}
          />
        </DashboardItem>
        {services.includes("Monitoring") ? (
          <DashboardItem
            minHeight={200}
            route={routes.Reports}
            title={translateString("managerDashboard.section.newlyPublishedAssetDiagnosticReports")}
          >
            <Table<IInstallation>
              headers={["", "", ""]}
              footersColSpans={[1, 2]}
              footers={["", translateString("managerDashboard.lastNDays", { days: timeframe.days })]}
              columns={[
                "",
                translateString("managerDashboard.documents.opened"),
                translateString("managerDashboard.documents.unopened"),
              ]}
              rows={selectedInstallations}
              row={(installation) => {
                const cmrReports = getReadStatusForCMReports(installationIds!, reports.data?.cmReports || []).find(
                  (cmr) => cmr.installationId === installation.id
                );
                return (
                  <tr key={installation.id}>
                    <Name>{installation.name}</Name>
                    {renderStatus("newlyPublishedAsset", "newlyPublishedAsset", cmrReports)}
                  </tr>
                );
              }}
            />
          </DashboardItem>
        ) : null}
      </Grid>
    </div>
  );
};

export const ManagerDashboardViewComponentWrapper = (props: IManagerDashboardViewComponentStateProps & IManagerDashboardViewComponentDispatchProps) => {
  React.useEffect(() => {
    if (!props.installations.data) {
      props.getInstallations();
    }
    if (!props.equipment.data) {
      props.getEquipment();
    }
  }, []);

  // Mount main component separately when not loading to reload invoices, requests etc. This matters when changing hierarchy accounts
  return !props.isReady ? <LoadingSpinner /> : <ManagerDashboardViewComponent {...props} />;
};

const FatNumber = styled.div`
  font-weight: 700;
  ${fluidTypography(fontSizes.xl.min, fontSizes.xl.max)};
  ${baseTextStyles}
`;

const ItemFooter = styled(Disclaimer)`
  text-align: center;
  margin: 0;
`;

const WarningCircle = styled.span`
  display: inline-block;
  border-radius: 100%;
  background-color: ${colors.notification.error};
  width: 1em;
  height: 1em;
  margin-right: ${spacer(1)};
`;

const DashboardItemInner = styled.div`
  border-radius: ${defaultBorderRadius};
  background: ${(props) => props.theme.navigationButton.background};
  height: 100%;
  padding: ${spacer(3)};
  box-sizing: border-box;
`;

const DashboardItemWrapper = styled.div<IDashboardItemWrapperProps>`
  max-width: 100%;
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : "auto")};
`;

const Title = styled(H4).attrs({ $uppercase: true })`
  text-align: center;
  margin: 0 0 ${spacer(2)} 0;
`;

const Content = styled.div`
  height: 100%;
  text-align: center;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  }
`;

const TooltipTitle = styled.div`
  font-size: 12px;
  text-align: left;
  color: ${colors.secondary.gray60};
`;

const TooltipGrid = styled.div`
  display: grid;
  font-size: 12px;
  text-align: left;
  gap: 0.25rem 0.5rem;
  grid-template-columns: 1fr 1fr;
`;

const UnreadDocument = styled.div`
  margin-bottom: ${spacer(1)};
  & * {
    text-align: left;
  }
`;

const Name = styled.td`
  cursor: pointer;
  text-align: left;
  user-select: none;
  white-spance: nowrap;
  svg {
    margin-right: 10px;
  }
`;

const ReadCounter = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  margin: 0 auto;
  line-height: 1;
  border-radius: 50%;
  padding: ${spacer(1)};
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
`;

const EquipmentRow = styled.tr`
  td:first-child {
    padding-left: 30px;
  }

  td {
    border-bottom: 1px solid ${colors.secondary.bluegray90};
  }
`;

export default ManagerDashboardViewComponentWrapper;
