import { ISectionBase64Image } from "online-services-types";
import * as React from "react";
import { IAPICallParams } from "src/APIFetch";
import { ImageCarousel } from "src/components/ImageCarousel";
import { LocalizedString } from "src/components/Localization";

export interface IPartImagesComponentStateProps {
  isReady: boolean;
}
export interface IPartImagesComponentDispatchProps {
  getImages(params: IAPICallParams): void;
}

export interface IPartImagesComponentOwnProps {
  productReferenceType: string | null;
  sectionId: string;
  images: ISectionBase64Image[];
  useOnboardingMockData?: boolean;
}

export type IPartImagesComponentProps = IPartImagesComponentStateProps &
  IPartImagesComponentDispatchProps &
  IPartImagesComponentOwnProps;

export class PartImagesComponent extends React.Component<IPartImagesComponentProps> {
  public componentDidMount() {
    /*
      NOTE:
      there are only limited amount of parts with images in dev env.
      So a workaround in development is to uncomment the below code so that every part will have the same images.
      After working comment it again.

      Also comment out code in file catalogueSections(function "getSectionImages"), so that function returns "sectionImages" without any conditions.
    */
    // this.props.getImages({
    //   prodRefType: 'W20',
    //   section: 'man-10',
    // });

    if (this.props.productReferenceType) {
      this.props.getImages({
        prodRefType: this.props.productReferenceType,
        section: this.props.sectionId,
      });
    }
  }
  public render() {
    if (!this.props.isReady && this.props.images && this.props.images.length === 0) {
      return (
        <div>
          <LocalizedString id="loading" />
        </div>
      );
    }

    if (this.props.images && this.props.images.length === 0) {
      return (
        <div>
          <LocalizedString id="spareParts.noPicturesFound" />
        </div>
      );
    }

    return <ImageCarousel maxHeight={"200px"} images={this.props.images} />;
  }
}
