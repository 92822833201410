import styled from "styled-components";
import colors from "src/design-system/Tokens/colors";
import { H2, P, pStyles } from "src/design-system/Tokens/typography";
import { spacer } from "src/design-system/Tokens/tokens";
import { Container } from "src/design-system/Container";

export const OnboardingStepWrapper = styled.div`
  ${H2} {
    color: ${colors.primary.black};
  }

  ${P} {
    padding-top: ${spacer(3)};
    color: ${colors.primary.black};
  }

  ${Container} {
    ${pStyles};
    color: ${colors.primary.black};
  }
`;
