import { Button, ButtonVariant } from "@wartsila/ui-kit";
import { IInstallation, ISFOCHistory } from "online-services-types";
import * as React from "react";
import { useInView } from "react-intersection-observer";

import { APIFetch } from "src/APIFetch";
import { ResponsiveTable } from "src/components/ResponsiveTable";
import { Container, FlexContainer } from "src/design-system/Container";
import { Select } from "src/design-system/Select";
import { DropdownStyle, ISelectObject } from "src/design-system/Select/Select";
import { H2 } from "src/design-system/Tokens/typography";
import { translateString } from "src/util/localization";
import { Filters, QuarterSelectDialog, ResponsiveViewContainer, StickyFiltersContainer } from "./sfoc.components";
import { useAllowExportAll, useInstallations, useSFOCHistory, useSFOCHistoryViewStateProps } from "./sfoc.hooks";
import { SFOCHistoryFilters } from "./sfoc.types";
import { columns, downloadSFOCHistoryCsv, quarterNumbers, redirectToCSVExport, statuses } from "./sfoc.utils";

const defaultFiltersState: SFOCHistoryFilters = {
  limit: 10,
  status: "",
  installation: "",
  quarterNumber: "",
};

export const SFOCHistoryView = () => {
  const { userInfo } = useSFOCHistoryViewStateProps();

  const [filters, setFilters] = React.useState<SFOCHistoryFilters>(defaultFiltersState);
  const [removingRow, setRemovingRow] = React.useState<boolean>(false);
  const [exportDialogOpen, setExportDialogOpen] = React.useState<boolean>(false);
  const [csvLoading, setCSVLoading] = React.useState<boolean>(false);

  const { ref, inView } = useInView({ initialInView: true });
  const history = useSFOCHistory(filters);
  const installations = useInstallations();
  const allowExportAll = useAllowExportAll();

  const onChangeFilter = ({ key, value }: { key: string; value: string }) => {
    setFilters((f) => ({ ...f, [key]: value }));
  };

  const removeRow = async (id: string) => {
    setRemovingRow(true);
    await new APIFetch("service/sfoc-report").delete(id);
    await history.refetch();
    setRemovingRow(false);
  };

  const exportCSV = async (fromQuarterNumber: number, toQuarterNumber: number, delimiter: string, exportAllBrands = false) => {
    setExportDialogOpen(false);
    setCSVLoading(true);
    if (allowExportAll && exportAllBrands) {
      redirectToCSVExport(fromQuarterNumber, toQuarterNumber, delimiter);
    } else {
      await downloadSFOCHistoryCsv(fromQuarterNumber, toQuarterNumber, delimiter);
    }
    setCSVLoading(false);
  };

  React.useEffect(() => {
    if (inView) {
      history.fetchNextPage();
    }
  }, [inView]);

  return (
    <ResponsiveViewContainer $fullwidth $marginTop={4}>
      <QuarterSelectDialog
        isOpen={exportDialogOpen}
        showExportAllBrands={allowExportAll}
        onSubmit={exportCSV}
        onCancel={() => setExportDialogOpen(false)}
      />
      <FlexContainer>
        <StickyFiltersContainer>
          <Filters>
            <Select
              isClearable
              isSearchable
              options={statuses}
              value={filters.status}
              label={translateString("sfoc.status")}
              dropdownStyle={DropdownStyle.FullWidth}
              getOptionLabel={(option: ISelectObject<string>) => option.item}
              getOptionValue={(option: ISelectObject<string>) => option.item}
              onChange={(value: string = "") => onChangeFilter({ key: "status", value })}
            />
            <Select
              isClearable
              isSearchable
              options={quarterNumbers}
              value={filters.quarterNumber}
              label={translateString("sfoc.quarter")}
              dropdownStyle={DropdownStyle.FullWidth}
              getOptionValue={(option: ISelectObject<string>) => option.item}
              getOptionLabel={(option: ISelectObject<string>) => `Q${option.item}`}
              onChange={(value: string = "") => onChangeFilter({ key: "quarterNumber", value })}
            />
            <Select
              isClearable
              isSearchable
              options={installations.data}
              label={translateString("sfoc.vessel")}
              dropdownStyle={DropdownStyle.FullWidth}
              getOptionValue={(option: ISelectObject<IInstallation>) => option.item.id}
              getOptionLabel={(option: ISelectObject<IInstallation>) => option.item.name}
              value={installations.data?.find((installation) => installation?.id === filters.installation)}
              onChange={(installation: IInstallation | null) =>
                onChangeFilter({ key: "installation", value: installation?.id || "" })
              }
            />
          </Filters>
        </StickyFiltersContainer>
      </FlexContainer>

      <Container $fullwidth>
        <FlexContainer $spaceBetween $marginBottom={1}>
          <H2>{translateString("spareParts.purchases")}</H2>
          <FlexContainer>
            <Button disabled={csvLoading} onClick={() => setExportDialogOpen(true)}>
              {translateString("export")}
            </Button>
            <FlexContainer $marginLeft={1}>
              <Select dropdownStyle={DropdownStyle.Toolbar} placeholder={translateString("select.sortPlaceholder")} />
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <Container $marginBottom={2}>
          <ResponsiveTable<ISFOCHistory>
            rows={history.data?.pages.flat()}
            columns={columns(removingRow, removeRow, userInfo)}
          />
        </Container>

        {history.hasNextPage ? (
          <Button
            ref={ref}
            variant={ButtonVariant.Warning}
            loading={history.isFetchingNextPage}
            onClick={() => history.fetchNextPage()}
          >
            Load more
          </Button>
        ) : null}
      </Container>
    </ResponsiveViewContainer>
  );
};
