import jwt_decode from "jwt-decode";
import { IInfoJWTData } from "online-services-types";

const getPersonalizedLocalStorageKey = (key: string) => {
  const infoJwt = localStorage.getItem("info");
  if (!infoJwt) {
    return key;
  }
  const decodedInfoJWT: IInfoJWTData = jwt_decode(infoJwt);
  return `${key}-${decodedInfoJWT.userInfo.userId}`;
};

export const getSelectedInstallationId = () => {
  return localStorage.getItem(getPersonalizedLocalStorageKey("selectedInstallationId")) || undefined;
};

export const getLastSeenVersion = () => {
  return localStorage.getItem(getPersonalizedLocalStorageKey("lastSeenVersion")) || "";
};

export const getSelectedInstallationName = () => {
  return localStorage.getItem(getPersonalizedLocalStorageKey("selectedInstallationName")) || undefined;
};

export const getHideRunningHoursCampaign = (userId: string) => {
  return localStorage.getItem(getPersonalizedLocalStorageKey("hideRunningHoursCampaign" + userId)) === "true";
};

export const getSelectedColorTheme = () => {
  return localStorage.getItem(getPersonalizedLocalStorageKey("theme")) || "dark";
};

export const getUsingOpenSearch = () => {
  return localStorage.getItem(getPersonalizedLocalStorageKey("useOpenSearch")) || "false";
};

export const setSelectedInstallationId = (installationId?: string) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("selectedInstallationId"), installationId || "");
};

export const setSelectedInstallationName = (installationName?: string) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("selectedInstallationName"), installationName || "");
};

export const setNewsSeenInModal = (newsItemIds: string[]) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("seenNewsInModal"), JSON.stringify(newsItemIds));
};

export const setHideRunningHoursCampaign = (userId: string) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("hideRunningHoursCampaign" + userId), "true");
};

export const setLastSeenVersion = (version: string) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("lastSeenVersion"), version);
};

export const setSelectedColorTheme = (theme?: string) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("theme"), theme || "");
};

export const switchOpenSearch = (using: string) => {
  return localStorage.setItem(getPersonalizedLocalStorageKey("useOpenSearch"), using || "false");
};

export const getNewsSeenInModal = (): string[] => {
  const value = localStorage.getItem(getPersonalizedLocalStorageKey("seenNewsInModal"));
  if (!value) {
    return [];
  }
  try {
    const ids = JSON.parse(value);

    if (!(ids instanceof Array)) {
      return [];
    }

    return ids;
  } catch (error) {
    return [];
  }
};

export const clearLocallyStoredSettings = () => {
  setSelectedInstallationId();
  setSelectedInstallationName();
};

export const setEULAAccept = (state: boolean) => {
  localStorage.setItem(getPersonalizedLocalStorageKey("eulaAccepted"), state ? "true" : "false");
};

export const getEULAAcceptedLocalStorage = () => {
  const state = localStorage.getItem(getPersonalizedLocalStorageKey("eulaAccepted"));
  if (state === null) {
    return null;
  }

  return state === "true";
};
