import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";
export const NightIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Night</title>
    <path
      d="M29.2475999,24 C29.2787283,17.1351634 33.7802873,11.0921041 40.3489353,
    9.09715696 C39.0116224,7.90569538 37.5214253,6.89785153 35.91773,6.10026286 C27.5063655,
    1.90908584 17.3115332,4.13987872 11.4192285,11.460928 C5.52692384,18.7819773 5.52692384,
    29.2180227 11.4192285,36.539072 C17.3115332,43.8601213 27.5063655,46.0909142 35.91773,
    41.8997371 C37.5214253,41.1021485 39.0116224,40.0943046 40.3489353,38.902843 C33.7802873,
    36.9078959 29.2787283,30.8648366 29.2475999,24 M27.0319973,39.5558628 C18.4407315,
    39.5558628 11.4761345,32.5912658 11.4761345,24 C11.4761345,15.4087342 18.4407315,
    8.44413724 27.0319973,8.44413724 C28.5443776,8.44598559 30.0485878,8.66592233 31.4981858,
    9.09715696 C27.2416901,12.8811777 24.8063759,18.3046889 24.8063759,24 C24.8063759,
    29.6953111 27.2416901,35.1188223 31.4981858,38.902843 C30.0485878,39.3340777 28.5443776,
    39.5540144 27.0319973,39.5558628"
    ></path>
  </IconBase>
);
