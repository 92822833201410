import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAppState } from "src/redux";
import { APIFetchStatus } from "src/APIFetch";
import {
  DesignerViewComponent,
  IDesignerViewDispatchProps,
  IDesignerViewStateProps,
} from "src/views/DesignerView/DesignerViewComponent";

const mapStateToProps = (state: IAppState): IDesignerViewStateProps => {
  return {
    news: state.newsnew.data || [],
    isReady: state.marineDocumentTypes.status === APIFetchStatus.Success,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export const DesignerViewContainer = connect<IDesignerViewStateProps, IDesignerViewDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(DesignerViewComponent);
export default DesignerViewContainer;
