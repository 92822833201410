import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const PartlySatisfied = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Partly satisfied</title>
    <g id="Icon_Text" data-name="Icon Text" color={props.color}>
      <path d="M24,46C13,46,4.12,36.59,4.12,25S13,4,24,4s19.88,9.42,19.88,21S35,46,24,46ZM24,8C15.24,8,8.12,15.63,8.12,25S15.24,42,24,42s15.88-7.63,15.88-17S32.76,8,24,8Z" />
      <path d="M22,21.45H19a2.75,2.75,0,0,0-2.5-2.94A2.75,2.75,0,0,0,14,21.45H11a5.74,5.74,0,0,1,5.5-5.94A5.74,5.74,0,0,1,22,21.45Z" />
      <path d="M37,21.45H34a2.75,2.75,0,0,0-2.5-2.94A2.75,2.75,0,0,0,29,21.45H26a5.74,5.74,0,0,1,5.5-5.94A5.74,5.74,0,0,1,37,21.45Z" />
      <rect x="15.5" y="30.01" width="17" height="4" />
    </g>
  </IconBase>
);
