import * as React from "react";
import { CommentVerb } from "src/components/Comments/CommentsComponent";
import { LocalizedString } from "src/components/Localization";
import { RequestComments } from "src/components/RequestComments";
import { NotificationBase } from "src/components/SupportList/NotificationBase";
import { FlexContainer } from "src/design-system/Container";
import { LinkWrapper } from "src/design-system/Link";
import { KeyValuePair, KeyValueValue } from "src/design-system/Table/Table";
import { spacer } from "src/design-system/Tokens/tokens";
import { Disclaimer } from "src/design-system/Tokens/typography";
import { ICombinedRequestViewModel, RequestType } from "src/models/warrantyClaim";
import { translateString } from "src/util/localization";
import { RequestStatus } from "src/util/requestStatus";
import styled from "styled-components";

interface INewRequestComponentProps {
  item: ICombinedRequestViewModel;
  onComment?: () => void;
  onDismiss?: () => void;
  isReadOnly: boolean;
}

interface INewRequestComponentState {
  commentsOpen: boolean;
}

const CommentLinkWrapper = styled.div`
  margin-top: ${spacer(1)};
`;

export class NewRequestComponent extends React.Component<INewRequestComponentProps, INewRequestComponentState> {
  constructor(props: INewRequestComponentProps) {
    super(props);

    this.state = {
      commentsOpen: false,
    };
  }

  public render() {
    let statusText = translateString("request.notification.newRequest");

    if (this.props.item.requestType === RequestType.SparePartClaim) {
      statusText = translateString("request.notification.newSparePartClaim");
    }

    if (
      (this.props.item.requestType === RequestType.TechRequest ||
        this.props.item.requestType === RequestType.OperationalSupportRequest) &&
      this.props.item.status === "Sent to distributor"
    ) {
      statusText = translateString("request.notification.newRequestDistributor");
    }

    return (
      <NotificationBase onDismiss={this.props.onDismiss}>
        {this.state.commentsOpen ? (
          <KeyValuePair>
            <Disclaimer>
              <LocalizedString id="commenting" />
            </Disclaimer>
            <KeyValueValue>
              <RequestComments
                request={this.props.item}
                isCommentingDisabled={this.props.isReadOnly}
                requestCanBeReopened={false}
                verb={CommentVerb.Post}
                onCommentPosted={this.props.onComment}
                onCancel={this.handleCancelButton}
              />
            </KeyValueValue>
          </KeyValuePair>
        ) : (
          <FlexContainer $column={true}>
            <div>{statusText}</div>
            {!this.props.isReadOnly &&
              !!this.props.onComment &&
              !this.state.commentsOpen &&
              this.isCommentingAllowed(this.props.item) && (
                <CommentLinkWrapper>
                  <LinkWrapper onClick={this.handleCommentButton}>
                    <LocalizedString id="request.comment" />
                  </LinkWrapper>
                </CommentLinkWrapper>
              )}
          </FlexContainer>
        )}
      </NotificationBase>
    );
  }

  private readonly handleCommentButton = () => {
    this.setState({ commentsOpen: true });
  };

  private readonly handleCancelButton = () => {
    this.setState({ commentsOpen: false });
  };

  private readonly isCommentingAllowed = (item: ICombinedRequestViewModel) => {
    return item.status === RequestStatus.InProgress || item.status === RequestStatus.WaitingForCustomerActions;
  };
}
