// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_ru: ITranslations = {
  ru: {
    "user.addNewUser": "Добавить пользователя",
    "user.allUsersInAccount": "Все пользователи в вашей учетной записи",
    "user.department": "Отдел",
    "user.digest.addAllInstallations": "Добавить все установки",
    "user.digest.addAllInstallations:energy": "Добавить все электростанции",
    "user.digest.addAllInstallations:marine": "Добавить все суда",
    "user.digest.period.daily": "Ежедневно",
    "user.digest.period.monthly": "Ежемесячно",
    "user.digest.period.never": "Никогда",
    "user.digest.period.weekly": "Еженедельно",
    "user.digest.service.reports": "Отчеты",
    "user.digest.service.requests": "Запросы",
    "user.digest.service.spareParts": "Запасные части",
    "user.digest.service.technicalDocuments": "Технические документы",
    "user.digest.validation.noInstallationsSelected": "Требуются установки.",
    "user.digest.validation.noInstallationsSelected:energy": "Требуются электростанции.",
    "user.digest.validation.noInstallationsSelected:marine": "Требуются суда.",
    "user.digest.validation.noServicesSelected": "Требуется сервисное обслуживание.",
    "user.digestDescription.daily": "Ежедневно для одной установки",
    "user.digestDescription.daily.plural": "Ежедневно для {numberOfInstallations} установок",
    "user.digestDescription.daily.plural:energy": "Ежедневно для {numberOfInstallations} электростанций",
    "user.digestDescription.daily.plural:marine": "Ежедневно для {numberOfInstallations} судов",
    "user.digestDescription.daily:energy": "Ежедневно для одной электростанции",
    "user.digestDescription.daily:marine": "Ежедневно для одного судна",
    "user.digestDescription.monthly": "Ежемесячно для одной установки",
    "user.digestDescription.monthly.plural": "Ежедневно для {numberOfInstallations} судов",
    "user.digestDescription.monthly.plural:energy": "Ежемесячно для {numberOfInstallations} электростанций",
    "user.digestDescription.monthly.plural:marine": "Ежемесячно для {numberOfInstallations} судов",
    "user.digestDescription.monthly:energy": "Ежемесячно для одной электростанции",
    "user.digestDescription.monthly:marine": "Ежемесячно для одного судна",
    "user.digestDescription.never": "Выключена",
    "user.digestDescription.weekly": "Еженедельно каждое воскресенье для одной установки",
    "user.digestDescription.weekly.plural": "Еженедельно каждое воскресенье для {numberOfInstallations} установок",
    "user.digestDescription.weekly.plural:energy": "Еженедельно каждое воскресенье для {numberOfInstallations} электростанций",
    "user.digestDescription.weekly.plural:marine": "Еженедельно каждое воскресенье для {numberOfInstallations} судов",
    "user.digestDescription.weekly:energy": "Еженедельно каждое воскресенье для одной электростанции",
    "user.digestDescription.weekly:marine": "Еженедельно каждое воскресенье для одного судна",
    "user.digestEmail": "Составить краткое изложение электронной почты",
    "user.digestEmailSettings": "Настройки краткого изложения электронной почты",
    "user.digestSettingsForm.frequencyOfDigest": "Частота краткого изложения",
    "user.digestSettingsForm.servicesOfInterest": "Виды сервисного обслуживания, представляющие интерес",
    "user.email": "Адрес электронной почты",
    "user.emailIsRequired": "Требуется электронная почта",
    "user.emailShort": "Электронная почта",
    "user.firstname": "Имя",
    "user.firstnameIsRequired": "Требуется имя",
    "user.lastname": "Фамилия",
    "user.lastnameIsRequired": "Требуется фамилия",
    "user.name": "Название",
    "user.notificationSettings": "НАСТРОЙКИ УВЕДОМЛЕНИЙ",
    "user.phone": "Телефон",
    "user.phoneNumber": "Номер телефона",
    "user.reasonForRemoval": "Причина для удаления",
    "user.reasonForRemovalIsRequired": "Требуется причина для удаления",
    "user.removeUser": "Удалить пользователя",
    "user.title": "Название",
    "user.userInformation": "ИНФОРМАЦИЯ О ПОЛЬЗОВАТЕЛЕ",
    "user.userPassword": "Пароль",
  },
};
