import { TooltipArrow } from "@wartsila/ui-kit";
import sortBy from "lodash/sortBy";
import React, { useCallback, useEffect, useState } from "react";
import { LocalizedString } from "src/components/Localization";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import colors from "src/design-system/Tokens/colors";
import { Tooltip } from "src/design-system/Tooltip";
import { IconSize, MinusIcon, PlusIcon } from "src/icons";
import {
  Circle,
  ISFOCFleetData,
  largeCircleSize,
  StatusCellTd,
  statusColorsWithYesNo,
  Statuses,
  StatusTable,
  THead,
  TUnderlinedBody,
  ValidationStatus,
  YesNo,
} from "src/util/sfoc/sfocCharts";
import styled from "styled-components";
import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";

const Name = styled.td`
  text-align: left;
  cursor: pointer;
  svg {
    margin-right: 10px;
  }
`;

const TFoot = styled.tfoot`
  & td {
    vertical-align: top;
    text-align: left;
  }

  & td:first-child {
    border-right: none;
  }
`;

const BrandRow = styled.tr``;
const InstallationRaw = styled.tr`
  & td:first-child {
    padding-left: 20px;
  }
`;
const EquipmentRow = styled.tr`
  & td:first-child {
    padding-left: 60px;
  }
`;

interface IStatusCellProps {
  status: keyof typeof statusColorsWithYesNo;
  width?: string;
  children?: React.ReactNode;
  onEnter?(): void;
  onExit?(): void;
  onClick?(): void;
}

export const StatusCell = (props: IStatusCellProps) => (
  <StatusCellTd
    onMouseEnter={props.onEnter}
    onMouseLeave={props.onExit}
    onClick={props.onClick}
    width={props.width || "25%"}
  >
    {<Circle color={statusColorsWithYesNo[props.status] || colors.uptime.nodata} size={largeCircleSize} />}
    {props.children}
  </StatusCellTd>
);

interface IMeasurementStatusChart {
  quarter: string;
  chartData: ISFOCFleetData[];
}

interface IBrand {
  id: string;
  brandFullName: string;
  brandInstallations: IBrandInstallation[];
}

interface IBrandInstallation {
  id: string;
  brandId: string;
  name: string;
  installationEquipment: IBrandInstallationEquipment[];
}

interface IBrandInstallationEquipment {
  id: string;
  installationId: string;
  brandId: string;
  name: string;
  measured: boolean;
  fuelSampleReceived: boolean;
  validationAndApproval: ValidationStatus;
  reasonForRejection: string;
}

const Reason = (props: { reason: string }) => (
  <div style={{ overflow: "visible", position: "absolute" }}>
    <Tooltip arrow={TooltipArrow.Right} title={props.reason} />
  </div>
);

export const MeasurementStatusChart = (props: IMeasurementStatusChart) => {
  const [visibleTooltip, setVisibleTooltip] = useState<string>();
  const [statusFilter, setStatusFilter] = useState(Object.keys(statusColorsWithYesNo));
  const [brandData, setBrandData] = useState<IBrand[]>([]);
  const [openedRows, setOpenedRows] = useState<string[]>([]);
  const hasData =
    props.chartData.filter((dataSet: ISFOCFleetData) => dataSet.quarter === `Q${props.quarter}`).length > 0;

  useEffect(() => {
    setOpenedRows([]);
  }, [props.quarter, props.chartData]);

  useEffect(() => {
    const fleetData = props.chartData.filter((dataSet: ISFOCFleetData) => dataSet.quarter === `Q${props.quarter}`);
    const tempBrandData: IBrand[] = [];

    fleetData.forEach((dataSet) => {
      const existingBrand = tempBrandData.find((brand) => brand.id === dataSet.accountId);

      if (existingBrand === undefined) {
        tempBrandData.push({
          id: dataSet.accountId,
          brandFullName: dataSet.accountFullName ? dataSet.accountFullName : dataSet.accountName,
          brandInstallations: mapInstallationById(dataSet, tempBrandData),
        });
      } else {
        const updatedInstallations = existingBrand.brandInstallations.concat(
          mapInstallationById(dataSet, tempBrandData)
        );

        existingBrand.brandInstallations = Array.from(new Set(updatedInstallations.map((inst) => inst.id)))
          .map((id) => updatedInstallations.find((i) => i.id === id))
          .filter((inst): inst is IBrandInstallation => inst !== undefined);
      }
    });

    setBrandData(
      tempBrandData.filter(
        (brand) =>
          brand.brandInstallations.filter((i) => i.installationEquipment.filter(applyFilter).length > 0).length > 0
      )
    );
  }, [props.quarter, props.chartData, statusFilter]);

  const mapInstallationById = (data: ISFOCFleetData, tempBrandData: IBrand[]) => {
    const existingInstallation = tempBrandData
      .find((brand) => brand.id === data.accountId)
      ?.brandInstallations.find((inst) => inst.brandId === data.accountId && inst.id === data.installationId);

    if (existingInstallation === undefined) {
      return [
        {
          id: data.installationId,
          brandId: data.accountId,
          name: data.installationName,
          installationEquipment: mapEquipmentById(data),
        },
      ] as IBrandInstallation[];
    } else {
      existingInstallation.installationEquipment = existingInstallation.installationEquipment.concat(
        mapEquipmentById(data)
      );
      return [existingInstallation];
    }
  };

  const isDataMeasured = (data: ISFOCFleetData) => {
    return data?.quarter && data?.testValidation !== "Suspended" && data?.testValidation !== "Not received";
  };

  const mapEquipmentById = (data: ISFOCFleetData) => {
    return [
      {
        id: data.equipmentId,
        installationId: data.installationId,
        brandId: data.accountId,
        name: data.equipmentName,
        measured: isDataMeasured(data),
        fuelSampleReceived: data.isFuelSampleReceived,
        validationAndApproval: data.testValidation,
        reasonForRejection: data.reasonForRejection,
      },
    ] as IBrandInstallationEquipment[];
  };

  const applyFilter = (item: IBrandInstallationEquipment) => {
    const allStatuses: (ValidationStatus | "Yes" | "No")[] = [
      item.validationAndApproval,
      item.measured ? "Yes" : "No",
      item.fuelSampleReceived ? "Yes" : "No",
    ];
    return allStatuses.some((status) => statusFilter.includes(status));
  };

  const isFleetMeasured = (brands: IBrand[], field: keyof IBrandInstallationEquipment) => {
    let measured = true; // measure or fuel sample received
    brands.forEach((brand) => {
      brand.brandInstallations.forEach((inst) => {
        inst.installationEquipment.forEach((eq) => {
          if (!eq[field]) {
            measured = false;
          }
        });
      });
    });
    return measured;
  };

  const isBrandMeasured = (installations: IBrandInstallation[], field: keyof IBrandInstallationEquipment) => {
    let measured = true; // measure or fuel sample received
    installations.forEach((inst) => {
      inst.installationEquipment.forEach((eq) => {
        if (!eq[field]) {
          measured = false;
        }
      });
    });
    return measured;
  };

  const isInstallationMeasured = (
    equipment: IBrandInstallationEquipment[],
    field: keyof IBrandInstallationEquipment
  ) => {
    let measured = true; // measure or fuel sample received
    equipment.forEach((eq) => {
      if (!eq[field]) {
        measured = false;
      }
    });
    return measured;
  };

  const percentageOfApprovedOverAllBrands = (brands: IBrand[]) => {
    let amountOfEngines = 0;
    let amountOfApproved = 0;
    brands.forEach((brand) => {
      brand.brandInstallations.forEach((inst) => {
        amountOfEngines += inst.installationEquipment.length;
        inst.installationEquipment.forEach((eq) => {
          if (eq.validationAndApproval === "Approved") {
            amountOfApproved += 1;
          }
        });
      });
    });

    return getPercentage(amountOfApproved, amountOfEngines);
  };

  const percentageOfApprovedOverAllInstallations = (installations: IBrandInstallation[]) => {
    let amountOfEngines = 0;
    let amountOfApproved = 0;
    installations.forEach((inst) => {
      amountOfEngines += inst.installationEquipment.length;
      inst.installationEquipment.forEach((eq) => {
        if (eq.validationAndApproval === "Approved") {
          amountOfApproved += 1;
        }
      });
    });

    return getPercentage(amountOfApproved, amountOfEngines);
  };
  const percentageOfApprovedOverAllEngines = (equipment: IBrandInstallationEquipment[]) => {
    let amountOfEngines = equipment.length;
    let amountOfApproved = 0;
    equipment.forEach((eq) => {
      if (eq.validationAndApproval === "Approved") {
        amountOfApproved += 1;
      }
    });

    return getPercentage(amountOfApproved, amountOfEngines);
  };

  const getPercentage = (amountOfApproved: number, amountOfEngines: number) => {
    return ((amountOfApproved / amountOfEngines) * 100).toFixed(0);
  };

  const onRowOpenClose = (id: string) => {
    if (openedRows.includes(id)) {
      setOpenedRows(openedRows.filter((row) => row !== id));
    } else {
      setOpenedRows([...openedRows, id]);
    }
  };
  const toggleFilter = useCallback(
    (status: string) =>
      setStatusFilter((old) => (old.includes(status) ? old.filter((s) => s !== status) : old.concat(status))),
    []
  );
  return (
    <DashboardItem title={<LocalizedString id="sfoc.quarterMeasurementStatus" values={{ quarter: props.quarter }} />}>
      {hasData ? (
        <StatusTable>
          <THead>
            <tr>
              <th />
              <th>
                <LocalizedString id="sfoc.measurementPhase.measurementReceived" />
              </th>
              <th>
                <LocalizedString id="sfoc.measurementPhase.fuelSampleReceived" />
              </th>
              <th>
                <LocalizedString id="sfoc.measurementPhase.validationAndApproval" />
              </th>
            </tr>
          </THead>
          <TUnderlinedBody>
            <BrandRow key="fleet" data-testid="fleet">
              <Name>{<LocalizedString id="sfoc.fleet" />}</Name>
              <StatusCell status={isFleetMeasured(brandData, "measured") ? "Yes" : "No"} />
              <StatusCell status={isFleetMeasured(brandData, "fuelSampleReceived") ? "Yes" : "No"} />
              <th>{percentageOfApprovedOverAllBrands(brandData)}</th>
            </BrandRow>
            {brandData.map((item: IBrand) => (
              <>
                <BrandRow key={item.id} data-testid={item.id}>
                  <Name onClick={() => onRowOpenClose(item.id)}>
                    {openedRows.includes(item.id) ? (
                      <MinusIcon size={IconSize.XSmall} />
                    ) : (
                      <PlusIcon size={IconSize.XSmall} />
                    )}
                    {item.brandFullName}
                  </Name>
                  <StatusCell status={isBrandMeasured(item.brandInstallations, "measured") ? "Yes" : "No"} />
                  <StatusCell status={isBrandMeasured(item.brandInstallations, "fuelSampleReceived") ? "Yes" : "No"} />
                  <th>{percentageOfApprovedOverAllInstallations(item.brandInstallations)}</th>
                </BrandRow>
                {openedRows.includes(item.id) &&
                  item.brandInstallations
                    .filter((i) => i.installationEquipment.filter(applyFilter).length > 0)
                    .map((inst: IBrandInstallation) => (
                      <>
                        <InstallationRaw key={inst.id} data-testid={inst.id}>
                          <Name onClick={() => onRowOpenClose(inst.id)}>
                            {openedRows.includes(inst.id) ? (
                              <MinusIcon size={IconSize.XSmall} />
                            ) : (
                              <PlusIcon size={IconSize.XSmall} />
                            )}
                            {inst.name}
                          </Name>
                          <StatusCell
                            status={isInstallationMeasured(inst.installationEquipment, "measured") ? "Yes" : "No"}
                          />
                          <StatusCell
                            status={
                              isInstallationMeasured(inst.installationEquipment, "fuelSampleReceived") ? "Yes" : "No"
                            }
                          />
                          <th>{percentageOfApprovedOverAllEngines(inst.installationEquipment)}</th>
                        </InstallationRaw>
                        {openedRows.includes(inst.id) &&
                          sortBy(inst.installationEquipment.filter(applyFilter), "name").map(
                            (eq: IBrandInstallationEquipment) => (
                              <EquipmentRow key={eq.id} data-testid={eq.id}>
                                <Name>{eq.name}</Name>
                                <StatusCell status={eq.measured ? "Yes" : "No"} />
                                <StatusCell status={eq.fuelSampleReceived ? "Yes" : "No"} />
                                <StatusCell
                                  onEnter={() => setVisibleTooltip(eq.id)}
                                  onExit={() => setVisibleTooltip(undefined)}
                                  onClick={() => setVisibleTooltip(visibleTooltip === eq.id ? undefined : eq.id)}
                                  status={eq.validationAndApproval}
                                >
                                  {eq?.validationAndApproval === "Rejected" && visibleTooltip === eq.id && (
                                    <Reason reason={eq?.reasonForRejection || ""} />
                                  )}
                                </StatusCell>
                              </EquipmentRow>
                            )
                          )}
                      </>
                    ))}
              </>
            ))}
          </TUnderlinedBody>
          <TFoot>
            <tr>
              <td />
              <td colSpan={2}>
                <YesNo onClick={toggleFilter} filters={statusFilter} enableFilter={true} />
              </td>
              <td>
                <Statuses onClick={toggleFilter} filters={statusFilter} enableFilter={true} />
              </td>
            </tr>
          </TFoot>
        </StatusTable>
      ) : (
        <LocalizedStringComponent id={"sfoc.noData"} />
      )}
    </DashboardItem>
  );
};
