// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fieldServices_ru: ITranslations = {
  ru: {
    "fieldServices.actualFinishDate": "Фактическая дата окончания",
    "fieldServices.actualStartDate": "Фактическая дата начала",
    "fieldServices.description": "Описание работы",
    "fieldServices.estimatedFinishDate": "Расчетная дата окончания",
    "fieldServices.estimatedStartDate": "Расчётная дата начала",
    "fieldServices.poNumber": "Номер заказа на поставку",
    "fieldServices.productReferenceType": "Тип ссылочного номера изделия",
    "fieldServices.recommendations": "Рекомендации",
    "fieldServices.serviceWorkCoordinator": "Координатор работ по обслуживанию",
  },
};
