// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_pt_BR: ITranslations = {
  pt_BR: {
    "reports.attachments": "Anexos",
    "reports.conditionMonitorinReports": "Relatórios de diagnóstico de ativos",
    "reports.newReportAvailable": "Novo relatório disponível",
    "reports.noReportsAvailable": "Nenhum relatório disponível",
    "reports.reportPeriodStartDate": "Período",
    "reports.reportPublishedDate": "Data da emissão",
    "reports.title": "Título",
  },
};
