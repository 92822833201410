import { Service } from "online-services-types";
import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { themes } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { defaultBoxShadow } from "src/design-system/Tokens/tokens";
import { H3 } from "src/design-system/Tokens/typography";
import {
  ArrowDownIcon,
  IconSize,
  LaptopIcon,
  MailIcon,
  PhoneIcon,
  TrainingIcon,
} from "src/icons";
import { getPathWithParams, getRoutes } from "src/routes";
import { translateString } from "src/util/localization";
import styled, { css, ThemeProvider } from "styled-components";

const HeadingWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: 45px;
  background: ${colors.primary.orange};
  cursor: pointer;

  ::before {
    content: "";
    width: 1px;
    height: 0;
    top: 0;
    right: calc(100% - 1px);
    position: absolute;
    border-bottom: 45px solid ${colors.primary.orange};
    border-left: 30px solid transparent;
  }

  @media (min-width: ${breakpoints.mobileLarge}px) {
    padding: 0 10px;
    height: 55px;

    ::before {
      border-bottom: 55px solid ${colors.primary.orange};
    }
  }
`;

const ContentWrapper = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  background: ${colors.primary.white};
  color: ${colors.primary.black};
  width: fit-content;
  justify-content: center;
  border-radius: 2px;
  box-shadow: ${defaultBoxShadow};
`;

const OptionStyle = css`
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 10px 12px;
  color: ${colors.primary.blue};
  border-bottom: 1px solid ${colors.secondary.gray10};
  
  &:first-child {
    border-radius: 2px 2px 0 0;
  }

  &:last-child {
    border-radius: 0 0 2px 2px;
    border-bottom: none;
  }

  &:hover {
    background-color: ${colors.secondary.gray10};
  }

  svg {
    fill: black;
  }
`;

const OptionLink = styled(Link)`
  ${OptionStyle}
`;

const OptionAnchor = styled.a.attrs({ rel: "noreferrer noopener", target: "_blank" })`
  ${OptionStyle}
`;

export const H3NoMargin = styled(H3)`
  margin: 0;
  padding: 0 10px;
  color: ${colors.primary.white};
`;

interface IContactComponentState {
  isOpen: boolean;
  closeConfirmationOpen: boolean;
}

interface IContactComponentOwnProps {
  services: ReadonlyArray<Service>;
  isExternallyOpen: boolean;
  isQuantipartsDistributor: boolean;
}

type IContactComponentProps = IContactComponentOwnProps & RouteComponentProps;

export class ContactComponent extends React.Component<IContactComponentProps, IContactComponentState> {
  private readonly headingRef: React.RefObject<HTMLDivElement>;
  private readonly contentRef: React.RefObject<HTMLDivElement>;

  constructor(props: IContactComponentProps) {
    super(props);

    this.state = {
      isOpen: false,
      closeConfirmationOpen: false,
    };

    this.headingRef = React.createRef<HTMLDivElement>();
    this.contentRef = React.createRef<HTMLDivElement>();
    this.handleClickOutsideContent = this.handleClickOutsideContent.bind(this);

    this.props.history.listen(() => {
      this.setState({ isOpen: false });
    });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutsideContent);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideContent);
  }

  public handleClickOutsideContent(event: MouseEvent | TouchEvent) {
    if (!this.contentRef?.current?.contains(event.target as Node) && !this.headingRef?.current?.contains(event.target as Node)) {
      this.setState({ isOpen: false });
    }
  }

  public render() {
    const routes = getRoutes();
    const isMobile = window.screen.width < breakpoints.mobileLarge;
    const {
      isExternallyOpen,
      services,
      isQuantipartsDistributor,
    } = this.props;
    const isOpen = this.state.isOpen || isExternallyOpen;
    return (
      <>
        <HeadingWrapper ref={this.headingRef} onClick={() => this.setState((prev) => ({ isOpen: !prev.isOpen }))}>
          <H3NoMargin>
            {isMobile ? (
              translateString("contact") + " "
            ) : (
              <>
                <LocalizedStringComponent
                  id={"contactOrganization"}
                  values={{ organization: isQuantipartsDistributor ? "QuantiParts" : "Wärtsilä" }}
                />
                &nbsp;<ArrowDownIcon />
              </>
            )}
          </H3NoMargin>
        </HeadingWrapper>
        <ContentWrapper ref={this.contentRef} className={"main-onboarding-ready"}>
          {isOpen && (
            <ThemeProvider theme={themes.light}>
              <OptionLink to={getPathWithParams(routes.CreateWarrantyClaim, { phase: 1 })}>
                <LaptopIcon size={IconSize.Base} />
                {isQuantipartsDistributor
                  ? translateString("request.nonTechnicalQP")
                  : translateString("request.nonTechnical")}
              </OptionLink>
              {!routes.Requests.isEnabled(services) && (
                <OptionLink to={{
                  pathname: getPathWithParams(routes.Contact),
                  state: { isQuantipartsDistributor: isQuantipartsDistributor },
                }}>
                  <MailIcon size={IconSize.Base} />
                  {translateString("contactCustomerSupport")}
                </OptionLink>
              )}
              <OptionLink to={{
                pathname: getPathWithParams(routes.TrainingContact),
                state: { isQuantipartsDistributor: isQuantipartsDistributor },
              }}>
                <TrainingIcon size={IconSize.Base} />
                {isQuantipartsDistributor
                  ? translateString("informationAboutTrainingsQP")
                  : translateString("informationAboutTrainings")}
              </OptionLink>
              <OptionAnchor href={isQuantipartsDistributor ? "https://quantiparts.com/contact/" : "https://addresses.wartsila.com"}>
                <PhoneIcon size={IconSize.Base} />
                {isQuantipartsDistributor
                  ? translateString("QPPhoneNumbers")
                  : translateString("wartsilaPhoneNumbers")}
              </OptionAnchor>
            </ThemeProvider>
          )}
        </ContentWrapper>
      </>
    );
  }
}
