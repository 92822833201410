import { themes } from "src/design-system/Theme/theme";

export const iconStyles = {
  defaultColorLight: themes.light.icon.defaultColor,
  defaultColorDark: themes.dark.icon.defaultColor,
  large: {
    mobile: {
      height: "48px",
      width: "48px",
    },
    desktop: {
      height: "48px",
      width: "48px",
    },
  },
  medium: {
    mobile: {
      height: "32px",
      width: "32px",
    },
    desktop: {
      height: "32px",
      width: "32px",
    },
  },
  base: {
    mobile: {
      height: "24px",
      width: "25px",
    },
    desktop: {
      height: "24px",
      width: "24px",
    },
  },
  small: {
    mobile: {
      height: "20px",
      width: "20px",
    },
    desktop: {
      height: "20px",
      width: "20px",
    },
  },
  xsmall: {
    mobile: {
      height: "12px",
      width: "12px",
    },
    desktop: {
      height: "12px",
      width: "12px",
    },
  },
};

export enum IconSize {
  XSmall = "xsmall",
  Small = "small",
  Base = "base",
  Medium = "medium",
  Large = "large",
}
