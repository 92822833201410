export enum AttachmentSource {
  TechRequest = "TechRequest",
  WarrantyClaim = "WarrantyClaim",
  CustomerSupportTicket = "CustomerSupportTicket",
  OperationalSupportRequest = "OperationalSupportTicket",
  ConditionMonitoringReports = "ConditionMonitoringReports",
  SparePartClaim = "SparePartClaim",
  ServiceWorkReport = "ServiceWorkReport",
  Commerce = "Commerce",
  Draft = "Draft",
  Shipment = "Shipment",
  VATStatement = "VATStatement",
  MergedPDF = "MergedPDF",
  SFOC = "SFOC",
}
