import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { APIFetchStatus } from "src/APIFetch";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import {
  IInstallationDetailsComponentDispatchProps,
  IInstallationDetailsComponentStateProps,
  InstallationDetailsComponent,
} from "./InstallationDetailsComponent";

const mapStateToProps = (state: IAppState): IInstallationDetailsComponentStateProps => {
  return {
    fluidReportRules: state.fluidReportRules,
    installationContracts: state.installationContracts.data || [],
    installations: state.installations.data || [],
    isReady: state.installationContracts.status === APIFetchStatus.Success,
    selectedInstallationId: state.userInfo.selectedInstallationId,
    isContractsEnabled: state.applicationContext.services.includes("Contracts"),
    isFluidReportingEnabled: state.applicationContext.services.includes("FluidReporting"),
    fluidReportDrafts: state.fluidReportDrafts.data || [],
    isFluidDraftsReady: state.fluidReportDrafts.status === APIFetchStatus.Success,
    isQuantipartsDistributor: state.userInfo.isQPDistributor && !state.userInfo.isQPDistributorViewDisabled,
    services: state.applicationContext.services,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IInstallationDetailsComponentDispatchProps =>
  bindActionCreators(
    {
      getFluidReportRules: APIResourceState.fluidReportRules.get,
      getInstallationContracts: APIResourceState.installationContracts.get,
      getFluidReportDrafts: APIResourceState.fluidReportDrafts.get,
    },
    dispatch
  );

export const InstallationDetailsContainer = connect<
  IInstallationDetailsComponentStateProps,
  IInstallationDetailsComponentDispatchProps
>(
  mapStateToProps,
  mapDispatchToProps
)(InstallationDetailsComponent);
