// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const error_ja: ITranslations = {
  ja: {
    "error.contactLinkText": "Wärtsiläへご連絡ください。",
    "error.defaultTitle": "不具合が発生しました",
    "error.pleaseContact": "問題が解決しない場合、{contactLink}へご連絡ください。",
    "success.defaultMessage": "操作を無事に完了しました。",
    "success.defaultTitle": "成功しました!",
  },
};
