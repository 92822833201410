import { ISpacing, flex, gap, margin, padding } from "../Tokens/spacings";
import styled, { css } from "styled-components";

interface IContainerProps {
  $width?: string;
  $maxwidth?: string;
  $noShrink?: boolean;
  $fullwidth?: boolean;
  $inlineBlock?: boolean;
  $positionRelative?: boolean;
  $verticalAlignTop?: boolean;
  $whiteSpacePreLine?: boolean;
}

export const containerProps = css`
  ${(props: IContainerProps) => props.$noShrink && `flex-shrink: 0;`};
  ${(props: IContainerProps) => props.$fullwidth && "width: 100%;"}
  ${(props: IContainerProps) => props.$verticalAlignTop && "vertical-align: top;"}
  ${(props: IContainerProps) => props.$width && `width: ${props.$width};`}
  ${(props: IContainerProps) => props.$inlineBlock && "display: inline-block;"}
  ${(props: IContainerProps) => props.$positionRelative && "position: relative;"};
  ${(props: IContainerProps) => props.$whiteSpacePreLine && "white-space: pre-line;"}
  ${(props: IContainerProps) => props.$maxwidth && `max-width: ${props.$maxwidth};`}
`;

export const Container = styled.div<IContainerProps & ISpacing>`
  ${padding};
  ${margin};
  ${containerProps};
  ${gap};
  ${flex};
`;
