import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { updateInterestedParties } from "src/components/SupportList/InterestedPeople/contacts";
import {
  IInterestedPeopleDispatchProps,
  IInterestedPeopleOwnProps,
  IInterestedPeopleStateProps,
  InterestedPeopleComponent
} from "src/components/SupportList/InterestedPeople/InterestedPeopleComponent";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";

const mapStateToProps = (state: IAppState): IInterestedPeopleStateProps => ({
  contacts: state.contacts,
});

const mapDispatchToProps = (dispatch: Dispatch): IInterestedPeopleDispatchProps =>
  bindActionCreators({
    getAccountContacts: APIResourceState.contacts.get,
    updateInterestedParties,
  }, dispatch);

export const InterestedPeopleContainer = connect<
  IInterestedPeopleStateProps,
  IInterestedPeopleDispatchProps,
  IInterestedPeopleOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(InterestedPeopleComponent);