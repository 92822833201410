// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_zh_CN: ITranslations = {
  zh_CN: {
    "documents.allDocuments": "所有文档",
    "documents.answer": "回答",
    "documents.appliesTo": "适用于",
    "documents.applyFilter": "应用筛选器",
    "documents.button.download": "下载",
    "documents.button.open": "打开",
    "documents.button.print": "打印",
    "documents.clearFilter": "清除筛选器",
    "documents.documentIssue": "文档问题",
    "documents.documentNumber": "文档编号",
    "documents.documentType": "文档类型",
    "documents.documentTypes.ASI": "通告",
    "documents.documentTypes.CMREPORT": "资产诊断报告",
    "documents.documentTypes.FAQ": "常见问题",
    "documents.documentTypes.MAN": "手册",
    "documents.documentTypes.SPC": "备件目录",
    "documents.documentTypes.SW": "软件",
    "documents.documentTypes.SWR": "工作报告",
    "documents.documentTypes.TECHREQUEST": "技术支持",
    "documents.documentTypes.TR": "技术报告",
    "documents.documentTypesLongText.MAN": "操作和维护手册",
    "documents.documentTypesLongText.SPC": "备件目录",
    "documents.documentTypesLongText.couldntFindArticle": "无法找到文章!",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "提取文章时错误：{error}",
    "documents.emptyDataText": "如要缩小搜索范围，请使用开放文本或筛选器或者下列预定范围：",
    "documents.files": "文件",
    "documents.filterBasedOnDocumentType": "基于文档类型的筛选器",
    "documents.forImmediateAction": "适合及时关注",
    "documents.listHeader": "文档 ({totalItems})",
    "documents.listHeaderEmptyPattern": "感兴趣的文档",
    "documents.listHeaderNoItems": "文档",
    "documents.loadMore": "加载更多",
    "documents.nickName": "昵称",
    "documents.productReferenceType": "产品参考类型",
    "documents.question": "问题",
    "documents.releaseNotes": "发布注解",
    "documents.scopeButton.ASI": "最近的通告",
    "documents.scopeButton.MAN": "最近的手册",
    "documents.search": "搜索",
    "documents.sections.attachments": "附件",
    "documents.sections.chapters": "章节",
    "documents.sections.workcards": "工作卡",
    "documents.shared.documentTypes.MF_CL_Analysis": "分析",
    "documents.shared.documentTypes.MF_CL_Claims": "索赔",
    "documents.shared.documentTypes.MF_CL_Failure": "故障",
    "documents.shared.documentTypes.MF_CL_Inventories": "库存",
    "documents.shared.documentTypes.MF_CL_MaintenancePlans": "维护计划",
    "documents.shared.documentTypes.MF_CL_MeetingMinutes": "会议纪要",
    "documents.shared.documentTypes.MF_CL_Report": "报告",
    "documents.shared.documentTypes.MF_CL_SFOCReport": "SFOC报告",
    "documents.shared.documentTypes.MF_CL_Training": "培训",
    "documents.shared.from": "从",
    "documents.shared.to": "至",
    "documents.sorting.documentNumberAZ": "文档编号 (A-Z)",
    "documents.sorting.documentTypeAZ": "文档类型 (A-Z)",
    "documents.sorting.titleAZ": "标题 (A-Z)",
    "documents.title": "标题",
    "documents.typeToSearch": "要搜索的类型...",
    "documents.updateNotificationText.ASI": "通告要求您操作",
    "documents.updateNotificationText.MAN": "章节已更新",
    "documents.updateNotificationText.TR": "新的技术报告已发布",
    "documents.updateNotificationText.other": "文档已更新",
  },
};
