// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_ja: ITranslations = {
  ja: {
    "fluid.afterSeparator": "セパレーター後",
    "fluid.analysisDate": "分析日",
    "fluid.analysisOf": "分析対象",
    "fluid.beforeSeparator": "セパレーター前",
    "fluid.bunkeringLocation": "燃料補給場所 (オプション)",
    "fluid.categoryISOF": "ISO-F区分",
    "fluid.commentsFromTheLaboratory": "研究所からのコメント (オプション)",
    "fluid.common": "共通",
    "fluid.coolingWater": "冷却水",
    "fluid.differentPerEngine": "エンジンごとに異なる",
    "fluid.draft.continueEditing": "編集を続行する",
    "fluid.draft.continueWithExistingDraft": "既存の下書きを続ける",
    "fluid.draft.createNewReport": "新しいフルードレポートを作成する",
    "fluid.draft.deleteDraft": "下書きを削除する",
    "fluid.draft.deleted": "下書きを削除しました",
    "fluid.draft.deleting": "下書きを削除中...",
    "fluid.draft.deletingError": "下書きを削除中にエラーが発生しました",
    "fluid.draft.or": "または",
    "fluid.draft.sending": "下書きを保存中...",
    "fluid.draft.sendingError": "下書きを保存中にエラーが発生しました",
    "fluid.draft.sent": "下書きを保存しました",
    "fluid.engineLife": "エンジン寿命",
    "fluid.engines": "エンジン",
    "fluid.enginesSampleData": "エンジンのサンプルデータ",
    "fluid.field.acid_number_mg_koh_g__c": "酸価",
    "fluid.field.aluminum_al_ppm__c": "アルミニウム、Al",
    "fluid.field.aluminum_silicon_mg_kg__c": "アルミニウム + シリコン",
    "fluid.field.ash_m_m__c": "灰分",
    "fluid.field.asphaltenes_m_m__c": "アスファルテン",
    "fluid.field.base_number_bn_mg_koh_g__c": "基数 (BN)",
    "fluid.field.boron_b_ppm__c": "ボロン、B",
    "fluid.field.calcium_ca_mg_kg__c": "カルシウム、Ca",
    "fluid.field.calcium_ca_ppm__c": "カルシウム、Ca",
    "fluid.field.carbon_residue_micro_method_m_m__c": "残留炭素、微量法",
    "fluid.field.ccai__c": "ＣＣＡＩ",
    "fluid.field.cetane_index__c": "セタン指数",
    "fluid.field.chlorides_cl_mg_l__c": "塩化物、Cl",
    "fluid.field.chromium_cr_ppm__c": "クロム、Cr",
    "fluid.field.copper_cu_mg_l__c": "銅、Cu",
    "fluid.field.copper_cu_ppm__c": "銅、Cu",
    "fluid.field.density_15_c_kg_m3__c": "濃度@15℃",
    "fluid.field.flash_point_c__c": "引火点",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "硫化水素",
    "fluid.field.insolubes_w__c": "不溶成分",
    "fluid.field.iron_fe_mg_kg__c": "鉄、Fe",
    "fluid.field.iron_fe_mg_l__c": "鉄、Fe",
    "fluid.field.iron_fe_ppm__c": "鉄、Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "動粘度@40℃",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "動粘度@50℃",
    "fluid.field.lead_pb_mg_kg__c": "鉛、Pb",
    "fluid.field.lead_pb_ppm__c": "鉛、Pb",
    "fluid.field.lower_heating_value_mj_kg__c": "低発熱量",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "潤滑性、修正摩耗痕直径 (wsd 1.4) @60℃",
    "fluid.field.magnesium_mg_mg_kg__c": "マグネシウム、Mg",
    "fluid.field.molybenum_mo_ppm__c": "モリブデン、Mo",
    "fluid.field.nickel_ni_mg_kg__c": "ニッケル、Ni",
    "fluid.field.nickel_ni_ppm__c": "ニッケル、Ni",
    "fluid.field.nitrate_no3_mg_l__c": "硝酸塩、NO₃",
    "fluid.field.nitration_abs_cm__c": "窒化",
    "fluid.field.nitrite_no2_mg_l__c": "亜硝酸塩、NO₂",
    "fluid.field.oxidation_abs_cm__c": "酸化",
    "fluid.field.oxidation_stability_g_m3__c": "酸化安定性",
    "fluid.field.ph__c": "pH",
    "fluid.field.phosphorus_p_mg_kg__c": "リン、P",
    "fluid.field.phosphorus_p_ppm__c": "リン、P",
    "fluid.field.pour_point_c__c": "流動点",
    "fluid.field.silicon_si_ppm__c": "シリコン、Si",
    "fluid.field.sodium_na_mg_kg__c": "ナトリウム、Na",
    "fluid.field.sodium_na_ppm__c": "ナトリウム、Na",
    "fluid.field.sulphates_so4_mg_l__c": "硫酸塩、SO₄",
    "fluid.field.sulphur_m_m__c": "硫黄",
    "fluid.field.tin_sn_ppm__c": "スズ、Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "全酸価 (TAN)",
    "fluid.field.total_hardness_ca_mg_dh__c": "総硬度 (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "総堆積量",
    "fluid.field.vanadium_v_mg_kg__c": "バナジウム、V",
    "fluid.field.vanadium_v_ppm__c": "バナジウム、V",
    "fluid.field.viscosity_100_c_cst__c": "粘度@100℃",
    "fluid.field.viscosity_40_c_cst__c": "粘度@40℃",
    "fluid.field.water_v_v__c": "水分",
    "fluid.field.water_vol_or_w__c": "水分",
    "fluid.field.zinc_zn_mg_kg__c": "亜鉛、Zn",
    "fluid.field.zinc_zn_ppm__c": "亜鉛、Zn",
    "fluid.fuel": "燃料",
    "fluid.gas": "ガス",
    "fluid.general": "一般",
    "fluid.generalInformation": "一般情報",
    "fluid.header.elemConcs": "元素濃度",
    "fluid.header.fuelProps": "燃料特性",
    "fluid.header.oilProps": "オイル特性",
    "fluid.htCircuit": "HT回路",
    "fluid.inputValues": "入力値",
    "fluid.laboratoryReferenceNumber": "実験室参照番号 (オプション)",
    "fluid.lastData": "最終データ",
    "fluid.lowSulphurFuels": "低硫黄燃料",
    "fluid.ltCircuit": "LT回路",
    "fluid.lubeOil": "潤滑油",
    "fluid.lubricant": "潤滑油",
    "fluid.notAvailable": "N/A",
    "fluid.oilAdded": "オイル追加",
    "fluid.oilLife": "オイル寿命",
    "fluid.oldLubricantDisclaimer": "このセクションには、以前に使用した潤滑油の履歴データが含まれています",
    "fluid.operationOn": "操作ON",
    "fluid.phase.reportData": "レポートデータ",
    "fluid.phase.sampleData": "サンプルデータ",
    "fluid.phase.scopeSelection": "範囲の選択",
    "fluid.phase.summary": "概要",
    "fluid.product": "製品",
    "fluid.reportData": "レポートデータ",
    "fluid.sameForAllEngines": "すべてのエンジンで同じ",
    "fluid.sampleAggregation": "サンプル集約",
    "fluid.sampleDate": "サンプル日",
    "fluid.samplePoint": "サンプルポイント",
    "fluid.scopeOfReport": "レポートの範囲",
    "fluid.sending": "フルードレポートを送信中...",
    "fluid.sendingError": "フルードレポートの送信中にエラーが発生しました",
    "fluid.sent": "フルードレポートを送信しました",
    "fluid.timeframe": "時間枠",
    "fluid.unit.abscm": "Abs/cm",
    "fluid.unit.cst": "cSt",
    "fluid.unit.degreec": "℃",
    "fluid.unit.degreedh": "°dH",
    "fluid.unit.gm3": "g/m³",
    "fluid.unit.kgm3": "kg/m³",
    "fluid.unit.mgkg": "mg/kg",
    "fluid.unit.mgkohg": "mg KOH/g",
    "fluid.unit.mgl": "mg/l",
    "fluid.unit.mjkg": "MJ/kg",
    "fluid.unit.mm2s": "mm²/s",
    "fluid.unit.pmm": "% m/m",
    "fluid.unit.ppm": "ppm",
    "fluid.unit.pvv": "% v/v",
    "fluid.unit.um": "µm",
    "fluid.unit.volporwp": "vol-% または w-%",
    "fluid.unit.wp": "w-%",
    "fluid.unspecified": "不特定",
    "fluid.uptime": "稼働時間",
    "fluid.uptime.availability": "利用可能性",
    "fluid.uptime.availabilityFormula": "(稼働 + 待機) / (稼働 + 待機 + 停止 + シャットダウン)",
    "fluid.uptime.availabilityLimits": "利用可能性目標値",
    "fluid.uptime.contractLimit": "契約制限",
    "fluid.uptime.daily": "日次",
    "fluid.uptime.engineAvailability": "エンジン利用可能性",
    "fluid.uptime.limitMustBeNumber": "制限値は数字でなければなりません!",
    "fluid.uptime.limitMustBeNumberBetween": "制限値は{min}から{max}までの数字でなければなりません!",
    "fluid.uptime.limits": "目標値を編集する",
    "fluid.uptime.monthly": "月次",
    "fluid.uptime.noAvailability": "この期間の利用可能なデータはありません。",
    "fluid.uptime.noData": "データなし",
    "fluid.uptime.noMaintenanceData": "この期間のメンテナンスデータはありません。",
    "fluid.uptime.noRunningHours": "この期間の稼働時間データはありません。",
    "fluid.uptime.period": "期間",
    "fluid.uptime.report": "レポート",
    "fluid.uptime.running": "稼働",
    "fluid.uptime.saveLimits": "保存",
    "fluid.uptime.shutDown": "シャットダウン",
    "fluid.uptime.standby": "待機",
    "fluid.uptime.stop": "停止",
    "fluid.uptime.sum": "合計",
    "fluid.uptime.yearly": "年次",
    "fluid.validation.analysisDateEarlierThanSampleDate": "分析日はサンプル日より前の日付にはできません",
    "fluid.validation.analysisDateInFuture": "分析日を将来の日付にすることはできません",
    "fluid.validation.analysisDateRequired": "分析日が必要です",
    "fluid.validation.categoryISOFIsRequired": "ISO-F区分が必要です",
    "fluid.validation.common": "予期しない値が入力されました (最小: {min}, 最大: {max})。",
    "fluid.validation.engineIsRequired": "エンジンが必要です",
    "fluid.validation.engineLifeMustBeANumber": "エンジン寿命は数字でなければなりません",
    "fluid.validation.fuelIsRequired": "燃料が必要です",
    "fluid.validation.installationIsRequired": "設備が必要です",
    "fluid.validation.installationIsRequired:energy": "発電プラントが必要です",
    "fluid.validation.installationIsRequired:marine": "船舶が必要です",
    "fluid.validation.lubricantIsRequired": "潤滑油が必要です",
    "fluid.validation.mustBeANumber": "{value}は数字でなければなりません。",
    "fluid.validation.oilAdedMustBeANumber": "追加オイルは数字でなければなりません",
    "fluid.validation.oilLifeMustBeANumber": "オイル寿命は数字でなければなりません",
    "fluid.validation.ppm": "予期しない値が入力されました (最小: {min}, 最大: {max})。％値をppmへ変換: 1% = 10000 ppm。",
    "fluid.validation.sampleAggregationIsRequired": "サンプル集約が必要です",
    "fluid.validation.sampleDateInFuture": "サンプル日を将来の日付にすることはできません",
    "fluid.validation.sampleDateRequired": "サンプル日が必要です",
    "fluid.validation.samplePointIsRequired": "サンプルポイントが必要です",
  },
};
