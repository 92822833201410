import * as React from "react";
import { IBaseStep } from "src/redux/onboarding";
import { OnboardingStepWrapper } from "./styles";
import { H2, P } from "src/design-system/Tokens/typography";
import { LocalizedString } from "../Localization";
import { DocumentsViewComponent } from "src/views/DocumentsView/DocumentsViewComponent";

export const onBoardingDocumentsSteps: IBaseStep[] = [
  {
    className: "technical-documentation-onboarding-your-documents",
    isFixed: true,
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="documentsOnboarding.stepOne.title" />
        </H2>
        <P>
          <LocalizedString id="documentsOnboarding.stepOne.content" />
        </P>
      </OnboardingStepWrapper>
    ),
    disableBeacon: true,
    placement: "bottom-start",
    onStepActivate(component: React.Component) {
      const docsComponent = component as {} as DocumentsViewComponent;
      docsComponent.initOnboarding();
    },
  },
  {
    className: "technical-documentation-onboarding-search-and-filter",
    disableOverlayClose: true,

    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="documentsOnboarding.stepTwo.title" />
        </H2>
        <P>
          <LocalizedString id="documentsOnboarding.stepTwo.content" />
        </P>
        <P>
          <LocalizedString id="documentsOnboarding.stepTwo.content2" />
        </P>
      </OnboardingStepWrapper>
    ),
    placement: "right",
  },
  {
    className: "technical-documentation-onboarding-sort",
    disableOverlayClose: true,

    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="documentsOnboarding.stepThree.title" />
        </H2>
        <P>
          <LocalizedString id="documentsOnboarding.stepThree.content" />
        </P>
      </OnboardingStepWrapper>
    ),
    placement: "left",
    onStepActivate(component: React.Component) {
      const docsComponent = component as {} as DocumentsViewComponent;
      docsComponent.focusDropdown();
    },
    onStepNext(component: React.Component) {
      const docsComponent = component as {} as DocumentsViewComponent;
      docsComponent.unfocusDropdown();
    },
  },
  {
    className: "technical-documentation-onboarding-preview",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="documentsOnboarding.stepFour.title" />
        </H2>
        <P>
          <LocalizedString id="documentsOnboarding.stepFour.content" />
        </P>
        <P>
          <LocalizedString id="documentsOnboarding.stepFour.content2" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "technical-documentation-onboarding-document-info",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="documentsOnboarding.stepFive.title" />
        </H2>
        <P>
          <LocalizedString id="documentsOnboarding.stepFive.content" />
        </P>
      </OnboardingStepWrapper>
    ),
    onStepNext(component: React.Component) {
      const docsComponent = component as {} as DocumentsViewComponent;
      docsComponent.restoreState();
    },
  },
];
