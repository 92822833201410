import { IAccountUser, IDigestSettings, IShipyardSubscription } from "online-services-types";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "src/util/error";

import { APIFetch } from "src/APIFetch";
import { setEULAAccept } from "src/util/localstorage";
import { translateString } from "src/util/localization";

export const getUserProfile = async (displayErrorToast = true) => {
  try {
    const result = await new APIFetch<IAccountUser[]>("service/user").get();
    if (result && result.length > 0) {
      return result[0];
    }
  } catch (error) {
    if (displayErrorToast) {
      displayError(error);
    }
  }
  return null;
};

export const getUserDigestSettings = async (displayErrorToast = true) => {
  try {
    const result = await new APIFetch<IDigestSettings>("service/digest-settings").get();
    if (result) {
      return result;
    }
  } catch (error) {
    if (displayErrorToast) {
      displayError(error);
    }
  }
  return null;
};

export const patchDigestSettings = async (data: IDigestSettings, displayUpdatingToast = true) => {
  try {
    let toastId;

    if (displayUpdatingToast) {
      toastId = displayProgress(translateString("profile.updating"), undefined, { autoClose: false });
    }

    await new APIFetch<IDigestSettings>("service/digest-settings").patch(data);

    if (displayUpdatingToast && toastId) {
      dismissMessage(toastId);
      displaySuccess("Profile updated!");
    }

    return true;
  } catch (error) {
    displayError(error);
  }
  return false;
};

export const patchUserProfile = async (user: Partial<IAccountUser>, displayUpdatingToast = true) => {
  try {
    let toastId;

    if (displayUpdatingToast) {
      toastId = displayProgress(translateString("profile.updating"), undefined, { autoClose: false });
    }

    await new APIFetch<IAccountUser>("service/user").patch(user);

    if (displayUpdatingToast && toastId) {
      dismissMessage(toastId);
      displaySuccess(translateString("profile.profileUpdated"));
    }

    return true;
  } catch (error) {
    displayError(translateString("profile.errorWhileUpdatingProfile"));
  }
  return false;
};

export const patchUserHasReadEULA = async () => {
  try {
    await new APIFetch<IAccountUser>("service/user").patch({ hasAcceptedEULA: true });

    return true;
  } catch (error) {
    displayError(error);
  }
  return false;
};

export const patchUserHasConfirmedUserDetails = async (title: string, department: string) => {
  try {
    await new APIFetch<IAccountUser>("service/user").patch({ title, department });

    return true;
  } catch (error) {
    displayError(error);
  }
  return false;
};

export const getUserShipyardSubscriptions = async (displayErrorToast = true) => {
  try {
    const result = await new APIFetch<IShipyardSubscription[]>("service/shipyard-subscriptions").get();
    if (result) {
      return result;
    }
  } catch (error) {
    if (displayErrorToast) {
      displayError(error);
    }
  }
  return null;
};

export const patchShipyardSubscriptions = async (data: IShipyardSubscription[], displayUpdatingToast = true) => {
  try {
    let toastId;
    if (displayUpdatingToast) {
      toastId = displayProgress(translateString("profile.updating"), undefined, { autoClose: false });
    }
    const updated = await new APIFetch<IShipyardSubscription[]>("service/shipyard-subscriptions").patch(data);
    if (displayUpdatingToast && toastId) {
      dismissMessage(toastId);
      displaySuccess("Profile updated!");
    }
    return updated;
  } catch (error) {
    displayError(error);
  }
  return null;
};

export const changePassword = async (password: string) => {
  try {
    await new APIFetch<{ password: string }>("service/user/change-password").post({ password });
    return true;
  } catch (error) {
    displayError(error);
    throw Error(error);
  }
};

export const getUserInitialInfo = async () => {
  const user = await getUserProfile(false);
  const acceptedEULA = user?.hasAcceptedEULA || false;
  const isQuantipartsDistributor = user?.isQPDistributor || false;
  const isQPDistributorViewDisabled = user?.isQPDistributorViewDisabled || false;
  setEULAAccept(acceptedEULA);
  return {
    acceptedEULA,
    isQuantipartsDistributor,
    isQPDistributorViewDisabled,
    daysUntilPasswordExpiry: user?.daysUntilPasswordExpiry,
  };
};
