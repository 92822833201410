import styled from "styled-components";
import colors from "src/design-system/Tokens/colors";
import { Container } from "src/design-system/Container";

export const ErrorMessage = styled.div`
  color: ${colors.primary.orange};
`;

export const MaxWidthContainer = styled(Container)`
  max-width: 630px;
`;
