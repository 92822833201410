import { FieldProps } from "formik";
import * as React from "react";
import { components, MultiValueProps, OptionProps } from "react-select-latest";
import CreatableSelect from "react-select-latest/creatable";

import { APIFetchStatus } from "src/APIFetch";
import { NewPersonDialog } from "src/components/PersonSelector/NewPersonDialog";
import { FlexContainer } from "src/design-system/Container";
import { ErrorComponent } from "src/design-system/Error";
import { ISelectStyleProps } from "src/design-system/Select/Select";
import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { fixedFontSizes, Label } from "src/design-system/Tokens/typography";
import { getObjectProperty } from "src/util/helpers";
import { translateString } from "src/util/localization";
import { validators } from "src/util/validators";
import styled from "styled-components";

import { ErrorMessage, MaxWidthContainer } from "./styles";
import { IOptionData, IProps, IState } from "./types";

const StyledSelectWrapper = styled.div`
    .react-select-container {
    width: 100%;
    font-size: ${fixedFontSizes.baseFontSize}px;
    color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.foreground1};
  }

  .react-select__control {
    border-radius: 0;
    border-color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.border1};
  }

  .react-select__indicator {
    color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.foreground1};

    :hover {
      color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.foreground1};
  }

  .react-select__multi-value {
    margin: 0.125em;
    border-radius: 0;
  }

  .react-select__multi-value__label {
    border-radius: 0;
    font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  }

  .react-select__multi-value__remove {
    border-radius: 0;

    :hover {
      color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.foreground2};
      background-color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.attention};
    .react-select__single-value {
      color: ${(props: ThemedProps) => props.theme.text};
    }
  }

  .react-select__menu {
    border-color: ${(props: ThemedProps<ISelectStyleProps>) => props.theme.select.border1};
    border-radius: 0;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export class EmailMultiSelect extends React.Component<IProps & FieldProps, IState> {
  constructor(props: IProps & FieldProps) {
    super(props);

    this.state = {
      contacts: [],
      isDialogOpen: false,
    };
  }

  public componentDidMount() {
    const { contacts } = this.props.form.values;
    if (contacts) {
      this.setState({
        contacts,
      });
    }
  }

  public onChange = (contacts: IOptionData[]) => {
    const newContacts = contacts ? contacts : [];
    this.setState({
      contacts: newContacts,
    });
    const formContacts = newContacts.map((contact) => ({
      id: contact.id ? contact.id : null,
      firstName: contact.firstName,
      lastName: contact.lastName,
      title: contact.title,
      phone: contact.phone,
      email: contact.email,
    }));
    this.props.form.setFieldValue(this.props.field.name, formContacts);
  };

  public onNewOption = (newContact: string) => {
    this.setState({ isDialogOpen: true, newEmail: newContact });
  };

  public dialogClose = () => {
    this.setState({ isDialogOpen: false });
  };

  public dialogAdd = (person: IOptionData) => {
    this.onChange(this.state.contacts.concat([{ ...person, value: person.email, label: person.email }]));
  };

  public render() {
    const contacts = this.state.contacts.map((contact) => ({ ...contact, value: contact.email, label: contact.email }));
    const { id, label, field, onlyNameAndEmail, requireName } = this.props;
    const { status, errorMessage, data: allContacts } = this.props.contacts;
    const options = allContacts ? allContacts.map((contact) => ({ ...contact, value: contact.email, label: contact.email })) : [];

    const form = this.props.form;
    const fieldTouched = getObjectProperty(form.touched, field.name);
    const fieldErrors = getObjectProperty(form.errors, field.name);

    const MultiValueContainer = (props: MultiValueProps<IOptionData>) => {
      const isValid = validators.isEmail(props.data.email);
      return (
        <components.MultiValueContainer {...props}>
          {isValid ? (
            props.children
          ) : (
            <FlexContainer style={{ backgroundColor: colors.primary.orange }}> {props.children} </FlexContainer>
          )}
        </components.MultiValueContainer>
      );
    };

    // This removes empty value from existing options, otherwise options contain 'Create "" '
    const Option = (props: OptionProps<IOptionData, true>) => {
      const isValid = props.data.value !== "";
      if (isValid) {
        return <components.Option {...props}>{props.children}</components.Option>;
      } else {
        return <span />;
      }
    };

    return (
      <div>
        <NewPersonDialog
          isOpen={this.state.isDialogOpen}
          handleCancel={this.dialogClose}
          handleAdd={this.dialogAdd}
          onClose={this.dialogClose}
          onlyNameAndEmail={onlyNameAndEmail}
          onlyEmail={!requireName}
          defaultEmail={this.state.newEmail}
        />
        <MaxWidthContainer $margin={[2, 0]}>
          {label && <Label>{label}</Label>}

          <StyledSelectWrapper>
            <CreatableSelect
              isMulti
              id={id}
              value={contacts}
              isSearchable={true}
              isClearable={false}
              options={options}
              name={field.name}
              onBlur={field.onBlur}
              onChange={this.onChange}
              placeholder={translateString("spareParts.typeInEmails")}
              onCreateOption={this.onNewOption}
              className="react-select-container"
              classNamePrefix="react-select"
              components={{ MultiValueContainer, Option }}
              isValidNewOption={() => !this.props.forbidCreatable}
              isLoading={this.props.contacts.status === APIFetchStatus.Busy}
            />
          </StyledSelectWrapper>
        </MaxWidthContainer>
        {fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
        {status === APIFetchStatus.Failed && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </div>
    );
  }
}
