import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const Satisfied = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Satisfied</title>
    <g id="Icon_Text" data-name="Icon Text" color={props.color}>
      <path d="M24.19,45C13.22,45,4.3,35.59,4.3,24S13.22,3,24.19,3s19.88,9.42,19.88,21S35.15,45,24.19,45Zm0-38C15.43,7,8.3,14.63,8.3,24s7.13,17,15.88,17,15.88-7.63,15.88-17S32.94,7,24.19,7Z" />
      <path d="M22.19,20.45h-3a2.75,2.75,0,0,0-2.5-2.94,2.75,2.75,0,0,0-2.5,2.94h-3a5.74,5.74,0,0,1,5.5-5.94A5.74,5.74,0,0,1,22.19,20.45Z" />
      <path d="M37.19,20.45h-3a2.75,2.75,0,0,0-2.5-2.94,2.75,2.75,0,0,0-2.5,2.94h-3a5.74,5.74,0,0,1,5.5-5.94A5.74,5.74,0,0,1,37.19,20.45Z" />
      <path d="M24.19,36.76c-6,0-10.94-4-10.94-9h4c0,2.71,3.18,5,6.94,5s6.94-2.29,6.94-5h4C35.13,32.72,30.22,36.76,24.19,36.76Z" />
    </g>
  </IconBase>
);
