// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const managerDashboard_en_US: ITranslations = {
  en_US: {
    "managerDashboard.accountSummary": "Account summary",
    "managerDashboard.bulletinsForImmediateAttention": "Bulletins for immediate attention",
    "managerDashboard.currencyInfo": "Currency Conversion Information",
    "managerDashboard.currencyInfoTooltip": "For your convenience, we have converted monetary values to a single currency in this dashboard. For precise values, please check the transactions in their original currency.",
    "managerDashboard.documents.opened": "Opened",
    "managerDashboard.documents.unopened": "Unopened",
    "managerDashboard.error": "Failed to fetch account overview data",
    "managerDashboard.groupedEngines": "{engines} engines",
    "managerDashboard.installationsSelected": "One installation selected",
    "managerDashboard.installationsSelected.plural": "{numInstallations} installations selected",
    "managerDashboard.installationsSelected.plural:energy": "{numInstallations} power plants selected",
    "managerDashboard.installationsSelected.plural:marine": "{numInstallations} vessels selected",
    "managerDashboard.installationsSelected:energy": "One power plant selected",
    "managerDashboard.installationsSelected:marine": "One vessel selected",
    "managerDashboard.lastNDays": "Last {days} days",
    "managerDashboard.newlyPublishedDocuments": "Newly published documents",
    "managerDashboard.numberOfRequests": "Number of requests",
    "managerDashboard.section.guideText": "Maximum amount of installations in graphs is {numGraphs}.\nYou can filter which installations you'll see.",
    "managerDashboard.section.guideText:energy": "Maximum amount of power plants in graphs is {numGraphs}.\nYou can filter which power plants you'll see.",
    "managerDashboard.section.guideText:marine": "Maximum amount of vessels in graphs is {numGraphs}.\nYou can filter which vessels you'll see.",
    "managerDashboard.section.newlyPublishedAssetDiagnosticReports": "Newly published Maintenance and Condition Reports",
    "managerDashboard.section.overdueInvoices": "Overdue invoices",
    "managerDashboard.section.personnelLoggedIn": "Personnel logged in",
    "managerDashboard.section.purchasedSpareParts": "Purchased spare parts",
    "managerDashboard.section.runningHours": "Updated running hours",
    "managerDashboard.section.submittedRFQs": "Submitted RFQs",
    "managerDashboard.section.submittedRequests": "Submitted requests",
    "managerDashboard.section.totalBalance": "Total balance",
    "managerDashboard.statisticsForInstallations": "Statistics for selected installations",
    "managerDashboard.statisticsForInstallations:energy": "Statistics for selected power plants",
    "managerDashboard.statisticsForInstallations:marine": "Statistics for selected vessels",
    "managerDashboard.technicalDocuments": "Technical documents",
    "managerDashboard.timespan.caption": "Last day",
    "managerDashboard.timespan.caption.plural": "Last {days} days",
    "managerDashboard.tooltip.lastUpdated": "Last updated {date}",
    "managerDashboard.unopenedDocuments": "Unopened documents",
  },
};
