import { IEnergyDocumentTypes, INewsItem, Service } from "online-services-types";
import * as React from "react";
import { Heading } from "src/components/Heading";
import { InstallationDetails } from "src/components/InstallationDetails";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { DetailsButton } from "src/design-system/DetailsButton";
import { Grid, GridRowMediumItems } from "src/design-system/Tokens/grid";
import {
  IconSize,
  InvoicesIcon,
  OtherDocumentsIcon,
  PerformanceIcon,
  PlantLayoutsIcon,
  PlantPerformanceIcon,
  PlantPricesIcon,
  PowerGrid,
} from "src/icons";
import { BatteryIcon } from "src/icons/EquipmentIcons";
import { getPathWithParams, getRoutes, IRoutes } from "src/routes";
import { translateString } from "src/util/localization";

export interface IEnergyPortfolioViewStateProps {
  services: ReadonlyArray<Service>;
  news: INewsItem[];
  energyDocumentTypes: IEnergyDocumentTypes[];
  isReady: boolean;
}

export interface IEnergyPortfolioViewDispatchProps {
  getEnergyDocumentTypes(): void;
}

export type IEnergyPortfolioViewProps = IEnergyPortfolioViewStateProps & IEnergyPortfolioViewDispatchProps;

export class EnergyPortfolioViewComponent extends React.Component<IEnergyPortfolioViewProps> {
  public async componentDidMount() {
    if (this.props.energyDocumentTypes.length === 0) {
      this.props.getEnergyDocumentTypes();
    }
  }

  public renderEquipmentGrid(routes: IRoutes) {
    const types = this.props.energyDocumentTypes[0];
    return (
      <>
        <Heading text={translateString("energy.powerPlants")} />
        <Grid $marginBottom={4} itemsPerRow={GridRowMediumItems}>
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.performance || "" : "" })}
            icon={<PlantPerformanceIcon size={IconSize.Large} />}
            label={translateString("energy.plantPerformance")}
          />
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.layouts || "" : "" })}
            icon={<PlantLayoutsIcon size={IconSize.Large} />}
            label={translateString("energy.plantLayouts")}
          />
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.prices || "" : "" })}
            icon={<PlantPricesIcon size={IconSize.Large} />}
            label={translateString("energy.plantPrices")}
          />
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.other || "" : "" })}
            icon={<OtherDocumentsIcon size={IconSize.Large} />}
            label={translateString("energy.engineLeaflets")}
          />
        </Grid>
      </>
    );
  }

  public renderStorageAndOptimizationGrid(routes: IRoutes) {
    const types = this.props.energyDocumentTypes[0];
    return (
      <>
        <Heading text={translateString("energy.storageAndOptimization")} />
        <Grid $marginBottom={4} itemsPerRow={GridRowMediumItems}>
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.esGeneral || "" : "" })}
            icon={<PowerGrid size={IconSize.Large} />}
            label={translateString("energy.general")}
          />
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.esSolutions || "" : "" })}
            icon={<BatteryIcon size={IconSize.Large} />}
            label={translateString("energy.solutions")}
          />
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.esPerformance || "" : "" })}
            icon={<PerformanceIcon size={IconSize.Large} />}
            label={translateString("energy.performance")}
          />
          <DetailsButton
            href={getPathWithParams(routes.EnergyDocuments, { documentType: types ? types.esPricing || "" : "" })}
            icon={<InvoicesIcon size={IconSize.Large} />}
            label={translateString("energy.pricingIndications")}
          />
        </Grid>
      </>
    );
  }

  public render() {
    if (!this.props.isReady) {
      return <LoadingSpinner />;
    }

    const routes = getRoutes();
    const equipmentGrid = this.renderEquipmentGrid(routes);
    const storageAndOptimizationGrid = this.renderStorageAndOptimizationGrid(routes);

    return (
      <div>
        {equipmentGrid}
        {storageAndOptimizationGrid}
        <InstallationDetails />
      </div>
    );
  }
}
