// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const sfoc_en_US: ITranslations = {
  en_US: {
    "sfoc.aBank": "A bank",
    "sfoc.allTestHistory": "SFOC All Test Performance History",
    "sfoc.approved": "Approved",
    "sfoc.bBank": "B bank",
    "sfoc.baseline": "Baseline",
    "sfoc.chartWillBeUpdated": "The chart will be updated at the end of the Quarter",
    "sfoc.dateOfTest": "Date of Test",
    "sfoc.deleteDraft": "Delete draft",
    "sfoc.deltaToQB": "Delta to QB %",
    "sfoc.editDraft": "Edit draft",
    "sfoc.endDate": "SFOC test end date",
    "sfoc.engine": "Engine",
    "sfoc.engineNickname": "Engine Nickname",
    "sfoc.error.eventLogIsRequired": "Event Log is required.",
    "sfoc.error.valueHasToBe24Hourformat": "Time has to be in 24-hour format (HH:MM).",
    "sfoc.error.valueHasToBeBetween": "Value has to be between {min} and {max}",
    "sfoc.error.valueHasToBeNumeric": "Value has to be numeric.",
    "sfoc.error.valueIsRequired": "Value is required.",
    "sfoc.eventLog": "Please attach the Event Log for the Quarter Test",
    "sfoc.exportAllBrands": "Export all brands",
    "sfoc.field.ISOCorrectedSFOC": "ISO corrected SFOC",
    "sfoc.field.LTWaterTemperature": "LT water temperature",
    "sfoc.field.absoluteHumidity": "Absolute Humidity",
    "sfoc.field.activePower": "Active power",
    "sfoc.field.advancedAQS": "Advanced Air Quality System",
    "sfoc.field.afterTestTakeFuelSample": "Before start the test, take a fuel sample, note seal no, and measure water content with the test kit",
    "sfoc.field.aluminumSilicon": "Aluminum + Silicon",
    "sfoc.field.ambientConditionValuesOnSFOCCabinetSameAsLocalWeatherStation": "Ambient Condition values on SFOC Cabinet are the same as Local Weather Station",
    "sfoc.field.averageFiringPressureBar": "Average firing pressure {bank}",
    "sfoc.field.averageFiringPressureBarHeading": "Average firing pressure",
    "sfoc.field.barometricPressure": "Barometric pressure",
    "sfoc.field.betaFactor": "Beta factor",
    "sfoc.field.bunkerSampleNumber": "Bunker Sample Number",
    "sfoc.field.cacDeltaCleaner": "CAC Delta Cleaner",
    "sfoc.field.calculatedISOCorrectedSFOC": "Calculated ISO corrected SFOC",
    "sfoc.field.calculatedSFOC": "Calculated SFOC",
    "sfoc.field.chargeAirPressure": "Charge air pressure",
    "sfoc.field.chargeAirTemperature": "Charge air temperature",
    "sfoc.field.checkThatSFOCSystemIsWorkingProperly": "Before start with test, check that SFOC system is working properly",
    "sfoc.field.cleanLeakMeasurementTime": "Measurement time",
    "sfoc.field.consumedFuel": "Consumed fuel",
    "sfoc.field.cylinder": "Cylinder {cylinderNr}",
    "sfoc.field.deltaPOverCAC": "Δp over CAC",
    "sfoc.field.deltaPOverTCAirFilter": "Δp over T/C air filter",
    "sfoc.field.density": "Density @ 15°C",
    "sfoc.field.differentialAutoZeroValue": "Differential auto zero value",
    "sfoc.field.engine": "Engine",
    "sfoc.field.engineRoomTemperature": "Engine room temperature",
    "sfoc.field.engineRoomVentilationIsOn": "Engine room ventilation is ON",
    "sfoc.field.engineSpeed": "Engine speed",
    "sfoc.field.exhaustGasBackPressureAfterTC": "Exhaust gas back pressure after T/C",
    "sfoc.field.exhaustGasCleaningSystemOff": "Exhaust Gas Cleaning System (Scrubber) switched OFF",
    "sfoc.field.exhaustGasTemperatureAfterTC": "Exhaust gas temperature after T/C",
    "sfoc.field.exhaustGasTemperatureBeforeTC": "Exhaust gas temperature before T/C",
    "sfoc.field.exhaustTemperatureAfterCylinder": "Exhaust temperature after cylinder {bank}",
    "sfoc.field.exhaustTemperatureAfterCylinderHeading": "Exhaust temperature after cylinder",
    "sfoc.field.foSampleSealNumber": "FO Sample seal number",
    "sfoc.field.fuelCalorificValueLHV": "Fuel calorific value LHV",
    "sfoc.field.fuelFlowInlet": "Fuel flow inlet",
    "sfoc.field.fuelFlowReturn": "Fuel flow return",
    "sfoc.field.fuelInletFlowRatioToConsumption": "Fuel inlet flow ratio to consumption",
    "sfoc.field.fuelLeakInOneHour": "Fuel clean leak in 1 hour",
    "sfoc.field.fuelNetSpecificEnergyLHV": "Fuel Net Specific Energy LHV",
    "sfoc.field.fuelPressureBeforeEngine": "Fuel pressure before engine",
    "sfoc.field.fuelTemperatureBeforeEngine": "Fuel temperature before engine",
    "sfoc.field.fuelType": "Fuel type",
    "sfoc.field.fuelTypeIsTheSameAsUsedDuringTheBaselineTest": "Fuel type is the same as used during the baseline test",
    "sfoc.field.fuelViscosityBeforeEngine": "Fuel viscosity before engine",
    "sfoc.field.generatorPowerFactor": "Generated power factor (Cos Φ)",
    "sfoc.field.latestUpgradeType": "Latest SFOC Upgrade Type",
    "sfoc.field.loadIndicatorPosition": "Load indicator position",
    "sfoc.field.measuredCleanLeakWeight": "Measured clean leak weight",
    "sfoc.field.measuredFOC": "Measured FOC",
    "sfoc.field.measuredFuelOilCleanLeak": "Measured fuel oil clean leak",
    "sfoc.field.nickname": "Nickname",
    "sfoc.field.priorStartTestEngineShouldRunWithStableLoadFor20MinInLoadControl": "Prior start test, Engine should run with stable load for 20min. in load control",
    "sfoc.field.producedEnergy": "Produced energy",
    "sfoc.field.pumpRackPosition": "Pump rack position {bank}",
    "sfoc.field.pumpRackPositionHeader": "Pump rack position",
    "sfoc.field.quarterNr": "Quarter number",
    "sfoc.field.repeatTestWhenProducedEnergyIsAboveBaseline": "Test is not valid if produced Energy is outside ±{tolerance}% of the required load, correct the load and repeat the test",
    "sfoc.field.requiredLoadForTest": "Required load for test",
    "sfoc.field.requiredTestDurationOneHour": "Required test duration 60min",
    "sfoc.field.runHoursAtCACOH": "Total engine running hours at the time of last CAC OH {bank}",
    "sfoc.field.runHoursAtLastCACWash": "Total engine running hours at the time of last CAC wash",
    "sfoc.field.runHoursAtLastInjectionPumpOH": "Total engine running hours at the time of last injection pump OH",
    "sfoc.field.runHoursAtLastInjectorOH": "Total engine running hours at the time of last injector OH",
    "sfoc.field.runHoursAtLastMajorOH": "Total engine running hours at the time of last major OH",
    "sfoc.field.runHoursAtLastTCMaintenance": "Total engine running hours at the time of last T/C maintenance {bank}",
    "sfoc.field.runHoursAtLastTCWash": "Total engine running hours at the time of last T/C wash",
    "sfoc.field.samplingPosition": "Sampling position",
    "sfoc.field.sulfur": "Sulfur",
    "sfoc.field.testEndTime": "Test end time",
    "sfoc.field.testEngineInLoadControlModeConfirmThatActivePowerIsTheSameAtBaseline": "For the test, run engine in Load Control Mode at the required load",
    "sfoc.field.testFailed": "Test failed",
    "sfoc.field.testFailedDueTo": "Test failed due to",
    "sfoc.field.testStartTime": "Test start time",
    "sfoc.field.totalRunningHours": "Total running hours",
    "sfoc.field.turboChargerSpeed": "Turbo charger speed",
    "sfoc.field.typeOfSFOCTest": "Type of SFOC test",
    "sfoc.field.viscosity": "Viscosity @ 40°C / 50°C",
    "sfoc.field.waterContent": "Water content",
    "sfoc.field.waterContentMeasured": "Test is not valid, if water content is higher than 1 %. Please correct and re-test water content to proceed",
    "sfoc.field.waterContentMeasuredFromFuel": "Water content measured from fuel",
    "sfoc.field.waterFuelEmulsionOff": "Water Fuel Emulsion (WFE) OFF minimum 6 running hrs prior starting the test",
    "sfoc.fleet": "Fleet",
    "sfoc.fuelType": "Fuel Type",
    "sfoc.hfoBaseline": "HFO baseline",
    "sfoc.hfoMeasurement": "HFO",
    "sfoc.improvementHistory": "PBL Fleet SFOC Development History",
    "sfoc.info.savingDraft": "Saving draft.",
    "sfoc.info.savingDraftFailed": "Draft saving failed.",
    "sfoc.info.savingDraftSuccess": "Draft was saved successfully.",
    "sfoc.info.sendingReport": "Sending SFOC report.",
    "sfoc.info.sendingReportFailed": "SFOC report sending failed.",
    "sfoc.info.sendingReportSuccess": "SFOC report was sent successfully.",
    "sfoc.isoCorrectedSfocQn": "ISO Corrected SFOCqn",
    "sfoc.meanValue": "Mean",
    "sfoc.measurementPhase.ISOCorrectedSFOC": "ISO Corrected SFOC",
    "sfoc.measurementPhase.SFOCSavingQB": "SFOC Saving to QB",
    "sfoc.measurementPhase.dateOfMeasurement": "Date of measurement",
    "sfoc.measurementPhase.fuelSampleReceived": "Fuel sample received",
    "sfoc.measurementPhase.measurementReceived": "Measurement received",
    "sfoc.measurementPhase.sfocqnWithFOLeak": "SFOCqn With FO leak",
    "sfoc.measurementPhase.validation": "Validation",
    "sfoc.measurementPhase.validationAndApproval": "Validation & Approval percentage",
    "sfoc.mgoBaseline": "MGO baseline",
    "sfoc.mgoMeasurement": "MGO",
    "sfoc.noData": "No data found",
    "sfoc.noDataMeasurement": "No data",
    "sfoc.noValidation": "Data will appear when the validation period has ended",
    "sfoc.phase.automation": "Automation",
    "sfoc.phase.cabinet": "Cabinet",
    "sfoc.phase.engine": "Engine",
    "sfoc.phase.general": "General",
    "sfoc.phase.overview": "Overview",
    "sfoc.phase.section.dataCollection": "Data Collection For Fuel Calculation",
    "sfoc.phase.section.engineOverHaulHistory": "Engine overhaul history",
    "sfoc.phase.section.fuelBunkerAnalysisReport": "Fuel Bunker Analysis Report",
    "sfoc.phase.section.fuelSampleSampleInfo": "SFOC Fuel Sample Info",
    "sfoc.phase.section.general": "General",
    "sfoc.phase.section.performanceTest": "Engine Performance Test",
    "sfoc.phase.section.sfocPerformanceCheckList": "SFOC Performance Test Check-List",
    "sfoc.phase.summary": "Summary",
    "sfoc.print": "Print SFOC report",
    "sfoc.productReferenceType": "Product Reference Type",
    "sfoc.quarter": "Quarter",
    "sfoc.quarterMeasurementStatus": "Q{quarter} measurement status",
    "sfoc.quarterNr": "Quarter number",
    "sfoc.quarterlyDevelopment": "Q{quarter} SFOC Development",
    "sfoc.quarterlyVerifiedSFOCMeasurements": "Quarterly verified SFOC measurements",
    "sfoc.reasonForRejection": "Reason for rejection",
    "sfoc.routing.toFleetView": "To fleet view",
    "sfoc.routing.toVesselView": "To vessel view",
    "sfoc.samplingPosition.AtFOBoosterUnite": "At FO Booster unite",
    "sfoc.samplingPosition.BeforeEngine": "Before engine",
    "sfoc.samplingPosition.Other": "Other",
    "sfoc.selectRangeOfQuarters": "Select range of quarters",
    "sfoc.sfocQnWithFoLeak": "SFOCqn with FO leak",
    "sfoc.sfocTestPerformanceHistory": "SFOC status performance history",
    "sfoc.startDate": "SFOC test start date",
    "sfoc.status": "Status",
    "sfoc.testFailure.DiffFlowFluctuationOver2": "Diff. flow fluctuation over > ±2%",
    "sfoc.testFailure.LoadAndDiffFlowFluctuationOver2": "Load and diff. flow fluctuation over > ±2%",
    "sfoc.testFailure.LoadFluctuationOver2": "Load fluctuation over > ±2%",
    "sfoc.testFollowingUp": "Q{quarter} SFOC Test Following Up",
    "sfoc.unit.MJPerKg": "MJ/kg",
    "sfoc.unit.bar": "bar",
    "sfoc.unit.gPerKg": "g/kg",
    "sfoc.unit.gPerkWh": "g/kWh",
    "sfoc.unit.hours": "h",
    "sfoc.unit.kJPerHour": "kJ/h",
    "sfoc.unit.kW": "kW",
    "sfoc.unit.kWh": "kWh",
    "sfoc.unit.kg": "kg",
    "sfoc.unit.kgPerHour": "kg/h",
    "sfoc.unit.kgPerM3": "kg/m³",
    "sfoc.unit.mbar": "mbar",
    "sfoc.unit.mgPerKg": "mg/kg",
    "sfoc.unit.min": "min",
    "sfoc.unit.mm": "mm",
    "sfoc.unit.mm2PerSec": "mm²/s",
    "sfoc.unit.percent": "%",
    "sfoc.unit.rpm": "rpm",
    "sfoc.useSliderToSelectRangeOfQuarters": "Use slider to select range of quarters to be exported.",
    "sfoc.value.afterOH": "After OH",
    "sfoc.value.audit": "Audit",
    "sfoc.value.na": "N/A",
    "sfoc.value.no": "No",
    "sfoc.value.notSpecified": "Not Specified",
    "sfoc.value.off": "Off",
    "sfoc.value.on": "On",
    "sfoc.value.other": "Other",
    "sfoc.value.quarterly": "Quarterly",
    "sfoc.value.yes": "Yes",
    "sfoc.vessel": "Vessel",
  },
};
