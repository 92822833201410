import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const ContractIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Contracts</title>
    <path d="M27.5,4H8.5V24h4V8h11V20h12V40h-23V38h-4v6h31V16Zm0,12V9.75L33.75,16Z" />
    <rect x="8.5" y="26.01" width="16" height="4" />
    <rect x="8.5" y="32.01" width="6" height="4" />
  </IconBase>
);
