import React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const PowerGrid = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Power grid</title>
    <path
      fillRule="evenodd"
      d="M 4 4 V 44 H 44 V 4 H 4 Z M 39.5556 12.8889 H 35.1111 V 8.44444 H 39.5556 V 12.8889 Z M 15.1111 15.1111 V 32.8889 H 32.8889 V 15.1111 H 15.1111 Z M 32.8889 35.1111 V 39.5556 H 15.1111 V 35.1111 H 32.8889 Z M 8.44444 32.8889 H 12.8889 V 15.1111 H 8.44444 V 32.8889 Z M 15.1111 12.8889 V 8.44444 H 32.8889 V 12.8889 H 15.1111 Z M 39.5556 15.1111 H 35.1111 V 32.8889 H 39.5556 V 15.1111 Z M 12.8889 8.44444 V 12.8889 H 8.44444 V 8.44444 H 12.8889 Z M 12.8889 35.1111 H 8.44444 V 39.5556 H 12.8889 V 35.1111 Z M 35.1111 39.5556 V 35.1111 H 39.5556 V 39.5556 H 35.1111 Z M 25.0222 23.6667 H 28.4444 L 22.2889 31.1889 L 22.9778 25.0333 H 19.5556 L 25.7111 16.8333 L 25.0222 23.6667 Z"
    />
  </IconBase>
);
