import { useState } from "react";
import * as React from "react";
import colors from "src/design-system/Tokens/colors";
import { IconSize, SadFace, HappyFace, PartlySatisfied } from "src/icons";
import { getRatingNames, Rating } from "./ratingNames";
import { ReopenComponent } from "../RatingButtons/ReopenComponent";
import { ICombinedRequestViewModel } from "src/models/warrantyClaim";
import { ICombinedRequest } from "online-services-types";
import { IReopenFormFields } from "../ReopenDialog/ReopenDialogComponent";
import { IconButton } from "../RatingButtons/IconButton";

interface IRatingComponent {
  displayReopenButton: boolean;
  item: ICombinedRequestViewModel;
  onChange(value: Rating): void;
  reopenRequest(request: ICombinedRequest, data: IReopenFormFields): void;
}

const RatingFace = (props: {
  value: Rating;
  isSelected: boolean;
  ratingNames: { [key: string]: string };
  onClick(): void;
}) => {
  const icons: { [value: string]: JSX.Element } = {
    [Rating.NotSatisfied]: <SadFace size={IconSize.Large} color={colors.notification.error} />,
    [Rating.PartlySatisfied]: <PartlySatisfied size={IconSize.Large} color={colors.notification.warning} />,
    [Rating.Satisfied]: <HappyFace size={IconSize.Large} color={colors.notification.ok} />,
  };
  const icon = icons[props.value] || <span />;
  return (
    <IconButton
      onClick={props.onClick}
      icon={icon}
      isSelected={props.isSelected}
      label={props.ratingNames[props.value]}
    />
  );
};

export const RatingComponent = (props: IRatingComponent) => {
  const ratingNames = getRatingNames();
  const [selectedRating, setSelectedRating] = useState<Rating | null>(null);
  const pickValue = (value: Rating) => {
    setSelectedRating(value);
    props.onChange(value);
  };

  return (
    <div>
      {Object.keys(ratingNames).map((value) => (
        <RatingFace
          key={value}
          value={value as Rating}
          isSelected={selectedRating === (value as Rating)}
          onClick={() => pickValue(value as Rating)}
          ratingNames={ratingNames}
        />
      ))}
      <ReopenComponent
        item={props.item}
        reopenRequest={props.reopenRequest}
        standalone={false}
        displayReopenButton={props.displayReopenButton}
      />
    </div>
  );
};
