import * as React from "react";
import { TooltipRenderProps } from "react-joyride";
import styled from "styled-components";

import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { Button, ButtonStyle } from "src/design-system/Button";
import colors from "src/design-system/Tokens/colors";
import { CloseIcon, IconSize } from "src/icons";
import { whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import { IExtendedStep } from "src/redux/onboarding";

// TODO: wrap Tooltip in ThemeProvider and take colors from theme

const TooltipBody = styled.div`
  position: relative;
  background-color: ${colors.primary.white};
  min-width: 300px;
  max-width: 350px;
  position: relative;
  border-radius: ${defaultBorderRadius};

  ${whenTabletOrLarger(`
    max-width: 520px;
  `)}
`;

const TooltipContent = styled.div`
  color: ${colors.primary.white};
  padding: 20px;
`;

const TooltipTitle = styled.h2`
  color: ${colors.primary.white};
  padding: 20px;
  margin: 0;
`;

const TooltipFooter = styled.div`
  padding: 12px 18px 24px;
  display: flex;
  justify-content: flex-end;
`;

const SkipButton = styled(Button)`
  margin-right: auto;
`;

const PreviousButton = styled(Button)`
  margin-left: 5px;
`;

const TooltipPrimaryButton = styled(Button)`
  margin-left: 5px;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 12px;
`;

interface ITooltipProps extends TooltipRenderProps {
  tooltipProps: any;
  step: IExtendedStep;
}

export const Tooltip = ({
  index,
  step,
  isLastStep,
  backProps,
  closeProps,
  skipProps,
  primaryProps,
  tooltipProps,
}: ITooltipProps) => (
  <TooltipBody {...tooltipProps}>
    <CloseIconWrapper onClick={isLastStep ? closeProps.onClick : skipProps.onClick}>
      <CloseIcon size={IconSize.Medium} color={colors.secondary.blue100} />
    </CloseIconWrapper>

    {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
    <TooltipContent>{step.content}</TooltipContent>
    <TooltipFooter>
      {!isLastStep && (
        <SkipButton {...skipProps} buttonStyle={ButtonStyle.Secondary}>
          <LocalizedStringComponent id="button.skip" />
        </SkipButton>
      )}

      {index > 0 && (
        <PreviousButton {...backProps}>
          <LocalizedStringComponent id="button.previous" />
        </PreviousButton>
      )}
      {!isLastStep && (
        <TooltipPrimaryButton {...primaryProps}>
          {step.nextText ? step.nextText : <LocalizedStringComponent id="button.next" />}
        </TooltipPrimaryButton>
      )}
      {isLastStep && (
        <TooltipPrimaryButton {...closeProps}>
          <LocalizedStringComponent id="button.close" />
        </TooltipPrimaryButton>
      )}
    </TooltipFooter>
  </TooltipBody>
);
