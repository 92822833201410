// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_zh_CN: ITranslations = {
  zh_CN: {
    "user.addNewUser": "添加用户",
    "user.allUsersInAccount": "您账户中的所有用户",
    "user.department": "部门",
    "user.digest.addAllInstallations": "添加所有设施",
    "user.digest.addAllInstallations:energy": "添加所有发电厂",
    "user.digest.addAllInstallations:marine": "添加所有船舶",
    "user.digest.period.daily": "每日",
    "user.digest.period.monthly": "每月",
    "user.digest.period.never": "从不",
    "user.digest.period.weekly": "每周",
    "user.digest.service.reports": "报告",
    "user.digest.service.requests": "请求",
    "user.digest.service.spareParts": "备件",
    "user.digest.service.technicalDocuments": "技术文件",
    "user.digest.validation.noInstallationsSelected": "需要填写设施",
    "user.digest.validation.noInstallationsSelected:energy": "需要填写发电厂。",
    "user.digest.validation.noInstallationsSelected:marine": "需要填写船舶",
    "user.digest.validation.noServicesSelected": "需要填写服务。",
    "user.digestDescription.daily": "每天，对应一台设施",
    "user.digestDescription.daily.plural": "每天，对应{numberOfInstallations}台设施",
    "user.digestDescription.daily.plural:energy": "每天，对应{numberOfInstallations}个发电厂",
    "user.digestDescription.daily.plural:marine": "每天，对应{numberOfInstallations}艘船舶",
    "user.digestDescription.daily:energy": "每天，对应一个发电厂",
    "user.digestDescription.daily:marine": "每天，对应一艘船舶",
    "user.digestDescription.monthly": "每月，对应一台设施",
    "user.digestDescription.monthly.plural": "每月，对应{numberOfInstallations}台设施",
    "user.digestDescription.monthly.plural:energy": "每月，对应{numberOfInstallations}个发电厂",
    "user.digestDescription.monthly.plural:marine": "每月，对应{numberOfInstallations}艘船舶",
    "user.digestDescription.monthly:energy": "每月，对应一个发电厂",
    "user.digestDescription.monthly:marine": "每月，对应一艘船舶",
    "user.digestDescription.never": "禁用",
    "user.digestDescription.weekly": "每周的周日，对应一台设施",
    "user.digestDescription.weekly.plural": "每周的周日，对应{numberOfInstallations}台设施",
    "user.digestDescription.weekly.plural:energy": "每周的周日，对应{numberOfInstallations}个发电厂",
    "user.digestDescription.weekly.plural:marine": "每周的周日，对应{numberOfInstallations}艘船舶",
    "user.digestDescription.weekly:energy": "每周的周日，对应一个发电厂",
    "user.digestDescription.weekly:marine": "每周的周日，对应一艘船舶",
    "user.digestEmail": "摘要电子邮件",
    "user.digestEmailSettings": "摘要电子邮件设置",
    "user.digestSettingsForm.frequencyOfDigest": "摘要频次",
    "user.digestSettingsForm.servicesOfInterest": "有关服务",
    "user.email": "电子邮件地址",
    "user.emailIsRequired": "需要填写电子邮箱",
    "user.emailShort": "电子邮箱",
    "user.firstname": "名",
    "user.firstnameIsRequired": "需要填写名字",
    "user.lastname": "姓",
    "user.lastnameIsRequired": "需要填写姓",
    "user.name": "名称",
    "user.notificationSettings": "通知设置",
    "user.phone": "电话",
    "user.phoneNumber": "电话号码",
    "user.reasonForRemoval": "删除的原因",
    "user.reasonForRemovalIsRequired": "需要填写删除的原因",
    "user.removeUser": "删除用户",
    "user.title": "标题",
    "user.userInformation": "用户信息",
    "user.userPassword": "密码",
  },
};
