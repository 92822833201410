import * as React from "react";

import { Button } from "@wartsila/ui-kit";
import { CSVLink } from "react-csv";
import { Container } from "src/design-system/Container";
import { ExportDelimiterDialog } from "../ExportDelimiterDialog";
import { ExportPartModel } from "../BasketExportDocument";
import { LinkProps } from "react-csv/components/Link";
import { translateString } from "src/util/localization";

export const ExportCSV = ({
  data,
  enabled,
  headers,
  filename,
}: {
  enabled: boolean;
  filename: string;
  data: ExportPartModel[];
  headers: {
    key: string;
    label: string;
  }[];
}) => {
  const [selectedDelimiter, setSelectedDelimiter] = React.useState(";");
  const csvLinkRef = React.useRef<{ link: { click: () => void } }>(null);
  const [isDelimiterDialogOpen, setIsDelimiterDialogOpen] = React.useState(false);
  const toggleDelimiterDialog = () => setIsDelimiterDialogOpen(!isDelimiterDialogOpen);

  const onSubmit = (delimiter: string) => {
    setSelectedDelimiter(delimiter);
    csvLinkRef.current?.link.click();
    setIsDelimiterDialogOpen(false);
  };

  return (
    <Container>
      <ExportDelimiterDialog
        useLiteralValue
        onSubmit={onSubmit}
        isOpen={isDelimiterDialogOpen}
        onCancel={toggleDelimiterDialog}
        title={translateString("spareParts.exportAsCSV")}
        example={["Installation", "Product id", "Reference type", "Spare..."]}
      />
      <CSVLink
        data={data}
        headers={headers}
        filename={filename}
        separator={selectedDelimiter}
        ref={csvLinkRef as React.RefObject<LinkProps> & React.RefObject<HTMLAnchorElement>}
      />
      <Button loading={!enabled} onClick={toggleDelimiterDialog}>
        {translateString("spareParts.exportAsCSV")}
      </Button>
    </Container>
  );
};
