import React from "react";
import { LocalizedString } from "src/components/Localization";
import { Container } from "src/design-system/Container";
import { FlexContainer } from "src/design-system/Container";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import { Disclaimer, H3 } from "src/design-system/Tokens/typography";
import { formatDate } from "src/util/formatters";
import styled from "styled-components";

const Wrapper = styled.div`
  border-radius: ${defaultBorderRadius};
  background: ${(props) => props.theme.navigationButton.background};
`;

export const QuarterDisplay = ({
  quarter,
  startDate,
  endDate,
}: {
  quarter: string;
  startDate: string;
  endDate: string;
}) => (
  <Wrapper>
    <Container>
      <FlexContainer>
        <Container $marginRight={3} $marginLeft={3} $padding={[0, 3, 0, 0]}>
          <H3>{quarter}</H3>
        </Container>
        <Container $marginRight={3}>
          <Container>
            <Disclaimer>
              <LocalizedString id="sfoc.startDate" />
            </Disclaimer>
          </Container>
          <Container>{formatDate(startDate)}</Container>
        </Container>
        <Container $marginRight={3}>
          <Container>
            <Disclaimer>
              <LocalizedString id="sfoc.endDate" />
            </Disclaimer>
          </Container>
          <Container>{formatDate(endDate)}</Container>
        </Container>
      </FlexContainer>
    </Container>
  </Wrapper>
);
