import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const ScaleDownIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Scale Down</title>
    <path d="M7,7V41H41V7ZM37,37H11V11H37Z"/><polygon points="21 28.41 21 31 23 31 23 25 17 25 17 27 19.59 27 14.59 32 16 33.41"/><polygon points="27 19.59 27 17 25 17 25 23 31 23 31 21 28.41 21 33.41 16 32 14.59"/>
  </IconBase>
);

