import { IAttachment, INotification } from "online-services-types";
import * as React from "react";
import { LocalizedString } from "src/components/Localization";
import { NotificationBase } from "src/components/SupportList/NotificationBase";
import { LinkWrapper } from "src/design-system/Link";
import { ICombinedRequestViewModel } from "src/models/warrantyClaim";
import { getPathWithParams, getRoutes, IRouteParams, redirectBrowserToURL } from "src/routes";
import { RouteType } from "src/routeType";
import { INotificationContext, NotificationContext } from "src/models/notifications";

interface INewAttachmentComponentProps {
  item: ICombinedRequestViewModel;
  notification: INotification<IAttachment>;
  discardNotification(ids: string[], notificationContext: INotificationContext): void;
}

export class NewAttachmentComponent extends React.Component<INewAttachmentComponentProps> {
  constructor(props: INewAttachmentComponentProps) {
    super(props);

    this.handleView = this.handleView.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  public render() {
    return (
      <NotificationContext.Consumer>
        {(notificationContext) => (
          <NotificationBase onDismiss={() => this.handleConfirm(notificationContext)}>
            <LocalizedString
              id="request.notification.newAttachmentAdded"
              values={{
                link: this.props.notification.data ? (
                  <LinkWrapper onClick={() => this.handleView(notificationContext)}>
                    <LocalizedString id="request.notification.newAttachmentAdded.linkText" />
                  </LinkWrapper>
                ) : (
                  <LocalizedString id="request.notification.newAttachmentAdded.linkText" />
                ),
              }}
            />
          </NotificationBase>
        )}
      </NotificationContext.Consumer>
    );
  }

  private readonly handleView = (notificationContext: INotificationContext) => {
    const attachment: IAttachment = this.props.notification.data;
    if (!!attachment) {
      const pathParams = { fileId: attachment.id };
      const queryParams: IRouteParams = { requestId: this.props.item.id, requestType: this.props.item.requestType };

      const path =
        getPathWithParams(getRoutes(RouteType.Standalone).Download, pathParams as {} as IRouteParams) +
        `?${Object.keys(queryParams)
          .map((key) => `${key}=${queryParams[key]}`)
          .join("&")}`;

      redirectBrowserToURL(path, true);
      this.props.discardNotification([this.props.notification.id], notificationContext);
    }
  };

  private readonly handleConfirm = (notificationContext: INotificationContext) => {
    this.props.discardNotification([this.props.notification.id], notificationContext);
  };
}
