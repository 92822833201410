import * as React from "react";

import { Loader, spinnerSize } from "../LoadingSpinner/LoadingSpinner";

import { FlexContainer } from "src/design-system/Container";
import { PdfIcon } from "src/icons";
import colors from "src/design-system/Tokens/colors";
import { displayError } from "src/util/error";
import { getClassificationAttachmentDownloadUrl } from "src/util/commerce";
import styled from "styled-components";

interface IDownloadProps {
  salesDocumentNumber: string;
  description: string;
}

const IconWrapper = styled(FlexContainer)`
  cursor: pointer;
`;

export const ClassificationAttachmentDownload = (props: IDownloadProps) => {
  const [isDownloading, setDownloading] = React.useState(false);
  const downloadAttachment = async () => {
    try {
      let downloadUrl;
      const { salesDocumentNumber, description } = props;
      downloadUrl = await getClassificationAttachmentDownloadUrl(salesDocumentNumber, description);

      if (downloadUrl) {
        for (const url of downloadUrl) {
          window.open(url, "_blank");
        }
        setDownloading(false);
      }

      setDownloading(false);
    } catch (e) {
      displayError(e);
      setDownloading(false);
    }
  };

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (!isDownloading) {
      setDownloading(true);
      await downloadAttachment();
    }
  };

  return (
    <IconWrapper $centered onClick={handleClick}>
      {!isDownloading ? <PdfIcon color={colors.primary.black} /> : null}
      {isDownloading && <Loader dark size={spinnerSize.sm} />}
    </IconWrapper>
  );
};
