import * as React from 'react';
import { ThemedProps } from 'src/design-system/Theme/theme';
import colors from 'src/design-system/Tokens/colors';
import { fixedFontSizes } from 'src/design-system/Tokens/typography';
import styled from 'styled-components';

import { Tooltip, TooltipArrow } from '@wartsila/ui-kit';

const InfoTooltip = styled(Tooltip)<{ width?: string, top?: string }>`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  color: ${colors.primary.black} !important;
  transform: translate(0, -50%);
  position: absolute;
  z-index: 1000;
  width: ${(props) => props.width ? props.width : "300px"};
  left: 2rem;
  top: ${(props) => props.top ? props.top : "50%"};
`;

interface IIconAndTooltipWrapperProps {
  display?: string;
  position?: string;
  width?: string;
  height?: string;
  margin?: string;
  zIndex?: string;
}

const IconAndTooltipWrapper = styled.div<IIconAndTooltipWrapperProps>`
  display: ${(props) => props.display || "block"};
  position: ${(props) => props.position || "absolute"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "auto"};
  margin: ${(props) => props.margin ? props.margin : ""};
`;

const IconWrapper = styled.div`
  color: ${(props: ThemedProps) => props.theme.icon.defaultColor};
`;

interface IIconWithTooltipProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  width?: string;
  top?: string;
  arrow?: TooltipArrow | false;
  iconProps?: IIconAndTooltipWrapperProps;
}

export const IconWithTooltip = ({ children, icon, width, top, arrow = TooltipArrow.Left, iconProps = {} }: IIconWithTooltipProps) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>();

  return (
    <IconAndTooltipWrapper {...iconProps} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <IconWrapper>{icon}</IconWrapper>
      {showTooltip ? <InfoTooltip arrow={arrow} width={width} top={top}>{children}</InfoTooltip> : null}
    </IconAndTooltipWrapper>
  );
};
