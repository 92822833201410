import * as React from "react";

import { ErrorToast } from "src/components/ErrorToast/ErrorToast";
import { FlagIcon } from "src/icons";
import { IError } from "online-services-types";
import { IIconBase } from "src/icons/IconBase";
import { isRunningInProduction } from "src/redux/applicationContext";
import { redirectBrowserToURL } from "src/routes";
import { toast } from "react-toastify";
import { translateString } from "./localization";

type MessageID = string | number;

/**
 * Display error message. It is preferable to pass in the whole IError
 * object so that the requestId and message are passed from the API.
 *
 * @param error IError object or string error message
 * @param customTitle Override default title
 * @param options Override default options
 * @param extRequestId Override the awsRequestId or pass in case error object is not available
 */

interface IMessageOptions {
  autoClose: false | undefined | number;
  toastId?: string;
  url?: string;
}

export function displayError(
  error: string | IError = "",
  customTitle?: string,
  options: IMessageOptions = { autoClose: false },
  extRequestId?: string
): MessageID {
  const customMessage = typeof error === "string" ? error : error.message;
  const requestId = extRequestId || (typeof error === "string" ? undefined : error.awsRequestId);
  const isNetworkError = customMessage.indexOf(translateString("error.networkError")) !== -1;
  const isReadOnlyError = customMessage.indexOf("readonly") !== -1;
  const isPasswordError =
    customMessage.includes("contain your user name") || customMessage.includes("repeated password");
  const isAuthHeaderError = customMessage.includes("No Authorization header set in request");
  const shouldDisplayDetails =
    !isRunningInProduction() || isPasswordError || isNetworkError || isAuthHeaderError || isReadOnlyError;
  const cleanMessage = customMessage.replace(new RegExp("^(Error: )*", "g"), "");

  let title = customTitle;
  let message = cleanMessage;
  let link = "";
  let prompt = "";

  if (isNetworkError) {
    title = translateString("error.networkError");
    message = translateString("error.networkErrorPleaseCheckConnectivity");
    link = window.location.href;
    prompt = translateString("error.networkErrorRefreshPrompt");
    options = { autoClose: false, toastId: "network-error" };
  }

  if (isReadOnlyError) {
    title = translateString("error.readOnlyErrorTitle");
    message = translateString("error.readOnlyErrorMsg");
    options = { autoClose: false, toastId: "readonly-error" };
  }

  if (isAuthHeaderError) {
    message = translateString("error.refreshPage");
    options = { autoClose: false, toastId: "auth-header-error" };
  }

  if (isPasswordError) {
    options = { autoClose: 5_000, toastId: "password-error" };
    if (customMessage.includes("contain your user name")) {
      message = translateString("user.cannotContainUsername");
    } else if (customMessage.includes("repeated password")) {
      message = translateString("user.repeatedPassword");
    }
  }

  return toast(
    (props) => (
      <ErrorToast
        type="error"
        title={title || translateString("error.defaultTitle")}
        message={shouldDisplayDetails ? message : undefined}
        link={link}
        linkPrompt={prompt}
        onClose={() => props.closeToast?.()}
        requestId={requestId}
      />
    ),
    options
  );
}

/**
 * Display success message
 *
 * @param customMessage Override default message
 * @param customTitle Override default title
 * @param options Override default options
 */

export function displaySuccess(
  customMessage?: string,
  customTitle?: string,
  options: IMessageOptions = { autoClose: 3000 }
): MessageID {
  return toast(
    (props) => (
      <ErrorToast
        type="success"
        title={customTitle || translateString("success.defaultTitle")}
        message={customMessage || translateString("success.defaultMessage")}
        onClose={() => props.closeToast?.()}
      />
    ),
    options
  );
}

export function displayCustom({
  title,
  link,
  message,
  content,
  onClose,
  linkPrompt,
  customIcon,
  options = { autoClose: 3000 },
}: {
  link?: string;
  linkPrompt?: string;
  title?: string;
  message?: string;
  onClose?: () => void;
  content: React.ReactNode;
  options?: IMessageOptions;
  customIcon?: React.ComponentType<IIconBase>;
}): MessageID {
  return toast(
    (props) => (
      <ErrorToast
        link={link}
        title={title}
        type="custom"
        content={content}
        message={message}
        linkPrompt={linkPrompt}
        customIcon={customIcon}
        onClose={() => {
          onClose?.();
          props.closeToast?.();
        }}
      />
    ),
    options
  );
}

/**
 * Display progress message
 *
 * @param customMessage Override default message
 * @param customTitle Override default title
 * @param options Override default options
 */

export function displayProgress(
  customMessage: string,
  customTitle?: string,
  options: IMessageOptions = { autoClose: false }
) {
  return toast(
    (props) => (
      <ErrorToast
        type="progress"
        title={customTitle}
        message={customMessage}
        onClose={() => {
          props.closeToast?.();
          if (options.url) {
            redirectBrowserToURL(options.url);
          }
        }}
      />
    ),
    options
  );
}

/**
 * Display progress message
 *
 * @param customMessage Override default message
 * @param customTitle Override default title
 * @param options Override default options
 */

export function displayPushNotfication(
  customMessage: string,
  customTitle?: string,
  options: IMessageOptions = { autoClose: false },
  url?: string
) {
  return toast(
    (props) => (
      <ErrorToast
        type="progress"
        title={customTitle}
        message={customMessage}
        customIcon={FlagIcon}
        onClose={() => {
          props.closeToast?.();
        }}
        link={url}
        linkPrompt={translateString("notifications.openPushNotificationLink")}
      />
    ),
    options
  );
}

export const displayInfo = displaySuccess;

/**
 * Update toast
 */

export function updateMessage(toastId: MessageID, options: IMessageOptions) {
  toast.update(toastId, options);
}

/**
 * Close toast
 */

export function dismissMessage(toastId: string | number) {
  toast.dismiss(toastId);
}
