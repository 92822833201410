export interface IQuarterWithDates {
  quarter: string;

  quarterEndDate: string;
  quarterStartDate?: string;

  endMesurements: string;
  startMeasurements: string;
}

export const datesOfQuarters: IQuarterWithDates[] = [
  {
    quarter: "1",
    startMeasurements: "2017-03-22",
    endMesurements: "2017-05-01",
    quarterEndDate: "2017-05-31",
    quarterStartDate: "2017-03-01",
  },
  {
    quarter: "2",
    startMeasurements: "2017-06-22",
    endMesurements: "2017-08-01",
    quarterEndDate: "2017-08-31",
    quarterStartDate: "2017-06-01",
  },
  {
    quarter: "3",
    startMeasurements: "2017-09-21",
    endMesurements: "2017-10-31",
    quarterEndDate: "2017-11-30",
    quarterStartDate: "2017-09-01",
  },
  {
    quarter: "4",
    startMeasurements: "2017-12-20",
    endMesurements: "2018-01-29",
    quarterEndDate: "2018-02-28",
    quarterStartDate: "2017-12-01",
  },

  {
    quarter: "5",
    startMeasurements: "2018-03-22",
    endMesurements: "2018-05-01",
    quarterEndDate: "2018-05-31",
    quarterStartDate: "2018-03-01",
  },
  {
    quarter: "6",
    startMeasurements: "2018-06-22",
    endMesurements: "2018-08-01",
    quarterEndDate: "2018-08-31",
    quarterStartDate: "2018-06-01",
  },
  {
    quarter: "7",
    startMeasurements: "2018-09-21",
    endMesurements: "2018-10-31",
    quarterEndDate: "2018-11-30",
    quarterStartDate: "2018-09-01",
  },
  {
    quarter: "8",
    startMeasurements: "2018-12-20",
    endMesurements: "2019-01-29",
    quarterEndDate: "2019-02-28",
    quarterStartDate: "2018-12-01",
  },

  {
    quarter: "9",
    startMeasurements: "2019-03-22",
    endMesurements: "2019-05-01",
    quarterEndDate: "2019-05-31",
    quarterStartDate: "2019-03-01",
  },
  {
    quarter: "10",
    startMeasurements: "2019-06-22",
    endMesurements: "2019-08-01",
    quarterEndDate: "2019-08-31",
    quarterStartDate: "2019-06-01",
  },
  {
    quarter: "11",
    startMeasurements: "2019-09-21",
    endMesurements: "2019-10-31",
    quarterEndDate: "2019-11-30",
    quarterStartDate: "2019-09-01",
  },
  {
    quarter: "12",
    startMeasurements: "2019-12-20",
    endMesurements: "2020-01-29",
    quarterEndDate: "2020-02-28",
    quarterStartDate: "2019-12-01",
  },

  {
    quarter: "13",
    startMeasurements: "2020-03-22",
    endMesurements: "2020-05-01",
    quarterEndDate: "2020-05-31",
    quarterStartDate: "2020-03-01",
  },
  {
    quarter: "14",
    startMeasurements: "2020-06-22",
    endMesurements: "2020-08-01",
    quarterEndDate: "2020-08-31",
    quarterStartDate: "2020-06-01",
  },
  {
    quarter: "15",
    startMeasurements: "2020-09-21",
    endMesurements: "2020-10-31",
    quarterEndDate: "2020-11-30",
    quarterStartDate: "2020-09-01",
  },
  {
    quarter: "16",
    startMeasurements: "2020-12-20",
    endMesurements: "2021-01-29",
    quarterEndDate: "2021-02-28",
    quarterStartDate: "2020-12-01",
  },

  {
    quarter: "17",
    startMeasurements: "2021-03-22",
    endMesurements: "2021-05-01",
    quarterEndDate: "2021-05-31",
    quarterStartDate: "2021-03-01",
  },
  {
    quarter: "18",
    startMeasurements: "2021-06-22",
    endMesurements: "2021-08-01",
    quarterEndDate: "2021-08-31",
    quarterStartDate: "2021-06-01",
  },
  {
    quarter: "19",
    startMeasurements: "2021-09-21",
    endMesurements: "2021-10-31",
    quarterEndDate: "2021-11-30",
    quarterStartDate: "2021-09-01",
  },
  {
    quarter: "20",
    startMeasurements: "2021-12-20",
    endMesurements: "2022-01-29",
    quarterEndDate: "2022-02-28",
    quarterStartDate: "2021-12-01",
  },

  {
    quarter: "21",
    startMeasurements: "2022-03-22",
    endMesurements: "2022-05-01",
    quarterEndDate: "2022-05-31",
    quarterStartDate: "2022-03-01",
  },
  {
    quarter: "22",
    startMeasurements: "2022-06-22",
    endMesurements: "2022-08-01",
    quarterEndDate: "2022-08-31",
    quarterStartDate: "2022-06-01",
  },
  {
    quarter: "23",
    startMeasurements: "2022-09-21",
    endMesurements: "2022-10-31",
    quarterEndDate: "2022-11-30",
    quarterStartDate: "2022-09-01",
  },
  {
    quarter: "24",
    startMeasurements: "2022-12-20",
    endMesurements: "2023-01-29",
    quarterEndDate: "2023-02-28",
    quarterStartDate: "2022-12-01",
  },

  {
    quarter: "25",
    startMeasurements: "2023-03-22",
    endMesurements: "2023-05-01",
    quarterEndDate: "2023-05-31",
    quarterStartDate: "2023-03-01",
  },
  {
    quarter: "26",
    startMeasurements: "2023-06-22",
    endMesurements: "2023-08-01",
    quarterEndDate: "2023-08-31",
    quarterStartDate: "2023-06-01",
  },
  {
    quarter: "27",
    startMeasurements: "2023-09-21",
    endMesurements: "2023-10-31",
    quarterEndDate: "2023-11-30",
    quarterStartDate: "2023-09-01",
  },
  {
    quarter: "28",
    startMeasurements: "2023-12-20",
    endMesurements: "2024-01-29",
    quarterEndDate: "2024-02-28",
    quarterStartDate: "2023-12-01",
  },

  {
    quarter: "29",
    startMeasurements: "2024-03-22",
    endMesurements: "2024-05-01",
    quarterEndDate: "2024-05-31",
    quarterStartDate: "2024-03-01",
  },
  {
    quarter: "30",
    startMeasurements: "2024-06-22",
    endMesurements: "2024-08-01",
    quarterEndDate: "2024-08-31",
    quarterStartDate: "2024-06-01",
  },
  {
    quarter: "31",
    startMeasurements: "2024-09-21",
    endMesurements: "2024-10-31",
    quarterEndDate: "2024-11-30",
    quarterStartDate: "2024-09-01",
  },
  {
    quarter: "32",
    startMeasurements: "2024-12-20",
    endMesurements: "2025-01-29",
    quarterEndDate: "2025-02-28",
    quarterStartDate: "2024-12-01",
  },

  {
    quarter: "33",
    startMeasurements: "2025-03-22",
    endMesurements: "2025-05-01",
    quarterEndDate: "2025-05-31",
    quarterStartDate: "2025-03-01",
  },
  {
    quarter: "34",
    startMeasurements: "2025-06-22",
    endMesurements: "2025-08-01",
    quarterEndDate: "2025-08-31",
    quarterStartDate: "2025-06-01",
  },
  {
    quarter: "35",
    startMeasurements: "2025-09-21",
    endMesurements: "2025-10-31",
    quarterEndDate: "2025-11-30",
    quarterStartDate: "2025-09-01",
  },
  {
    quarter: "36",
    startMeasurements: "2025-12-20",
    endMesurements: "2026-01-29",
    quarterEndDate: "2026-02-28",
    quarterStartDate: "2025-12-01",
  },

  {
    quarter: "37",
    startMeasurements: "2026-03-22",
    endMesurements: "2026-05-01",
    quarterEndDate: "2026-05-31",
    quarterStartDate: "2026-03-01",
  },
  {
    quarter: "38",
    startMeasurements: "2026-06-22",
    endMesurements: "2026-08-01",
    quarterEndDate: "2026-08-31",
    quarterStartDate: "2026-06-01",
  },
  {
    quarter: "39",
    startMeasurements: "2026-09-21",
    endMesurements: "2026-10-31",
    quarterEndDate: "2026-11-30",
    quarterStartDate: "2026-09-01",
  },
  {
    quarter: "40",
    startMeasurements: "2026-12-20",
    endMesurements: "2027-01-29",
    quarterEndDate: "2027-02-28",
    quarterStartDate: "2026-12-01",
  },

  {
    quarter: "41",
    startMeasurements: "2027-03-22",
    endMesurements: "2027-05-01",
    quarterEndDate: "2027-05-31",
    quarterStartDate: "2027-03-01",
  },
  {
    quarter: "42",
    startMeasurements: "2027-06-22",
    endMesurements: "2027-08-01",
    quarterEndDate: "2027-08-31",
    quarterStartDate: "2027-06-01",
  },
  {
    quarter: "43",
    startMeasurements: "2027-09-21",
    endMesurements: "2027-10-31",
    quarterEndDate: "2027-11-30",
    quarterStartDate: "2027-09-01",
  },
  {
    quarter: "44",
    startMeasurements: "2027-12-20",
    endMesurements: "2028-01-29",
    quarterEndDate: "2028-02-28",
    quarterStartDate: "2027-12-01",
  },

  {
    quarter: "45",
    startMeasurements: "2028-03-22",
    endMesurements: "2028-05-01",
    quarterEndDate: "2028-05-31",
    quarterStartDate: "2028-03-01",
  },
  {
    quarter: "46",
    startMeasurements: "2028-06-22",
    endMesurements: "2028-08-01",
    quarterEndDate: "2028-08-31",
    quarterStartDate: "2028-06-01",
  },
  {
    quarter: "47",
    startMeasurements: "2028-09-21",
    endMesurements: "2028-10-31",
    quarterEndDate: "2028-11-30",
    quarterStartDate: "2028-09-01",
  },
  {
    quarter: "48",
    startMeasurements: "2028-12-20",
    endMesurements: "2029-01-29",
    quarterEndDate: "2029-02-28",
    quarterStartDate: "2028-12-01",
  },

  {
    quarter: "49",
    startMeasurements: "2029-03-22",
    endMesurements: "2029-05-01",
    quarterEndDate: "2029-05-31",
    quarterStartDate: "2029-03-01",
  },
  {
    quarter: "50",
    startMeasurements: "2029-06-22",
    endMesurements: "2029-08-01",
    quarterEndDate: "2029-08-31",
    quarterStartDate: "2029-06-01",
  },
  {
    quarter: "51",
    startMeasurements: "2029-09-21",
    endMesurements: "2029-10-31",
    quarterEndDate: "2029-11-30",
    quarterStartDate: "2029-09-01",
  },
  {
    quarter: "52",
    startMeasurements: "2029-12-20",
    endMesurements: "2030-01-29",
    quarterEndDate: "2030-02-28",
    quarterStartDate: "2029-12-01",
  },

  {
    quarter: "53",
    startMeasurements: "2030-03-22",
    endMesurements: "2030-05-01",
    quarterEndDate: "2030-05-31",
    quarterStartDate: "2030-03-01",
  },
  {
    quarter: "54",
    startMeasurements: "2030-06-22",
    endMesurements: "2030-08-01",
    quarterEndDate: "2030-08-31",
    quarterStartDate: "2030-06-01",
  },
  {
    quarter: "55",
    startMeasurements: "2030-09-21",
    endMesurements: "2030-10-31",
    quarterEndDate: "2030-11-30",
    quarterStartDate: "2030-09-01",
  },
  {
    quarter: "56",
    startMeasurements: "2030-12-20",
    endMesurements: "2031-01-29",
    quarterEndDate: "2031-02-28",
    quarterStartDate: "2030-12-01",
  },

  {
    quarter: "57",
    startMeasurements: "2031-03-22",
    endMesurements: "2031-05-01",
    quarterEndDate: "2031-05-31",
    quarterStartDate: "2031-03-01",
  },
  {
    quarter: "58",
    startMeasurements: "2031-06-22",
    endMesurements: "2031-08-01",
    quarterEndDate: "2031-08-31",
    quarterStartDate: "2031-06-01",
  },
  {
    quarter: "59",
    startMeasurements: "2031-09-21",
    endMesurements: "2031-10-31",
    quarterEndDate: "2031-11-30",
    quarterStartDate: "2031-09-01",
  },
  {
    quarter: "60",
    startMeasurements: "2031-12-20",
    endMesurements: "2032-01-29",
    quarterEndDate: "2032-02-28",
    quarterStartDate: "2031-12-01",
  },

  {
    quarter: "61",
    startMeasurements: "2032-03-22",
    endMesurements: "2032-05-01",
    quarterEndDate: "2032-05-31",
    quarterStartDate: "2032-03-01",
  },
  {
    quarter: "62",
    startMeasurements: "2032-06-22",
    endMesurements: "2032-08-01",
    quarterEndDate: "2032-08-31",
    quarterStartDate: "2032-06-01",
  },
  {
    quarter: "63",
    startMeasurements: "2032-09-21",
    endMesurements: "2032-10-31",
    quarterEndDate: "2032-11-30",
    quarterStartDate: "2032-09-01",
  },
  {
    quarter: "64",
    startMeasurements: "2032-12-20",
    endMesurements: "2033-01-29",
    quarterEndDate: "2033-02-28",
    quarterStartDate: "2032-12-01",
  },

  {
    quarter: "65",
    startMeasurements: "2033-03-22",
    endMesurements: "2033-05-01",
    quarterEndDate: "2033-05-31",
    quarterStartDate: "2033-03-01",
  },
  {
    quarter: "66",
    startMeasurements: "2033-06-22",
    endMesurements: "2033-08-01",
    quarterEndDate: "2033-08-31",
    quarterStartDate: "2033-06-01",
  },
  {
    quarter: "67",
    startMeasurements: "2033-09-21",
    endMesurements: "2033-10-31",
    quarterEndDate: "2033-11-30",
    quarterStartDate: "2033-09-01",
  },
  {
    quarter: "68",
    startMeasurements: "2033-12-20",
    endMesurements: "2034-01-29",
    quarterEndDate: "2034-02-28",
    quarterStartDate: "2033-12-01",
  },

  {
    quarter: "69",
    startMeasurements: "2034-03-22",
    endMesurements: "2034-05-01",
    quarterEndDate: "2034-05-31",
    quarterStartDate: "2034-03-01",
  },
  {
    quarter: "70",
    startMeasurements: "2034-06-22",
    endMesurements: "2034-08-01",
    quarterEndDate: "2034-08-31",
    quarterStartDate: "2034-06-01",
  },
  {
    quarter: "71",
    startMeasurements: "2034-09-21",
    endMesurements: "2034-10-31",
    quarterEndDate: "2034-11-30",
    quarterStartDate: "2034-09-01",
  },
  {
    quarter: "72",
    startMeasurements: "2034-12-20",
    endMesurements: "2035-01-29",
    quarterEndDate: "2035-02-28",
    quarterStartDate: "2034-12-01",
  },
];
