import { IArticlesRESTPageWrapper, IError, IResource } from "online-services-types";
import { AnyAction, Dispatch } from "redux";
import { APIFetchStatus, IAPICallParams, IAPIResource } from "src/APIFetch";
import { displayError } from "src/util/error";
import { action } from "typesafe-actions";
import { APIAbortHandle } from "./APIAbortHandle";
import { APIResource, APIResourceErrorDisplay } from "./APIResource";

interface IRestPayload<T> {
  data: T | T[];
  params?: IAPICallParams;
  totalCount: number;
  searchSuggestions: string[];
  pagination?: string;
  append: boolean;
}

interface IRESTAction<T> extends AnyAction {
  type: string;
  id?: string;
  payload?: IRestPayload<T>;
  errorMessage?: string;
}

export class SearchResultsAPIResource<T extends IResource> extends APIResource<T> {
  public totalCount: number;
  public searchSuggestions: string[];
  public pagination?: string;
  public readonly doneWithParamsAction: (
    params: IAPICallParams,
    result: IArticlesRESTPageWrapper<T>,
    append: boolean
  ) => IRESTAction<T>;

  constructor(
    resourceName: string,
    childResourceName?: string,
    enableCache = false,
    displayErrors: APIResourceErrorDisplay = APIResourceErrorDisplay.Display
  ) {
    super(resourceName, childResourceName, enableCache, displayErrors);
    this.doneWithParamsAction = (params: IAPICallParams, result: IArticlesRESTPageWrapper<T>, append: boolean): IRESTAction<T> =>
      action(`API_RESOURCE_${this.resourceCombo}_SUCCESS_WITH_PARAMS`, {
        append,
        data: result.results,
        totalCount: result.totalNumberOfResults,
        searchSuggestions: result.searchSuggestions,
        pagination: result.pagination,
        params,
      });
  }

  public getWithParams = (params: IAPICallParams, abortHandle?: APIAbortHandle, append = false) => {
    return async (dispatch: Dispatch) => {
      dispatch(this.getAction());
      try {
        const result = await this.fetcher.getWithParams(params);
        if (abortHandle && abortHandle.isAborted()) {
          return;
        }
        dispatch(this.doneWithParamsAction(params, result as {} as IArticlesRESTPageWrapper<T>, append));
      } catch (error) {
        displayError(error);
        dispatch(this.errorAction((error as IError).message));
      }
    };
  };

  public handleSuccessWithParams(state: SearchResultsAPIResource<T>, action: IRESTAction<T>): APIResource<T> {
    state.status = APIFetchStatus.Success;
    if (action.payload) {
      if (action.payload.append) {
        // Append only ID's that do not exist in the list already
        state.data = state.data.concat(
          (action.payload.data as T[]).filter(
            (item) => state.data.find((stateItem) => stateItem.id === item.id) === undefined
          )
        );
      } else {
        state.data = action.payload.data as T[];
      }
      state.totalCount = action.payload.totalCount;
      state.searchSuggestions = action.payload.searchSuggestions;
      state.pagination = action.payload.pagination;
    }
    state.errorMessage = undefined;
    return state;
  }

  public getDefaultState(previousState: SearchResultsAPIResource<T>) {
    return Object.assign({}, previousState) || { status: APIFetchStatus.Idle, data: undefined };
  }

  public createReducer(
    stateKey: string
  ): (state: SearchResultsAPIResource<T>, action: IRESTAction<T>) => IAPIResource<T> {
    this.stateKey = stateKey;
    const superReducer = super.createReducer(stateKey);
    return (previousState: SearchResultsAPIResource<T>, action: IRESTAction<T>): IAPIResource<T> => {
      const state = this.getDefaultState(previousState);

      if (action.type === `API_RESOURCE_${this.resourceCombo}_SUCCESS_WITH_PARAMS`) {
        return this.handleSuccessWithParams(state, action);
      }

      return superReducer(state, action);
    };
  }
}
