// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_ja: ITranslations = {
  ja: {
    "user.addNewUser": "ユーザーの追加",
    "user.allUsersInAccount": "アカウントのすべてのユーザー",
    "user.department": "部署",
    "user.digest.addAllInstallations": "すべての設備を追加する",
    "user.digest.addAllInstallations:energy": "すべての発電プラントを追加する",
    "user.digest.addAllInstallations:marine": "すべての船舶を追加する",
    "user.digest.period.daily": "日次",
    "user.digest.period.monthly": "月次",
    "user.digest.period.never": "なし",
    "user.digest.period.weekly": "週次",
    "user.digest.service.reports": "レポート",
    "user.digest.service.requests": "リクエスト",
    "user.digest.service.spareParts": "予備部品",
    "user.digest.service.technicalDocuments": "技術文書",
    "user.digest.validation.noInstallationsSelected": "設備が必要です。",
    "user.digest.validation.noInstallationsSelected:energy": "発電プラントが必要です。",
    "user.digest.validation.noInstallationsSelected:marine": "船舶が必要です。",
    "user.digest.validation.noServicesSelected": "サービスが必要です。",
    "user.digestDescription.daily": "設備1件に対して毎日",
    "user.digestDescription.daily.plural": "設備{numberOfInstallations}件に対して毎日",
    "user.digestDescription.daily.plural:energy": "発電プラント{numberOfInstallations}件に対して毎日",
    "user.digestDescription.daily.plural:marine": "船舶{numberOfInstallations}件に対して毎日",
    "user.digestDescription.daily:energy": "発電プラント1件に対して毎日",
    "user.digestDescription.daily:marine": "船舶1件に対して毎日",
    "user.digestDescription.monthly": "設備1件に対して毎月",
    "user.digestDescription.monthly.plural": "設備{numberOfInstallations}件に対して毎月",
    "user.digestDescription.monthly.plural:energy": "発電プラント{numberOfInstallations}件に対して毎月",
    "user.digestDescription.monthly.plural:marine": "船舶{numberOfInstallations}件に対して毎月",
    "user.digestDescription.monthly:energy": "発電プラント1件に対して毎月",
    "user.digestDescription.monthly:marine": "船舶1件に対して毎月",
    "user.digestDescription.never": "無効になりました",
    "user.digestDescription.weekly": "設備1件に対して毎週日曜日",
    "user.digestDescription.weekly.plural": "設備{numberOfInstallations} 件に対して毎週日曜日",
    "user.digestDescription.weekly.plural:energy": "発電プラント{numberOfInstallations}件に対して毎週日曜日",
    "user.digestDescription.weekly.plural:marine": "船舶{numberOfInstallations} 件に対して毎週日曜日",
    "user.digestDescription.weekly:energy": "発電プラント1件に対して毎週日曜日",
    "user.digestDescription.weekly:marine": "船舶1件に対して毎週日曜日",
    "user.digestEmail": "電子メールを整理する",
    "user.digestEmailSettings": "電子メール設定を整理する",
    "user.digestSettingsForm.frequencyOfDigest": "整理の頻度",
    "user.digestSettingsForm.servicesOfInterest": "興味のあるサービス",
    "user.email": "電子メールアドレス",
    "user.emailIsRequired": "電子メールが必要です",
    "user.emailShort": "電子メール",
    "user.firstname": "名",
    "user.firstnameIsRequired": "名が必要です",
    "user.lastname": "姓",
    "user.lastnameIsRequired": "姓が必要です",
    "user.name": "名称",
    "user.notificationSettings": "通知設定",
    "user.phone": "電話",
    "user.phoneNumber": "電話番号",
    "user.reasonForRemoval": "削除の理由",
    "user.reasonForRemovalIsRequired": "削除の理由が必要です",
    "user.removeUser": "ユーザーの削除",
    "user.title": "タイトル",
    "user.userInformation": "ユーザー情報",
    "user.userPassword": "パスワード",
  },
};
