import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const RemoveIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Remove</title>
    <path d="M22,4V22H4V4H22m4-4H0V26H26V0h0Z" />
    <polygon points="19.36 16.54 15.83 13 19.36 9.46 16.54 6.64 13 10.17 9.46 6.64 6.64 9.46 10.17 13 6.64 16.54 9.46 19.36 13 15.83 16.54 19.36 19.36 16.54" />
  </IconBase>
);
