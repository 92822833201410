import {
  ICombinedRequest,
  ICustomerSupportTicket,
  IEquipment,
  IInstallation,
  IOperationalSupportRequest,
  ISparePartClaim,
  ITechRequest,
  IWarrantyClaim,
} from "online-services-types";
import { APIFetchStatus, IAPIResource } from "src/APIFetch";

export enum RequestType {
  CustomerSupportTicket = "CustomerSupportTicket",
  TechRequest = "TechRequest",
  WarrantyClaim = "WarrantyClaim",
  SparePartClaim = "SparePartClaim",
  OperationalSupportRequest = "OperationalSupportTicket",
  // OperationalSupportRequest value has a suffix "Ticket" due to the email link containig that string
  // as a requestType. Otherwise they are generally referred as "Request"s.
}

export interface IWarrantyClaimViewModel extends IWarrantyClaim {
  equipment: IEquipment | undefined;
  installation: IInstallation | undefined;
}

export interface ITechRequestViewModel extends ITechRequest {
  equipment: IEquipment | undefined;
  installation: IInstallation | undefined;
}

export interface ICustomerSupportTicketViewModel extends ICustomerSupportTicket {
  equipment: IEquipment | undefined;
  installation: IInstallation | undefined;
}

export interface ISparePartClaimViewModel extends ISparePartClaim {
  equipment: IEquipment | undefined;
  installation: IInstallation | undefined;
}

export interface IOperationalSupportViewModel extends IOperationalSupportRequest {
  equipment: IEquipment | undefined;
  installation: IInstallation | undefined;
}

interface ICombinedRequestViewModelExtension {
  equipment: IEquipment | undefined;
  installation: IInstallation | undefined;
}

export type ICombinedRequestViewModel = ICombinedRequestViewModelExtension & ICombinedRequest & IWarrantyClaim;

export function getWarrantyClaimViewModel(
  claim: IWarrantyClaim,
  installations: IInstallation[],
  equipments: IEquipment[]
): IWarrantyClaimViewModel {
  return Object.assign(claim, {
    equipment: equipments.find((item) => item.id === claim.equipmentId),
    installation: installations.find((item) => item.id === claim.installationId),
  });
}

export function getTechRequestViewModel(
  claim: ITechRequest,
  installations: IInstallation[],
  equipments: IEquipment[]
): ITechRequestViewModel {
  return Object.assign(claim, {
    equipment: equipments.find((item) => item.id === claim.equipmentId),
    installation: installations.find((item) => item.id === claim.installationId),
  });
}

export function getCustomerSupportTicketViewModel(
  claim: ICustomerSupportTicket,
  installations: IInstallation[],
  equipments: IEquipment[]
): ICustomerSupportTicketViewModel {
  return Object.assign(claim, {
    equipment: equipments.find((item) => item.id === claim.equipmentId),
    installation: installations.find((item) => item.id === claim.installationId),
  });
}

export function getSparePartClaimViewModel(
  claim: ISparePartClaim,
  installations: IInstallation[],
  equipments: IEquipment[]
): ISparePartClaimViewModel {
  return Object.assign(claim, {
    equipment: equipments.find((item) => item.id === claim.equipmentId),
    installation: installations.find((item) => item.id === claim.installationId),
  });
}

export function getOperationalSupportViewModel(
  claim: IOperationalSupportRequest,
  installations: IInstallation[],
  equipments: IEquipment[]
): IOperationalSupportViewModel {
  return {
    ...claim,
    equipment: equipments.find((item) => item.id === claim.equipmentId),
    installation: installations.find((item) => item.id === claim.installationId),
  };
}

/**
 * This could be generic
 */
function getViewModelResource<T, T2>(
  requestType: RequestType,
  claims: IAPIResource<ICombinedRequest>,
  installations: IAPIResource<IInstallation>,
  equipments: IAPIResource<IEquipment>,
  augmentViewModel: (model: T, installations: IInstallation[], equipments: IEquipment[]) => T2
): IAPIResource<T2> {
  if (claims.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: claims.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  if (installations.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: installations.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  if (equipments.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: equipments.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  const status = [claims, equipments, installations].reduce((finalStatus, resource) => {
    if (resource.status !== APIFetchStatus.Success) {
      return resource.status;
    }

    return finalStatus;
  }, APIFetchStatus.Success);

  if (status !== APIFetchStatus.Success) {
    return {
      data: [],
      status,
    };
  }

  return {
    data: claims.data
      .filter((combinedRequest) => combinedRequest.requestType === requestType)
      .map((claim) => augmentViewModel(claim as {} as T, installations.data, equipments.data)),
    status: APIFetchStatus.Success,
  };
}

export function getWarrantyClaimViewModelResource(
  claims: IAPIResource<ICombinedRequest>,
  installations: IAPIResource<IInstallation>,
  equipments: IAPIResource<IEquipment>
): IAPIResource<IWarrantyClaimViewModel> {
  return getViewModelResource<IWarrantyClaim, IWarrantyClaimViewModel>(
    RequestType.WarrantyClaim,
    claims,
    installations,
    equipments,
    getWarrantyClaimViewModel
  );
}

export function getTechRequestViewModelResource(
  claims: IAPIResource<ICombinedRequest>,
  installations: IAPIResource<IInstallation>,
  equipments: IAPIResource<IEquipment>
): IAPIResource<ITechRequestViewModel> {
  return getViewModelResource<ITechRequest, ITechRequestViewModel>(
    RequestType.TechRequest,
    claims,
    installations,
    equipments,
    getTechRequestViewModel
  );
}

export function getCombinedRequestViewModelResource(
  claims: IAPIResource<ICombinedRequest>,
  installations: IAPIResource<IInstallation>,
  equipments: IAPIResource<IEquipment>
): IAPIResource<ICombinedRequestViewModel> {
  if (claims.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: claims.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  if (installations.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: installations.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  if (equipments.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: equipments.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  const status = [claims, equipments, installations].reduce((finalStatus, resource) => {
    if (resource.status !== APIFetchStatus.Success) {
      return resource.status;
    }

    return finalStatus;
  }, APIFetchStatus.Success);

  if (status !== APIFetchStatus.Success) {
    return {
      data: [],
      status,
    };
  }

  const mappers = {
    [RequestType.WarrantyClaim]: getWarrantyClaimViewModel,
    [RequestType.TechRequest]: getTechRequestViewModel,
    [RequestType.CustomerSupportTicket]: getCustomerSupportTicketViewModel,
    [RequestType.SparePartClaim]: getSparePartClaimViewModel,
    [RequestType.OperationalSupportRequest]: getOperationalSupportViewModel,
  };

  return {
    data: (claims.data || []).map((claim) => {
      const mapper = mappers[claim.requestType];

      if (!mapper) {
        throw new Error(`Unknown combined request type "${claim.requestType} in request ${claim.id}"`);
      }

      return Object.assign(mapper(claim, installations.data, equipments.data), { requestType: claim.requestType });
    }),
    status: APIFetchStatus.Success,
  };
}
