import * as React from "react";
import { IBaseStep } from "src/redux/onboarding";
import { OnboardingStepWrapper } from "./styles";
import { H2, P } from "src/design-system/Tokens/typography";
import { LocalizedString } from "../Localization";
import { translateString } from "src/util/localization";

export const onBoardingIndexSteps: IBaseStep[] = [
  {
    className: "main-onboarding-start",
    isFixed: true,
    disableOverlayClose: true,
    nextText: translateString("letsStart"),
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="mainOnboarding.stepOne.title" />
        </H2>
        <P>
          <LocalizedString id="mainOnboarding.stepOne.content" />
        </P>
      </OnboardingStepWrapper>
    ),
    disableBeacon: true,
    placement: "left",
  },
  {
    className: "main-onboarding-your-profile",
    disableOverlayClose: true,

    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="mainOnboarding.stepTwo.title" />
        </H2>
        <P>
          <LocalizedString id="mainOnboarding.stepTwo.content" />
        </P>
        <P>
          <LocalizedString id="mainOnboarding.stepTwo.content2" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "main-onboarding-my-installation",
    disableOverlayClose: true,

    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="mainOnboarding.stepThree.title" />
        </H2>
        <P>
          <LocalizedString id="mainOnboarding.stepThree.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "main-onboarding-your-services",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="mainOnboarding.stepFour.title" />
        </H2>
        <P>
          <LocalizedString id="mainOnboarding.stepFour.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "main-onboarding-ready",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="mainOnboarding.stepFive.title" />
        </H2>
        <P>
          <LocalizedString id="mainOnboarding.stepFive.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
];
