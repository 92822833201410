// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const onboarding_es_MX: ITranslations = {
  es_MX: {
    "documentsOnboarding.stepFive.content": "Vea la información de documento y vea los detalles respecto a qué equipo se aplica el documento.",
    "documentsOnboarding.stepFive.title": "Información de documento",
    "documentsOnboarding.stepFour.content": "Acceso fácil a vista previa dando clic en las páginas del documento.",
    "documentsOnboarding.stepFour.content2": "Las palabras de búsqueda usadas se resaltarán en la vista previa.",
    "documentsOnboarding.stepFour.title": "Vista previa",
    "documentsOnboarding.stepOne.content": "Vea, busque, filtre y clasifique todos sus documentos fácilmente en una página.",
    "documentsOnboarding.stepOne.title": "Sus documentos",
    "documentsOnboarding.stepThree.content": "Clasifique su vista para tener los documentos más importantes en la parte superior.",
    "documentsOnboarding.stepThree.title": "Clasificar",
    "documentsOnboarding.stepTwo.content": "Use diferentes valores de búsqueda para encontrar lo que necesita.",
    "documentsOnboarding.stepTwo.content2": "También puede filtrar su búsqueda con tipos de documento.",
    "documentsOnboarding.stepTwo.title": "Búsqueda y Filtro",
    "mainOnboarding.stepFive.content": "Gracias por tomar la visita guiada con nosotros. Si tiene cualquier pregunta o necesita ayuda, nuestro Soporte al cliente siempre está disponible para ayudarlo. Esperamos que disfrute el nuevo ambiente.",
    "mainOnboarding.stepFive.title": "¡Está listo para comenzar!",
    "mainOnboarding.stepFour.content": "Todos los servicios disponibles se indican a continuación.",
    "mainOnboarding.stepFour.title": "Sus servicios",
    "mainOnboarding.stepOne.content": "Lo vamos a guiar a través de las funciones básicas con una visita rápida. Esto tomará menos de un minuto.",
    "mainOnboarding.stepOne.title": "¡Bienvenido a su nueva experiencia en línea Wärtsilä!",
    "mainOnboarding.stepThree.content": "Todas las instalaciones que eligió ver en su perfil están fácilmente disponibles en su portada. Al dar clic en una instalación específica puede encontrar productos relacionados con ésta.",
    "mainOnboarding.stepThree.content:energy": "Todas las plantas de energía que eligió ver en su perfil están fácilmente disponibles en su portada. Al dar clic en una planta de energía específica puede encontrar productos relacionados con ésta.",
    "mainOnboarding.stepThree.content:marine": "Todas las embarcaciones que eligió ver en su perfil están fácilmente disponibles en su portada. Al dar clic en una embarcación específica puede encontrar productos relacionados con ésta.",
    "mainOnboarding.stepThree.title": "Sus instalaciones",
    "mainOnboarding.stepThree.title:energy": "Sus plantas de energía",
    "mainOnboarding.stepThree.title:marine": "Sus embarcaciones",
    "mainOnboarding.stepTwo.content": "Desde el perfil puede elegir qué instalaciones desea ver en su portada, actualizar su información básica, y resumir los ajustes de correo electrónico.",
    "mainOnboarding.stepTwo.content2": "Siempre puede repetir esta visita rápida desde su perfil.",
    "mainOnboarding.stepTwo.content:energy": "Desde el perfil puede elegir qué plantas de energía desea ver en su portada, actualizar su información básica, y resumir los ajustes de correo electrónico.",
    "mainOnboarding.stepTwo.content:marine": "Desde el perfil puede elegir qué embarcaciones desea ver en su portada, actualizar su información básica, y resumir los ajustes de correo electrónico.",
    "mainOnboarding.stepTwo.title": "Su perfil",
    "sparePartsOnboarding.stepAddPartsToCart.content": "Al dar clic en los botones + y – o escribiendo la cantidad puede agregar o eliminar artículos al carrito y ver el subtotal.",
    "sparePartsOnboarding.stepAddPartsToCart.title": "Agregue partes al carrito de compras",
    "sparePartsOnboarding.stepBrowseParts.content": "Seleccione un producto y navegue las partes de repuesto.",
    "sparePartsOnboarding.stepBrowseParts.content2": "Puede filtrar las partes de repuesto con un nombre o número de parte.",
    "sparePartsOnboarding.stepBrowseParts.content3": "También puede agregar partes de repuesto manualmente con varias partidas de línea al mismo tiempo.",
    "sparePartsOnboarding.stepBrowseParts.title": "Explorar partes de repuesto",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content": "Realice su pedido o cree una cotización. Seleccione una instalación y comience a navegar en sus partes de repuesto.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:energy": "Realice su pedido o cree una cotización. Seleccione una planta de energía y comience a navegar en sus partes de repuesto.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:marine": "Realice su pedido o cree una cotización. Seleccione una embarcación y comience a navegar en sus partes de repuesto.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.title": "Nuevo pedido o cotización",
    "sparePartsOnboarding.stepOrdersAndQuotations.content": "Vea los estados y detalles de sus pedidos y cotizaciones.",
    "sparePartsOnboarding.stepOrdersAndQuotations.content2": "Desde esta vista, puede realizar fácilmente un pedido a partir de una cotización.",
    "sparePartsOnboarding.stepOrdersAndQuotations.title": "Pedidos y Cotizaciones",
    "sparePartsOnboarding.stepPartDetails.content": "Expanda la vista para ver los detalles de la parte de repuesto.",
    "sparePartsOnboarding.stepPartDetails.content2": "Desde la imagen, puede ver el dibujo expandido de la parte de repuesto que muestra más detalles.",
    "sparePartsOnboarding.stepPartDetails.content3": "También encontrará el precio, peso, y tiempo de entrega para la parte.",
    "sparePartsOnboarding.stepPartDetails.title": "Detalles de partes de repuesto",
    "sparePartsOnboarding.stepShoppingCart.content": "Puede ver las partes de repuesto seleccionadas y el precio total en su carrito de compras.",
    "sparePartsOnboarding.stepShoppingCart.title": "Carrito de compras",
    "sparePartsOnboarding.stepYourSpareParts.content": "Revise todos sus pedidos y cotizaciones fácilmente en una página. Puede filtrar su vista y ver el historial completo.",
    "sparePartsOnboarding.stepYourSpareParts.content2": "Navegue las partes de repuesto, cree nuevos pedidos o cotizaciones, o cree un reclamo de parte de repuesto.",
    "sparePartsOnboarding.stepYourSpareParts.title": "Página de Comercio",
    "supportOnboarding.stepFive.content": "Cualquier persona de su organización puede agregar comentarios relacionados con la solicitud y estar en contacto con Wärtsilä.",
    "supportOnboarding.stepFive.title": "Comentarios",
    "supportOnboarding.stepFour.content": "Los anexos se pueden agregar todo el tiempo que la solicitud esté en curso.",
    "supportOnboarding.stepFour.title": "Archivos adjuntos",
    "supportOnboarding.stepOne.content": "Revise todas sus solicitudes fácilmente en una página. Puede filtrar o clasificar su vista y ver los estados de solicitud y el historial completo.",
    "supportOnboarding.stepOne.title": "Su página de Soporte",
    "supportOnboarding.stepSeven.content": "Para obtener todas sus solicitudes en un documento, puede exportarlas fácilmente.",
    "supportOnboarding.stepSeven.title": "Exportar solicitudes",
    "supportOnboarding.stepSix.content": "Cuando se complete una solicitud, puede calificarla y proporcionar retroalimentación, o volver a abrir la solicitud.",
    "supportOnboarding.stepSix.title": "Calificar el servicio",
    "supportOnboarding.stepThree.content": "Expanda para ver los detalles de solicitud y proporcionar información adicional",
    "supportOnboarding.stepThree.title": "Sus solicitudes",
    "supportOnboarding.stepTwo.content": "Crear todas sus solicitudes en un lugar:",
    "supportOnboarding.stepTwo.title": "Crear una nueva solicitud",
  },
};
