import { BarRow, ChartTable } from "./GroupedDatesChart";

import { IInstallation } from "online-services-types";
import { ISparePartOrder } from "../request/interfaces";
import { Legend } from "./Legend";
import { ManagerDashboardTableWrapper } from "./shared";
import React from "react";
import { TimelineRow } from "./TimelineRow";
import { groupPurchasesByInstallation } from "../dashboard";

export const SparePartOrderChart = (props: {
  start: string;
  end: string;
  sparePartOrderCurrency: string;
  installations: IInstallation[];
  sparePartOrders: ISparePartOrder[];
}) => {
  const scale =
    groupPurchasesByInstallation(props.sparePartOrders).reduce(
      (prev, curr) =>
        Math.max(
          prev,
          curr.items.reduce((prevItem, currItem) => Math.max(prevItem, currItem.total), 0)
        ),
      0
    ) > 10000
      ? 1000
      : 1;

  return (
    <ManagerDashboardTableWrapper>
      <Legend categories={props.installations.map((installation) => installation.name)} />
      <ChartTable>
        <tbody>
          <BarRow
            timeframeEnd={props.end}
            timeframeStart={props.start}
            unitLabel={props.sparePartOrderCurrency}
            categories={props.installations.map((installation) => installation.id)}
            data={groupPurchasesByInstallation(props.sparePartOrders).map((date) => ({
              items: date.items.map((item) => ({ category: item.installationId, count: item.total / scale })),
              date: date.date,
            }))}
            postfix={scale >= 1000 ? "K" : undefined}
          />
          <TimelineRow start={new Date(props.start)} end={new Date(props.end)} />
        </tbody>
      </ChartTable>
    </ManagerDashboardTableWrapper>
  );
};
