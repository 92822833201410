import * as React from "react";

import { CloseIcon, IconSize, SadFace, ThumbUp } from "src/icons";
import { H4, P } from "src/design-system/Tokens/typography";
import { getPathWithParams, getRoutes } from "src/routes";
import { spacer, spacers } from "../../design-system/Tokens/tokens";
import styled, { ThemeProvider } from "styled-components";

import { FlexContainer } from "src/design-system/Container";
import { IIconBase } from "src/icons/IconBase";
import { Link } from "src/design-system/Link";
import { LocalizedString } from "../Localization";
import colors from "src/design-system/Tokens/colors";
import { themes } from "src/design-system/Theme/theme";
import { translateString } from "src/util/localization";

const ToastContainer = styled(FlexContainer)`
  align-items: flex-start;
`;

const ToastIcon = styled.div`
  margin: ${spacers([1, 2, 0, 0])};
`;

const ToastContent = styled.div`
  flex: 1;
`;

const ToastClose = styled.div`
  margin: ${spacer(2)};
`;

interface IToastMessageProps {
  type: "error" | "success" | "progress" | "custom";
  title?: string;
  link?: string;
  requestId?: string;
  message?: string;
  linkPrompt?: string;
  content?: React.ReactNode;
  customIcon?: React.ComponentType<IIconBase>;
  onClose(): void;
}

const OffscreenCanvas = styled.canvas`
  display: none;
`;

const ErrorImage = styled.img`
  max-width: 100%;
`;

// Obfuscate the GUID so HotJar doesn't think it's a credit card number
export class RequestId extends React.Component<{ text: string }> {
  private readonly ref: React.RefObject<any>;
  private image: string;

  constructor(props: { text: string }) {
    super(props);
    this.ref = React.createRef();
  }
  public componentDidMount() {
    const canvas = this.ref.current;

    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.fillStyle = colors.primary.gray;
      ctx.fillText(this.props.text, 0, 10);
      this.image = canvas.toDataURL();
    }
  }

  public render() {
    return (
      <div>
        <OffscreenCanvas height={18} width={300} ref={this.ref} />
        <ErrorImage alt={this.props.text} src={this.image} />
      </div>
    );
  }
}

export const ErrorToast: React.FC<IToastMessageProps> = (props) => {
  // Sometimes it can be an object {message, stack}.
  const isMessageString = typeof props.message === "string";

  return (
    <ToastContainer>
      <ThemeProvider theme={themes.light}>
        <>
          {props.type !== "progress" && (
            <ToastIcon>
              {props.type === "success" && <ThumbUp size={IconSize.Large} color={colors.notification.ok} />}
              {props.type === "error" && <SadFace size={IconSize.Large} color={colors.notification.error} />}
            </ToastIcon>
          )}
          {props.customIcon && (
            <ToastIcon>
              <props.customIcon size={IconSize.Large} color={colors.primary.orange} />
            </ToastIcon>
          )}
          <ToastContent>
            {props.title && <H4>{props.title}</H4>}
            {props.message && isMessageString && <P>{props.message}</P>}
            {props.link && (
              <P>
                <Link to={props.link}>{props.linkPrompt}</Link>
              </P>
            )}
            {props.type === "error" && (
              <>
                <RequestId text={props.requestId || new Date().toString()} />
                <P>
                  <LocalizedString
                    id="error.pleaseContact"
                    values={{
                      contactLink: (
                        <Link
                          to={{
                            pathname: getPathWithParams(getRoutes().Contact),
                            state: {
                              errorStack: props.message,
                              errorUUID: props.requestId,
                            },
                          }}
                        >
                          {translateString("error.contactLinkText")}
                        </Link>
                      ),
                    }}
                  />
                </P>
              </>
            )}
            {props.content}
          </ToastContent>
          <ToastClose onClick={props.onClose}>
            <CloseIcon size={IconSize.XSmall} color={colors.primary.black} />
          </ToastClose>
        </>
      </ThemeProvider>
    </ToastContainer>
  );
};
