import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const PerformanceIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Performance</title>
    <path d="M41,32.54,40,6H37.92L37,32.54a4,4,0,0,0,.17,7L38,42h2l.82-2.46a4,4,0,0,0,.17-7Z" />
    <path d="M35.94,10.21l.17-4.05A33.54,33.54,0,0,0,6,38H6v2h6V38H10a29.29,29.29,0,0,1,2.22-9.81l2.13.88.77-1.85-2.06-.85a29.62,29.62,0,0,1,4.87-7l1.45,1.45,1.41-1.41-1.45-1.45a29.62,29.62,0,0,1,7-4.87l.85,2.06L29,14.37l-.88-2.13A29.3,29.3,0,0,1,35.94,10.21Z" />
    <path d="M35.67,16.22A24,24,0,0,0,15,40h4A20,20,0,0,1,35.5,20.31Z" />
  </IconBase>
);
