// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fieldServices_pt_BR: ITranslations = {
  pt_BR: {
    "fieldServices.actualFinishDate": "Data real de término",
    "fieldServices.actualStartDate": "Data real de início",
    "fieldServices.description": "Descrição do trabalho",
    "fieldServices.estimatedFinishDate": "Data estimada de término",
    "fieldServices.estimatedStartDate": "Data prevista de início",
    "fieldServices.poNumber": "Número do pedido",
    "fieldServices.productReferenceType": "Tipo de referência do produto",
    "fieldServices.recommendations": "Recomendações",
    "fieldServices.serviceWorkCoordinator": "Coordenador de serviço",
  },
};
