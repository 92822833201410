import { IInfoJWTData, IServiceLimitations, Service } from "online-services-types";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";

const SET_APPLICATION_CONTEXT = "SET_APPLICATION_CONTEXT";
const SET_SERVICES = "SET_SERVICES";
const SET_PARTS_BLOCKING_DATA = "SET_PARTS_BLOCKING_DATA";

export const setApplicationContext = (context: IInfoJWTData) => action(SET_APPLICATION_CONTEXT, context);
export const setServices = (services: Service[]) => action(SET_SERVICES, services);
export const setPartsBlockingStatus = (blockedStatus: boolean) => action(SET_PARTS_BLOCKING_DATA, blockedStatus);

export interface IApplicationContextState {
  readonly services: ReadonlyArray<Service>;
  readonly limitations: IServiceLimitations;
  readonly restHost?: string;
  readonly readonly?: boolean;
  readonly punchOut?: boolean;
  readonly punchOutSessionId?: string;
  readonly partsBlockingStatus: boolean;
}

// .readonly will be undefined until after JWT has been decoded after it will be true/false
// this is to delay hotjar scripts injection (should not be injected when read-only)

const initialState: IApplicationContextState = {
  services: [],
  limitations: {
    partsOnlineWithoutOrderingCapability: true,
    transferOrder: false,
    quantipartsTransferOrder: false,
    warrantyCasesReadOnly: false,
    partsClaimsReadOnly: false,
    externalTechRequestsReadOnly: false,
    documentCollaborationReadOnly: false,
  },
  restHost: undefined,
  readonly: undefined,
  partsBlockingStatus: false,
};

const servicesHiddenInProduction: ReadonlyArray<string> = [];

const productionDomain = "online.wartsila.com";

export function isRunningInProduction() {
  return window.location.hostname === productionDomain;
}

export function applicationContextReducer(state = initialState, action: AnyAction): IApplicationContextState {
  switch (action.type) {
    case SET_APPLICATION_CONTEXT:
      const services: Service[] = action.payload.services;
      // Filter out the services that should not be visible in production if we are running from online.wartsila.com
      return {
        ...state,
        services: services.filter(
          (service) => !isRunningInProduction() || !servicesHiddenInProduction.includes(service)
        ),
        limitations: action.payload.limitations,
        restHost: action.payload.restHost,
        readonly: action.payload.readonly,
        punchOut: action.payload.punchOut,
        punchOutSessionId: action.payload.punchOutSessionId,
      };
    case SET_SERVICES:
      return Object.assign({}, state, {
        services: action.payload,
      });
    case SET_PARTS_BLOCKING_DATA:
      return Object.assign({}, state, {
        partsBlockingStatus: action.payload,
      });
  }
  return state;
}
