// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_es_MX: ITranslations = {
  es_MX: {
    "request.addContact": "Agregar contacto",
    "request.addNewContact": "Agregar nuevo contacto",
    "request.addNewUser": "Agregar usuario",
    "request.addressLine1": "Dirección Línea 1",
    "request.addressLine2": "Dirección Línea 2",
    "request.attachment.Box": "Caja",
    "request.attachment.Box or packing material": "Caja o material de empaque",
    "request.attachment.Claimed part": "Parte reclamada",
    "request.attachment.Content of the box(es) and damaged parts": "Contenido de las cajas y partes dañadas",
    "request.attachment.Corroded part": "Parte corroída",
    "request.attachment.Damage on part": "Daño en la parte",
    "request.attachment.Entire part": "Parte completa",
    "request.attachment.Markings and / or labels on the part": "Marcas y / o etiquetas en la parte",
    "request.attachment.Markings on the claimed part": "Marcas en la parte reclamada",
    "request.attachment.Markings on the part": "Marcas en la parte",
    "request.attachment.Markings on the received part": "Marcas en la parte recibida",
    "request.attachment.Markings on the required / correct part": "Marcas en la parte requerida / correcta",
    "request.attachment.Other": "Otros",
    "request.attachment.Packing material": "Material de empaque",
    "request.attachment.Packing, incl. labels": "Empaque, incluyendo etiquetas",
    "request.attachment.Pictures of leaking parts": "Imágenes de partes con fuga",
    "request.attachment.Received part": "Parte recibida",
    "request.attachment.Required / correct part": "Parte requerida / correcta",
    "request.attachment.Required part": "Parte requerida",
    "request.attachment.Test reports": "Reportes de prueba",
    "request.attachmentDescription": "Por favor, proporcione imágenes de panorámica general, e imágenes de acercamiento.",
    "request.attachmentDialog.Confirm": "Eliminar",
    "request.attachmentDialog.Text": "¿Está seguro que desea retirar el anexo?",
    "request.attachmentDialog.Title": "Retirar anexo",
    "request.attachments": "Archivos adjuntos",
    "request.auxAttachmentDescription": "Por favor, proporcione imágenes de panorámica general, imágenes de acercamiento, e imágenes de la placa de registro del producto.",
    "request.backgroundInformation": "Información de antecedentes",
    "request.beforeClaiming": "Antes de reclamar las partes faltantes, asegúrese que se hayan realizado las siguientes verificaciones:",
    "request.beforeClaiming.checkBottom": "3. El cajones de madera a veces hay una lámina de madera en la parte inferior que puede parecer el piso/fondo y las partes se pueden haber colocado debajo de esta lámina.",
    "request.beforeClaiming.checkPackage": "1. Revise de nuevo la caja de envío/caja/cajón de madera en el que llegó la entrega.",
    "request.beforeClaiming.checkPaddings": "2. Revise el relleno y el material de empaque.",
    "request.chooseReferenceRequest": "Elegir solicitud de referencia",
    "request.city": "Ciudad",
    "request.claimAllItems": "Reclamar todas las líneas de entrega por la misma razón / problema",
    "request.claimBasketText": "{basketItems} artículos de línea",
    "request.claimDescription": "Descripción breve de reclamo",
    "request.claimItem": "Artículo de reclamo",
    "request.claimOrder": "Ordenar reclamo",
    "request.claimQuantity": "Cantidad reclamada",
    "request.claimReason": "Razón de reclamo",
    "request.claimReasonChangeDislaimer": "Observe que cuando cambie la razón de reclamo, se perderán sus respuestas específicas de artículo.",
    "request.claimReasons": "Razones de reclamo",
    "request.claimReference": "Reclamo de referencia",
    "request.claimedItems": "Artículos reclamados",
    "request.claimedOrder": "Pedidos a reclamar",
    "request.comment": "Comentario",
    "request.companyName": "Nombre de empresa",
    "request.contactInformation": "Información del contacto",
    "request.contactInformationDescription": "Usted es la persona de contacto principal para esta solicitud, pero puede agregar personas interesadas adicionales que serán notificadas sobre los cambios relevantes a esta solicitud.",
    "request.couldntFindDelivery": "¡No se pudo encontrar la entrega!",
    "request.country": "País",
    "request.createdBy": "Creado por",
    "request.creationDate": "Fecha de creación",
    "request.customerClaimReference": "Referencia de reclamo del cliente",
    "request.customerClaimReferenceOptional": "Referencia de reclamo del cliente (opcional)",
    "request.customerInformation": "Información al cliente",
    "request.daAddressLine1IsRequired": "¡Se requiere dirección!",
    "request.daCityIsRequired": "¡Se requiere la ciudad!",
    "request.daCompanyNameIsRequired": "¡Se requiere nombre de empresa!",
    "request.daCountryIsRequired": "¡Se requiere el país!",
    "request.daPostalCodeIsRequired": "¡Se requiere el código postal!",
    "request.dateOfOccurrence": "Fecha de ocurrencia",
    "request.deliveryAddress": "Dirección de entrega",
    "request.deliveryExpected": "Entrega esperada",
    "request.deliveryNumber": "Número de entrega",
    "request.deliveryReceived": "Entrega recibida",
    "request.describeLeakageNoticed": "Cuándo y cómo se observó la fuga",
    "request.description": "Descripción",
    "request.descriptionIsRequired": "¡Se requiere descripción!",
    "request.descriptionLong": "Mientras más información detallada recibamos en el registro, más rápido podremos manejar la solicitud",
    "request.deselect": "Eliminar",
    "request.detailedDescription": "Descripción detallada",
    "request.detailedReason": "Razón detallada",
    "request.discardingDraft": "Desechando borrador...",
    "request.draftDiscarded": "Borrador descartado.",
    "request.email": "Correo electrónico",
    "request.emailIsRequired": "Se requiere correo electrónico",
    "request.equipmentIsRequired": "Se requiere producto",
    "request.errorWhileDiscardingDraft": "Error al descartar borrador: {error}",
    "request.errorWhileFetchingDelivery": "Error al recuperar la entrega: {error}",
    "request.exportAll": "Exportar todo",
    "request.external": "Externo",
    "request.firstName": "Nombre",
    "request.firstnameIsRequired": "Se requiere nombre",
    "request.generalInformation": "Información general",
    "request.hadBoxBeenOpened": "¿Alguien abrió la caja/cajón antes que usted la recibiera (por ejemplo, agente aduanal)?",
    "request.hadBoxBeenOpenedBefore": "¿Alguien abrió la caja/cajón antes que la recibiera el consignatario?",
    "request.haveNotifiedFreightForwarder": "¿Ya reclamó o notificó al transportista?",
    "request.haveNotifiedInsuranceCompany": "¿Ya reclamó o notificó a la compañía de seguros?",
    "request.howTheReasonWasIdentified": "¿Cómo se identificó el problema y en qué circunstancias?",
    "request.howTheReasonWasIdentifiedOptional": "¿Cómo se identificó el problema y en qué circunstancias? (opcional)",
    "request.ifYouWantToCommentText": "Si desea continuar comentando, vuelva a abrir la solicitud",
    "request.installationIsRequired": "Se requiere instalación",
    "request.interestedPeople": "Personas interesadas",
    "request.isInWarrantyUntil": "Periodo de garantía activo hasta {date}",
    "request.item": "Artículo",
    "request.itemCount": "Conteo de artículo",
    "request.keptPartCondition": "¿En qué condiciones se mantenía la parte reclamada después de la recepción?",
    "request.lastModified": "Última modificación",
    "request.lastName": "Apellido",
    "request.lastnameIsRequired": "Se requiere apellido",
    "request.multipleItems": "Artículos múltiples ({count})",
    "request.newSparePartsClaim": "Nuevo reclamo de partes de repuesto",
    "request.noRequestsFound": "No se encontraron solicitudes.",
    "request.nonTechnical": "Soporte al cliente",
    "request.notification.completedSparePartClaim": "Se completó el reclamo de parte de repuesto",
    "request.notification.newAttachmentAdded": "Se agregó un {link}",
    "request.notification.newAttachmentAdded.linkText": "nuevo anexo",
    "request.notification.newRequest": "Se creó una nueva solicitud",
    "request.notification.newSparePartClaim": "Se creó un nuevo reclamo de parte de repuesto",
    "request.notification.solutionPlanIsProvided": "Se proporcionó plan de solución",
    "request.numberDoesNotMatchAccount": "El número de documento ingresado no corresponde con sus detalles de cuenta. Revise de nuevo e intente otra vez o póngase en contacto con Wärtsilä.",
    "request.openFullRequest": "Abrir solicitud completa",
    "request.openRequests": "Solicitudes",
    "request.optional": "(opcional)",
    "request.orderInformation": "Información de pedido",
    "request.orderNumber": "Número de pedido Wärtsilä",
    "request.part": "Parte",
    "request.phone": "Teléfono",
    "request.pleaseAttachDocuments": "Documentos de soporte",
    "request.pleaseEnterNumber": "Ingrese un número de documento.",
    "request.poNumber": "Su número de pedido",
    "request.possibleActionsAlreadyTaken": "Acciones ya tomadas",
    "request.possibleActionsAlreadyTakenOptional": "Acciones ya tomadas (opcional)",
    "request.postalCode": "Código postal",
    "request.productReferenceType": "Tipo de referencia de producto",
    "request.provideDetailedDescription": "Descripción detallada del problema",
    "request.provideDocumentNameReferenceNumber": "Nombre de documento y número de referencia del documento de entrega reclamado",
    "request.provideMeasurementsClaimed": "Medidas de la parte reclamada",
    "request.provideMeasurementsCorrect": "Medidas de las partes requeridas / correctas.",
    "request.provideMeasurementsReceived": "Medidas de las partes recibidas.",
    "request.provideMeasurementsRequired": "Medidas de la parte requerida (correctas)",
    "request.provideTypeSerialNumber": "En caso de equipo auxiliar, proporcione el tipo y número de serie de la unidad y el componente.",
    "request.quantity": "Cantidad",
    "request.rating": "Clasificación",
    "request.rating.approveLabel": "¿Está satisfecho con el servicio?",
    "request.rating.customerServiceRegards": "Saludos cordiales,\n\nWärtsilä",
    "request.rating.customerServiceThankYou": "Gracias,\n\nWärtsilä",
    "request.rating.feedbackGiven": "Retroalimentación provista",
    "request.rating.feedbackSent": "Retroalimentación enviada",
    "request.rating.notSatisfied": "No satisfecho",
    "request.rating.offlineCouldNotLoadTicket": "No se pudo cargar boleto para retroalimentación fuera de línea.",
    "request.rating.offlineRequestRatedAlready": "Solicitud ya calificada",
    "request.rating.offlineRequestRatedAlreadyMessage": "La solicitud \"{requestTitle}\" ya ha sido calificada pero todavía puede dejar retroalimentación.",
    "request.rating.offlineRequestRatedSuccessfully": "Solicitud calificada con éxito",
    "request.rating.offlineRequestRatedSuccessfullyMessage": "Gracias por calificar \"{requestTitle}\".",
    "request.rating.offlineSendFeedback": "Enviar retroalimentación",
    "request.rating.offlineSendingFeedback": "Enviando retroalimentación...",
    "request.rating.offlineSendingFeedbackFailed": "Falló el envío de retroalimentación...",
    "request.rating.offlineSendingReopenRequest": "Enviando solicitud de re-apertura...",
    "request.rating.offlineSendingReopenRequestFailed": "Falló envío de solicitud de re-apertura...",
    "request.rating.openFeedback": "Abrir retroalimentación para mejorar nuestro servicio",
    "request.rating.openFeedbackPlaceholder": "También puede dejar la retroalimentación abierta...",
    "request.rating.openFeedbackToImproveOurService": "Abrir retroalimentación para mejorar nuestro servicio",
    "request.rating.reasonForReopening": "Razón para volver a abrir",
    "request.rating.reasonIsRequired": "Se requiere razón para volver a abrir",
    "request.rating.reasonPlaceholder": "Escribir razón...",
    "request.rating.reopenButtonText": "Volver a abrir",
    "request.rating.reopenDialogTitle": "Volver a abrir solicitud",
    "request.rating.reopenRequest": "Volver a abrir solicitud",
    "request.rating.requestIsClosed": "La solicitud está cerrada",
    "request.rating.requestIsClosedNewRequestLinkText": "nueva solicitud",
    "request.rating.requestIsClosedRatingTitle": "La solicitud está cerrada y ya no se puede clasificar.",
    "request.rating.requestIsClosedTitle": "La solicitud está cerrada y ya no se puede volver a abrir.\nPor favor cree un {newRequestLink}.",
    "request.rating.requestReopened": "La solicitud se volvió a abrir",
    "request.rating.requestReopenedText": "La solicitud se volvió a abrir, y nos pondremos en contacto con usted tan pronto como sea posible.",
    "request.rating.satisfied": "Satisfecho",
    "request.rating.sendFeedback": "Enviar",
    "request.rating.suggestionIsRequired": "Se requiere retroalimentación.",
    "request.rating.thankYouForYourFeedback": "Gracias por su retroalimentación",
    "request.rating.thankYouForYourFeedbackText": "Siempre estamos buscando maneras de mejorar nuestro servicio al cliente, y su retroalimentación es la más valiosa.",
    "request.rating.weHaveAlreadyReceivedFeedback": "Ya recibimos retroalimentación para esta solicitud. No se puede proporcionar retroalimentación adicional.",
    "request.rating.weWillRedirectYou": "Ahora lo enviaremos al {link}",
    "request.rating.whatCanWeDoBetter": "Escribir retroalimentación...",
    "request.reason": "Razón",
    "request.reason.9999": "Deficiencia de calidad de parte",
    "request.reason.DD13": "Documentación de entrega y factura",
    "request.reason.DD16": "Cantidad equivocada de partes / envío incompleto",
    "request.reason.DD18": "Parte equivocada",
    "request.reason.DD22": "Entrega tardía",
    "request.reason.DD23": "Envío perdido (entrega completa perdida)",
    "request.reason.DD24": "Empaque deficiente / entrega dañada",
    "request.reason.QD02": "La parte tiene fuga",
    "request.reason.QD03": "La parte no es funcional",
    "request.reason.QD07": "La parte está corroída",
    "request.reason.QD08": "La parte está dañada",
    "request.reason.QD09": "La parte no se ajusta",
    "request.reason.QD12": "Problema de clasificación de parte",
    "request.reason.QD14": "Marcas de parte / problema relacionada con etiqueta",
    "request.recentlyOpened": "Solicitudes abiertas recientemente",
    "request.requestId": "ID de solicitud",
    "request.requestType": "Tipo de solicitud",
    "request.requestTypeIsRequired": "Seleccione un tipo de solicitud",
    "request.runningHours": "Horas de operación",
    "request.runningHoursDate": "Actualización de fecha",
    "request.runningHoursDateInFuture": "¡Las horas de operación no pueden estar en el futuro!",
    "request.runningHoursMustBeBetween": "¡Las horas de operación deben ser un número entre 0 y 9 999 999 999!",
    "request.runningHoursOptional": "Horas de operación (opcional)",
    "request.selectAddress": "Seleccionar dirección",
    "request.selectCategory": "Seleccionar categoría...",
    "request.selectClaimReason": "Seleccione la razón...",
    "request.selectClaimedItems": "Seleccione los artículos reclamados",
    "request.solution": "Solución",
    "request.solutionPlan": "Plan de solución",
    "request.solutionPlanProvided": "Plan de solución proporcionado",
    "request.solutionText": "Solución de Wärtsilä",
    "request.sparePartsClaim": "Reclamo de parte de repuesto",
    "request.subject": "Asunto",
    "request.subjectIsRequired": "Se requiere asunto",
    "request.suggestedPartsText": "Si ya sabe qué partes se deben reemplazar, agréguelas a la solicitud.",
    "request.suggestedSpareParts": "Partes de repuesto sugeridas",
    "request.suggestionToReplaceParts": "Sugerencia para reemplazar partes",
    "request.summary": "Resumen",
    "request.technical": "Solicitud de soporte técnico o garantía",
    "request.title": "Título",
    "request.titleIsRequired": "Se requiere título",
    "request.track": "Rastreo",
    "request.typePartNameOrNumber": "Escriba el nombre o número de parte",
    "request.types.allRequests": "Todas las solicitudes",
    "request.uploadsAreNotFinished": "¡No terminaron las cargas!",
    "request.validation.deliveryIsRequired": "¡Se requiere entrega!",
    "request.validation.detailedReasonIsRequired": "¡Se requiere razón detallada!",
    "request.validation.fieldIsRequired": "¡Campo requerido!",
    "request.validation.itemIsRequired": "¡Se requiere artículo!",
    "request.validation.quantityIsRequired": "¡Se requiere cantidad reclamada!",
    "request.validation.quantityMustBeNumberBetween": "¡Cantidad no válida ingresada! (mín: {min}, máx: {max})",
    "request.validation.quantityMustBeNumeric": "¡La cantidad reclamada debe ser un número!",
    "request.validation.reasonIsRequired": "Se requiere razón!",
    "request.wartsilaInitiated": "Iniciado por Wärtsilä",
    "request.wasBoxVisiblyDamaged": "Si las partes dentro del envío estaban dañadas, ¿la caja o cajón estaba dañado visiblemente?",
    "request.wasForwarderInformed": "Si la caja o cajón estaba dañada físicamente durante la recepción, ¿se informó al transportista al momento de la entrega?",
    "request.wasOutsideOfBoxDamaged": "¿El exterior de la caja o cajón estaba dañado?",
    "request.wasPackagingMaterialDamaged": "¿El material de empaque dentro de la caja estaba dañado?",
    "request.wasPackingMaterialDamagedInsufficient": "¿El material de empaque dentro de la caja estaba dañado o era insuficiente?",
    "request.wasPartInUseLeakage": "¿La parte estaba en uso cuando se observó la fuga? Si es así, ¿por cuántas horas en operación?",
    "request.wasPartInUseProblem": "¿La parte estaba en uso cuando se observó el problema? Si es así, ¿por cuántas horas en operación?",
    "request.wasPartInspectedByWartsila": "Si la parte estaba en uso, ¿personal de Wärtsilä revisó e instaló la parte?",
    "request.whenCorrosionNoticed": "¿Cuándo se observó la corrosión?",
    "request.whenDeliveryExpected": "¿Cuándo se esperaba la entrega?",
    "request.whenDeliveryReceived": "¿Cuándo se recibió la entrega?",
    "request.whenWasDamageNoticed": "Cuándo se observó el daño",
    "request.yourClaimReference": "Su referencia de reclamo",
    "requestStatus.answered": "Completado",
    "requestStatus.approved": "Aprobado",
    "requestStatus.closed": "Cerrado",
    "requestStatus.closedChangedToProject": "Cerrado - Cambiado a proyecto",
    "requestStatus.draft": "Borrador",
    "requestStatus.inProgress": "En progreso",
    "requestStatus.partsInDelivery": "Partes en entrega",
    "requestStatus.rejected": "Rechazado",
    "requestStatus.waitingForCustomerActions": "Esperando acciones del cliente",
  },
};
