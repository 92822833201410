import { ICombinedRequest, IContact, IError } from "online-services-types";
import { Dispatch } from "redux";
import { APIFetch } from "src/APIFetch";
import { RequestType } from "src/models/warrantyClaim";
import { APIResourceState } from "src/redux/api";
import { displayError } from "src/util/error";

export function updateInterestedParties(request: ICombinedRequest, contacts: IContact[], onSuccess?: () => void) {
  return async (dispatch: Dispatch) => {
    const fetcher = getFetcher(request.requestType);
    if (fetcher !== null) {
      try {
        await fetcher.patch(
          {
            id: request.id,
            contacts
          },
          request.id
        );
        dispatch(
          APIResourceState.combinedRequests.patchAction([
            {
              id: request.id,
              contacts,
            },
          ])
        );
        if (onSuccess) onSuccess();
      } catch (error) {
        displayError((error as IError).message, undefined, undefined, error.awsRequestId);
      }
    }
  };
}

function getFetcher(requestType: string) {
  switch (requestType) {
    case RequestType.TechRequest:
      return new APIFetch("requests/techrequests");
    case RequestType.WarrantyClaim:
      return new APIFetch("requests/warrantyclaims");
    case RequestType.OperationalSupportRequest:
      return new APIFetch("requests/operationalsupportrequests");
    default:
      return null;
  }
}