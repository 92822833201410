import {
  IAccount,
  IAccountUser,
  IAddress,
  IArticle,
  IAttachment,
  ICMReport,
  ICombinedRequest,
  IContact,
  IContractRules,
  ICustomerGroupAccount,
  ICustomerGroupInstallation,
  ICustomerSupportTicket,
  IDelivery,
  IDeliveryLineItem,
  IDeliveryProject,
  IDigestSettings,
  IDocument,
  IEnergyDocument,
  IEnergyDocumentTypes,
  IEquipment,
  IFluidReport,
  IFluidReportChart,
  IFluidReportEvaluation,
  IFluidReportRules,
  IInstallation,
  IInstallationContract,
  IMarineDocument,
  IMarineDocumentType,
  INewsItem,
  INotification,
  IOperationalSupportRequest,
  IPart,
  IPartsCatalogSection,
  IPurchase,
  ISalesforcePO,
  ISalesforceRFQ,
  ISectionBase64Image,
  ISFBasketDraft,
  IShipment,
  ISparePartClaim,
  ITechRequest,
  ITraining,
  IUptimeChart,
  IUptimeReportChart,
  IUptimeRules,
  IVariationOrder,
  IVATStatement,
  IWarrantyClaim,
} from "online-services-types";
import { Dispatch, ReducersMapObject } from "redux";
import { APIResource, APIResourceErrorDisplay } from "./APIResource";
import { ParamsAPIResource } from "./ParamsAPIResource";
import { SearchResultsAPIResource } from "./SearchResultsAPIResource";

export const invalidateAPIResourceCaches = () => {
  return async (dispatch: Dispatch) => {
    Object.keys(APIResourceState).forEach((key) => {
      APIResourceState[key].invalidateCache()(dispatch);
    });
  };
};

export interface IAPIResourceState {
  accounts: APIResource<IAccount>;
  addresses: APIResource<IAddress>;
  attachments: APIResource<IAttachment>;
  documents: SearchResultsAPIResource<IDocument>;
  documentsnew: SearchResultsAPIResource<IDocument>;
  contacts: APIResource<IContact>;
  equipments: APIResource<IEquipment>;
  deliveryProjects: APIResource<IDeliveryProject>;
  deliveryProjectShipments: APIResource<IShipment>;
  deliveryProjectVariationOrders: APIResource<IVariationOrder>;
  installationContracts: APIResource<IInstallationContract>;
  installations: APIResource<IInstallation>;
  vatStatements: ParamsAPIResource<IVATStatement>;
  purchaseOrder: APIResource<ISalesforcePO>;
  requestForQuotation: APIResource<ISalesforceRFQ>;
  installationsEquipments: APIResource<IEquipment>;
  users: APIResource<IAccountUser>;
  digestSettings: APIResource<IDigestSettings>;
  warrantyclaims: APIResource<IWarrantyClaim>;
  techrequests: APIResource<ITechRequest>;
  operationalSupportRequests: APIResource<IOperationalSupportRequest>;
  customerSupportTickets: APIResource<ICustomerSupportTicket>;
  combinedRequests: APIResource<ICombinedRequest>;
  purchases: ParamsAPIResource<IPurchase>;
  sparePartPackages: ParamsAPIResource<ISFBasketDraft>;
  sparePartClaims: APIResource<ISparePartClaim>;
  catalogueSections: ParamsAPIResource<IPartsCatalogSection>;
  catalogueParts: ParamsAPIResource<IPart>;
  sectionImages: ParamsAPIResource<ISectionBase64Image>;
  cmreports: APIResource<ICMReport>;
  fluidReportRules: APIResource<IFluidReportRules>;
  fluidReports: APIResource<IFluidReport>;
  fluidReportDrafts: APIResource<IFluidReport>;
  fluidReportEvaluations: APIResource<IFluidReportEvaluation>;
  fluidReportCharts: ParamsAPIResource<IFluidReportChart>;
  uptimeEquipmentCharts: APIResource<IUptimeChart>;
  uptimeVesselCharts: ParamsAPIResource<IUptimeReportChart>;
  uptimeRules: APIResource<IUptimeRules>;
  notifications: APIResource<INotification>;
  news: APIResource<INewsItem>;
  newsnew: APIResource<INewsItem>;
  deliveries: ParamsAPIResource<IDelivery>;
  deliveryLineItems: APIResource<IDeliveryLineItem>;
  contractRules: APIResource<IContractRules>;
  trainings: APIResource<ITraining>;
  customerGroupAccounts: APIResource<ICustomerGroupAccount>;
  hierarchyInstallations: APIResource<ICustomerGroupInstallation>;
  energyDocuments: APIResource<IEnergyDocument>;
  energyDocumentsNew: APIResource<IEnergyDocument>;
  energyDocumentTypes: APIResource<IEnergyDocumentTypes>;
  marineDocuments: SearchResultsAPIResource<IMarineDocument>;
  marineDocumentsNew: SearchResultsAPIResource<IMarineDocument>;
  marineDocumentTypes: APIResource<IMarineDocumentType>;
}

const installationsPath = "service/installations";
const deliveryProjectsPath = "service/deliveryprojects";

/**
 * Note: APIResource uses APIFetch internally which translates the path like this:
 *
 *   "requests/techrequests" -> "/api-requests/techrequests"
 *   "service/contracts" -> "/api-service/contracts"
 *
 * That is, the final path has the "/api-" prefix (for CloudFront to work correctly).
 */

export const APIResourceState: IAPIResourceState = {
  accounts: new APIResource<IAccount>("service/accounts"),
  addresses: new APIResource<IAddress>("service/addresses"),
  attachments: new APIResource<IAttachment>("service/attachments"),
  documents: new SearchResultsAPIResource<IArticle>("documents/documents"),
  documentsnew: new SearchResultsAPIResource<IArticle>("documents/documentsnew"),
  combinedRequests: new APIResource<ICombinedRequest>("requests/requests"),
  contacts: new APIResource<IContact>("service/contacts"),
  equipments: new APIResource<IEquipment>("service/equipments", undefined, true),
  deliveryProjects: new APIResource<IDeliveryProject>(deliveryProjectsPath, undefined, true),
  deliveryProjectShipments: new APIResource<IShipment>(deliveryProjectsPath, "shipments"),
  deliveryProjectVariationOrders: new APIResource<IVariationOrder>(deliveryProjectsPath, "salesorders"),
  installationContracts: new APIResource<IInstallationContract>(installationsPath, "contracts"),
  installations: new APIResource<IInstallation>(installationsPath, undefined, true),
  vatStatements: new ParamsAPIResource<IVATStatement>("spareparts/vat-statements"),
  purchaseOrder: new APIResource<ISalesforcePO>("spareparts/purchaseorder"),
  requestForQuotation: new APIResource<ISalesforceRFQ>("spareparts/requestforquotation"),
  installationsEquipments: new APIResource<IEquipment>(installationsPath, "equipments"),
  users: new APIResource<IAccountUser>("service/users"),
  digestSettings: new APIResource<IDigestSettings>("service/digest-settings"),
  warrantyclaims: new APIResource<IWarrantyClaim>("requests/warrantyclaims"),
  techrequests: new APIResource<ITechRequest>("requests/techrequests"),
  operationalSupportRequests: new APIResource<IOperationalSupportRequest>("requests/operationalsupportrequests"),
  // Retry is enabled for PATCH for CSC
  customerSupportTickets: new APIResource<ICustomerSupportTicket>(
    "requests/customer-support-tickets",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  ),
  cmreports: new APIResource<ICMReport>("contracts/cmreports"),
  fluidReportRules: new APIResource<IFluidReportRules>("contracts/fluidreportrules"),
  fluidReports: new APIResource<IFluidReport>("contracts/fluidreports"),
  fluidReportDrafts: new APIResource<IFluidReport>("contracts/fluidreportdrafts"),
  fluidReportEvaluations: new APIResource<IFluidReportEvaluation>("contracts/fluidreportevaluations"),
  fluidReportCharts: new ParamsAPIResource<IFluidReportChart>("contracts/fluidreportcharts"),
  purchases: new ParamsAPIResource<IPurchase>("spareparts/purchases"),
  sparePartPackages: new ParamsAPIResource<ISFBasketDraft>("spareparts/sparepartpackages"),
  sparePartClaims: new APIResource<ISparePartClaim>("requests/sparepartclaims"),
  catalogueSections: new ParamsAPIResource<IPartsCatalogSection>("spareparts/sections"),
  catalogueParts: new ParamsAPIResource<IPart>("spareparts/parts", undefined, false, true),
  sectionImages: new ParamsAPIResource<ISectionBase64Image>("spareparts/images", undefined, false, true),
  notifications: new APIResource<INotification>(
    "service/notifications",
    undefined,
    false,
    APIResourceErrorDisplay.Autohide,
    true
  ),
  uptimeEquipmentCharts: new APIResource<IUptimeChart>("contracts/uptimeequipmentcharts", undefined, true),
  uptimeVesselCharts: new ParamsAPIResource<IUptimeReportChart>("contracts/uptimevesselcharts"),
  uptimeRules: new APIResource<IUptimeRules>("contracts/uptimerules"),
  news: new APIResource<INewsItem>("service/news"),
  newsnew: new APIResource<INewsItem>("service/newsnew"),
  deliveries: new ParamsAPIResource<IDelivery>("service/deliveries", undefined, false),
  deliveryLineItems: new APIResource<IDeliveryLineItem>("service/deliveries", "lineitems"),
  contractRules: new APIResource<IContractRules>("contracts/contractrules"),
  trainings: new APIResource<ITraining>("service/trainings"),
  customerGroupAccounts: new APIResource<ICustomerGroupAccount>("spareparts/customer-group-accounts"),
  hierarchyInstallations: new APIResource<ICustomerGroupInstallation>("spareparts/hierarchy-installations"),
  energyDocuments: new APIResource<IEnergyDocument>("documents/energydocuments"),
  energyDocumentsNew: new APIResource<IEnergyDocument>("documents/energydocuments-new"),
  energyDocumentTypes: new APIResource<IEnergyDocumentTypes>("documents/energydocumenttypes"),
  marineDocuments: new SearchResultsAPIResource<IMarineDocument>("documents/marinedocuments"),
  marineDocumentsNew: new SearchResultsAPIResource<IMarineDocument>("documents/marinedocuments-new"),
  marineDocumentTypes: new APIResource<IMarineDocumentType>("documents/sfigroup"),
};

export const injectAPIReducers = (oldReducers: ReducersMapObject) => {
  const newReducers: ReducersMapObject = {};

  Object.keys(oldReducers).forEach((key) => {
    newReducers[key] = oldReducers[key];
  });

  Object.keys(APIResourceState).forEach((key) => {
    newReducers[key] = APIResourceState[key].createReducer(key);
  });

  return newReducers;
};
