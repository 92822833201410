import { Tooltip as UIKitTooltip, TooltipArrow } from "@wartsila/ui-kit";
import * as React from "react";
import { TooltipPayload, TooltipProps } from "recharts";
import { themes } from "src/design-system/Theme/theme";
import { WarningIcon } from "src/icons";

import { formatDate } from "src/util/formatters";
import { translateString } from "src/util/localization";
import styled, { css } from "styled-components";
import colors from "../Tokens/colors";
import { spacer, spacers } from "../Tokens/tokens";
import { fixedFontSizes, fluidTypography, fontSizes, pStyles } from "../Tokens/typography";
import { IListData } from "./index";
import {MouseEventHandler} from "react";

export const rechartsTooltipOffset = 20;

export const StyledTooltip = styled(UIKitTooltip)`
  ${pStyles};
  z-index: 200;
  color: ${colors.primary.black};
  padding: ${spacers([3, 4])};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
`;

const TooltipContentWrapper = styled.div``;

const WideContent = styled.div`
  flex-direction: row;
  align-items: flex-start;
  display: flex;

  > div:not(:first-child) {
    margin-left: ${spacer(2)};
  }
`;

export const TooltipLabel = styled.p`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  line-height: 1.6em;
  color: ${themes.light.primary1};
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  margin-bottom: 0px;
`;

const TooltipListRow = styled.div`
  text-align: left;
  margin-bottom: 0px;
`;

const TooltipText = styled.p`
  ${fluidTypography(fontSizes.xs.min, fontSizes.xs.max)};
  margin: 0;
`;

const TooltipTitle = styled.div`
  ${fluidTypography(fontSizes.xs.min, fontSizes.xs.max)};
  font-weight: 700;
  margin-bottom: ${spacer(1)};
  text-align: left;
`;

const SFOCTooltipTitle = styled.div`
  ${fluidTypography(fontSizes.xs.min, fontSizes.xs.max)};
  font-weight: 500;
  margin: 0;
  text-align: left;
`;

const defaultLeft = 76;
const defaultTop = 28;

const AbsoluteTooltip = styled(StyledTooltip)`
  min-width: 100px;
  ${({ width }: IAbsoluteTooltipProps) =>
    width &&
    css`
      width: ${width}px;
    `}
  position: absolute;
  left: ${(props: IAbsoluteTooltipProps) => `${(props.left ?? defaultLeft) + (props.xOffset || 0)}px`};
  top: ${(props: IAbsoluteTooltipProps) => `${props.top ?? defaultTop}px`};
`;

interface IGroupedPayload {
  [key: string]: {
    key: string;
    data: IListData[];
  };
}

interface IAbsoluteTooltipProps {
  left?: number;
  top?: number;
  width?: number;
  xOffset?: number;
}

interface ITooltipProps {
  arrow?: TooltipArrow | false;
  onMouseLeave?: MouseEventHandler<HTMLElement> | undefined;
}

interface ITooltipContentProps {
  title: string;
  unit?: string;
  listData?: IListData[];
  children?: React.ReactNode;
  onMouseLeave?: MouseEventHandler<HTMLElement> | undefined;
}

type IStaticTooltipProps = ITooltipContentProps & IAbsoluteTooltipProps & ITooltipProps;
type ICustomTooltipProps = TooltipProps & ITooltipProps;
type TooltipPropsType = ITooltipContentProps & ITooltipProps;

export const CustomTooltip = (props: ICustomTooltipProps) => {
  const { payload = [], label } = props;

  return (
    <Tooltip
      title={!!label ? formatDate(label.toString()) : ""}
      listData={payload.map((entry: TooltipPayload, index: number) => ({
        key: index,
        label: entry.name,
        text: `${roundToNDecimalPlaces(entry.value as number, 2)}${!!entry.unit ? entry.unit : ""}`,
      }))}
    />
  );
};

export const BigGroupedRunningHoursTooltip = (props: TooltipProps) => {
  const { payload, label } = props;
  const groupedData: IGroupedPayload = {};

  const types = [
    translateString("fluid.uptime.running"),
    translateString("fluid.uptime.standby"),
    translateString("fluid.uptime.stop"),
    translateString("fluid.uptime.shutDown"),
    translateString("fluid.uptime.noData"),
  ];
  const stringEnd = new RegExp(" (" + types.join("|") + ")$", "");

  if (!!payload) {
    let installationKey: string;
    let translationIndex = 0;
    payload.forEach((entry: TooltipPayload, index: number) => {
      const key = entry.name.replace(stringEnd, "");
      if (!!!installationKey) {
        installationKey = key;
      }

      if (installationKey !== key) {
        installationKey = key;
        translationIndex = 0;
      }

      if (!groupedData[key]) {
        groupedData[key] = {
          key,
          data: [],
        };
      }

      groupedData[key].data.push({
        label: types[translationIndex],
        text: `${roundToNDecimalPlaces(entry.value as number, 2).toString()}${entry.unit}`,
        index,
      });
      translationIndex++;
    });
  }

  return (
    <StyledTooltip>
      <TooltipTitle>{!!label ? formatDate(label.toString()) : ""}</TooltipTitle>
      <WideContent>
        {Object.keys(groupedData).map((key: string) => (
          <div key={`base-tooltop-${key}`}>
            <TooltipContent title={groupedData[key].key} listData={groupedData[key].data} />
          </div>
        ))}
      </WideContent>
    </StyledTooltip>
  );
};

/**
 *  Absolutely positioned Tooltip
 * @param props: IStaticTooltipProps
 */
export const StaticTooltip = (props: IStaticTooltipProps) => {
  return (
    <AbsoluteTooltip {...props}>
      <TooltipContent {...props} />
    </AbsoluteTooltip>
  );
};

const TooltipContent = (props: ITooltipContentProps) => {
  return (
    <TooltipContentWrapper>
      <TooltipTitle>{props.title}</TooltipTitle>
      {props.listData &&
        props.listData.map((item, idx) => (
          <TooltipListRow key={`Tooltip-item-${item.index || idx}`}>
            <TooltipLabel>{item.label}</TooltipLabel>
            <TooltipText>{item.text}</TooltipText>
          </TooltipListRow>
        ))}
      {props.children}
    </TooltipContentWrapper>
  );
};

export const Tooltip = (props: TooltipPropsType) => {
  // Default arrow: left, set to false for no arrow
  const arrowStyle = props.arrow !== false ? props.arrow : TooltipArrow.Left;
  return (
    <StyledTooltip arrow={arrowStyle} {...props}>
      <TooltipContent {...props} />
    </StyledTooltip>
  );
};

export const SFOCTooltip = (props: TooltipPropsType) => {
  return (
    <TooltipContentWrapper>
      <SFOCTooltipTitle>
        {props.title}
        {props.unit}
      </SFOCTooltipTitle>
    </TooltipContentWrapper>
  );
};

export const DisclaimerTooltip = (props: IStaticTooltipProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const handleClick: React.MouseEventHandler = (event) => {
    event.stopPropagation();
    setShowTooltip((prev) => !prev);
  };
  return (
    <span
      onMouseOver={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ position: "relative" }}
    >
      <span onClick={handleClick} onMouseEnter={() => setShowTooltip(true)}>
        <WarningIcon />
      </span>
      {showTooltip && <StaticTooltip onMouseLeave={() => setShowTooltip(false)} {...props} />}
    </span>
  );
};

export default Tooltip;

const roundToNDecimalPlaces = (value: number, decimalPlaces: number): number => {
  const decimalBase = 10;
  const decimalFactor = Math.pow(decimalBase, decimalPlaces);
  return Math.round(value * decimalFactor) / decimalFactor;
};
