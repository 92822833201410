import { BarRow, ChartTable } from "./GroupedDatesChart";

import { IInstallation } from "online-services-types";
import { IRFQ } from "../request/interfaces";
import { Legend } from "./Legend";
import { ManagerDashboardTableWrapper } from "./shared";
import React from "react";
import { TimelineRow } from "./TimelineRow";
import { groupRFQsByType } from "../dashboard";
import { translateString } from "src/util/localization";

export const RFQChart = (props: { end: string; start: string; rfqs: IRFQ[]; installations: IInstallation[] }) => {
  return (
    <ManagerDashboardTableWrapper>
      <Legend categories={props.installations.map((installation) => installation.name)} />
      <ChartTable>
        <tbody>
          <BarRow
            timeframeEnd={props.end}
            timeframeStart={props.start}
            unitLabel={translateString("managerDashboard.numberOfRequests")}
            categories={props.installations.map((installation) => installation.id)}
            data={groupRFQsByType(props.rfqs).map((date) => ({
              items: date.items.map((item) => ({ category: item.installationId, count: item.count })),
              date: date.date,
            }))}
          />
          <TimelineRow start={new Date(props.start)} end={new Date(props.end)} />
        </tbody>
      </ChartTable>
    </ManagerDashboardTableWrapper>
  );
};
