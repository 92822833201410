import * as React from "react";
import colors from "src/design-system/Tokens/colors";
import { CloseIcon, IconSize } from "src/icons";
import { FlexContainer } from "src/design-system/Container";
import styled from "styled-components";

interface INotificationBaseProps {
  onDismiss?: () => void;
  children?: React.ReactNode;
}

const NotificationWrapper = styled(FlexContainer)`
  max-width: 500px;
`;

const BodyWrapper = styled.div`
  flex: 1;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  margin: 0 15px;
`;

export const NotificationBase: React.FC<INotificationBaseProps> = ({ onDismiss, children }) => (
  <NotificationWrapper>
    <BodyWrapper>{children}</BodyWrapper>
    {!!onDismiss && (
      <CloseIconWrapper onClick={onDismiss}>
        <CloseIcon size={IconSize.XSmall} color={colors.primary.gray} />
      </CloseIconWrapper>
    )}
  </NotificationWrapper>
);
