import { spacingTokens, spacings } from "./tokens";

import { css } from "styled-components";

export interface ISpacing {
  $flex?: string;
  $padding?: number | number[];
  $paddingTop?: number;
  $paddingLeft?: number;
  $paddingBottom?: number;
  $margin?: number | number[];
  $marginTop?: number;
  $marginBottom?: number;
  $marginRight?: number;
  $marginLeft?: number;
  $gap?: number;
}

export const padding = css`
  ${(props: ISpacing) => {
    if (props.$padding instanceof Array) {
      return "padding:" + joinSpaceArray(props.$padding);
    } else if (props.$padding && isValidSpacing(props.$padding)) {
      return "padding:" + getSpaceValue(props.$padding);
    }

    if (props.$paddingTop && isValidSpacing(props.$paddingTop)) {
      return "padding-top:" + getSpaceValue(props.$paddingTop);
    }

    if (props.$paddingBottom && isValidSpacing(props.$paddingBottom)) {
      return "padding-bottom:" + getSpaceValue(props.$paddingBottom);
    }

    if (props.$paddingLeft && isValidSpacing(props.$paddingLeft)) {
      return "padding-left:" + getSpaceValue(props.$paddingLeft);
    }
    return "";
  }};
`;

export const gap = css`
  ${(props: ISpacing) => {
    if (props.$gap && isValidSpacing(props.$gap)) {
      return "gap:" + getSpaceValue(props.$gap);
    }

    return "";
  }};
`;

export const flex = css`
  ${(props: ISpacing) => {
    if (props.$flex) {
      return "flex:" + props.$flex;
    }

    return "";
  }};
`;

export const margin = css`
  ${(props: ISpacing) => {
    if (props.$margin instanceof Array) {
      return "margin:" + joinSpaceArray(props.$margin);
    } else if (props.$margin && isValidSpacing(props.$margin)) {
      return "margin:" + getSpaceValue(props.$margin);
    }

    if (props.$marginTop && isValidSpacing(props.$marginTop)) {
      return "margin-top:" + getSpaceValue(props.$marginTop);
    }

    if (props.$marginBottom && isValidSpacing(props.$marginBottom)) {
      return "margin-bottom:" + getSpaceValue(props.$marginBottom);
    }

    if (props.$marginLeft && isValidSpacing(props.$marginLeft)) {
      return "margin-left:" + getSpaceValue(props.$marginLeft);
    }

    if (props.$marginRight && isValidSpacing(props.$marginRight)) {
      return "margin-right:" + getSpaceValue(props.$marginRight);
    }

    return "";
  }};
`;

const joinSpaceArray = (array: number[]) => {
  return array.map((x) => getSpaceValue(x)).join(" ");
};

const getSpaceValue = (index: number) => {
  return spacings[index] + spacingTokens.unit;
};

const isValidSpacing = (index: number): boolean => {
  return index > -1 && index < spacings.length + 1;
};
