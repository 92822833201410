import { CommerceOrderView, CommerceQuoteView, ShoppingCartView, SuccessView } from "./views/Commerce";
import { IRoute, IRouteWithComponent } from "src/routes";
import { InstallationSelectionView, InstallationsSelectionView } from "src/views/InstallationSelectionView";
import { NewsView, RequestQuotation } from "src/views/NewsView";

import { CommerceService } from "./services/commerce/commerce.index";
import { ContactCMView } from "src/views/ContactCMView";
import { ContactView } from "src/views/ContactView";
import { ContractView } from "src/views/ContractView";
import { CreateFluidReportView } from "src/views/CreateFluidReportView";
import { CreateWarrantyClaimView } from "src/views/CreateWarrantyClaimView";
import { DocumentsView } from "src/views/DocumentsView";
import { DownloadView } from "src/views/DownloadView";
import { EnergyDocumentsView } from "src/views/EnergyDocumentsView";
import { EquipmentsView } from "src/views/EquipmentsView";
import { FluidReportDraftView } from "src/views/FluidReportDraftView";
import { IndexView } from "src/views/IndexView";
import { InstallationInformationView } from "src/views/InstallationInformationView";
import { InstallationView } from "src/views/InstallationView";
import { InvoicesView } from "src/views/InvoicesView";
import { LoadingView } from "src/views/LoadingView";
import { ManagerDashboardView } from "./views/ManagerDashboardView";
import { MarineDocumentsView } from "src/views/MarineDocumentsView";
import { NewVATStatementPageView } from "src/views/NewVATStatementPageView";
import { OfflineRatingView } from "src/views/OfflineRatingView";
import { ProfileView } from "src/views/ProfileView";
import { RFQView } from "src/views/RFQView";
import { ReleaseNotesPage } from "src/views/ReleaseNotesView/ReleaseNotesPage";
import { ReportsView } from "src/views/ReportsView";
import { RequestsView } from "src/views/SupportView";
import { RouteId } from "src/util/routeId";
import { RunningHoursService } from "./services/runninghours/runninghours.index";
import { SFOCFleetDashboardView } from "src/views/SFOCFleetDashboardView";
import { SFOCHistoryView } from "src/services/sfoc/sfoc.view.history";
import { SFOCVesselDashboardView } from "./views/SFOCVesselDashboardView";
import { SFOCView } from "src/views/SFOCView";
import { SparePartPackagesView } from "src/views/SparePartPackagesView";
import { SparePartsCatalogueView } from "src/views/SparePartsCatalogueView";
import { TrainingContactView } from "src/views/TrainingContactView";
import { TrainingRegisterViewComponent } from "src/views/TrainingRegisterView";
import { TrainingsView } from "src/views/TrainingsView";
import { UsersView } from "src/views/UsersView";
import { VATStatementsView } from "src/views/VATStatementsView";
import { VesselReportsView } from "src/views/VesselReportsView";

const routeComponentMapping = {
  [RouteId.CreateWarrantyClaim]: CreateWarrantyClaimView,
  [RouteId.Download]: DownloadView,
  [RouteId.Requests]: RequestsView,
  [RouteId.Documents]: DocumentsView,
  [RouteId.Contact]: ContactView,
  [RouteId.Contract]: ContractView,
  [RouteId.ContactCM]: ContactCMView,
  [RouteId.Equipments]: EquipmentsView,
  [RouteId.Users]: UsersView,
  [RouteId.Profile]: ProfileView,
  [RouteId.Index]: IndexView,

  [RouteId.Installation]: InstallationView,
  [RouteId.InstallationInformation]: InstallationInformationView,
  [RouteId.Reports]: ReportsView,
  [RouteId.CreateFluidReport]: CreateFluidReportView,
  [RouteId.RunningHours]: RunningHoursService,
  [RouteId.FluidReportDraft]: FluidReportDraftView,
  [RouteId.SpareParts]: CommerceService,
  [RouteId.SparePartsCatalogue]: SparePartsCatalogueView,
  [RouteId.InstallationSelection]: InstallationSelectionView,
  [RouteId.Charts]: VesselReportsView,
  [RouteId.News]: NewsView,
  [RouteId.ReleaseNotes]: ReleaseNotesPage,
  [RouteId.RequestQuotation]: RequestQuotation,
  [RouteId.OfflineRating]: OfflineRatingView,
  [RouteId.NewSFOCReport]: SFOCView,
  [RouteId.SFOCHistory]: SFOCHistoryView,
  [RouteId.SFOCVesselDashboard]: SFOCVesselDashboardView,
  [RouteId.SFOCFleetDashboard]: SFOCFleetDashboardView,
  [RouteId.Invoices]: InvoicesView,
  [RouteId.NewRFQ]: RFQView,
  [RouteId.TrainingRegister]: TrainingRegisterViewComponent,
  [RouteId.TrainingContact]: TrainingContactView,
  [RouteId.Trainings]: TrainingsView,
  [RouteId.ManagerDashboard]: ManagerDashboardView,
  [RouteId.EnergyDocuments]: EnergyDocumentsView,
  [RouteId.MarineDocuments]: MarineDocumentsView,

  [RouteId.CommerceQuote]: CommerceQuoteView,

  [RouteId.VATStatements]: VATStatementsView,

  [RouteId.NewVATStatement]: NewVATStatementPageView,

  [RouteId.CommerceOrder]: CommerceOrderView,
  [RouteId.CommerceShoppingCart]: ShoppingCartView,
  [RouteId.CommerceOrderSuccess]: SuccessView,
  [RouteId.CommerceQuoteSuccess]: SuccessView,
  [RouteId.Installations]: InstallationsSelectionView,
  [RouteId.Loading]: LoadingView,
  [RouteId.SparePartPackages]: SparePartPackagesView,
};

/**
 * Get route definition with component required for rendering.
 * Route definitions do not include components to prevent cyclic references that break tests
 */
export const getRouteWithComponent = (route: IRoute): IRouteWithComponent => {
  if (!routeComponentMapping[route.id]) {
    throw new Error(`Configuration error! No component found for route: ${route.id}`);
  }
  const routesWithComponents = route as IRouteWithComponent;
  routesWithComponents.component = routeComponentMapping[route.id];
  return routesWithComponents;
};
