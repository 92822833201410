// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const eula_en_US: ITranslations = {
  en_US: {
    "eula.acceptPrivacyStatement": "I accept the {privacyStatement}",
    "eula.acceptTermsAndConditions": "I accept the {termsAndConditions}",
    "eula.agreeButtonText": "Continue",
    "eula.cancelButtonText": "Cancel & logout",
    "eula.caption": "Before continuing to Wärtsilä Online please read and accept the Terms of Use and the Privacy Notice.",
    "eula.privacyStatement": "Privacy Notice",
    "eula.termsAndConditions": "Terms of Use",
    "eula.title": "Accept Terms of Use",
    "eula.validate.acceptPrivacyStatement": "You have to accept the Privacy Notice",
    "eula.validate.acceptTermsAndConditions": "You have to accept the Terms of Use",
  },
};
