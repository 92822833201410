import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const VariationOrderIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>variation order</title>
    <path
      d="M 24 4 C 12.9543 4 4 12.9543 4 24 C 4 35.0457 12.9543 44 24 44 C 35.0457 44 44 35.0457 44 24 C 44 18.6957 41.8929 13.6086 38.1421 9.85786 C 34.3914 6.10714 29.3043 4 24 4 Z M 24 40 C 15.1634 40 8 32.8366 8 24 C 8 15.1634 15.1634 8 24 8 C 32.8366 8 40 15.1634 40 24 C 40 28.2435 38.3143 32.3131 35.3137 35.3137 C 32.3131 38.3143 28.2435 40 24 40 Z M 24 17.38 C 23.3744 17.3767 22.7681 17.5965 22.29 18 C 21.8546 18.3304 21.5962 18.8434 21.59 19.39 C 21.5924 19.9884 21.9226 20.5373 22.45 20.82 C 23.3026 21.2786 24.2307 21.5801 25.19 21.71 C 26.3111 21.9149 27.4169 22.1955 28.5 22.55 C 31.68 23.65 33.27 25.61 33.27 28.43 C 33.2815 30.3378 32.3105 32.1172 30.7 33.14 C 29.3025 34.0954 27.6853 34.6803 26 34.84 V 38 H 22 V 34.81 C 21.0376 34.6675 20.0991 34.395 19.21 34 C 17.8816 33.4184 16.7321 32.4934 15.88 31.32 C 15.1189 30.2994 14.7021 29.0631 14.69 27.79 H 20.81 C 20.7712 28.6244 21.1451 29.4245 21.81 29.93 C 22.5183 30.4032 23.3591 30.6379 24.21 30.6 C 24.9196 30.6477 25.6245 30.4539 26.21 30.05 C 26.6476 29.7111 26.8967 29.1833 26.88 28.63 C 26.869 28.0487 26.5274 27.5247 26 27.28 C 24.9514 26.7854 23.8416 26.4323 22.7 26.23 C 21.2863 25.9411 19.9198 25.4564 18.64 24.79 C 17.662 24.2808 16.8241 23.539 16.2 22.63 C 15.6329 21.7669 15.3401 20.7525 15.36 19.72 C 15.3355 17.8285 16.2303 16.0428 17.76 14.93 C 18.9972 13.9898 20.459 13.3899 22 13.19 V 10 H 26 V 13.16 C 27.7074 13.3192 29.3425 13.9263 30.74 14.92 C 32.3725 16.0545 33.3266 17.9326 33.28 19.92 H 26.68 C 26.68 18.23 25.78 17.38 24 17.38 Z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </IconBase>
);
