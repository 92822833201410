import * as React from "react";
import { Radio } from "src/design-system/Radio";
import { spacer } from "src/design-system/Tokens/tokens";
import { P, fixedFontSizes } from "src/design-system/Tokens/typography";
import styled from "styled-components";
import { Container, FlexContainer } from "../Container";

export interface IRadioChoiceProps {
  name: string;
  question?: string;
  selectedOptionId: string;
  options: IRadioChoiceOption[];
  horizontal?: boolean;
  icon?: React.ReactNode;
  onOptionSelect(option: IRadioChoiceOption): void;
  onSelectCallback?(): void;
}

export interface IRadioChoiceOption {
  id: string;
  label: string;
  description?: string | JSX.Element;
  sapCode?: string;
}

interface IRadioLabelWrapperProps {
  $inline?: boolean;
}

const RadioLabelWrapper = styled.div<IRadioLabelWrapperProps>`
  ${(props) => (props.$inline ? "display: inline-flex" : "display: flex")};
  flex-direction: column;
  margin: ${spacer(1)} 0 ${spacer(2)};
  margin-right: ${spacer(3)};
`;

const RadioQuestion = styled(P)`
  font-size: ${fixedFontSizes.baseFontSize}px;
  margin-bottom: ${spacer(2)};
`;

const RadioDescription = styled(P)`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  margin-left: ${spacer(4)};
  margin-bottom: 0;
`;

export class RadioChoice extends React.Component<IRadioChoiceProps> {
  public render() {
    return (
      <div>
        {this.props.question ? (
          <RadioQuestion>
            <FlexContainer>
              {this.props.question} <Container $padding={[0, 0, 0, 1]}>{this.props.icon || null}</Container>
            </FlexContainer>
          </RadioQuestion>
        ) : null}

        {this.props.options.map((option: IRadioChoiceOption) => (
          <RadioLabelWrapper key={option.id} $inline={this.props.horizontal}>
            <Radio
              id={`${this.props.name}_${option.id}`}
              name={this.props.name}
              value={option.id}
              label={option.label}
              checked={option.id === this.props.selectedOptionId}
              onChange={() => {
                this.props.onOptionSelect(option);
              }}
            />
            {option.description && <RadioDescription>{option.description}</RadioDescription>}
          </RadioLabelWrapper>
        ))}
      </div>
    );
  }
}
