// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_ru: ITranslations = {
  ru: {
    "fluid.afterSeparator": "После сепаратора",
    "fluid.analysisDate": "Дата анализа",
    "fluid.analysisOf": "Анализ",
    "fluid.beforeSeparator": "Перед сепаратором",
    "fluid.bunkeringLocation": "Место бункеровки (как опция)",
    "fluid.categoryISOF": "Категория ISO-F",
    "fluid.commentsFromTheLaboratory": "Примечания от лаборатории (как опция)",
    "fluid.common": "Общий",
    "fluid.coolingWater": "Охлаждающая жидкость",
    "fluid.differentPerEngine": "Отличается для каждого двигателя",
    "fluid.draft.continueEditing": "Продолжить редактирование",
    "fluid.draft.continueWithExistingDraft": "Перейдите к существующему черновику",
    "fluid.draft.createNewReport": "Создать новый отчет по жидкостям",
    "fluid.draft.deleteDraft": "Удалить черновик",
    "fluid.draft.deleted": "Черновик удален",
    "fluid.draft.deleting": "Удаление черновика...",
    "fluid.draft.deletingError": "Ошибка при удалении черновика",
    "fluid.draft.or": "ИЛИ",
    "fluid.draft.sending": "Сохранение черновика...",
    "fluid.draft.sendingError": "Ошибка при сохранении черновика",
    "fluid.draft.sent": "Черновик сохранен",
    "fluid.engineLife": "Срок эксплуатации двигателя",
    "fluid.engines": "Двигатели",
    "fluid.enginesSampleData": "Данные выборки по двигателям",
    "fluid.field.acid_number_mg_koh_g__c": "Кислотное число",
    "fluid.field.aluminum_al_ppm__c": "Алюминий, Al",
    "fluid.field.aluminum_silicon_mg_kg__c": "Алюминий + Кремний",
    "fluid.field.ash_m_m__c": "Зола",
    "fluid.field.asphaltenes_m_m__c": "Асфальтены",
    "fluid.field.base_number_bn_mg_koh_g__c": "Щелочное число (BN)",
    "fluid.field.boron_b_ppm__c": "Бор, B",
    "fluid.field.calcium_ca_mg_kg__c": "Кальций, Ca",
    "fluid.field.calcium_ca_ppm__c": "Кальций, Ca",
    "fluid.field.carbon_residue_micro_method_m_m__c": "Коксовый остаток, микрометод",
    "fluid.field.ccai__c": "CCAI",
    "fluid.field.cetane_index__c": "Цетановый индекс",
    "fluid.field.chlorides_cl_mg_l__c": "Хлориды, Cl",
    "fluid.field.chromium_cr_ppm__c": "Хром, Cr",
    "fluid.field.copper_cu_mg_l__c": "Медь, Cu",
    "fluid.field.copper_cu_ppm__c": "Медь, Cu",
    "fluid.field.density_15_c_kg_m3__c": "Плотность при 15 °C",
    "fluid.field.flash_point_c__c": "Температура воспламенения",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "Сероводород",
    "fluid.field.insolubes_w__c": "Нерастворимые включения",
    "fluid.field.iron_fe_mg_kg__c": "Железо, Fe",
    "fluid.field.iron_fe_mg_l__c": "Железо, Fe",
    "fluid.field.iron_fe_ppm__c": "Железо, Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "Кинематическая вязкость при 40 °C",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "Кинематическая вязкость при 50 °C",
    "fluid.field.lead_pb_mg_kg__c": "Свинец, Pb",
    "fluid.field.lead_pb_ppm__c": "Свинец, Pb",
    "fluid.field.lower_heating_value_mj_kg__c": "Нижнее значение теплоты сгорания",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "Смазывающая способность, скорректированный диаметр пятна износа (wsd 1,4) при 60 °C",
    "fluid.field.magnesium_mg_mg_kg__c": "Магний, Mg",
    "fluid.field.molybenum_mo_ppm__c": "Молибден, Mo",
    "fluid.field.nickel_ni_mg_kg__c": "Никель, Ni",
    "fluid.field.nickel_ni_ppm__c": "Никель, Ni",
    "fluid.field.nitrate_no3_mg_l__c": "Нитраты, NO₃",
    "fluid.field.nitration_abs_cm__c": "Нитрация",
    "fluid.field.nitrite_no2_mg_l__c": "Нитриты, NO₂",
    "fluid.field.oxidation_abs_cm__c": "Окисление",
    "fluid.field.oxidation_stability_g_m3__c": "Устойчивость к окислению",
    "fluid.field.ph__c": "pH",
    "fluid.field.phosphorus_p_mg_kg__c": "Фосфор, P",
    "fluid.field.phosphorus_p_ppm__c": "Фосфор, P",
    "fluid.field.pour_point_c__c": "Температура текучести",
    "fluid.field.silicon_si_ppm__c": "Кремний, Si",
    "fluid.field.sodium_na_mg_kg__c": "Натрий, Na",
    "fluid.field.sodium_na_ppm__c": "Натрий, Na",
    "fluid.field.sulphates_so4_mg_l__c": "Сульфаты, SO₄",
    "fluid.field.sulphur_m_m__c": "Сера",
    "fluid.field.tin_sn_ppm__c": "Олово, Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "Общее кислотное число (TAN)",
    "fluid.field.total_hardness_ca_mg_dh__c": "Общая жесткость (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "Общий осадок",
    "fluid.field.vanadium_v_mg_kg__c": "Ванадий, V",
    "fluid.field.vanadium_v_ppm__c": "Ванадий, V",
    "fluid.field.viscosity_100_c_cst__c": "Вязкость при 100 °C",
    "fluid.field.viscosity_40_c_cst__c": "Вязкость при 40 °C",
    "fluid.field.water_v_v__c": "Вода",
    "fluid.field.water_vol_or_w__c": "Вода",
    "fluid.field.zinc_zn_mg_kg__c": "Цинк, Zn",
    "fluid.field.zinc_zn_ppm__c": "Цинк, Zn",
    "fluid.fuel": "Топливо",
    "fluid.gas": "Газ",
    "fluid.general": "Общая информация",
    "fluid.generalInformation": "Общая информация",
    "fluid.header.elemConcs": "Концентрации элементов",
    "fluid.header.fuelProps": "Свойства топлива",
    "fluid.header.oilProps": "Свойства масла",
    "fluid.htCircuit": "ВТ контур",
    "fluid.inputValues": "Входные значения",
    "fluid.laboratoryReferenceNumber": "Лабораторный ссылочный номер (как опция)",
    "fluid.lastData": "Последние данные",
    "fluid.lowSulphurFuels": "Низкосернистые сорта топлива",
    "fluid.ltCircuit": "НТ контур",
    "fluid.lubeOil": "Смазочное масло",
    "fluid.lubricant": "Смазочный материал",
    "fluid.notAvailable": "ОТСУТСТВУЕТ",
    "fluid.oilAdded": "Масло добавлено",
    "fluid.oilLife": "Срок службы масла",
    "fluid.oldLubricantDisclaimer": "В этом разделе содержатся данные прошлых периодов по ранее использованному смазочному материалу",
    "fluid.operationOn": "Работа на",
    "fluid.phase.reportData": "Данные отчета",
    "fluid.phase.sampleData": "Данные выборки",
    "fluid.phase.scopeSelection": "Выбор области",
    "fluid.phase.summary": "Краткое описание",
    "fluid.product": "Продукт",
    "fluid.reportData": "Данные отчета",
    "fluid.sameForAllEngines": "Одинаково для всех двигателей",
    "fluid.sampleAggregation": "Агрегация проб",
    "fluid.sampleDate": "Дата отбора пробы",
    "fluid.samplePoint": "Точка отбора проб",
    "fluid.scopeOfReport": "Предмет отчета",
    "fluid.sending": "Предоставление отчета по жидкостям...",
    "fluid.sendingError": "Ошибка при предоставлении отчета по жидкостям",
    "fluid.sent": "Отчет по жидкостям предоставлен",
    "fluid.timeframe": "Временные рамки",
    "fluid.unit.abscm": "Абс./см",
    "fluid.unit.cst": "сСт",
    "fluid.unit.degreec": "&deg;С",
    "fluid.unit.degreedh": "&deg;dH",
    "fluid.unit.gm3": "г/м³",
    "fluid.unit.kgm3": "кг/м³",
    "fluid.unit.mgkg": "мг/кг",
    "fluid.unit.mgkohg": "мг КОН / г",
    "fluid.unit.mgl": "мг/л",
    "fluid.unit.mjkg": "МДж/кг",
    "fluid.unit.mm2s": "мм²/с",
    "fluid.unit.pmm": "% м/м",
    "fluid.unit.ppm": "ppm",
    "fluid.unit.pvv": "% об./об.",
    "fluid.unit.um": "мкм",
    "fluid.unit.volporwp": "об. % или вес. %",
    "fluid.unit.wp": "вес. %",
    "fluid.unspecified": "Не соответствующий техническим условиям",
    "fluid.uptime": "Часы наработки",
    "fluid.uptime.availability": "Эксплуатационная готовность",
    "fluid.uptime.availabilityFormula": "(Работа + Ожидание) / (Работа + Ожидание + Остановка + Отключение)",
    "fluid.uptime.availabilityLimits": "Цели эксплуатационной готовности",
    "fluid.uptime.contractLimit": "Ограничение соглашения",
    "fluid.uptime.daily": "Ежедневно",
    "fluid.uptime.engineAvailability": "Эксплуатационная готовность двигателя",
    "fluid.uptime.limitMustBeNumber": "Предел должен быть числом!",
    "fluid.uptime.limitMustBeNumberBetween": "Предел должен быть числом от {min} до {max}!",
    "fluid.uptime.limits": "Редактировать целевые значения",
    "fluid.uptime.monthly": "Ежемесячно",
    "fluid.uptime.noAvailability": "Нет данных по эксплуатационной готовности за этот период.",
    "fluid.uptime.noData": "Нет данных",
    "fluid.uptime.noMaintenanceData": "Нет данных по техническому обслуживанию за этот период.",
    "fluid.uptime.noRunningHours": "Нет данных по наработке в часах за этот период.",
    "fluid.uptime.period": "Период",
    "fluid.uptime.report": "Отчет",
    "fluid.uptime.running": "Работа",
    "fluid.uptime.saveLimits": "Сохранить",
    "fluid.uptime.shutDown": "Отключение",
    "fluid.uptime.standby": "Ожидание",
    "fluid.uptime.stop": "Остановка",
    "fluid.uptime.sum": "СУММА",
    "fluid.uptime.yearly": "Ежегодно",
    "fluid.validation.analysisDateEarlierThanSampleDate": "Дата анализа не может быть датой раньше, чем дата отбора пробы",
    "fluid.validation.analysisDateInFuture": "Дата анализа не может быть датой в будущем",
    "fluid.validation.analysisDateRequired": "Требуется дата анализа",
    "fluid.validation.categoryISOFIsRequired": "Требуется категория ISO-F",
    "fluid.validation.common": "Введено неожиданное значение (мин.: {min}, макс.: {max}).",
    "fluid.validation.engineIsRequired": "Требуется двигатель",
    "fluid.validation.engineLifeMustBeANumber": "Срок эксплуатации двигателя должен быть числом",
    "fluid.validation.fuelIsRequired": "Требуется топливо",
    "fluid.validation.installationIsRequired": "Требуется установка",
    "fluid.validation.installationIsRequired:energy": "Требуется электростанция",
    "fluid.validation.installationIsRequired:marine": "Требуется судно",
    "fluid.validation.lubricantIsRequired": "Требуется смазочный материал",
    "fluid.validation.mustBeANumber": "{value} должно быть числом.",
    "fluid.validation.oilAdedMustBeANumber": "Добавленное масло должно быть числом",
    "fluid.validation.oilLifeMustBeANumber": "Срок службы масла должен быть числом",
    "fluid.validation.ppm": "Введено неожиданное значение (мин.: {min}, макс.: {max}). Перевод % значений в промилле: 1 % = 10000 промилле.",
    "fluid.validation.sampleAggregationIsRequired": "Требуется агрегация проб",
    "fluid.validation.sampleDateInFuture": "Дата отбора пробы не может быть датой в будущем",
    "fluid.validation.sampleDateRequired": "Требуется дата отбора пробы",
    "fluid.validation.samplePointIsRequired": "Требуется точка отбора проб",
  },
};
