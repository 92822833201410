// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const error_en_US: ITranslations = {
  en_US: {
    "error.backToHomePage": "Back to home page",
    "error.contactLinkText": "contact Wärtsilä",
    "error.defaultTitle": "Something went wrong",
    "error.pleaseContact": "If the problem persists please {contactLink}.",
    "success.defaultMessage": "The operation was completed successfully.",
    "success.defaultTitle": "Success!",
  },
};
