// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const projects_en_US: ITranslations = {
  en_US: {
    "project.invoices": "Invoices",
    "project.manager": "Project manager",
    "project.milestones": "Milestones",
    "project.milestones.commissioningKickOff": "Commissioning kick-off",
    "project.milestones.contractSigning": "Contract signing",
    "project.milestones.handoverDate": "Handover from commissioning to Warranty",
    "project.milestones.projectKickOff": "Project kick-off meeting",
    "project.milestones.scheduledDate": "Scheduled date",
    "project.myProjects": "My projects",
    "project.name": "Project name",
    "project.salesOrderNr": "Sales Order #",
    "project.scope": "Project scope",
    "project.shipments": "Shipments",
    "project.shipments.ata": "ATA",
    "project.shipments.atd": "ATD",
    "project.shipments.description": "Description of shipment",
    "project.shipments.eta": "ETA",
    "project.shipments.etd": "ETD",
    "project.shipments.filterShipments": "Filter shipments",
    "project.shipments.forwardedComment": "Forwarded comment",
    "project.shipments.incoterms": "Incoterms",
    "project.shipments.notifyTo": "Notify to",
    "project.shipments.projectLogisticsComment": "Project logistics comment",
    "project.shipments.shipment": "Shipment",
    "project.shipments.shipmentDocuments": "Shipment Documents",
    "project.shipments.shipmentDocuments.document": "Document",
    "project.shipments.shipmentDocuments.fileType": "File type",
    "project.shipments.shipmentDocuments.open": "Open",
    "project.shipments.shipmentInformation": "Shipment information",
    "project.shipments.shipmentNumber": "Shipment number",
    "project.shipments.shipmentProgress": "Shipment Progress",
    "project.shipments.shipmentType": "Shipment type",
    "project.shipments.shippingMarks": "Shipping marks",
    "project.shipments.status": "Processing status",
    "project.shipments.trackingNumber": "Tracking number",
    "project.shipments.tradeFinanceComment": "Trade finance comment",
    "project.shipments.wartsilaShipmentProgress": "Wärtsilä Shipment Progress",
    "project.shipments.weight": "Total weight (kg)",
    "project.spNumber": "SP Number",
    "project.variationOrders": "Variation Orders",
    "project.variationOrders.date": "PO Date",
    "project.variationOrders.description": "Description",
    "project.variationOrders.incoterms": "Incoterms",
    "project.variationOrders.number": "PO Number",
    "project.variationOrders.value": "Net value",
  },
};
