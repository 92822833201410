import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const TrashIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>0221 Tresh can</title>
    <g id="Icon_Text" data-name="Icon Text">
      <rect x="11" y="15.01" width="26" height="4" />
      <path d="M12,21l3.27,22H32.73L36,21H12ZM24,39H18.72L16.64,25H24Z" />
      <polygon points="26 13.01 32 13.01 30 9.01 26 9.01 22 9.01 18 9.01 16 13.01 22 13.01 26 13.01" />
    </g>
  </IconBase>
);
