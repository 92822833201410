import * as React from "react";
import styled from "styled-components";

import colors from "src/design-system/Tokens/colors";
import { H2 } from "src/design-system/Tokens/typography";
import { spacer } from "src/design-system/Tokens/tokens";
import { IStyledFormHeadingProps, StyledFormHeading } from "src/design-system/Form/Form";
import { DividerLine, DividerColor } from "src/design-system/Divider/Divider";

const HeadingContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: ${(props: IStyledFormHeadingProps) => (props.flexAlign ? `flex-${props.flexAlign}` : "flex-end")};
  margin-bottom: ${spacer(2)};
`;

const Wrapper = styled.div`
  display: flex;
`;

const LinkContainer = styled.div`
  align-self: flex-end;
  display: flex;
  margin-bottom: ${(props: IStyledFormHeadingProps) => (props.tight ? spacer(2) : spacer(4))};
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const linkTriangleHeight = 30;
const linkTriangleWidth = linkTriangleHeight / Math.tan((60 * Math.PI) / 180);
const LinkTriangle = styled.svg`
  fill: ${colors.secondary.bluegray100};
  height: ${linkTriangleHeight}px; // Height must explicitly set due to IE11 svg scaling issues.
  stroke: ${colors.primary.orange};
  stroke-width: 1;
  width: ${linkTriangleWidth}px; // Width must explicitly set due to IE11 svg scaling issues.

  polygon {
    stroke-dasharray: ${Math.sqrt(linkTriangleWidth ** 2 + linkTriangleHeight ** 2)}, ${linkTriangleHeight}, 0,
      ${linkTriangleWidth};
  }
`;

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  border-top: 1px solid ${colors.primary.orange};
`;

export const GrowH2 = styled(H2)`
  flex-grow: 1;
`;

interface IHeadingProps {
  text?: string;
  tight?: boolean;
  noTopMargin?: boolean;
  dark?: boolean;
  links?: JSX.Element[];
  sideButton?: JSX.Element | null;
  className?: string;
  flexAlign?: string;
  icon?: JSX.Element | null;
}

export class Heading extends React.Component<React.PropsWithChildren<IHeadingProps>> {
  public render() {
    return (
      <Wrapper className={this.props.className}>
        <StyledFormHeading tight={this.props.tight} noTopMargin={this.props.noTopMargin}>
          <HeadingContentWrapper flexAlign={this.props.flexAlign}>
            {this.props.icon ? <IconWrapper>{this.props.icon}</IconWrapper> : null}
            {this.props.text ? <GrowH2>{this.props.text}</GrowH2> : null}
            {this.props.children}
            {this.props.sideButton}
          </HeadingContentWrapper>
          {!this.props.tight ? <DividerLine $color={DividerColor.Orange} /> : null}
        </StyledFormHeading>

        {this.props.links ? (
          <LinkContainer>
            <LinkTriangle viewBox={`0 0 ${linkTriangleWidth} ${linkTriangleHeight}`}>
              <polygon
                points={`0,${linkTriangleHeight} ${linkTriangleWidth},0 ${linkTriangleWidth},${linkTriangleHeight}`}
              />
            </LinkTriangle>
            <LinkWrapper>{this.props.links}</LinkWrapper>
          </LinkContainer>
        ) : null}
      </Wrapper>
    );
  }
}
