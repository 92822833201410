import * as React from "react";
import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";
import { defaultBorderRadius, spacers } from "src/design-system/Tokens/tokens";
import { FlexContainer } from "src/design-system/Container";

export const StyledHeader = styled(FlexContainer)`
  padding: ${spacers([0.5, 7])};
  background-color: ${(props: ThemedProps) => (props.theme.table ? props.theme.table.indicator : colors.primary.blue)};
  color: ${colors.primary.white};
  border-radius: ${defaultBorderRadius};
  min-height: 37px;
`;

const SystemHeader: React.FunctionComponent<{ title: string }> = ({ title }) => (
  <StyledHeader $centered={true}>{title}</StyledHeader>
);

export default SystemHeader;
