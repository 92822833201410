// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_fi: ITranslations = {
  fi: {
    "fluid.afterSeparator": "After Separator",
    "fluid.analysisDate": "Analyysipäivä",
    "fluid.analysisOf": "Analyysi",
    "fluid.beforeSeparator": "Before Separator",
    "fluid.categoryISOF": "ISO-F -kategoria",
    "fluid.common": "Common",
    "fluid.coolingWater": "Jäähdytysvesi",
    "fluid.differentPerEngine": "Eri arvot kaikille moottoreille",
    "fluid.draft.continueEditing": "Jatka muokkaamista",
    "fluid.draft.continueWithExistingDraft": "Jatka olemassaolevaa luonnosta",
    "fluid.draft.createNewReport": "Luo uusi nesteraportti",
    "fluid.draft.deleteDraft": "Poista luonnos",
    "fluid.draft.deleted": "Luonnos poistettu",
    "fluid.draft.deleting": "Poistetaan luonnosta...",
    "fluid.draft.deletingError": "Virhe luonnoksen poistamisessa",
    "fluid.draft.or": "TAI",
    "fluid.draft.sending": "Lähetetään luonnosta...",
    "fluid.draft.sendingError": "Virhe luonnoksen lähetyksessä",
    "fluid.draft.sent": "Luonnos lähetetty",
    "fluid.engineLife": "Moottorin ikä",
    "fluid.engines": "Moottorit",
    "fluid.enginesSampleData": "Moottorien näytteiden tiedot",
    "fluid.field.acid_number_mg_koh_g__c": "Happoluku",
    "fluid.field.aluminum_al_ppm__c": "Alumiini, Al",
    "fluid.field.aluminum_silicon_mg_kg__c": "Alumiini + Pii",
    "fluid.field.ash_m_m__c": "Tuhka",
    "fluid.field.asphaltenes_m_m__c": "Asfalteenit",
    "fluid.field.base_number_bn_mg_koh_g__c": "Perusnumero (BN)",
    "fluid.field.boron_b_ppm__c": "Boori, B",
    "fluid.field.calcium_ca_mg_kg__c": "Kalsium, Ca",
    "fluid.field.calcium_ca_ppm__c": "Kalkki, Ca",
    "fluid.field.carbon_residue_micro_method_m_m__c": "Hiilijäämä, mikro menetelmä",
    "fluid.field.ccai__c": "Laskettu hiilen aromaattisuusindeksi",
    "fluid.field.cetane_index__c": "Cetan-indeksi",
    "fluid.field.chlorides_cl_mg_l__c": "Kloridit, Cl",
    "fluid.field.chromium_cr_ppm__c": "Kromi, Cr",
    "fluid.field.copper_cu_mg_l__c": "Kupari, Cu",
    "fluid.field.copper_cu_ppm__c": "Kupari, Cu",
    "fluid.field.density_15_c_kg_m3__c": "Tiheys @15 °C",
    "fluid.field.flash_point_c__c": "Leimahduspiste",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "Rikkivety",
    "fluid.field.insolubes_w__c": "Liukenemattomat aineet",
    "fluid.field.iron_fe_mg_kg__c": "Rauta, Fe",
    "fluid.field.iron_fe_mg_l__c": "Rauta, Fe",
    "fluid.field.iron_fe_ppm__c": "Raua, Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "Kinemaattinen viskositeetti @40 °C",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "Kinemaattinen viskositeetti @50 °C",
    "fluid.field.lead_pb_mg_kg__c": "Lyijy, Pb",
    "fluid.field.lead_pb_ppm__c": "Lyijy, Pb",
    "fluid.field.lower_heating_value_mj_kg__c": "Alempi lämmitysarvo",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "Voitelu, korjattu kuluminen (wsd 1.4) @ 60 °C",
    "fluid.field.magnesium_mg_mg_kg__c": "Magnesium, Mg",
    "fluid.field.molybenum_mo_ppm__c": "Molybdeeni, Mo",
    "fluid.field.nickel_ni_mg_kg__c": "Nikkeli, Ni",
    "fluid.field.nickel_ni_ppm__c": "Nikkeli, Ni",
    "fluid.field.nitrate_no3_mg_l__c": "Nitraatti, NO₃",
    "fluid.field.nitration_abs_cm__c": "Nitrauslaatu",
    "fluid.field.nitrite_no2_mg_l__c": "Nitriitti, NO₂",
    "fluid.field.oxidation_abs_cm__c": "Hapetus",
    "fluid.field.oxidation_stability_g_m3__c": "Hapettavuuden stabiliteetti",
    "fluid.field.ph__c": "Happamuus (pH)",
    "fluid.field.phosphorus_p_mg_kg__c": "Fosfori, P",
    "fluid.field.phosphorus_p_ppm__c": "Fosfori, P",
    "fluid.field.pour_point_c__c": "Jähmepiste",
    "fluid.field.silicon_si_ppm__c": "Silikoni, Si",
    "fluid.field.sodium_na_mg_kg__c": "Natrium, Na",
    "fluid.field.sodium_na_ppm__c": "Natrium, Na",
    "fluid.field.sulphates_so4_mg_l__c": "Sulfaatit, SO₄",
    "fluid.field.sulphur_m_m__c": "Rikki",
    "fluid.field.tin_sn_ppm__c": "Tina, Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "Kokonaisprosenttimäärä (TAN)",
    "fluid.field.total_hardness_ca_mg_dh__c": "Kokonaiskovuus (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "Kokonaissedimentti",
    "fluid.field.vanadium_v_mg_kg__c": "Vanadiini, V",
    "fluid.field.vanadium_v_ppm__c": "Vanadiini, V",
    "fluid.field.viscosity_100_c_cst__c": "Viskositeetti @100 °C",
    "fluid.field.viscosity_40_c_cst__c": "Viskositeetti @40 °C",
    "fluid.field.water_v_v__c": "Vesi",
    "fluid.field.water_vol_or_w__c": "Vesi",
    "fluid.field.zinc_zn_mg_kg__c": "Sinkki, Zn",
    "fluid.field.zinc_zn_ppm__c": "Sinkki, Zn",
    "fluid.fuel": "Polttoaine",
    "fluid.gas": "Gas",
    "fluid.general": "Yleiset",
    "fluid.generalInformation": "Yleiset tiedot",
    "fluid.header.elemConcs": "Alkuainepitoisuudet",
    "fluid.header.fuelProps": "Polttoaineen ominaisuudet",
    "fluid.header.oilProps": "Öljyn ominaisuudet",
    "fluid.htCircuit": "HT circuit",
    "fluid.inputValues": "Syötearvt",
    "fluid.lastData": "Viimeisin data",
    "fluid.lowSulphurFuels": "Vähärikkiset polttoaineet",
    "fluid.ltCircuit": "LT circuit",
    "fluid.lubeOil": "Voiteluöljy",
    "fluid.lubricant": "Voiteluaine",
    "fluid.notAvailable": "Ei saatavilla",
    "fluid.oilAdded": "Öljyä lisätty",
    "fluid.oilLife": "Öljyn ikä",
    "fluid.operationOn": "Toiminta polttoaineella",
    "fluid.phase.reportData": "Raportin tiedot",
    "fluid.phase.sampleData": "Näytteen tiedot",
    "fluid.phase.scopeSelection": "Alueen valinta",
    "fluid.phase.summary": "Yhteenveto",
    "fluid.product": "Tuote",
    "fluid.reportData": "Raportin tiedot",
    "fluid.sameForAllEngines": "Samat arvot kaikille moottoreille",
    "fluid.sampleAggregation": "Näytteen kooste",
    "fluid.sampleDate": "Näytteenottopäivä",
    "fluid.samplePoint": "Näytepiste",
    "fluid.scopeOfReport": "Raportin alue",
    "fluid.sending": "Lähetetään nesteraporttia...",
    "fluid.sendingError": "Virhe nesteraportin lähetyksessä",
    "fluid.sent": "Nesteraportti lähetetty",
    "fluid.timeframe": "Ajanjakso",
    "fluid.unit.abscm": "Abs/cm fi",
    "fluid.unit.cst": "cSt fi",
    "fluid.unit.degreec": "°C fi",
    "fluid.unit.degreedh": "°dH fi",
    "fluid.unit.gm3": "g/m³ fi",
    "fluid.unit.kgm3": "kg/m³ fi",
    "fluid.unit.mgkg": "mg/kg fi",
    "fluid.unit.mgkohg": "mg KOH / g fi",
    "fluid.unit.mgl": "mg/l fi",
    "fluid.unit.mjkg": "MJ/kg fi",
    "fluid.unit.mm2s": "mm²/s fi",
    "fluid.unit.none": "fi",
    "fluid.unit.pmm": "% m/m fi",
    "fluid.unit.ppm": "ppm fi",
    "fluid.unit.pvv": "% v/v fi",
    "fluid.unit.um": "µm fi",
    "fluid.unit.volporwp": "vol-% or w-% fi",
    "fluid.unit.wp": "w-% fi",
    "fluid.unspecified": "Määrittelemätön",
    "fluid.uptime": "Käyntitunnit",
    "fluid.uptime.availability": "Saatavuus",
    "fluid.uptime.availabilityFormula": "(käynnissä + levossa) / (käynnissä + levossa + pois päältä + pysäytetty)",
    "fluid.uptime.contractLimit": "Sopimuksen raja",
    "fluid.uptime.daily": "Päivä",
    "fluid.uptime.engineAvailability": "Moottorin saatavuus",
    "fluid.uptime.monthly": "Kuukausi",
    "fluid.uptime.noAvailability": "Saatavuusdataa ei ole saatavilla tälle ajanjaksolle.",
    "fluid.uptime.noData": "Ei dataa",
    "fluid.uptime.noRunningHours": "Ajotuntidataa ei ole saatavilla tälle ajanjaksolle.",
    "fluid.uptime.period": "Ajanjakso",
    "fluid.uptime.report": "Raportti",
    "fluid.uptime.running": "Käynnissä",
    "fluid.uptime.shutDown": "Pois päältä",
    "fluid.uptime.standby": "Levossa",
    "fluid.uptime.stop": "Pysäytetty",
    "fluid.uptime.sum": "SUMMA",
    "fluid.uptime.yearly": "Vuosi",
    "fluid.validation.analysisDateEarlierThanSampleDate": "Analyysipäivä ei voi olla aiempi kuin näytteenottopäivä",
    "fluid.validation.analysisDateInFuture": "Analyysipäivä ei voi olla tulevaisuudesssa",
    "fluid.validation.analysisDateRequired": "Analyysipäivä vaaditaan",
    "fluid.validation.categoryISOFIsRequired": "ISO-F -kategoria on pakollinen",
    "fluid.validation.common": "Yllättävä arvo syötetty (min: {min}, max: {max}).",
    "fluid.validation.engineIsRequired": "Moottori on pakollinen",
    "fluid.validation.engineLifeMustBeANumber": "Moottorin iän pitää olla numero",
    "fluid.validation.fuelIsRequired": "Polttoaine vaaditaan",
    "fluid.validation.installationIsRequired": "Installaatio on pakollinen",
    "fluid.validation.installationIsRequired:energy": "Voimala on pakollinen",
    "fluid.validation.installationIsRequired:marine": "Alus on pakollinen",
    "fluid.validation.lubricantIsRequired": "Voiteluaine on pakollinen",
    "fluid.validation.mustBeANumber": "{value} on oltava numero.",
    "fluid.validation.oilAdedMustBeANumber": "Lisätyn öljyn pitää olla numero",
    "fluid.validation.oilLifeMustBeANumber": "Öljyn iän pitää olla numero",
    "fluid.validation.ppm": "Yllättävä arvo syötetty (min: {min}, max: {max}). Konversio % arvoista ppm:ään: 1% = 10000 ppm.",
    "fluid.validation.sampleAggregationIsRequired": "Näytteiden kooste vaaditaan",
    "fluid.validation.sampleDateInFuture": "Näytteenottopäivä ei voi olla tulevaisuudessa",
    "fluid.validation.sampleDateRequired": "Näytteenottopäivä vaaditaan",
    "fluid.validation.samplePointIsRequired": "Näytepiste on pakollinen",
  },
};
