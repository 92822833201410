import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";

export const ButtonLink = styled.button`
  margin: 0;
  padding: 0;
  border: 0;

  background: transparent;
  font-size: inherit;
  color: ${colors.primary.white};
  text-transform: uppercase;

  cursor: pointer;

  :focus {
    outline: none;
    background: rgba(255, 255, 255, 0.05);
  }
`;

export const ButtonLinkMenu = styled(ButtonLink)`
  display: block;
  padding: 0.75em 0;
`;
