import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const WarningRedIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props} viewBox="0 0 40 40">
    <title>warning</title>
    <path fill="#dc3127" d="M20.18,0.36a20,20,0,1,0,20,20,20,20,0,0,0-20-20" transform="translate(-0.18 -0.36)" />
    <rect fill="#fff" x="17" y="9" width="6" height="14" />
    <path fill="#fff" d="M20.18,25.36a3,3,0,1,0,3,3,3,3,0,0,0-3-3" transform="translate(-0.18 -0.36)" />
  </IconBase>
);
