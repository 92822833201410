import * as React from "react";

import { ArrowLeftIcon } from "./ArrowLeftIcon";
import { IIconBase } from "./IconBase";
import styled from "styled-components";

const UpIcon = styled(ArrowLeftIcon)`
  transform: rotate(90deg);
`;

export const ArrowUpIcon = ({ ...props }: IIconBase) => <UpIcon {...props} />;
