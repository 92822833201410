import { Field } from "formik";
import * as React from "react";
import { FormDialog } from "src/components/Dialog";
import { ModalSize } from "src/components/Dialog/Modal";
import { StyledFormField } from "src/design-system/Form/Form";
import { ISelectObject, Select } from "src/design-system/Select/Select";
import { TextInput } from "src/design-system/TextInput";
import { P } from "src/design-system/Tokens/typography";
import { departmentList } from "src/util/departments";
import { translateString } from "src/util/localization";
import { IErrors } from "src/util/validators";
import styled from "styled-components";
import { LocalizedString } from "../Localization";

interface IConfirmInformationDialogProps {
  isOpen: boolean;
  onConfirm: (title: string, department: string) => void;
  onCancel: () => void;
  initialInfo: { title: string; department: string };
  isUpdateRequired: boolean;
}

interface IConfirmInformationForm {
  title: string;
  department: string;
}

const FieldWrapper = styled(StyledFormField)`
  margin-top: 24px;
`;

export class ConfirmInformationDialog extends React.Component<IConfirmInformationDialogProps> {
  public render() {
    return (
      <FormDialog<IConfirmInformationForm>
        isDialogOpen={this.props.isOpen}
        onCancel={this.props.onCancel}
        title={this.props.isUpdateRequired ? translateString("user.updateYourInformation") : translateString("user.confirmYourInformation")}
        submitButtonTitle={translateString("confirmAndContinue")}
        cancelButtonTitle={translateString("askMeLater")}
        size={ModalSize.Small}
        initialValues={{
          title: this.props.initialInfo.title || "",
          department: this.props.initialInfo.department || "",
        }}
        validate={() => ({} as IErrors)}
        onSubmit={(values) => {
          this.props.onConfirm(values.title, values.department);
        }}
      >
        <>
          <P>
            <LocalizedString id="user.helpUsKeepContentRelevant" />
          </P>
          <FieldWrapper>
            <StyledFormField>
              <Field
                name="department"
                label={translateString("user.department")}
                component={Select}
                options={departmentList}
                getOptionLabel={(option: ISelectObject<string>) => option.item}
                getOptionValue={(option: ISelectObject<string>) => option.item}
                maxMenuHeight={100}
              />
            </StyledFormField>
          </FieldWrapper>
          <FieldWrapper>
            <StyledFormField>
              <Field
                name="title"
                label={translateString("user.jobTitle")}
                component={TextInput}
              />
            </StyledFormField>
          </FieldWrapper>
        </>
      </FormDialog>
    );
  }
}
