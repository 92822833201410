import { FlexContainer } from "src/design-system/Container";
import { H2 } from "src/design-system/Tokens/typography";
import { WarningIcon } from "src/icons";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";

export const Wrapper = styled.div`
  & ${H2} {
    margin-top: 0;
  }
`;

export const Link = styled.a`
  color: ${colors.primary.blue};
`;

export const FlexContainerWithBorder = styled(FlexContainer)`
  border: 1px solid ${colors.primary.gray};
`;

export const NewReportRowWrapper = styled.div<{ discarded?: boolean }>`
  display: flex;
  align-items: center;
  padding: 15px;

  opacity: ${({ discarded }) => (discarded ? 0.5 : 1)};
  filter: ${({ discarded }) => (discarded ? "grayscale(100%)" : "none")};

  > span {
    margin-left: ${spacer(1)};
  }
`;
export const FlexWrapper = styled.div`
  display: flex;
  button {
    margin-right: 10px;
  }
`;

export const NoRequestsFoundIndicator = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const OrderTypeContainer = styled.div`
  text-transform: uppercase;
  color: ${colors.primary.blue};
`;

export const Dist = styled.div`
  display: flex;
  align-items: center;
`;

export const WIcon = styled(WarningIcon)`
  margin-right: 10px;
`;

export const NotificationHistoryLink = styled.span.attrs({ tabIndex: 0 })`
  font-weight: bold;
  color: ${colors.primary.blue};
  cursor: pointer;

  :before {
    content: " ";
    white-space: pre;
  }
`;

export const NotificationSubscriptionLink = styled.span.attrs({ tabIndex: 0 })`
  color: ${colors.primary.blue};
  cursor: pointer;
  text-decoration: underline;
`;
