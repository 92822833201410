import * as React from "react";

import { FormatOptionLabelMeta, Props as ReactSelectProps } from "react-select";
import styled, { css } from "styled-components";

import { ErrorComponent } from "src/design-system/Error";
import { FormikProps } from "formik";
import { P } from "src/design-system/Tokens/typography";
import { SelectField } from "@wartsila/ui-kit";
import { ThemedProps } from "../Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { getObjectProperty } from "src/util/helpers";
import { inputTokens } from "../Tokens/tokens";

export enum DropdownStyle {
  FullWidth, // 100%
  Toolbar, // sorting
  Transparent,
  Default,
}

export interface ISelectStyleProps {
  dropdownStyle?: DropdownStyle;
  toolbarStyleWidth?: string;
}

export interface IFieldProps<V = any> {
  field?: {
    name: string;
  };
  form?: FormikProps<V>;
}

type ValueType<T> = T;

export interface GroupedOption<T> {
  readonly label: string;
  readonly options: GroupedOptionItem<T>[];
}

export interface GroupedOptionItem<T> {
  readonly label: string;
  readonly value: T;
}

export interface ISelectBaseProps<T> {
  filter?: (option: T) => boolean; // for custom options filtering
  isOptionDisabled?: (option: T) => boolean;
  filterOption?: ((option: ReactSelectProps["FilterOptionOption"], inputValue: string) => boolean) | null;
  onBlur?: () => void;
  // value and onChange aren't under one object, because user might use the onChange prop when using formik and in that situation, value is coming via formik
  value?: ValueType<T> | Array<ValueType<T>>;
  getOptionLabel?: (option: ISelectObject<T>) => string;
  getOptionValue?: (option: ISelectObject<T>) => string;
  isClearable?: boolean;
  isSearchable?: boolean;
  itemsToShow?: number;
  dropdownStyle?: DropdownStyle;
  formatOptionLabel?: (option: any, labelMeta: FormatOptionLabelMeta<any, boolean>) => React.ReactNode; // formats value AND label
  placeholder?: string;
  name?: string;
  theme?: string;
  hideOptions?: boolean;
  isMulti?: boolean;
  openMenuOnFocus?: boolean;
  isLoading?: boolean;
  components?: any;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  maxMenuHeight?: number;
  isGrouped?: boolean;
}

export interface ISelectProps<T> extends ISelectBaseProps<T> {
  id?: string;
  label?: string;
  defaultValue?: ISelectObject<ValueType<T>> | ISelectObject<ValueType<T>>[];
  disabled?: boolean;
  invalid?: boolean;
  options?: T[];
  onChange?: (itemObject: T | null | T[] | [] | GroupedOptionItem<T>) => void;
  optionsFn?: () => Array<ISelectObject<T>>;
  className?: string;
  setRef?: (input: HTMLElement | null) => void;
  toolbarStyleWidth?: string;
}

export interface ISelectObject<T> {
  disabled: boolean;
  item: T;
}

const DropdownStyles = {
  Default: css`
    width: ${inputTokens.inputDropdownWidth};
  `,
  FullWidth: css`
    width: 100%;
  `,
  Toolbar: css`
    width: ${inputTokens.inputToolbarWidth};
  `,
  Transparent: css`
    .react-select__control {
      background-color: transparent;
      border-color: transparent;
    }

    .react-select__single-value {
      color: ${(props: ThemedProps) => props.theme.text};
      text-align: right;
    }

    .react-select__indicator,
    .react-select__indicator:hover {
      color: ${(props: ThemedProps) => props.theme.text};
    }
  `,
};

const StyledAutoComplete = styled(SelectField)`
  ${(props: ISelectStyleProps) => {
    switch (props.dropdownStyle) {
      case DropdownStyle.FullWidth:
        return DropdownStyles.FullWidth;
      case DropdownStyle.Toolbar:
        return DropdownStyles.Toolbar;
      case DropdownStyle.Transparent:
        return DropdownStyles.Transparent;
      default:
        return DropdownStyles.Default;
    }
  }}
`;

export class Select<T> extends React.Component<ISelectProps<T> & IFieldProps> {
  public componentDidUpdate() {
    const { form, field }: IFieldProps = this.props;
    if (form && field) {
      const fieldValueFromFieldName = getObjectProperty(form.values, field.name);
      if (fieldValueFromFieldName === undefined) {
        form.setFieldValue(field.name, "");
      }
    }
  }

  public render() {
    const {
      isGrouped = false,
      isClearable = false,
      isSearchable = true,
      isLoading = false,
      isMulti = false,
    }: ISelectProps<T> = this.props;
    const { onChangeFn, onBlurFn, dropdownValue, errorMessage } = this.getCallbacks();
    const selectOptions = isGrouped ? this.props.options : this.getOptions();

    return (
      <>
        {this.props.label !== undefined && <P>{this.props.label}</P>}
        <StyledAutoComplete
          styles={{
            option: (provided) => ({
              ...provided,
              color: colors.primary.black,
            }),
            multiValueRemove: (provided) => ({
              ...provided,
              color: colors.primary.black,
            }),
          }}
          onBlur={onBlurFn}
          isMulti={isMulti}
          id={this.props.id}
          isLoading={isLoading}
          name={this.props.name}
          options={selectOptions}
          isClearable={isClearable}
          isSearchable={isSearchable}
          invalid={this.props.invalid}
          value={dropdownValue || null}
          disabled={this.props.disabled}
          className={this.props.className}
          components={this.props.components}
          placeholder={this.props.placeholder}
          filterOption={this.props.filterOption}
          defaultValue={this.props.defaultValue}
          dropdownStyle={this.props.dropdownStyle}
          maxMenuHeight={this.props.maxMenuHeight}
          getOptionLabel={this.props.getOptionLabel}
          getOptionValue={this.props.getOptionValue}
          openMenuOnFocus={this.props.openMenuOnFocus}
          isOptionDisabled={this.props.isOptionDisabled}
          closeMenuOnSelect={this.props.closeMenuOnSelect}
          formatOptionLabel={this.props.formatOptionLabel}
          hideSelectedOptions={this.props.hideSelectedOptions}
          onChange={isGrouped ? this.props.onChange : onChangeFn}
        />
        {errorMessage}
      </>
    );
  }

  private readonly getOptions = (): Array<ISelectObject<T>> => {
    const { optionsFn, options, hideOptions, filter } = this.props;
    // User can define its own options function which will be used by dropdown. This is used in DropdownApiResource when this component is used there
    if (optionsFn) {
      return optionsFn();
    } else {
      return (!hideOptions && options && options.filter(filter || (() => true)).map(this.getSingleOption)) || [];
    }
  };

  private readonly getCallbacks = () => {
    const { form, field } = this.props;
    if (form && field) {
      return this.getFormCallbacks(form, field);
    } else {
      // If there is no Formik then use user's defined functions
      return this.getNonFormCallbacks();
    }
  };

  private readonly getSingleOption = (item: T) => {
    const { isOptionDisabled } = this.props;
    return {
      // if the 'isOptionDisabled' function is not specified, then all options are enabled
      disabled: isOptionDisabled ? isOptionDisabled(item) : false,
      item,
    };
  };

  private readonly getFormCallbacks = (form: FormikProps<T>, field: { name: string }) => {
    const handleArrayChange = (valueItem: Array<ISelectObject<T>>) => {
      const fieldValue = this.getValuesFromOptions(valueItem);
      if (onChange) {
        onChange(fieldValue);
      }
      return fieldValue;
    };
    const handleNonArrayChange = (valueItem: ISelectObject<T> | null) => {
      const fieldValue = valueItem === null || valueItem.item === undefined ? null : valueItem.item;
      if (onChange) {
        onChange(fieldValue);
      }
      return fieldValue;
    };

    const { onChange, hideOptions } = this.props;
    const onChangeFn = (valueItem: ISelectObject<T> | Array<ISelectObject<T>> | null) => {
      let fieldValue: T | null | T[];
      if (valueItem instanceof Array) {
        fieldValue = handleArrayChange(valueItem);
      } else {
        fieldValue = handleNonArrayChange(valueItem);
      }
      form.setFieldValue(field.name, fieldValue);
    };

    const onBlurFn = () => form.setFieldTouched(field.name, true);
    const formValue = getObjectProperty(form.values, field.name);
    const fieldTouched = getObjectProperty(form.touched, field.name);
    const fieldErrors = getObjectProperty(form.errors, field.name);
    const value = formValue ? formValue : "";

    let dropdownValue: (undefined | ISelectObject<T>)[] | undefined | ISelectObject<T>;
    if (value instanceof Array) {
      dropdownValue = value.map(this.getSingleValueKey);
    } else {
      dropdownValue = this.getSingleValueKey(value);
    }

    const errorMessage = !hideOptions
      ? fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>
      : null;

    return {
      onChangeFn,
      onBlurFn,
      dropdownValue,
      errorMessage,
    };
  };

  private readonly getSingleValueKey = (val: T | undefined) => {
    if (!val) {
      return undefined;
    }
    return this.getSingleOption(val);
  };

  private readonly getNonFormCallbacks = () => {
    const { onChange, onBlur, value } = this.props;
    const onChangeFn = (valueItem: ISelectObject<T> | ISelectObject<T>[] | null) => {
      if (onChange) {
        let val;
        if (valueItem instanceof Array) {
          val = valueItem.map((item) => item.item);
        } else {
          val = valueItem === null || valueItem.item === undefined ? null : valueItem.item;
        }
        onChange(val);
      }
    };

    let dropdownValue: (undefined | ISelectObject<T>)[] | undefined | ISelectObject<T>;
    if (value instanceof Array) {
      dropdownValue = value.map(this.getSingleValueKey);
    } else {
      dropdownValue = this.getSingleValueKey(value);
    }
    return {
      onChangeFn,
      onBlurFn: onBlur,
      dropdownValue,
      errorMessage: null,
    };
  };

  private readonly getValuesFromOptions = (valueItem: Array<ISelectObject<T>> | null) => {
    const values: T[] = [];
    if (valueItem === null) {
      return values;
    } else {
      valueItem.forEach((element) => {
        if (element.item !== undefined && element.item !== null) {
          values.push(element.item);
        }
      });
    }
    return values;
  };
}
