import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const TechnicalIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Technical</title>
    <path d="M34.42,29.49L36,27.86,33.14,25l-1.63,1.63a8.14,8.14,0,0,0-2.11-.87v-2.3h-4.1v2.3a8.14,8.14,0,0,0-2.11.87L21.58,25l-2.9,2.9,1.63,1.63a8.14,8.14,0,0,0-.87,2.11h-2.3v4.1h2.3a8.14,8.14,0,0,0,.87,2.11l-1.63,1.63,2.9,2.9L23.2,40.7a8.14,8.14,0,0,0,2.11.87v2.3h4.1v-2.3a8.14,8.14,0,0,0,2.11-.87l1.63,1.63,2.9-2.9L34.42,37.8a8.14,8.14,0,0,0,.87-2.11h2.3V31.6h-2.3A8.14,8.14,0,0,0,34.42,29.49Zm-3,4.37a4.06,4.06,0,0,1-1,2.52l-0.29.29a4.06,4.06,0,0,1-2.52,1H27.15a4.06,4.06,0,0,1-2.53-1.05l-0.28-.28a4.07,4.07,0,0,1-1.05-2.53V33.44a4.07,4.07,0,0,1,1.06-2.54l0.28-.28a4.06,4.06,0,0,1,2.53-1.05h0.43a4.06,4.06,0,0,1,2.53,1.05l0.29,0.29a4.06,4.06,0,0,1,1,2.52v0.44Z" />
    <polygon points="37.48 10.11 34.16 13.52 29.93 9.25 33.37 5.77 32.54 4 29.12 4 26 7.54 26 13.52 9 30.6 9 33.16 10.34 35 12.9 35 18.26 29.58 16.15 27.43 21.41 22.15 23.53 24.36 29.98 18 35.96 18 39 14.38 39 10.96 37.48 10.11" />
  </IconBase>
);
