import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const EnginesIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Engines</title>
    <rect x="20" y="36" width="14" height="4" />
    <rect x="20" y="18" width="14" height="16" />
    <path d="M16,8H12l-2,2H6a2,2,0,0,0-2,2V24H8v3h6V40h4V10A2,2,0,0,0,16,8ZM10,18a3,3,0,1,1,3-3A3,3,0,0,1,10,18Z" />
    <rect x="42" y="22" width="2" height="14" />
    <rect x="36" y="12" width="4" height="24" />
    <rect x="20" y="12" width="14" height="4" />
  </IconBase>
);
