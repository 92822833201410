// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_fi: ITranslations = {
  fi: {
    "addAttachment": "Lisää liitetiedosto",
    "addFilter": "Lisää rajaus",
    "addInstallation": "Lisää installaatio",
    "addInstallation.nameIsRequired": "Nimi on pakollinen",
    "addInstallation:energy": "Lisää voimala",
    "addInstallation:marine": "Lisää alus",
    "applyFilter": "Aseta rajaus",
    "attachments": "Liitteet",
    "auth.continueToUseOS": "Jatka Wärtsilä Onlinen käyttöä",
    "auth.logout": "Kirjaudu ulos",
    "auth.readOnlyMode": "Vain luku -tila",
    "auth.redirectingToLogin": "Uudelleenohjataan kirjautumiseen...",
    "auth.sessionExpired": "Kirjautumisen aikaraja täyttyi. Uudelleenohjataan sisäänkirjautumiseen.",
    "auth.sessionWillExpire": "Sisäänkirjautumisesi vanhenee kohta",
    "button.cancel": "Peruuta",
    "button.close": "Sulje",
    "button.next": "Seuraava",
    "button.previous": "Edellinen",
    "button.send": "Lähetä",
    "cancel": "Peruuta",
    "change": "Muuta",
    "changePasswordDialog.success": "Ohjesähköposti lähetetty onnistuneesti.",
    "chooseDocumentType": "Valitse dokumenttityyppi",
    "chooseFilter": "Valitse rajaus…",
    "clearFilters": "Tyhjennä rajaukset",
    "comment.commentIsRequired": "Kommentti on pakollinen",
    "comment.postComment": "Lähetä kommentti",
    "comment.replyComment": "Vastaa",
    "comment.writeYourComment": "Kirjoita kommenttisi",
    "comment.writeYourReply": "Kirjoita vastauksesi",
    "comment.you": "Sinä",
    "commenting": "Kommentointi",
    "contact.contactWartsilaCustomerSupport": "Ota yhteys Wärtsilän asiakastukeen",
    "contact.subject": "Otsikko",
    "contact.subjectIsRequired": "Otsikko on pakolinen",
    "contact.yourMessage": "Viestisi",
    "contactCustomerSupport": "Ota yhteyttä asiakaspalveluun",
    "customerSupportTicket": "Tukipyyntö",
    "date": "Päivämäärä",
    "delete": "Poista",
    "didYouMean": "Tarkoititko:",
    "download.downloadReady": "Lataus valmis!",
    "download.equipmentNotFound": "Laitteelle ei löydetty artikkelia",
    "download.error": "Latauksessa ilmeni ongelma",
    "download.preparingDownload": "Valmistellaan latausta",
    "draft.confirmDelete": "Oletko varma, että haluat poistaa tämän luonnoksen?",
    "draft.confirmDeleteTitle": "Poistetaanko luonnos?",
    "draft.continueEditing": "Jatka muokkaamista",
    "draft.delete": "Poista luonnos",
    "edit": "Muokkaa",
    "engine": "Moottori",
    "equipment": "Laite",
    "equipment.configuration": "Konfiguraatio",
    "equipment.nickname": "Tuoten lempinimi",
    "equipment.performanceData": "Suoriutumistieto",
    "equipment.title": "Otsikko",
    "equipmentNotFound": "Laitteita ei löydy",
    "fileUpload.attachmentRejected": "Liite hylätty. Tarkista tiedoston lähetyksen rajat.",
    "fileUpload.noContactPerson": "Yhteyshenkilöä ei ole. Viestiä ei voi lähettää!",
    "fileUpload.uploadAborted": "Tiedoston lähetys keskeytettiin",
    "fileUpload.uploadFailed": "Tiedoston lähetys epäonnistui",
    "fileUpload.userHasReadOnlyAccess": "Käyttäjällä ei ole kirjoitusoikeutta",
    "filterRequests": "Rajaa pyyntöjä",
    "installation": "Installaatio",
    "installation.classificationSociety": "Luokitusjärjestö",
    "installation.deliveryDate": "Toimituspäivä",
    "installation.description": "Kuvaus",
    "installation.imo": "IMO",
    "installation.inspectionDate": "Tarkastuspäivä",
    "installation.name": "Nimi",
    "installation.nextPlannedMaintenance": "Seuraava suunniteltu huolto",
    "installation.type": "Tyyppi",
    "installation:energy": "Voimala",
    "installation:marine": "Alus",
    "installations": "Instalaatiot",
    "installations:energy": "Voimalat",
    "installations:marine": "Alukset",
    "language": "Kieli",
    "language.languageNotFound": "Kielikoodia ei löytynyt",
    "languageDisclaimer": "Tämän pyynnön lähettäminen englanniksi nopeuttaa käsittelyprosessia",
    "loading": "Ladataan...",
    "logout": "Kirjaudu ulos",
    "manufacturerSerialNumber": "Valmistajan sarjanumero",
    "month.april": "huhtikuu",
    "month.august": "elokuu",
    "month.december": "joulukuu",
    "month.february": "helmikuu",
    "month.january": "tammikuu",
    "month.july": "heinäkuu",
    "month.june": "kesäkuu",
    "month.march": "maaliskuu",
    "month.may": "toukokuu",
    "month.november": "marraskuu",
    "month.october": "lokakuu",
    "month.september": "syyskuu",
    "myFleet": "Minun installaationi",
    "myFleet:marine": "Minun laivastoni",
    "myInstallations": "Minun instalaationi",
    "myInstallations:energy": "Minun voimalani",
    "myInstallations:marine": "Minun alukseni",
    "myProfile": "Minun profiilini",
    "nickname": "Lempinimi",
    "no": "Ei",
    "ok": "OK",
    "optionalFieldTitle": "vapaaehtoinen",
    "phone": "Puhelin",
    "pleaseAddInstallation": "Valitsethan installaation!",
    "pleaseAddInstallation:energy": "Valitsethan voimalan!",
    "pleaseAddInstallation:marine": "Valitsethan aluksen!",
    "productNumber": "Tuotenumero",
    "productType": "Tuotetyyppi",
    "profile.updating": "Päivitetään profiilia…",
    "removeInstallation": "Poista instalaatio",
    "removeInstallation:energy": "Poista voimala",
    "removeInstallation:marine": "Poista alus",
    "request.savingDraft": "Tallennetaan luonnosta...",
    "request.send": "Lähetä pyyntö",
    "request.sending": "Lähetetään pyyntöä...",
    "request.sendingError": "Virhe pyynnön lähetyksessä",
    "request.sent": "Pyyntö lähetetty.",
    "routes.contactContractManager": "Ota yhteyttä sopimusmanageriin",
    "routes.contracts": "Sopimukset",
    "routes.download": "Lataukset",
    "routes.equipment": "Laitteet",
    "routes.fluidReportDraft": "Uusi nesteraportti",
    "routes.installation": "Installaatio",
    "routes.installationSelection": "Spare Parts: Valitse installaatio",
    "routes.installationSelection:energy": "Varaosat: Valitse voimalaitos",
    "routes.installationSelection:marine": "Varaosat: Valitse alus",
    "routes.newFluidReport": "Uusi nesteraportti",
    "routes.newRequest": "Uusi pyyntö",
    "routes.partsCatalogue": "Varaosat: Selailla",
    "routes.performance": "Suoriutumistieto",
    "routes.reports": "Raportit",
    "routes.requests": "Pyynnöt",
    "routes.runningHours": "Ajotunnit",
    "routes.spareParts": "Varaosat",
    "routes.technicalDocuments": "Tekninen dokumentaatio",
    "routes.users": "Käyttäjät",
    "runningHours": "Ajotunnit",
    "runningHours.confirmDialogMessageHigh": "Syötit ajotunneiksi arvon, joka on suurempi kuin aikaisemmasta muutoksesta kulunut aika. Haluatko päivittää tiedot?",
    "runningHours.confirmDialogMessageLow": "Syötit ajotunneiksi arvon, joka on pienempi nykyinen arvo. Voit tehdä pyynnön ajotuntien muuttamisesta.",
    "runningHours.invalidValue": "Virheellinen arvo",
    "runningHours.lowValue": "Syötit arvon, joka on pienempi kuin edellinen arvo.",
    "runningHours.makeRequest": "Uudet ajotunnit",
    "runningHours.newRunningHours": "Uudet ajotunnit",
    "runningHours.reasonForLowerValue": "Syy pienemmälle lukemalle",
    "runningHours.reasonForRequest": "Pyynnön syy",
    "runningHours.reasonIsRequired": "Syy on pakollinen",
    "runningHours.updateRunningHours": "Päivitä ajotunnit",
    "runningHours.youCanMakeRequest": "Voit tehdä pyynnön ajotuntien muutokseen.",
    "runningHoursUpdated": "Ajotunnit päivitetty",
    "save": "Tallenna",
    "saveVisibilitySelection": "Tallenna näkyvyysvalinnat",
    "searchInsteadFor": "Hae sen sijaan",
    "searchPlaceholder": "Etsi...",
    "select": "Valitse",
    "select.sortPlaceholder": "Järjestä",
    "selectInstallation": "Valitse installaatio",
    "selectInstallation:energy": "Valitse voimala",
    "selectInstallation:marine": "Valitse alus",
    "send": "Lähetä",
    "sendingFileFailed": "Epäonnistui",
    "services": "Palvelut",
    "showMore": "Näytä lisää",
    "showingResultsFor": "Näytetään hakutulokset hakuehdolle",
    "sorting.createdByAZ": "Tekijä (A-Ö)",
    "sorting.emailShortAZ": "Sähköposti (A-Ö)",
    "sorting.equipmentAZ": "Laite (A-Ö)",
    "sorting.installationAZ": "Installaatio (A-Ö)",
    "sorting.modified": "Muokattu",
    "sorting.nameAZ": "Nimi (A-Ö)",
    "sorting.newestFirst": "Uusin ensin",
    "sorting.oldestFirst": "Vanhin ensin",
    "sorting.requestTypeAZ": "Pyyntötyyppi (A-Ö)",
    "sorting.statusAZ": "Tila (A-Ö)",
    "sorting.subjectAZ": "Aihe (A-Ö)",
    "sorting.titleAZ": "Titteli (A-Ö)",
    "status": "Tila",
    "techRequest": "Tekninen pyyntö",
    "technicalDocumentation": "Tekninen dokumentaatio",
    "type": "Tyypi",
    "typeToFilter": "Kirjoita rajataksesi...",
    "unit.hours": "tuntia",
    "unit.litres": "litraa",
    "unknown": "Tuntematon",
    "update": "Päivitä",
    "users": "Käyttäjät",
    "validation.characterLimit": "Merkkiraja on {characters} merkkiä",
    "validation.incorrectPhoneNumber": "Puhelinumeron tulee alkaa +-merkillä ja sisältää 9 - 14 numeroa",
    "viewContracts": "Näytä sopimukset",
    "visibleInstallations": "Näkyvät installaatiot",
    "visibleInstallations.changeAction": "voit  tehdä pyynnön aluksen lisäyksestä tai poistosta",
    "visibleInstallations.changeLabel": "Jos listaan pitää tehdä muutoksia,",
    "visibleInstallations:energy": "Näkyvät voimalat",
    "visibleInstallations:marine": "Näkyvät alukset",
    "warrantyClaim": "Takuuvaatimus",
    "warrantyEndDate": "Takuun loppupäivä",
    "yes": "Kyllä",
  },
};
