import { IUserInfo } from "online-services-types";
import * as React from "react";
import { State } from "react-burger-menu";
import { AccountSelector } from "src/components/AccountSelector";
import DelayMenuItem from "src/components/DelayMenuItem/DelayMenuItem";
import { LocalizedString } from "src/components/Localization";
import { MenuItem, SideMenu } from "src/components/SideMenu";
import { SuppressHotjar } from "src/components/SuppressHotjar";
import { VisibleInstallationsPreferences } from "src/components/VisibleInstallationsPreferences";
import { Button, ButtonStyle } from "src/design-system/Button";
import { ButtonType } from "src/design-system/Button/Button";
import { ButtonLinkMenu } from "src/design-system/Button/ButtonLink";
import { ThemedProps, themes } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { baseTextStyles, Disclaimer } from "src/design-system/Tokens/typography";
import { getCurrentRoute, getPathWithParams, getRoutes } from "src/routes";
import { formatDateTime } from "src/util/formatters";
import { RouteId } from "src/util/routeId";
import styled, { ThemeProvider } from "styled-components";
import { outerContainerId, pageWrapId } from "./containerIds";
import { DividerLine } from "../../design-system/Divider";
import { DividerColor } from "../../design-system/Divider/Divider";
import { privacyStatementLink, termsAndConditionsLink, ThemedA } from "../EULA/EULAComponent";
import { Link } from "react-router-dom";

export const UserName = styled.span`
  text-transform: uppercase;
  font-size: 1em;
`;

export const UserEmail = styled.span`
  font-size: 0.9em;
`;

const Spacer = styled.div`
  height: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  border-top: solid 1px ${colors.primary.orange};
`;

const MenuWrapper = styled.div`
  height: 100%;
`;
const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: ${colors.primary.white};
`;

const Version = styled(Disclaimer)`
  color: ${colors.primary.white};
  padding: 0.8em 0;
  text-align: right;
  white-space: pre-line;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 5em;
  font-size: 0.9em;
`;

export const FooterLink = styled(Link)`
  ${baseTextStyles};
  text-decoration: none;
  border-bottom: 1px solid ${(props: ThemedProps) => props.theme.text};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 120px 120px;
`;

export const Column = styled.div`
  text-align: center;
`;

const Hr = styled(DividerLine)`
  margin: 0.75em 0;
`;

const closeDelay = 450;

export interface IDispatchProps {
  startOnboarding(routeId: RouteId, ignoreCompletedOnboardings?: boolean): void;
}

export interface IUserPrefsProps {
  isOpen: boolean;
  userInfo: IUserInfo;
  toggleMenu: () => void;
  showOnlyDesignerLinks: boolean;
  isDesigner: boolean;
  onMenuStateChange?(state: State): void;
}

const AccountSelectorWrapper = styled.div`
  padding-bottom: 0.5em;
  font-size: 1rem;
`;

const UserEmailMenuItem = styled.div`
  padding-top: 0.3em;
`;

const LogoutButton = styled(Button)`
  width: 100%;
`;

// TODO:props
export class UserPrefsComponent extends React.Component<IUserPrefsProps & IDispatchProps> {
  constructor(props: IUserPrefsProps & IDispatchProps) {
    super(props);

    this.triggerOnboarding = this.triggerOnboarding.bind(this);
  }

  public render() {
    const version = process.env.REACT_APP_OS_VERSION;
    const buildTime = process.env.REACT_APP_OS_BUILD_TIME;
    const routes = getRoutes();
    const isMobile = window.screen.width < breakpoints.mobileLarge;
    let showOnboardings = true;
    if (this.props.showOnlyDesignerLinks && this.props.isDesigner) {
      showOnboardings = false;
    }
    if (this.props.userInfo.isShipyard) {
      showOnboardings = false;
    }

    return (
      <SideMenu
        isOpen={this.props.isOpen}
        menuIcon={false}
        outerContainerId={outerContainerId}
        pageWrapId={pageWrapId}
        onMenuStateChange={this.props.onMenuStateChange}
      >
        <MenuWrapper>
          <FlexWrapper>
            <div>
              {isMobile && (
                <MenuItem>
                  <AccountSelectorWrapper>
                    <AccountSelector />
                  </AccountSelectorWrapper>
                </MenuItem>
              )}

              <MenuItem>
                <SuppressHotjar>
                  <UserName>{this.props.userInfo.name}</UserName>
                </SuppressHotjar>
              </MenuItem>

              <UserEmailMenuItem>
                <SuppressHotjar>
                  <UserEmail>{this.props.userInfo.email}</UserEmail>
                </SuppressHotjar>
              </UserEmailMenuItem>

              <Spacer />

              {!this.props.showOnlyDesignerLinks && !this.props.isDesigner && (
                <VisibleInstallationsPreferences toggleMenu={this.props.toggleMenu} />
              )}

              <DelayMenuItem onClick={this.props.toggleMenu} delay={closeDelay} to={getPathWithParams(routes.Profile)}>
                <LocalizedString id="myProfile" />
              </DelayMenuItem>
              <DelayMenuItem onClick={this.props.toggleMenu} delay={closeDelay} to={getPathWithParams(routes.Users)}>
                <LocalizedString id="users" />
              </DelayMenuItem>

              {showOnboardings && (
                <ButtonLinkMenu onClick={this.triggerOnboarding}>
                  <LocalizedString id="contextHelp" />
                </ButtonLinkMenu>
              )}

              <ThemeProvider theme={themes.dark}>
                <MenuItem>
                  <LogoutButton
                    buttonType={ButtonType.Outline}
                    buttonStyle={ButtonStyle.Transparent}
                    onClick={() => this.logout()}
                  >
                    <LocalizedString id="logout" />
                  </LogoutButton>
                </MenuItem>
              </ThemeProvider>
            </div>
            <Footer>
              <Hr $color={DividerColor.Gray} />
              <Container>
                <Column>
                  <ThemedA href={privacyStatementLink} target="_blank">
                    <LocalizedString id="eula.privacyStatement" />
                  </ThemedA>
                </Column>
                <Column>
                  <ThemedA href={termsAndConditionsLink} target="_blank">
                    <LocalizedString id="eula.termsAndConditions" />
                  </ThemedA>
                </Column>
              </Container>
            </Footer>
            <Version>{version + (buildTime ? "\n" + formatDateTime(buildTime) : "")}</Version>
          </FlexWrapper>
        </MenuWrapper>
      </SideMenu>
    );
  }

  private triggerOnboarding() {
    this.props.toggleMenu();

    setTimeout(() => {
      const currentRoute = getCurrentRoute();
      if (currentRoute) {
        this.props.startOnboarding(currentRoute.id, true);
      }
    }, 450);
  }

  private async logout() {
    const accessToken = localStorage.getItem("token") || "";
    localStorage.removeItem("token");
    localStorage.removeItem("info");
    window.location.assign(`${process.env.REACT_APP_API_URL_BASE}auth/logout?token=${accessToken}`);
  }
}
