import * as React from "react";
import { MAX_FILE_SIZE_MB } from "src/components/BasketItemsList/BasketItemsListComponent";
import { LocalizedString } from "src/components/Localization";
import { themes } from "src/design-system/Theme/theme";

import {
  AttachmentIcon,
  ChatBubbleIcon,
  CheckCircleIcon,
  IconSize,
  InProgressIcon,
  InformationIcon,
  TriangleWarningIcon,
  WarningIcon,
} from "src/icons";
import { Container, FlexContainer } from "src/design-system/Container";
import { IEquipment, ILineItem, INotification, IPurchase } from "online-services-types";
import { ISalesLineItem, itemTypes } from "src/components/CommerceList/types";
import { Tooltip, TooltipArrow } from "@wartsila/ui-kit";
import { capitalizeFirstLetter, formatCurrency, formatDate } from "src/util/formatters";
import { spacer, spacers } from "src/design-system/Tokens/tokens";

import { AddToBasketButtons } from "../AddToBasketButtons";
import { AttachmentUploader } from "../AttachmentUploader";
import { ClassificationAttachmentDownload } from "../CommerceList/ClassificationAttachmentDownload";
import { CommentField } from "./CommentField";
import { IOwnProps as IButtonsOwnProps } from "../AddToBasketButtons/types";
import { IDENTIFICATION_REQUIRED } from "src/util/commerce";
import { IExtendedPartAvailability } from "src/redux/partsAvailability";
import { IPartsCatalogSectionViewModel } from "src/models/catalogueSections";
import { IResponsiveTableColumn } from "../ResponsiveTable/interfaces";
import { IUploadedFile } from "../AttachmentUploader/AttachmentUploaderComponent";
import { IconWithTooltip } from "src/views/CreateFluidReportView/phases/IconWithTooltip";
import { LabelNew } from "../CommerceList/ResponsiveDeliveryTree";
import { LeadText } from "src/components/PartsCatalogueList/index";
import { PortalTooltip } from "../Portal";
import { PreWrapText } from "../SupportList/columns";
import { PurchaseStatus } from "src/services/commerce/commerce.purchase-status";
import { TableCellLoader } from "src/components/LoadingSpinner";
import colors from "src/design-system/Tokens/colors";
import { fixedFontSizes, P } from "src/design-system/Tokens/typography";
import { getCurrencySymbol } from "src/util/currencyMapper";
import { isUndefined } from "util";
import moment from "moment";
import styled, { ThemeProvider } from "styled-components";
import { translateString } from "src/util/localization";

const DescriptionCell = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${spacers([1, 0])};
  margin: 2px 0;
`;

const CommentCell = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${spacers([0.55, 0])};
  margin: ${spacer(0.15)} 0;
`;

const CommentCount = styled.span`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  color: ${colors.primary.black};
  margin-top: -10px;
`;

const IconAndTooltipWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

const InProgressIconWrapper = styled.div`
  position: absolute;
`;

const InProgressWrapper = styled.div`
  display: flex;
`;

const QuantityWrapper = styled.div`
  font-size: ${fixedFontSizes.baseFontSize}px;
  margin-left: 10px;
`;

const InfoTooltip = styled(Tooltip)`
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 0.5rem;
  width: 300px;
  position: absolute;
  z-index: 1000;
`;

const ProgressTooltip = styled(Tooltip)`
  top: 20%;
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  color: ${colors.primary.black} !important;
  transform: translate(0, -50%);
  width: 200px;
  position: absolute;
  z-index: 1000;
  left: 2rem;
`;

const HR = styled.div`
  border-bottom: 1px solid ${colors.primary.gray};
  margin: 1em 0;
  max-width: 320px;
`;

const EmptyPrice = styled.span<{ highlight?: boolean }>`
  color: ${({ highlight }) => (highlight ? colors.primary.orange : "inherit")};
`;

const isDateWithinNotificationDate = (date?: string, notifications: INotification[] = []): boolean => {
  if (!date || notifications.length === 0) return false;
  return notifications.some((notif) => {
    return moment(date).isSameOrAfter(moment(notif.creationDate).subtract(1, "days"));
  });
};

const InlineTooltip = ({ icon, text }: { icon: JSX.Element; text: string }) => (
  <IconWithTooltip
    top="35%"
    width="150px"
    iconProps={{
      display: "inline-block",
      position: "relative",
      width: "fit-content",
      height: "1em",
      margin: "-4px 0 0 0.5em",
    }}
    icon={icon}
  >
    <Container>{text}</Container>
  </IconWithTooltip>
);

const LINEITEM_CHAR_LIMIT = 255;

export const BasketButtonsRenderer: React.FC<IButtonsOwnProps> = ({
  part,
  onChangeItemNumber,
  externalBasket,
  hidePrices,
  showTrashButton,
  className,
  setRecommendations,
}) => {
  return (
    <div style={{ backgroundColor: colors.secondary.gray10 }}>
      <AddToBasketButtons
        part={part}
        onChangeItemNumber={onChangeItemNumber}
        externalBasket={externalBasket}
        hidePrices={hidePrices}
        inactive={isUndefined(part.netPrice)}
        showTrashButton={showTrashButton}
        className={className}
        setRecommendations={setRecommendations}
      />
    </div>
  );
};

export const ExternalBasketButtonsRenderer: React.FC<IButtonsOwnProps> = ({
  part,
  onChangeItemNumber,
  externalBasket,
  hidePrices,
  showTrashButton,
  className,
  inactive,
  setRecommendations,
}) => {
  return (
    <div style={{ backgroundColor: colors.secondary.gray10 }}>
      <AddToBasketButtons
        part={part}
        onChangeItemNumber={onChangeItemNumber}
        externalBasket={externalBasket}
        hidePrices={hidePrices}
        inactive={Boolean(inactive)}
        showTrashButton={showTrashButton}
        className={className}
        setRecommendations={setRecommendations}
      />
    </div>
  );
};

export const descriptionMetadata: IResponsiveTableColumn<IPartsCatalogSectionViewModel> = {
  key: "description",
  label: "",
  plannedWidth: 300,
  priority: 10,
  alwaysShown: true,
  hideEmptyValues: false,
  renderer: ({ row }) => {
    return (
      <DescriptionCell>
        {row.id} {row.description}
      </DescriptionCell>
    );
  },
};

export const specialMarks = (partsAvailability?: IExtendedPartAvailability[]): IResponsiveTableColumn<ILineItem> => ({
  key: "specialMarks",
  priority: 9,
  plannedWidth: 75,
  alwaysShown: true,
  hideEmptyValues: false,
  isEnabled: (_, rows) => {
    const availability = partsAvailability?.filter((a) => rows.find((p) => p.materialId === a.materialId)) || [];

    const marksHidden =
      availability.every((row) => !row.dangerousGoodsIndicator && !row.nonCancellableItem) &&
      rows.every((row) => !row.dangerousGoodsIndicator && !row.nonCancellableItem);

    return !marksHidden;
  },
  valueRenderer: ({ row }) => {
    const availability = partsAvailability?.find((partAvailability) => {
      if (partAvailability.equipmentId !== row.equipmentId) return false;

      if (partAvailability.id && row.sparePartNumber) {
        return partAvailability.id === row.sparePartNumber;
      } else {
        return partAvailability.materialId === row.materialId;
      }
    });

    return (
      <FlexContainer $paddingTop={3} $gap={1}>
        {row?.nonCancellableItem || availability?.nonCancellableItem ? (
          <PortalTooltip
            component={
              <InfoTooltip arrow={TooltipArrow.Left}>{translateString("spareParts.nonCancellable")}</InfoTooltip>
            }
          >
            <Container $verticalAlignTop $inlineBlock $positionRelative>
              <InformationIcon color={colors.primary.orange} />
            </Container>
          </PortalTooltip>
        ) : null}

        {row?.dangerousGoodsIndicator || availability?.dangerousGoodsIndicator ? (
          <PortalTooltip
            component={
              <InfoTooltip arrow={TooltipArrow.Left}>{translateString("spareParts.DGRTooltipText")}</InfoTooltip>
            }
          >
            <Container $verticalAlignTop $inlineBlock $positionRelative>
              <TriangleWarningIcon color={colors.primary.orange} />
            </Container>
          </PortalTooltip>
        ) : null}
      </FlexContainer>
    );
  },
});

export const spnDescriptionMetadata = (): IResponsiveTableColumn<ILineItem> => ({
  key: "spnDescription",
  label: translateString("spareParts.partDescription"),
  priority: 10,
  plannedWidth: 300,
  alwaysShown: true,
  hideEmptyValues: false,
  valueRenderer: ({ value, row }) => {
    let description;

    if (value) {
      description = value;
    } else {
      description = row ? row.materialText : translateString("spareParts.noDescription");
    }

    return (
      <FlexContainer $gap={1}>
        <span>{description}</span>
      </FlexContainer>
    );
  },
});

export const icons = (parentType: string): IResponsiveTableColumn<ILineItem> => ({
  key: "icon",
  label: " ",
  priority: 5,
  plannedWidth: 75,
  isEnabled: (_, rows) => {
    return rows.some(
      (row) =>
        (parentType === itemTypes.order || parentType === itemTypes.quotation) && (row.dangerousGoodsIndicator || row.itemNote)
    );
  },
  alwaysShown: true,
  valueRenderer: ({ row }) => (
    <FlexContainer $centered>
      {(parentType === itemTypes.order || parentType === itemTypes.quotation) && row.dangerousGoodsIndicator ? (
        <InlineTooltip
          icon={<TriangleWarningIcon color="#ed731a" size={IconSize.Small} />}
          text={translateString(
            parentType === itemTypes.order ? "spareParts.DGROrderLine" : "spareParts.DGRQuotationLine"
          )}
        />
      ) : (
        ""
      )}
      {(parentType === itemTypes.order || parentType === itemTypes.quotation) && row.itemNote ? (
        <InlineTooltip
          icon={<InformationIcon color="#ed731a" size={IconSize.Small} />}
          text={translateString("spareParts.ItemNoteLine")}
        />
      ) : (
        ""
      )}
    </FlexContainer>
  ),
});

export const partDescriptionMetadata = (parentType: string): IResponsiveTableColumn<ILineItem> => ({
  key: "partDescription",
  label: translateString("spareParts.partDescription"),
  priority: 10,
  plannedWidth: 300,
  alwaysShown: true,
  valueRenderer: ({ value, row }) => <>{value || row?.materialText || translateString("spareParts.noDescription")}</>,
});

export const idMetadata = (type?: string): IResponsiveTableColumn<ILineItem> => ({
  key: "sparePartNumber",
  label: translateString("spareParts.partNumber"),
  priority: 8,
  plannedWidth: 140,
  alwaysShown: true,
  valueRenderer: ({ row }) => sparePartValue(row, type),
});

export const lineNumber: IResponsiveTableColumn<ILineItem> = {
  key: "lineNumber",
  label: translateString("spareParts.lineNumber"),
  priority: 0,
  plannedWidth: 90,
  alwaysShown: true,
  valueRenderer: ({ row }) => (row.lineNumber ? Number(row.lineNumber) : ""),
};

export const dispatchDateMetadata = (
  isFCA: boolean,
  dispatchDateNotifications?: INotification[]
): IResponsiveTableColumn<ILineItem> => ({
  key: "dispatchDate",
  label: isFCA ? translateString("spareParts.readyForCollection") : translateString("spareParts.dispatchDate"),
  priority: 8,
  plannedWidth: 160,
  alwaysShown: true,
  valueRenderer: ({ row }) =>
    row.dispatchDate && row.deliveredQuantity !== row.quantity ? (
      <>
        {formatDate(row.dispatchDate)}
        {isFCA && isDateWithinNotificationDate(row.dispatchDateChanged, dispatchDateNotifications) ? (
          <InlineTooltip
            icon={<WarningIcon size={IconSize.Small} />}
            text={translateString("spareParts.collectionDateHasChanged")}
          />
        ) : null}
      </>
    ) : (
      "-"
    ),
});

export const etaDateMetadata = (etaDateNotifications?: INotification[]): IResponsiveTableColumn<ILineItem> => ({
  key: "deliveryDate",
  label: translateString("spareParts.deliveryDate"),
  priority: 8,
  plannedWidth: 160,
  alwaysShown: true,
  valueRenderer: ({ row }) =>
    row.deliveryDate && row.deliveredQuantity !== row.quantity ? (
      <>
        {formatDate(row.deliveryDate)}
        {isDateWithinNotificationDate(row.deliveryDateChanged, etaDateNotifications) ? (
          <InlineTooltip
            icon={<WarningIcon size={IconSize.Small} />}
            text={translateString("spareParts.etaDateHasChanged")}
          />
        ) : null}
      </>
    ) : (
      "-"
    ),
});

export const deliveredMetadata = (isOrder: boolean): IResponsiveTableColumn<ILineItem> => ({
  key: "deliveries",
  label: translateString("spareParts.delivery"),
  priority: 10,
  plannedWidth: 160,
  alwaysShown: true,
  isEnabled: () => isOrder,
  valueRenderer: ({ row }) => {
    if (row.deliveredQuantity === row.quantity) {
      return (
        row.deliveries && (
          <IconWithTooltip
            top="20%"
            width="200px"
            iconProps={{ position: "absolute", width: "auto" }}
            icon={<CheckCircleIcon size={IconSize.Large} color={colors.notification.ok} />}
          >
            <Container>
              <div>
                {translateString("spareParts.deliveryStatusLabel", {
                  delivery:
                    row.deliveries.length === 1
                      ? translateString("spareParts.deliveryStatus")
                      : translateString("spareParts.deliveryStatuses"),
                })}
              </div>
              <HR />
              {row.deliveries.map((del) => {
                return `- ${del} \n`;
              })}
            </Container>
          </IconWithTooltip>
        )
      );
    }
    if (row.deliveredQuantity !== 0 && row.quantity && row.deliveredQuantity && row.deliveries) {
      return (
        <DeliveryInProgressIcon quantity={row.quantity} delivered={row.deliveredQuantity} deliveries={row.deliveries} />
      );
    }

    return (
      <IconWithTooltip
        iconProps={{ position: "absolute", width: "auto" }}
        top="20%"
        width="200px"
        icon={<span>-</span>}
      >
        <Container $inlineBlock>{translateString("spareParts.noDeliveries")}</Container>
      </IconWithTooltip>
    );
  },
});

export const leadTextMetadata = (
  equipments: IEquipment[],
  partsAvailability?: IExtendedPartAvailability[],
  rowType?: string,
  rowStatus?: string
): IResponsiveTableColumn<ILineItem> => ({
  key: "leadText",
  label: translateString("spareParts.leadTime"),
  priority: 4,
  plannedWidth: 160,
  valueRenderer: ({ row }) => {
    const isQuotation = rowType === itemTypes.quotation;
    const isQuotationQuoted = isQuotation && rowStatus === PurchaseStatus.Quoted;
    const isQuotationCreated = isQuotation && rowStatus === PurchaseStatus.Created;
    const isQuotationInProcess = isQuotation && rowStatus?.toLowerCase() === PurchaseStatus.InProcess.toLowerCase();

    const availability = partsAvailability?.find((partAvailability) => {
      if (partAvailability.equipmentId !== row.equipmentId) return false;

      if (partAvailability.id && row.sparePartNumber) {
        return partAvailability.id === row.sparePartNumber;
      } else {
        return partAvailability.materialId === row.materialId;
      }
    });

    if (!isQuotation || isQuotationQuoted || isQuotationCreated || isQuotationInProcess) {
      const rowWithAvailability = availability
        ? { ...row, leadText: availability.leadText, quantityInStock: availability.quantityInStock }
        : row;
      return <LeadText equipment={equipments} row={rowWithAvailability} />;
    }

    return "-";
  },
});

export const pageMetadata: IResponsiveTableColumn<ILineItem> = {
  key: "page",
  label: translateString("spareParts.page"),
  priority: 8,
  plannedWidth: 140,
  hideEmptyValues: true,
};

export const positionMetadata: IResponsiveTableColumn<ILineItem> = {
  key: "position",
  label: translateString("spareParts.position"),
  priority: 8,
  plannedWidth: 140,
  hideEmptyValues: true,
};

export const netWeightMetadata: IResponsiveTableColumn<ILineItem> = {
  key: "netWeight",
  label: translateString("spareParts.partWeight"),
  priority: 2,
  plannedWidth: 100,
  labelRenderer: ({ row }) => {
    return row && row.weightUnit !== null
      ? `${translateString("spareParts.partWeight")} (${row.weightUnit})`
      : translateString("spareParts.partWeight");
  },
  valueRenderer: ({ row }) => {
    const { quantity, netWeight } = row;
    if (quantity > 0) {
      return (quantity * netWeight).toFixed(3);
    }
    return netWeight;
  },
};
interface InProgressProps {
  quantity: number;
  delivered: number;
  deliveries: string[];
}
export const DeliveryInProgressIcon = ({ quantity, delivered, deliveries }: InProgressProps) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>();

  return (
    <InProgressIconWrapper onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <InProgressWrapper>
        <InProgressIcon size={IconSize.Large} color={colors.secondary.yellow} />
        <QuantityWrapper>{`${delivered} / ${quantity}`}</QuantityWrapper>
        {showTooltip && (
          <ProgressTooltip arrow={TooltipArrow.Left}>
            <div>
              {translateString("spareParts.deliveryStatusLabel", {
                delivery:
                  deliveries.length === 1
                    ? translateString("spareParts.deliveryStatus")
                    : translateString("spareParts.deliveryStatuses"),
              })}
            </div>
            <HR />
            {deliveries.map((del) => {
              return `- ${del} \n`;
            })}
          </ProgressTooltip>
        )}
      </InProgressWrapper>
    </InProgressIconWrapper>
  );
};

export const InfoIconWithTooltip = ({
  text = translateString("spareParts.leadTimeNotificationTooltipText"),
}: {
  text?: string;
}) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>();

  return (
    <IconAndTooltipWrapper onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <InformationIcon color={colors.primary.orange} />
      {showTooltip && <InfoTooltip arrow={TooltipArrow.Left}>{text}</InfoTooltip>}
    </IconAndTooltipWrapper>
  );
};

export const WarninigIconWithTooltip = ({ text = translateString("spareParts.DGRTooltipText") }: { text?: string }) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>();

  return (
    <IconAndTooltipWrapper onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
      <TriangleWarningIcon color={colors.primary.orange} />
      {showTooltip && <InfoTooltip arrow={TooltipArrow.Left}>{text}</InfoTooltip>}
    </IconAndTooltipWrapper>
  );
};

export const netPriceMetadata = (highlightEmpty = false): IResponsiveTableColumn<ILineItem> => ({
  key: "netPrice",
  label: translateString("spareParts.price"),
  priority: 9,
  alwaysShown: true,
  plannedWidth: 120,
  labelRenderer: ({ row }) => {
    if (row && row.currency) {
      return `${translateString("spareParts.price")} (${getCurrencySymbol(row.currency)})`;
    } else if (row && row.currencyCode) {
      return `${translateString("spareParts.price")} (${getCurrencySymbol(row.currencyCode)})`;
    }
    return "";
  },
  valueRenderer: ({ value }) => {
    if (typeof value === "undefined") {
      return <TableCellLoader />;
    }
    if (value === 0) {
      return (
        <EmptyPrice highlight={highlightEmpty}>
          {capitalizeFirstLetter(translateString("spareParts.askForQuote"))}
        </EmptyPrice>
      );
    }

    return formatCurrency(value as number);
  },
});

export const cylinderMetadata: IResponsiveTableColumn<ILineItem> = {
  key: "cylinder",
  label: translateString("spareParts.location"),
  alwaysHidden: true,
  hideEmptyValues: true,
  priority: 2,
  plannedWidth: 100,
};

export const qtySectionMetadata: IResponsiveTableColumn<ILineItem> = {
  key: "qtySection",
  label: translateString("spareParts.qtySection"),
  alwaysHidden: true,
  hideEmptyValues: true,
  priority: 2,
  plannedWidth: 100,
};

export const quantityMetadata: IResponsiveTableColumn<ILineItem> = {
  key: "quantity",
  label: translateString("request.quantity"),
  alwaysShown: true,
  hideEmptyValues: true,
  priority: 2,
  plannedWidth: 80,
};

// Sales Doc Columns
export const descMetadata: IResponsiveTableColumn<ISalesLineItem> = {
  key: "description",
  label: translateString("spareParts.partDescription"),
  priority: 10,
  plannedWidth: 300,
  valueRenderer: ({ value }) => {
    if (value === IDENTIFICATION_REQUIRED) {
      return translateString("spareParts.identificationRequired");
    }

    return value;
  },
};
export const partNumberMetadata: IResponsiveTableColumn<ISalesLineItem> = {
  key: "sparePartNumber",
  label: translateString("spareParts.partNumber"),
  priority: 10,
  plannedWidth: 140,
};

export const partWeightMetadata = (type: string): IResponsiveTableColumn<ILineItem | ISalesLineItem> => ({
  key: "weight",
  label: translateString("spareParts.partWeight"),
  priority: 2,
  plannedWidth: 100,
  valueRenderer: ({ row }) => {
    if (type === itemTypes.quotation) {
      if (row.materialNumber === "TECH ID") return "-";
    }

    if (!row.weight) return "-";

    return row.weight;
  },
  labelRenderer: ({ row }) => {
    return row && row.weightUnit
      ? `${translateString("spareParts.partWeight")} (${row.weightUnit})`
      : translateString("spareParts.partWeight");
  },
});
export const partQtyMetadata: IResponsiveTableColumn<ISalesLineItem> = {
  key: "quantity",
  label: translateString("spareParts.quantity"),
  priority: 2,
  plannedWidth: 55,
  valueRenderer: ({ row }) => {
    return row && row.quantity
      ? `${row.quantity.split(".")[0]} ${row.unitOfMeasure === "PC" ? "" : row.unitOfMeasure}`
      : "";
  },
};

export const quantitySF: IResponsiveTableColumn<ILineItem> = {
  key: "quantity",
  label: translateString("spareParts.quantity"),
  priority: 2,
  plannedWidth: 55,
};

export const priceMetadata = (type: string): IResponsiveTableColumn<ILineItem | ISalesLineItem> => ({
  key: "subTotal",
  label: "Price",
  priority: 10,
  plannedWidth: 150,
  isEnabled: () => type !== itemTypes.draft,
  labelRenderer: ({ row }) =>
    row && row.currency ? `${translateString("spareParts.subtotal")} (${getCurrencySymbol(row.currency)})` : "",
  valueRenderer: ({ row }) => {
    if (type === itemTypes.quotation) {
      if (row.materialNumber === "TECH ID") return translateString("purchaseStatus.toBeConfirmed");
    }
    return row && row.totalPrice ? `${formatCurrency(+row.totalPrice)}` : "";
  },
});

export const sfPriceMetadata = (type: string, parentStatus: string): IResponsiveTableColumn<ILineItem> => ({
  key: "subTotal",
  label: "Price",
  priority: 10,
  plannedWidth: 150,
  isEnabled: () => type !== itemTypes.draft,
  labelRenderer: ({ row }) =>
    row && row.currencyCode ? `${translateString("spareParts.subtotal")} (${getCurrencySymbol(row.currencyCode)})` : "",
  valueRenderer: ({ row }) => {
    if (parentStatus === PurchaseStatus.Expired) return "-";
    if (row.materialNumber === "TECH ID") return translateString("purchaseStatus.toBeConfirmed");
    return row && row.totalPrice ? `${formatCurrency(+row.totalPrice)}` : "";
  },
});

export const availableCommentBubble: IResponsiveTableColumn<ILineItem> = {
  key: "availableCommentBubble",
  label: "",
  priority: 10,
  plannedWidth: 40,
  alwaysHidden: false,
  alwaysShown: true,
  valueRenderer: ({ row }) => {
    // Comment count hard coded, since we have no data how many comments we have.
    return (
      row &&
      row.otherInformation && (
        <CommentCell>
          <ChatBubbleIcon size={IconSize.Medium} color={colors.primary.black} />
          <CommentCount> 1</CommentCount>
        </CommentCell>
      )
    );
  },
};

export const availableAttachmentsBubble: IResponsiveTableColumn<ILineItem> = {
  key: "availableAttachmentsBubble",
  label: "",
  priority: 10,
  plannedWidth: 40,
  alwaysHidden: false,
  alwaysShown: true,
  valueRenderer: ({ row }) => {
    return (
      (row?.attachmentIds.length > 0 && (
        <CommentCell>
          <AttachmentIcon size={IconSize.Medium} color={colors.primary.black} />
          <CommentCount> {row.attachmentIds.length}</CommentCount>
        </CommentCell>
      )) ||
      undefined
    );
  },
};

export const classificationAttachment: (
  salesDocumentNumber: string,
  notifications: INotification<{ sfId?: string }>[]
) => IResponsiveTableColumn<ILineItem> = (salesDocumentNumber, notifications) => ({
  key: "cceDescription",
  label: "",
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: false,
  labelRenderer: ({ row }) => {
    return row.isCCECreated ? translateString("spareParts.classificationAttachment") : "";
  },
  valueRenderer: ({ row }) => {
    const isNew = notifications.find((n) => n.data.sfId === row.sfId);
    if (row.isCCECreated) {
      return (
        <FlexContainer>
          <ClassificationAttachmentDownload
            description={row.cceDescription || ""}
            salesDocumentNumber={salesDocumentNumber}
          />
          {isNew ? (
            <Container $marginLeft={1}>
              <LabelNew>{translateString("new")}</LabelNew>
            </Container>
          ) : null}
        </FlexContainer>
      );
    }

    return "";
  },
});

export const commentMetadata = (setComment?: (item: ILineItem) => void): IResponsiveTableColumn<ILineItem> => ({
  key: "otherInformation",
  label: translateString("spareParts.otherInformation"),
  priority: 10,
  plannedWidth: 115,
  alwaysHidden: true,
  isEnabled: (row) => (setComment ? shouldDisplayAddComment(row) : Boolean(row.otherInformation)),
  valueRenderer: ({ row }) => (
    <CommentField
      comment={row.otherInformation}
      limit={LINEITEM_CHAR_LIMIT}
      onChange={setComment ? (comment) => setComment({ ...row, otherInformation: comment }) : undefined}
    />
  ),
});

export const itemNoteMetadata = (parentType: string): IResponsiveTableColumn<ILineItem> => ({
  key: "itemNote",
  label: translateString("spareParts.otherInformation"),
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: true,
  hideEmptyValues: true,
  isEnabled: (row) => Boolean(row.itemNote),
  valueRenderer: ({ value, row }) => (
    <>
      {(parentType === itemTypes.order || parentType === itemTypes.quotation) && row.itemNote ? (
        <PreWrapText>{value}</PreWrapText>
      ) : (
        ""
      )}
    </>
  ),
});

export const serialNumberMetadata = {
  key: "serialNumber",
  label: translateString("spareParts.serialNumber"),
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: true,
  hideEmptyValues: true,
};

export const vendorCodeMetadata = {
  key: "vendorCode",
  label: translateString("spareParts.vendorCode"),
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: true,
  hideEmptyValues: true,
};

export const markingsMetadata = {
  key: "markings",
  label: translateString("spareParts.markings"),
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: true,
  hideEmptyValues: true,
};

export const sizeAndDimensionsMetadata = {
  key: "sizeAndDimensions",
  label: translateString("spareParts.sizeAndDimensions"),
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: true,
  hideEmptyValues: true,
};

export const usageTargetMetadata = {
  key: "usageTarget",
  label: translateString("spareParts.usageTarget"),
  priority: 10,
  plannedWidth: 150,
  alwaysHidden: true,
  hideEmptyValues: true,
};

export const hideAttachmentsColumnFilter = (hide: boolean = false, column: IResponsiveTableColumn<ILineItem>) =>
  hide ? column.key !== "attachments" : true;

export const hideLineNumberColumnFilter = (hide: boolean = false, column: IResponsiveTableColumn<ILineItem>) =>
  hide ? column.key !== "lineNumber" : true;

export const attachmentMetadata = (
  maxFileSize: number,
  setAttachment: (item: ILineItem, files: IUploadedFile[]) => void,
  getFileTempStore: (row?: ILineItem) => IUploadedFile[],
  disableEdit: boolean,
  readOnly: boolean,
  setBusy?: (state: boolean) => void
): IResponsiveTableColumn<ILineItem> => ({
  key: "attachments",
  label: "",
  labelRenderer: () => translateString("spareParts.attachments"),
  priority: 10,
  plannedWidth: 115,
  alwaysHidden: true,
  isEnabled: (row) => (readOnly ? (row.attachments ?? []).length > 0 : row.materialId !== "TECH ID"),
  valueRenderer: ({ row }) => {
    return (
      <ThemeProvider theme={themes.light}>
        <AttachmentUploader
          relatedId={row.sfId}
          files={getFileTempStore(row)}
          onFilesChanged={(files) => setAttachment(row, files)}
          onBusy={setBusy}
          iconColor={colors.primary.black}
          disabled={!row.sfId || disableEdit}
          nonRemovable={disableEdit}
          downloadAllowed
          maxSize={maxFileSize}
        />
        <P style={{ color: colors.primary.black, fontSize: "14px" }}>
          <LocalizedString id="fileUpload.sizeLimit" values={{ limit: MAX_FILE_SIZE_MB }} />
        </P>
      </ThemeProvider>
    );
  },
});

export const salesDocColumns = (purchase: IPurchase) => [
  lineNumber,
  descMetadata,
  partNumberMetadata,
  partWeightMetadata(purchase.type),
  partQtyMetadata,
];

export const salesDocColumnsWPrice = (purchase: IPurchase) => [
  lineNumber,
  descMetadata,
  partNumberMetadata,
  partWeightMetadata(purchase.type),
  partQtyMetadata,
  priceMetadata(purchase.type),
];

const shouldDisplayAddComment = (row: ILineItem) => {
  return Boolean(row.sparePartNumber || (row.materialId && row.materialId !== "TECH ID"));
};

export const sparePartValue = (part: ILineItem, type?: string) => {
  if (part.materialId !== "TECH ID" && part.displayValue !== undefined) {
    return part.displayValue;
  }

  if (part.addedFromMatNum || !part.sparePartNumber) {
    if (type === itemTypes.quotation) {
      if (part.materialId === "TECH ID") return translateString("purchaseStatus.toBeConfirmed");
    }

    if (part.materialId === "TECH ID") return "";

    return part.materialId;
  }

  return part.sparePartNumber;
};
