import * as React from "react";

import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import { CompassIcon, IconSize, PowerPlantIcon, VesselIcon } from "src/icons";
import { DropdownStyle, ISelectObject } from "src/design-system/Select/Select";
import { Grid, GridRowMediumItems, GridRowSmallItems } from "src/design-system/Tokens/grid";
import { H4, P } from "src/design-system/Tokens/typography";
import {
  IAccount,
  IEquipment,
  IFluidReportEvaluation,
  IInstallation,
  INewsItem,
  INotification,
  IUptimeChart,
  Service,
} from "online-services-types";
import { IRoutes, getPathWithParams, getRoutes } from "src/routes";
import { LoadingSpinner, MenuItemRectangleLoader } from "src/components/LoadingSpinner";

import { Container } from "src/design-system/Container";
import { DesignerView } from "src/views/DesignerView";
import { DetailsButton } from "src/design-system/DetailsButton";
import { EnergyPortfolioView } from "src/views/EnergyPortfolioView";
import { FlexContainer } from "src/design-system/Container";
import { Heading } from "src/components/Heading";
import { LocalizedString } from "src/components/Localization";
import { NavigationButton } from "src/design-system/NavigationButton";
import { QuantipartsView } from "src/views/QuantipartsView";
import { RouteComponentProps } from "react-router";
import { Select } from "src/design-system/Select";
import { ServicesGrid } from "src/components/ServicesGrid";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { getTrafficLightSet } from "src/components/TrafficLight";
import { maxInstallations } from "src/util/formatters";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";
import { translateString } from "src/util/localization";

export interface IIndexViewComponentStateProps {
  accounts: IAccount[];
  selectedInstallationName?: string;
  selectedInstallationId?: string;
  services: ReadonlyArray<Service>;
  installations: IAPIResource<IInstallation>;
  fluidReportEvaluations: IAPIResource<IFluidReportEvaluation>;
  notifications: INotification[];
  equipments: IAPIResource<IEquipment>;
  uptimeEquipmentCharts: IUptimeChart[];
  isUptimeEquipmentChartsBusy: boolean;
  news: INewsItem[];
  isOnboardingFinished: boolean;
  isEnergy: boolean;
  isMarine: boolean;
  isManager: boolean;
  isEnergyConsultant: boolean;
  isDesigner: boolean;
  isShipyard: boolean;
  isQuantipartsDistributor?: boolean;
  visibleInstallations: number;
  loadingNotifications: boolean;
}

export interface IIndexViewComponentDispatchProps {
  getInstallations(): void;
  getFluidReportEvaluations(): void;
  getEquipments(): void;
  getUptimeEquipmentCharts(): void;
  setSelectedInstallation(selectedInstallationId?: string, selectedInstallationName?: string): void;
}

export interface IIndexViewComponentOwnState {
  selectedOwner: string | undefined;
  selectedStatus: string | undefined;
}

const FilterWrapper = styled.div`
  margin: 0 20px -70px 0;
  ${(props: { $isMobile: boolean }) => props.$isMobile && ` margin: 0;`};
  padding-bottom: ${spacer(2)};
  justify-content: center;
`;

export type IIndexViewComponentProps = IIndexViewComponentDispatchProps &
  IIndexViewComponentStateProps &
  RouteComponentProps;

export class IndexViewComponent extends React.Component<IIndexViewComponentProps, IIndexViewComponentOwnState> {
  constructor(props: IIndexViewComponentProps) {
    super(props);
    this.state = {
      selectedOwner: undefined,
      selectedStatus: undefined,
    };

    this.groupEvaluations = this.groupEvaluations.bind(this);
  }

  public render() {
    const isMobile = window.screen.width < breakpoints.mobileLarge;
    if (this.props.isQuantipartsDistributor) return <QuantipartsView />;
    if (this.props.isDesigner) return <DesignerView />;
    if (this.props.isEnergyConsultant) return <EnergyPortfolioView />;
    const routes = getRoutes();

    let filteredInstallations: IInstallation[] = [];
    if (this.props.installations.data) {
      filteredInstallations = this.props.installations.data.filter(
        (installation: IInstallation) => !installation.hidden
      );
    }

    let installationsContent = this.renderInstallations(filteredInstallations, routes);

    let filtersContent = null;
    if (this.props.isShipyard) {
      let filteredInstallationsOwners: string[] = [];
      let filteredInstallationsStatuses: string[] = [];
      filteredInstallations.forEach((installation: IInstallation) => {
        if (
          installation.owner &&
          installation.owner !== "" &&
          !filteredInstallationsOwners.includes(installation.owner)
        ) {
          filteredInstallationsOwners = filteredInstallationsOwners.concat(installation.owner);
        }
        if (!filteredInstallationsStatuses.includes(installation.statusName)) {
          filteredInstallationsStatuses = filteredInstallationsStatuses.concat(installation.statusName);
        }
      });
      filtersContent = (
        <FlexContainer $justifiedEnd $spaceBetween $column={isMobile}>
          <FilterWrapper $isMobile={isMobile}>
            <Select<string>
              label={translateString("installation.owner")}
              isClearable={true}
              value={this.state.selectedOwner}
              options={filteredInstallationsOwners}
              placeholder={translateString("all")}
              getOptionLabel={(option: ISelectObject<string>) => option.item}
              getOptionValue={(option: ISelectObject<string>) => option.item}
              onChange={(option: string) => this.setState({ selectedOwner: option })}
              isLoading={this.props.installations.status === APIFetchStatus.Busy}
              toolbarStyleWidth={isMobile ? "auto" : "250px"}
              dropdownStyle={DropdownStyle.Toolbar}
            />
          </FilterWrapper>
          <FilterWrapper $isMobile={isMobile}>
            <Select<string>
              label={translateString("installation.status")}
              isClearable={true}
              value={this.state.selectedStatus}
              options={filteredInstallationsStatuses}
              placeholder={translateString("all")}
              getOptionLabel={(option: ISelectObject<string>) =>
                option.item.replace("Pending", translateString("yardView.underConstruction"))
              }
              getOptionValue={(option: ISelectObject<string>) => option.item}
              onChange={(option: string) => this.setState({ selectedStatus: option })}
              isLoading={this.props.installations.status === APIFetchStatus.Busy}
              toolbarStyleWidth={isMobile ? "auto" : "250px"}
              dropdownStyle={DropdownStyle.Toolbar}
            />
          </FilterWrapper>
        </FlexContainer>
      );
    }

    let servicesContent = null;
    if (this.props.isEnergyConsultant) {
      servicesContent = "";
    } else {
      servicesContent = (
        <Container>
          <Heading text={translateString("services")} className={"main-onboarding-your-services"} />
          <ServicesGrid
            accounts={this.props.accounts}
            routes={routes}
            services={this.props.services}
            equipments={this.props.equipments.data || []}
            selectedInstallationId={this.props.selectedInstallationId}
            notifications={this.props.notifications}
            news={this.props.news}
            showManagerActions={this.props.isManager}
            isShipyard={this.props.isShipyard}
            displayExpertInsightLink={this.props.services.includes("Expert Insight")}
          />
        </Container>
      );
    }

    return (
      <div>
        {filtersContent}
        <Heading text={`${translateString("installations")}`} className={"main-onboarding-my-installation"} />
        {installationsContent}
        {servicesContent}
      </div>
    );
  }

  public async componentDidMount() {
    if (!this.props.isEnergyConsultant) {
      if (this.props.visibleInstallations <= maxInstallations) {
        this.props.getInstallations();
      }
      if (!this.props.isQuantipartsDistributor) {
        if (this.props.services.includes("FluidReporting") && !this.props.isShipyard) {
          if (!this.props.fluidReportEvaluations.data?.length) {
            this.props.getFluidReportEvaluations();
          }
        }
        this.props.getEquipments();
      }
      if (this.props.services.includes("Uptime")) {
        this.props.getUptimeEquipmentCharts();
      }

      // Deselect currently selected installation
      // TODO: This is inside a setTimeout because of transition(?) issues causing the component to render as empty if it changes mid-transition
      setTimeout(this.props.setSelectedInstallation, 0);
    }
  }

  public renderInstallations(installations: IInstallation[], routes: IRoutes) {
    let filteredInstallations = installations;

    if (this.state.selectedOwner) {
      filteredInstallations = filteredInstallations.filter((installation) => {
        return installation.owner === this.state.selectedOwner;
      });
    }

    if (this.state.selectedStatus) {
      filteredInstallations = filteredInstallations.filter((installation) => {
        return installation.statusName === this.state.selectedStatus;
      });
    }

    const hideIcon =
      this.props.visibleInstallations <= maxInstallations || filteredInstallations.length <= maxInstallations; // the mutual icon is dynamic to changes in filters

    if (!hideIcon) {
      return (
        <Grid itemsPerRow={GridRowSmallItems} fallbackWidth={{ min: 125, max: 140 }}>
          <NavigationButton
            className="mutualIcon"
            href={{
              pathname: getPathWithParams(routes.Installations),
              state: { selectedOwner: this.state.selectedOwner, selectedStatus: this.state.selectedStatus },
            }}
            icon={
              this.props.isEnergy ? (
                <PowerPlantIcon size={IconSize.Large} />
              ) : this.props.isMarine ? (
                <VesselIcon size={IconSize.Large} />
              ) : (
                <CompassIcon size={IconSize.Large} />
              )
            }
            label={translateString("browse", { type: translateString("installations") })}
          />
        </Grid>
      );
    }
    if (Object.keys(filteredInstallations).length > 0 || this.props.installations.status === APIFetchStatus.Busy) {
      // Show spinner if busy and there are no old values from previous fetch
      if (
        this.props.installations.status === APIFetchStatus.Busy ||
        this.isFluidEvaluationsBusy() ||
        this.isUptimeBusy()
      ) {
        return <LoadingSpinner />;
      } else {
        const evaluationsGrouped = this.groupEvaluations(
          this.props.fluidReportEvaluations.data,
          this.props.uptimeEquipmentCharts
        );
        return (
          <Container $margin={[0, 0, 3, 0]}>
            <Grid itemsPerRow={GridRowMediumItems} fallbackWidth={{ min: 200, max: 200 }} $marginBottom={6}>
              {this.props.installations.status === APIFetchStatus.Success ? (
                Object.keys(filteredInstallations).map((key) => {
                  return (
                    <div key={key}>
                      <DetailsButton
                        href={getPathWithParams(routes.Installation, {
                          installationSalesforceId: filteredInstallations[key].id,
                        })}
                        onClick={() => {
                          this.props.setSelectedInstallation(
                            filteredInstallations[key].id,
                            filteredInstallations[key].name
                          );
                        }}
                        label={filteredInstallations[key].name}
                        additionalVesselInfo={
                          this.props.isShipyard ? (
                            <div>
                              <P>{filteredInstallations[key].projectName}</P>
                              <P>{filteredInstallations[key].owner}</P>
                              <P>{filteredInstallations[key].type}</P>
                              <P>
                                {filteredInstallations[key].imoNumber && (
                                  <LocalizedString
                                    id="yardView.imoNumber"
                                    values={{ imoNumber: filteredInstallations[key].imoNumber }}
                                  />
                                )}
                              </P>
                            </div>
                          ) : undefined
                        }
                      >
                        {!this.props.isShipyard &&
                          getTrafficLightSet(filteredInstallations[key].id, evaluationsGrouped)}
                        {this.props.isShipyard && (
                          <div>
                            {filteredInstallations[key].statusName === "Pending"
                              ? translateString("yardView.underConstruction")
                              : filteredInstallations[key].statusName}
                          </div>
                        )}
                      </DetailsButton>
                    </div>
                  );
                })
              ) : (
                <div>
                  <MenuItemRectangleLoader />
                </div>
              )}
            </Grid>
          </Container>
        );
      }
    } else {
      // No visible installations defined
      return (
        <Container $padding={[0, 0, 6]}>
          <P>
            <LocalizedString id="noVisibleInstallationsFound" values={{ sector: translateString("installations") }} />
          </P>
          <H4>
            <LocalizedString
              id="noVisibleInstallationsFoundHelp"
              values={{ sector: translateString("myInstallations") }}
            />
          </H4>
        </Container>
      );
    }
  }

  private groupEvaluations(
    evaluations: IFluidReportEvaluation[],
    uptimeEquipmentCharts: IUptimeChart[]
  ): { [groupKey: string]: string } {
    if (this.props.equipments.status !== APIFetchStatus.Success) {
      // Equipment fetch not yet ready.
      return {};
    }

    const groupedEvaluations = {};

    if (evaluations) {
      for (const evaluation of evaluations) {
        const coolingWater = groupedEvaluations[`${evaluation.installationId}_coolingWater`];
        const lubeOil = groupedEvaluations[`${evaluation.installationId}_lubeOil`];
        const fuel = groupedEvaluations[`${evaluation.installationId}_fuel`];

        if (evaluation.coolingWater === "GREEN" && !coolingWater) {
          groupedEvaluations[`${evaluation.installationId}_coolingWater`] = "green";
        } else if (evaluation.coolingWater === "YELLOW" && (coolingWater === "green" || !coolingWater)) {
          groupedEvaluations[`${evaluation.installationId}_coolingWater`] = "yellow";
        } else if (evaluation.coolingWater === "RED" && coolingWater !== "red") {
          groupedEvaluations[`${evaluation.installationId}_coolingWater`] = "red";
        }

        if (evaluation.lubeOil === "GREEN" && !lubeOil) {
          groupedEvaluations[`${evaluation.installationId}_lubeOil`] = "green";
        } else if (evaluation.lubeOil === "YELLOW" && (lubeOil === "green" || !lubeOil)) {
          groupedEvaluations[`${evaluation.installationId}_lubeOil`] = "yellow";
        } else if (evaluation.lubeOil === "RED" && lubeOil !== "red") {
          groupedEvaluations[`${evaluation.installationId}_lubeOil`] = "red";
        }

        if (evaluation.fuel === "GREEN" && !fuel) {
          groupedEvaluations[`${evaluation.installationId}_fuel`] = "green";
        } else if (evaluation.fuel === "YELLOW" && (fuel === "green" || !fuel)) {
          groupedEvaluations[`${evaluation.installationId}_fuel`] = "yellow";
        } else if (evaluation.fuel === "RED" && fuel !== "red") {
          groupedEvaluations[`${evaluation.installationId}_fuel`] = "red";
        }
      }
    }

    for (const chart of uptimeEquipmentCharts) {
      const equipmentItem = this.props.equipments.data.find((equipment) => equipment.id === chart.equipmentId);

      if (equipmentItem) {
        const engineAvailability = groupedEvaluations[`${equipmentItem.installationId}_engineAvailability`];

        if (chart.availabilityStatus === "GREEN" && !engineAvailability) {
          groupedEvaluations[`${equipmentItem.installationId}_engineAvailability`] = "green";
        } else if (chart.availabilityStatus === "RED" && engineAvailability !== "RED") {
          groupedEvaluations[`${equipmentItem.installationId}_engineAvailability`] = "red";
        }
      }
    }

    return groupedEvaluations;
  }

  private readonly isUptimeBusy = () =>
    this.props.isUptimeEquipmentChartsBusy && this.props.uptimeEquipmentCharts.length === 0;
  private readonly isFluidEvaluationsBusy = () =>
    this.props.fluidReportEvaluations.status === APIFetchStatus.Busy &&
    this.props.fluidReportEvaluations.data &&
    this.props.fluidReportEvaluations.data.length === 0;
}
