import { Field } from "formik";
import { IInstallation } from "online-services-types";
import * as React from "react";

import { IAPIResource } from "src/APIFetch";
import { FormDialog } from "src/components/Dialog";
import { StyledFormField } from "src/design-system/Form/Form";
import { Select } from "src/design-system/Select";
import { ISelectObject } from "src/design-system/Select/Select";
import { SwitchButton, SwitchButtonWrapper } from "src/design-system/SwitchButton";
import { TextArea } from "src/design-system/TextArea";
import { TextInput } from "src/design-system/TextInput";
import { themes } from "src/design-system/Theme/theme";
import { sendNewInstallationRequest, sendRemoveInstallationRequest } from "src/redux/actions";
import { translateString } from "src/util/localization";
import { validateForm, validators } from "src/util/validators";
import { ThemeProvider } from "styled-components";

enum RequestType {
  AddInstallation,
  RemoveInstallation,
}

export interface IInstallationRequestForm {
  name: string;
  imoNumber: string;
  description: string;
  installation?: IInstallation;
}

export interface IAddInstallationComponentProps {
  installations: IAPIResource<IInstallation>;
  isFormOpen: boolean;
  formClose(): void;
}

export interface IAddInstallationComponentState {
  requestType: RequestType;
  selectedInstallation?: IInstallation;
}

export class InstallationRequestComponent extends React.Component<
  IAddInstallationComponentProps,
  IAddInstallationComponentState
> {
  public constructor(props: IAddInstallationComponentProps) {
    super(props);

    this.state = {
      requestType: RequestType.AddInstallation,
      selectedInstallation: undefined,
    };
  }

  public render() {
    return (
      <FormDialog<IInstallationRequestForm>
        title={translateString("addInstallation")}
        submitButtonTitle={translateString("request.send")}
        isDialogOpen={this.props.isFormOpen}
        onCancel={this.props.formClose}
        onSubmit={this.onFormSubmit}
        initialValues={{
          name: "",
          imoNumber: "",
          description: "",
          installation: undefined,
        }}
        validate={this.validateForm}
      >
        <>
          <ThemeProvider theme={themes.light}>
            <SwitchButtonWrapper>
              <SwitchButton
                label={translateString("addInstallation")}
                isSelected={this.state.requestType === RequestType.AddInstallation}
                onClick={() => this.setState({ requestType: RequestType.AddInstallation })}
              />
              <SwitchButton
                label={translateString("removeInstallation")}
                isSelected={this.state.requestType === RequestType.RemoveInstallation}
                onClick={() => this.setState({ requestType: RequestType.RemoveInstallation })}
              />
            </SwitchButtonWrapper>
          </ThemeProvider>

          {this.state.requestType === RequestType.AddInstallation ? (
            <div>
              <Field name="name" label={translateString("installation.name")} component={TextInput} />
              <Field
                name="imoNumber"
                label={`${translateString("installation.imo")} (${translateString("optionalFieldTitle")})`}
                component={TextInput}
              />
              <Field
                name="description"
                label={`${translateString("installation.description")} (${translateString("optionalFieldTitle")})`}
                component={TextArea}
              />
            </div>
          ) : null}

          {this.state.requestType === RequestType.RemoveInstallation ? (
            <div>
              <StyledFormField>
                <Field
                  label={translateString("installation")}
                  name="installation"
                  itemsToShow={4}
                  options={this.props.installations.data}
                  getOptionLabel={(option: ISelectObject<IInstallation>) => option.item.name}
                  getOptionValue={(option: ISelectObject<IInstallation>) => option.item.id}
                  component={Select}
                />
              </StyledFormField>
              <Field
                name="description"
                label={`${translateString("installation.description")} (${translateString("optionalFieldTitle")})`}
                component={TextArea}
              />
            </div>
          ) : null}
        </>
      </FormDialog>
    );
  }

  public onFormSubmit = async (values: IInstallationRequestForm) => {
    if (this.state.requestType === RequestType.AddInstallation) {
      await sendNewInstallationRequest({
        name: values.name,
        imoNumber: values.imoNumber,
        description: values.description,
      });
    }

    if (this.state.requestType === RequestType.RemoveInstallation && values.installation) {
      await sendRemoveInstallationRequest({
        installationId: values.installation.id,
        description: values.description,
      });
    }

    this.props.formClose();
  };

  private readonly validateForm = (fields: any) => {
    if (this.state.requestType === RequestType.AddInstallation) {
      return validateForm(fields, {
        name: [{ valErr: translateString("addInstallation.nameIsRequired"), valFn: validators.isRequired }],
      });
    }

    if (this.state.requestType === RequestType.RemoveInstallation) {
      return validateForm(fields, {
        installation: [{ valErr: translateString("pleaseAddInstallation"), valFn: validators.isRequired }],
      });
    }
    return {};
  };
}
