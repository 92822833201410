// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const eula_es_MX: ITranslations = {
  es_MX: {
    "eula.acceptPrivacyStatement": "Acepto la {privacyStatement}",
    "eula.acceptTermsAndConditions": "Acepto los {termsAndConditions}",
    "eula.agreeButtonText": "Continuar",
    "eula.cancelButtonText": "Cancelar y cerrar sesión",
    "eula.caption": "Antes que pueda usar Wärtsilä Online tiene que leer y aceptar los Términos y Condiciones de la declaración de Privacidad.",
    "eula.privacyStatement": "Declaración de privacidad",
    "eula.termsAndConditions": "Términos y Condiciones",
    "eula.title": "Aceptar Términos y condiciones",
    "eula.validate.acceptPrivacyStatement": "Tiene que aceptar la Declaración de privacidad",
    "eula.validate.acceptTermsAndConditions": "Tiene que aceptar los Términos y Condiciones",
  },
};
