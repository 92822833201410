import * as React from "react";
import styled from "styled-components";

import logo from "./w_white.png";

const Img = styled.img`
  width: 130px;
  margin-left: 10px;
`;

export const WartsilaLogoWhite: React.FC = () => <Img src={logo} alt="Wärtsilä Logo White" />;
