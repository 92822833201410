import { Dispatch, bindActionCreators } from "redux";
import { ILeadTextDispatchProps, ILeadTextOwnProps, ILeadTextStateProps, LeadTextComponent } from "./LeadTextComponent";

import { IAppState } from "src/redux";
import { connect } from "react-redux";
import { fetchMultiplePartAvailability } from "src/redux/partsAvailability";

const mapStateToProps = (state: IAppState, ownProps: ILeadTextOwnProps): ILeadTextStateProps => {
  return {
    isReady: state.partsAvailability.isReady,
    partsAvailability: state.partsAvailability.availability,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ILeadTextDispatchProps =>
  bindActionCreators(
    {
      getMultiplePartAvailability: fetchMultiplePartAvailability,
    },
    dispatch
  );

const LeadTextContainer = connect<ILeadTextStateProps, ILeadTextDispatchProps, ILeadTextOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(LeadTextComponent);

export { LeadTextContainer };
