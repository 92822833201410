import { IError } from "online-services-types";
import { Dispatch } from "redux";

import { notifyAboutSessionExpiration, resetExpiration } from "src/redux/auth";
import { dispatchGlobal } from "src/redux/globalDispatch";
import { translateString } from "./util/localization";

// 'Error from SF: Session expired or invalid' if token is expired, code is '500'
export const handleErrorMessages = (error: IError, dispatch: Dispatch<any> | undefined) => {
  if (error.message === "Error from SF: Session expired or invalid") {
    if (dispatch) {
      dispatch(notifyAboutSessionExpiration());
    }
    throw new Error("Session expired or invalid");
  } else {
    throw error;
  }
};

export const checkTokens = (dispatch: Dispatch<any> | undefined, accessToken: string, offlineToken?: string) => {
  if (!accessToken && !offlineToken) {
    if (dispatch) {
      dispatch(notifyAboutSessionExpiration());
    }
    throw new Error("Session expired or invalid");
  }
};

// Pass through normal exceptions and map TypeError errors to a generic network error message

const handleNetworkError = (error: Error | TypeError) => {
  if (error instanceof TypeError) {
    return new Error(translateString("error.networkError"));
  }

  return error;
};

export enum APIFetchStatus {
  Idle,
  Busy,
  Success,
  Failed,
}

export interface IAPIResource<T> {
  status: APIFetchStatus;
  data: T[];
  errorMessage?: string;
  totalCount?: number;
}

export interface IAPICallParams {
  [key: string]: string | number | undefined;
}

export class APIFetch<T, ReturnType = T> {
  private readonly pathBase: string;
  private readonly resourceChild?: string;
  private readonly disableRetryPatch: boolean;
  private readonly disableRetryPost: boolean;
  private readonly offlineToken?: string;
  private readonly disableAuthKeepAlive: boolean;
  private readonly maxRetryCount = 15;
  private readonly retryWaitBaseTimeMs = 500;
  private readonly retryResponses = ["UNABLE_TO_LOCK_ROW"];
  private additionalHeaders: { [header: string]: string } = {};

  /**
   * Handles fetches from the API.
   *
   * @constructor
   * @param {string} resource The resource to be fetched with the service path prefix. E.g. "service/installations". APIFetch will internally
   *  prepend the path with REACT_APP_API_URL_BASE, i.e. the final example path will be "/api-service/installations".
   * @param {string} resourceChild The child resource to the parent resource. This will be appended to the final path when ID is used when
   *  making a GET request. E.g. using a child resource of "contracts" in the above example will result in paths like "/api-service/installations/{id}/contracts"
   * @param {boolean} disableRetry APIFetch will retry post a few times (configured in maxRetryCount) if the backend responds with a suitable error code. This flag
   *  prevents the retries.
   * @param {string} offlineToken Offline token to be passed instead of the app-wide auth token
   * @param {boolean} disableAuthKeepAlive Disable resetting of token expiration
   */

  constructor(
    resource: string,
    resourceChild?: string,
    disableRetryPost = false,
    disableRetryPatch = true,
    offlineToken?: string,
    disableAuthKeepAlive = false
  ) {
    this.pathBase = `${process.env.REACT_APP_API_URL_BASE}${resource}`;
    this.resourceChild = resourceChild;
    this.disableRetryPatch = disableRetryPatch;
    this.disableRetryPost = disableRetryPost;
    this.offlineToken = offlineToken;
    this.disableAuthKeepAlive = disableAuthKeepAlive;
  }

  public async getWithParams(params: IAPICallParams): Promise<ReturnType> {
    return this.get(undefined, params);
  }

  public async get(id?: string | number, params?: IAPICallParams): Promise<ReturnType> {
    const accessToken: string = localStorage.getItem("token") || "";

    checkTokens(dispatchGlobal, accessToken, this.offlineToken);

    let path = this.pathBase;

    if (id) {
      path += `/${encodeURIComponent(id)}${this.resourceChild ? "/" + this.resourceChild : ""}`;
    }

    if (params && Object.keys(params).length > 0) {
      path += `?${Object.keys(params)
        .filter((key) => Boolean(params[key]) || params[key] === 0)
        .map((key) => `${key}=${encodeURIComponent((params[key] ?? "").toString())}`)
        .join("&")}`;
    }

    const headers: { [key: string]: string } = {
      Authorization: accessToken,
      "Cache-Control": "no-cache",
      "x-wos-view": window.location.pathname,
      ...this.additionalHeaders,
    };

    if (this.offlineToken) {
      headers["x-wos-token"] = this.offlineToken;
    }

    try {
      const result = await fetch(path, {
        headers,
      });

      this.fireDispatch();

      if (!result.ok) {
        // Result JSON is of type IError
        handleErrorMessages((await result.json()) as IError, dispatchGlobal);
      }

      return result.json();
    } catch (error) {
      throw handleNetworkError(error);
    }
  }

  public async postWithParams(data: T, params: IAPICallParams, id?: string): Promise<ReturnType | null> {
    const accessToken: string = localStorage.getItem("token") || "";

    checkTokens(dispatchGlobal, accessToken, this.offlineToken);

    let path = this.pathBase;

    if (id) {
      path += `/${id}${this.resourceChild ? "/" + this.resourceChild : ""}`;
    }

    if (Object.keys(params).length > 0) {
      path += `?${Object.keys(params)
        .filter((key) => Boolean(params[key]) || params[key] === 0)
        .map((key) => `${key}=${encodeURIComponent((params[key] ?? "").toString())}`)
        .join("&")}`;
    }


    const payload = {
      body: JSON.stringify(data),
      headers: {
        Authorization: accessToken,
        "Cache-Control": "no-cache",
        "x-wos-view": window.location.pathname,
        "x-wos-token": this.offlineToken || "",
        ...this.additionalHeaders,
      },
      method: "post",
    };

    try {
      const result = await this.doFetch(path, payload, !this.disableRetryPost);

      if (!result.ok) {
        // Result JSON is of type IError
        const error = (await result.json()) as IError;
        handleErrorMessages(error, dispatchGlobal);
        return null;
      }

      return result.json();
    } catch (error) {
      throw handleNetworkError(error);
    }
  }

  public async post(data: T, id?: string): Promise<ReturnType | null> {
    const accessToken: string = localStorage.getItem("token") || "";

    checkTokens(dispatchGlobal, accessToken, this.offlineToken);

    let path = this.pathBase;

    if (id) {
      path += `/${id}${this.resourceChild ? "/" + this.resourceChild : ""}`;
    }

    const payload = {
      body: JSON.stringify(data),
      headers: {
        Authorization: accessToken,
        "Cache-Control": "no-cache",
        "x-wos-view": window.location.pathname,
        "x-wos-token": this.offlineToken || "",
        ...this.additionalHeaders,
      },
      method: "post",
    };

    try {
      const result = await this.doFetch(path, payload, !this.disableRetryPost);

      if (!result.ok) {
        // Result JSON is of type IError
        const error = (await result.json()) as IError;
        handleErrorMessages(error, dispatchGlobal);
        return null;
      }

      return result.json();
    } catch (error) {
      throw handleNetworkError(error);
    }
  }

  public async delete(id: string): Promise<void> {
    const accessToken: string = localStorage.getItem("token") || "";

    checkTokens(dispatchGlobal, accessToken, this.offlineToken);

    let path = this.pathBase;

    if (id) {
      path += `/${id}`;
    }

    try {
      const result = await fetch(path, {
        headers: {
          Authorization: accessToken,
          "Cache-Control": "no-cache",
          "x-wos-view": window.location.pathname,
          "x-wos-token": this.offlineToken || "",
          ...this.additionalHeaders,
        },
        method: "delete",
      });

      this.fireDispatch();

      if (!result.ok) {
        // Result JSON is of type IError
        handleErrorMessages((await result.json()) as IError, dispatchGlobal);
      }
    } catch (error) {
      throw handleNetworkError(error);
    }
  }

  public async patch(data: Partial<T> | Array<Partial<T>>, id?: string): Promise<ReturnType | null> {
    const accessToken: string = localStorage.getItem("token") || "";

    checkTokens(dispatchGlobal, accessToken, this.offlineToken);

    let path = this.pathBase;

    if (id) {
      path += `/${id}`;
    }

    try {
      const result = await this.doFetch(
        path,
        {
          body: JSON.stringify(data),
          headers: {
            Authorization: accessToken,
            "Cache-Control": "no-cache",
            "x-wos-view": window.location.pathname,
            "x-wos-token": this.offlineToken || "",
            ...this.additionalHeaders,
          },
          method: "PATCH",
        },
        !this.disableRetryPatch
      );

      this.fireDispatch();

      if (!result.ok) {
        // Result JSON is of type IError
        handleErrorMessages(await result.json(), dispatchGlobal);
        return null;
      }

      return result.json();
    } catch (error) {
      throw handleNetworkError(error);
    }
  }

  public addHeaders(headers: { [header: string]: string }) {
    this.additionalHeaders = { ...this.additionalHeaders, ...headers };
    return this;
  }

  private fireDispatch() {
    if (dispatchGlobal && !this.disableAuthKeepAlive) {
      dispatchGlobal(resetExpiration());
    }
  }

  // Post and patch messages can retried if the retry functionality is not disabled
  // and the backend response is correct
  private readonly doFetch = async (
    fetchPath: string,
    fetchPayload: RequestInit,
    enableRetry = false,
    retryCount = 1
  ): Promise<Response> => {
    const fetchResult = await fetch(fetchPath, fetchPayload);
    this.fireDispatch();
    if (!fetchResult.ok) {
      const error = (await fetchResult.json()) as IError;
      const { errorCode } = error;
      if (retryCount <= this.maxRetryCount && enableRetry && errorCode && this.retryResponses.includes(errorCode)) {
        return new Promise<Response>((resolve) => {
          setTimeout(() => {
            resolve(this.doFetch(fetchPath, fetchPayload, enableRetry, retryCount + 1));
          }, this.retryWaitBaseTimeMs * retryCount);
        });
      }

      // .json() was called above to check for the error which consumes the stream and it cannot be called
      // again (below). So we just patch in the error JSON as a normal method.
      return Object.assign(fetchResult, { json: () => error });
    } else {
      return fetchResult;
    }
  };
}
