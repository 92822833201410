import { ICombinedRequest } from "online-services-types";
import * as React from "react";
import { CommentVerb } from "src/components/Comments/CommentsComponent";
import { LocalizedString } from "src/components/Localization";
import { RequestComments } from "src/components/RequestComments";
import { NotificationBase } from "src/components/SupportList/NotificationBase";
import { Disclaimer } from "src/design-system/Tokens/typography";
import {
  NotificationType,
  RequestWaitingForReplyNotification,
  INotificationContext,
  NotificationContext,
} from "src/models/notifications";
import { KeyValuePair, KeyValueValue } from "src/design-system/Table/Table";
import { translateString } from "src/util/localization";

interface IReplyRequestComponentProps {
  isReadOnly: boolean;
  item: ICombinedRequest;
  notification: RequestWaitingForReplyNotification;
  refreshNotifications(): void;
  onDismiss?: () => void;
  discardNotification(notificationIds: string[], notificationContext: INotificationContext | null): void;
  discardAndSetNotificationAsRead(notificationIds: string[], notificationContext: INotificationContext | null): void;
  refreshComments?(id: string): void;
}

interface IReplyRequestComponentState {
  commentsOpen: boolean;
}

export class ReplyRequestComponent extends React.Component<IReplyRequestComponentProps, IReplyRequestComponentState> {
  constructor(props: IReplyRequestComponentProps) {
    super(props);
    this.state = {
      commentsOpen: false,
    };
  }

  public handleReply = () => {
    this.setState({ commentsOpen: true });
  };

  public handleCancelComment = () => {
    this.setState({ commentsOpen: false });
  };

  public handleCommentPosted = (notificationContext: INotificationContext) => {
    const notificationIds = this.props.notification.id.split("_");
    this.props.discardNotification(notificationIds, notificationContext);
    this.props.refreshNotifications();
    if (this.props.refreshComments) {
      this.props.refreshComments(this.props.item.id);
    }
  };

  public handleDismiss = (notificationContext: INotificationContext) => {
    const notificationIds = this.props.notification.id.split("_");
    this.props.discardAndSetNotificationAsRead(notificationIds, notificationContext);
  };

  public render() {
    return (
      <NotificationContext.Consumer>
        {(context: INotificationContext) => (
          <NotificationBase
            onDismiss={
              this.props.notification.type !== NotificationType.WaitingForReply
                ? () => this.handleDismiss(context)
                : undefined
            }
          >
            {this.state.commentsOpen ? (
              <KeyValuePair>
                <Disclaimer>
                  <LocalizedString id="commenting" />
                </Disclaimer>
                <KeyValueValue>
                  <RequestComments
                    request={this.props.item}
                    isCommentingDisabled={this.props.isReadOnly}
                    requestCanBeReopened={false}
                    verb={CommentVerb.Reply}
                    onCommentPosted={() => this.handleCommentPosted(context)}
                    onCancel={this.handleCancelComment}
                  />
                </KeyValueValue>
              </KeyValuePair>
            ) : (
              <NotificationBase onDismiss={this.props.onDismiss}>
                {translateString("request.notification.newComment")}
              </NotificationBase>
            )}
          </NotificationBase>
        )}
      </NotificationContext.Consumer>
    );
  }
}
