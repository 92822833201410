import * as React from "react";
import { LocalizedString } from "src/components/Localization";
import { Button, ButtonStyle } from "src/design-system/Button";
import { ICombinedRequestViewModel } from "src/models/warrantyClaim";
import { getRoutes, redirectBrowser } from "src/routes";
import styled from "styled-components";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";

interface IDraftButtonsProps {
  claim: ICombinedRequestViewModel;
  onDelete?(requestType: string, id: string): void;
}

interface IDraftButtonsState {
  isConfirmDialogOpen: boolean;
}

const DraftButtonsWrapper = styled.div`
  width: 100%;
  display: inline-flex;

  & > * {
    margin-right: 20px;
  }
`;

export class DraftButtons extends React.Component<IDraftButtonsProps, IDraftButtonsState> {
  constructor(props: IDraftButtonsProps) {
    super(props);

    this.state = {
      isConfirmDialogOpen: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  public handleDelete() {
    this.setState({ isConfirmDialogOpen: false });
    if (this.props.onDelete) {
      this.props.onDelete(this.props.claim.requestType, this.props.claim.id);
    }
  }

  public handleCancel() {
    this.setState({ isConfirmDialogOpen: false });
  }

  public render() {
    const { claim, onDelete } = this.props;
    const isDraft = claim.status === "Draft";

    if (!isDraft) {
      return null;
    }

    const confirmDelete = () => {
      this.setState({ isConfirmDialogOpen: true });
    };

    return (
      <DraftButtonsWrapper>
        <Button
          onClick={() =>
            redirectBrowser(getRoutes().CreateWarrantyClaim, {
              phase: 1,
              requestType: claim.requestType,
              draftId: claim.id,
            })
          }
        >
          <LocalizedString id="draft.continueEditing" />
        </Button>
        {onDelete && (
          <Button buttonStyle={ButtonStyle.Secondary} onClick={() => confirmDelete()}>
            <LocalizedString id="draft.delete" />
          </Button>
        )}
        <ConfirmDeleteDialog
          isOpen={this.state.isConfirmDialogOpen}
          onOk={this.handleDelete}
          onCancel={this.handleCancel}
        />
      </DraftButtonsWrapper>
    );
  }
}
