// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_ja: ITranslations = {
  ja: {
    "documents.allDocuments": "すべての文書",
    "documents.answer": "回答",
    "documents.appliesTo": "適用先",
    "documents.applyFilter": "フィルターを適用する",
    "documents.button.download": "ダウンロード",
    "documents.button.open": "開",
    "documents.button.print": "Print (印刷)",
    "documents.clearFilter": "フィルターを削除する",
    "documents.documentIssue": "文書発行",
    "documents.documentNumber": "文書番号",
    "documents.documentType": "文書タイプ",
    "documents.documentTypes.ASI": "速報",
    "documents.documentTypes.CMREPORT": "資産診断レポート",
    "documents.documentTypes.FAQ": "FAQ",
    "documents.documentTypes.MAN": "取扱説明書",
    "documents.documentTypes.SPC": "予備部品カタログ",
    "documents.documentTypes.SW": "ソフトウェア",
    "documents.documentTypes.SWR": "作業レポート",
    "documents.documentTypes.TECHREQUEST": "技術サポート",
    "documents.documentTypes.TR": "技術レポート",
    "documents.documentTypesLongText.MAN": "作業保守マニュアル",
    "documents.documentTypesLongText.SPC": "予備部品カタログ",
    "documents.documentTypesLongText.couldntFindArticle": "記載が見つかりませんでした!",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "記載取得中のエラー: {error}",
    "documents.emptyDataText": "検索範囲を絞り込むには、以下のオープンテキスト、フィルター、または定義済みの範囲を使用します。",
    "documents.files": "ファイル",
    "documents.filterBasedOnDocumentType": "文書タイプに基づいてフィルター処理する",
    "documents.forImmediateAction": "応急処置のため",
    "documents.listHeader": "文書 ({totalItems})",
    "documents.listHeaderEmptyPattern": "お気に入りの文書",
    "documents.listHeaderNoItems": "文書",
    "documents.loadMore": "さらにロード",
    "documents.nickName": "ニックネーム",
    "documents.productReferenceType": "製品参照タイプ",
    "documents.question": "質問",
    "documents.releaseNotes": "リリースノート",
    "documents.scopeButton.ASI": "最近の速報",
    "documents.scopeButton.MAN": "最近の取扱説明書",
    "documents.search": "検索",
    "documents.sections.attachments": "添付書類",
    "documents.sections.chapters": "チャプター",
    "documents.sections.workcards": "ワークカード",
    "documents.shared.documentTypes.MF_CL_Analysis": "分析",
    "documents.shared.documentTypes.MF_CL_Claims": "請求",
    "documents.shared.documentTypes.MF_CL_Failure": "異常",
    "documents.shared.documentTypes.MF_CL_Inventories": "在庫品",
    "documents.shared.documentTypes.MF_CL_MaintenancePlans": "メンテナンス計画",
    "documents.shared.documentTypes.MF_CL_MeetingMinutes": "議事録",
    "documents.shared.documentTypes.MF_CL_Report": "レポート",
    "documents.shared.documentTypes.MF_CL_SFOCReport": "SFOCレポート",
    "documents.shared.documentTypes.MF_CL_Training": "研修",
    "documents.shared.from": "開始",
    "documents.shared.to": "終了",
    "documents.sorting.documentNumberAZ": "文書番号 (A-Z)",
    "documents.sorting.documentTypeAZ": "文書タイプ (A-Z)",
    "documents.sorting.titleAZ": "タイトル (A-Z)",
    "documents.title": "タイトル",
    "documents.typeToSearch": "入力して検索...",
    "documents.updateNotificationText.ASI": "速報を確認するにはアクションが必要です",
    "documents.updateNotificationText.MAN": "チャプターをアップデートしました",
    "documents.updateNotificationText.TR": "新しい技術レポートが発行されました",
    "documents.updateNotificationText.other": "文書がアップデートされました",
  },
};
