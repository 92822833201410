import {
  ICombinedRequest,
  ICustomerSupportTicket,
  IDelivery,
  IJWTUserInfo,
  INotification,
  IOperationalSupportRequest,
  IServiceLimitations,
  ISparePartClaim,
  ITechRequest,
  IWarrantyClaim,
} from "online-services-types";
import * as React from "react";
import VisibilitySensor from "react-visibility-sensor";

import { APIFetch } from "src/APIFetch";
import {
  NewReportRowWrapper,
  NoRequestsFoundIndicator,
  NotificationHistoryLink,
} from "src/components/CommerceList/styles";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { IAppClaimComment } from "src/components/RequestComments/RequestCommentsComponent";
import { ResponsiveTable } from "src/components/ResponsiveTable";
import { IResponsiveTableColumn, ISort } from "src/components/ResponsiveTable/interfaces";
import { NewAttachment } from "src/components/SupportList/NewAttachment";
import { NewRequest } from "src/components/SupportList/NewRequest";
import { NotificationBase } from "src/components/SupportList/NotificationBase";
import { Button } from "src/design-system/Button";
import { Container, FlexContainer } from "src/design-system/Container";
import colors from "src/design-system/Tokens/colors";
import { spacer, spacers } from "src/design-system/Tokens/tokens";
import { H2 } from "src/design-system/Tokens/typography";

import { CloseIcon, IconSize, WarningIcon } from "src/icons";
import { VisibilityIcon } from "src/icons/VisibilityIcon";
import {
  filterDeliveryNotifications,
  filterNewRequestAttachments,
  filterNewRequestNotifications,
  filterRequestNotifications,
  filterRequestNotificationsIncludingDiscarded,
  filterResolvedSparePartNotifications,
  filterSolutionPlanNotifications,
  filterWaitingForRatingNotifications,
  filterWaitingForReplyNotifications,
  INotificationContext,
  NotificationContext,
  NotificationType,
  RequestWaitingForRatingNotification,
  RequestWaitingForReplyNotification,
} from "src/models/notifications";
import { ICombinedRequestViewModel, RequestType } from "src/models/warrantyClaim";
import { doSearchTermsMatchResourceFields, getSearchTermArrayFromString } from "src/util/helpers";
import { translateString } from "src/util/localization";
import { RequestStatus } from "src/util/requestStatus";
import { validators } from "src/util/validators";
import styled from "styled-components";
import { INotificationRow, NotificationContentMap, NotificationWithMultiple } from "../CommerceList/types";
import { Dialog } from "../Dialog";
import { CloseIconWrapper } from "../Dialog/Dialog";
import { ISizeListener } from "../GenericList/GenericList";
import { LocalizedStringComponent } from "../Localization/LocalizedStringComponent";
import { IApprovalData } from "./Approval/rating";
// Columns
import {
  accountName,
  attachments,
  claimQuantity,
  claimReason,
  closeClaim,
  contacts,
  createdByEmail,
  createdByName,
  createdByPhone,
  createdDate,
  customerClaimReference,
  deliveries,
  deliveryNumber,
  description,
  detailedDescription,
  external,
  isConfiguredToReadOnly,
  itemCount,
  lastModifiedDate,
  linkedRequests,
  openChat,
  openClaim,
  poNumber,
  product,
  productref,
  reopenButton,
  requestId,
  requestType,
  solutionPlan,
  solutionPlanProvided,
  solutionRating,
  solutionText,
  SparePartpart,
  spcRating,
  status,
  subject,
  submittedDate,
  suggestedParts,
  ticketComments,
  vessel,
  warrantyEndDateForShipyard,
  wartsilaInitiatedClaim,
  wartsilaOrderNumber,
} from "./columns";
import { RatingButtons } from "./RatingButtons";
import { IReopenFormFields } from "./ReopenDialog/ReopenDialogComponent";
import { ReplyRequest } from "./ReplyRequest";

const Wrapper = styled.div`
  & ${H2} {
    margin-top: 0;
  }
`;
export const ExtraRowWithMargin = styled.div`
  margin-left: ${spacer(7)};
  padding-bottom: ${spacer(2)};
`;

export const ExtraRowWithIcon = styled.div`
  display: flex;
  margin-left: 0;
  padding: ${spacers([1, 0, 3])};
`;

export const IconWrapper = styled.div`
  width: 30px;
  margin-left: ${spacer(2)};
`;

export const ShipyardVisibilityContainer = styled(FlexContainer)`
  font-size: 0.8rem;
`;

const NotificationRow = ({
  translationId,
  values,
  moreNotifsNum,
  onMoreClick,
  discarded,
  discard,
}: INotificationRow) => {
  return (
    <NewReportRowWrapper discarded={discarded}>
      <WarningIcon size={IconSize.Small} />
      <span>
        <LocalizedStringComponent id={translationId} values={values} />
        {moreNotifsNum && (
          <NotificationHistoryLink onClick={onMoreClick}>
            <LocalizedStringComponent id={"notifications.moreNewNotifications"} values={{ number: moreNotifsNum }} />
          </NotificationHistoryLink>
        )}
      </span>
      {discard ? (
        <CloseIconWrapper onClick={discard}>
          <CloseIcon size={IconSize.XSmall} color={colors.primary.gray} />
        </CloseIconWrapper>
      ) : null}
    </NewReportRowWrapper>
  );
};

export interface IWarrantyClaimListOwnProps {
  header?: (visibleRows: ICombinedRequestViewModel[], rows: ICombinedRequestViewModel[]) => React.ReactNode;
  sortOrder: ISort;
  select?: (itemId: string) => void;
  selected?: string;
  outline?: boolean;
  filters?: { [key: string]: string | string[] };
  preselectedRequestId?: string;
  includeWarrantyId?: boolean;
  isPartsEnabled?: boolean;
  useOnboardingMockData?: boolean;
  isQuantipartsDistributor?: boolean;
  serviceLimitations?: IServiceLimitations;
}

export interface IWarrantyClaimListStateProps {
  notifications: INotification[];
  requests: ICombinedRequestViewModel[];
  showInstallationColumn: boolean;
  isReady?: boolean;
  installationId?: string;
  user?: IJWTUserInfo;
  isEnergy: boolean;
}

export interface IWarrantyClaimListDispatchProps {
  getClaims(): void;
  getEquipments(): void;
  getInstallations(): void;
  refreshNotifications(): void;
  discardNotification(ids: string[], notificationContext: INotificationContext | null): void;
  deleteDraft?(requestType: RequestType, id: string): void;
  approveRequest(request: ICombinedRequest, data: IApprovalData): void;
  reopenRequest(request: ICombinedRequest, data: IReopenFormFields): void;
  discardAndSetNotificationAsRead(ids: string[], notificationContext: INotificationContext | null): void;
  confirmCloseWarrantyClaim(claim: IWarrantyClaim): void;
}

export type IWarrantyClaimListPropsNoContainer = IWarrantyClaimListStateProps &
  IWarrantyClaimListOwnProps &
  IWarrantyClaimListDispatchProps;
export type IWarrantyClaimListProps = ISizeListener & IWarrantyClaimListPropsNoContainer;

export type ICombinedRequestWithNotificationsViewModel = ICombinedRequestViewModel & {
  notifications: INotification[];
  isRelevant: boolean;
};

export interface IWarrantyClaimListComponentState {
  uploaderBusy: boolean;
  sortItemKey?: string;
  visibleItems: number;
  parsedSearch: string[];
  selectedRequestId: string | null;
  commentRefreshFns: { [item: string]: () => void };
  commentPlaceholders: { [item: string]: IAppClaimComment[] };
}

export const AnswerRow = (props: {
  item: ICombinedRequestViewModel;
  notifications: RequestWaitingForRatingNotification[];
  approveRequest(request: ICombinedRequest, data: IApprovalData): void;
  reopenRequest(request: ICombinedRequest, data: IReopenFormFields): void;
  discardNotification(ids: string[], notificationContext: INotificationContext): void;
}) => {
  const notificationIds = props.notifications.map((notification) => notification.id);
  return (
    <div className={"support-onboarding-rate-service"}>
      <NotificationContext.Consumer>
        {(context) => (
          <RatingButtons
            item={props.item}
            approveRequest={(request, data) => {
              props.discardNotification(notificationIds, context);
              props.approveRequest(request, data);
            }}
            reopenRequest={(request, data) => {
              props.discardNotification(notificationIds, context);
              props.reopenRequest(request, data);
            }}
          />
        )}
      </NotificationContext.Consumer>
    </div>
  );
};

export const ReplyRow = (props: {
  isReadOnly: boolean;
  item: ICombinedRequestViewModel;
  notification: RequestWaitingForReplyNotification;
  refreshNotifications(): void;
  discardNotification(ids: string[], notificationContext: INotificationContext | null): void;
  discardAndSetNotificationAsRead(ids: string[], notificationContext: INotificationContext | null): void;
  refreshComments?(id: string): void;
}) => {
  return (
    <div>
      <ReplyRequest
        isReadOnly={props.isReadOnly}
        item={props.item}
        refreshNotifications={props.refreshNotifications}
        discardNotification={props.discardNotification}
        discardAndSetNotificationAsRead={props.discardAndSetNotificationAsRead}
        notification={props.notification}
        refreshComments={props.refreshComments}
      />
    </div>
  );
};

export class SupportListComponent extends React.Component<IWarrantyClaimListProps, IWarrantyClaimListComponentState> {
  constructor(props: IWarrantyClaimListProps) {
    super(props);

    this.getColumnContentForSort = this.getColumnContentForSort.bind(this);
    this.refreshComments = this.refreshComments.bind(this);
    this.addCommentPlaceholder = this.addCommentPlaceholder.bind(this);
    this.popCommentPlaceholder = this.popCommentPlaceholder.bind(this);
    this.registerCommentRefreshFn = this.registerCommentRefreshFn.bind(this);
    this.handleBusyChange = this.handleBusyChange.bind(this);
    this.confirmCloseClaim = this.confirmCloseClaim.bind(this);

    this.state = {
      uploaderBusy: false,
      visibleItems: 20,
      parsedSearch: [],
      commentRefreshFns: {},
      commentPlaceholders: {},
      selectedRequestId: null,
    };
  }

  public componentDidMount() {
    this.props.getClaims();
    if (!this.props.isQuantipartsDistributor) {
      this.props.getInstallations();
      this.props.getEquipments();
    }
  }

  public componentDidUpdate(prevProps: IWarrantyClaimListProps) {
    if (this.props.filters !== prevProps.filters) {
      this.setState({ visibleItems: 20 });
      this.parseSearchText();
    }
  }

  public componentWillUnmount() {
    const notificationsToDiscard: INotification[] = filterResolvedSparePartNotifications(this.props.notifications);
    this.props.discardAndSetNotificationAsRead(
      notificationsToDiscard.map((notif) => notif.id),
      null
    );
  }

  public handleBusyChange(busy: boolean) {
    this.setState({ uploaderBusy: busy });
  }

  public confirmCloseClaim(event: React.MouseEvent, claim: IWarrantyClaim) {
    event.stopPropagation();
    this.props.confirmCloseWarrantyClaim(claim);
  }

  // Used for registering an expanded item's comment field instance's refresh function
  // so that it can be triggered when replying to a notification
  public registerCommentRefreshFn(id: string, funct: () => void) {
    const functs = Object.assign({}, this.state.commentRefreshFns);
    functs[id] = funct;
    this.setState({ commentRefreshFns: functs });
  }

  // Refresh a specified item's comments. Used in notifications where you can leave a comment
  public refreshComments(id: string) {
    if (this.state.commentRefreshFns[id]) {
      this.state.commentRefreshFns[id]();
    }
  }

  // Add a new comment placeholder to the start of the array
  public addCommentPlaceholder(id: string, comment: IAppClaimComment) {
    const placeholders = Object.assign({}, this.state.commentPlaceholders);
    if (!placeholders[id]) {
      placeholders[id] = [comment];
    } else {
      placeholders[id].unshift(comment);
    }
    this.setState({ commentPlaceholders: placeholders });
  }

  // Remove the oldest comment placeholder from the end of the array
  public popCommentPlaceholder(id: string) {
    const placeholders = Object.assign({}, this.state.commentPlaceholders);
    if (placeholders[id] !== undefined && placeholders[id].length > 0) {
      placeholders[id].pop();
      this.setState({ commentPlaceholders: placeholders }, () => this.refreshComments(id));
    }
  }

  public getColumnContentForSort(item: ICombinedRequestViewModel, key: string): string {
    let value = item[key];

    switch (key) {
      case "creationDate":
        value = new Date(item.creationDate);
        break;
      case "lastModifiedDate":
        value = new Date(item.lastModifiedDate);
        break;
      case "installationId":
        value = item.installation && item.installation.name;
        break;
    }

    return value;
  }

  public handleDismissAll(item: ICombinedRequestWithNotificationsViewModel, notificationContext: INotificationContext) {
    const { notifications: notifs } = this.props;
    const notificationsToDiscard: INotification[] = filterNewRequestNotifications(notifs, item)
      .concat(filterWaitingForReplyNotifications(notifs, item))
      .concat(filterResolvedSparePartNotifications(notifs, item))
      .concat(filterNewRequestAttachments(notifs, item))
      .concat(filterSolutionPlanNotifications(notifs, item));
    notificationsToDiscard.forEach((notif) => {
      const notifIds = notif.id.split("_");
      this.props.discardAndSetNotificationAsRead(notifIds, notificationContext);
    });
  }

  public getCustomerActions = (item: ICombinedRequestWithNotificationsViewModel) => {
    const waitingForRatingNotifications = filterWaitingForRatingNotifications(item.notifications, item);
    if (!(item as ITechRequest).ratingAllowed) return [];
    return [
      <ExtraRowWithIcon>
        <IconWrapper>
          <WarningIcon size={IconSize.Small} />
        </IconWrapper>
        <div>
          <AnswerRow
            item={item}
            approveRequest={this.props.approveRequest}
            discardNotification={this.props.discardNotification}
            reopenRequest={this.props.reopenRequest}
            notifications={waitingForRatingNotifications}
          />
        </div>
      </ExtraRowWithIcon>,
    ];
  };

  public getDismissableNotifications = (
    item: ICombinedRequestWithNotificationsViewModel,
    notificationContext: INotificationContext
  ) => {
    return filterWaitingForReplyNotifications(item.notifications, item)
      .map((notification) => (
        <ExtraRowWithIcon key={notification.id}>
          <IconWrapper>
            <WarningIcon size={IconSize.Small} />
          </IconWrapper>
          <div>
            <ReplyRow
              isReadOnly={isConfiguredToReadOnly(item, this.props.serviceLimitations)}
              item={item}
              notification={notification}
              discardNotification={this.props.discardNotification}
              discardAndSetNotificationAsRead={(id) =>
                this.props.discardAndSetNotificationAsRead(id, notificationContext)
              }
              refreshNotifications={this.props.refreshNotifications}
              refreshComments={this.refreshComments}
            />
          </div>
        </ExtraRowWithIcon>
      ))
      .concat(
        filterNewRequestNotifications(item.notifications, item, true).map((notification) => (
          <ExtraRowWithIcon key={notification.id}>
            <IconWrapper>
              <WarningIcon size={IconSize.Small} />
            </IconWrapper>
            <div>
              <NewRequest
                item={item}
                onComment={
                  item.requestType !== RequestType.SparePartClaim
                    ? () => {
                        this.props.discardAndSetNotificationAsRead([notification.id], notificationContext);
                        this.refreshComments(item.id);
                      }
                    : undefined
                }
                onDismiss={() => this.props.discardAndSetNotificationAsRead([notification.id], notificationContext)}
                isReadOnly={isConfiguredToReadOnly(item, this.props.serviceLimitations)}
              />
            </div>
          </ExtraRowWithIcon>
        ))
      )
      .concat(
        filterResolvedSparePartNotifications(item.notifications, item).map((notification) => (
          <ExtraRowWithIcon key={notification.id}>
            <IconWrapper>
              <WarningIcon size={IconSize.Small} />
            </IconWrapper>
            <div>
              <NotificationBase>
                <LocalizedString id="request.notification.completedSparePartClaim" />
              </NotificationBase>
            </div>
          </ExtraRowWithIcon>
        ))
      )
      .concat(
        filterNewRequestAttachments(item.notifications, item).map((notification) => (
          <ExtraRowWithIcon key={notification.id}>
            <IconWrapper>
              <WarningIcon size={IconSize.Small} />
            </IconWrapper>
            <div>
              <NewAttachment
                item={item}
                notification={notification}
                discardNotification={this.props.discardAndSetNotificationAsRead}
              />
            </div>
          </ExtraRowWithIcon>
        ))
      )
      .concat(
        filterSolutionPlanNotifications(item.notifications, item).map((notification) => (
          <ExtraRowWithIcon key={notification.id}>
            <IconWrapper>
              <WarningIcon size={IconSize.Small} />
            </IconWrapper>
            <div>
              <NotificationBase
                onDismiss={() => this.props.discardAndSetNotificationAsRead([notification.id], notificationContext)}
              >
                <LocalizedString id="request.notification.solutionPlanIsProvided" />
              </NotificationBase>
            </div>
          </ExtraRowWithIcon>
        ))
      )
      .concat(this.renderDeliveryNotifications({ requestId: item.id, context: notificationContext }));
  };

  public getExtraRows = (
    item: ICombinedRequestWithNotificationsViewModel,
    notificationContext: INotificationContext
  ) => {
    const rows: React.ReactNode[] = [];
    const customerActions: React.ReactNode[] = this.getCustomerActions(item);
    const dismissableNotifications: React.ReactNode[] = this.getDismissableNotifications(item, notificationContext);
    const dismissAll: React.ReactNode[] = [];
    if (dismissableNotifications.length > 1) {
      dismissAll.push(
        <ExtraRowWithMargin>
          {!isConfiguredToReadOnly(item, this.props.serviceLimitations) && (
            <Button key={`${item.id}_dismiss_all`} onClick={() => this.handleDismissAll(item, notificationContext)}>
              <LocalizedString id="dismissAll" />
            </Button>
          )}
        </ExtraRowWithMargin>
      );
    }
    rows.push(customerActions.concat(dismissAll).concat(dismissableNotifications));
    return rows.length > 0 ? rows : undefined;
  };

  public rowFilter = (request: ICombinedRequestWithNotificationsViewModel) => {
    const filters = this.props.filters;
    return (
      !filters ||
      Object.keys(filters).every((filterKey) => {
        if (request.requestType !== RequestType.SparePartClaim && filterKey === "accountId") {
          // Account filter only enabled for RequestType.SparePartClaim
          return true;
        }

        const filter = filters[filterKey];

        if (filterKey === "searchText") {
          return this.isRequestSearchMatch(request);
        }

        if (
          filterKey === "displayStatus" &&
          filter instanceof Array &&
          filter.includes(RequestStatus.InProgress) &&
          (request.displayStatus === RequestStatus.InProgress || request.displayStatus === RequestStatus.Open)
        ) {
          // Note: InProgress filters both InProgress and Open
          return true;
        }

        if (
          request.requestType === RequestType.OperationalSupportRequest &&
          filterKey === "requestType" &&
          filter instanceof Array &&
          filter.includes("TechRequest")
        ) {
          // Filtering by TechRequest should also show Operational Support requests
          return true;
        }

        return (
          request[filterKey] === filter ||
          (filter instanceof Array && (filter.length === 0 || filter.includes(request[filterKey])))
        );
      })
    );
  };

  public render() {
    const requests = this.props.requests;
    const filteredRequestCount = this.requestsWithNotifications(requests).filter(this.rowFilter).length;

    const filteredRequests = requests.filter(this.rowFilter);

    return (
      <Wrapper>
        {this.props.header ? this.props.header(filteredRequests, requests) : null}
        {
          // render loading spinner
          !this.props.isReady && <LoadingSpinner />
        }
        {
          // render table
          this.props.isReady && (
            <NotificationContext.Consumer>
              {(context) => (
                <>
                  {this.renderList(this.requestsWithNotifications(requests), context)}

                  <Dialog
                    onCancel={this.closeRequestNotifications}
                    title={translateString("notifications.history")}
                    isDialogOpen={Boolean(this.state.selectedRequestId)}
                  >
                    {this.state.selectedRequestId ? (
                      this.renderDeliveryNotifications({
                        context,
                        showAllSeparately: true,
                        requestId: this.state.selectedRequestId,
                      })
                    ) : (
                      <span />
                    )}
                  </Dialog>
                </>
              )}
            </NotificationContext.Consumer>
          )
        }
        {
          // Render load more
          this.state.visibleItems < filteredRequestCount && this.renderLoadMore(filteredRequestCount)
        }
      </Wrapper>
    );
  }

  private readonly renderLoadMore = (filteredCount: number) => {
    const paginationChunkSize = 20;
    const loadMore = () =>
      this.state.visibleItems < filteredCount &&
      this.setState({ visibleItems: this.state.visibleItems + paginationChunkSize });
    return (
      <VisibilitySensor
        intervalCheck={true}
        intervalDelay={100}
        onChange={(isVisible: boolean) => filteredCount > 0 && isVisible && loadMore()}
      >
        <Button onClick={() => loadMore()}>
          <LocalizedString id="main.loadMore" />
        </Button>
      </VisibilitySensor>
    );
  };

  private readonly getColumns = (
    notificationContext: INotificationContext
  ): Array<IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel>> => {
    return [
      subject(),
      customerClaimReference(),
      product(this.props),
      vessel(this.props),
      status(this.confirmCloseClaim),
      requestId(this.props),
      closeClaim(this.props.confirmCloseWarrantyClaim),
      reopenButton(this.props),
      requestType(),
      warrantyEndDateForShipyard({ enabled: !this.props.isEnergy }),
      lastModifiedDate(),
      linkedRequests(),
      openChat(),
      openClaim(this.props),
      poNumber(),
      wartsilaOrderNumber(this.props.isPartsEnabled),
      deliveryNumber(),
      SparePartpart(),
      claimReason(),
      productref(),
      description(),
      detailedDescription(),
      claimQuantity(),
      createdDate(),
      submittedDate(),
      suggestedParts(),
      itemCount(),
      createdByEmail(),
      accountName(),
      createdByName(),
      createdByPhone(),
      wartsilaInitiatedClaim(),
      contacts(),
      external(),
      solutionPlan(this.props, notificationContext),
      solutionPlanProvided(),
      solutionRating(),
      spcRating(),
      solutionText(),
      deliveries(),
      attachments(this.props, this.state, this.handleBusyChange),
      ticketComments(
        this.props,
        this.state,
        this.registerCommentRefreshFn,
        this.refreshComments,
        this.addCommentPlaceholder,
        this.popCommentPlaceholder
      ),
    ];
  };

  private readonly renderList = (
    requests: ICombinedRequestWithNotificationsViewModel[],
    context: INotificationContext
  ) => {
    if (requests.length === 0) {
      return (
        <NoRequestsFoundIndicator>
          <LocalizedString id="request.noRequestsFound" />
        </NoRequestsFoundIndicator>
      );
    }

    return (
      <ResponsiveTable<ICombinedRequestWithNotificationsViewModel>
        displayedItemId={this.props.preselectedRequestId}
        ensureVisibleId={this.props.preselectedRequestId}
        scrollToOpenItem={true}
        getHasAlert={this.getHasAlert}
        getAlertColor={this.getAlertColor}
        filter={this.rowFilter}
        sort={this.props.sortOrder}
        isItemOpen={(item) =>
          this.props.preselectedRequestId !== undefined && item.id === this.props.preselectedRequestId
        }
        columns={this.getColumns(context)}
        limit={this.state.visibleItems}
        onSelect={this.props.select}
        selectedId={this.props.selected}
        rows={requests}
        getRowDetails={this.fetchDetails}
        hideFetchSpinner
        rowExtension={(item) => {
          if (item.isVisibleToShipyard) {
            const isShipyardUser = this.props.user?.isShipyard;
            return (
              <Container>
                {!isShipyardUser && item.isVisibleToShipyard ? (
                  <ShipyardVisibilityContainer $padding={[2, 0]} $centered>
                    <IconWrapper>
                      <VisibilityIcon color={colors.primary.black} size={IconSize.Small} />
                    </IconWrapper>
                    <Container>{translateString("request.visibleToShipyard")}</Container>
                  </ShipyardVisibilityContainer>
                ) : null}
                {this.getExtraRows(item, context)}
              </Container>
            );
          }
          return this.getExtraRows(item, context);
        }}
        className="support-onboarding-your-requests"
      />
    );
  };

  private readonly requestsWithNotifications = (requests: ICombinedRequestViewModel[]) => {
    const notifications = filterRequestNotifications(this.props.notifications);
    const relevancyNotifications = filterRequestNotificationsIncludingDiscarded(this.props.notifications);
    return requests.map((request) => ({
      ...request,
      notifications: notifications.filter((notification) => notification.objectId === request.id),
      isRelevant:
        relevancyNotifications.filter((notification) => notification.objectId === request.id).length > 0 ||
        this.getIsPendingConfirmation(request),
      displayId:
        (request as ISparePartClaim).requestId ||
        (validators.isNumeric((request as IWarrantyClaim).warrantyId) ? (request as IWarrantyClaim).warrantyId : "") ||
        (request as ITechRequest).techRequestId ||
        (request as ICustomerSupportTicket).cscId ||
        (request as IOperationalSupportRequest).operationalSupportRequestId,
      uploaderState: this.state.uploaderBusy,
      commentPlaceholders: this.state.commentPlaceholders,
      commentRefreshFns: this.state.commentRefreshFns,
    }));
  };

  private readonly parseSearchText = () => {
    if (!this.props.filters || !this.props.filters.searchText) {
      this.setState({ parsedSearch: [] });
      return;
    }
    const fullSearchText =
      this.props.filters.searchText instanceof Array
        ? this.props.filters.searchText[0].toLowerCase()
        : this.props.filters.searchText.toLowerCase();

    const searchWords: string[] = getSearchTermArrayFromString(fullSearchText);

    this.setState({ parsedSearch: searchWords });
  };

  private readonly isRequestSearchMatch = (request: ICombinedRequestWithNotificationsViewModel) => {
    if (!this.state.parsedSearch.length || (this.state.parsedSearch.length === 1 && !this.state.parsedSearch[0])) {
      return true;
    }

    const fieldsToMatch = [
      "subject",
      "requestId",
      "warrantyId",
      "techRequestId",
      "cscId",
      "operationalSupportRequestId",
      "description",
      "solutionText",
      "customerClaimNumber",
      "poNumber",
      "wartsilaOrderNumber",
      "deliveryNumber",
    ] as {} as Array<keyof ICombinedRequestWithNotificationsViewModel>;
    // Setting the const as an Array<keyof ICombinedRequestWithNotificationsViewModel> directly leads to type problems
    // with the keys that don't exist in every request type

    return doSearchTermsMatchResourceFields<ICombinedRequestWithNotificationsViewModel>(
      this.state.parsedSearch,
      request,
      fieldsToMatch
    );
  };

  private readonly getHasAlert = (row: ICombinedRequestWithNotificationsViewModel) => {
    return (
      row.notifications.length > 0 ||
      row.displayStatus === "Failed" ||
      this.getIsPendingConfirmation(row) ||
      Boolean((row as ITechRequest).ratingAllowed)
    );
  };

  private readonly getAlertColor = (row: ICombinedRequestWithNotificationsViewModel) => {
    return row.displayStatus === "Failed" ? colors.notification.error : undefined;
  };

  private readonly getIsPendingConfirmation = (
    row: ICombinedRequestWithNotificationsViewModel | ICombinedRequestViewModel
  ) => {
    return (
      row.requestType === RequestType.WarrantyClaim &&
      row.displayStatus === RequestStatus.Closed &&
      (row as IWarrantyClaim).pendingCustomerConfirmation === "Pending Customer Confirmation" &&
      !(row as IWarrantyClaim).confirmedByCustomer
    );
  };

  private readonly fetchDetails = async (
    item: ICombinedRequestWithNotificationsViewModel
  ): Promise<ICombinedRequestWithNotificationsViewModel | null> => {
    if (item.requestType === RequestType.WarrantyClaim && (item as IWarrantyClaim).deliveries) {
      try {
        const response = await new APIFetch<IDelivery[]>("requests/warrantyclaims/" + item.id + "/deliveries").get();
        if (response) {
          return { ...item, deliveries: response };
        }
        return null;
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  private readonly getNotificationContentMap = (notifications: NotificationWithMultiple[]): NotificationContentMap => {
    const map: NotificationContentMap = {};

    for (let n of notifications) {
      if (!n) continue;
      switch (n.type) {
        case NotificationType.WarrantyClaimDeliveryDocumentAvailable:
          if (n.multiple) {
            // Multiple docs
            map[n.id] = {
              translationId: "notificationList.title.WarrantyClaimDeliveryDocumentAvailableMultiple",
              values: { deliveryId: n.data.id },
            };
          } else {
            // Single doc
            map[n.id] = {
              translationId: "notificationList.title.WarrantyClaimDeliveryDocumentAvailable",
              values: { documentType: n.data.notificationDocumentType || "", deliveryId: n.data.id },
            };
          }
          break;
        case NotificationType.WarrantyClaimDeliveryReadyToBeCollected:
          map[n.id] = {
            translationId: "notificationList.title.WarrantyClaimDeliveryReadyToBeCollected",
            values: { deliveryId: n.data.id },
          };
          break;
        case NotificationType.WarrantyClaimDeliveryDispatched:
          map[n.id] = {
            translationId: "notificationList.title.WarrantyClaimDeliveryDispatched",
            values: { deliveryId: n.data.id },
          };
          break;
        case NotificationType.WarrantyClaimDeliveryDelivered:
          map[n.id] = {
            translationId: "notificationList.title.WarrantyClaimDeliveryDelivered",
            values: { deliveryId: n.data.id },
          };
          break;
        case NotificationType.WarrantyClaimDeliveryCollected:
          map[n.id] = {
            translationId: "notificationList.title.WarrantyClaimDeliveryCollected",
            values: { deliveryId: n.data.id },
          };
          break;
      }
    }

    return map;
  };

  private readonly openRequestNotifications = (item: ICombinedRequestWithNotificationsViewModel) => {
    this.setState({ selectedRequestId: item.id });
  };

  private readonly closeRequestNotifications = () => {
    this.setState({ selectedRequestId: null });
  };

  public renderDeliveryNotifications = ({
    context,
    requestId,
    showAllSeparately = false,
  }: {
    requestId: string | null;
    showAllSeparately?: boolean;
    context: INotificationContext;
  }) => {
    const item = this.requestsWithNotifications(this.props.requests).find((r) => r.id === requestId);
    if (!item?.notifications) return [];

    const notifications = filterDeliveryNotifications(item.notifications);

    const notificationsWithMultiple = notifications.filter(
      (n) => n.type !== NotificationType.WarrantyClaimDeliveryDocumentAvailable
    ) as NotificationWithMultiple[];

    const deliveryDocumentNotifications = notifications.filter(
      (n) => n.type === NotificationType.WarrantyClaimDeliveryDocumentAvailable
    ) as NotificationWithMultiple[];

    const deliveryIds = Array.from(new Set(deliveryDocumentNotifications.map((n) => n.data?.id))).filter((n) =>
      Boolean(n)
    );

    deliveryIds.forEach((id) => {
      const documentNotifications = deliveryDocumentNotifications.filter((n) => n.data?.id === id);
      if (documentNotifications.length > 0) {
        notificationsWithMultiple.push({
          ...documentNotifications[0],
          multiple: documentNotifications.length > 1,
        });
      }
    });

    const notificationContentMap = this.getNotificationContentMap(notificationsWithMultiple);

    const filteredNotifications = notificationsWithMultiple.filter((n) => Boolean(notificationContentMap[n.id]));

    // Display first notification if more than three
    if (filteredNotifications.length > 2 && !showAllSeparately) {
      const content = notificationContentMap[filteredNotifications[0].id];
      return [
        <NotificationRow
          values={content.values}
          key={filteredNotifications[0].id}
          translationId={content.translationId}
          moreNotifsNum={filteredNotifications.length - 1}
          onMoreClick={() => this.openRequestNotifications(item)}
          discard={() => {
            const idsToDiscard: string[] = [];

            filteredNotifications.forEach((n) => {
              if (!n.multiple) {
                idsToDiscard.push(n.id);
                return;
              }

              const documentNotifications = deliveryDocumentNotifications.filter(
                (notification) => notification.data?.id === n.data.id
              );
              documentNotifications.forEach((n) => idsToDiscard.push(n.id));
            });

            this.props.discardAndSetNotificationAsRead(idsToDiscard, context);
          }}
        />,
      ];
    }

    return notificationsWithMultiple
      .filter((n) => Boolean(notificationContentMap[n.id]))
      .map((n) => {
        const notifContent = notificationContentMap[n.id];
        return (
          <NotificationRow
            key={n.id}
            discarded={n.discarded}
            values={notifContent.values}
            translationId={notifContent.translationId}
            onMoreClick={() => this.openRequestNotifications(item)}
            discard={() => {
              if (!n.multiple) {
                this.props.discardAndSetNotificationAsRead([n.id], context);
                return;
              }

              this.props.discardAndSetNotificationAsRead(
                deliveryDocumentNotifications
                  .filter((notification) => notification.data?.id === n.data.id)
                  .map((n) => n.id),
                context
              );
            }}
          />
        );
      });
  };
}
