// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const sfoc_ja: ITranslations = {
  ja: {
    "sfoc.aBank": "Aバンク",
    "sfoc.bBank": "Bバンク",
    "sfoc.error.valueHasToBe24Hourformat": "時間は24時間形式 (HH:MM) でなければなりません。",
    "sfoc.error.valueHasToBeBetween": "値は{min}から{max}の間でなければなりません",
    "sfoc.error.valueHasToBeNumeric": "値は数値でなければなりません。",
    "sfoc.error.valueIsRequired": "値が必要です。",
    "sfoc.field.ISOCorrectedSFOC": "ISO修正SFOC",
    "sfoc.field.LTWaterTemperature": "LT水温度",
    "sfoc.field.absoluteHumidity": "絶対湿度",
    "sfoc.field.activePower": "有効電力",
    "sfoc.field.afterTestTakeFuelSample": "試験後、燃料サンプルを採取し、シール番号を書き留め、試験キットで水分量を測定します",
    "sfoc.field.ambientConditionValuesOnSFOCCabinetSameAsLocalWeatherStation": "SFOCキャビネットの周囲条件の値は、現地の気象台の値と同じです",
    "sfoc.field.averageFiringPressureBar": "平均点火圧{bank}",
    "sfoc.field.averageFiringPressureBarHeading": "平均点火圧",
    "sfoc.field.barometricPressure": "大気圧",
    "sfoc.field.betaFactor": "ベータ因子",
    "sfoc.field.calculatedISOCorrectedSFOC": "計算されたISO修正SFOC",
    "sfoc.field.calculatedSFOC": "計算されたSFOC",
    "sfoc.field.chargeAirPressure": "給気圧力",
    "sfoc.field.chargeAirTemperature": "給気温度",
    "sfoc.field.checkThatSFOCSystemIsWorkingProperly": "試験を開始する前に、SFOCシステムが正常に動作していることを確認します",
    "sfoc.field.cleanLeakMeasurementTime": "測定時間",
    "sfoc.field.consumedFuel": "消費された燃料",
    "sfoc.field.cylinder": "シリンダー{cylinderNr}",
    "sfoc.field.deltaPOverCAC": "CAC上のΔp",
    "sfoc.field.deltaPOverTCAirFilter": "T/Cエアフィルター上のΔp",
    "sfoc.field.differentialAutoZeroValue": "ディファレンシャル自動ゼロ値",
    "sfoc.field.engine": "エンジン",
    "sfoc.field.engineRoomTemperature": "エンジンルーム温度",
    "sfoc.field.engineRoomVentilationIsOn": "エンジンルームの換気がオンです",
    "sfoc.field.engineSpeed": "エンジン回転数",
    "sfoc.field.exhaustGasBackPressureAfterTC": "T/C後の排気ガス背圧",
    "sfoc.field.exhaustGasCleaningSystemOff": "排気ガス清浄システム (スクラバー) がオフです",
    "sfoc.field.exhaustGasTemperatureAfterTC": "T/C後の排気ガス温度",
    "sfoc.field.exhaustGasTemperatureBeforeTC": "T/C前の排気ガス温度",
    "sfoc.field.exhaustTemperatureAfterCylinder": "{bank}シリンダー後の排気温度",
    "sfoc.field.exhaustTemperatureAfterCylinderHeading": "シリンダー後の排気温度",
    "sfoc.field.foSampleSealNumber": "FOサンプルシール番号",
    "sfoc.field.fuelCalorificValueLHV": "燃料発熱量LHV",
    "sfoc.field.fuelFlowInlet": "燃料フローインレット",
    "sfoc.field.fuelFlowReturn": "燃料フローリターン",
    "sfoc.field.fuelInletFlowRatioToConsumption": "消費に対する燃料インレットフロー比",
    "sfoc.field.fuelNetSpecificEnergyLHV": "燃料ネット固有エネルギーLHV",
    "sfoc.field.fuelPressureBeforeEngine": "エンジン前の燃圧",
    "sfoc.field.fuelTemperatureBeforeEngine": "エンジン前の燃料温度",
    "sfoc.field.fuelType": "燃料タイプ",
    "sfoc.field.fuelTypeIsTheSameAsUsedDuringTheBaselineTest": "燃料の種類は、ベースライン試験 (通常はHFO) で使用したものと同じです",
    "sfoc.field.fuelViscosityBeforeEngine": "エンジン前の燃料粘度",
    "sfoc.field.generatorPowerFactor": "生成された力率 (Cos Φ)",
    "sfoc.field.loadIndicatorPosition": "負荷インジケータ位置",
    "sfoc.field.measuredCleanLeakWeight": "測定されたクリーンリーク重量",
    "sfoc.field.measuredFOC": "測定されたFOC",
    "sfoc.field.measuredFuelOilCleanLeak": "燃料油のクリーンリークの測定",
    "sfoc.field.nickname": "ニックネーム",
    "sfoc.field.priorStartTestEngineShouldRunWithStableLoadFor20MinInLoadControl": "試験開始前に、エンジンを安定した負荷で20分間回転させ、負荷を制御する必要があります。",
    "sfoc.field.producedEnergy": "生成されたエネルギー",
    "sfoc.field.pumpRackPosition": "ポンプラック位置{bank}",
    "sfoc.field.pumpRackPositionHeader": "ポンプラック位置",
    "sfoc.field.quarterNr": "四半期番号",
    "sfoc.field.repeatTestWhenProducedEnergyIsAboveBaseline": "生成されたエネルギーがベースラインの±{tolerance}％を超えたら、試験を繰り返します",
    "sfoc.field.runHoursAtCACOH": "最終CAC OH時の稼働時間",
    "sfoc.field.runHoursAtLastCACWash": "最終CAC洗浄時の稼働時間",
    "sfoc.field.runHoursAtLastInjectionPumpOH": "最終噴射ポンプOH時の稼働時間",
    "sfoc.field.runHoursAtLastInjectorOH": "最終インジェクターOH時の稼働時間",
    "sfoc.field.runHoursAtLastMajorOH": "最終主要OH時の稼働時間",
    "sfoc.field.runHoursAtLastTCMaintenance": "最終T/Cメンテナンス時の稼働時間",
    "sfoc.field.runHoursAtLastTCWash": "最終T/C洗浄時の稼働時間",
    "sfoc.field.samplingPosition": "サンプリング位置",
    "sfoc.field.sulfur": "硫黄",
    "sfoc.field.testEndTime": "試験終了時間",
    "sfoc.field.testEngineInLoadControlModeConfirmThatActivePowerIsTheSameAtBaseline": "負荷制御モードでエンジン試験を行い、有効電力がベースラインと同じであることを確認します",
    "sfoc.field.testFailedDueTo": "以下の原因により試験に失敗しました",
    "sfoc.field.testStartTime": "試験開始時間",
    "sfoc.field.totalRunningHours": "合計稼働時間",
    "sfoc.field.turboChargerSpeed": "ターボチャージャ回転数",
    "sfoc.field.typeOfSFOCTest": "SFOC試験の種類",
    "sfoc.field.waterContent": "含水率",
    "sfoc.field.waterContentMeasured": "試験キットで測定した水分量",
    "sfoc.field.waterContentMeasuredFromFuel": "燃料から測定された水分量",
    "sfoc.field.waterFuelEmulsionOff": "テストを開始する前に、水燃料エマルジョン (WFE) をオフの状態で最低6時間稼働させます。",
    "sfoc.info.sendingReport": "SFOCレポートの送信。",
    "sfoc.info.sendingReportFailed": "SFOCレポートの送信に失敗しました。",
    "sfoc.info.sendingReportSuccess": "SFOCレポートが無事に送信されました。",
    "sfoc.meanValue": "平均",
    "sfoc.phase.automation": "自動",
    "sfoc.phase.cabinet": "キャビネット",
    "sfoc.phase.engine": "エンジン",
    "sfoc.phase.general": "一般",
    "sfoc.phase.section.dataCollection": "燃料計算のためのデータ収集",
    "sfoc.phase.section.engineOverHaulHistory": "エンジンオーバホール履歴",
    "sfoc.phase.section.general": "一般",
    "sfoc.phase.section.performanceTest": "エンジン性能試験",
    "sfoc.phase.section.sfocPerformanceCheckList": "SFOC性能試験チェックリスト",
    "sfoc.samplingPosition.AtFOBoosterUnite": "FOブースター統合時",
    "sfoc.samplingPosition.BeforeEngine": "エンジン前",
    "sfoc.samplingPosition.Other": "その他",
    "sfoc.testFailure.DiffFlowFluctuationOver2": "ディファレンシャルフロー変動が±2％超過",
    "sfoc.testFailure.LoadAndDiffFlowFluctuationOver2": "負荷およびディファレンシャルフロー変動が±2％超過",
    "sfoc.testFailure.LoadFluctuationOver2": "負荷変動が±2％超過",
    "sfoc.unit.MJPerKg": "MJ/kg",
    "sfoc.unit.bar": "bar",
    "sfoc.unit.gPerKg": "g/kg",
    "sfoc.unit.gPerkWh": "g/kWh",
    "sfoc.unit.hours": "h",
    "sfoc.unit.kW": "kW",
    "sfoc.unit.kWh": "kWh",
    "sfoc.unit.kg": "kg",
    "sfoc.unit.kgPerHour": "kg/h",
    "sfoc.unit.kgPerM3": "kg/m3",
    "sfoc.unit.mbar": "mbar",
    "sfoc.unit.mgPerKg": "mg/kg",
    "sfoc.unit.min": "最小",
    "sfoc.unit.mm": "mm",
    "sfoc.unit.mm2PerSec": "mm2/s",
    "sfoc.unit.percent": "%",
    "sfoc.unit.rpm": "rpm",
    "sfoc.value.afterOH": "OH後",
    "sfoc.value.audit": "監査",
    "sfoc.value.na": "N/A",
    "sfoc.value.no": "いいえ",
    "sfoc.value.off": "オフ",
    "sfoc.value.on": "オン",
    "sfoc.value.other": "その他",
    "sfoc.value.quarterly": "四半期ごと",
    "sfoc.value.yes": "はい",
  },
};
