import { Slider } from "@wartsila/ui-kit";
import { Dialog } from "src/components/Dialog";
import { Container, FlexContainer } from "src/design-system/Container";
import { RadioChoice } from "src/design-system/Radio";
import { IRadioChoiceOption } from "src/design-system/Radio/RadioChoiceComponent";

import { ISpacing } from "src/design-system/Tokens/spacings";
import React from "react";
import { ThemedProps } from "src/design-system/Theme/theme";
import { getFirstAndLastCurrentQuarters } from "src/services/sfoc/sfoc.utils";
import { translateString } from "src/util/localization";
import styled from "styled-components";

type FilterProps = { children: React.ReactNode | React.ReactNode[] } & ISpacing;
export const Filters = ({ children, ...props }: FilterProps) => {
  return (
    <FiltersContainer $padding={[2]} {...props}>
      {Array.isArray(children) ? (
        children.map((child, key) => (
          <Container key={key} $marginBottom={2}>
            {child}
          </Container>
        ))
      ) : (
        <Container $marginBottom={2}>{children}</Container>
      )}
    </FiltersContainer>
  );
};

type QuarterSelectDialogProps = {
  isOpen: boolean;
  showExportAllBrands: boolean;
  onSubmit: (fromQuarter: number, toQuarter: number, delimiter: string, exportAllBrands?: boolean) => void;
  onCancel: () => void;
};

export const QuarterSelectDialog = ({ isOpen, showExportAllBrands, onSubmit, onCancel }: QuarterSelectDialogProps) => {
  const [selectedQuarters, setSelectedQuarters] = React.useState<[number, number]>(getFirstAndLastCurrentQuarters());
  const [selectedDelimiter, setSelectedDelimiter] = React.useState<string>("semicolon");
  const [minValue, maxValue] = getFirstAndLastCurrentQuarters();

  return (
    <Dialog
      title={translateString("sfoc.selectRangeOfQuarters")}
      onCancel={onCancel}
      isDialogOpen={isOpen}
      submit={{
        buttonTitle: translateString("export"),
        callback: () => onSubmit(...selectedQuarters, selectedDelimiter),
      }}
      extraButtons={showExportAllBrands ? [{
        buttonTitle: translateString("sfoc.exportAllBrands"),
        callback: () => onSubmit(...selectedQuarters, selectedDelimiter, true),
      }] : undefined}
    >
      <Container>
        <SliderContainer>
          <SliderLabel>{translateString("sfoc.useSliderToSelectRangeOfQuarters")}</SliderLabel>
          <Slider
            value={selectedQuarters}
            min={minValue}
            max={maxValue}
            onChange={(_: unknown, values: [number, number]) => setSelectedQuarters(values)}
          />
        </SliderContainer>
        <br />
        <Container>
          <p>{translateString("export.chooseDelimiter")}</p>
          <RadioChoice
            name="delimiter"
            selectedOptionId={selectedDelimiter}
            options={[
              {
                id: "semicolon",
                label: translateString("export.semicolon"),
                description: <DelimiterExample>"Quarter Number";"Account Name";"Installation...</DelimiterExample>,
              },
              {
                id: "comma",
                label: translateString("export.comma"),
                description: <DelimiterExample>"Quarter Number","Account Name","Installation...</DelimiterExample>,
              },
            ]}
            onOptionSelect={(option: IRadioChoiceOption) => setSelectedDelimiter(option.id)}
          />
        </Container>
      </Container>
    </Dialog>
  );
};

export const StickyFiltersContainer = styled(Container)`
  top: 1rem;
  width: 100%;
  position: sticky;
  align-self: flex-start;
`;

const FiltersContainer = styled(Container)`
  background-color: ${(props: ThemedProps) => props.theme.menu.background};
`;

export const ResponsiveViewContainer = styled(FlexContainer)`
  & > div:first-child {
    flex: 1 1 100%;
    max-width: 275px;
    margin-right: 1rem;
  }

  @media all and (max-width: 768px) {
    flex-wrap: wrap;

    & > div:first-child {
      margin: 2rem 0;
      max-width: 100%;
    }
  }
`;

export const SliderContainer = styled(Container)`
  > div {
    margin: 2em 1.1em;
  }
`;

export const DraftButtonContainer = styled(FlexContainer).attrs({ $wrap: true })`
  grid-gap: 10px;
`;

export const SliderLabel = styled.p`
  padding-bottom: 16px;
`;

export const DelimiterExample = styled.code`
  color: #6c6c6c;
  font-size: 1.2em;
`;
