// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_fi: ITranslations = {
  fi: {
    "user.addNewUser": "Lisää uusi käyttäjä",
    "user.allUsersInAccount": "Kaikki tilin käyttäjät",
    "user.department": "Osasto",
    "user.digest.period.daily": "Päivittäin",
    "user.digest.period.monthly": "Kuukausittain",
    "user.digest.period.never": "Ei koskaan",
    "user.digest.period.weekly": "Viikoittain",
    "user.digest.service.reports": "Raportit",
    "user.digest.service.requests": "Pyynnöt",
    "user.digest.service.technicalDocuments": "Tekninen dokumentaatio",
    "user.digest.validation.noInstallationsSelected": "Installaatiovalinta on pakollinen.",
    "user.digest.validation.noInstallationsSelected:energy": "Voimalavalinta on pakollinen.",
    "user.digest.validation.noInstallationsSelected:marine": "Alusvalinta on pakollinen.",
    "user.digest.validation.noServicesSelected": "Palveluvalinta on pakollinen.",
    "user.digestDescription.daily": "Päivittäin yhdelle installaatiolle",
    "user.digestDescription.daily.plural": "Päivittäin {numberOfInstallations} installaatiolle",
    "user.digestDescription.daily.plural:energy": "Päivittäin {numberOfInstallations} voimalalle",
    "user.digestDescription.daily.plural:marine": "Päivittäin {numberOfInstallations} alukselle",
    "user.digestDescription.daily:energy": "Päivittäin yhdelle voimalalle",
    "user.digestDescription.daily:marine": "Päivittäin yhdelle alukselle",
    "user.digestDescription.monthly": "Kuukausittain yhdelle installaatiolle",
    "user.digestDescription.monthly.plural": "Kuukausittain {numberOfInstallations} installaatiolle",
    "user.digestDescription.monthly.plural:energy": "Kuukausittain {numberOfInstallations} voimalalle",
    "user.digestDescription.monthly.plural:marine": "Kuukausittain {numberOfInstallations} alukselle",
    "user.digestDescription.monthly:energy": "Kuukausittain yhdelle voimalalle",
    "user.digestDescription.monthly:marine": "Kuukausittain yhdelle alukselle",
    "user.digestDescription.never": "Poissa käytöstä",
    "user.digestDescription.weekly": "Viikoittain yhdelle installaatiolle",
    "user.digestDescription.weekly.plural": "Viikoittain {numberOfInstallations} installaatiolle",
    "user.digestDescription.weekly.plural:energy": "Viikoittain {numberOfInstallations} voimalalle",
    "user.digestDescription.weekly.plural:marine": "Viikoittain {numberOfInstallations} alukselle",
    "user.digestDescription.weekly:energy": "Viikoittain yhdelle voimalalle",
    "user.digestDescription.weekly:marine": "Viikoittain yhdelle alukselle",
    "user.digestEmail": "Koostesähköposti",
    "user.digestEmailSettings": "Koostesähköpostin asetukset",
    "user.digestSettingsForm.frequencyOfDigest": "Koosteen lähetysväli",
    "user.digestSettingsForm.servicesOfInterest": "Palvelut, joista olen kiinnostunut",
    "user.email": "Sähköpostiosoite",
    "user.emailIsRequired": "Sähköpostiosoite on pakollinen",
    "user.emailShort": "Sähköposti",
    "user.firstname": "Etunimi",
    "user.firstnameIsRequired": "Etunimi on pakollinen",
    "user.lastname": "Sukunimi",
    "user.lastnameIsRequired": "Sukunimi on pakollinen",
    "user.name": "Nimi",
    "user.notificationSettings": "Ilmoitusasetukset",
    "user.phone": "Puhelinnumero",
    "user.phoneNumber": "Puhelinnumero",
    "user.reasonForRemoval": "Syy poistolle",
    "user.reasonForRemovalIsRequired": "Syy poistolle on pakollinen",
    "user.removeUser": "Poista käyttäjä",
    "user.title": "Titteli",
    "user.userInformation": "Käyttäjätiedot",
    "user.userPassword": "Salasana",
  },
};
