import { FieldProps } from "formik";
import * as React from "react";
import { Checkbox } from "src/design-system/Checkbox";
import { getRatingReasons } from "src/components/SupportList/Approval/ratingNames";
import { Container } from "src/design-system/Container";
import { FlexContainer } from "src/design-system/Container";
import colors from "src/design-system/Tokens/colors";
import { toggleArrayValue } from "src/util/helpers";
import { translateString } from "src/util/localization";
import styled from "styled-components";

const BoldP = styled.p`
  font-weight: 600;
  margin: 20px 0px;
`;

const ReasonSectionWrapper = styled.div`
  padding-bottom: 40px;
`;

const ReasonWrapper = styled(FlexContainer)`
  padding-top: 5px;
`;

const ReasonText = styled.span`
  flex: 1;
  display: inline-block;
  margin: auto 0;
`;

const ReasonForRating = (props: { reason: string; onChange: () => void; checked: boolean }) => (
  <ReasonWrapper>
    <Checkbox color={colors.primary.black} checked={props.checked} onChange={props.onChange} />
    <ReasonText>{props.reason}</ReasonText>
  </ReasonWrapper>
);

const onReasonSelect = (props: FieldProps<string[]>, reason: string) => {
  props.form.setFieldValue(props.field.name, toggleArrayValue(props.field.value, reason));
};

export const CMReportRatingSurvey = (props: { isSatisfied: boolean } & FieldProps<string[]>) => {
  const ratingReasons = getRatingReasons();

  return (
    <Container>
      <BoldP>
        {props.isSatisfied
          ? translateString("request.rating.reasonForSatisfiedRating")
          : translateString("request.rating.reasonForNotFullySatisfiedRating")}
      </BoldP>
      <ReasonSectionWrapper>
        {Object.keys(ratingReasons).map((reason: string) => (
          <ReasonForRating
            reason={ratingReasons[reason]}
            onChange={() => onReasonSelect(props, reason)}
            checked={props.field.value.indexOf(reason) !== -1}
          />
        ))}
      </ReasonSectionWrapper>
    </Container>
  );
};
