import { IOnboarding } from "online-services-types";
import * as React from "react";
import { Placement, Step } from "react-joyride";
import { AnyAction, Dispatch } from "redux";

import { getUserProfile, patchUserProfile } from "src/views/ProfileView/userProfile";
import { IAppState } from ".";
import { RouteId } from "src/util/routeId";
import { onboardingSupportSteps } from "src/components/Onboarding/SupportSteps";
import { onBoardingIndexSteps } from "src/components/Onboarding/IndexSteps";
import {
  onBoardingSparePartsSteps,
  onBoardingSparePartsCatalogueSteps,
} from "src/components/Onboarding/SpatePartsSteps";
import { onBoardingDocumentsSteps } from "src/components/Onboarding/TechnicalDocumentSteps";

/*
------------ How to add new onboarding flows ------------

- add function call startOnboarding(routeId: RouteId) in the componentDidMount() function in the view where the onboarding flow will be
Example taken from AppComponent: "this.props.startOnboarding(RouteId.Index)"

- add steps the the "onboardingStepsBase" object in this file (Check from Joyride which properties can be added to steps)

- add classnames to components which correspond to the classnames in steps
Example of applying className property a component <div className={'test-onboarding-to-say-what-it-does'/>

*/

export interface IOnboardingDispatchProps {
  incrementOnboardingStep(): void;
  decrementOnboardingStep(): void;
  pauseOnboarding(): void;
  resumeOnboarding(): void;
  startOnboarding(routeId: RouteId, ignoreCompletedOnboardings?: boolean, component?: React.Component): void;
  stopOnboarding(isSkipped: boolean): void;
  updateViewComponentInstance?(routeId: RouteId, component: React.Component): void;
}

const CHANGE_STEPS = "CHANGE_STEPS";
const START_ONBOARDING = "START_ONBOARDING";
const STOP_ONBOARDING = "STOP_ONBOARDING";
const INCREMENT_STEP = "INCREMENT_STEP";
const DECREMENT_STEP = "DECREMENT_STEP";
const PAUSE_ONBOARDING = "PAUSE_ONBOARDING";
const RESUME_ONBOARDING = "RESUME_ONBOARDING";
const UPDATE_VIEW_COMPONENT_INSTANCE = "UPDATE_VIEW_COMPONENT_INSTANCE";
const SET_ONBOARDING_FINISHED = "SET_ONBOARDING_FINISHED";

export interface IBaseStep {
  className: string; // className in component to match with step to make Joyride map step
  isFixed?: boolean;
  nextText?: string;
  disableOverlayClose?: boolean;
  content: React.ReactNode;
  disableBeacon?: boolean;
  placement?: Placement;
  // The component instance is passed to onStepActivate, you need to register that with startOnboarding() (3rd param) in componentDidUpdate()
  onStepActivate?(component?: React.Component): void;
  onStepNext?(component?: React.Component): void;
  onStepPrevious?(component?: React.Component): void;
}

interface IOnboardingStepsBase {
  [key: string]: IBaseStep[];
}

export const onboardingStepsBase: IOnboardingStepsBase = {
  [RouteId.Index]: onBoardingIndexSteps,
  [RouteId.Requests]: onboardingSupportSteps,
  [RouteId.Documents]: onBoardingDocumentsSteps,
  [RouteId.SpareParts]: onBoardingSparePartsSteps,
  [RouteId.SparePartsCatalogue]: onBoardingSparePartsCatalogueSteps,
};

export interface IExtendedStep extends Step {
  className: string;
  nextText?: string;
  onStepActivate?(component?: React.Component): void;
  onStepNext?(component?: React.Component): void;
  onStepPrevious?(component?: React.Component): void;
}

interface IOnboardingSteps {
  [key: string]: IExtendedStep[];
}

// Add "target" properties to steps, which are required by joyride
// They are generated here, so that developer doesn't have to care about them and write them
export const generateSteps = () => {
  const newSteps: IOnboardingSteps = {};

  for (const routeIdKey of Object.keys(onboardingStepsBase)) {
    newSteps[routeIdKey] = onboardingStepsBase[routeIdKey].map((step: IBaseStep) => ({
      target: `.${step.className}`,
      ...step,
    }));
  }

  return newSteps;
};

export const onboardingSteps = generateSteps();

// stepTarget is a className without a dot
export const getRouteOfTargetStep = (stepTarget: string): string | null => {
  let routeIdToReturn = null;

  Object.keys(onboardingSteps).forEach((routeId: string) => {
    const routeSteps: IExtendedStep[] = onboardingSteps[routeId];
    routeSteps.forEach((step) => {
      if (step.target === "." + stepTarget) {
        routeIdToReturn = routeId;
      }
    });
  });

  return routeIdToReturn;
};

export const incrementOnboardingStep = () => (dispatch: Dispatch) => {
  dispatch({
    type: INCREMENT_STEP,
  });
};

export const decrementOnboardingStep = () => (dispatch: Dispatch) => {
  dispatch({
    type: DECREMENT_STEP,
  });
};

/*
  If onboarding object found:
    If onboarding object isCompleted:
      Do nothing
    If onboarding object !isCompleted:
      Mark it completed with isCompleted: true, and with isSkipped
  Else:
    Add onboarding object with isCompleted: true, and with isSkipped
*/

const markOnboardingComplete = async (routeId: RouteId, isSkipped: boolean) => {
  const user = await getUserProfile(false);

  if (user) {
    const completedOnboardings = user.onboardings;
    const routeOnboarding = completedOnboardings.find((onboarding: IOnboarding) => onboarding.view === routeId);

    if (!routeOnboarding) {
      const newCompletedOnboardings = [
        ...completedOnboardings,
        {
          view: routeId,
          isSkipped,
          isCompleted: true,
        },
      ];

      await patchUserProfile(
        {
          onboardings: newCompletedOnboardings,
        },
        false
      );
    } else if (routeOnboarding && !routeOnboarding.isCompleted) {
      // if the onboarding is not completed, mark it completed
      routeOnboarding.isCompleted = true;
      routeOnboarding.isSkipped = isSkipped;

      await patchUserProfile(
        {
          onboardings: [...completedOnboardings],
        },
        false
      );
    }
  }
};

const isOnboardingComplete = async (routeId: RouteId) => {
  const user = await getUserProfile(false);

  if (user) {
    const completedOnboardings = user.onboardings;

    try {
      const routeOnboarding = completedOnboardings.find((onboarding: IOnboarding) => onboarding.view === routeId);

      if (routeOnboarding && routeOnboarding.isCompleted) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      // console.log('err with checking if onboarding completed:', err);
    }
  }

  return false;
};

export const pauseOnboarding = () => (dispatch: Dispatch) => {
  dispatch({
    type: PAUSE_ONBOARDING,
  });
};

export const resumeOnboarding = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESUME_ONBOARDING,
  });
};

const viewComponentInstances: { [routeId: string]: React.Component } = {};

export const startOnboarding =
  (routeId: RouteId, ignoreCompletedOnboardings?: boolean, component?: React.Component) =>
  async (dispatch: Dispatch) => {
    // Store the instance of the view at routeId so that the flow if started from side menu also has access to it

    if (component) {
      viewComponentInstances[routeId] = component;
    }

    const isOnboardingCompleteForRoute = await isOnboardingComplete(routeId);

    if ((!ignoreCompletedOnboardings && isOnboardingCompleteForRoute) || !onboardingSteps[routeId]) {
      setTimeout(() => {
        dispatch({ type: SET_ONBOARDING_FINISHED });
      }, 0);
      return;
    }

    // TODO: Move all timings and delays in the app to some central location???
    const routeTransitionTime = 400;

    // Never start onboarding for spare parts catalogue (handled by RouteId.SparePartsView)
    // unless the user triggers it manually from user prefs menu (first visit would otherwise
    // trigger it).

    if (ignoreCompletedOnboardings || routeId !== RouteId.SparePartsCatalogue) {
      setTimeout(() => {
        dispatch({
          type: START_ONBOARDING,
          payload: {
            steps: onboardingSteps[routeId],
            routeId,
            component: viewComponentInstances[routeId],
          },
        });
      }, routeTransitionTime);
    }
  };

export const stopOnboarding = (isSkipped: boolean) => async (dispatch: Dispatch, getState: () => IAppState) => {
  dispatch({
    type: STOP_ONBOARDING,
  });
  const currentRouteId = getState().onboarding.currentRouteId;
  await markOnboardingComplete(currentRouteId, isSkipped);
};

export const updateViewComponentInstance =
  (routeId: RouteId, component: React.Component) => async (dispatch: Dispatch) => {
    viewComponentInstances[routeId] = component;
    dispatch({
      type: UPDATE_VIEW_COMPONENT_INSTANCE,
      payload: { component: viewComponentInstances[routeId] },
    });
  };

export interface IOnboardingState {
  step: number;
  currentRouteId: RouteId;
  isOnboardingRunning: boolean;
  isOnboardingFinished: boolean;
  currentSteps: IExtendedStep[];
  component?: React.Component;
}

const initialState: IOnboardingState = {
  step: 0,
  isOnboardingRunning: false,
  isOnboardingFinished: false,
  currentRouteId: RouteId.Index,
  currentSteps: onboardingSteps[RouteId.Index],
  component: undefined,
};

export const onboardingReducer = (state = initialState, action: AnyAction): IOnboardingState => {
  switch (action.type) {
    case INCREMENT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case DECREMENT_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case PAUSE_ONBOARDING:
      return {
        ...state,
        isOnboardingRunning: false,
      };
    case RESUME_ONBOARDING:
      return {
        ...state,
        isOnboardingRunning: true,
      };
    case START_ONBOARDING:
      return {
        ...state,
        isOnboardingRunning: true,
        isOnboardingFinished: false,
        step: 0,

        currentRouteId: action.payload.routeId,
        currentSteps: action.payload.steps,
        component: action.payload.component,
      };
    case STOP_ONBOARDING:
      return {
        ...state,
        isOnboardingRunning: false,
        isOnboardingFinished: true,
      };
    case CHANGE_STEPS:
      return {
        ...state,
        currentRouteId: action.payload.routeId,
        currentSteps: action.payload.steps,
      };
    case UPDATE_VIEW_COMPONENT_INSTANCE:
      return {
        ...state,
        component: action.payload.component,
      };
    case SET_ONBOARDING_FINISHED:
      return {
        ...state,
        isOnboardingFinished: true,
      };
    default:
      return state;
  }
};
