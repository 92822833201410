import { useEffect, useRef } from "react";

// custom hook that just provides a boolean flag to indicate whether the
// current render is the first render (when the component was mounted).
export const useIsFirstRender = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
