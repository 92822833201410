import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const AddManuallyIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>0228 Write All</title>
    <g id="Icon_Text" data-name="Icon Text">
      <rect x="32" y="29" width="8" height="2" />
      <rect x="28" y="33" width="12" height="2" />
      <rect x="24" y="37" width="16" height="2" />
      <path d="M29.13,11.8,9.27,31.66,7.63,40.37l8.71-1.64L36.2,18.87ZM14.39,35l-1.75.33L13,33.61,29.13,17.46l1.41,1.41Z" />
      <rect x="33.42" y="7.58" width="4" height="10" transform="translate(1.48 28.73) rotate(-45)" />
    </g>
  </IconBase>
);
