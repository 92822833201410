import { IAccountUser, IContact, IEquipment, IPart } from "online-services-types";

import { isNumber } from "lodash";
import moment from "moment";
import { translateString } from "./localization";
import { validators } from "./validators";

export const maxInstallations: number = 18;

const months = [
  "month.january",
  "month.february",
  "month.march",
  "month.april",
  "month.may",
  "month.june",
  "month.july",
  "month.august",
  "month.september",
  "month.october",
  "month.november",
  "month.december",
];

const shortMonths = [
  "month.january.short",
  "month.february.short",
  "month.march.short",
  "month.april.short",
  "month.may.short",
  "month.june.short",
  "month.july.short",
  "month.august.short",
  "month.september.short",
  "month.october.short",
  "month.november.short",
  "month.december.short",
];

export const dateFormat = "YYYY-MM-DD";
export const dateMomentFormat = "ll";
export const formatDate = (date: string | Date) => moment(new Date(date)).format("ll");
export const formatDateTime = (date: string | Date) => moment(new Date(date)).format("lll");
export const formatDateRange = (start: string | Date, end: string | Date) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  if (startDate.toISOString() === endDate.toISOString()) {
    return `${moment(startDate).format("ll")}`;
  } else if (startDate.getFullYear() !== endDate.getFullYear() || startDate.getMonth() !== endDate.getMonth()) {
    return `${moment(startDate).format("ll")} - ${moment(endDate).format("ll")}`;
  }

  return `${moment(startDate).format("MMM D")} - ${moment(endDate).format("ll")}`;
};

export const getDayOfMonth = (date: string | Date) => (moment(date).isValid() ? moment(date).format("D") : "");

export const getMonthName = (date: string | Date) => {
  const monthNum = parseInt(moment(date).format("M"), 10);

  if (monthNum >= 1 && monthNum <= 12) {
    return translateString(months[monthNum - 1]);
  }
  return "";
};

export const getMonthShortName = (date: string | Date) => {
  const monthNum = parseInt(moment(date).format("M"), 10);
  if (monthNum >= 1 && monthNum <= 12) {
    return translateString(shortMonths[monthNum - 1]);
  }
  return "";
};

// format currency so that it has spaces every 3 digits.
export const formatCurrency = (num: number, separator = " ") => {
  if (!isNumber(num)) {
    return num;
  }

  return num
    .toFixed(2) // always two decimal digits
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
};

// Capitalize only the first letter of the whole passed string. eg. capitalizeFirstLetter("My Spare Parts") => "My spare parts"
// Skip words with length 1 or words where all letters are uppercase (abbreviations etc.)
export const capitalizeFirstLetter = (line: string) => {
  if (line.length > 1 && line !== line.toUpperCase()) {
    return line.charAt(0).toUpperCase() + line.slice(1).toLowerCase();
  } else {
    return line;
  }
};

export const capitalizeAllWords = (line: string, except?: string): string => {
  if (except) {
    return line
      .split(except)
      .map((part) => capitalizeAllWords(part))
      .join(except);
  }
  return line.split(" ").map(capitalizeFirstLetter).join(" ");
};

export const getYear = (date: string | Date) => moment(date).format("YYYY");

export const formatBoolean = (value: boolean) => (value ? translateString("yes") : translateString("no"));

export const failureDateFormat = dateFormat;

export const fluidReportDateFormat = dateFormat;

export const sparePartsDateFormat = dateFormat;

export const technicalDocumentsDateFormat = dateFormat;

export const formatEquipmentName = (equipment: IEquipment | undefined) => {
  if (equipment && equipment.nickName && equipment.description) {
    return `${equipment.nickName} - ${equipment.description}`;
  }

  if (equipment && equipment.description) {
    return equipment.description;
  }

  return "";
};

export const formatEquipmentLabel = (equipment: IEquipment) => {
  const { serialNumber, description, nickName } = equipment;

  if (description) {
    if (nickName) {
      return description.includes(serialNumber)
        ? `${description} - ${nickName}`
        : `${serialNumber} ${description} - ${nickName}`;
    } else {
      return description.includes(serialNumber) ? description : `${serialNumber} ${description}`;
    }
  }
  if (nickName) {
    return nickName.includes(serialNumber) ? nickName : `${nickName} - ${serialNumber}`;
  }
  return "";
};

export const formatAvailabilityText = (leadText = "", quantityInStock = 0, quantityRequested: number) => {
  if (quantityInStock <= 0 || quantityInStock < quantityRequested) {
    if (leadText === "") return translateString("spareParts.toBeConfirmed");
    return leadText;
  }

  return translateString("spareParts.inStock");
};

export const formatPartIdValue = (part: IPart) => {
  if (part.addedFromMatNum) {
    return part.materialId;
  }
  if (!part.sparePartNumber) {
    return part.materialId;
  }

  return part.sparePartNumber;
};

export const formatEmailList = (user: IAccountUser, receivers: IContact[]): string => {
  return Array.from(
    new Set(
      receivers
        .map((contact) => contact.email)
        .concat([user.email])
        .filter((email) => validators.isEmail(email))
    )
  ).join(",");
};

enum FrequencyUnits {
  HOURS = "HOURS",
  CYCLES = "CYCLES",
}

export const formatFrequencyUnit = (unit: string) => {
  switch (unit) {
    case FrequencyUnits.HOURS:
      return translateString("unit.hours");
    case FrequencyUnits.CYCLES:
      return translateString("unit.cycles");
    default:
      return unit;
  }
};

export const formatHours = (hours?: number, frequencyUnit?: string) => {
  if (hours && frequencyUnit) {
    return `${hours} ${formatFrequencyUnit(frequencyUnit).substring(0, 1)}`;
  }
  return translateString("unknown");
};

export enum PeriodTranslationKeys {
  YEAR = "year",
  YEARS = "years",
  MONTH = "month",
  MONTHS = "months",
}
export const Periods = {
  Months: {
    key: PeriodTranslationKeys.MONTHS,
    label: `6 ${translateString(PeriodTranslationKeys.MONTHS)}`,
  },
  Year: {
    key: PeriodTranslationKeys.YEAR,
    label: `1 ${translateString(PeriodTranslationKeys.YEAR)}`,
  },
  Years: {
    key: PeriodTranslationKeys.YEARS,
    label: `2 ${translateString(PeriodTranslationKeys.YEARS)}`,
  },
};

export const getStartAndEndPeriod = (start: string, fromMonth: number, toMonth: number) => {
  const from = moment(start).add(fromMonth, "months").format(dateFormat);
  const to = moment(start).add(toMonth, "months");
  const endOfTo = to.endOf("month").format(dateFormat);

  return { from, to: endOfTo };
};

export const formatTickValue = (startDate: string, tickValue: number) => {
  const value = moment(startDate).add(tickValue, "months");

  const isPast = isDateInPast(value);
  const yearValue = value.month() === 0 ? value.year() : null;

  return { yearValue, tickValue: getMonthShortName(value.format(dateFormat)), isPast };
};

export const isDateInPast = (value: moment.Moment) => {
  const now = moment().startOf(PeriodTranslationKeys.MONTH);
  return value.format(dateFormat) !== now.format(dateFormat) ? value.isBefore(now) : false;
};
