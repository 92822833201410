import * as React from "react";
// Styles
import * as s from "./styles";

import { Disclaimer, IDisclaimerTheme, KeepHeightText } from "src/design-system/Tokens/typography";
import { IColumnWidth, IResponsiveTableColumn, IRowProps, IRowState } from "./interfaces";
import { isNull, isUndefined } from "lodash";

import { Container } from "src/design-system/Container";
// Interfaces
import { IResource } from "online-services-types";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { ThemeProvider } from "styled-components";
import { themes } from "src/design-system/Theme/theme";

const isEmptyColumnValue = (value: string | null | undefined | number) =>
  value === null || value === undefined || value === "";

function isColumnEnabled<T>(column: IResponsiveTableColumn, item: T, items: T[]) {
  return column.isEnabled === undefined || column.isEnabled(item, items);
}

function shouldShowColumn(column: IResponsiveTableColumn, value: IResource) {
  return !(column.hideEmptyValues && value === null);
}

export class Row<T extends IResource> extends React.Component<IRowProps<T>, IRowState<T>> {
  constructor(props: IRowProps<T>) {
    super(props);
    this.state = {
      expanded: false,
      showSubList: false,
      fetchBusy: false,
      selectedId: "",
    };
  }

  public componentDidMount() {
    this.shouldToggleRow();
  }

  public componentDidUpdate(prevProps: IRowProps<T>) {
    if (!this.props.initialOpenState) {
      this.shouldToggleRow();
    }
    if (prevProps.ensureVisibleId !== this.props.ensureVisibleId) {
      this.shouldToggleRow(prevProps);
    }
    if (this.props.controlledOpenState) {
      this.shouldToggleRow(prevProps);
    }
  }

  public readonly shouldToggleRow = (prevProps?: IRowProps<T>) => {
    if (!prevProps && this.props.initialOpenState && !this.state.expanded) {
      this.toggleExpandedState();
    }
    if (
      prevProps &&
      prevProps.initialOpenState !== this.props.initialOpenState &&
      this.state.expanded !== this.props.initialOpenState
    ) {
      this.toggleExpandedState();
    }
  };

  private readonly hideBasedOnExpandedState = (column: IResponsiveTableColumn) => {
    return column.hideOnExpand && this.state.expanded;
  };

  public render() {
    const {
      row,
      rows,
      columns,
      columnWidths,
      children,
      isSubList,
      sublistRenderer,
      firstColumnBackground,
      hideFetchSpinner,
      nesting,
    } = this.props;
    const { expanded, showSubList, details } = this.state;
    // Currently in order to show dynamically toggle we need to run renderSublist to know if there are any children
    // In case there are children, we will always show the toggle
    // If subListRendered function is too slow this might cause performance problems
    let hasChildren = false;
    if (sublistRenderer && columnWidths.maxWidthToShowToogle) {
      const renderedSubList = sublistRenderer(details || row, this.state.fetchBusy, nesting ? nesting + 1 : 1);
      if (renderedSubList) {
        hasChildren = true;
      }
    }

    return (
      <s.Row
        $isSubList={isSubList}
        className={this.props.className}
        isSelectable={this.props.onSelect !== undefined}
        isSelected={this.props.selectedId === row.id && this.state.selectedId === row.id}
      >
        <s.RowElement
          alertColor={this.props.getAlertColor ? this.props.getAlertColor(row) : undefined}
          hasAlert={this.props.getHasAlert ? this.props.getHasAlert(row) : false}
        >
          <s.VisibleRow onClick={this.toggleExpandedState}>
            <s.Expand $background={firstColumnBackground}>
              <s.PlusMinus
                expanded={this.state.expanded}
                $hasChildren={hasChildren}
                $showToggleWidth={columnWidths.maxWidthToShowToogle}
              />
            </s.Expand>
            <s.RowContent $accumulator={columnWidths.accumulator}>
              {this.getVisibleColumns(columns, columnWidths.columnWidthsInOrder, details || row, rows)}
            </s.RowContent>
          </s.VisibleRow>
          {children}
          {!hideFetchSpinner && this.state.fetchBusy && details === undefined && <LoadingSpinner dark={true} />}
          {expanded && (
            <s.SubRow>
              {this.getHiddenColumns(columns, columnWidths.columnWidthsInOrder, details || row, rows)}
            </s.SubRow>
          )}
        </s.RowElement>
        {showSubList && sublistRenderer && (
          <s.SubList>{sublistRenderer(details || row, this.state.fetchBusy, nesting ? nesting + 1 : 1)}</s.SubList>
        )}
      </s.Row>
    );
  }

  public setFetching = (fetchBusy: boolean) => {
    this.setState({ fetchBusy });
  };

  public fetchDetails = async () => {
    if (this.state.expanded && this.props.getRowDetails) {
      this.setState({ fetchBusy: true });
      const details = await this.props.getRowDetails(this.props.row);
      if (details) {
        this.setState({ details });
      }
      this.setState({ fetchBusy: false });
    }
  };

  private readonly toggleExpandedState = () => {
    this.setState({ expanded: !this.state.expanded }, () => {
      if (this.state.expanded) {
        setTimeout(() => {
          this.setState({ showSubList: true });
          this.fetchDetails();
        }, 0);
        if (this.props.onSelect && this.props.row) {
          this.props.onSelect(this.props.row.id);
          this.setState({ selectedId: this.props.row.id });
        }
        this.props.onExpand?.();
      } else {
        this.setState({ showSubList: false });
        if (this.props.onSelect) {
          this.setState({ selectedId: "" });
          this.props.onSelect("");
        }
      }
    });
  };

  private getVisibleColumns(
    columns: IResponsiveTableColumn[],
    columnWidths: IColumnWidth[],
    row: T,
    rows: T[]
  ): Array<JSX.Element | null> {
    return columnWidths.map((columnWidth) => {
      const column = columns.find((currentColumn) => currentColumn.key === columnWidth.key);

      if (
        column &&
        isColumnEnabled<T>(column, row, rows) &&
        shouldShowColumn(column, row[column.key]) &&
        !this.hideBasedOnExpandedState(column)
      ) {
        return (
          <s.Column
            key={column.key}
            $widthToShow={columnWidth.width}
            $width={column.plannedWidth}
            $alwaysShow={column.alwaysShown}
            $staticWidth={column.staticWidth || false}
            $backgroundColor={column.backgroundColor}
            $grow={column.grow}
          >
            {this.renderCellContent(column, row, column.hideEmptyValues || false)}
          </s.Column>
        );
      } else {
        return null;
      }
    });
  }

  private getHiddenColumns(
    columns: IResponsiveTableColumn[],
    columnWidths: IColumnWidth[],
    row: T,
    rows: T[]
  ): Array<JSX.Element | null> {
    return columns
      .map((column) => {
        // If row value is empty and empty values are hidden
        if ((!row[column.key] || row[column.key] === "") && column.hideEmptyValues) {
          return null;
        }
        // If row is always shown in collapsed view
        if (column.alwaysShown) {
          return null;
        }

        if (!isColumnEnabled<T>(column, row, rows)) {
          return null;
        }

        const columnWidth = columnWidths.find(
          (currentColumn) => currentColumn.key === column.key && !this.hideBasedOnExpandedState(column)
        );

        return (
          <s.ExpandedRow
            key={column.key}
            $widthToShow={columnWidth && columnWidth.width}
            $alwaysShow={column.alwaysShown}
          >
            {this.renderCellContent(column, row, column.hideEmptyValues || false)}
          </s.ExpandedRow>
        );
      })
      .filter((column) => column !== null);
  }

  private renderCellContent(column: IResponsiveTableColumn, row: T, hideEmptyValues: boolean): JSX.Element | undefined {
    if (isEmptyColumnValue(row[column.key]) && hideEmptyValues) {
      return undefined;
    }

    const { labelRenderer, valueRenderer, renderer } = column;

    const renderLabel = () => {
      if (isNull(column.label) || isNull(column.label) || isUndefined(column.label)) {
        return null;
      }

      if (!labelRenderer) {
        return <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{column.label}</Disclaimer>;
      }

      const labelContent = labelRenderer({
        value: column.label,
        row,
        idx: this.props.idx,
        expanded: this.state.expanded,
      });
      return labelContent ? (
        <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{labelContent}</Disclaimer>
      ) : (
        <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{column.label}</Disclaimer>
      );
    };

    const renderValue = () => {
      const rawValue = row[column.key];

      if (isUndefined(valueRenderer)) {
        return <KeepHeightText>{rawValue}</KeepHeightText>;
      }

      const valueContent = valueRenderer({
        value: row[column.key],
        row,
        idx: this.props.idx,
        refetch: this.fetchDetails,
        fetching: this.state.fetchBusy,
        setFetching: this.setFetching,
        expanded: this.state.expanded,
      });
      return !isUndefined(valueContent) && !isNull(valueContent) ? (
        <KeepHeightText>{valueContent}</KeepHeightText>
      ) : (
        <KeepHeightText>{rawValue}</KeepHeightText>
      );
    };

    if (renderer) {
      return (
        <ThemeProvider theme={themes.light}>
          <div>
            {renderer({
              value: row[column.key],
              label: column.label,
              row,
              idx: this.props.idx,
              refetch: this.fetchDetails,
              fetching: this.state.fetchBusy,
              setFetching: this.setFetching,
              expanded: this.state.expanded,
            })}
          </div>
        </ThemeProvider>
      );
    } else {
      return (
        <Container $padding={[1, 0]}>
          {renderLabel()}
          {renderValue()}
        </Container>
      );
    }
  }
}
