// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_pt_BR: ITranslations = {
  pt_BR: {
    "documents.allDocuments": "Todos os documentos",
    "documents.answer": "Resposta",
    "documents.appliesTo": "Aplica-se a",
    "documents.applyFilter": "Aplicar filtro",
    "documents.button.download": "Download",
    "documents.button.open": "Abrir",
    "documents.button.print": "Imprimir",
    "documents.clearFilter": "Limpar filtro",
    "documents.documentIssue": "Edição do documento",
    "documents.documentNumber": "Número do documento",
    "documents.documentType": "Tipo de documento",
    "documents.documentTypes.ASI": "Boletim",
    "documents.documentTypes.CMREPORT": "Relatório de diagnóstico de ativos",
    "documents.documentTypes.FAQ": "FAQ",
    "documents.documentTypes.MAN": "Manual",
    "documents.documentTypes.SPC": "Catálogo de peças de reposição",
    "documents.documentTypes.SW": "Software",
    "documents.documentTypes.SWR": "Relatório de trabalho",
    "documents.documentTypes.TECHREQUEST": "Suporte técnico",
    "documents.documentTypes.TR": "Relatório técnico",
    "documents.documentTypesLongText.MAN": "Manual de manutenção e operação",
    "documents.documentTypesLongText.SPC": "Catálogo de peças de reposição",
    "documents.documentTypesLongText.couldntFindArticle": "Não foi possível encontrar o artigo!",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "Erro ao buscar artigo: {error}",
    "documents.emptyDataText": "Para restringir o âmbito da pesquisa use texto aberto ou filtros ou os âmbitos predefinidos abaixo:",
    "documents.files": "Arquivos",
    "documents.filterBasedOnDocumentType": "Filtro baseado no tipo de documento",
    "documents.forImmediateAction": "Para atenção imediata",
    "documents.listHeader": "Documentos ({totalItems})",
    "documents.listHeaderEmptyPattern": "Documentos de interesse",
    "documents.listHeaderNoItems": "Documentos",
    "documents.loadMore": "Carregar mais",
    "documents.nickName": "Apelido",
    "documents.productReferenceType": "Tipo de referência do produto",
    "documents.question": "Pergunta",
    "documents.releaseNotes": "Notas de versão",
    "documents.scopeButton.ASI": "Boletins recentes",
    "documents.scopeButton.MAN": "Manuais recentes",
    "documents.search": "Pesquisar",
    "documents.sections.attachments": "Anexos",
    "documents.sections.chapters": "Capítulos",
    "documents.sections.workcards": "Cartões de trabalho",
    "documents.shared.documentTypes.MF_CL_Analysis": "Análise",
    "documents.shared.documentTypes.MF_CL_Claims": "Reclamações",
    "documents.shared.documentTypes.MF_CL_Failure": "Falha",
    "documents.shared.documentTypes.MF_CL_Inventories": "Estoques",
    "documents.shared.documentTypes.MF_CL_MaintenancePlans": "Planos de manutenção",
    "documents.shared.documentTypes.MF_CL_MeetingMinutes": "Atas de reunião",
    "documents.shared.documentTypes.MF_CL_Report": "Relatórios",
    "documents.shared.documentTypes.MF_CL_SFOCReport": "Relatório SFOC",
    "documents.shared.documentTypes.MF_CL_Training": "Treinamento",
    "documents.shared.from": "De",
    "documents.shared.to": "Até",
    "documents.sorting.documentNumberAZ": "Número de documento (A-Z)",
    "documents.sorting.documentTypeAZ": "Tipo de documento (A-Z)",
    "documents.sorting.titleAZ": "Título (A-Z)",
    "documents.title": "Título",
    "documents.typeToSearch": "Tipo de pesquisa...",
    "documents.updateNotificationText.ASI": "O boletim requer alguma ação",
    "documents.updateNotificationText.MAN": "Capítulo atualizado",
    "documents.updateNotificationText.TR": "Foi publicado um relatório técnico novo",
    "documents.updateNotificationText.other": "O documento foi atualizado",
  },
};
