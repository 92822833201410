import { Dispatch, bindActionCreators } from "redux";
import {
  INotificationSideBarComponentDispatchProps,
  INotificationSideBarComponentOwnProps,
  INotificationSideBarComponentStateProps,
  NotificationSideBarComponent,
} from "./NotificationSideBarComponent";
import { discardAndSetNotificationAsRead, fetchNotifications } from "src/models/notifications";

import { IAppState } from "src/redux";
import { connect } from "react-redux";

const mapStateToProps = (state: IAppState): INotificationSideBarComponentStateProps => ({
  installations: state.installations,
  notifications: state.notifications,
  equipments: state.equipments,
});

const mapDispatchToProps = (dispatch: Dispatch): INotificationSideBarComponentDispatchProps =>
  bindActionCreators(
    {
      discardAndSetNotificationAsRead,
      fetchNotifications,
    },
    dispatch
  );

const NotificationSideBarContainer = connect<
  INotificationSideBarComponentStateProps,
  INotificationSideBarComponentDispatchProps,
  INotificationSideBarComponentOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSideBarComponent);
export { NotificationSideBarContainer };
