import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const PlantPricesIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Plant Prices</title>
    <path d="M24 4a20 20 0 1020 20A20 20 0 0024 4zm0 36a16 16 0 1116-16 16 16 0 01-16 16z" />
    <path d="M22.29 18a2.63 2.63 0 011.71-.62c1.78 0 2.68.85 2.68 2.54h6.6a5.92 5.92 0 00-2.54-5A9.74 9.74 0 0026 13.16V10h-4v3.19a8.9 8.9 0 00-4.24 1.74 5.83 5.83 0 00-2.4 4.79 5.12 5.12 0 00.84 2.91 6.73 6.73 0 002.44 2.16 15.52 15.52 0 004.06 1.44 13.09 13.09 0 013.3 1.05 1.52 1.52 0 01.88 1.35 1.73 1.73 0 01-.67 1.42 3.15 3.15 0 01-2 .55 4 4 0 01-2.4-.67 2.54 2.54 0 01-1-2.14h-6.12a6 6 0 001.19 3.53A8.16 8.16 0 0019.21 34a10.75 10.75 0 002.79.81V38h4v-3.16a10 10 0 004.7-1.7 5.54 5.54 0 002.57-4.71q0-4.23-4.77-5.88a25.21 25.21 0 00-3.31-.84 8.07 8.07 0 01-2.74-.89 1.63 1.63 0 01-.86-1.43 1.77 1.77 0 01.7-1.39z" />
  </IconBase>
);
