// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const sfoc_zh_CN: ITranslations = {
  zh_CN: {
    "sfoc.aBank": "A 气缸列",
    "sfoc.bBank": "B 气缸列",
    "sfoc.error.valueHasToBe24Hourformat": "时间必须为24小时格式（时：分）。",
    "sfoc.error.valueHasToBeBetween": "数值必须介于{min}和{max}之间。",
    "sfoc.error.valueHasToBeNumeric": "数值必须为数字。",
    "sfoc.error.valueIsRequired": "需要填写数值。",
    "sfoc.field.ISOCorrectedSFOC": "ISO校正SFOC",
    "sfoc.field.LTWaterTemperature": "低温水温度",
    "sfoc.field.absoluteHumidity": "绝对湿度",
    "sfoc.field.activePower": "有功功率",
    "sfoc.field.afterTestTakeFuelSample": "测试后，采集燃油样本，记录封条号。用测试套件测定水含量",
    "sfoc.field.ambientConditionValuesOnSFOCCabinetSameAsLocalWeatherStation": "SFOC机柜上的环境条件值与当地气象站相同。",
    "sfoc.field.averageFiringPressureBar": "平均点火压力{bank}",
    "sfoc.field.averageFiringPressureBarHeading": "平均点火压力",
    "sfoc.field.barometricPressure": "大气压力",
    "sfoc.field.betaFactor": "β系数",
    "sfoc.field.calculatedISOCorrectedSFOC": "计算的ISO校正SFOC",
    "sfoc.field.calculatedSFOC": "计算的SFOC",
    "sfoc.field.chargeAirPressure": "增压空气压力",
    "sfoc.field.chargeAirTemperature": "增压空气温度",
    "sfoc.field.checkThatSFOCSystemIsWorkingProperly": "开始测试前，检查SFOC系统是否正确工作",
    "sfoc.field.cleanLeakMeasurementTime": "测量时间",
    "sfoc.field.consumedFuel": "消耗的燃油",
    "sfoc.field.cylinder": "气缸{cylinderNr}",
    "sfoc.field.deltaPOverCAC": "CAC压差Δp",
    "sfoc.field.deltaPOverTCAirFilter": "T/C空气滤清器压差Δp",
    "sfoc.field.differentialAutoZeroValue": "差分自动零值",
    "sfoc.field.engine": "发动机",
    "sfoc.field.engineRoomTemperature": "发动机室温度",
    "sfoc.field.engineRoomVentilationIsOn": "发动机室通风开启",
    "sfoc.field.engineSpeed": "发动机转速",
    "sfoc.field.exhaustGasBackPressureAfterTC": "T/C后的废气背压",
    "sfoc.field.exhaustGasCleaningSystemOff": "废气清洁系统（洗涤器）关闭",
    "sfoc.field.exhaustGasTemperatureAfterTC": "T/C后的废气温度",
    "sfoc.field.exhaustGasTemperatureBeforeTC": "T/C前的废气温度",
    "sfoc.field.exhaustTemperatureAfterCylinder": "气缸{bank}后的排气温度",
    "sfoc.field.exhaustTemperatureAfterCylinderHeading": "气缸后的排气温度",
    "sfoc.field.foSampleSealNumber": "燃油样本封条号",
    "sfoc.field.fuelCalorificValueLHV": "燃油热值LHV",
    "sfoc.field.fuelFlowInlet": "燃油流入口",
    "sfoc.field.fuelFlowReturn": "燃油流回流",
    "sfoc.field.fuelInletFlowRatioToConsumption": "燃油入口流量与消耗量之比",
    "sfoc.field.fuelNetSpecificEnergyLHV": "燃油净比能LHV",
    "sfoc.field.fuelPressureBeforeEngine": "发动机前的燃油压力",
    "sfoc.field.fuelTemperatureBeforeEngine": "发动机前的燃油温度",
    "sfoc.field.fuelType": "燃油类型",
    "sfoc.field.fuelTypeIsTheSameAsUsedDuringTheBaselineTest": "燃油类型与基线测试时用的相同（通常为HFO）",
    "sfoc.field.fuelViscosityBeforeEngine": "发动机前的燃油粘度",
    "sfoc.field.generatorPowerFactor": "产生的功率因数 (Cos Φ)",
    "sfoc.field.loadIndicatorPosition": "负荷指示器位置",
    "sfoc.field.measuredCleanLeakWeight": "测得的净油泄放重量",
    "sfoc.field.measuredFOC": "测得的FOC",
    "sfoc.field.measuredFuelOilCleanLeak": "测得的燃油净油泄放量",
    "sfoc.field.nickname": "昵称",
    "sfoc.field.priorStartTestEngineShouldRunWithStableLoadFor20MinInLoadControl": "开始测试前，发动机应在负载控制模式下以稳定负载运行20分钟",
    "sfoc.field.producedEnergy": "产生的能量",
    "sfoc.field.pumpRackPosition": "泵齿条位置{bank}",
    "sfoc.field.pumpRackPositionHeader": "泵齿条位置",
    "sfoc.field.quarterNr": "季度数",
    "sfoc.field.repeatTestWhenProducedEnergyIsAboveBaseline": "产生的能量超过基准± {tolerance}%时，重复测试",
    "sfoc.field.runHoursAtCACOH": "上一次CAC大修时的运行小时数",
    "sfoc.field.runHoursAtLastCACWash": "上一次CAC清洗时的运行小时数",
    "sfoc.field.runHoursAtLastInjectionPumpOH": "上一次喷射泵大修时的运行小时数",
    "sfoc.field.runHoursAtLastInjectorOH": "上一次喷油器大修时的运行小时数",
    "sfoc.field.runHoursAtLastMajorOH": "上一次大修时的运行小时数",
    "sfoc.field.runHoursAtLastTCMaintenance": "上一次T/C维护保养时的运行小时数",
    "sfoc.field.runHoursAtLastTCWash": "上一次T/C清洗时的运行小时数",
    "sfoc.field.samplingPosition": "采样位置",
    "sfoc.field.sulfur": "硫",
    "sfoc.field.testEndTime": "测试结束时间",
    "sfoc.field.testEngineInLoadControlModeConfirmThatActivePowerIsTheSameAtBaseline": "在负载控制模式下测试发动机 & 确认有功功率与基线测试时相同",
    "sfoc.field.testFailedDueTo": "测试失败，原因为",
    "sfoc.field.testStartTime": "测试开始时间",
    "sfoc.field.totalRunningHours": "总运行小时数",
    "sfoc.field.turboChargerSpeed": "涡轮增压器转速",
    "sfoc.field.typeOfSFOCTest": "SFOC测试类型",
    "sfoc.field.waterContent": "水含量",
    "sfoc.field.waterContentMeasured": "用测试套件测得的水含量",
    "sfoc.field.waterContentMeasuredFromFuel": "从燃油测得的水含量",
    "sfoc.field.waterFuelEmulsionOff": "开始测试前，油水乳化 (WFE) 关闭至少6运行小时",
    "sfoc.info.sendingReport": "发送SFOC报告。",
    "sfoc.info.sendingReportFailed": "SFOC报告发送失败。",
    "sfoc.info.sendingReportSuccess": "SFOC报告成功发送。",
    "sfoc.meanValue": "平均",
    "sfoc.phase.automation": "自动化",
    "sfoc.phase.cabinet": "机柜",
    "sfoc.phase.engine": "发动机",
    "sfoc.phase.general": "概述",
    "sfoc.phase.section.dataCollection": "燃油计算需要的数据收集",
    "sfoc.phase.section.engineOverHaulHistory": "发动机大修历史记录",
    "sfoc.phase.section.general": "概述",
    "sfoc.phase.section.performanceTest": "发动机性能测试",
    "sfoc.phase.section.sfocPerformanceCheckList": "SFOC性能测试检查表",
    "sfoc.samplingPosition.AtFOBoosterUnite": "燃油升压单元上",
    "sfoc.samplingPosition.BeforeEngine": "发动机前",
    "sfoc.samplingPosition.Other": "其他",
    "sfoc.testFailure.DiffFlowFluctuationOver2": "流量差波动 > ±2%",
    "sfoc.testFailure.LoadAndDiffFlowFluctuationOver2": "负载和流量差波动 > ±2%",
    "sfoc.testFailure.LoadFluctuationOver2": "负载波动 > ±2%",
    "sfoc.unit.MJPerKg": "MJ/kg",
    "sfoc.unit.bar": "巴",
    "sfoc.unit.gPerKg": "g/kg",
    "sfoc.unit.gPerkWh": "g/kWh",
    "sfoc.unit.hours": "h",
    "sfoc.unit.kW": "kW",
    "sfoc.unit.kWh": "kWh",
    "sfoc.unit.kg": "千克",
    "sfoc.unit.kgPerHour": "kg/h",
    "sfoc.unit.kgPerM3": "kg/m3",
    "sfoc.unit.mbar": "毫巴",
    "sfoc.unit.mgPerKg": "mg/kg",
    "sfoc.unit.min": "最小",
    "sfoc.unit.mm": "mm",
    "sfoc.unit.mm2PerSec": "mm2/s",
    "sfoc.unit.percent": "%",
    "sfoc.unit.rpm": "转/分",
    "sfoc.value.afterOH": "大修后",
    "sfoc.value.audit": "审核",
    "sfoc.value.na": "N/A",
    "sfoc.value.no": "不是",
    "sfoc.value.off": "关闭",
    "sfoc.value.on": "开启",
    "sfoc.value.other": "其他",
    "sfoc.value.quarterly": "每季",
    "sfoc.value.yes": "是",
  },
};
