import { whenPhoneLargeOrLarger, whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";

import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import { getSelectedColorTheme } from "src/util/localstorage";
import styled from "styled-components";

interface IButton {
  isTrashButton?: boolean;
  disabled?: boolean;
}

const theme = getSelectedColorTheme();

export const Button = styled.button<IButton>`
  background-color: ${(props: ThemedProps<IButton>) => {
    if (props.disabled) {
      return props.theme.button.disabled;
    }

    if (props.isTrashButton) {
      return colors.primary.gray;
    }

    return theme === "quantiparts" ? props.theme.table.indicator : props.theme.button.outlineForeground;
  }};

  color: ${colors.primary.white};
  border: none;
  font-weight: 700;
  cursor: pointer;
  width: 45px;
  height: 52px;
  flex: 0 0 auto;
  svg {
    fill: ${(props: ThemedProps<IButton>) => (props.isTrashButton ? colors.primary.black : colors.primary.white)};
  }

  :focus {
    outline: none;
  }
`;

interface IWrapper {
  showTrashButton: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  background-color: ${colors.secondary.gray10};
  height: 100%;
  display: flex;
  max-width: 320px;

  ${whenPhoneLargeOrLarger(`
    background-color: transparent;
  `)};

  ${whenTabletOrLarger(`
    max-width: 400px;
  `)};

  ${(props) =>
    !props.showTrashButton &&
    `
    border-bottom-right-radius: ${defaultBorderRadius};
    border-top-right-radius: ${defaultBorderRadius};
  `}
`;
