import * as React from "react";

import { Container } from "src/design-system/Container";
import { IAccount } from "online-services-types";
import { IUserState } from "src/redux/user";

export type ExternalEventProps = {
  user: IUserState;
  account: IAccount;
};

export type Props = {
  children: React.ReactNode;
  event: (props: ExternalEventProps) => void;
};

export const AnalyticsEventComponent = (props: Props & ExternalEventProps) => {
  return (
    <Container onClick={() => props.event({ account: props.account, user: props.user })}>{props.children}</Container>
  );
};
