import { ThemedProps } from "src/design-system/Theme/theme";
import { spacer } from "src/design-system/Tokens/tokens";
import { fixedFontSizes, H4 } from "src/design-system/Tokens/typography";
import styled from "styled-components";

// Generic form element styles, can be split into separete components if the become more complex

export interface IStyledFormHeadingProps {
  tight?: boolean;
  noTopMargin?: boolean;
  flexAlign?: string;
}

export const StyledInputLabel = styled(H4)`
  ${(props: ThemedProps) => `
    color: ${props.theme.input.foreground2};
  `}

  font-size: ${fixedFontSizes.baseFontSize}px;
  text-transform: none;
  margin-bottom: ${spacer(1)};
  display: block;
`.withComponent("label");

export const StyledFormInputWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacer(1)};
  align-items: center;
`;

export const StyledFormHeading = styled.div`
  flex-grow: 1;
  margin-top: ${(props: IStyledFormHeadingProps) => (props.tight ? spacer(2) : props.noTopMargin ? "0" : spacer(4))};
  margin-bottom: ${(props: IStyledFormHeadingProps) => (props.tight ? spacer(2) : spacer(4))};
`;

export interface IStyledFormField {
  noMargin?: boolean; // controls whether there are top and bottom margins on the element
}

export const StyledFormField = styled.div`
  ${(props: IStyledFormField) =>
    props.noMargin
      ? ""
      : `
    margin-bottom: ${spacer(3)};
  `}
`;
