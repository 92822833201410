import { connect } from "react-redux";
import { IAppState } from "src/redux";
import { RequestCommentsComponent } from "./RequestCommentsComponent";

const mapStateToProps = (state: IAppState) => ({
  userId: state.userInfo.userId,
  userName: state.userInfo.name,
});

export const RequestCommentsContainer = connect(mapStateToProps)(RequestCommentsComponent);
