// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_ru: ITranslations = {
  ru: {
    "reports.attachments": "Приложения",
    "reports.conditionMonitorinReports": "ОТЧЕТЫ ПО ДИАГНОСТИКЕ АКТИВОВ",
    "reports.newReportAvailable": "Доступен новый отчет",
    "reports.noReportsAvailable": "Нет доступных отчетов",
    "reports.reportPeriodStartDate": "Период",
    "reports.reportPublishedDate": "Дата выпуска",
    "reports.title": "Название",
  },
};
