import { IPendingRequest } from "online-services-types";
import { APIFetch } from "src/APIFetch";

export async function doPendingRequest<ReturnType>(
  initialRequest: () => Promise<IPendingRequest | null>,
  retryCount = 1,
  previousPendingRequest?: IPendingRequest
): Promise<ReturnType> {
  const retryWaitBaseTimeMs = 500;
  const maxRetries = 100;

  // Note: The status is only checked from the spareparts service table - extend this when used in any other service
  const pendingRequest = !previousPendingRequest
    ? await initialRequest()
    : await new APIFetch<IPendingRequest>("spareparts/pending-status").get(previousPendingRequest.key);

  if (pendingRequest && pendingRequest.status === "busy" && retryCount <= maxRetries) {
    return new Promise<ReturnType>((resolve) => {
      setTimeout(() => {
        resolve(doPendingRequest(initialRequest, retryCount + 1, pendingRequest));
      }, Math.min(5000, retryWaitBaseTimeMs * retryCount));
    });
  } else if (pendingRequest && pendingRequest.status === "success") {
    return pendingRequest.response as ReturnType;
  } else {
    throw pendingRequest ? pendingRequest.error : new Error("Pending request failed");
  }
}
