import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { APIFetchStatus } from "src/APIFetch";
import { IAppState } from "src/redux";
import { setSelectedInstallation } from "src/redux/user";
import {
  IQuantipartsViewDispatchProps,
  IQuantipartsViewStateProps,
  QuantipartsViewComponent,
} from "src/views/QuantipartsView/QuantipartsViewComponent";

const mapStateToProps = (state: IAppState): IQuantipartsViewStateProps => {
  return {
    selectedInstallationName: state.userInfo.selectedInstallationName,
    selectedInstallationId: state.userInfo.selectedInstallationId,
    isReady: state.accounts.status === APIFetchStatus.Success,
    installations: state.installations,
    visibleInstallations: state.userInfo.visibleInstallations,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedInstallation,
    },
    dispatch
  );

export const QuantipartsViewContainer = connect<IQuantipartsViewStateProps, IQuantipartsViewDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(QuantipartsViewComponent);
export default QuantipartsViewContainer;
