import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const FlagIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props} viewBox="0 0 48 48">
    <title>Flag</title>
    <g>
      <polygon points="33 8 31 6 16 6 11 6 11 20 11 42 17 42 17 20 23 20 25 22 37 22 37 8" />
    </g>
  </IconBase>
);
