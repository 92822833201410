import styled from "styled-components";
import { StyledButton } from "../Button/Button";
import { spacer, spacers } from "../Tokens/tokens";
import { breakpoints } from "../Tokens/breakpoints";

interface IButtonsWrapper {
  tight?: boolean;
}

export const ButtonsWrapper = styled.div`
  margin: ${spacers([2, 0])};
  display: flex;
  ${(props: IButtonsWrapper) => props.tight && `justify-content: space-between;`};
  flex-wrap: wrap;
  gap: ${spacer(2)};

  ${StyledButton} {
    @media (max-width: ${breakpoints.mobileLarge}px) {
      min-width: auto;
      padding: 0.5em 0.75em;
    }
  }
`;
