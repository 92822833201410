import "core-js";
import "moment/locale/fi";
import "url-search-params-polyfill";

import * as serviceWorker from "src/serviceWorker";

import { Sector, defaultLang, setLanguage, setSector } from "src/util/localization";

import ReactModal from "react-modal";
import { displayPushNotfication } from "./error";
import { getUserProfile } from "src/views/ProfileView/userProfile";
import { handleClientErrorEvent } from "./handleClientError";
import moment from "moment";
import polyfills from "src/util/polyfills";
import { redirectToLogin } from "src/redux/auth";
import { setSelectedColorTheme } from "src/util/localstorage";
import { shouldRedirectToLogin } from "src/routes";

const jwtHardcodedVersion = "6";

export const initI18n = () => {
  const locale = "en";

  // Date formatting is done using moment (including react-datepicker) so this is enough.
  moment.locale(locale);

  // Force Monday as the first day of week and Jan 4 as the day that sets the first week of the year.
  // I.e. the "Finnish" style.
  moment.updateLocale(locale, {
    week: {
      dow: 1,
      doy: 4,
    },
  });
};

interface ILocation {
  href: string;
  hostname: string;
}

interface IHistory {
  // This is subset of window.location.history. No idea if we can get rid fo the any.
  replaceState(data: any, title?: string, url?: string | null): void;
}

export interface IWindow {
  localStorage: Storage;
  location: ILocation;
  history: IHistory;
  addEventListener: (param: string, handler: (event: ErrorEvent) => void) => void;
}

export async function init(windowObject: IWindow) {
  const currentPath = windowObject.location.href;
  const [pathWithoutQuerystring, queryString] = currentPath.split("?");
  const params = new URLSearchParams(queryString);

  windowObject.addEventListener("error", (event: ErrorEvent) => {
    handleClientErrorEvent(event);
  });

  initI18n();
  polyfills();

  setSector(Sector.Marine);

  ReactModal.setAppElement("#root");

  const token = params.get("token");
  const info = params.get("info");

  if (token && info) {
    // on login, set the hardcoded jwt version in local storage
    windowObject.localStorage.setItem("jwtVersion", jwtHardcodedVersion);
    windowObject.localStorage.setItem("token", token);
    windowObject.localStorage.setItem("info", info);
    windowObject.localStorage.removeItem("token_exp_date");

    // Re-add all query parameters to URL except token and info
    const strippedQueryParams = new URLSearchParams(params);
    strippedQueryParams.delete("token");
    strippedQueryParams.delete("info");

    windowObject.history.replaceState(
      {},
      document.title,
      pathWithoutQuerystring + (strippedQueryParams.size > 0 ? `?${strippedQueryParams.toString()}` : "")
    );
  } else {
    // when doing a new release and jwt changes, bump the hardcoded jwt version here, so that user will be logged off when jwt versions don't match
    if (jwtHardcodedVersion !== localStorage.getItem("jwtVersion")) {
      if (shouldRedirectToLogin(window.location.pathname)) {
        redirectToLogin(window.location);
      }
    }
  }

  // Skip user language fetch if logged anonymously
  // TODO: Not a very clean way to handle this
  if (shouldRedirectToLogin(window.location.pathname)) {
    await setLanguageForApplication(windowObject, params);
    await setThemeForApplication(windowObject);
  }
}

async function setLanguageForApplication(windowObject: IWindow, params: URLSearchParams) {
  // NOTE: Changing language/context will not trigger rerender of components
  /*
    If the language code is coming from url, then use it.
    Otherwise fetch user profile and get language from there.
   */
  const langFromParams = params.get("lang");
  if (langFromParams) {
    setLanguage(langFromParams);
  } else {
    try {
      // Hide error visible during slow login redirects
      if (windowObject.localStorage.getItem("token")) {
        const userProfile = await getUserProfile(false);
        if (userProfile) {
          setLanguage(userProfile.language);
        }
      } else {
        setLanguage(defaultLang);
      }
    } catch (err) {
      setLanguage(defaultLang);
    }
  }
}

async function setThemeForApplication(windowObject: IWindow) {
  const defaultTheme = "dark";
  try {
    // Hide error visible during slow login redirects
    if (windowObject.localStorage.getItem("token")) {
      const userProfile = await getUserProfile(false);
      if (userProfile) {
        setSelectedColorTheme(userProfile.theme.toLowerCase());
      }
    } else {
      setSelectedColorTheme(defaultTheme);
    }
  } catch (err) {
    setSelectedColorTheme(defaultTheme);
  }
}

interface IPushNotificationPayload {
  notification: {
    title: string;
    message: string;
  };
  fcmOptions?: {
    link: string;
  };
}

export function initServiceWorker() {
  serviceWorker.register({
    onMessage: (notification: IPushNotificationPayload) => {
      displayPushNotfication(
        notification.notification.message,
        notification.notification.title,
        {
          autoClose: false,
        },
        notification.fcmOptions?.link
      );
    },
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event) => {
          // Force reload to ensure the latest version is used by the user
          if ((event.target as ServiceWorker).state === "activated") {
            window.location.reload();
          }
        });

        // Post a skip_message message to the SW so that we don't need to wait until
        // the user closes the window/tab.
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    },
  });
}

export const getAppEnvironment = () => {
  const [subdomain] = window.location.hostname.split(".");

  let env: "local" | "dev" | "qa" | "test" | "prod" = "local";
  switch (subdomain) {
    case "online-dev":
      env = "dev";
      break;
    case "online-qa":
      env = "qa";
      break;
    case "online-test":
      env = "test";
      break;
    case "online":
      env = "prod";
      break;
  }

  return env;
};
