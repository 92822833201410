// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const error_pt_BR: ITranslations = {
  pt_BR: {
    "error.contactLinkText": "contatar Wärtsilä",
    "error.defaultTitle": "Há alguma coisa errada",
    "error.pleaseContact": "Se o problema persistir entre em contato com {contactLink}.",
    "success.defaultMessage": "A operação foi terminada com sucesso.",
    "success.defaultTitle": "Bem-sucedido!",
  },
};
