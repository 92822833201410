import { APIFetch } from "src/APIFetch";
import {
  IRegisterNavigationRequest, IRegisterNewsReadMoreRequest,
  IRegisterSearchWordSupportRequest,
  IRegisterViewCountRequest,
} from "online-services-types";
import { getUsingOpenSearch } from "./localstorage";

export const logDocumentView = (articleId: string) => {
  return new APIFetch<IRegisterViewCountRequest, {}>("monitoring/register-view-count").post({
    viewType: "document",
    articleId,
    isUsingOpenSearch: getUsingOpenSearch().toLowerCase() === "true",
  });
};

export const logCMReportView = (reportId: string) => {
  return new APIFetch<IRegisterViewCountRequest, {}>("monitoring/register-view-count").post({
    viewType: "cmreport",
    reportId,
  });
};

export const logSearch = (userId: string, searchSource: string, searchValue: string) => {
  return new APIFetch<IRegisterSearchWordSupportRequest, {}>("monitoring/register-search").post({
    source: searchSource,
    searchWord: searchValue,
    userId,
  });
};

export const logPageView = (eventTarget: string) => {
  return new APIFetch<IRegisterNavigationRequest, {}>("monitoring/register-navigation").post({
    eventType: "Navigation",
    eventTarget,
    timestamp: new Date().toISOString(),
  });
};

export const logNewsReadMore = (newsArticleId: string) => {
  return new APIFetch<IRegisterNewsReadMoreRequest, {}>("monitoring/register-news-read-more").post({
    eventType: "News - Read More",
    entityId: newsArticleId,
  });
};
