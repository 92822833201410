import * as React from "react";

import { ICheckbox } from "src/design-system/Checkbox/Checkbox";
import { Checkbox } from "src/design-system/Checkbox";
import { Radio } from "src/design-system/Radio";
import { IRadio } from "src/design-system/Radio/Radio";
import colors from "src/design-system/Tokens/colors";
import { Disclaimer, IDisclaimerTheme } from "src/design-system/Tokens/typography";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import styled from "styled-components";

export enum InputType {
  Checkbox,
  Radio,
}

export interface IFieldValuePair {
  label?: string;
  value?: string | number;
  component?: JSX.Element;
  width?: number;
}

export const FieldValueWrapper = styled.div`
  width: 100%;
  color: ${colors.primary.black};
`;

export const RowWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: ${colors.primary.white};
  border-radius: ${defaultBorderRadius};
  margin-bottom: 4px;
`;

export const ValuesWrapper = styled.div`
  margin-left: 6px;
  flex-direction: column;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
`;

export interface IListRowProps {
  values: IFieldValuePair[];
  index: number;
  lastIndex: number;
  isChecked: boolean;
  maxWidth?: string;
  inputType?: InputType;
  toggleChange(): void;
}

export class OptionListItem extends React.Component<IListRowProps> {
  public render() {
    const InputComponent: React.FunctionComponent<ICheckbox | IRadio> =
      this.props.inputType === InputType.Radio ? Radio : Checkbox;
    return (
      <RowWrapper style={{ maxWidth: !!this.props.maxWidth ? this.props.maxWidth : "100%" }}>
        <InputComponent
          checked={this.props.isChecked}
          onChange={this.props.toggleChange}
          color={colors.primary.black}
        />
        {this.props.values.map((item: IFieldValuePair, key: number) => (
          <ValuesWrapper
            key={key}
            onClick={() => !item.component && this.props.toggleChange()}
            style={{ width: !!item.width ? `${item.width}px` : "auto" }}
          >
            {!!item.component ? (
              <div onClick={(event) => event.preventDefault()}>{item.component}</div>
            ) : (
              <>
                <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{item.label}</Disclaimer>
                <FieldValueWrapper>{!!item.value ? item.value : "-"}</FieldValueWrapper>
              </>
            )}
          </ValuesWrapper>
        ))}
      </RowWrapper>
    );
  }
}
