import { INewsItem } from "online-services-types";
import * as React from "react";

import { Heading } from "src/components/Heading";
import { DetailsButton } from "src/design-system/DetailsButton";
import { NavigationButton } from "src/design-system/NavigationButton";
import { IconSize, NewsIcon } from "src/icons";
import { CargoIcon, ShipIcon, CrewIcon, MachineryIcon, SystemsIcon, ShipCommonIcon } from "src/icons/CategoryIcons";
import { getPathWithParams, getRoutes } from "src/routes";
import { Grid, GridRowMediumItems, GridRowSmallItems, GridSpacing } from "src/design-system/Tokens/grid";
import { translateString } from "src/util/localization";

export interface IDesignerViewStateProps {
  news: INewsItem[];
  isReady: boolean;
}

export interface IDesignerViewDispatchProps {}

export type IDesignerViewProps = IDesignerViewDispatchProps & IDesignerViewStateProps;

export class DesignerViewComponent extends React.Component<IDesignerViewProps> {
  public render() {
    const routes = getRoutes();

    return (
      <>
        <Heading text={translateString("equipmentCategories")} />
        <Grid $marginBottom={4} itemsPerRow={GridRowMediumItems} spacing={GridSpacing.airy}>
          <DetailsButton
            href={getPathWithParams(routes.MarineDocuments, { sfiMainGroup: 3, sfiMainGroupName: "cargo" })}
            icon={<CargoIcon size={IconSize.Large} />}
            label={`3: ${translateString("equipment.category.cargo")}`}
          />
          <DetailsButton
            href={getPathWithParams(routes.MarineDocuments, { sfiMainGroup: 4, sfiMainGroupName: "ship" })}
            icon={<ShipIcon size={IconSize.Large} />}
            label={`4: ${translateString("equipment.category.ship")}`}
          />
          <DetailsButton
            href={getPathWithParams(routes.MarineDocuments, { sfiMainGroup: 5, sfiMainGroupName: "crewAndPassenger" })}
            icon={<CrewIcon size={IconSize.Large} />}
            label={`5: ${translateString("equipment.category.crewAndPassenger")}`}
          />
          <DetailsButton
            href={getPathWithParams(routes.MarineDocuments, { sfiMainGroup: 6, sfiMainGroupName: "machineryMain" })}
            icon={<MachineryIcon size={IconSize.Large} />}
            label={`6: ${translateString("equipment.category.machineryMain")}`}
          />
          <DetailsButton
            href={getPathWithParams(routes.MarineDocuments, { sfiMainGroup: 7, sfiMainGroupName: "machinerySystems" })}
            icon={<SystemsIcon size={IconSize.Large} />}
            label={`7: ${translateString("equipment.category.machinerySystems")}`}
          />
          <DetailsButton
            href={getPathWithParams(routes.MarineDocuments, { sfiMainGroup: 8, sfiMainGroupName: "shipCommon" })}
            icon={<ShipCommonIcon size={IconSize.Large} />}
            label={`8:${translateString("equipment.category.shipCommon")}`}
          />
        </Grid>

        <Heading text={translateString("services")} />
        <Grid itemsPerRow={GridRowSmallItems} fallbackWidth={{ min: 125, max: 140 }} spacing={GridSpacing.airy}>
          <NavigationButton
            href={getPathWithParams(routes.News)}
            icon={<NewsIcon size={IconSize.Large} />}
            label={translateString("routes.news")}
          />
        </Grid>
      </>
    );
  }
}
