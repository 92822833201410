// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_zh_CN: ITranslations = {
  zh_CN: {
    "request.addContact": "添加联系方式",
    "request.addNewContact": "添加新联系方式",
    "request.addNewUser": "添加用户",
    "request.addressLine1": "地址行1",
    "request.addressLine2": "地址行2",
    "request.attachment.Box": "包装箱",
    "request.attachment.Box or packing material": "包装箱或包装材料",
    "request.attachment.Claimed part": "索赔备件",
    "request.attachment.Content of the box(es) and damaged parts": "包装箱中的内容和损坏的备件",
    "request.attachment.Corroded part": "锈蚀的备件",
    "request.attachment.Damage on part": "备件损坏",
    "request.attachment.Entire part": "整个备件",
    "request.attachment.Markings and / or labels on the part": "备件上的标识和/或标签",
    "request.attachment.Markings on the claimed part": "索赔备件上的标识",
    "request.attachment.Markings on the part": "备件上的标识",
    "request.attachment.Markings on the received part": "收到的备件上的标识",
    "request.attachment.Markings on the required / correct part": "需要的/正确的备件上的标识",
    "request.attachment.Other": "其他",
    "request.attachment.Packing material": "包装材料",
    "request.attachment.Packing, incl. labels": "包装，包括标签",
    "request.attachment.Pictures of leaking parts": "泄漏件图片",
    "request.attachment.Received part": "收到的备件",
    "request.attachment.Required / correct part": "需要的/正确的备件",
    "request.attachment.Required part": "需要的备件",
    "request.attachment.Test reports": "测试报告",
    "request.attachmentDescription": "请提供概览图及局部细节图。",
    "request.attachmentDialog.Confirm": "删除",
    "request.attachmentDialog.Text": "您确定要删除附件？",
    "request.attachmentDialog.Title": "删除附件",
    "request.attachments": "附件",
    "request.auxAttachmentDescription": "请提供概览图、局部细节图及产品登记牌图。",
    "request.backgroundInformation": "背景信息",
    "request.beforeClaiming": "对遗漏件提出索赔前，请确保下列检查已完成：",
    "request.beforeClaiming.checkBottom": "3. 木条箱中有时候在底部有一块木板，木板起到隔层/底层的作用，备件可能就放在该木板下面。",
    "request.beforeClaiming.checkPackage": "1. 复查交货用的包裹/包装箱/木条箱。",
    "request.beforeClaiming.checkPaddings": "2. 检查衬垫和包装材料。",
    "request.chooseReferenceRequest": "选择参考请求",
    "request.city": "城市",
    "request.claimAllItems": "由于相同原因/问题对所有交付项索赔",
    "request.claimBasketText": "{basketItems}排列项",
    "request.claimDescription": "简短的索赔说明",
    "request.claimItem": "物品索赔",
    "request.claimOrder": "订单索赔",
    "request.claimQuantity": "索赔数量",
    "request.claimReason": "索赔原因",
    "request.claimReasonChangeDislaimer": "请注意，更改索赔原因时，您的索赔项具体原因将丢失。",
    "request.claimReasons": "索赔原因",
    "request.claimReference": "参考索赔",
    "request.claimedItems": "索赔物品",
    "request.claimedOrder": "要索赔的订单",
    "request.comment": "备注",
    "request.companyName": "公司名称",
    "request.contactInformation": "联系信息",
    "request.contactInformationDescription": "您是此请求的主要联系人，但是您可添加其他有关人员，此请求的相关更改将通知他们。",
    "request.couldntFindDelivery": "无法找到交货!",
    "request.country": "国家",
    "request.createdBy": "创建人",
    "request.creationDate": "创建日期",
    "request.customerClaimReference": "客户索赔参考",
    "request.customerClaimReferenceOptional": "客户索赔参考（可选）",
    "request.customerInformation": "客户信息",
    "request.daAddressLine1IsRequired": "需要填写地址！",
    "request.daCityIsRequired": "需要填写城市！",
    "request.daCompanyNameIsRequired": "需要填写公司名称！",
    "request.daCountryIsRequired": "需要填写国家！",
    "request.daPostalCodeIsRequired": "需要填写邮政编码！",
    "request.dateOfOccurrence": "发生日期",
    "request.deliveryAddress": "交付地址",
    "request.deliveryExpected": "货物待收",
    "request.deliveryNumber": "交付编号",
    "request.deliveryReceived": "货物已收到",
    "request.describeLeakageNoticed": "泄漏是什么时候及如何注意到的",
    "request.description": "说明",
    "request.descriptionIsRequired": "需要填写说明！",
    "request.descriptionLong": "注册时我们收到的详细信息越多，我们处理请求的速度越快",
    "request.deselect": "删除",
    "request.detailedDescription": "详细说明",
    "request.detailedReason": "详细原因",
    "request.discardingDraft": "正在废弃草稿...",
    "request.draftDiscarded": "草稿已废弃。",
    "request.email": "电子邮箱",
    "request.emailIsRequired": "需要填写电子邮箱",
    "request.equipmentIsRequired": "需要填写产品",
    "request.errorWhileDiscardingDraft": "废弃草稿时错误：{error}",
    "request.errorWhileFetchingDelivery": "提取交货时错误：{error}",
    "request.exportAll": "导出全部",
    "request.external": "外部",
    "request.firstName": "名",
    "request.firstnameIsRequired": "需要填写名字",
    "request.generalInformation": "一般信息",
    "request.hadBoxBeenOpened": "包装箱/板条箱是否在您收到前已被人打开（例如，报关代理）？",
    "request.hadBoxBeenOpenedBefore": "包装箱/板条箱是否在收件人收到前已被人打开？",
    "request.haveNotifiedFreightForwarder": "您是否已经向货运代理人索赔或通知货运代理人？",
    "request.haveNotifiedInsuranceCompany": "您是否已经向保险公司索赔或通知保险公司？",
    "request.howTheReasonWasIdentified": "如何识别问题以及在什么情形下发生问题？",
    "request.howTheReasonWasIdentifiedOptional": "如何识别问题以及在什么情形下发生问题？（可选）",
    "request.ifYouWantToCommentText": "如果您想继续注释，请重新打开请求",
    "request.installationIsRequired": "需要填写设施",
    "request.interestedPeople": "有关人员",
    "request.isInWarrantyUntil": "保修期在{date}前有效",
    "request.item": "项目",
    "request.itemCount": "物品统计",
    "request.keptPartCondition": "索赔备件收到后保存在什么条件下？",
    "request.lastModified": "上一次修改",
    "request.lastName": "姓",
    "request.lastnameIsRequired": "需要填写姓",
    "request.multipleItems": "多项物品 ({count})",
    "request.newSparePartsClaim": "新备件索赔",
    "request.noRequestsFound": "未找到请求。",
    "request.nonTechnical": "客服",
    "request.notification.completedSparePartClaim": "备件索赔已完成",
    "request.notification.newAttachmentAdded": "{link}已添加",
    "request.notification.newAttachmentAdded.linkText": "新附件",
    "request.notification.newRequest": "新请求已创建",
    "request.notification.newSparePartClaim": "新备件索赔已创建",
    "request.notification.solutionPlanIsProvided": "解决方案计划已提供",
    "request.numberDoesNotMatchAccount": "输入的文档编号与您的帐户详细信息不符。请复查并重试，或者联系Wärtsilä。",
    "request.openFullRequest": "打开整份请求",
    "request.openRequests": "请求",
    "request.optional": "（选配件）",
    "request.orderInformation": "订单信息",
    "request.orderNumber": "Wärtsilä订购号",
    "request.part": "零件",
    "request.phone": "电话",
    "request.pleaseAttachDocuments": "支持文件",
    "request.pleaseEnterNumber": "请输入文档编号。",
    "request.poNumber": "您的PO编号",
    "request.possibleActionsAlreadyTaken": "已经采取的措施",
    "request.possibleActionsAlreadyTakenOptional": "已经采取的措施（可选）",
    "request.postalCode": "邮政编码",
    "request.productReferenceType": "产品参考类型",
    "request.provideDetailedDescription": "详细的问题描述",
    "request.provideDocumentNameReferenceNumber": "交货索赔文件的文件名和参考号",
    "request.provideMeasurementsClaimed": "索赔备件的尺寸",
    "request.provideMeasurementsCorrect": "需要的（正确的）备件尺寸。",
    "request.provideMeasurementsReceived": "收到的备件尺寸。",
    "request.provideMeasurementsRequired": "需要的（正确的）备件尺寸",
    "request.provideTypeSerialNumber": "如果是辅助设备，则提供单元和部件的类型与序列号。",
    "request.quantity": "数量",
    "request.rating": "等级",
    "request.rating.approveLabel": "您对服务是否满意？",
    "request.rating.customerServiceRegards": "顺颂商祺，\n\nWärtsilä",
    "request.rating.customerServiceThankYou": "感谢您，\n\nWärtsilä",
    "request.rating.feedbackGiven": "反馈已提供",
    "request.rating.feedbackSent": "反馈已发送",
    "request.rating.notSatisfied": "不满意",
    "request.rating.offlineCouldNotLoadTicket": "无法加载离线反馈工单。",
    "request.rating.offlineRequestRatedAlready": "请求已评定",
    "request.rating.offlineRequestRatedAlreadyMessage": "请求\"{requestTitle}\"已评定，但是您仍可留下反馈。",
    "request.rating.offlineRequestRatedSuccessfully": "请求成功评定",
    "request.rating.offlineRequestRatedSuccessfullyMessage": "感谢您的评定\"{requestTitle}\"。",
    "request.rating.offlineSendFeedback": "发送反馈",
    "request.rating.offlineSendingFeedback": "正在发送反馈...",
    "request.rating.offlineSendingFeedbackFailed": "发送反馈失败。",
    "request.rating.offlineSendingReopenRequest": "正在发送重新打开请求...",
    "request.rating.offlineSendingReopenRequestFailed": "发送重新打开请求失败。",
    "request.rating.openFeedback": "改进我们服务的公开反馈",
    "request.rating.openFeedbackPlaceholder": "您也可留下您的公开反馈...",
    "request.rating.openFeedbackToImproveOurService": "改进我们服务的公开反馈",
    "request.rating.reasonForReopening": "重新打开的原因",
    "request.rating.reasonIsRequired": "需要填写重新打开的原因。",
    "request.rating.reasonPlaceholder": "输入原因...",
    "request.rating.reopenButtonText": "重新打开",
    "request.rating.reopenDialogTitle": "重新打开请求",
    "request.rating.reopenRequest": "重新打开请求",
    "request.rating.requestIsClosed": "请求关闭",
    "request.rating.requestIsClosedNewRequestLinkText": "新请求",
    "request.rating.requestIsClosedRatingTitle": "请求关闭，不再可评定。",
    "request.rating.requestIsClosedTitle": "请求关闭，不再可重新打开。\n请创建{newRequestLink}。",
    "request.rating.requestReopened": "请求重新打开",
    "request.rating.requestReopenedText": "请求重新打开，我们将尽快回复您。",
    "request.rating.satisfied": "满意",
    "request.rating.sendFeedback": "发送",
    "request.rating.suggestionIsRequired": "需要填写反馈",
    "request.rating.thankYouForYourFeedback": "感谢您提供您的反馈",
    "request.rating.thankYouForYourFeedbackText": "我们一直寻找改善我们客户服务的方式，您的反馈无比珍贵。",
    "request.rating.weHaveAlreadyReceivedFeedback": "我们已经收到此请求的反馈。不能提供更多反馈。",
    "request.rating.weWillRedirectYou": "现在，我们将帮您转至{link}",
    "request.rating.whatCanWeDoBetter": "输入反馈...",
    "request.reason": "原因",
    "request.reason.9999": "备件的质量缺陷",
    "request.reason.DD13": "交付资料和发票",
    "request.reason.DD16": "备件数量错误/装货不足",
    "request.reason.DD18": "错误的备件",
    "request.reason.DD22": "推迟交付",
    "request.reason.DD23": "运输丢失（全部交货丢失）",
    "request.reason.DD24": "包装不良/交付损坏",
    "request.reason.QD02": "备件泄漏",
    "request.reason.QD03": "备件不能正常工作",
    "request.reason.QD07": "备件锈蚀",
    "request.reason.QD08": "备件损坏",
    "request.reason.QD09": "备件不合适",
    "request.reason.QD12": "备件等级问题",
    "request.reason.QD14": "备件标识/标签相关问题",
    "request.recentlyOpened": "最近打开的请求",
    "request.requestId": "请求ID",
    "request.requestType": "请求类型",
    "request.requestTypeIsRequired": "请选择请求类型",
    "request.runningHours": "运行小时数",
    "request.runningHoursDate": "更新日期",
    "request.runningHoursDateInFuture": "运行小时数日期不能是将来的日期！",
    "request.runningHoursMustBeBetween": "运行小时数必须是0到9 999 999 999之间的数字！",
    "request.runningHoursOptional": "运行小时数（可选）",
    "request.selectAddress": "选择地址",
    "request.selectCategory": "选择类别...",
    "request.selectClaimReason": "选择原因...",
    "request.selectClaimedItems": "选择索赔物品",
    "request.solution": "解决方案",
    "request.solutionPlan": "解决方案计划",
    "request.solutionPlanProvided": "提供的解决方案计划",
    "request.solutionText": "Wärtsilä解决方案",
    "request.sparePartsClaim": "备件索赔",
    "request.subject": "主题",
    "request.subjectIsRequired": "需要填写主题",
    "request.suggestedPartsText": "如果您已经知道哪些零件应该更换，请将它们添加到请求中。",
    "request.suggestedSpareParts": "建议的备件",
    "request.suggestionToReplaceParts": "更换零件的建议",
    "request.summary": "总结",
    "request.technical": "技术支持或保修请求",
    "request.title": "标题",
    "request.titleIsRequired": "需要填写标题",
    "request.track": "跟踪",
    "request.typePartNameOrNumber": "输入备件名称或编号",
    "request.types.allRequests": "所有请求",
    "request.uploadsAreNotFinished": "上传未完成！",
    "request.validation.deliveryIsRequired": "需要填写交付！",
    "request.validation.detailedReasonIsRequired": "需要填写详细原因！",
    "request.validation.fieldIsRequired": "需要填写的栏！",
    "request.validation.itemIsRequired": "需要填写物品！",
    "request.validation.quantityIsRequired": "需要填写索赔数量！",
    "request.validation.quantityMustBeNumberBetween": "输入了无效数量！（最小：{min}，最大：{max}）",
    "request.validation.quantityMustBeNumeric": "索赔数量必须是一个数字！",
    "request.validation.reasonIsRequired": "需要填写原因!",
    "request.wartsilaInitiated": "Wärtsilä发起",
    "request.wasBoxVisiblyDamaged": "如果包裹内的备件损坏，包装箱或板条箱是否明显损坏？",
    "request.wasForwarderInformed": "如果收货时包装箱或板条箱明显损坏，是否在交付时通知货运代理人？",
    "request.wasOutsideOfBoxDamaged": "包装箱或板条箱外面是否损坏？",
    "request.wasPackagingMaterialDamaged": "包装箱内的包装材料是否损坏？",
    "request.wasPackingMaterialDamagedInsufficient": "包装箱内的包装材料是否损坏或不足？",
    "request.wasPartInUseLeakage": "注意到泄漏时备件是否在使用？如果是，多少运行小时？",
    "request.wasPartInUseProblem": "注意到问题时备件是否在使用？如果是，多少运行小时？",
    "request.wasPartInspectedByWartsila": "如果备件在使用，备件是否由Wärtsilä人员检查并安装？",
    "request.whenCorrosionNoticed": "什么时候注意到锈蚀？",
    "request.whenDeliveryExpected": "预计什么时候交货？",
    "request.whenDeliveryReceived": "货物是什么时候收到的？",
    "request.whenWasDamageNoticed": "什么时候注意到损坏",
    "request.yourClaimReference": "您的索赔参考",
    "requestStatus.answered": "已完成",
    "requestStatus.approved": "已改进",
    "requestStatus.closed": "关闭",
    "requestStatus.closedChangedToProject": "关闭 - 改为项目",
    "requestStatus.draft": "草拟",
    "requestStatus.inProgress": "正在处理",
    "requestStatus.partsInDelivery": "交付中的备件",
    "requestStatus.rejected": "已拒绝",
    "requestStatus.waitingForCustomerActions": "等待客户行动",
  },
};
