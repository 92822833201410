// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_ja: ITranslations = {
  ja: {
    "contracts.attachmentDocumentTypes": "文書 ({types})",
    "contracts.attachmentImageTypes": "画像 ({types})",
    "contracts.attachmentsMessage": "最大ファイルサイズ{maxSize}で、次の種類の添付ファイルをアップロードできます。",
    "contracts.cbmExpertName": "資産診断",
    "contracts.contact": "連絡先",
    "contracts.contactInformation": "連絡先情報",
    "contracts.contactPerson": "担当者名",
    "contracts.contractManager": "契約マネージャー",
    "contracts.contractNumber": "契約番号",
    "contracts.contractType": "タイプ",
    "contracts.equipmentContracts": "製品契約",
    "contracts.expertiseCenter": "専門センター",
    "contracts.expiry": "失効日",
    "contracts.installationContracts": "設備契約",
    "contracts.installationContracts:energy": "発電プラント契約",
    "contracts.installationContracts:marine": "船舶契約",
    "contracts.installationsInScope": "範囲内の設備",
    "contracts.installationsInScope:energy": "範囲内の発電プラント",
    "contracts.installationsInScope:marine": "範囲内の船舶",
    "contracts.invalidContract": "無効な契約",
    "contracts.maintenancePlannerName": "メンテナンス計画",
    "contracts.noContactPerson": "担当者名がありません",
    "contracts.noEquipmentContractsAvailable": "利用可能な製品契約はありません",
    "contracts.noInstallationContractsAvailable": "利用可能な設備契約はありません",
    "contracts.noInstallationContractsAvailable:energy": "利用可能な発電プラント契約はありません",
    "contracts.noInstallationContractsAvailable:marine": "利用可能な船舶契約はありません",
    "contracts.productNumbers": "製品番号",
    "contracts.productReferenceTypes": "製品参照タイプ",
    "contracts.remoteSupporterName": "リモートサポーター",
    "contracts.startDate": "開始日",
    "contracts.technicalExperts": "技術専門家",
    "contracts.validation.messageIsRequired": "メッセージが必要です",
    "contracts.validation.subjectIsRequired": "件名が必要です",
  },
};
