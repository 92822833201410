import { capitalize, debounce } from "lodash";
import moment from "moment";
import { IEquipment, ILineItem, IPartModel, IPartsAvailabilityQueryParams } from "online-services-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { TableCellLoader } from "src/components/LoadingSpinner";
import { InfoIconWithTooltip } from "src/components/PartsCatalogueList/PartsMetadata";
import { IExtendedPartAvailability } from "src/redux/partsAvailability";
import { useIsFirstRender } from "src/util/customHooks/useIsFirstRender";
import { formatAvailabilityText, sparePartsDateFormat } from "src/util/formatters";

export interface ILeadTextStateProps {
  isReady: boolean;
  partsAvailability: IExtendedPartAvailability[];
}

export interface ILeadTextDispatchProps {
  getMultiplePartAvailability(
    equipmentId: string,
    parts: IPartsAvailabilityQueryParams[],
    codeResolution?: boolean
  ): void;
}

export interface ILeadTextOwnProps {
  row: IPartModel | ILineItem;
  equipment: IEquipment[];
}

export type ILeadTextProps = ILeadTextStateProps & ILeadTextOwnProps & ILeadTextDispatchProps;

const SEARCH_DELAY = 1000;

export const LeadTextComponent = ({
  getMultiplePartAvailability,
  row,
  equipment,
  isReady,
  partsAvailability,
}: ILeadTextProps) => {
  const { quantityInStock, materialId, item_number, quantity, equipmentId, id, cylinder } = row;
  const itemsEquipment = equipment.find((eq) => eq.id === equipmentId);
  const [leadText, setLeadText] = useState<string | undefined>(undefined);
  const isFirstRender = useIsFirstRender();
  // props.isReady gives us the status of the fetch for all of the rows so we need to store the local
  // status also here
  const [isSelfLoading, setSelfLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isFirstRender) {
      setSelfLoading(true);
      const params: IPartsAvailabilityQueryParams[] = [
        {
          cylinder,
          partId: id,
          materialId: materialId,
          date: moment().format(sparePartsDateFormat),
          quantity: quantity || 1,
          equipmentId: equipmentId,
          item_number: item_number,
        },
      ];

      const isAvailableInStock = quantityInStock && quantity && quantityInStock >= quantity;
      const fetchLeadTime = () => {
        if (itemsEquipment) {
          if (isAvailableInStock) return;
          getMultiplePartAvailability(
            itemsEquipment.id,
            params,
            itemsEquipment !== undefined ? itemsEquipment.hasCodeResolution : undefined
          );
        }
      };
      const debouncedFetch = debounce(fetchLeadTime, SEARCH_DELAY);

      debouncedFetch();
      return debouncedFetch.cancel;
    }
    return;
  }, [quantity]);

  useEffect(() => {
    if (isReady) {
      if (row.leadText === "") setLeadText("");
      if (isFirstRender && row.leadText) {
        setLeadText(row.leadText);
        setSelfLoading(false);
      } else {
        // The "global" fetch completed so our local status should be done as well
        partsAvailability.forEach((part) => {
          if (part.id && part.id === id) {
            setLeadText(part.leadText);
          } else if (part.materialId === row.materialId) {
            setLeadText(part.leadText);
          }
        });
        if (row.leadText) {
          setLeadText(row.leadText);
        }

        setSelfLoading(false);
      }
    }
  }, [isReady, row]);

  if (leadText === undefined || isSelfLoading) {
    return <TableCellLoader />;
  }

  const formattedValue = capitalize(formatAvailabilityText(leadText, quantityInStock, quantity || 0));
  const showInfoIcon = Number(formattedValue.substr(0, 2)) >= 4;

  return (
    <div style={{ display: "flex" }}>
      {formattedValue}
      {showInfoIcon && <InfoIconWithTooltip />}
    </div>
  );
};
