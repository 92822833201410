import React, { useContext } from "react";
import { Sidebar } from "src/components/SideMenu";
import { themes } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { CloseIcon, IconSize } from "src/icons";
import { ThemeProvider } from "styled-components";
import { ReleaseNoteSection } from "../NewsView/ReleaseNotesSection";
import { VersionContext } from "./VersionContext";

export const ReleaseNotesSidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(VersionContext);

  return (
    <Sidebar
      closeIcon={<CloseIcon color={colors.primary.black} size={IconSize.Small} />}
      background={colors.primary.white}
      isOpen={isSidebarOpen}
      onMenuStateChange={(state) => setIsSidebarOpen(state.isOpen)}
    >
      <ThemeProvider theme={themes.light}>
        <ReleaseNoteSection />
      </ThemeProvider>
    </Sidebar>
  );
};
