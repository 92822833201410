import moment from "moment";
import React from "react";
import colors from "src/design-system/Tokens/colors";
import { fixedFontSizes } from "src/design-system/Tokens/typography";
import styled from "styled-components";
import { RelativeRow } from "./shared";

const DateLabel = styled.div`
  display: inline-block;
  position: absolute;
  width: 60px;
  margin-left: -35px;
  top: 16px;
  text-align: left;
  transform: rotateZ(-45deg);
  font-size: ${fixedFontSizes.baseFontSizeSmall};
`;

const DateRow = styled.tr`
  height: 2em;
  & > * {
    border-top-color: ${colors.primary.white};
  }
`;

export const TimelineRow = (props: { start: Date; end: Date }) => {
  const dates: React.ReactNode[] = [];
  const l = props.end.getTime() - props.start.getTime();

  for (let tick = props.start.getTime(); tick <= props.end.getTime(); tick += l / 12) {
    dates.push(
      <DateLabel key={tick} style={{ left: `${((tick - props.start.getTime()) / l) * 100}%` }}>
        {moment(tick).format("MMM DD")}
      </DateLabel>
    );
  }

  return (
    <DateRow>
      <td />
      <RelativeRow>{dates}</RelativeRow>
    </DateRow>
  );
};
