import * as React from "react";
import TagManager from "@sooro-io/react-gtm-module";

const environmentGTMIds: { [key: string]: string } = {
  "online-qa.wartsila.com": "GTM-KWRHZK6",
  "online.wartsila.com": "GTM-M62CKC5",
};

export class GTMComponent extends React.PureComponent {
  public componentDidMount() {
    const gtmId = environmentGTMIds[window.location.hostname];
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }

  public render() {
    return null;
  }
}
