import { Button, ButtonStyle, ButtonType } from "src/design-system/Button/Button";
import { DropdownStyle, ISelectObject } from "src/design-system/Select/Select";
import { Grid, GridRowExtraLargeItems, GridSpacing } from "src/design-system/Tokens/grid";
import { IEquipment, IInstallation } from "online-services-types";
import { IQuarterWithDates, datesOfQuarters } from "src/views/SFOCVesselDashboardView/datesOfQuarters";
import { ISFOCData, calculateCurrentQuarter, getQuarterLabels } from "src/util/sfoc/sfocCharts";
import { getPathWithParams, getRoutes, redirectBrowser } from "src/routes";

import { APIFetch } from "src/APIFetch";
import { ArrowRightIcon } from "src/icons";
import { Container } from "src/design-system/Container";
import { FlexContainer } from "src/design-system/Container";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { MeasurementStatusChart } from "./MeasurementStatusChart";
import { MeasurementsChart } from "./MeasurementsChart";
import { PerformanceHistoryChart } from "./PerformanceHistoryChart";
import { QuarterDisplay } from "./QuarterDisplay";
import { QuarterlyDevelopmentChart } from "src/views/SFOCVesselDashboardView/QuarterlyDevelopmentChart";
import React from "react";
import { ReportLink } from "src/components/InstallationDetails/InstallationDetailsComponent";
import { Select } from "src/design-system/Select";
import { spacers } from "src/design-system/Tokens/tokens";
import styled from "styled-components";
import { translateString } from "src/util/localization";

export interface ISFOCVesselDashboardViewComponentStateProps {
  equipments: IEquipment[];
  isBusy: boolean;
}

interface ISFOCVesselDashboardViewComponentState {
  selectedInstallationId: string | undefined;
  selectedQuarter: string;
  quarterData: IQuarterWithDates;
  chartData?: ISFOCData[];
  sfocInstallations?: IInstallation[];
}

const fetchData = (): Promise<ISFOCData[]> => {
  return new APIFetch<ISFOCData[]>("service/sfoc-chart").get();
};

const fetchSFOCInstallations = (): Promise<IInstallation[]> => {
  return new APIFetch<IInstallation[]>("service/sfoc-installation").get();
};

const getMeasurmentData = (data?: ISFOCData[]): ISFOCData[] => {
  const isSFOCTested = (status: string): boolean => {
    return status === "Approved" || status === "Rejected" || status === "Suspended";
  };
  return (
    data?.map((item) => ({ ...item, quarters: item.quarters?.filter((q) => isSFOCTested(q.testValidation)) })) || []
  );
};

const SelectWrapper = styled.div`
  flex: 1;
  & > * {
    float: left;
    max-width: 250px;
    margin: ${spacers([0, 1, 1, 1])};
  }
`;

const ViewChangeButton = styled(Button)`
  height: 100%;
`;

export interface ISFOCVesselDashboardViewComponentDispatchProps {
  getEquipments(): void;
}

export type ISFOCVesselDashboardViewComponentProps = ISFOCVesselDashboardViewComponentStateProps &
  ISFOCVesselDashboardViewComponentDispatchProps;

export class SFOCVesselDashboardViewComponent extends React.Component<
  ISFOCVesselDashboardViewComponentProps,
  ISFOCVesselDashboardViewComponentState
> {
  public state: ISFOCVesselDashboardViewComponentState = {
    selectedInstallationId: undefined,
    selectedQuarter: "",
    quarterData: { quarter: "", startMeasurements: "", endMesurements: "", quarterEndDate: "" },
    chartData: undefined,
    sfocInstallations: undefined,
  };

  public componentDidMount = async () => {
    if (this.props.equipments.length === 0) {
      this.props.getEquipments();
    }
    const quarter = calculateCurrentQuarter();
    const quarterData: IQuarterWithDates = datesOfQuarters.find((dataSet) => dataSet.quarter === quarter) || {
      quarter: "",
      startMeasurements: "",
      endMesurements: "",
      quarterEndDate: "",
    };

    this.setState({
      quarterData,
      selectedQuarter: quarterData.quarter,
    });

    const [chartData, sfocInstallations] = await Promise.all([fetchData(), fetchSFOCInstallations()]);
    this.setState({
      chartData,
      sfocInstallations,
    });
  };

  public render() {
    const routes = getRoutes();
    const quarterLabels: string[] = getQuarterLabels(this.state.quarterData.quarter);
    const equipmentsWithSFOC = this.props.equipments.filter((eq) => eq.hasSFOCContract);
    equipmentsWithSFOC.sort((a, b) =>
      (a.nickName || a.description || a.serialNumber) < (b.nickName || b.description || b.serialNumber) ? -1 : 1
    );
    return (
      <Container>
        <FlexContainer>
          {this.state.selectedInstallationId && (
            <Container $margin={[0, 2, 0, 0]}>
              <ReportLink
                to={{ pathname: getPathWithParams(routes.NewSFOCReport), state: { selectedInstallationId: this.state.selectedInstallationId } }}
                label={translateString("routes.newSFOCReport")}
              />
            </Container>
          )}
          <ReportLink
            withoutIcon
            to={getPathWithParams(routes.SFOCHistory)}
            label={translateString("routes.sfocPerformanceDataHistory")}
          />
        </FlexContainer>

        <FlexContainer $spaceBetween $marginBottom={4}>
          <QuarterDisplay
            startDate={this.state.quarterData.startMeasurements}
            endDate={this.state.quarterData.endMesurements}
            quarter={"Q" + this.state.quarterData.quarter}
          />
          <SelectWrapper>
            <Select<string>
              id="installationId"
              options={this.state.sfocInstallations?.map((installation) => installation.id)}
              value={this.state.selectedInstallationId || ""}
              onChange={(value: string) => {
                this.setState({ selectedInstallationId: value });
              }}
              getOptionLabel={(option: ISelectObject<string>) =>
                this.state.sfocInstallations?.find((installation) => installation.id === option.item)?.name || ""
              }
              getOptionValue={(option: ISelectObject<string>) => option.item}
              dropdownStyle={DropdownStyle.Toolbar}
              isLoading={!this.state.sfocInstallations}
              toolbarStyleWidth={`230px`}
            />
            <Select<string>
              id="quarterNr"
              options={quarterLabels}
              value={this.state.selectedQuarter || ""}
              onChange={(value: string) => this.setState({ selectedQuarter: value })}
              getOptionLabel={(option: ISelectObject<string>) => translateString("sfoc.quarter") + option.item}
              getOptionValue={(option: ISelectObject<string>) => option.item}
              dropdownStyle={DropdownStyle.Toolbar}
            />
          </SelectWrapper>
          <ViewChangeButton
            buttonType={ButtonType.Outline}
            buttonStyle={ButtonStyle.Transparent}
            icon={<ArrowRightIcon />}
            onClick={() => redirectBrowser(getRoutes().SFOCFleetDashboard)}
          >
            {translateString("sfoc.routing.toFleetView")}
          </ViewChangeButton>
        </FlexContainer>
        {this.props.isBusy ? (
          <LoadingSpinner />
        ) : (
          <>
            {this.state.selectedInstallationId && (
              <>
                <Grid
                  spacing={GridSpacing.airy}
                  itemsPerRow={GridRowExtraLargeItems}
                  fallbackWidth={{ min: 500, max: 640 }}
                >
                  <QuarterlyDevelopmentChart
                    chartData={this.state.chartData || []}
                    quarter={this.state.selectedQuarter}
                    installationId={this.state.selectedInstallationId}
                    equipments={equipmentsWithSFOC}
                  />
                  <MeasurementStatusChart
                    chartData={this.state.chartData || []}
                    quarter={this.state.selectedQuarter}
                    equipments={equipmentsWithSFOC}
                    installationId={this.state.selectedInstallationId}
                  />
                </Grid>
                <Container $margin={[3, 0]}>
                  <PerformanceHistoryChart
                    currentQuarter={this.state.quarterData.quarter}
                    chartData={this.state.chartData || []}
                    equipments={equipmentsWithSFOC}
                    installationId={this.state.selectedInstallationId}
                  />
                </Container>
                <Container $margin={[3, 0]}>
                  <MeasurementsChart
                    chartData={getMeasurmentData(this.state.chartData)}
                    equipments={equipmentsWithSFOC}
                    installationId={this.state.selectedInstallationId}
                    quarter={this.state.quarterData.quarter}
                  />
                </Container>
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}
