import Moment from "moment";
import { IEquipment } from "online-services-types";
import React from "react";
import { Checkbox } from "src/design-system/Checkbox";
import { LocalizedString } from "src/components/Localization";
import { Container } from "src/design-system/Container";
import { FlexContainer } from "src/design-system/Container";
import { Select } from "src/design-system/Select";
import { DropdownStyle, ISelectObject } from "src/design-system/Select/Select";
import { themes } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import { Disclaimer, fontSizes } from "src/design-system/Tokens/typography";
import { SFOCTooltip } from "src/design-system/Tooltip";
import { StyledTooltip, TooltipLabel } from "src/design-system/Tooltip/Tooltip";
import { CheckIcon, IconSize } from "src/icons";
import { getSelectedColorTheme } from "src/util/localstorage";
import styled from "styled-components";

export const getDefaultColor = () => {
  const theme = getSelectedColorTheme(); //TODO: get name from context
  return theme === "light" ? themes.light.text : themes.dark.text;
};

// vessel dashboard
export interface ISFOCData {
  installationId: string;
  equipmentId: string;
  quarters: ISFOCQuarter[];
  mgoBaseline: number | null;
  hfoBaseline: number | null;
}

export interface ISFOCQuarter {
  quarter: string; // "Qxx"
  measurementDate: string;
  fuelType: "HFO" | "MGO";
  deltaToQBgPerkWh: number;
  deltaToQBPercent: number;
  isFuelSampleReceived: boolean;
  testValidation: ValidationStatus;
  reasonForRejection: string;
  ISOCorrectedSFOC: number;
  sfocqnWithFOLeak: number;
  year: number;
}

export interface ISFOCQuarterWithEquipment extends ISFOCQuarter {
  equipmentId: string;
  isNoData?: boolean;
}

// fleet dashboard
export interface ISFOCFleetData {
  accountName: string;
  accountFullName: string;
  accountId: string;
  installationId: string;
  installationName: string;
  equipmentId: string;
  equipmentName: string;
  quarter: string;
  testValidation: ValidationStatus;
  isFuelSampleReceived: boolean;
  reasonForRejection: string;
  mgoBaseline: number | null;
  hfoBaseline: number | null;
  qBPercent: number;
  qBKwh: number;
  year: number;
}

export type ValidationStatus = "Approved" | "Validation pending" | "Rejected" | "Suspended" | "Not received";

// Quarterly improvements chart (fleet & vessel view)

export interface ISFOCDataImprovements {
  installationId?: string;
  equipmentId?: string;
  quarters: ISFOCQuarter[];
  mgoBaseline: number | null;
  hfoBaseline: number | null;
}

export interface IInstalledEquipment extends IEquipment {
  currentQuarter: ISFOCQuarter | null;
}

export const TitleWrapper = styled.div`
  & > * {
    display: inline-block;
  }
`;

const StyledSFOCTooltip = styled(StyledTooltip)`
  padding: ${spacer(2)};
  visibility: visible;
  margin-left: ${spacer(2.5)};
  overflow: visible;
  position: absolute;
  min-width: 60px;
  max-width: 140px;
  &:after {
    top: 0px;
  }
`;

export const calculateScaleObjectsForDevelopmentCharts = (end: number, start: number) => {
  const objectsToReturn: { scale: React.ReactNode[]; zeroPoint: number; valueMultiplier: number } = {
    scale: [],
    zeroPoint: 0,
    valueMultiplier: 0,
  };

  const scale: React.ReactNode[] = [];
  const rangeEnd = Math.max(0, end);
  let rangeStart = Math.min(0, start);
  const wholeRange = Math.abs(rangeStart) + rangeEnd;

  let stepOnScale = 0.25;
  if (wholeRange > 2 && wholeRange < 8) {
    stepOnScale = 0.5;
  } else if (wholeRange >= 8 && wholeRange < 15) {
    stepOnScale = 1;
  } else if (wholeRange >= 15 && wholeRange < 25) {
    stepOnScale = 2;
  } else if (wholeRange >= 25 && wholeRange < 50) {
    stepOnScale = 4;
  } else if (wholeRange >= 50 && wholeRange < 75) {
    stepOnScale = 8;
  } else if (wholeRange >= 75 && wholeRange < 100) {
    stepOnScale = 10;
  } else if (wholeRange >= 100 && wholeRange < 160) {
    stepOnScale = 15;
  } else if (wholeRange >= 160) {
    stepOnScale = 20;
  }
  // we show 2 decimals, if the step is 0.25 to avoid confusion from rounding
  const toFixed = stepOnScale === 0.25 ? 2 : 1;
  rangeStart = Math.floor(rangeStart / stepOnScale) * stepOnScale;
  let zeroPoint = 0;

  for (let i = rangeStart; i <= rangeEnd; i += stepOnScale) {
    if (Math.abs(i) < 0.1) {
      zeroPoint = scale.length;
    }
    const fixedNumber = i.toFixed(toFixed);
    scale.push(
      <ScaleNumber key={i}>
        <div>{fixedNumber}</div>
      </ScaleNumber>
    );
  }

  objectsToReturn.scale = scale;
  objectsToReturn.zeroPoint = zeroPoint;
  objectsToReturn.valueMultiplier = 1 / stepOnScale / 2;

  return objectsToReturn;
};

export const DeltaToQB = (props: { value: number; unit: string }) => {
  let numberValue = props.value.toString();
  if (numberValue.length > 5) {
    numberValue = props.value.toFixed(4);
    if (props.unit === "%") {
      numberValue = props.value.toFixed(2);
    }
  }

  return (
    <StyledSFOCTooltip>
      <SFOCTooltip title={numberValue.toString() + " "} unit={props.unit} />
    </StyledSFOCTooltip>
  );
};

const barChartBarHeight = 15; // 15 pixels

// the sfoc report is valid for development chart only if qBPercent has a value
export const filterValidSFOCs = (dataSet: ISFOCFleetData) => dataSet.qBPercent !== null;

// in measurementChart we only need to show quarters starting from the first approved
export const sliceToTheFirstApprovedQuarter = (data: ISFOCData[]) =>
  data.map((item) => ({
    ...item,
    quarters: item.quarters?.slice(item.quarters?.findIndex((q) => q.testValidation === "Approved")),
  }));

export const Bar = (props: { value: number }) => {
  const borderWidth = 8 * Math.abs(props.value);

  if (props.value > 0) {
    return (
      <div
        style={{
          position: "relative",
          width: `calc(${100 * props.value * 2}% + ${borderWidth}px)`,
          height: `${barChartBarHeight}px`,
          background: colors.notification.error,
          opacity: `0.8`,
          marginLeft: `-1px`,
        }}
      />
    );
  } else if (props.value < 0) {
    return (
      <div
        style={{
          position: "relative",
          width: `calc(${100 * Math.abs(props.value) * 2}% + ${borderWidth}px)`,
          left: `calc(${-100 * Math.abs(props.value) * 2}% - ${borderWidth}px)`,
          margin: `-4px`,
          height: `${barChartBarHeight}px`,
          background: colors.notification.ok,
          opacity: `0.9`,
        }}
      />
    );
  } else {
    return null;
  }
};

export const UnitTdInFooter = styled.div`
  padding-right: 20px;
`;

// Test Follow Up Chart & Test Performance History Chart (fleet view)
const calculatePercentage = (statusValue: number, allStatusesValues: number) => (statusValue / allStatusesValues) * 100;

export const PartOfABarWithTooltip = (props: {
  status: string;
  statusValue: number;
  allStatusesValues: number;
  borderWidth: number;
  visible: boolean;
  visibleTooltipStatus: string | undefined;
  onEnter(): void;
  onExit(): void;
}) => {
  let backgroundColor: string = "";
  switch (props.status) {
    case "approved":
      backgroundColor = colors.notification.ok;
      break;
    case "received":
      backgroundColor = colors.notification.ok;
      break;
    case "rejected":
      backgroundColor = colors.notification.error;
      break;
    case "suspended":
      backgroundColor = colors.graphnode.lightblue;
      break;
    case "notReceived":
      backgroundColor = colors.uptime.nodata;
      break;
  }
  const value = calculatePercentage(props.statusValue, props.allStatusesValues);
  return (
    <>
      <div
        onMouseEnter={props.onEnter}
        onMouseLeave={props.onExit}
        style={{
          width: `calc(${100 * props.statusValue}% + ${props.borderWidth}px)`,
          height: `${barChartBarHeight}px`,
          background: backgroundColor,
          opacity: `0.8`,
        }}
      />
      {props.visible && props.visibleTooltipStatus === props.status && (
        <div>
          <DeltaToQB value={value} unit={"%"} />
        </div>
      )}
    </>
  );
};

// Tooltip wrappers

export const MultiTooltipText = styled.p`
  margin: 0 10px 5px 0;
  color: ${(props) => props?.color};
  width: 50px;
`;

export const MultiTooltipTextLong = styled(MultiTooltipText)`
  width: 120px;
`;

export const MultiTooltipLabel = styled(TooltipLabel)`
  margin: 0 10px 10px 0;
  width: 50px;
`;

export const MultiTooltipLabelLong = styled(MultiTooltipLabel)`
  width: 120px;
`;

// Quarterly improvements chart & Test Follow Up Chart & Test Performance History Chart (fleet & vessel view)

export const Table = styled.table`
  max-width: 100%;
  max-height: 400px;
  table-layout: fixed;
  border-collapse: collapse;
  & td {
    margin: 0px;
  }
  & tfoot {
    border: none;
  }
`;

export const BlockTable = styled(Table)`
  display: block;
  overflow: auto;
`;

export const DataCell = styled.td<{ isBaseline: boolean; isLastCell?: boolean }>`
  width: calc(3em - 3px);
  border: ${(props) => (!props.isLastCell ? `1px solid ${colors.secondary.bluegray80}` : `none`)};
  box-sizing: border-box;
  overflow: overflow;
  border-left: ${(props) => (props.isBaseline ? `3px solid ${getDefaultColor()}` : `none`)};
`;

export const NameDataCell = styled.td`
  width: min-width: calc(10em - 2px);
  padding: 5px 10px;
  border: 1px solid ${colors.secondary.bluegray80};
`;

export const ScaleNumber = styled.td<{ position?: string }>`
  border: none;
  & > * {
    left: ${(props) => (props.position === "right" ? 50 : -50)}%;
    border: 0;
    position: relative;
    width: calc(3em - 2px);
  }
`;

//Measurement Status Chart (fleet & vessel view)

export const largeCircleSize = 14;
export const smallCircleSize = 10;

export const YesNo = (props: { filters: string[]; onClick(status: string): void; enableFilter: boolean }) => (
  <FlexContainer $centered={true} $justified={true}>
    <Container $margin={[0, 1]}>
      <Status
        enableFilter={props.enableFilter}
        color={statusColorsWithYesNo.Yes}
        name="Yes"
        onClick={props.onClick}
        checked={props.filters.includes("Yes")}
      />
    </Container>
    <Container $margin={[0, 1]}>
      <Status
        enableFilter={props.enableFilter}
        color={statusColorsWithYesNo.No}
        name="No"
        onClick={props.onClick}
        checked={props.filters.includes("No")}
      />
    </Container>
  </FlexContainer>
);

export const TUnderlinedBody = styled.tbody`
  & > tr:last-child > * {
    border-width: 1px 1px 1px 0;
  }
`;

export const StatusWrapper = styled.div<{ color: string; enableFilter: boolean }>`
  height: 16px;
  ${(props) => (props.enableFilter ? `border: 2px solid ${props.color}` : "")};
  ${(props) => (props.enableFilter ? "cursor: pointer; padding: 2px; margin: 2px; font-size: 10px;" : "")};
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const Status = (props: {
  enableFilter?: boolean;
  checked?: boolean;
  color: string;
  name: string;
  onClick?(status: string): void;
}) => {
  const CheckWrapper = styled.div`
    border: 2px solid ${getDefaultColor()};
    display: inline-block;
    width: 12px;
    height: 12px;
    overflow: hidden;
  `;

  return (
    <StatusWrapper
      enableFilter={props.enableFilter || false}
      color={props.color}
      onClick={props.enableFilter ? () => props.onClick && props.onClick(props.name) : undefined}
    >
      {props.enableFilter && (
        <CheckWrapper>{props.checked && <CheckIcon size={IconSize.XSmall} color={getDefaultColor()} />}</CheckWrapper>
      )}
      {!props.enableFilter && <Circle size={smallCircleSize} color={props.color} />}
      <StatusLabel>{props.name}</StatusLabel>
    </StatusWrapper>
  );
};

export const statusColorsWithYesNo: { [key in ValidationStatus | "Yes" | "No"]: string } = {
  Yes: colors.notification.ok,
  No: colors.notification.error,
  Approved: colors.notification.ok,
  "Validation pending": colors.notification.warning,
  Rejected: colors.notification.error,
  Suspended: colors.graphnode.blue,
  "Not received": colors.uptime.nodata,
};

export const Statuses = (props: { filters: string[]; onClick(status: string): void; enableFilter: boolean }) => (
  <Container $margin={[0, 1]}>
    {Object.keys(statusColorsWithYesNo)
      .filter((status) => status !== "Yes" && status !== "No")
      .map((key) => (
        <Status
          enableFilter={props.enableFilter}
          checked={props.filters.includes(key)}
          onClick={props.onClick}
          key={key}
          name={key}
          color={statusColors[key]}
        />
      ))}
  </Container>
);

export const StatusTable = styled(Table)`
  width: 900px;
  margin: auto;
  font-size: ${fontSizes.xs.min}px;
  border-collapse: collapse;

  & td,
  & th {
    border: 1px solid ${colors.secondary.bluegray80};
    border-width: 1px 1px 0 0;
    padding: ${spacer(1)};
  }
`;

export const THead = styled.thead`
  & th {
    font-weight: normal;
    border: none;
    border-left: 1px solid ${colors.secondary.bluegray80};
  }

  & th:first-child {
    border: none;
  }
`;

export const StatusCellTd = styled.td`
  width: ${(props: { width: string }) => props.width};
`;

interface IStatusCellProps {
  status: keyof typeof statusColorsWithYesNo;
  width?: string;
  children?: React.ReactNode;
  onEnter?(): void;
  onExit?(): void;
  onClick?(): void;
}

export const StatusCell = (props: IStatusCellProps) => (
  <StatusCellTd
    onMouseEnter={props.onEnter}
    onMouseLeave={props.onExit}
    onClick={props.onClick}
    width={props.width || "25%"}
  >
    {<Circle color={statusColorsWithYesNo[props.status] || colors.uptime.nodata} size={largeCircleSize} />}
    {props.children}
  </StatusCellTd>
);

// mutual helpers

export const sortByNickname = (eqA: IEquipment, eqB: IEquipment) => {
  if (eqA.nickName < eqB.nickName) {
    return -1;
  }

  if (eqA.nickName > eqB.nickName) {
    return 1;
  }

  return 0;
};

export const sortQuarter = (a: { quarter: string }, b: { quarter: string }) => {
  const aNum = parseInt(a.quarter.replace("Q", ""));
  const bNum = parseInt(b.quarter.replace("Q", ""));

  return aNum - bNum;
};
export const sortQuarters = (a: ISFOCQuarter, b: ISFOCQuarter) => {
  const aNum = parseInt(a.quarter.replace("Q", ""));
  const bNum = parseInt(b.quarter.replace("Q", ""));

  return aNum - bNum;
};

export type Unit = "%" | "g/kWh" | "SFOC" | "ISO SFOC";

/*
  Calculates quarter information for given date.
  Returns current quarter as a number, start and end dates for current quarter
  and start and end dates for measurement period.
*/

export const calculateCurrentQuarter = () => {
  const targetDate = Moment.utc().format();

  const targetMoment = Moment(targetDate).utc(false);
  const firstQStartMoment = Moment("2017-03-01").utc(false); //First official quarters start date.
  const monthsSinceStartQ = targetMoment.diff(firstQStartMoment, "months", true);
  const currentQuarter = Math.floor(Math.floor(monthsSinceStartQ) / 3 + 1);

  return currentQuarter.toString();
};

export const getQuarterLabels = (endQuarterString: string) => {
  const quarterLabels: string[] = [];

  let startQuarter = 1;
  let endQuarter = Number(endQuarterString);

  for (let quarter = startQuarter; quarter <= endQuarter; quarter++) {
    quarterLabels.push(`${quarter}`);
  }

  return quarterLabels;
};

const StyledSelect = styled(Select)`
  text-transform: none;
  text-align: right;
  margin-left: 2em;
  width: 125px;
  min-width: 125px;

  .react-select__value-container {
    padding: 0;
  }
`;

export const StatusLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Circle = styled.div<{ color?: string; size: number }>`
  display: inline-block;
  margin: 0 ${spacer(1)};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: ${(props) => props.color || getDefaultColor()};
  border-radius: 50%;
`;

export const Box = styled.div<{ color?: string; size: number }>`
  display: inline-block;
  margin: 0 ${spacer(1)};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: ${(props) => props.color || getDefaultColor()};
`;

export const Baseline = styled.div<{ color?: string }>`
  width: 1em;
  height: 1px;
  border-width: 2px 0 0 0;
  border-color: ${(props) => props.color || getDefaultColor()};
  border-style: dashed;
  bottom: 0.5em;
  margin-right: ${spacer(1)};
`;

const LegendWrapper = styled.div`
  font-size: 12px;
  padding-top: 15px;
`;

export const statusColors = {
  Approved: colors.notification.ok,
  Received: colors.notification.ok,
  "Validation pending": colors.notification.warning,
  Rejected: colors.notification.error,
  Suspended: colors.graphnode.blue,
  "Not received": colors.uptime.nodata,
};

export const RowWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  @media (max-width: ${breakpoints.desktop}px) {
    max-width: 80%;
  }

  @media (max-width: ${breakpoints.mobileLarge}px) {
    max-width: 50%;
  }
`;

interface IEquipmentButtonWrapperProps {
  borderColor: string;
}

const EquipmentButtonWrapper = styled.div<IEquipmentButtonWrapperProps>`
  border: 2px solid ${(props) => props.borderColor};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  line-height: 1;
  margin-top: 5px;
`;
// Ready To Use Components

export const UnitSelector = ({ onChange, unit }: { unit: Unit; onChange: (unit: string) => void }) => (
  <StyledSelect
    onChange={onChange}
    options={["%", "g/kWh", "SFOC", "ISO SFOC"]}
    value={unit}
    dropdownStyle={DropdownStyle.Transparent}
    getOptionValue={(unit: ISelectObject<string>) => unit.item}
    getOptionLabel={(unit: ISelectObject<string>) => unit.item}
  />
);

export const EquipmentButton = ({
  equipment,
  checked,
  hfoBaseline,
  mgoBaseline,
  color,
  onClick,
}: {
  equipment: IEquipment;
  hfoBaseline: number;
  mgoBaseline: number;
  checked: boolean;
  onClick: () => void;
  color: string;
}) => (
  <EquipmentButtonWrapper onClick={onClick} borderColor={color}>
    <FlexContainer $padding={1} $margin={1}>
      <Checkbox onChange={onClick} checked={checked} />
      <Disclaimer>
        <div>{equipment.nickName}</div>
        <div>{equipment.description}</div>
        <div>
          <LocalizedString id="sfoc.hfoBaseline" />: {hfoBaseline.toFixed(4)}
        </div>
        <div>
          <LocalizedString id="sfoc.mgoBaseline" />: {mgoBaseline.toFixed(4)}
        </div>
      </Disclaimer>
    </FlexContainer>
  </EquipmentButtonWrapper>
);

export const Legend = (props: { chart: string }) => {
  const labels =
    props.chart === "testFollowUp"
      ? ["Received", "Suspended", "Not received"]
      : props.chart === "allTestHistory"
      ? ["Approved", "Rejected", "Suspended", "Not received"]
      : [];
  return (
    <LegendWrapper>
      <FlexContainer $centered={true} $justified={true}>
        {labels.map((key) => (
          <FlexContainer>
            <Box color={statusColors[key]} size={10} />
            <StatusLabel>{key}</StatusLabel>
          </FlexContainer>
        ))}
      </FlexContainer>
    </LegendWrapper>
  );
};
