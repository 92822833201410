import { IJWTUserInfo, ISFOCHistory } from "online-services-types";
import React from "react";
import { APIFetch } from "src/APIFetch";
import { IResponsiveTableColumn } from "src/components/ResponsiveTable/interfaces";
import { AttachmentList } from "src/components/SupportList/AttachmentList";
import { Button, ButtonStyle } from "src/design-system/Button";
import { AttachmentSource } from "src/models/attachments";
import { getPathWithParams, getRoutes, IRouteParams, redirectBrowser, redirectBrowserToURL } from "src/routes";
import { RouteType } from "src/routeType";
import { displayError } from "src/util/error";
import { translateString } from "src/util/localization";
import { datesOfQuarters } from "src/views/SFOCVesselDashboardView/datesOfQuarters";
import { DraftButtonContainer } from "./sfoc.components";
import { SFOCExportCSV } from "./sfoc.types";

enum SFOCStatus {
  Approved = "Approved",
  Rejected = "Rejected",
  Suspended = "Suspended",
  NotReceived = "Not Received",
  PendingReview = "Pending Review",
  Draft = "Draft",
}

export const statuses = [SFOCStatus.Approved, SFOCStatus.Rejected, SFOCStatus.Suspended, SFOCStatus.NotReceived, SFOCStatus.PendingReview, SFOCStatus.Draft];
export const quarterNumbers = datesOfQuarters.map(({ quarter }) => quarter);
export const getFirstAndLastCurrentQuarters = (): [number, number] => {
  const currentQuarterNumbers = datesOfQuarters
    .filter((dates) => new Date(dates.startMeasurements) <= new Date())
    .map(({ quarter }) => quarter);
  return [Number(currentQuarterNumbers[0]), Number(currentQuarterNumbers[currentQuarterNumbers.length - 1])];
};

export const columns = (draftButtonsDisabled: boolean, onDeleteRow: (id: string) => void, userInfo?: IJWTUserInfo): IResponsiveTableColumn<ISFOCHistory>[] => [
  {
    priority: 10,
    plannedWidth: 150,
    key: "quarterNumber",
    label: translateString("sfoc.quarterNr"),
  },
  {
    priority: 9,
    plannedWidth: 150,
    key: "dateOfTest",
    label: translateString("sfoc.dateOfTest"),
  },
  {
    priority: 8,
    plannedWidth: 200,
    key: "installationId",
    label: translateString("sfoc.vessel"),
    valueRenderer: ({ row }) => row.installationName,
  },
  {
    priority: 7,
    plannedWidth: 150,
    key: "engineNickname",
    label: translateString("sfoc.engineNickname"),
  },
  {
    priority: 6,
    plannedWidth: 150,
    key: "productReferenceType",
    label: translateString("sfoc.productReferenceType"),
  },
  {
    priority: 5,
    key: "status",
    plannedWidth: 150,
    label: translateString("sfoc.status"),
  },
  {
    priority: 5,
    key: "fuelType",
    plannedWidth: 150,
    alwaysHidden: true,
    label: translateString("sfoc.fuelType"),
  },
  {
    priority: 4,
    key: "sfocQnWithFoLeak",
    plannedWidth: 150,
    alwaysHidden: true,
    hideEmptyValues: true,
    label: translateString("sfoc.sfocQnWithFoLeak"),
  },
  {
    priority: 3,
    key: "isoCorrectedSfocQn",
    plannedWidth: 150,
    alwaysHidden: true,
    hideEmptyValues: true,
    label: translateString("sfoc.isoCorrectedSfocQn"),
    valueRenderer: ({ value }) => {
      const roundFourDecimals = (n: number) => Math.round((n + Number.EPSILON) * 10_000) / 10_000;
      return roundFourDecimals(Number(value));
    },
  },
  {
    priority: 2,
    key: "deltaToQB",
    plannedWidth: 150,
    alwaysHidden: true,
    hideEmptyValues: true,
    label: translateString("sfoc.deltaToQB"),
  },
  {
    priority: 1,
    plannedWidth: 150,
    alwaysHidden: true,
    hideEmptyValues: true,
    key: "reasonForRejection",
    label: translateString("sfoc.reasonForRejection"),
  },
  {
    priority: 1,
    plannedWidth: 300,
    key: "id",
    alwaysHidden: true,
    label: "",
    isEnabled: (row) => row.status === SFOCStatus.Draft,
    valueRenderer: ({ row }) => (
      <DraftButtonContainer>
        <Button
          disabled={draftButtonsDisabled}
          onClick={() => redirectBrowser(getRoutes().NewSFOCReport, { phase: 1, draftId: row.id })}
        >
          {translateString("sfoc.editDraft")}
        </Button>
        <Button
          disabled={draftButtonsDisabled}
          buttonStyle={ButtonStyle.Secondary} onClick={() => onDeleteRow(row.id)}
        >
          {translateString("sfoc.deleteDraft")}
        </Button>
      </DraftButtonContainer>
    ),
  },
  {
    priority: 1,
    plannedWidth: 200,
    key: "attachmentIds",
    alwaysHidden: true,
    label: translateString("attachments"),
    isEnabled: (row) => row.status !== SFOCStatus.Draft,
    valueRenderer: ({ row }) => (
      <AttachmentList
        attachmentSource={AttachmentSource.SFOC}
        isUploaderBusy={false}
        sourceId={row.id}
        isAttachmentUploadDisabled={true}
        showTitle={true}
        user={userInfo}
      />
    ),
  },
];

export const redirectToCSVExport = (fromQuarterNumber: number, toQuarterNumber: number, delimiter: string) => {
  const params: IRouteParams = { fileId: "sfocCSV" };
  let path = getPathWithParams(getRoutes(RouteType.Standalone).Download, params);
  const queryParams: { [param: string]: string } = {
    requestType: "CSVSfoc",
    delimiter,
    from: `${fromQuarterNumber}`,
    to: `${toQuarterNumber}`,
    exportAll: "true",
  };
  path += `?${Object.keys(queryParams)
    .map((key) => `${key}=${queryParams[key]}`)
    .join("&")}`;
  redirectBrowserToURL(path, true);
}

export const downloadSFOCHistoryCsv = async (fromQuarterNumber: number, toQuarterNumber: number, delimiter: string) => {
  try {
    const { url, error } = await new APIFetch<SFOCExportCSV>("service/sfoc-csv").getWithParams({
      fromQuarterNumber,
      toQuarterNumber,
      delimiter,
    });
    if (error) {
      displayError(error);
    } else {
      window.open(url, "_self");
    }
  } catch (error) {
    displayError(
      `Error while loading SFOC history: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};
