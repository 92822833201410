// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_ja: ITranslations = {
  ja: {
    "purchaseStatus.confirmed": "確認済み",
    "purchaseStatus.draft": "ドラフト",
    "purchaseStatus.expired": "期限切れ",
    "purchaseStatus.inProcess": "進行中",
    "purchaseStatus.ordered": "注文済み",
    "purchaseStatus.quoted": "見積済み",
    "spareParts": "予備部品",
    "spareParts.addLineItems": "ライン品目を追加する",
    "spareParts.addManually": "手動で追加",
    "spareParts.addMultipleLineItems": "複数のライン品目を追加する",
    "spareParts.addressSaveFailure": "アドレスの保存に失敗しました。もう一度やり直してください。",
    "spareParts.addressSaveSuccess": "アドレスが保存されました",
    "spareParts.addressWillBeInformedLater": "住所は後で通知されます",
    "spareParts.agreeToTermsAndConditionsIsRequired": "利用規約に同意する必要があります",
    "spareParts.agreeWith": "同意する",
    "spareParts.allowPartiaDelivery": "一部配送可能",
    "spareParts.askForQuotation": "見積を依頼する",
    "spareParts.askForQuote": "見積依頼",
    "spareParts.availability": "利用可能性",
    "spareParts.availabilitySubjectDisclamer": "利用可能性については事前販売の対象となります。",
    "spareParts.basketCreatedBy": "バスケット作成者",
    "spareParts.basketPriceDisclaimer": "注文合計額が200ユーロに相当する金額を下回っている場合、サプライヤーは最小請求額である200ユーロを請求します。",
    "spareParts.basketWillBeDiscarded": "バスケットに保存されていない変更があります。このページを離れると、バスケットは空になります。本当に続行してもよいですか?",
    "spareParts.blockedCustomerMessage": "残念ながら続行できません。Wärtsiläへご連絡ください。",
    "spareParts.browseSpareParts": "予備部品を閲覧する",
    "spareParts.chooseProduct": "製品を選択する…",
    "spareParts.contactInformation": "連絡先情報",
    "spareParts.copy": "Copy (コピー)",
    "spareParts.copyItemsPrompt": "以下の書式に部品番号をコピーしてください",
    "spareParts.createdBy": "作成者",
    "spareParts.createfirstOrder": "最初の注文を作成する",
    "spareParts.creationDate": "作成日",
    "spareParts.currency": "通貨",
    "spareParts.deleteBasketDraft": "下書きを削除する",
    "spareParts.deliveryAddress": "配送先住所",
    "spareParts.deliveryAddressIsFinalDestination": "配送先住所が最終目的地です",
    "spareParts.deliveryInformation": "配送情報",
    "spareParts.deliveryLocation": "配送先",
    "spareParts.deliveryLocationIsRequired": "配送先が必要です",
    "spareParts.deliveryMode": "配送モード",
    "spareParts.deliveryModeIsRequired": "配送モードが必要です",
    "spareParts.deliveryTerms": "配送条件",
    "spareParts.deliveryTermsIsRequired": "配送条件が必要です",
    "spareParts.deliverymode.AirFreight": "航空貨物輸送",
    "spareParts.deliverymode.Courier": "宅配便",
    "spareParts.deliverymode.SeaFreight": "海上貨物輸送",
    "spareParts.deliverymode.ToBeCollected": "集荷予定",
    "spareParts.deliverymode.Truck": "トラック",
    "spareParts.description": "部品名称",
    "spareParts.discard": "破棄する",
    "spareParts.dispatchingInformation": "発送情報",
    "spareParts.draft": "ドラフト",
    "spareParts.draftDeleteFailure": "下書きの削除に失敗しました",
    "spareParts.draftDeleteSuccess": "バスケットの下書きが削除されました",
    "spareParts.draftName": "下書き名",
    "spareParts.draftNameIsRequired": "下書き名が必要です",
    "spareParts.draftSaveFailure": "バスケットの下書きの保存に失敗しました。",
    "spareParts.draftSaveSuccess": "バスケットの下書きが保存されました",
    "spareParts.draftUpdateSuccess": "バスケットの下書きが更新されました",
    "spareParts.editInformation": "情報を編集する",
    "spareParts.emailAddresses": "電子メールアドレス",
    "spareParts.emptyBasket": "ショッピングバスケットは空です",
    "spareParts.errorFetchingPDF": "PDF詳細を取得中にエラーが発生しました",
    "spareParts.estimatedWeight": "部品の推定重量",
    "spareParts.etaDate": "必要なETA日付",
    "spareParts.etaDateIsRequired": "ETA日付が必要です",
    "spareParts.export": "エクスポート",
    "spareParts.exportAsCSV": "CSVでエクスポート",
    "spareParts.exportAsPDF": "PDFでエクスポート",
    "spareParts.failedFetchingManualParts": "ネットワークエラー",
    "spareParts.filterPurchases": "購入をフィルター処理する",
    "spareParts.finalDestination": "最終目的地",
    "spareParts.finalDestinationIsDifferent": "最終目的地の住所が異なります",
    "spareParts.freeComment": "追加情報",
    "spareParts.freeCommentPlaceholder": "たとえば、特別な梱包の指示、文書化のリクエストなど",
    "spareParts.freightCosts": "輸送費",
    "spareParts.freightCostsOnlyAddedAtInvoicing": "輸送費は請求時にのみ追加されます",
    "spareParts.generalInformation": "一般情報",
    "spareParts.goodsTotal": "商品合計",
    "spareParts.howToDispatchOrder": "注文をどのように発送しますか?",
    "spareParts.identificationRequired": "技術的識別が必要です",
    "spareParts.inAccordanceWithITCode": "イタリアの民法1341条および1342条に従う。",
    "spareParts.inStock": "在庫あり",
    "spareParts.isDeliveryAddressFinalDestinationAddress": "配送先住所はこの注文の最終目的地ですか?",
    "spareParts.lastItemDialogText": "本当にバスケットからこの品目を削除してもよいですか?",
    "spareParts.lastItemDialogTitle": "品目を削除",
    "spareParts.lastModified": "最終修正",
    "spareParts.leadTime": "リードタイム",
    "spareParts.lineItems": "ライン品目",
    "spareParts.loadBasketDraft": "下書きをロードする",
    "spareParts.loadMore": "さらにロード",
    "spareParts.location": "場所",
    "spareParts.makeAnotherOrder": "他の注文を作成する",
    "spareParts.makeAnotherQuote": "他の見積を作成する",
    "spareParts.markings.optional": "マーキング (オプション)",
    "spareParts.minimumPossibleBusinessDateLimit": "最短の日付は本日から3営業日後になります",
    "spareParts.nextStep": "次のステップへ",
    "spareParts.noCatalogueFound": "申し訳ありません - 製品カタログはまだ利用できません",
    "spareParts.noDescription": "利用可能な説明はありません",
    "spareParts.noFilteredPartFound": "申し訳ありません - 部分が見つかりません",
    "spareParts.noItemsAvailable": "利用可能な品目はありません",
    "spareParts.noPDFinSAP": "PDFは利用できません。予備部品チームにお問い合わせください。",
    "spareParts.noPicturesFound": "写真が見つかりません",
    "spareParts.noQuotationsOrOrders": "見積も注文もまだ作成していません。",
    "spareParts.noSalesDocumentsFound": "販売書類が見つかりません",
    "spareParts.notOfficialQuotationDisclaimer": "正式な見積ではありません。価格は、購入日の価格表に基づいたグローバル表示価格の影響を受けます。利用可能性については事前販売の対象となります。",
    "spareParts.on": "オン",
    "spareParts.onlyAllowCompleteOrder": "一式注文のみ可能",
    "spareParts.onlyNumberOrNameRequired": "この表では、予備部品番号または部品名のみが必要です。次の画面には、部品に関する詳細を追加するオプションが含まれています。",
    "spareParts.order": "注文",
    "spareParts.orderConfirmed": "注文が確認されました",
    "spareParts.orderDetails": "注文詳細",
    "spareParts.orderFullDispatch": "注文がすべて発送されました",
    "spareParts.orderNew": "新しい注文が作成されました",
    "spareParts.orderPartialDispatch": "注文の一部が発送されました",
    "spareParts.orderReceived": "注文は受領されました",
    "spareParts.partDescription": "部品説明",
    "spareParts.partDescriptionIsRequired": "部品説明が必要です",
    "spareParts.partNameOrNumber": "部品名称/番号",
    "spareParts.partNotFound": "部品が見つかりませんでした。追加情報を指定してください。",
    "spareParts.partNumber": "部品番号",
    "spareParts.partOrder.pleaseNoteMayTakeUpMinutes": "私の予備部品に注文が表示されるまで数分かかる場合があります。",
    "spareParts.partOrder.useReferenceNumber": "参照を使用して、私の予備部品から注文を見つけることができます。",
    "spareParts.partOrder.weHaveReceived": "注文を受領しました。",
    "spareParts.partOrder.weWillGetBackToYouOrder": "注文が処理され次第、ご連絡いたします。",
    "spareParts.partWeight": "重量",
    "spareParts.partWeightUnit": "重量単位",
    "spareParts.pictures": "写真",
    "spareParts.price": "価格",
    "spareParts.priceNoVatDisclaimer": "価格にはVATは含まれません。請求時に適用される有効なVATに関する法律に基づいて、VATが追加されます。",
    "spareParts.product": "製品",
    "spareParts.productId": "製品ID",
    "spareParts.productIsNotSelected": "製品が選択されていません",
    "spareParts.purchases": "購入",
    "spareParts.qtySection": "このセクションの数量",
    "spareParts.quantity": "数量",
    "spareParts.quantityUnit": "数量単位",
    "spareParts.quotation": "見積",
    "spareParts.quotationCannotBeCopied": "不明な部分を含む見積はコピーできません",
    "spareParts.quotationCreated": "新しい見積が作成されました",
    "spareParts.quotationQuoted": "見積価格が確認されました",
    "spareParts.quotationReceived": "見積は受領されました",
    "spareParts.quotationRequest.pleaseNoteMayTakeUpMinutes": "私の予備部品に見積が表示されるまで数分かかる場合があります。",
    "spareParts.quotationRequest.useReferenceNumber": "参照を使用して、私の予備部品から見積を見つけることができます。",
    "spareParts.quotationRequest.weHaveReceived": "見積を受領しました。",
    "spareParts.quotationRequest.weWillGetBackToYouQuotation": "見積が処理され次第、ご連絡いたします。",
    "spareParts.quoteDetails": "見積詳細",
    "spareParts.referenceType": "参照タイプ",
    "spareParts.requestPDF": "PDFをリクエスト",
    "spareParts.rows": "列",
    "spareParts.saveAddress": "アドレスを保存する",
    "spareParts.saveInformation": "情報を保存する",
    "spareParts.saveProgressForLater": "後で利用するために進行状況を保存しますか?",
    "spareParts.selectProduct": "製品を選択する",
    "spareParts.serialNumber.optional": "シリアル番号 (オプション)",
    "spareParts.serviceGridButtonTitle": "コマース",
    "spareParts.shoppingCart": "ショッピングカート",
    "spareParts.shoppingCartEmpty": "ショッピングカートが空です",
    "spareParts.sizeAndDimensions.optional": "サイズおよび寸法 (オプション)",
    "spareParts.sorting.purchaseTypeAZ": "購入タイプ (A-Z)",
    "spareParts.specificClauses": "特定条項",
    "spareParts.subtotal": "小計",
    "spareParts.summary": "概要",
    "spareParts.termsAndConditions": "利用規約",
    "spareParts.termsConditionsDisclaimerPDF": "部品販売はすべて、一般利用規約 - 部品 (2021) の対象となります。サプライヤーによって請求される金額が200ユーロに相当する金額を下回る場合、サプライヤーは最小請求額の200ユーロとなるように追加料金を請求するか、または輸送手数料があれば、それに相当額が追加された手数料を請求するものとします。",
    "spareParts.termsDisclaimer": "Wärtsiläとの間で署名された予備部品供給契約を締結している場合、本契約は注文が行われたときに有効となり、本契約の利用規約のみが適用されます。",
    "spareParts.thankYou": "ありがとうございました",
    "spareParts.thereWasErrorWhenSendingOrder": "注文の送信時にエラーが発生しました。",
    "spareParts.thereWasErrorWhenSendingQuotation": "見積の送信時にエラーが発生しました。",
    "spareParts.total": "Total",
    "spareParts.totalSum": "総計",
    "spareParts.typeInEmails": "電子メールアドレスを入力する",
    "spareParts.typePartNameOrNumber": "部品名称または番号を入力",
    "spareParts.unsavedChanges": "保存されていない変更",
    "spareParts.usageTarget.optional": "使用法/システム/単位 (オプション)",
    "spareParts.vatDisclaimer": "価格にはVATは含まれません。請求時に適用される有効なVATに関する法律に基づいて、VATが追加されます。",
    "spareParts.vendorCode.optional": "ベンダー部品コード (オプション)",
    "spareParts.wartsilaReference": "Wärtsiläの参照",
    "spareParts.youCanAddPartsUsingFollowingAction": "次のアクションを使用して予備部品を追加することもできます",
    "spareParts.yourReference": "あなたの参照",
    "spareParts.yourReferenceIsRequired": "参照が必要です",
    "spareparts.errorFetchingDetails": "注文詳細を取得中にエラーが発生しました",
  },
};
