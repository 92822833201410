import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const AuxiliariesIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Auxiliaries</title>
    <path d="M24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 C35.045695,44 44,35.045695 44,24 C44,18.6956702 41.8928632,13.6085919 38.1421356,9.85786438 C34.3914081,6.10713681 29.3043298,4 24,4 Z M32.74,37.38 L32.74,37.38 C32.1317362,37.7794591 31.496929,38.1369557 30.84,38.45 L30.75,38.45 C30.43,38.6 30.1,38.74 29.75,38.87 L29.66,38.87 C29.32,38.9999998 28.99,39.12 28.66,39.22 L28.66,39.22 C27.153827,39.7091821 25.5833746,39.9720476 24,39.9999998 C23.0542249,39.9955636 22.1106426,39.9085666 21.18,39.74 L21.18,39.74 C19.7277531,39.4671435 18.320796,38.9925479 17,38.33 L17,38.33 C14.5035665,37.1166329 12.3615038,35.2810711 10.78,32.9999998 L10.78,32.9999998 C10.62,32.76 10.46,32.51 10.32,32.26 C10.2608674,32.1770387 10.2074121,32.0901739 10.16,31.9999998 C9.99,31.71 9.83,31.42 9.68,31.12 L9.68,31.06 C9.36219918,30.4124432 9.08824935,29.7442729 8.86,29.06 L8.86,29.06 C8.30290965,27.4306016 8.01250742,25.7219559 8,23.9999998 C8.00326706,22.9695111 8.10371178,21.9416269 8.3,20.93 L8.3,20.93 C8.59195134,19.4560354 9.09372305,18.0315426 9.79,16.7 L9.79,16.7 C10.9853658,14.3730619 12.7330583,12.374718 14.88,10.88 L14.88,10.88 C16.0862894,10.0326013 17.4059165,9.35932216 18.8,8.88 L18.8,8.88 C20.4717424,8.29722315 22.22959,7.99999983 24,7.99999983 C24.952918,8.00323784 25.9035433,8.09361419 26.84,8.27 L26.84,8.27 C28.2853474,8.53970072 29.6856782,9.01096588 31,9.67 L31,9.67 C33.391364,10.837977 35.4536151,12.5840162 37,14.75 L37,14.75 C37.8545986,15.9807844 38.5281316,17.3278505 39,18.75 L39,18.75 C39.9692533,21.3873984 40.2110378,24.2370012 39.7,26.9999998 L39.7,26.9999998 C39.4098431,28.4780848 38.9150832,29.9085132 38.23,31.25 L38.23,31.25 C36.9619082,33.7344052 35.0701137,35.8467368 32.74,37.38 Z"></path>
    <circle cx="24.01" cy="24" r="3"></circle>
    <path d="M29.73,25.78 C29.3082218,27.1061322 28.4385984,28.2444198 27.27,29 C29.9288343,30.411433 32.8719779,31.2044752 35.88,31.32 C36.6545506,30.0861084 37.2251992,28.7354609 37.57,27.32 C34.868485,27.4779278 32.1710502,26.9480745 29.73,25.78 L29.73,25.78 Z"></path>
    <path d="M19.1,27.45 C17.7389379,30.1005426 16.9872939,33.0217042 16.9,36 C18.1433624,36.7353668 19.4924846,37.2750157 20.9,37.6 C20.7310099,34.8955438 21.246924,32.1921536 22.4,29.74 C21.0695721,29.3784722 19.9042342,28.5697983 19.1,27.45 L19.1,27.45 Z"></path>
    <path d="M18,24 C18.004719,23.664873 18.034805,23.3305842 18.09,23 C15.2464675,23.8721653 12.6354128,25.3724965 10.45,27.39 C10.7968334,28.7810819 11.356789,30.1101331 12.11,31.33 C13.9393873,29.3505867 16.236173,27.8618019 18.79,27 C18.2646738,26.0880032 17.9919769,25.0524455 18,24 L18,24 Z"></path>
    <path d="M18.28,22.2 C18.7058475,20.8812516 19.5750588,19.7505701 20.74,19 C18.0781502,17.5871157 15.1314255,16.7940297 12.12,16.68 C11.3495544,17.9160254 10.7791922,19.2659951 10.43,20.68 C10.74,20.68 11.06,20.63 11.37,20.63 C13.7617265,20.630292 16.1229617,21.1667811 18.28,22.2 L18.28,22.2 Z"></path>
    <path d="M21,18.82 C21.9105476,18.2877421 22.9453099,18.0049071 24,18 C24.311678,18.0009239 24.622743,18.0276822 24.93,18.08 C24.0279513,15.2483088 22.5079914,12.6524221 20.48,10.48 C19.0815145,10.8433178 17.7486561,11.4237017 16.53,12.2 C18.5557226,13.9932633 20.0936486,16.2709076 21,18.82 Z"></path>
    <path d="M28.9,20.55 C30.2645332,17.9002707 31.0196044,14.9790678 31.11,12 C29.8687477,11.2580966 28.5191094,10.7148672 27.11,10.39 C27.2789901,13.0944562 26.763076,15.7978464 25.61,18.25 C26.9382114,18.6161897 28.0999324,19.428335 28.9,20.55 L28.9,20.55 Z"></path>
    <path d="M30,24 C29.9975545,24.3522569 29.9640919,24.7036142 29.9,25.05 C32.7498261,24.0941003 35.3475357,22.5081303 37.5,20.41 C37.1322977,18.992363 36.5415945,17.6421842 35.75,16.41 C34.0024001,18.4766649 31.7591053,20.0662389 29.23,21.03 C29.7440959,21.9347708 30.0097453,22.9594185 30,24 Z"></path>
    <path d="M26.55,29.42 C25.7542006,29.8019725 24.8827228,30.000191 24,30 C23.479733,29.9970647 22.9620574,29.9264726 22.46,29.79 C23.3495379,32.7268861 24.9029506,35.4196961 27,37.66 C28.4090717,37.3399969 29.758946,36.8000472 31,36.06 C28.9787853,34.2588006 27.4476734,31.9741752 26.55,29.42 L26.55,29.42 Z"></path>
  </IconBase>
);
