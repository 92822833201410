import { AnalyticsEventComponent, Props } from "./AnalyticsEventComponent";

import { IAppState } from "src/redux";
import { connect } from "react-redux";

const mapStateToProps = (state: IAppState) => ({
  user: state.userInfo,
  account: (state.accounts.data || []).find((account) => account.selected) || {},
});

export const AnalyticsEventContainer = connect<ReturnType<typeof mapStateToProps>, {}, Props>(mapStateToProps)(
  AnalyticsEventComponent
);

export const analyticsDocumentTypes = {
  COC: "Declaration of Compliance",
  ZRD0: "Proforma",
  ZRC0: "Proforma",
  ZRP0: "Proforma",
  ZRD1: "Proforma",
  ZRC1: "Proforma",
  ZRP1: "Proforma",
  ZPR0: "Delivery note",
  ZPR1: "Delivery note",
  IHM: "IHM",
  REA: "REACH",
  ROH: "RoHS",
  MSD: "MSDS",
  PL: "Packing list",
  order: "Order Acknowledgement",
  quotation: "Quotation PDF",
  CCE: "Classification certificate",
};
