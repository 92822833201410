import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const LockIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Lock</title>
    <path d="M11,20.58a10,10,0,0,1,13.11-9.51l1.24-3.8A14,14,0,0,0,7,20.58V39.42H27a8,8,0,0,0,8-8v-8H11V20.58Zm8,10.61V26.42h4v4.78A3,3,0,1,1,19,31.19Z" />
    <rect x="33.79" y="11.83" width="4" height="2" transform="translate(1.41 29.07) rotate(-45)" />
    <rect x="29" y="9.42" width="2" height="4" />
    <rect x="35" y="17.42" width="4" height="2" />
  </IconBase>
);
