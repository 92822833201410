import styled from "styled-components";

import colors from "src/design-system/Tokens/colors";
import { Disclaimer, H4, P } from "src/design-system/Tokens/typography";

interface ITableCellProps {
  center?: boolean;
  noPadding?: boolean;
  verticalAlign?: string;
}

interface ITableBodyProps {
  hasZebraStripes: boolean;
  selected?: boolean | undefined;
  outline?: boolean | undefined;
  fullBorder?: boolean | undefined;
}

export const Table = styled.table``;

interface ITableWrapper {
  staticWidthColumns?: boolean;
}

export const TableWrapper = styled.div<ITableWrapper>`
  & > table {
    width: 100%;
    border-collapse: collapse;

    ${(props: ITableWrapper) => (props.staticWidthColumns ? "table-layout: fixed" : "")};
  }

  ${Disclaimer} {
    color: ${colors.secondary.gray40};
  }
`;

export const TableHead = styled.thead``;

export const TableHeadRow = styled(H4)`
  color: ${colors.primary.white};
  text-transform: none;
`.withComponent("tr");

export const TableHeadCell = styled.th`
  background: ${colors.primary.blue};
  padding: 0.5em;
  text-align: left;

  &.sortable {
    cursor: pointer;
  }

  &.currentSort {
    background: ${colors.secondary.blue100};
  }
`;

export const TableBody = styled.tbody`
  ${(props: ITableBodyProps) => {
    if (props.hasZebraStripes && !props.selected) {
      return `
        &:nth-child(even) > * > * {
          background: ${colors.primary.white};
        }

        &:nth-child(odd) > * > * {
          background: ${colors.secondary.gray10};
        }
      `;
    } else if (props.selected) {
      return `& > * > * {
          background: ${colors.secondary.gray10};
        }`;
    } else {
      return `
        & > * > * {
          background: ${colors.primary.white};
        }`;
    }
  }}

  ${(props: ITableBodyProps) =>
    props.outline &&
    `td {
        border: 1px solid ${colors.primary.blue};
        border-style: solid none;

        :first-child {
          border-style: none;
        }
        :last-child {
          border-style: solid solid solid none;
        }
      }`};

  ${(props: ITableBodyProps) =>
    props.fullBorder &&
    `
        border: 1px solid ${colors.secondary.gray40};
        ::after {
          content: none !important;
        }
      `};

  color: ${colors.primary.black};
  vertical-align: top;
`;

export const TableBodyRow = styled(P)`
  color: ${colors.primary.black};
  background: transparent;

  &.extendable {
    cursor: pointer;
  }
`.withComponent("tr");

export const TableCell = styled.td`
  ${(props: ITableCellProps) => props.center && `text-align: center;`};
  ${(props: ITableCellProps) => props.verticalAlign && `vertical-align: ${props.verticalAlign};`};
  ${(props: ITableCellProps) => props.noPadding !== true && `padding: .5em .5em .75em .5em;`};
`;

export const KeyValuePair = styled.div`
  margin-bottom: 1em;
`;

export const KeyValueKey = styled.div`
  font-weight: 700;
`;

export const KeyValueValue = styled.div``;
