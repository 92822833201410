import * as React from "react";
import { fixedFontSizes } from "src/design-system/Tokens/typography";
import { AlarmIcon, AlarmOffIcon, IconSize } from "src/icons";
import { askPermission, revokeFirebaseToken, updateFirebaseToken } from "src/serviceWorker";
import { translateString } from "src/util/localization";
import styled from "styled-components";

interface IPushPermissionNotificationState {
  pushNotificationPermission: boolean;
  requestOngoing: boolean;
}

export interface IPushPermissionNotificationStateProps {
  userId: string;
}

type PushNotificationButtonProps = {
  disabled?: boolean,
};

const PushNotificationButton = styled.div.attrs({ tabIndex: 0 })<PushNotificationButtonProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  cursor: ${({ disabled }) => disabled ? "auto" : "pointer"};
  user-select: none;
`;

// tslint:disable-next-line: no-empty-interface
export interface IOwnProps {}
export type IProps = IOwnProps & IPushPermissionNotificationStateProps;

export class PushPermissionNotification extends React.Component<IProps, IPushPermissionNotificationState> {
  constructor(props: IProps) {
    super(props);

    this.state = { pushNotificationPermission: false, requestOngoing: false };
  }

  public async componentDidMount() {
    const initial = localStorage.getItem(this.getKey());
    this.setState({ pushNotificationPermission: initial === "true" });
  }

  public render() {
    return (
      <PushNotificationButton disabled={this.state.requestOngoing} onClick={async () => {
        if (this.state.requestOngoing) return;
        this.setState({ requestOngoing: true });
        if (!this.state.pushNotificationPermission) {
          const permission = await askPermission();
          this.setState({ pushNotificationPermission: permission });
          if (permission) {
            await updateFirebaseToken();
          }
        } else {
          this.setState({ pushNotificationPermission: false });
          await revokeFirebaseToken();
        }
        localStorage.setItem(this.getKey(), this.state.pushNotificationPermission + "");
        this.setState({ requestOngoing: false });
      }}>
        <span>
          {translateString(this.state.pushNotificationPermission ? "notifications.pushNotificationsOn" : "notifications.pushNotificationsOff")}
        </span>
        {this.state.pushNotificationPermission
          ? <AlarmIcon size={IconSize.Medium} />
          : <AlarmOffIcon size={IconSize.Medium} />
        }
      </PushNotificationButton>
    );
  }

  private getKey = () => `pushNotificationPermission-${this.props.userId}`;
}
