// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fieldServices_es_MX: ITranslations = {
  es_MX: {
    "fieldServices.actualFinishDate": "Fecha de terminación real",
    "fieldServices.actualStartDate": "Fecha de inicio real",
    "fieldServices.description": "Descripción de trabajo",
    "fieldServices.estimatedFinishDate": "Fecha de terminación calculada",
    "fieldServices.estimatedStartDate": "Fecha de inicio calculada",
    "fieldServices.poNumber": "Número de pedido",
    "fieldServices.productReferenceType": "Tipo de referencia de producto",
    "fieldServices.recommendations": "Recomendaciones",
    "fieldServices.serviceWorkCoordinator": "Coordinador de trabajo de servicio",
  },
};
