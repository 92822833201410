import * as React from "react";
import styled from "styled-components";
import { Radio as UIRadio } from "@wartsila/ui-kit";
import { ThemedProps } from "../Theme/theme";
import { fixedFontSizes } from "../Tokens/typography";

const RadioInput = styled(UIRadio)`
  label {
    font-size: ${fixedFontSizes.baseFontSize}px;
    ${(props: ThemedProps) => `
      color: ${props.theme.input.foreground2};
    `}
  }
`;

export interface IRadio {
  checked?: boolean;
  value?: string;
  name?: string;
  id?: string;
  label?: string | JSX.Element;
  disabled?: boolean;
  invalid?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?(arg: any): void;
}

export const Radio = (props: IRadio) => <RadioInput {...props} />;
