import * as React from "react";

import { FormStyleProvider, FormTheme } from "src/design-system/Theme/form";
import { validateForm, validators } from "src/util/validators";

import { Field } from "formik";
import { FormDialog } from "src/components/Dialog";
import { TextInput } from "src/design-system/TextInput";
import { translateString } from "src/util/localization";

interface INewPersonDialogProps {
  isOpen: boolean;
  handleCancel: () => void;
  handleAdd: (person: INewPerson) => void;
  onClose: () => void;
  onlyNameAndEmail?: boolean;
  onlyEmail?: boolean;
  defaultEmail?: string;
}
export interface INewPerson {
  title: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export class NewPersonDialog extends React.Component<INewPersonDialogProps, INewPerson> {
  public handleAdd = (values: INewPerson) => {
    this.props.handleAdd(values);
    this.props.onClose();
  };

  public getValidator(isRequired: boolean) {
    if (isRequired) {
      return validators.isRequired;
    } else {
      return () => true;
    }
  }

  public render() {
    return (
      <FormDialog<INewPerson>
        title={translateString(this.props.onlyEmail ? "request.addNewEmailAddress" : "request.addNewContact")}
        submitButtonTitle={translateString("request.addContact")}
        isDialogOpen={this.props.isOpen}
        onCancel={this.props.handleCancel}
        initialValues={{
          email: this.props.defaultEmail ? this.props.defaultEmail : "",
          firstName: "",
          lastName: "",
          phone: "",
          title: "",
        }}
        validate={(values) =>
          validateForm(values, {
            email: [
              { valErr: translateString("request.emailIsRequired"), valFn: validators.isRequired },
              { valErr: translateString("request.faulthyEmail"), valFn: validators.isEmail },
            ],
            firstName: [{ valErr: translateString("request.firstnameIsRequired"), valFn: this.getValidator(!this.props.onlyEmail) }],
            lastName: [{ valErr: translateString("request.lastnameIsRequired"), valFn: this.getValidator(!this.props.onlyEmail) }],
            title: [{ valErr: translateString("request.titleIsRequired"), valFn: this.getValidator(!this.props.onlyNameAndEmail && !this.props.onlyEmail) }],
          })
        }
        onSubmit={this.handleAdd}
      >
        <FormStyleProvider theme={FormTheme.Dark}>
          {!this.props.onlyNameAndEmail && !this.props.onlyEmail && (
            <Field name="title" label={translateString("request.title")} component={TextInput} />
          )}
          {!this.props.onlyEmail && (
            <>
              <Field name="firstName" label={translateString("request.firstName")} component={TextInput} />
              <Field name="lastName" label={translateString("request.lastName")} component={TextInput} />
            </>
          )}
          {!this.props.onlyNameAndEmail && !this.props.onlyEmail && (
            <Field name="phone" label={translateString("request.phone.optional")} component={TextInput} />
          )}
          <Field name="email" label={translateString("request.email")} component={TextInput} />
        </FormStyleProvider>
      </FormDialog>
    );
  }
}
