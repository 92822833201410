import { IRunningHoursBatchUpdateRequest, IRunningHoursBatchUpdateResult } from "online-services-types";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "src/util/error";

import { APIFetch } from "src/APIFetch";
import { APIResourceState } from "src/redux/api";
import { Dispatch } from "redux";
import { IAppState } from "src/redux";
import { IUserState } from "./user";
import moment from "moment";
import { translateString } from "src/util/localization";

export const updateRunningHours = (request: IRunningHoursBatchUpdateRequest) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(updateRunningHoursStartedAction());
    try {
      const toastId = displayProgress("Updating running hours...");
      const response = await new APIFetch<IRunningHoursBatchUpdateRequest, IRunningHoursBatchUpdateResult>(
        "service/update-running-hours"
      ).post(request);
      dismissMessage(toastId);

      if (response !== null) {
        dispatch(
          APIResourceState.equipments.patchAction(
            response.updateStatus
              .filter((status) => status.success && status.runningHours !== undefined)
              .map((status) => ({
                id: status.equipmentId,
                runningHours: status.runningHours,
                runningHoursDate: status.runningHoursDate
              }))
          )
        );
      }

      if (response !== null && !response.success) {
        displayError("An error occurred. Running hours might have been partially updated.");
        dispatch(updateRunningHoursFailureAction(response));
      } else {
        dispatch(updateRunningHoursSuccessAction());
        displaySuccess("Running hours updated!");
      }
    } catch {
      dispatch(updateRunningHoursFailureAction());
    }
  };
};

export const requestUpdateRunningHours = (
  equipments: Array<{ id: string; runningHours: string; runningHoursDate?: string }>,
  installationId: string,
  requestReason: string,
  userInfo: IUserState
) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(updateRunningHoursStartedAction());
    const toastId = displayProgress(translateString("request.sendingRunningHoursRequest"));
    try {
      new APIFetch(`service/request/update-equipment-running-hours`)
        .post({
          equipment: equipments,
          description: `
        Installation Id: ${installationId}\n
        Date: ${moment().format("DD.MM.YYYY")}\n
        User: ${userInfo.name} (id: ${userInfo.userId})\n
        Description: ${requestReason}
      `,
        })
        .then(() => {
          dismissMessage(toastId);
          dispatch(updateRunningHoursSuccessAction());
          displaySuccess(translateString("request.runningHoursRequestSent"));
        });
    } catch (error) {
      dismissMessage(toastId);
      dispatch(updateRunningHoursFailureAction());
      displayError(
        `${translateString("request.errorWhileSendingRunningHoursRequest")}: ${(error as Error).message}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
  };
};

const UPDATE_RUNNING_HOURS_STARTED = "UPDATE_RUNNING_HOURS_STARTED";
const UPDATE_RUNNING_HOURS_SUCCESS = "UPDATE_RUNNING_HOURS_SUCCESS";
const UPDATE_RUNNING_HOURS_FAILURE = "UPDATE_RUNNING_HOURS_FAILURE";

interface IAction {
  type: string;
  payload?: {};
}

const updateRunningHoursStartedAction = (): IAction => ({
  type: UPDATE_RUNNING_HOURS_STARTED,
});

const updateRunningHoursSuccessAction = (): IAction => ({
  type: UPDATE_RUNNING_HOURS_SUCCESS,
});
const updateRunningHoursFailureAction = (result?: IRunningHoursBatchUpdateResult): IAction => ({
  type: UPDATE_RUNNING_HOURS_FAILURE,
  payload: result,
});

export interface IUpdateRunningHoursState {
  updateInProgress: boolean;
  failureStatus: IRunningHoursBatchUpdateResult | null;
}

const initialState: IUpdateRunningHoursState = {
  updateInProgress: false,
  failureStatus: null,
};

export function runningHoursReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case UPDATE_RUNNING_HOURS_STARTED:
      return {
        ...state,
        updateInProgress: true,
      };
    case UPDATE_RUNNING_HOURS_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
      };
    case UPDATE_RUNNING_HOURS_FAILURE:
      return {
        ...state,
        updateInProgress: false,
        failureStatus: action.payload,
      };
  }
  return state;
}
