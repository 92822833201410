import { ILineItem } from "online-services-types";
import { capitalize } from "lodash";
import { formatCurrency } from "src/util/formatters";
import { getCurrencySymbol } from "src/util/currencyMapper";
import { translateString } from "src/util/localization";

class ExportPartModel {
  public price: number;
  public weight: number;
  public quantity: number;
  public currency: string;
  public subtotal: number;
  public productId: string;
  public weightUnit: string;
  public partNumber: string;
  public description: string;
  public currencyCode: string;
  public quantityUnit: string;
  public availability: string;
  public installation: string;
  public referenceType: string;

  constructor(part: ILineItem, { installation }: { installation: string }) {
    this.quantityUnit = part.unit;
    this.weight = part.netWeight;
    this.productId = part.productId;
    this.weightUnit = part.weightUnit;
    this.price = part.netPrice || 0;
    this.quantity = part.quantity || 0;
    this.currency = part.currency || "";
    this.referenceType = part.referenceEngine;
    this.currencyCode = part.currencyCode || "";
    this.subtotal = (part.netPrice || 0) * part.quantity;
    this.availability = this.formatAvailability(part, this.quantity)?.toLowerCase();
    this.description = part.spnDescription || part.materialText;
    this.partNumber = part.id ? part.id : part.materialId;
    this.installation = installation;
  }

  private static get dictionaryInOrder() {
    return {
      // The elements placement order affects columns placement in PDF.
      installation: translateString("spareParts.installation"),
      productId: translateString("spareParts.productId"),
      referenceType: translateString("spareParts.referenceType"),
      partNumber: translateString("spareParts.partNumber"),
      description: translateString("spareParts.description"),
      quantity: translateString("spareParts.quantity"),
      quantityUnit: translateString("spareParts.quantityUnit"),
      price: translateString("spareParts.price"),
      subtotal: translateString("spareParts.subtotal"),
      currency: translateString("spareParts.currency"),
      weight: translateString("spareParts.partWeight"),
      weightUnit: translateString("spareParts.partWeightUnit"),
      availability: translateString("spareParts.availability"),
    };
  }

  public get headers() {
    const headersKeys = Object.keys(ExportPartModel.dictionaryInOrder);

    return headersKeys.map((headerKey: string) => {
      return {
        key: headerKey,
        label: ExportPartModel.dictionaryInOrder[headerKey],
      };
    });
  }

  public forPDF() {
    return {
      weight: this.weight ? `${this.weight} ${this.weightUnit}` : "",
      price: this.price ? `${formatCurrency(this.price)} ${getCurrencySymbol(this.currencyCode)}` : "",
      subtotal: this.subtotal ? `${formatCurrency(this.subtotal)} ${getCurrencySymbol(this.currencyCode)}` : "",
      quantity: `${this.quantity} ${this.quantityUnit}`,
      productId: this.productId,
      partNumber: this.partNumber,
      description: this.description,
      referenceType: this.referenceType,
      availability: capitalize(this.availability),
    };
  }

  private formatAvailability(part: ILineItem, quantityRequested: number) {
    const { leadText = "", quantityInStock = 0 } = part;

    if (quantityInStock <= 0 || quantityInStock < quantityRequested) {
      return leadText;
    }

    return translateString("spareParts.inStock");
  }
}

export { ExportPartModel };
