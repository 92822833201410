import { APIFetch, IAPICallParams } from "src/APIFetch";
import { APIResourceState, invalidateAPIResourceCaches } from "./api";
import {
  IAccount,
  IAccountUser,
  IAddUserRequest,
  IAttachment,
  IContactContractRequest,
  IContactRequest,
  IDownloadLink,
  IEquipment,
  IInstallation,
  INewInstallationRequest,
  INotificationSubscription,
  IPart,
  IPartsBlockedStatus,
  IPurchase,
  IRemoveInstallationRequest,
  IRemoveUserRequest,
  IServicesResponse,
  ITrainingContactRequest,
} from "online-services-types";
import { IUserState, setUserInfo } from "src/redux/user";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "src/util/error";
import { setPartsBlockingStatus, setServices } from "./applicationContext";

import { AttachmentSource } from "src/models/attachments";
import { Dispatch } from "redux";
import { IAppState } from "src/redux";
import { clearLocallyStoredSettings } from "src/util/localstorage";
import { fetchNotifications } from "src/models/notifications";
import moment from "moment";
import { translateString } from "src/util/localization";

export const setInstallationVisibility = (installations: IInstallation[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      await new APIFetch<IInstallation>("service/installations").patch(installations);
      dispatch(APIResourceState.installations.patchAction(installations));
      await fetchNotifications()(dispatch, getState);
    } catch (error) {
      displayError(
        `${translateString("request.errorWhileUpdatingInstallationVisibility")}: ${error}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
  };
};

interface IStartFileTransferOptions {
  disableToast?: boolean;
  fileId: string;
  fileIds?: string;
  articleName?: string;
  articleId?: string;
  requestType?: string;
  requestId?: string;
  equipmentId?: string;
  cmReportId?: string;
  courseId?: string;
  energyDocumentId?: string;
  searchText?: string;
  openSearch?: string;
  delimiter?: string;
  from?: string;
  to?: string;
  exportAll?: string;
}

export const startFileTransfer = async (options: IStartFileTransferOptions) => {
  try {
    const params: IAPICallParams = {};

    if (options.articleId) {
      params.articleId = options.articleId;
    }

    if (options.articleName) {
      params.articleName = options.articleName;
    }

    if (options.requestId) {
      params.requestId = options.requestId;
    }

    if (options.requestType) {
      params.requestType = options.requestType;
    }

    if (options.equipmentId) {
      params.equipmentId = options.equipmentId;
    }
    if (options.cmReportId) {
      params.cmReportId = options.cmReportId;
    }
    if (options.courseId) {
      params.courseId = options.courseId;
    }
    if (options.searchText) {
      params.searchText = options.searchText;
    }
    if (options.energyDocumentId) {
      params.energyDocumentId = options.energyDocumentId;
    }
    if (options.openSearch) {
      params.openSearch = options.openSearch;
    }
    if (options.delimiter) {
      params.delimiter = options.delimiter;
    }
    if (options.from) {
      params.from = options.from;
    }
    if (options.to) {
      params.to = options.to;
    }
    if (options.exportAll) {
      params.exportAll = options.exportAll;
    }

    let result: IDownloadLink | null = null;

    if (options.fileIds) {
      result = await new APIFetch<{ fileIds: string }, IDownloadLink>("files/files").postWithParams(
        { fileIds: options.fileIds },
        params,
        options.fileId
      );
    } else {
      result = await new APIFetch<IDownloadLink>("files/files").get(options.fileId, params);
    }

    if (result?.status === "error") {
      if (!options.disableToast) {
        displayError(translateString("request.couldntStartFileDownload"));
      }
    }
    return result;
  } catch (error) {
    if (!options.disableToast) {
      displayError(
        `${translateString("request.couldntStartFileDownload")}: ${error}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
    return null;
  }
};

export const getFileTransferStatus = async (transferId: string, options?: { disableToast?: boolean }) => {
  try {
    const result = await new APIFetch<IDownloadLink>("files/files", "status").get(transferId);
    if (result.status === "error") {
      if (!options?.disableToast) {
        displayError(translateString("request.couldntDownloadFile"));
      }
    }
    return result.status;
  } catch (error) {
    if (!options?.disableToast) {
      displayError(
        `${translateString("request.couldntDownloadFile")}: ${error}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
    return "error";
  }
};

export const sendNewInstallationRequest = async (newInstallationRequest: INewInstallationRequest) => {
  const toastId = displayProgress(translateString("request.sending"));
  try {
    await new APIFetch("service/request/new-installation").post(newInstallationRequest);
    dismissMessage(toastId);
    displaySuccess(translateString("request.installationRequestSent"));
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.errorWhileSendingInstallationRequest")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};

export const sendRemoveInstallationRequest = async (removeInstallationRequest: IRemoveInstallationRequest) => {
  const toastId = displayProgress(translateString("request.sending"));
  try {
    await new APIFetch("service/request/remove-installation").post(removeInstallationRequest);
    dismissMessage(toastId);
    displaySuccess(translateString("request.installationRequestSent"));
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.errorWhileSendingInstallationRequest")}: ${error}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};

export const sendAddUserRequest = async (newUserRequest: IAddUserRequest) => {
  const toastId = displayProgress(translateString("request.sending"));
  try {
    await new APIFetch("service/request/new-user").post(newUserRequest);
    dismissMessage(toastId);
    displaySuccess(translateString("request.sent"));

    return true;
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.sendingError")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );

    return false;
  }
};

export const sendRemoveUserRequest = async (removeUserRequest: IRemoveUserRequest, isSelfDeactivation?: boolean) => {
  const toastId = displayProgress(translateString("request.sending"));
  try {
    await new APIFetch("service/request/remove-user").post(removeUserRequest);
    dismissMessage(toastId);
    if (isSelfDeactivation) {
      displaySuccess(translateString("user.deactivationRequestSent"));
    } else {
      displaySuccess(translateString("request.sent"));
    }

    return true;
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.sendingError")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );

    return false;
  }
};

export const sendContactRequest = async (contactRequest: IContactRequest) => {
  const toastId = displayProgress(translateString("request.sendingContactRequest"));
  try {
    await new APIFetch("service/contactus").post(contactRequest);
    dismissMessage(toastId);
    displaySuccess(translateString("request.contactRequestSent"));
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.errorWhileSendingContactRequest")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};

export const sendTrainingContactRequest = async (contactRequest: ITrainingContactRequest): Promise<boolean> => {
  const toastId = displayProgress(translateString("request.sendingContactRequest"));
  try {
    await new APIFetch("service/contacttraining").post(contactRequest);
    dismissMessage(toastId);
    displaySuccess(translateString("request.contactRequestSent"));
    return true;
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.errorWhileSendingContactRequest")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
    return false;
  }
};

export const getAttachments = async (attachmentSource: AttachmentSource, id: string): Promise<IAttachment[]> => {
  const handlers = {
    [AttachmentSource.TechRequest]: new APIFetch<IAttachment[]>("requests/techrequests", "attachments"),
    [AttachmentSource.WarrantyClaim]: new APIFetch<IAttachment[]>("requests/warrantyclaims", "attachments"),
    [AttachmentSource.CustomerSupportTicket]: new APIFetch<IAttachment[]>(
      "requests/customer-support-tickets",
      "attachments"
    ),
    [AttachmentSource.SparePartClaim]: new APIFetch<IAttachment[]>("requests/sparepartclaims", "attachments"),
    [AttachmentSource.OperationalSupportRequest]: new APIFetch<IAttachment[]>("requests/operationalsupportrequests", "attachments"),
    [AttachmentSource.ConditionMonitoringReports]: new APIFetch<IAttachment[]>("contracts/cmreports", "attachments"),
    [AttachmentSource.ServiceWorkReport]: new APIFetch<IAttachment[]>("documents/documents", "attachments"),
    [AttachmentSource.Commerce]: new APIFetch<IAttachment[]>(`spareparts/record-attachments`),
    [AttachmentSource.Draft]: new APIFetch<IAttachment[]>(`spareparts/basket-attachments`),
    [AttachmentSource.Shipment]: new APIFetch<IAttachment[]>("service/shipments", "attachments"),
    [AttachmentSource.VATStatement]: new APIFetch<IAttachment[]>("spareparts/vat-statements", "attachments"),
    [AttachmentSource.SFOC]: new APIFetch<IAttachment[]>("service/sfoc-report", "attachments"),
  };

  if (!handlers[attachmentSource]) {
    throw new Error(translateString("request.unknownSource"));
  }

  try {
    return await handlers[attachmentSource].get(id);
  } catch (e) {
    displayError(
      `${translateString("request.errorWhileFetchingAttachments")}: ${e.message}`,
      undefined,
      undefined,
      e.requestId
    );
  }
  return [];
};

export const getSpareParts = async (equipmentId: string) => {
  try {
    return await new APIFetch<IPart[]>("spareparts/parts").get(undefined, { equipment_id: equipmentId });
  } catch (e) {
    displayError(
      `${translateString("request.erroWhileFetchingSpareParts")}: ${(e as Error).message}`,
      undefined,
      undefined,
      e.awsRequestId
    );
  }
  return [];
};

export const updateEquipment = (
  equipmentId: string,
  equipment: IEquipment,
  equipmentProperty: string,
  propertyValue: string,
  updateRunningHoursDate = false,
  newRunningHourDate?: string
) => {
  return async (dispatch: Dispatch) => {
    const toastId = displayProgress(translateString("request.updating"));
    try {
      const newEquipment = {
        ...equipment,
        [equipmentProperty]: propertyValue,
      };

      let data = {};

      if (updateRunningHoursDate && newRunningHourDate !== undefined) {
        newEquipment.runningHoursDate = newRunningHourDate;
      } else if (updateRunningHoursDate && newRunningHourDate === undefined) {
        newEquipment.runningHoursDate = new Date().toISOString();
        newRunningHourDate = new Date().toString();
      }

      if (updateRunningHoursDate) {
        data = {
          [equipmentProperty]: propertyValue,
          runningHoursDate: newRunningHourDate,
        };
      } else {
        data = {
          [equipmentProperty]: propertyValue,
        };
      }

      await new APIFetch(`service/equipment/${equipmentId}`).patch(data);

      dispatch(APIResourceState.equipments.patchAction([newEquipment]));

      dismissMessage(toastId);
      displaySuccess(translateString("request.successfullyUpdated"));
    } catch (error) {
      dismissMessage(toastId);
      displayError(
        `${translateString("request.errorWhileUpdating")}: ${(error as Error).message}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
  };
};

export const makeRunningHoursUpdateRequest = async (
  equipments: Array<{ id: string; runningHours: string; runningHoursDate?: string }>,
  installationId: string,
  requestReason: string,
  userInfo: IUserState
) => {
  const toastId = displayProgress(translateString("request.sendingRunningHoursRequest"));
  try {
    await new APIFetch(`service/request/update-equipment-running-hours`).post({
      equipment: equipments,
      description: `
        Installation Id: ${installationId}\n
        Date: ${moment().format("DD.MM.YYYY")}\n
        User: ${userInfo.name} (id: ${userInfo.userId})\n
        Description: ${requestReason}
      `,
    });

    dismissMessage(toastId);
    displaySuccess(translateString("request.runningHoursRequestSent"));
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.errorWhileSendingRunningHoursRequest")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};

export const sendMessageToContractManager = async (contactRequest: IContactContractRequest) => {
  const toastId = displayProgress(translateString("request.sendingMessageToContractManager"));
  try {
    await new APIFetch("contracts/contactcontract").post(contactRequest);
    dismissMessage(toastId);
    displaySuccess(translateString("request.messageSent"));
  } catch (error) {
    dismissMessage(toastId);
    displayError(
      `${translateString("request.errorWhileSendingMessage")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};

export const updateServices = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const response = await new APIFetch<IServicesResponse>("service/services").get();
    dispatch(setServices(response.services.map((service) => service.name)));
  };
};

export const updatePartsBlockingData = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const response = await new APIFetch<IPartsBlockedStatus>("spareparts/blocking-data").get();
    dispatch(setPartsBlockingStatus(response.blocked));
  };
};

export const selectCurrentAccount = (selectedAccountId: string) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const toastId = displayProgress(translateString("account.selectingAccount"));
    try {
      const state = getState();
      const allAccounts = state.accounts.data || (await new APIFetch<IAccount[]>(`service/accounts`).get());
      const updatedAccounts = allAccounts.map((account: IAccount) => ({
        ...account,
        selected: account.id === selectedAccountId,
      }));
      dispatch(APIResourceState.accounts.patchAction(updatedAccounts));
      await new APIFetch(`service/accounts/${selectedAccountId}`).patch({ selected: true });

      updateServices()(dispatch, getState);
      updatePartsBlockingData()(dispatch, getState);
      invalidateAPIResourceCaches()(dispatch);
      fetchNotifications()(dispatch, getState);
      APIResourceState.installations.get()(dispatch, getState);
      APIResourceState.fluidReportEvaluations.get()(dispatch, getState);
      APIResourceState.equipments.get()(dispatch, getState);
      APIResourceState.uptimeEquipmentCharts.get()(dispatch, getState);
      dismissMessage(toastId);
      clearLocallyStoredSettings();
      displaySuccess(translateString("account.accountSelected"));
    } catch (error) {
      dismissMessage(toastId);
      displayError(
        `${translateString("request.errorWhileSelectingAccount")}: ${(error as Error).message}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
  };
};

// used to update user and get anew all the requests
export const resetCurrentUser = (updatedUser: IAccountUser, updatedUserInfo: IUserState) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const toastId = displayProgress(translateString("profile.updating"), undefined, { autoClose: false });
    try {
      const updatedUserResponse = await new APIFetch<IAccountUser>("service/user").patch(updatedUser);
      const isQPDistributor = updatedUser.isQPDistributor && !updatedUser.isQPDistributorViewDisabled;
      if (!isQPDistributor) {
        APIResourceState.fluidReportEvaluations.get()(dispatch, getState);
        APIResourceState.equipments.get()(dispatch, getState);
      }
      updateServices()(dispatch, getState);
      updatePartsBlockingData()(dispatch, getState);
      invalidateAPIResourceCaches()(dispatch);
      fetchNotifications()(dispatch, getState);
      APIResourceState.installations.get()(dispatch, getState);
      APIResourceState.uptimeEquipmentCharts.get()(dispatch, getState);
      // we need to manually set visible installations from the response of user update request
      await setUserInfo({
        ...updatedUserInfo,
        visibleInstallations: updatedUserResponse?.[0].visibleInstallations || updatedUserInfo.visibleInstallations,
      })(dispatch);

      dismissMessage(toastId);
      clearLocallyStoredSettings();
      displaySuccess(translateString("profile.profileSelected"));
    } catch (error) {
      dismissMessage(toastId);
      displayError(
        `${translateString("profile.errorWhileSelectingProfile")}: ${(error as Error).message}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
  };
};

export const getAccounts = async () => {
  try {
    return await new APIFetch<IAccount[]>("service/accounts").get();
  } catch (error) {
    displayError(`${translateString("request.errorWhileFetchingAccounts")}: ${(error as Error).message}`);
    return [];
  }
};

export const setNotificationsAsRead = async (notificationIds: string[]) => {
  try {
    await new APIFetch("service/notifications").patch({ ids: notificationIds });
  } catch (error) {
    displayError(
      `${translateString("request.errorWhileSettingNotificationRead")}: ${(error as Error).message}`,
      undefined,
      undefined,
      error.awsRequestId
    );
  }
};

export const updatePurchaseNotificationSubscription = (
  purchase: IPurchase,
  subscription: INotificationSubscription | null
) => {
  return async (dispatch: Dispatch) => {
    dispatch(APIResourceState.purchases.patchAction([{ ...purchase, subscription: subscription || undefined }]));
  };
};
