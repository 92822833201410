import { ThemedProps } from "src/design-system/Theme/theme";

import { breakpoints } from "src/design-system/Tokens/breakpoints";
import styled, { css } from "styled-components";
import colors from "./colors";
import { spacer } from "./tokens";

/*
 * Typography component provides styles for basic typography elements
 * such as headers and paragraphs.
 */

export const fontSizes = {
  xl: { min: 24, max: 30 },
  l: { min: 20, max: 24 },
  m: { min: 18, max: 22 },
  s: { min: 16, max: 18 },
  xs: { min: 12, max: 16 },
};

export const fixedFontSizes = {
  baseFontSizeXLarge: 24,
  baseFontSizeLarge: 20,
  baseFontSizeMedium: 16,
  baseFontSize: 14,
  baseFontSizeSmall: 12,
};

export const fontBase = `'Roboto', Arial, Helvetica, sans-serif`;

export const baseTextStyles = css`
  line-height: 1.3em;
  color: ${(props: ThemedProps) => props.theme.text};
  font-style: normal;
  margin: 0 0 ${spacer(1)};
`;

export const pStyles = css`
  ${baseTextStyles}
  font-size: ${fixedFontSizes.baseFontSize}px;
  font-weight: 400;
`;

export const H1 = styled.h1`
  ${fluidTypography(fontSizes.xl.min, fontSizes.xl.max)};
  ${baseTextStyles}
  text-transform: capitalize;
  font-weight: 300;
`;

export const H2Styles = css`
  ${fluidTypography(fontSizes.l.min, fontSizes.l.max)};
  ${baseTextStyles};
  font-weight: 400;
`;

export const H2 = styled.h2`
  ${H2Styles};
`;

export const H3 = styled.h3`
  ${fluidTypography(fontSizes.m.min, fontSizes.m.max)};
  line-height: 1.3em;
  color: ${(props: ThemedProps<IH4Props>) => props.theme.text};
  text-transform: uppercase;
  font-weight: 400;
  margin: 0.3em 0;
`;

interface IH4Props {
  noMargin?: boolean;
  $uppercase?: boolean;
}

export const H4 = styled.h4<IH4Props>`
  ${fluidTypography(fontSizes.s.min, fontSizes.s.max)};
  line-height: 1.3em;
  color: ${(props: ThemedProps<IH4Props>) => props.theme.text};
  font-weight: 400;
  margin: ${(props: ThemedProps<IH4Props>) => (props.noMargin ? "0" : "0.3em 0")};
  ${(props: ThemedProps<IH4Props>) => (props.$uppercase ? "text-transform: uppercase;" : "")};
`;

export const P = styled.p`
  ${pStyles};
`;

export const Label = styled.label`
  ${(props: ThemedProps) => `
    color: ${props.theme.input.foreground2};
  `}

  font-size: ${fixedFontSizes.baseFontSize}px;
  display: block;
  margin-bottom: ${spacer(1)};
`.withComponent("label");

export enum IDisclaimerTheme {
  Dark = 1,
  Light = 2,
  Quantiparts = 3,
}

interface IDisclaimer {
  forcedTheme?: IDisclaimerTheme;
  color?: string;
}

const getDisclaimerColor = (props: ThemedProps<IDisclaimer>) => {
  if (props.forcedTheme) {
    return props.forcedTheme === IDisclaimerTheme.Dark ? colors.secondary.gray40 : colors.primary.white;
  } else {
    if (props.color) {
      return props.color;
    }
    return props.theme.primary1;
  }
};

export const smallTextStyles = css`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
`;

export const Disclaimer = styled.span<IDisclaimer>`
  ${smallTextStyles};
  line-height: 1.6em;
  color: ${(props: ThemedProps<IDisclaimer>) => getDisclaimerColor(props)};
  font-weight: 400;
`;

export const Details = styled.p`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  margin: 0;
`;

/**
 * Calculates fluid font size based on given parameters between mobile and tablet, after tabled defined maximum font is used
 * @param minFontSize  minimun font size for mobile breakpoint
 * @param maxFontSize  maximum font size for tablet breakpoint
 * @returns       CSS that corresponds to this typography
 */
export function fluidTypography(minFontSize: number, maxFontSize: number): string {
  return `
    //Dynamic calculation based on viewport width between mobile and tablet
    font-size: ${minFontSize}px;
    @media (min-width: ${breakpoints.mobile}px) {
        font-size: calc(${minFontSize}px + (${maxFontSize} - ${minFontSize}) * ((100vw - ${breakpoints.mobile}px) / (${breakpoints.tablet} - ${breakpoints.mobile})));
    }
    @media (min-width: ${breakpoints.tablet}px) {
        font-size: ${maxFontSize}px;
    }
  `;
}

// Simulates empty row with content with ::before non-breaking space
export const KeepHeightStyles = css`
  ::before {
    content: "\feff";
  }
`;

export const KeepHeightText = styled.div`
  min-height: 1em;
`;
