import * as React from "react";

import { LoadingSpinner } from "src/components/LoadingSpinner";

export class LoadingView extends React.Component {
  public render() {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }
}
