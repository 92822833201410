import { IPurchase } from "online-services-types";
import mockImage from "src/images/onboarding/SparePartsCatalogueMockImage.png";
import { IPartsCatalogSectionViewModel } from "src/models/catalogueSections";
import { IBasketState } from "src/redux/basket";

export const getSparePartOnboardingMockPurchases = (): IPurchase[] => {
  const items = [];
  for (let i = 1; i <= 5; i++) {
    items.push({
      id: `${i}`,
      status: i > 4 ? "Confirmed" : "Quoted",
      type: i > 4 ? "Order" : "Quotation",
      wartsilaReference: "",
      yourReference: `EXAMPLE-${i > 4 ? "ORDER" : "QUOTATION"}-123-${i}`,
      installationName: "EXAMPLE",
      lastModifiedDate: new Date().toString(),
      lineItems: [
        {
          lineNumber: `${i}00`,
          description: "Example Item 1",
          sparePartNumber: `${i}001`,
          weight: "0.02",
          weightUnit: "KG",
          quantity: `${i + 1}`,
          unitOfMeasure: "PC",
          totalPrice: "10",
          currency: "EUR",
          parentRow: "000000",
          equipmentId: "12345",
        },
        {
          lineNumber: `${i}01`,
          description: "Example Item 2",
          sparePartNumber: `${i}002`,
          weight: "0.3",
          weightUnit: "KG",
          quantity: `${i + 2}`,
          unitOfMeasure: "PC",
          totalPrice: "5",
          currency: "EUR",
          parentRow: "000000",
          equipmentId: "12345",
        },
        {
          lineNumber: `${i}02`,
          description: "Example Item 3",
          sparePartNumber: `${i}003`,
          weight: "5",
          weightUnit: "KG",
          quantity: `${i + 3}`,
          unitOfMeasure: "PC",
          totalPrice: "15",
          currency: "EUR",
          parentRow: "000000",
          equipmentId: "54321",
        },
      ],
      goodsTotal: `${i * 30}`,
      freeComment: undefined,
      createdBy: "Me",
      assosiatedOrder: false,
      orderSAPExternalId: "1234",
    });
  }

  return items as {} as IPurchase[];
};

export const getSparePartOnboardingMockCatalogue = (): IPartsCatalogSectionViewModel[] => {
  const items: IPartsCatalogSectionViewModel[] = [];
  for (let i = 1; i <= 5; i++) {
    items.push({
      id: `999-00${i}`,
      parts: [
        {
          id: `${i}01`,
          productId: "prodId",
          equipmentId: "equipId",
          cylinder: "Example Location",
          materialId: "matId",
          materialText: "matText",
          sectionId: "secId",
          qtySection: 5,
          spnDescription: "This is an example part",
          weightUnit: "KG",
          netWeight: 3,
          referenceEngine: "refEngine",
          unit: "unit",
          netPrice: 10,
          leadText: "In stock",
          currency: "EUR",
          frontendKey: "123",
        },
        {
          id: `${i}02`,
          productId: "prodId",
          equipmentId: "equipId",
          cylinder: "Example Location",
          materialId: "matId",
          materialText: "matText",
          sectionId: "secId",
          qtySection: 7,
          spnDescription: "This is an example part",
          weightUnit: "KG",
          netWeight: 0.6,
          referenceEngine: "refEngine",
          unit: "unit",
          netPrice: 5,
          leadText: "1 day",
          currency: "EUR",
          frontendKey: "456",
        },
        {
          id: `${i}03`,
          productId: "prodId",
          equipmentId: "equipId",
          cylinder: "Example Location",
          materialId: "matId",
          materialText: "matText",
          sectionId: "secId",
          qtySection: 3,
          spnDescription: "This is an example part",
          weightUnit: "KG",
          netWeight: 1.3,
          referenceEngine: "refEngine",
          unit: "unit",
          netPrice: 15,
          leadText: "In stock",
          currency: "EUR",
          frontendKey: "789",
        },
      ],
      images: [
        {
          id: "IMAGE-1",
          url: mockImage,
          productReferenceType: "",
          sectionId: "",
          type: "",
          name: "",
          language: "",
          imageData: "",
        },
      ],
      equipmentId: "12345",
      upperLevelSectionId: i > 4 ? "999 Example Section 1" : "999 Example Section 2",
      description: `Example Catalog`,
    });
  }

  return items;
};

export const getSparePartOnboardingMockBasket = (): IBasketState => {
  return {
    items: [
      {
        id: `101`,
        productId: "prodId",
        equipmentId: "12345",
        cylinder: "Example Location",
        materialId: "matId",
        materialText: "matText",
        sectionId: "secId",
        qtySection: 5,
        spnDescription: "This is an example part",
        weightUnit: "KG",
        netWeight: 3,
        referenceEngine: "refEngine",
        unit: "unit",
        netPrice: 10,
        leadText: "In stock",
        currency: "EUR",
        lineNumber: 101,
        quantity: 2,
        frontendKey: "123",
        otherInformation: "",
        attachmentIds: [],
      },
      {
        id: `103`,
        productId: "prodId",
        equipmentId: "54321",
        cylinder: "Example Location",
        materialId: "matId",
        materialText: "matText",
        sectionId: "secId",
        qtySection: 3,
        spnDescription: "This is an example part",
        weightUnit: "KG",
        netWeight: 1.3,
        referenceEngine: "refEngine",
        unit: "unit",
        netPrice: 15,
        leadText: "In stock",
        currency: "EUR",
        lineNumber: 103,
        quantity: 2,
        frontendKey: "456",
        otherInformation: "",
        attachmentIds: [],
      },
    ],
    totalItemsNum: 4,
    lineItemsNum: 2,
    totalPrice: 20,
    quotationReference: {
      yourReference: "EXAMPLE-BASKET-123-1",
      wartsilaReference: "",
    },
    basketDraft: {
      id: null,
      name: null,
      oldDraft: [],
      relatedSPBasket: null,
    },
  };
};
