import { IPartAvailability, IMultiplePartAvailability, IPartsAvailabilityQueryParams } from "online-services-types";
import { Dispatch } from "redux";
import { IAppState } from "src/redux";
import { getMultiplePartsAvailability } from "src/util/commerce";

const PART_AVAILABILITY_FETCH_STARTED = "PART_AVAILABILITY_FETCH_STARTED";
const PART_AVAILABILITY_FETCH_SUCCESS = "PART_AVAILABILITY_FETCH_SUCCESS";
const PART_AVAILABILITY_FETCH_FAILURE = "PART_AVAILABILITY_FETCH_FAILURE";
const PART_AVAILABILITY_FETCH_MULTIPLE_SUCCESS = "PART_AVAILABILITY_FETCH_MULTIPLE_SUCCESS";

interface IAction {
  type: string;
  payload: {
    partId?: string;
    result?: IMultiplePartAvailability[] | IPartAvailability[];
  };
}

export interface IExtendedPartAvailability extends IPartAvailability {
  id: string;
}

const partAvailabilityFetchFailure = (): IAction => ({
  type: PART_AVAILABILITY_FETCH_FAILURE,
  payload: {},
});

interface IPartAvailabilitySuccessAction extends IAction {
  payload: {
    partId: string;
    result: IPartAvailability[];
  };
}

interface IMultiplePartAvailabilitySuccessAction extends IAction {
  payload: {
    result: IMultiplePartAvailability[];
  };
}

const partAvailabilityFetchMultipleSuccess = (
  result: IMultiplePartAvailability[]
): IMultiplePartAvailabilitySuccessAction => ({
  type: PART_AVAILABILITY_FETCH_MULTIPLE_SUCCESS,
  payload: {
    result,
  },
});

export function fetchMultiplePartAvailability(
  equipmentId: string,
  parts: IPartsAvailabilityQueryParams[],
  codeResolution?: boolean
) {
  return async (dispatch: Dispatch<IAction>, getState: () => IAppState) => {
    try {
      dispatch({ type: PART_AVAILABILITY_FETCH_STARTED, payload: {} });
      // Fetch in chunks to avoid Lambda timeouts (SAP takes a while to fetch each chunk)
      const availability: IMultiplePartAvailability[] = await getMultiplePartsAvailability(
        equipmentId,
        parts,
        codeResolution
      );

      dispatch(partAvailabilityFetchMultipleSuccess(availability));
    } catch (err) {
      dispatch(partAvailabilityFetchFailure());
    }
  };
}

export interface IPartAvailabilityState {
  availability: IExtendedPartAvailability[];
  isReady: boolean;
}

const initialState: IPartAvailabilityState = {
  availability: [],
  isReady: true,
};

const updateAvailabilityData = (
  data: IExtendedPartAvailability[],
  partId: string,
  partAvailability: IPartAvailability
) => {
  const newData = { ...partAvailability, id: partId };
  const availability = data.filter((el) => el.materialId !== partAvailability.materialId || el.id !== partId);

  return [...availability, newData];
};

export function partsAvailabilityReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case PART_AVAILABILITY_FETCH_STARTED:
      return { availability: state.availability, isReady: false };
    case PART_AVAILABILITY_FETCH_FAILURE:
      return { availability: state.availability, isReady: true };
    case PART_AVAILABILITY_FETCH_SUCCESS:
      const successAction = action as IPartAvailabilitySuccessAction;
      return {
        isReady: true,
        availability: updateAvailabilityData(
          state.availability,
          successAction.payload.partId,
          successAction.payload.result[0]
        ),
      };
    case PART_AVAILABILITY_FETCH_MULTIPLE_SUCCESS:
      const multipleSuccessAction = action as IMultiplePartAvailabilitySuccessAction;
      let availability = state.availability;

      multipleSuccessAction.payload.result.forEach((resultRow) => {
        availability = updateAvailabilityData(availability, resultRow.partId, resultRow.availability[0]);
      });
      return {
        isReady: true,
        availability,
      };
  }
  return state;
}
