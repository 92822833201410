import * as React from "react";

import { P, fixedFontSizes } from "src/design-system/Tokens/typography";
import styled, { css } from "styled-components";

import { ErrorComponent } from "../Error";
import { FieldProps } from "formik";
import { InputLabel } from "src/design-system/TextInput";
import TextareaAutosize from "react-autosize-textarea";
import { ThemedProps } from "src/design-system/Theme/theme";
import { TextArea as UITextArea } from "@wartsila/ui-kit";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { getObjectProperty } from "src/util/helpers";
import { inputTokens } from "src/design-system/Tokens/tokens";
import { translateString } from "src/util/localization";

interface IWrapper {
  withMargin?: boolean;
}

const Wrapper = styled.div`
  ${(props: IWrapper) =>
    props.withMargin
      ? `
    margin-bottom: 18px;
  `
      : ""}
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MaxCharCounter = styled.div`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  color: ${colors.secondary.gray40};
`;

const TextAreaStyles = css`
  width: ${inputTokens.inputWidth};

  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: ${inputTokens.inputTextAreaWidth};
  }
`;

const AutoSizeTextAreaStyles = css`
  ${TextAreaStyles};

  box-sizing: border-box;
  padding: 0.85em;
  border: 1px solid #333;
  border-radius: 0;
  background-color: ${colors.primary.white};
  font-family: inherit;
  line-height: 1.2;
  font-size: 0.9em;
  resize: none;
  -webkit-appearance: none;

  &:focus {
    border-color: ${colors.primary.blue};
    outline: none;
    box-shadow: 0 0 0 1px ${colors.primary.blue};
  }
`;

const DescriptionParagrapgh = styled(P)`
  ${(props: ThemedProps) => `
    color: ${props.theme.input.foreground2};
  `}
`;

const AutoSizeTextArea = styled(TextareaAutosize)`
  ${TextAreaStyles}
  ${AutoSizeTextAreaStyles}
`;

const StyledTextArea = styled(UITextArea)`
  ${TextAreaStyles}

  height: 5em;
`;

interface ITextAreaInputProps {
  label: string;
  theme?: string;
  description?: string;
  placeholder?: string;
  maxRows?: number;
  maxlength?: number;
  rows?: number;
  autoHeight?: boolean;
  className?: string;
  withMargin?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  autoFocus?: boolean;
}

const defaultNumberOfRows = 5;

export const TextArea = ({
  label,
  placeholder,
  description,
  field,
  form,
  autoHeight = false,
  maxRows,
  maxlength,
  rows = defaultNumberOfRows,
  className,
  disabled,
  invalid,
  withMargin,
  autoFocus,
  onResize,
  ...props
}: ITextAreaInputProps & FieldProps<string> & React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const fieldTouched = getObjectProperty(form.touched, field.name);
  const fieldErrors = getObjectProperty(form.errors, field.name);

  return (
    <Wrapper withMargin={withMargin}>
      {label && <InputLabel>{label}</InputLabel>}
      {description && <DescriptionParagrapgh>{description}</DescriptionParagrapgh>}
      <InnerWrapper>
        {autoHeight ? (
          <AutoSizeTextArea
            {...props}
            {...field}
            // Specify a correct event object
            onResize={(e) => onResize?.(e as unknown as React.SyntheticEvent<HTMLTextAreaElement, Event>)}
            rows={rows}
            placeholder={placeholder}
            className={className}
            maxRows={maxRows}
            maxLength={maxlength}
            autoFocus={autoFocus}
          />
        ) : (
          <StyledTextArea
            {...field}
            rows={rows}
            placeholder={placeholder}
            maxLength={maxlength}
            disabled={disabled}
            invalid={invalid}
            autoFocus={autoFocus}
          />
        )}
      </InnerWrapper>
      {maxlength && (
        <MaxCharCounter>
          {translateString("validation.characters", { currentCharCount: field.value.length, maxCharCount: maxlength })}
        </MaxCharCounter>
      )}
      {fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
    </Wrapper>
  );
};
