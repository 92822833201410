import * as React from "react";

import { inputTokens, spacer } from "../Tokens/tokens";

import { ErrorComponent } from "../Error";
import { FieldProps } from "formik";
import { FlexContainer } from "src/design-system/Container";
import { InputField } from "@wartsila/ui-kit";
import { Label } from "src/design-system/Tokens/typography";
import { ThemedProps } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { getObjectProperty } from "src/util/helpers";
import styled from "styled-components";

/*
  TODO:

  - combine Wrappers of all "inputs" (also look at Dropdown wrapper) to extend from one styledcomponent with "withMargin" prop

  - DateInput wrapper border color -> theme it!

  - write stories for error component
 */

export interface IWrapper {
  withMargin?: boolean;
  fullWidth?: boolean;
  withInlineLabel?: boolean;
}

const Wrapper = styled.div`
  ${(props: IWrapper) =>
    props.withMargin
      ? `
    margin-bottom: ${spacer(3)};
  `
      : ""}
  ${(props: IWrapper) => (props.fullWidth ? "width: 100%;" : "")}


  @media(min-width: ${breakpoints.tablet}px) {
    ${(props: IWrapper) => (props.withInlineLabel ? "display: flex; align-items: center;" : "")}
  }
`;

interface IInputLabel {
  withInlineLabel?: boolean;
}

export const InputLabel = styled(Label)<IInputLabel>`
  ${(props: ThemedProps<IInputLabel>) => (props.withInlineLabel ? "width: 240px;" : "")}
`;

const UnitLabel = styled(InputLabel)`
  margin-top: ${spacer(1)};
  margin-left: ${spacer(1)};
  min-width: 65px;
`;

const InputInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacer(2)};
`;

interface IInputProps {
  $width?: number;
  $fullwidth?: boolean;
}

export const InputFieldWithCrossOrigin = (
  props: React.InputHTMLAttributes<HTMLInputElement> & { invalid?: boolean }
) => {
  return <InputField {...props} crossOrigin="" />;
};

export const Input = styled(InputFieldWithCrossOrigin)<IInputProps>`
  box-sizing: border-box;
  width: ${(props) => (props.$fullwidth ? "100%" : inputTokens.inputWidth)};
  ${(props) => props.$width && `width: ${spacer(props.$width)};`};
`;

export interface ITextInputProps {
  label: string;
  theme?: string;
  unit?: React.ReactNode;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  withMargin?: boolean;
  withInlineLabel?: boolean;
  width?: number;
  hideErrors?: boolean;
  disabled?: boolean;
  invalid?: boolean;
}

export const TextInput = ({
  label,
  field,
  unit,
  form,
  withMargin,
  placeholder,
  onChange,
  withInlineLabel,
  width,
  hideErrors,
  disabled,
  invalid,
  onBlur,
}: ITextInputProps & FieldProps<string>) => {
  const fieldTouched = getObjectProperty(form.touched, field.name);
  const fieldErrors = getObjectProperty(form.errors, field.name);
  if (onChange !== undefined) {
    field.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      form.handleChange(e);
      onChange(e);
    };
  }
  if (onBlur !== undefined) {
    field.onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      onBlur(e);
    };
  }

  return (
    <Wrapper withInlineLabel={withInlineLabel} withMargin={withMargin}>
      <InputLabel withInlineLabel={withInlineLabel}>{label}</InputLabel>
      <InputInnerWrapper>
        <FlexContainer $centered={true}>
          <Input placeholder={placeholder} $width={width} disabled={disabled} invalid={invalid} {...field} />
          {unit && <UnitLabel>{unit}</UnitLabel>}
        </FlexContainer>
        {fieldTouched && fieldErrors && !hideErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
      </InputInnerWrapper>
    </Wrapper>
  );
};
