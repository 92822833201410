import * as React from "react";
import { LocalizedString } from "src/components/Localization";
import styled from "styled-components";

interface IReadOnlyNotificationProps {
  mockedUser: string | null;
}

const ThinRedLine = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ReadOnlyNotification: React.FC<IReadOnlyNotificationProps> = (props: IReadOnlyNotificationProps) =>
  props.mockedUser !== null ? (
    <ThinRedLine>
      <LocalizedString id="auth.readOnlyMode" />: {props.mockedUser}
    </ThinRedLine>
  ) : null;
