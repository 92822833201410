import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const InformationIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>InformationIcon</title>
    <g transform="rotate(180, 24, 24)">
      <rect x="21" y="12" width="6" height="16" />
      <circle cx="24" cy="33" r="3" />
      <path d="M24,44A20,20,0,1,1,44,24,20,20,0,0,1,24,44ZM24,8A16,16,0,1,0,40,24,16,16,0,0,0,24,8Z" />
    </g>
  </IconBase>
);
