import * as React from "react";
import { Button } from "src/design-system/Button";

import { Input, InputLabel } from "src/design-system/TextInput";

import { Container } from "src/design-system/Container";
import colors from "src/design-system/Tokens/colors";
import { InvisibleIcon, VisibleIcon } from "src/icons";
import styled from "styled-components";
import { Dialog } from "../Dialog";
import { ErrorComponent } from "src/design-system/Error";
import { changePassword } from "src/views/ProfileView/userProfile";
import { translateString } from "src/util/localization";
import { useLocalStorage } from "usehooks-ts";

type Props = {
  opened: boolean;
  onCancel: () => void;
  onError?: () => void;
  onSuccess?: () => void;
};

export const PASSWORD_NOTIFICATION_LOCALSTORAGE = "OS-password-notification";

export const ChangePasswordDialog = (props: Props) => {
  const [password, setPassword] = React.useState({
    password: "",
    confirmation: "",
  });
  const [submitted, setSubmitted] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);
  
  const passwordsFilled = Boolean(password.password && password.confirmation);

  const passwordsDontMatch = passwordsFilled && password.password !== password.confirmation;
  const passwordDontHaveMinimumLength = passwordsFilled && password.password.length < 8;
  const isAlphaNumeric = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z!@#$&()`.+,/"-\d]{1,}$/.test(password.password);
  const hasValidCharacters = /^[A-Za-z!@#$&()`.+,/"-\d]{1,}$/.test(password.password);

  const hasErrors = passwordsDontMatch || passwordDontHaveMinimumLength || !isAlphaNumeric || !hasValidCharacters;
  const [, resetPasswordExpirationNotification] = useLocalStorage(PASSWORD_NOTIFICATION_LOCALSTORAGE, true);

  return (
    <Dialog
      submit={{
        buttonTitle: "Confirm",
        callback: async () => {
          setSubmitted(true);
          if (hasErrors) return;
          await changePassword(password.password).then(
            () => {
              if (props.onSuccess) props.onSuccess();
              resetPasswordExpirationNotification(true);
            },
            () => {
              if (props.onError) props.onError();
            }
          );
        },
        buttonDisabled: !passwordsFilled,
      }}
      onCancel={props.onCancel}
      isDialogOpen={props.opened}
      title={translateString("user.changePassword")}
    >
      <Container $padding={[1, 0]}>
        <Container $marginBottom={2}>
          <InputLabel withInlineLabel>{translateString("user.newPassword")}</InputLabel>
          <InputWrapper>
            <Input
              type={showPassword ? "text" : "password"}
              value={password.password}
              onChange={(e) => setPassword((s) => ({ ...s, password: e.target.value }))}
            />
            <Button onClick={() => setShowPassword(prev => !prev)}>
              {showPassword ? <InvisibleIcon /> : <VisibleIcon />}
            </Button>
          </InputWrapper>
        </Container>
        <Container>
          <InputLabel withInlineLabel>{translateString("user.confirmNewPassword")}</InputLabel>
          <InputWrapper>
            <Input
              type={showConfirmation ? "text" : "password"}
              value={password.confirmation}
              onChange={(e) => setPassword((s) => ({ ...s, confirmation: e.target.value }))}
            />
            <Button onClick={() => setShowConfirmation(prev => !prev)}>
              {showConfirmation ? <InvisibleIcon /> : <VisibleIcon />}
            </Button>
          </InputWrapper>
        </Container>
        <Container $marginTop={2}>
          {submitted ? (
            <>
              {passwordsDontMatch ? (
                <ErrorComponent>{translateString("user.passwordsDontMatch")}</ErrorComponent>
              ) : null}
              {password.password && !isAlphaNumeric && hasValidCharacters ? (
                <ErrorComponent>{translateString("user.passwordMustBeAlphanumeric")}</ErrorComponent>
              ) : null}
              {password.password && !hasValidCharacters ? (
                <ErrorComponent>
                  {translateString("user.passwordMayOnlyContainValidCharacters", {
                    characters: '! @ # $ & ( ) ` . + , / " -',
                  })}
                </ErrorComponent>
              ) : null}
              {passwordDontHaveMinimumLength ? (
                <ErrorComponent>{translateString("user.passwordMinimumLength", { length: 8 })}</ErrorComponent>
              ) : null}
            </>
          ) : null}
        </Container>
      </Container>
    </Dialog>
  );
};

const InputWrapper = styled.div`
  position: relative;
  
  > div {
    padding-right: 20px;
  }
  
  > button {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 0;
    width: 30px;
    background: none;
    border: none;
    
    :hover {
      background: none !important;
    }
    
    svg {
      fill: ${colors.primary.blue};
    }
  }
`;
