import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const CompassIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>compass</title>
    <path d="M6,22.15l3.33-1.38A15,15,0,0,1,20.77,9.36L22.15,6l.4-1A18.89,18.89,0,0,0,11.32,9.91L9.86,8.44,8.44,9.86l1.47,1.46A18.89,18.89,0,0,0,5.07,22.55Z" />
    <path d="M42,25.85l-3.33,1.38A15,15,0,0,1,27.23,38.64L25.85,42l-.4,1a18.89,18.89,0,0,0,11.23-4.84l1.46,1.47,1.42-1.42-1.47-1.46a18.89,18.89,0,0,0,4.84-11.23Z" />
    <path d="M20.77,38.64A15,15,0,0,1,9.36,27.23L6,25.85l-1-.4A18.89,18.89,0,0,0,9.91,36.68L8.44,38.14l1.42,1.42,1.46-1.47a18.89,18.89,0,0,0,11.23,4.84l-.4-1Z" />
    <path d="M27.23,9.36A15,15,0,0,1,38.64,20.77L42,22.15l1,.4a18.89,18.89,0,0,0-4.84-11.23l1.47-1.46L38.14,8.44,36.68,9.91A18.89,18.89,0,0,0,25.45,5.07l.4,1Z" />
    <path d="M41.2,24,29.06,18.94,24,6.8,18.94,18.94,6.8,24l12.14,5.06L24,41.2l5.06-12.14ZM24,30.8,22,26l-4.8-2L22,22l2-4.8L26,22l4.8,2L26,26Z" />
  </IconBase>
);
