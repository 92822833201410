import * as React from "react";

import { Button, ButtonStyle } from "src/design-system/Button";
import { ButtonIconPlacement, ButtonType } from "src/design-system/Button/Button";
import { IconSize, SelectIcon } from "src/icons";

import { IInstallation } from "online-services-types";
import { InstallationSelectionDialog } from "src/components/InstallationSelectionDialog";
import { translateString } from "src/util/localization";

interface IInstallationFilter {
  opened: boolean;
  toggle: () => void;
  installations: IInstallation[];
  selectedInstallationIds: string[];
  maxNumberOfInstallations?: number;
  onChange(installationIds: string[]): void;
}

export const InstallationFilter = (props: IInstallationFilter) => {
  const label =
    props.selectedInstallationIds.length === 1
      ? translateString("managerDashboard.installationsSelected")
      : translateString("managerDashboard.installationsSelected.plural", {
          numInstallations: props.selectedInstallationIds.length,
        });

  return (
    <>
      <Button
        onClick={props.toggle}
        buttonType={ButtonType.Outline}
        buttonStyle={ButtonStyle.Transparent}
        icon={<SelectIcon size={IconSize.XSmall} />}
        iconPlacement={ButtonIconPlacement.After}
      >
        {label}
      </Button>
      <InstallationSelectionDialog
        onCancel={props.toggle}
        isDialogOpen={props.opened}
        installations={props.installations}
        buttonTitle={translateString("save")}
        title={translateString("selectInstallations")}
        initialDisplayedInstallations={props.installations}
        maxNumberOfInstallations={props.maxNumberOfInstallations}
        initialSelectedInstallationIds={props.selectedInstallationIds}
        onSubmit={(ids) => {
          props.onChange(ids);
          props.toggle();
        }}
      />
    </>
  );
};
