import { CloseIcon, InformationIcon } from "src/icons";
import { Container, FlexContainer } from "src/design-system/Container";
import { H4, P, fixedFontSizes } from "src/design-system/Tokens/typography";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { getRoutes, redirectBrowser } from "src/routes";

import { AnalyticsEvent } from "src/components/AnalyticsEvent";
import { LinkWrapper } from "src/design-system/Link/Link";
import { VersionContext } from "src/views/ReleaseNotesView/VersionContext";
import { analyticsEvents } from "src/events";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import styled from "styled-components";
import { translateString } from "src/util/localization";

const NotificationBody = styled(FlexContainer)`
  width: 500px;
  box-sizing: border-box;

  color: ${colors.primary.black};
  background-color: ${colors.primary.white};
  filter: drop-shadow(0 0 5px ${colors.transparent.black});

  H4 {
    color: ${colors.primary.black};
  }

  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
    margin: 0;
    H4 {
      font-size: 12px;
    }
    p {
      font-size: 8px;
    }
  }
`;

const InfoIconWrapper = styled.div`
  align-self: center;

  svg {
    width: 40px;
    height: 40px;

    @media (max-width: ${breakpoints.tablet}px) {
      width: 30px;
      height: 30px;
    }
  }
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  align-self: center;

  svg {
    width: 15px;
    height: 15px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    svg {
      width: 10px;
      height: 10px;
    }
  }
`;

const StyledLinkWrapper = styled(LinkWrapper)`
  margin: -5px 0px 0px 0;

  @media (max-width: ${breakpoints.tablet}px) {
    margin: -5px 0px 0px 0px;
    font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  }
`;

const FixedContainer = styled(Container)`
  box-sizing: border-box;

  position: fixed;
  left: 50%;
  top: ${spacer(3)};
  transform: translateX(-50%);

  z-index: 1;

  @media (max-width: ${breakpoints.tablet}px) {
    top: 0;
    position: relative;
  }
`;

export const WOHasBeenUpdatedNotification = () => {
  const version = useContext(VersionContext);
  const [showNotification, setShowNotification] = useState<boolean>(false);

  useEffect(() => {
    setShowNotification(version.hasNewReleaseNotes);
  }, [version.hasNewReleaseNotes]);

  const markAndClose = useCallback(() => {
    version.markVersionSeen();
    setShowNotification(false);
  }, [version.markVersionSeen]);

  return (
    <FixedContainer>
      {showNotification ? (
        <NotificationBody $gap={3} $spaceBetween $padding={[2, 4]}>
          <FlexContainer $gap={3}>
            <InfoIconWrapper>
              <InformationIcon color={colors.primary.orange} />
            </InfoIconWrapper>
            <Container>
              <H4>{translateString("main.wartsilaOnlineHasBeenUpdated")}</H4>
              <AnalyticsEvent event={() => analyticsEvents.dcpReleaseNotes({ dcp_notes_click_at: "banner" })}>
                <P>
                  <StyledLinkWrapper
                    onClick={() => {
                      redirectBrowser(getRoutes().ReleaseNotes);
                      markAndClose();
                    }}
                  >
                    {translateString("main.whatIsNew")}
                  </StyledLinkWrapper>
                </P>
              </AnalyticsEvent>
            </Container>
          </FlexContainer>
          <CloseIconWrapper onClick={() => markAndClose()}>
            <CloseIcon color={colors.primary.black} />
          </CloseIconWrapper>
        </NotificationBody>
      ) : null}
    </FixedContainer>
  );
};
