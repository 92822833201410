import { IAvailabilityLimitBatchUpdateRequest, IAvailabilityLimitBatchUpdateResult } from "online-services-types";
import { Dispatch } from "redux";
import { APIFetch } from "src/APIFetch";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "src/util/error";

export const updateAvailabilityLimit = (request: IAvailabilityLimitBatchUpdateRequest) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    dispatch(updateAvailabilityLimitStartedAction());
    try {
      const toastId = displayProgress("Updating availability limits...");
      const response = await new APIFetch<IAvailabilityLimitBatchUpdateRequest, IAvailabilityLimitBatchUpdateResult>(
        "service/update-availability-limit"
      ).post(request);
      dismissMessage(toastId);

      if (response !== null) {
        dispatch(
          APIResourceState.equipments.patchAction(
            response.updateStatus
              .filter((status) => status.success && status.customerSetAvailabilityLimit !== undefined)
              .map((status) => ({
                id: status.equipmentId,
                customerSetAvailabilityLimit: status.customerSetAvailabilityLimit,
              }))
          )
        );
      }

      if (response !== null && !response.success) {
        displayError("An error occurred. Availability limits might have been partially updated.");
        dispatch(updateAvailabilityLimitFailureAction(response));
      } else {
        dispatch(updateAvailabilityLimitSuccessAction());
        // Reload the uptime equipment charts to refresh the traffic lights
        APIResourceState.uptimeEquipmentCharts.invalidateCache()(dispatch, getState);
        APIResourceState.uptimeEquipmentCharts.get()(dispatch, getState);
        displaySuccess("Availability limits updated!");
      }
    } catch {
      dispatch(updateAvailabilityLimitFailureAction());
    }
  };
};

const UPDATE_AVAILABILITY_LIMIT_STARTED = "UPDATE_AVAILABILITY_LIMIT_STARTED";
const UPDATE_AVAILABILITY_LIMIT_SUCCESS = "UPDATE_AVAILABILITY_LIMIT_SUCCESS";
const UPDATE_AVAILABILITY_LIMIT_FAILURE = "UPDATE_AVAILABILITY_LIMIT_FAILURE";

interface IAction {
  type: string;
  payload?: {};
}

const updateAvailabilityLimitStartedAction = (): IAction => ({
  type: UPDATE_AVAILABILITY_LIMIT_STARTED,
});

const updateAvailabilityLimitSuccessAction = (): IAction => ({
  type: UPDATE_AVAILABILITY_LIMIT_SUCCESS,
});
const updateAvailabilityLimitFailureAction = (result?: IAvailabilityLimitBatchUpdateResult): IAction => ({
  type: UPDATE_AVAILABILITY_LIMIT_FAILURE,
  payload: result,
});

export interface IUpdateAvailabilityLimitState {
  updateInProgress: boolean;
  failureStatus: IAvailabilityLimitBatchUpdateResult | null;
}

const initialState: IUpdateAvailabilityLimitState = {
  updateInProgress: false,
  failureStatus: null,
};

export function availabilityLimitReducer(state = initialState, action: IAction) {
  switch (action.type) {
    case UPDATE_AVAILABILITY_LIMIT_STARTED:
      return {
        ...state,
        updateInProgress: true,
      };
    case UPDATE_AVAILABILITY_LIMIT_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
      };
    case UPDATE_AVAILABILITY_LIMIT_FAILURE:
      return {
        ...state,
        updateInProgress: false,
        failureStatus: action.payload,
      };
  }
  return state;
}
