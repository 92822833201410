import { IClientError } from "online-services-types";
import { APIFetch } from "src/APIFetch";

// Handle events caught by window's error eventListener
export const handleClientErrorEvent = (errorEvent: ErrorEvent) => {
  const { message, error, filename } = errorEvent;

  handleClientError(error, message, "event-listener", filename);
};

// Handle errors caught by componentDidCatch
export const handleClientError = async (
  errorObject: Error | React.ErrorInfo,
  message: string,
  errorId: string,
  filename: string = ""
) => {
  const formattedError = JSON.parse(JSON.stringify(errorObject, (key: string, value: any) => formatClientError(value)));

  // Ignore errors that will be processed by componentDidCatch.
  // SEE: https://github.com/facebook/react/issues/10474
  if (formattedError && formattedError.stack && formattedError.stack.indexOf("invokeGuardedCallbackDev") >= 0) {
    return;
  }

  const clientErrorObject: IClientError = {
    errorId,
    message: message || formattedError.message,
    href: window.location.href,
    stackTrace: formattedError ? formattedError.componentStack || formattedError.stack || "" : "",
    browser: window.navigator.userAgent,
    filename,
  };

  await new APIFetch("monitoring/record-client-error").post(clientErrorObject);
};

export const formatClientError = (value: Error) => {
  if (value instanceof Error) {
    const error = {};
    Object.getOwnPropertyNames(value).forEach((valueKey: string) => {
      error[valueKey] = value[valueKey];
    });
    return error;
  }
  return value;
};
