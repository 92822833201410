// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_ja: ITranslations = {
  ja: {
    "request.addContact": "連絡先を追加する",
    "request.addNewContact": "新しい連絡先を追加する",
    "request.addNewUser": "ユーザーの追加",
    "request.addressLine1": "住所 1",
    "request.addressLine2": "住所 2",
    "request.attachment.Box": "箱",
    "request.attachment.Box or packing material": "箱または梱包材",
    "request.attachment.Claimed part": "請求された部品",
    "request.attachment.Content of the box(es) and damaged parts": "箱の内容および破損した部品",
    "request.attachment.Corroded part": "腐食箇所",
    "request.attachment.Damage on part": "部品の損傷",
    "request.attachment.Entire part": "部品全体",
    "request.attachment.Markings and / or labels on the part": "部品のマーキングまたはラベル",
    "request.attachment.Markings on the claimed part": "請求された部品のマーキング",
    "request.attachment.Markings on the part": "部品のマーキング",
    "request.attachment.Markings on the received part": "受領した部品のマーキング",
    "request.attachment.Markings on the required / correct part": "要求された、または正しい部品のマーキング",
    "request.attachment.Other": "その他",
    "request.attachment.Packing material": "梱包材",
    "request.attachment.Packing, incl. labels": "梱包 (ラベルを含む)",
    "request.attachment.Pictures of leaking parts": "漏れ箇所の写真",
    "request.attachment.Received part": "受領部品",
    "request.attachment.Required / correct part": "要求された、または正しい部品",
    "request.attachment.Required part": "要求部品",
    "request.attachment.Test reports": "試験レポート",
    "request.attachmentDescription": "全体写真とクローズアップ写真を提供してください。",
    "request.attachmentDialog.Confirm": "削除する",
    "request.attachmentDialog.Text": "本当に添付ファイルを削除してもよいですか?",
    "request.attachmentDialog.Title": "添付ファイルを削除する",
    "request.attachments": "添付書類",
    "request.auxAttachmentDescription": "全体写真、クローズアップ写真、および製品登録プレートの写真を提供してください。",
    "request.backgroundInformation": "バックグラウンド情報",
    "request.beforeClaiming": "不足している部品を請求する前に、必ず以下のチェックを行ってください。",
    "request.beforeClaiming.checkBottom": "3. 木枠の底部には木製シートが設置されている場合があり、これが床または底部に見えるため、部品がこのシートの下に隠れている場合があります。",
    "request.beforeClaiming.checkPackage": "1. 配送物が入っていた小包、箱、木枠などを再点検します。",
    "request.beforeClaiming.checkPaddings": "2. 詰め物および梱包材を確認します。",
    "request.chooseReferenceRequest": "参照リクエストを選択する",
    "request.city": "市",
    "request.claimAllItems": "同じ理由または問題ですべての配送ラインを請求する",
    "request.claimBasketText": "{basketItems}ライン品目",
    "request.claimDescription": "簡単な請求説明",
    "request.claimItem": "品目を請求",
    "request.claimOrder": "注文を請求",
    "request.claimQuantity": "請求された数量",
    "request.claimReason": "請求理由",
    "request.claimReasonChangeDislaimer": "請求理由を変更すると、品目固有の回答が失われることに注意してください。",
    "request.claimReasons": "請求理由",
    "request.claimReference": "参照請求",
    "request.claimedItems": "請求された品目",
    "request.claimedOrder": "請求のための注文",
    "request.comment": "コメント",
    "request.companyName": "会社名",
    "request.contactInformation": "連絡先情報",
    "request.contactInformationDescription": "お客様はこのリクエストの主な連絡先ですが、その他関係者を追加してこのリクエストに関連する変更を通知することができます。",
    "request.couldntFindDelivery": "配送が見つかりませんでした!",
    "request.country": "国",
    "request.createdBy": "作成者",
    "request.creationDate": "作成日",
    "request.customerClaimReference": "カスタマー請求参照",
    "request.customerClaimReferenceOptional": "カスタマー請求参照 (オプション)",
    "request.customerInformation": "カスタマー情報",
    "request.daAddressLine1IsRequired": "住所が必要です!",
    "request.daCityIsRequired": "市が必要です!",
    "request.daCompanyNameIsRequired": "会社名が必要です!",
    "request.daCountryIsRequired": "国が必要です!",
    "request.daPostalCodeIsRequired": "郵便番号が必要です!",
    "request.dateOfOccurrence": "発生日",
    "request.deliveryAddress": "配送先住所",
    "request.deliveryExpected": "配送予定",
    "request.deliveryNumber": "配送番号",
    "request.deliveryReceived": "配送受領",
    "request.describeLeakageNoticed": "漏れにいつ、どのように気づきましたか",
    "request.description": "部品名称",
    "request.descriptionIsRequired": "説明が必要です!",
    "request.descriptionLong": "登録時に受け取る情報が詳細であるほど、リクエストを迅速に処理できます。",
    "request.deselect": "削除する",
    "request.detailedDescription": "詳細な説明",
    "request.detailedReason": "詳細な理由",
    "request.discardingDraft": "下書きを破棄中...",
    "request.draftDiscarded": "下書きを破棄しました。",
    "request.email": "電子メール",
    "request.emailIsRequired": "電子メールが必要です",
    "request.equipmentIsRequired": "製品が必要です",
    "request.errorWhileDiscardingDraft": "下書きの破棄中にエラーが発生しました: {error}",
    "request.errorWhileFetchingDelivery": "配送取得中のエラー: {error}",
    "request.exportAll": "すべてエクスポートする",
    "request.external": "外部",
    "request.firstName": "名",
    "request.firstnameIsRequired": "名が必要です",
    "request.generalInformation": "一般情報",
    "request.hadBoxBeenOpened": "あなたが受領する前に、誰か (通関業者など) によって箱/木枠が開けられていましたか?",
    "request.hadBoxBeenOpenedBefore": "荷受人が受領する前に、箱または木枠が誰かによって開けられていましたか?",
    "request.haveNotifiedFreightForwarder": "すでに貨物運送業者に請求または通知しましたか?",
    "request.haveNotifiedInsuranceCompany": "すでに保険会社に請求または通知しましたか?",
    "request.howTheReasonWasIdentified": "問題はどのような状況でどのように特定されましたか?",
    "request.howTheReasonWasIdentifiedOptional": "問題はどのような状況でどのように特定されましたか? (オプション)",
    "request.ifYouWantToCommentText": "コメントを続けたい場合、リクエストを再度開きます",
    "request.installationIsRequired": "設備が必要です",
    "request.interestedPeople": "その他関係者",
    "request.isInWarrantyUntil": "{date}まで有効な保証期間",
    "request.item": "番号",
    "request.itemCount": "品目数",
    "request.keptPartCondition": "受領後、請求部品はどのような条件下で保管されていましたか?",
    "request.lastModified": "最終修正",
    "request.lastName": "姓",
    "request.lastnameIsRequired": "姓が必要です",
    "request.multipleItems": "複数の品目 ({count})",
    "request.newSparePartsClaim": "新しい予備部品請求",
    "request.noRequestsFound": "リクエストは見つかりませんでした。",
    "request.nonTechnical": "カスタマーサポート",
    "request.notification.completedSparePartClaim": "予備部品請求が完了しました",
    "request.notification.newAttachmentAdded": "{link}が追加されました",
    "request.notification.newAttachmentAdded.linkText": "新しい添付ファイル",
    "request.notification.newRequest": "新しいリクエストが作成されました",
    "request.notification.newSparePartClaim": "新しい予備部品請求が作成されました",
    "request.notification.solutionPlanIsProvided": "ソリューション計画が提供されます",
    "request.numberDoesNotMatchAccount": "入力した文書番号は、アカウントの詳細と一致しません。再点検後に再試行するか、Wärtsiläにお問い合わせください。",
    "request.openFullRequest": "全リクエストを開く",
    "request.openRequests": "リクエスト",
    "request.optional": "(オプション)",
    "request.orderInformation": "注文情報",
    "request.orderNumber": "Wärtsilä注文番号",
    "request.part": "部",
    "request.phone": "電話",
    "request.pleaseAttachDocuments": "補助文書",
    "request.pleaseEnterNumber": "文書番号を入力してください。",
    "request.poNumber": "あなたのPO番号",
    "request.possibleActionsAlreadyTaken": "既に実行されたアクション",
    "request.possibleActionsAlreadyTakenOptional": "既に実行されたアクション (オプション)",
    "request.postalCode": "郵便番号",
    "request.productReferenceType": "製品参照タイプ",
    "request.provideDetailedDescription": "問題の詳細な説明",
    "request.provideDocumentNameReferenceNumber": "請求された配送文書の文書名および参照番号",
    "request.provideMeasurementsClaimed": "請求された部品の測定",
    "request.provideMeasurementsCorrect": "要求された、または正しい部品の測定。",
    "request.provideMeasurementsReceived": "受領した部品の測定。",
    "request.provideMeasurementsRequired": "要求された (正しい) 部品の測定",
    "request.provideTypeSerialNumber": "補助機器の場合、ユニットとコンポーネントのタイプおよびシリアル番号を提供します。",
    "request.quantity": "数量",
    "request.rating": "レーティング",
    "request.rating.approveLabel": "サービスに満足していますか?",
    "request.rating.customerServiceRegards": "敬具、\n\nWärtsilä",
    "request.rating.customerServiceThankYou": "ありがとうございました。\n\nWärtsilä",
    "request.rating.feedbackGiven": "フィードバックを入手しました",
    "request.rating.feedbackSent": "フィードバックを送信しました",
    "request.rating.notSatisfied": "満足していない",
    "request.rating.offlineCouldNotLoadTicket": "オフラインフィードバックのチケットをロードできませんでした。",
    "request.rating.offlineRequestRatedAlready": "既に評価されたリクエスト",
    "request.rating.offlineRequestRatedAlreadyMessage": "リクエスト「{requestTitle}」は既に評価されていますが、フィードバックをまだ残すことができます。",
    "request.rating.offlineRequestRatedSuccessfully": "リクエストは正常に評価されました",
    "request.rating.offlineRequestRatedSuccessfullyMessage": "「{requestTitle}」を評価していただき、ありがとうございます。",
    "request.rating.offlineSendFeedback": "フィードバックを送信する",
    "request.rating.offlineSendingFeedback": "フィードバックを送信中...",
    "request.rating.offlineSendingFeedbackFailed": "フィードバックの送信に失敗しました。",
    "request.rating.offlineSendingReopenRequest": "再開リクエストを送信中...",
    "request.rating.offlineSendingReopenRequestFailed": "再開リクエストの送信に失敗しました。",
    "request.rating.openFeedback": "サービスを改善するためのオープンなフィードバック",
    "request.rating.openFeedbackPlaceholder": "また、フィードバックをオープンのままにすることもできます...",
    "request.rating.openFeedbackToImproveOurService": "サービスを改善するためのオープンなフィードバック",
    "request.rating.reasonForReopening": "再度開く理由",
    "request.rating.reasonIsRequired": "再度開く理由が必要です。",
    "request.rating.reasonPlaceholder": "理由を入力...",
    "request.rating.reopenButtonText": "再度開く",
    "request.rating.reopenDialogTitle": "リクエストを再度開く",
    "request.rating.reopenRequest": "リクエストを再度開く",
    "request.rating.requestIsClosed": "リクエストは閉じられています",
    "request.rating.requestIsClosedNewRequestLinkText": "新しいリクエスト",
    "request.rating.requestIsClosedRatingTitle": "リクエストは閉じられており、評価することはできません。",
    "request.rating.requestIsClosedTitle": "リクエストは閉じられており、再度開くことはできません。\n{newRequestLink}を作成してください。",
    "request.rating.requestReopened": "再度開かれたリクエスト",
    "request.rating.requestReopenedText": "リクエストが再度開かれましたら、できるだけ早くご連絡いたします。",
    "request.rating.satisfied": "満足している",
    "request.rating.sendFeedback": "送信する",
    "request.rating.suggestionIsRequired": "フィードバックが必要です。",
    "request.rating.thankYouForYourFeedback": "フィードバックをありがとうございました。",
    "request.rating.thankYouForYourFeedbackText": "弊社は常にカスタマーサービスの改善に努めており、お客様のフィードバックこそ最も価値があるものと認識しております。",
    "request.rating.weHaveAlreadyReceivedFeedback": "このリクエストに対するフィードバックは既に受領しています。それ以上のフィードバックを得ることはできません。",
    "request.rating.weWillRedirectYou": "{link}に転送します",
    "request.rating.whatCanWeDoBetter": "フィードバックを入力...",
    "request.reason": "理由",
    "request.reason.9999": "部品の品質不足",
    "request.reason.DD13": "配送書類および請求書",
    "request.reason.DD16": "部品の数量間違い/不完全な貨物",
    "request.reason.DD18": "部品間違い",
    "request.reason.DD22": "配送遅延",
    "request.reason.DD23": "貨物の紛失 (完全な配送での紛失)",
    "request.reason.DD24": "不十分な梱包/配送破損",
    "request.reason.QD02": "部品に漏れがあります",
    "request.reason.QD03": "部品が機能していません",
    "request.reason.QD07": "部品に錆があります",
    "request.reason.QD08": "部品が破損しています",
    "request.reason.QD09": "部品が合いません",
    "request.reason.QD12": "部品分類の問題",
    "request.reason.QD14": "部品のマーキング/ラベル関連の問題",
    "request.recentlyOpened": "最近開かれたリクエスト",
    "request.requestId": "リクエストID",
    "request.requestType": "リクエストタイプ",
    "request.requestTypeIsRequired": "リクエストタイプを選択してください",
    "request.runningHours": "稼働時間",
    "request.runningHoursDate": "更新日",
    "request.runningHoursDateInFuture": "稼働時間を未来の日付にすることはできません!",
    "request.runningHoursMustBeBetween": "稼働時間は、0～9,999,999,999の数字でなければなりません!",
    "request.runningHoursOptional": "稼働時間 (オプション)",
    "request.selectAddress": "住所を選択する",
    "request.selectCategory": "分類を選択...",
    "request.selectClaimReason": "理由を選択...",
    "request.selectClaimedItems": "請求品目を選択する",
    "request.solution": "ソリューション",
    "request.solutionPlan": "ソリューション計画",
    "request.solutionPlanProvided": "提供されたソリューション計画",
    "request.solutionText": "Wärtsiläソリューション",
    "request.sparePartsClaim": "予備部品請求",
    "request.subject": "件名",
    "request.subjectIsRequired": "件名が必要です",
    "request.suggestedPartsText": "交換する部品が既に分かっている場合、それらをリクエストに追加してください。",
    "request.suggestedSpareParts": "推奨予備部品",
    "request.suggestionToReplaceParts": "部品交換の提案",
    "request.summary": "概要",
    "request.technical": "技術サポートまたは保証リクエスト",
    "request.title": "タイトル",
    "request.titleIsRequired": "タイトルが必要です",
    "request.track": "トラック",
    "request.typePartNameOrNumber": "部品名称または番号を入力",
    "request.types.allRequests": "すべてのリクエスト",
    "request.uploadsAreNotFinished": "アップロードが完了していません!",
    "request.validation.deliveryIsRequired": "配送が必要です!",
    "request.validation.detailedReasonIsRequired": "詳細な理由が必要です!",
    "request.validation.fieldIsRequired": "必須フィールドです!",
    "request.validation.itemIsRequired": "品目が必要です!",
    "request.validation.quantityIsRequired": "請求された数量が必要です!",
    "request.validation.quantityMustBeNumberBetween": "無効な数量が入力されました! (最小: {min}, 最大: {max})",
    "request.validation.quantityMustBeNumeric": "請求された数量は数字でなければなりません!",
    "request.validation.reasonIsRequired": "理由が必要です!",
    "request.wartsilaInitiated": "Wärtsilä開始",
    "request.wasBoxVisiblyDamaged": "小包内の部品が損傷していた場合、箱または木枠の損傷を目視確認できましたか?",
    "request.wasForwarderInformed": "受領時に箱または木枠の損傷が目視で確認された場合、配送時に運送業者に通知しましたか?",
    "request.wasOutsideOfBoxDamaged": "箱または木枠の外側が損傷していましたか?",
    "request.wasPackagingMaterialDamaged": "箱の中の梱包材が損傷していましたか?",
    "request.wasPackingMaterialDamagedInsufficient": "箱の中の梱包材が損傷していた、または不足していましたか?",
    "request.wasPartInUseLeakage": "漏れに気づいたとき、部品を使用中でしたか?そうである場合、稼働は何時間でしたか?",
    "request.wasPartInUseProblem": "問題に気づいたとき、部品を使用中でしたか?そうである場合、稼働は何時間でしたか?",
    "request.wasPartInspectedByWartsila": "部品が使用中であった場合、その部品はWärtsiläの担当者によって検査および設置されましたか?",
    "request.whenCorrosionNoticed": "腐食にいつ気づきましたか?",
    "request.whenDeliveryExpected": "配送予定日はいつでしたか?",
    "request.whenDeliveryReceived": "配送物はいつ受領しましたか?",
    "request.whenWasDamageNoticed": "損傷にいつ気づきましたか",
    "request.yourClaimReference": "あなたの請求参照",
    "requestStatus.answered": "完了済み",
    "requestStatus.approved": "承認済み",
    "requestStatus.closed": "閉",
    "requestStatus.closedChangedToProject": "終了 - プロジェクトに変更",
    "requestStatus.draft": "ドラフト",
    "requestStatus.inProgress": "進行中",
    "requestStatus.partsInDelivery": "配送中の部品",
    "requestStatus.rejected": "却下済み",
    "requestStatus.waitingForCustomerActions": "顧客のアクションを待っています",
  },
};
