import { spacer, spacers } from "src/design-system/Tokens/tokens";

import { StyleSheet } from "@react-pdf/renderer";
import colors from "src/design-system/Tokens/colors";

const styles = StyleSheet.create({
  page: {
    // Padding should be hardcoded(no spacer or any other func)
    // Otherwise page renders incorrectly
    padding: 60,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: spacer(5),
  },
  section: {
    fontSize: 7,
  },
  headers: {
    flexDirection: "row",
    padding: spacer(1),
    color: colors.primary.white,
    backgroundColor: colors.primary.blue,
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  flexEnd: {
    display: "flex",
    fontWeight: 600,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  image: {
    width: 96,
    height: 59,
  },
  imageQP: {
    width: 118,
    height: 45,
  },
  installation: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: spacer(3),
  },
  note: {
    fontSize: 8,
    marginBottom: spacer(3),
    color: colors.primary.orange,
  },
  weight: {
    fontSize: 8,
    margin: spacers([1, 0]),
  },
  disclaimer: {
    marginBottom: spacer(1),
    fontSize: 8,
  },
  disclaimerPrice: {
    margin: spacers([2, 1]),
    fontSize: 8,
  },
  disclaimerBottom: {
    position: "absolute",
    height: 50,
    bottom: 0,
    left: 60,
  },
  content: {
    flexDirection: "column",
    padding: spacers([0, 1]),
  },
  contentRow: {
    flexDirection: "row",
    borderTop: 1,
    borderTopColor: colors.secondary.gray10,
  },
  contentRowValue: {
    margin: spacers([1, 0]),
  },
});

export default styles;
