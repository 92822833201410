import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import * as React from "react";

import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, IconSize } from "src/icons";
import Slider, { Settings } from "react-slick";
import {
  breakpoints,
  whenDesktopLargeOrLarger,
  whenDesktopOrLarger,
  whenPhoneLargeOrLarger,
  whenTabletOrLarger,
} from "src/design-system/Tokens/breakpoints";

import { Portal } from "react-portal";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";

const getBase64Src = (image: IBase64Image) => {
  const mimeTypes = {
    GIF: "image/gif",
    SVG: "image/svg+xml",
  };

  return `data:${mimeTypes[image.type.toUpperCase()]};base64,${image.imageData}`;
};

const isUrlImage = (img: IImage): img is IUrlImage => {
  return "url" in img;
};

const Img = styled.img<{ maxHeight: string }>`
  max-height: ${(props) => props.maxHeight};
  cursor: pointer;
`;

interface IPaginationButton {
  isSelected: boolean;
}

const PaginationButton = styled.div<IPaginationButton>`
  border: 2px ${colors.primary.blue} solid;
  ${(props: IPaginationButton) =>
    props.isSelected
      ? `
    color: ${colors.primary.white};
    background-color: ${colors.primary.blue};
  `
      : `
    color: ${colors.primary.blue};
  `}
  padding: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DotsContainer = styled.ul`
  color: ${colors.secondary.gray10};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  && li {
    position: relative;
    display: flex;
    cursor: pointer;
    margin: 5px 10px 10px 10px;
    align-items: center;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  max-width: calc(100vw - 80px);

  ${whenPhoneLargeOrLarger(`
    max-width: calc(100vw - 190px);
  `)}

  @media (min-width: ${breakpoints.tablet + 1}px) {
    max-width: calc(100vw - 450px);
  }

  ${whenDesktopOrLarger(`
    max-width: calc(100vw - 500px);
  `)}

  ${whenDesktopLargeOrLarger(`
    max-width: calc(${breakpoints.desktopLarge}px - 500px);
  `)}

  & .slick-list {
    border: 3px ${colors.secondary.gray10} solid;
  }

  & .slick-track {
    margin-left: 0;
  }
  & .slick-slide {
    height: 202px;
  }
`;

interface ISliderImageWrapper {
  isBorder: boolean;
}

const SliderImageWrapper = styled.div<ISliderImageWrapper>`
  display: inline-block;
  margin: 1px;

  ${(props: ISliderImageWrapper) =>
    props.isBorder &&
    `
    border-left: 3px solid ${colors.secondary.gray10};
  `}
`;

interface IUrlImage {
  id: string;
  url: string;
}
interface IBase64Image {
  id: string;
  type: string;
  url?: string;
  imageData?: string;
}

type IImage = IUrlImage | IBase64Image;

interface ImgCarouselProps {
  images?: IImage[];
  maxHeight: string;
}

interface ImgCarouselState {
  selectedImage: IImage | null;
  currentImageIndex: number;
  showOpenedImage: boolean;
}

const SingleImageViewerWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  z-index: 14;
  justify-content: center;
  align-items: flex-start;
  padding-top: 90px;

  left: 50%;
  right: 50%;

  & img {
    background-color: ${colors.primary.white};
    width: calc(100vw - 35px);
    box-shadow: 0px 3px 5px 0px ${colors.secondary.blue100};
    user-select: none;
  }

  ${whenTabletOrLarger(`
    & img {
      width: 650px;
    }
  `)}
`;

const SingleImageControls = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: ${colors.primary.blue};

  :hover {
    cursor: grab;
  }

  :active {
    cursor: grabbing;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: auto;
  bottom: auto;
  right: 15px;
  height: 20px;
  cursor: pointer;
`;

const ImageControlsWrapper = styled.div`
  display: flex;
  width: 85px;
  align-items: center;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
`;

interface ISingleImageViewer {
  image?: IImage;
  slider: any;
  onClose: () => void;
  onNext: (useSliderToMove: boolean) => void;
  onPrev: (useSliderToMove: boolean) => void;
}

const SingleImageViewer = ({ onClose, onNext, onPrev, image, slider }: ISingleImageViewer) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (image && ref?.current) {
      const draggablePic = ref.current;

      const onContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        return false;
      };

      document.addEventListener("contextmenu", onContextMenu);

      const mousedown = (event: MouseEvent) => {
        //initial shifts
        let shiftX = event.clientX - draggablePic.getBoundingClientRect().left;
        let shiftY = event.clientY - draggablePic.getBoundingClientRect().top;

        draggablePic.style.position = "fixed";
        draggablePic.style.zIndex = "1000";

        const moveAt = (clientX: number, clientY: number) => {
          draggablePic.style.left = clientX - shiftX + "px";
          draggablePic.style.top = clientY - shiftY + "px";
        };

        const onMouseMove = (event: MouseEvent) => {
          moveAt(event.clientX, event.clientY);
          draggablePic.style.cursor = "grabbing";
        };

        moveAt(event.clientX, event.clientY);

        document.addEventListener("mousemove", onMouseMove);

        document.onmouseup = () => {
          document.onmouseup = null;
          draggablePic.style.cursor = "auto";
          document.removeEventListener("mousemove", onMouseMove);
        };
      };

      // That’s because the browser has its own drag’n’drop support for images and some other elements.
      // It runs automatically and conflicts with ours.
      draggablePic.ondragstart = () => false;
      draggablePic.addEventListener("mousedown", mousedown);

      return () => {
        draggablePic.removeEventListener("mousedown", mousedown);
        document.removeEventListener("contextmenu", onContextMenu);
      };
    }

    return;
  }, [image]);

  if (image === null || image === undefined) {
    return null;
  }

  // if we have too few pictures,
  const useSliderToMove =
    slider && slider.props.dots && slider.innerSlider.state.slideCount > slider.props.slidesToShow;

  return (
    <Portal>
      <SingleImageViewerWrapper ref={ref}>
        <div>
          <SingleImageControls>
            <ImageControlsWrapper>
              <IconWrapper onClick={() => onPrev(useSliderToMove)}>
                <ArrowLeftIcon size={IconSize.Medium} color={colors.primary.white} />
              </IconWrapper>

              <IconWrapper onClick={() => onNext(useSliderToMove)}>
                <ArrowRightIcon size={IconSize.Medium} color={colors.primary.white} />
              </IconWrapper>
            </ImageControlsWrapper>

            <CloseIconWrapper onClick={onClose}>
              <CloseIcon size={IconSize.Medium} color={colors.primary.white} />
            </CloseIconWrapper>
          </SingleImageControls>

          <img alt={image.id} src={isUrlImage(image) ? image.url : getBase64Src(image)} />
        </div>
      </SingleImageViewerWrapper>
    </Portal>
  );
};

export class ImageCarousel extends React.Component<ImgCarouselProps, ImgCarouselState> {
  private slider: Slider | null;
  private settings: Settings = {
    focusOnSelect: false,
    dots: true,
    infinite: false,
    dotsClass: "",
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    arrows: false,
    customPaging: (currentIndex: number) => (
      <PaginationButton isSelected={this.state.currentImageIndex === currentIndex}>{currentIndex + 1}</PaginationButton>
    ),
    appendDots: (dots: React.ReactNode) => {
      return <DotsContainer>{dots}</DotsContainer>;
    },
    beforeChange: (currentIndex: number, nextIndex: number) => {
      if (currentIndex !== nextIndex) {
        this.setState({ currentImageIndex: nextIndex });
      }
    },
    responsive: [
      {
        breakpoint: breakpoints.desktop,
        settings: {
          slidesToShow: 4,
          dots: true,
        },
      },
      {
        breakpoint: breakpoints.tablet,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: breakpoints.mobile,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  constructor(props: ImgCarouselProps) {
    super(props);
    this.state = {
      selectedImage: null,
      currentImageIndex: 0,
      showOpenedImage: false,
    };
  }

  public render() {
    return (
      <Wrapper>
        {this.state.showOpenedImage && (
          <SingleImageViewer
            slider={this.slider}
            onClose={() => this.setState({ showOpenedImage: false })}
            onNext={(useSliderToMove: boolean) => {
              if (this.slider && useSliderToMove) {
                this.slider.slickNext();
              } else {
                if (this.props.images && this.state.currentImageIndex >= this.props.images.length - 1) {
                  return;
                }
                this.setState({ currentImageIndex: this.state.currentImageIndex + 1 });
              }
            }}
            onPrev={(useSliderToMove: boolean) => {
              if (this.slider && useSliderToMove) {
                this.slider.slickPrev();
              } else {
                if (this.state.currentImageIndex <= 0) {
                  return;
                }
                this.setState({ currentImageIndex: this.state.currentImageIndex - 1 });
              }
            }}
            image={this.props.images ? this.props.images[this.state.currentImageIndex] : undefined}
          />
        )}

        <Slider ref={(c) => (this.slider = c)} {...this.settings}>
          {this.props.images &&
            this.props.images.map((img, index) => (
              <SliderImageWrapper
                key={img.id}
                isBorder={index > 0}
                onClick={() => {
                  this.setState({ showOpenedImage: true, currentImageIndex: index });
                }}
              >
                <Img
                  src={isUrlImage(img) ? img.url : getBase64Src(img)}
                  onClick={() => {
                    this.setState({ selectedImage: img });
                  }}
                  maxHeight={this.props.maxHeight}
                />
              </SliderImageWrapper>
            ))}
        </Slider>
      </Wrapper>
    );
  }
}
