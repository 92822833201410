import { ISpacing, margin, padding } from "src/design-system/Tokens/spacings";

import { whenIE11 } from "src/util/browserCompatibility";
import styled from "styled-components";
import { breakpoints, whenLargerThan } from "./breakpoints";
import { spacer, spacers } from "./tokens";

export enum ColumnCount {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  NINE = 9,
  TWELVE = 12,
  EIGHTEEN = 18,
}

interface IGridProps extends ISpacing {
  spacing?: GridSpacing;
  fallbackWidth?: { min: number; max: number };

  itemsPerRow?: {
    mobile: ColumnCount;
    mobileLarge?: ColumnCount;
    tablet?: ColumnCount;
    desktop?: ColumnCount;
    desktopLarge?: ColumnCount;
  };
}

export enum GridSpacing {
  default,
  airy,
}

export const GridRowExtraLargeItems = { mobile: ColumnCount.ONE, tablet: ColumnCount.ONE, desktop: ColumnCount.TWO };
export const GridRowLargeItems = { mobile: ColumnCount.TWO, tablet: ColumnCount.FOUR, desktop: ColumnCount.FOUR };
export const GridRowMediumItems = { mobile: ColumnCount.TWO, tablet: ColumnCount.FOUR, desktop: ColumnCount.SIX };
export const GridRowSmallItems = {
  mobile: ColumnCount.THREE,
  mobileLarge: ColumnCount.FOUR,
  tablet: ColumnCount.SIX,
  desktopLarge: ColumnCount.NINE,
};

function gridMediaColumns(breakpoint: string): (props: IGridProps) => string {
  return (props: IGridProps) => {
    return (
      props.itemsPerRow &&
      props.itemsPerRow[breakpoint] &&
      `
      ${whenLargerThan(
        breakpoints[breakpoint],
        `
        grid-template-columns: repeat(${props.itemsPerRow[breakpoint]}, [col] 1fr);
    `
      )}`
    );
  };
}

export const GridSpacings: { [some: string]: { gridGap: string; padding: string } } = {
  [GridSpacing.default]: {
    gridGap: spacer(2),
    padding: spacers([0, 2, 2, 0]),
  },
  [GridSpacing.airy]: {
    gridGap: spacers([4, 3]),
    padding: spacers([0, 3, 4, 0]),
  },
};

export const Grid = styled.div<IGridProps>`
  display: grid;

  ${margin};
  ${padding};

  grid-template-columns: repeat(${(props: IGridProps) => props.itemsPerRow && props.itemsPerRow.mobile}, [col] 1fr);

  ${gridMediaColumns("mobileLarge")}
  ${gridMediaColumns("tablet")}
  ${gridMediaColumns("desktop")}
  ${gridMediaColumns("desktopLarge")}

  ${(props: IGridProps) => {
    if (props.spacing && GridSpacings[props.spacing]) {
      return `grid-gap: ${GridSpacings[props.spacing].gridGap};`;
    } else {
      return `grid-gap: ${GridSpacings[GridSpacing.default].gridGap};`;
    }
  }}

  ${(props: IGridProps) =>
    whenIE11(`
    display: flex;
    flex-wrap: wrap;

    & > * {
      ${
        props.spacing && GridSpacings[props.spacing]
          ? `padding: ${GridSpacings[props.spacing].padding}`
          : `padding: ${GridSpacings[GridSpacing.default].padding}`
      }

    ${
      props.fallbackWidth
        ? `
        min-width: ${props.fallbackWidth.min}px;
        max-width: ${props.fallbackWidth.max}px;
      `
        : `
        min-width: 180px;
        max-width: 200px;
      `
    }
    }
  `)}
`;
