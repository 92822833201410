import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const HappyFace = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Happy face</title>
    <path d="M32.55,29.95a0.73,0.73,0,0,0-.72-0.74,0.79,0.79,0,0,0-.68.46l0,0a8.39,8.39,0,0,1-7.67,5.12A8.22,8.22,0,0,1,15.91,30l-0.05-.11a0.67,0.67,0,0,0-.59-0.31,0.73,0.73,0,0,0-.72.74,0.88,0.88,0,0,0,.14.44,9.71,9.71,0,0,0,17.72-.36v0A1.15,1.15,0,0,0,32.55,29.95Z" />
    <path d="M23.78,4a20,20,0,1,0,20,20A20,20,0,0,0,23.78,4Zm0,36a16,16,0,1,1,16-16A16,16,0,0,1,23.78,40Z" />
    <path d="M35,18.33a0.36,0.36,0,0,1-.42.29,0.4,0.4,0,0,1-.31-0.27v0a4.27,4.27,0,0,0-3.47-3A4.2,4.2,0,0,0,26.66,17l0,0a0.35,0.35,0,0,1-.32.1,0.36,0.36,0,0,1-.31-0.4,0.39,0.39,0,0,1,.1-0.19A5.1,5.1,0,0,1,35,18.12h0A0.51,0.51,0,0,1,35,18.33Z" />
    <circle cx="29.64" cy="22.49" r="3" />
    <path d="M13,18.33a0.36,0.36,0,0,0,.42.29,0.4,0.4,0,0,0,.31-0.27v0a4.27,4.27,0,0,1,3.47-3A4.2,4.2,0,0,1,21.34,17l0,0a0.35,0.35,0,0,0,.32.1,0.36,0.36,0,0,0,.31-0.4,0.39,0.39,0,0,0-.1-0.19A5.1,5.1,0,0,0,13,18.12h0A0.51,0.51,0,0,0,13,18.33Z" />
    <circle cx="17.92" cy="22.49" r="3" />
  </IconBase>
);
