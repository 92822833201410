import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { startOnboarding } from "src/redux/onboarding";
import { IUserPrefsProps, UserPrefsComponent, IDispatchProps } from "./UserPrefsComponent";

const mapStateToProps = (state: IUserPrefsProps) => ({
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps =>
  bindActionCreators(
    {
      startOnboarding,
    },
    dispatch
  );

const UserPrefsContainer = connect(mapStateToProps, mapDispatchToProps)(UserPrefsComponent);
export { UserPrefsContainer };
