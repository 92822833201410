import { IAuth } from "online-services-types";
import { AnyAction } from "redux";
import { action } from "typesafe-actions";
import { redirectBrowserToURL } from "src/routes";

export const redirectToLogin = (location: Location) =>
  redirectBrowserToURL(
    `${process.env.REACT_APP_API_URL_BASE}auth/login?path=${encodeURIComponent(
      `${location.pathname}${location.search}`
    )}`
  );

// 60000 == 1min  // 120000 == 2 min // 7200000 == 2 hours
export const TOKEN_EXPIRATION_TIME: number = 7200000;
// 30000 == 30 sec // 60000 == 1 min // 600000 == 10 minutes
export const TOKEN_EXPIRATION_REMINDER_TIME: number = TOKEN_EXPIRATION_TIME - 600000;

const RESET_TOKEN_EXPIRATION = "RESET_TOKEN_EXPIRATION";
const NOTIFY_ABOUT_SESSION_EXPIRATION = "NOTIFY_ABOUT_SESSION_EXPIRATION";

export const resetExpiration = () => action(RESET_TOKEN_EXPIRATION);
export const notifyAboutSessionExpiration = () => action(NOTIFY_ABOUT_SESSION_EXPIRATION);

const initialState: IAuth = {
  tokenExpirationDate: new Date().getTime() + TOKEN_EXPIRATION_TIME,
  isSessionExpired: false,
};

export const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case RESET_TOKEN_EXPIRATION:
      const nextTokenExpirationDate = new Date().getTime() + TOKEN_EXPIRATION_TIME;
      return {
        ...state,
        tokenExpirationDate: nextTokenExpirationDate,
      };
    case NOTIFY_ABOUT_SESSION_EXPIRATION:
      return {
        ...state,
        isSessionExpired: true,
      };
    default:
      return state;
  }
};
