import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAppState } from "src/redux";
import { selectCurrentAccount } from "src/redux/actions";
import { APIResourceState } from "src/redux/api";
import {
  AccountSelectorComponent,
  IAccountSelectorDispatchProps,
  IAccountSelectorOwnProps,
  IAccountSelectorReduxStateProps
} from "./AccountSelectorComponent";

const mapStateToProps = (state: IAppState) => ({
  accounts: state.accounts,
  showAccounts: state.accounts.data && state.accounts.data.length > 1,
});

const mapDispatchToProps = (dispatch: Dispatch): IAccountSelectorDispatchProps =>
  bindActionCreators(
    {
      getAccounts: APIResourceState.accounts.get,
      selectCurrentAccount,
    },
    dispatch
  );

const AccountSelectorContainer = connect<IAccountSelectorReduxStateProps, IAccountSelectorDispatchProps, IAccountSelectorOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(AccountSelectorComponent);
export { AccountSelectorContainer };
