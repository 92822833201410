import * as React from "react";
import { useState } from "react";
import { Dialog } from "src/components/Dialog";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { spinnerAlign, spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { Button } from "src/design-system/Button";
import { translateString } from "src/util/localization";

export const CloseClaimButton = ({ onConfirmClose }: { onConfirmClose: () => void }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <>
      {isLoading ? (
        <LoadingSpinner size={spinnerSize.sm} align={spinnerAlign.start} disableText dark />
      ) : (
        <Button onClick={() => setIsOpen(true)}>
          <LocalizedString id="request.closeClaim" />
        </Button>
      )}
      <Dialog
        title={translateString("request.closeClaim")}
        isDialogOpen={isOpen}
        onCancel={() => setIsOpen(false)}
        submit={{
          buttonTitle: translateString("request.closeClaim"),
          callback: () => {
            onConfirmClose();
            setIsLoading(true);
            setIsOpen(false);
          },
        }}
      >
        <LocalizedString id="request.closeClaim.areYouSure" />
      </Dialog>
    </>
  );
};