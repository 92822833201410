// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const sfoc_pt_BR: ITranslations = {
  pt_BR: {
    "sfoc.aBank": "Bloco A",
    "sfoc.bBank": "Bloco B",
    "sfoc.error.valueHasToBe24Hourformat": "O horário deve estar no formato 24 horas (HH:MM).",
    "sfoc.error.valueHasToBeBetween": "O valor deve estar entre {min} e {max}",
    "sfoc.error.valueHasToBeNumeric": "O valor deve ser numérico.",
    "sfoc.error.valueIsRequired": "O valor é obrigatório",
    "sfoc.field.ISOCorrectedSFOC": "SFOC corrigido pela ISO",
    "sfoc.field.LTWaterTemperature": "Temperatura da água LT",
    "sfoc.field.absoluteHumidity": "Umidade absoluta",
    "sfoc.field.activePower": "Energia ativa",
    "sfoc.field.afterTestTakeFuelSample": "Após o teste, retire uma amostra de combustível, anote o n° da vedação, meça com o teor de água com o kit de teste",
    "sfoc.field.ambientConditionValuesOnSFOCCabinetSameAsLocalWeatherStation": "Os valores das condições ambientes no Gabinete SFOC devem ser os mesmos que da Estação meteorológica local",
    "sfoc.field.averageFiringPressureBar": "Pressão média de detonação {bank}",
    "sfoc.field.averageFiringPressureBarHeading": "Pressão média de detonação",
    "sfoc.field.barometricPressure": "Pressão barométrica",
    "sfoc.field.betaFactor": "Fator beta",
    "sfoc.field.calculatedISOCorrectedSFOC": "SFOC corrigido pela ISO calculado",
    "sfoc.field.calculatedSFOC": "SFOC calculado",
    "sfoc.field.chargeAirPressure": "Pressão de ar de sobrealimentação",
    "sfoc.field.chargeAirTemperature": "Temperatura do ar de carga",
    "sfoc.field.checkThatSFOCSystemIsWorkingProperly": "Antes de iniciar o teste, verifique se o sistema SFOC está funcionando corretamente",
    "sfoc.field.cleanLeakMeasurementTime": "Tempo de medição",
    "sfoc.field.consumedFuel": "Combustível consumido",
    "sfoc.field.cylinder": "Cilindro {cylinderNr}",
    "sfoc.field.deltaPOverCAC": "Δp no CAC",
    "sfoc.field.deltaPOverTCAirFilter": "Δp no filtro de ar do T/C",
    "sfoc.field.differentialAutoZeroValue": "Valor de zero automático diferencial",
    "sfoc.field.engine": "Motor",
    "sfoc.field.engineRoomTemperature": "Temperatura da sala do motor",
    "sfoc.field.engineRoomVentilationIsOn": "A ventilação da sala do motor está LIGADA",
    "sfoc.field.engineSpeed": "Velocidade do motor",
    "sfoc.field.exhaustGasBackPressureAfterTC": "Contrapressão do gás de exaustão após o T/C",
    "sfoc.field.exhaustGasCleaningSystemOff": "Sistema de limpeza do gás de exaustão (lavador) DESLIGADO",
    "sfoc.field.exhaustGasTemperatureAfterTC": "Temperatura do gás de exaustão após o T/C",
    "sfoc.field.exhaustGasTemperatureBeforeTC": "Temperatura do gás de exaustão antes do T/C",
    "sfoc.field.exhaustTemperatureAfterCylinder": "Temperatura de exaustão após o cilindro {bank}",
    "sfoc.field.exhaustTemperatureAfterCylinderHeading": "Temperatura de exaustão após o cilindro",
    "sfoc.field.foSampleSealNumber": "Número da vedação da amostra de FO",
    "sfoc.field.fuelCalorificValueLHV": "Valor calorífico do combustível LHV",
    "sfoc.field.fuelFlowInlet": "Entrada da vazão de combustível",
    "sfoc.field.fuelFlowReturn": "Retorno da vazão de combustível",
    "sfoc.field.fuelInletFlowRatioToConsumption": "Razão da vazão de admissão de combustível em relação ao consumo",
    "sfoc.field.fuelNetSpecificEnergyLHV": "Poder calorífero específico do combustível LHV",
    "sfoc.field.fuelPressureBeforeEngine": "Pressão do combustível antes do motor",
    "sfoc.field.fuelTemperatureBeforeEngine": "Temperatura do combustível na entrada do motor",
    "sfoc.field.fuelType": "Tipo de combustível",
    "sfoc.field.fuelTypeIsTheSameAsUsedDuringTheBaselineTest": "O tipo de combustível é o mesmo usado durante o teste de base (normalmente HFO)",
    "sfoc.field.fuelViscosityBeforeEngine": "Viscosidade do combustível na entrada do motor",
    "sfoc.field.generatorPowerFactor": "Fator de potência gerado (Cos Φ)",
    "sfoc.field.loadIndicatorPosition": "Posição do indicador de carga",
    "sfoc.field.measuredCleanLeakWeight": "Peso do vazamento limpo medido",
    "sfoc.field.measuredFOC": "FOC medido",
    "sfoc.field.measuredFuelOilCleanLeak": "Vazamento limpo de óleo combustível medido",
    "sfoc.field.nickname": "Apelido",
    "sfoc.field.priorStartTestEngineShouldRunWithStableLoadFor20MinInLoadControl": "Antes de iniciar o teste, o motor deverá funcionar com carga estável durante 20 minutos no modo de controle de carga",
    "sfoc.field.producedEnergy": "Energia produzida",
    "sfoc.field.pumpRackPosition": "Posição do rack da bomba {bank}",
    "sfoc.field.pumpRackPositionHeader": "Posição do rack da bomba",
    "sfoc.field.quarterNr": "Número do trimestre",
    "sfoc.field.repeatTestWhenProducedEnergyIsAboveBaseline": "Repita o teste quado a energia produzida estiver acima de ± {tolerance}% da base",
    "sfoc.field.runHoursAtCACOH": "Horas de operação no momento do último CAC OH",
    "sfoc.field.runHoursAtLastCACWash": "Horas de operação no momento da última lavagem CAC",
    "sfoc.field.runHoursAtLastInjectionPumpOH": "Horas de operação no momento da última bomba de injeção OH",
    "sfoc.field.runHoursAtLastInjectorOH": "Horas de operação no momento do último injetor OH",
    "sfoc.field.runHoursAtLastMajorOH": "Horas de operação no momento da última principal OH",
    "sfoc.field.runHoursAtLastTCMaintenance": "Horas de operação no momento da última manutenção do T/C",
    "sfoc.field.runHoursAtLastTCWash": "Horas de operação no momento da última lavagem do T/C",
    "sfoc.field.samplingPosition": "Posição de amostragem",
    "sfoc.field.sulfur": "Enxofre",
    "sfoc.field.testEndTime": "Horário de término do teste",
    "sfoc.field.testEngineInLoadControlModeConfirmThatActivePowerIsTheSameAtBaseline": "Teste o motor no modo de controle de carga e confirme se a energia ativa é a mesma que a base.",
    "sfoc.field.testFailedDueTo": "O teste falhou devido a",
    "sfoc.field.testStartTime": "Horário de início do teste",
    "sfoc.field.totalRunningHours": "Número total de horas de operação",
    "sfoc.field.turboChargerSpeed": "Velocidade do turbocompressor",
    "sfoc.field.typeOfSFOCTest": "Tipo de teste SFOC",
    "sfoc.field.waterContent": "Teor de água",
    "sfoc.field.waterContentMeasured": "Teor de água medido com o kit de teste",
    "sfoc.field.waterContentMeasuredFromFuel": "Teor de água medido no combustível",
    "sfoc.field.waterFuelEmulsionOff": "Emulsão de água-combustível (WFE) DESLIGADA por no mínimo 6 horas de operação antes de iniciar o teste",
    "sfoc.info.sendingReport": "Enviando relatório SFOC.",
    "sfoc.info.sendingReportFailed": "Envio do relatório SFOC falhou.",
    "sfoc.info.sendingReportSuccess": "O relatório SFOC foi enviado com sucesso.",
    "sfoc.meanValue": "Média",
    "sfoc.phase.automation": "Automação",
    "sfoc.phase.cabinet": "Gabinete",
    "sfoc.phase.engine": "Motor",
    "sfoc.phase.general": "Geral",
    "sfoc.phase.section.dataCollection": "Coleta de dados para cálculo do combustível",
    "sfoc.phase.section.engineOverHaulHistory": "Histórico de reparos do motor",
    "sfoc.phase.section.general": "Geral",
    "sfoc.phase.section.performanceTest": "Teste de desempenho do motor",
    "sfoc.phase.section.sfocPerformanceCheckList": "Lista de verificação do teste de desempenho SFOC",
    "sfoc.samplingPosition.AtFOBoosterUnite": "Na unidade reserva FO",
    "sfoc.samplingPosition.BeforeEngine": "Antes do motor",
    "sfoc.samplingPosition.Other": "Outro",
    "sfoc.testFailure.DiffFlowFluctuationOver2": "Flutuação da vazão diferencial acima de > ±2%",
    "sfoc.testFailure.LoadAndDiffFlowFluctuationOver2": "Flutuação da carga e da vazão diferencial acima de > ±2%",
    "sfoc.testFailure.LoadFluctuationOver2": "Flutuação da carga acima de > ±2%",
    "sfoc.unit.MJPerKg": "MJ/kg",
    "sfoc.unit.bar": "bar",
    "sfoc.unit.gPerKg": "g/kg",
    "sfoc.unit.gPerkWh": "g/kWh",
    "sfoc.unit.hours": "h",
    "sfoc.unit.kW": "kW",
    "sfoc.unit.kWh": "kWh",
    "sfoc.unit.kg": "kg",
    "sfoc.unit.kgPerHour": "kg/h",
    "sfoc.unit.kgPerM3": "kg/m3",
    "sfoc.unit.mbar": "mbar",
    "sfoc.unit.mgPerKg": "mg/kg",
    "sfoc.unit.min": "mín",
    "sfoc.unit.mm": "mm",
    "sfoc.unit.mm2PerSec": "mm2/s",
    "sfoc.unit.percent": "%",
    "sfoc.unit.rpm": "rpm",
    "sfoc.value.afterOH": "Após OH",
    "sfoc.value.audit": "Auditar",
    "sfoc.value.na": "N/A",
    "sfoc.value.no": "Não",
    "sfoc.value.off": "Desligado",
    "sfoc.value.on": "Ligado",
    "sfoc.value.other": "Outro",
    "sfoc.value.quarterly": "Trimestralmente",
    "sfoc.value.yes": "Sim",
  },
};
