import { Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { IAccountUser } from "online-services-types/index";
import * as React from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import { APIFetch } from "src/APIFetch";
import { Heading } from "src/components/Heading";
import { GrowH2 } from "src/components/Heading/Heading";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { SummaryItem } from "src/components/RequestSummary/RequestSummaryComponent";
import * as s from "src/components/TrainingsList/styles";
import { Button, ButtonStyle } from "src/design-system/Button";
import { Container } from "src/design-system/Container";
import { TextArea } from "src/design-system/TextArea";
import { TextInput } from "src/design-system/TextInput";
import colors from "src/design-system/Tokens/colors";
import { IconSize } from "src/icons";
import { getRoutes, redirectBrowser } from "src/routes";
import { PageSection } from "src/design-system/Container";
import { ButtonsWrapper } from "src/design-system/ButtonsWrapper";
import { DividerColor, DividerLine } from "src/design-system/Divider/Divider";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "src/util/error";
import { translateString } from "src/util/localization";
import { getUserProfile } from "src/views/ProfileView/userProfile";
import { IRadioChoiceOption, RadioChoice } from "src/design-system/Radio/RadioChoiceComponent";

interface IRouteParams {
  trainingId: string;
}

interface IRegisterState {
  loading: boolean;
  user?: IAccountUser;
}

interface ITrainingRegisterViewLocationState {
  trainingId: string;
}

type IRegisterProps = RouteComponentProps<IRouteParams, StaticContext, ITrainingRegisterViewLocationState>;

interface IFormProps {
  isRegisterMySelf: radioP;
  people: IPerson[];
}

interface IPerson {
  name?: string;
  email?: string;
  phone?: string;
  other: string;
}

type radioP = "Yes" | "No";

export class TrainingRegisterViewComponent extends React.Component<IRegisterProps, IRegisterState> {
  constructor(props: IRegisterProps) {
    super(props);

    this.state = {
      loading: false,
      user: undefined,
    };

    this.renderMyself = this.renderMyself.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  public readonly getUser = async () => {
    const user: IAccountUser = (await getUserProfile()) as IAccountUser;
    this.setState({ user });
  };

  public async componentWillMount() {
    await this.getUser();
  }

  public readonly registerParticipants = async (trainingId: string, participants: IPerson[]) => {
    const toastId = displayProgress(translateString("trainings.status.sending"));
    try {
      await new APIFetch(`service/trainings/${trainingId}`).patch(participants);

      dismissMessage(toastId);
      displaySuccess(translateString("trainings.success.message"), translateString("trainings.success.title"));
    } catch (error) {
      dismissMessage(toastId);
      displayError(
        `Error while registering participants: ${(error as Error).message}`,
        undefined,
        undefined,
        error.awsRequestId
      );
    }
  };

  public render() {
    if (this.state.user) {
      let initialValues: IFormProps;
      initialValues = {
        isRegisterMySelf: "Yes",
        people: [
          {
            name: `${this.state.user.firstName} ${this.state.user.lastName}`,
            email: this.state.user.email ? this.state.user.email : "-",
            phone: this.state.user.phone ? this.state.user.phone : "-",
            other: "",
          },
          {
            name: "",
            email: "",
            phone: "",
            other: "",
          },
        ],
      };

      return (
        <s.Wrapper>
          <PageSection>
            <Heading text={translateString("trainings.participant.info")} />
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, actions) => {
                return await this.onFormSubmit(values, actions);
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <Form>
                  {/* Show radio buttons and user info if isRegisterMySelf = "Yes" */}
                  <RadioChoice
                    name="registersomeone"
                    selectedOptionId={values.isRegisterMySelf}
                    onOptionSelect={(option: IRadioChoiceOption) => {
                      return setFieldValue("isRegisterMySelf", option.id);
                    }}
                    options={[
                      { id: "Yes", label: translateString("trainings.register.myself") },
                      { id: "No", label: translateString("trainings.register.colleauge") },
                    ]}
                    horizontal={true}
                  />
                  {values.isRegisterMySelf === "Yes" && this.renderMyself(values)}
                  {values.isRegisterMySelf === "No" && this.renderEmptyColleague(1)}
                  <FieldArray name="people">
                    {({ push, remove }) => (
                      <React.Fragment>
                        {values.people &&
                          values.people.length > 0 &&
                          values.people.slice(2).map((person, index) => {
                            const i = index + 2; // first two values are constants
                            return (
                              <div key={i}>
                                <GrowH2 style={{ color: `${colors.primary.white}` }}>
                                  <s.ParticipantHeader>
                                    {i}. <LocalizedStringComponent id="trainings.participant" />
                                    {/* Remove colleague button */}
                                    <s.ButtonWrap onClick={() => remove(i)}>
                                      <s.Minus size={IconSize.Medium} color={colors.primary.white} />
                                    </s.ButtonWrap>
                                  </s.ParticipantHeader>
                                </GrowH2>
                                {this.renderEmptyColleague(i)}
                              </div>
                            );
                          })}
                        {/* Add new colleague button */}
                        <Container $marginTop={4}>
                          <s.ButtonWrap onClick={() => push({ name: "", email: "", phone: "", other: "" })}>
                            <s.Plus size={IconSize.Large} color={colors.primary.white} />
                            <LocalizedStringComponent id="trainings.participant.add" />
                          </s.ButtonWrap>
                        </Container>
                      </React.Fragment>
                    )}
                  </FieldArray>

                  {/* Form footer buttons (Cancel/Send) */}
                  <ButtonsWrapper>
                    <Button buttonStyle={ButtonStyle.Secondary} onClick={() => redirectBrowser(getRoutes().Trainings)}>
                      <LocalizedString id="cancel" />
                    </Button>
                    <Button type="submit" buttonStyle={ButtonStyle.Next} disabled={isSubmitting}>
                      <LocalizedString id="trainings.send" />
                    </Button>
                  </ButtonsWrapper>
                </Form>
              )}
            </Formik>
          </PageSection>
        </s.Wrapper>
      );
    } else {
      return <LoadingSpinner />;
    }
  }

  private validateName(value: string) {
    let error;
    if (!value) {
      error = translateString("trainings.required");
    }
    return error;
  }

  private validateEmail(value: string) {
    const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let error;
    if (!value) {
      error = translateString("trainings.required");
    } else if (!emailRegExp.test(value)) {
      error = translateString("trainings.invalid.email");
    }
    return error;
  }

  private validatePhone(value: string) {
    const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/g;
    let error;
    if (!value) {
      error = translateString("trainings.required");
    } else if (!phoneRegExp.test(value)) {
      error = translateString("trainings.invalid.phone");
    }
    return error;
  }

  private renderEmptyColleague(index: number) {
    return (
      <div>
        <s.FieldsWrap>
          <s.Label>
            <LocalizedStringComponent id="trainings.participant.name" />
          </s.Label>
          <Field
            name={`people[${index}].name`}
            autoHeight={true}
            rows={2}
            component={TextInput}
            validate={this.validateName}
          />
          <s.Label>
            <LocalizedStringComponent id="trainings.participant.email" />
          </s.Label>
          <Field
            name={`people[${index}].email`}
            autoHeight={true}
            rows={2}
            component={TextInput}
            validate={this.validateEmail}
          />
          <s.Label>
            <LocalizedStringComponent id="trainings.participant.phone" />
          </s.Label>
          <Field
            name={`people[${index}].phone`}
            autoHeight={true}
            rows={2}
            component={TextInput}
            validate={this.validatePhone}
          />
          <s.Label>
            <LocalizedStringComponent id="trainings.participant.other" />
          </s.Label>
          <Field name={`people[${index}].other`} autoHeight={true} rows={2} component={TextArea} />
        </s.FieldsWrap>
        <DividerLine $color={DividerColor.Gray} />
      </div>
    );
  }

  private renderMyself(values: IFormProps) {
    return (
      <div>
        <s.FieldsWrap>
          <SummaryItem label={translateString("trainings.participant.name")} textValue={values.people[0].name} />
          <SummaryItem label={translateString("trainings.participant.email")} textValue={values.people[0].email} />
          <SummaryItem label={translateString("trainings.participant.phone")} textValue={values.people[0].phone} />
          <s.Label>
            <LocalizedStringComponent id="trainings.participant.other" />
          </s.Label>
          <Field name="people[0].other" autoHeight={true} rows={2} component={TextArea} />
        </s.FieldsWrap>
        <DividerLine $color={DividerColor.Gray} />
      </div>
    );
  }

  private async onFormSubmit(values: IFormProps, actions: FormikHelpers<IFormProps>) {
    // when user is registering himself, then remove the value of empty colleague,
    // else remove user data
    if (values.isRegisterMySelf === "Yes") {
      values.people.splice(1, 1);
    } else {
      values.people.splice(0, 1);
    }
    await this.registerParticipants(this.props.location.state.trainingId, values.people);
    actions.setSubmitting(false);
    actions.resetForm();
    redirectBrowser(getRoutes().Trainings);
  }
}
