import { Field } from "formik";
import * as React from "react";

import { FormDialog } from "src/components/Dialog";
import { TextArea } from "src/design-system/TextArea";
import { FormStyleProvider, FormTheme } from "src/design-system/Theme/form";
import { translateString } from "src/util/localization";
import { IErrors } from "src/util/validators";

export interface IReopenDialogProps {
  isOpen: boolean;
  onApprove(data: IReopenFormFields): void;
  onCancel(): void;
}

export interface IReopenFormFields {
  reason: string;
}

export class ReopenDialogComponent extends React.Component<IReopenDialogProps> {
  constructor(props: IReopenDialogProps) {
    super(props);
    this.state = {
      rating: null,
    };
  }

  public validate = (values: IReopenFormFields): IErrors => {
    const errors: IErrors = {};

    if (values.reason.trim().length === 0) {
      errors.reason = translateString("request.rating.reasonIsRequired");
    }

    return errors;
  };

  public render() {
    return (
      <FormDialog<IReopenFormFields>
        title={translateString("request.rating.reopenDialogTitle")}
        initialValues={{ reason: "" }}
        validate={this.validate}
        isDialogOpen={this.props.isOpen}
        submitButtonTitle={translateString("request.rating.reopenRequest")}
        onCancel={this.props.onCancel}
        onSubmit={this.props.onApprove}
      >
        <FormStyleProvider theme={FormTheme.Dark}>
          <Field
            name="reason"
            label={translateString("request.rating.reasonForReopening")}
            component={TextArea}
            placeholder={translateString("request.rating.reasonPlaceholder")}
          />
        </FormStyleProvider>
      </FormDialog>
    );
  }
}
