import * as React from "react";
import { slide as Menu, State } from "react-burger-menu";
import { outerContainerId, pageWrapId } from "src/components/UserPrefs/containerIds";
import { ThemedProps } from "src/design-system/Theme/theme";
import { whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";
import styled from "styled-components";

interface IMenuWrapperProps {
  background?: string;
  hideCloseButton?: boolean;
}

export interface ISidebarProps extends IMenuWrapperProps {
  isOpen: boolean;
  onMenuStateChange(state: State): void;
  children: React.ReactNode;
  closeIcon?: JSX.Element;
}

const MenuWrapper = styled.div<IMenuWrapperProps & ThemedProps>`
  & .bm-menu-wrap {
    width: 300px;
    height: 100%;

    ${whenTabletOrLarger(`
      width: 28em;
    `)}
  }

  & .bm-item-list {
    background: ${(props) => props.background || props.theme.background};
  }
  
  & .bm-cross-button {
    ${({hideCloseButton}) => hideCloseButton ? "display: none;" : ""}
  }
  
  & .bm-menu {
    & * {
      box-sizing: border-box;
    }
  }
`;

// Avoid the use of !important by resetting the default burger menu width
const menuStylesWidthOverride = {
  bmBurgerBars: {},
  bmBurgerButton: {},
  bmCross: {},
  bmCrossButton: {},
  bmItemList: {},
  bmMenuWrap: { width: "" },
  bmMenu: {},
  bmMorphShape: {},
  bmOverlay: {},
};

export class Sidebar extends React.Component<ISidebarProps> {
  public render() {
    return (
      <MenuWrapper background={this.props.background} hideCloseButton={this.props.hideCloseButton}>
        <Menu
          burgerButtonClassName="burgerButton"
          isOpen={this.props.isOpen}
          outerContainerId={outerContainerId}
          pageWrapId={pageWrapId}
          noOverlay={false}
          onStateChange={this.props.onMenuStateChange}
          right={true}
          customBurgerIcon={false}
          styles={menuStylesWidthOverride}
          customCrossIcon={this.props.closeIcon}
        >
          {this.props.children}
        </Menu>
      </MenuWrapper>
    );
  }
}
