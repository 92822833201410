import { CometD } from "cometd";
import { sortBy } from "lodash";
import moment from "moment";
import { IChat, IChatMessage, IChatMessagesResponse, Service } from "online-services-types";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { State as BurgerMenuState } from "react-burger-menu";
import { APIFetch } from "src/APIFetch";
import {
  AttachmentUploaderComponent,
  IUploadedFile,
  QueueStatus
} from "src/components/AttachmentUploader/AttachmentUploaderComponent";
import { CommentRow } from "src/components/Comments/CommentsComponent";
import { DialogHeader } from "src/components/Dialog/Dialog";
import { Modal } from "src/components/Dialog/Modal";
import { Loader, LoadingSpinner } from "src/components/LoadingSpinner";
import { spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { Sidebar } from "src/components/SideMenu";
import { Button } from "src/design-system/Button";
import { Container, FlexContainer } from "src/design-system/Container";
import { themes } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import {
  AttachmentIcon,
  ChevronLeftIcon,
  IconSize,
  MeetingIcon,
  ScaleDownIcon,
  ScaleUpIcon,
  SendIcon
} from "src/icons";
import { IUserState } from "src/redux/user";
import { ChatEvent, ChatMessageWithPending, ChatWindowMode, SubscriptionMessage } from "src/services/atrs/atrs.types";
import { formatDateTime } from "src/util/formatters";
import { translateString } from "src/util/localization";
import { ThemeProvider } from "styled-components";
import {
  ChatBoxWrapper,
  ChatButtons,
  ChatContainer,
  ChatInfoMessage,
  ChatMessagesContainer,
  ChatTextArea,
  DialogContentWrapper,
  IconButton,
  IndicatorBubble,
  MenuToggle,
  StyledContainer
} from "./atrs.styles";
import {
  closeChat,
  getChatAttachmentInfoFromJson,
  getChatConfiguration,
  getNextTempId,
  groupTranscriptsByCase,
  isChatOpen,
  isMessageMatch,
  isSessionOpen,
  PremiumSupportContext,
  usePremiumSupport
} from "./atrs.utils";

interface IPremiumSupportProviderProps {
  userInfo: IUserState;
  services: ReadonlyArray<Service>;
  children: ReactNode;
}

export const PremiumSupportProvider = ({ userInfo, services, children }: IPremiumSupportProviderProps) => {
  const { userId } = userInfo;
  const [showChatButton, setShowChatButton] = useState<boolean>(false);
  const [isChatAppOpen, setIsChatAppOpen] = useState<boolean>(false);
  const [messages, setMessages] = useState<ChatMessageWithPending[]>([]);
  const [chats, setChats] = useState<IChat[]>([]);
  const [selectedChat, setSelectedChat] = useState<IChat | null>(null);
  const [currentTranscriptId, setCurrentTranscriptId] = useState<string | null>(null);
  const [isLoadingChats, setIsLoadingChats] = useState<boolean>(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);

  const fetchChats = async (silentLoad: boolean, onChatsLoaded?: (chats: IChat[]) => void) => {
    setIsLoadingChats(!silentLoad);
    try {
      const chatResponse = await new APIFetch<IChat>(`requests/transcripts`).get();
      const groupedChats = groupTranscriptsByCase(chatResponse?.transcripts || []);

      if (groupedChats && groupedChats.length > 0) {
        setShowChatButton(true);
        setChats(groupedChats);
        onChatsLoaded?.(groupedChats);
      } else {
        setShowChatButton(false);
      }
    } catch (e) {
      setShowChatButton(false);
      setIsChatAppOpen(false);
    } finally {
      setIsLoadingChats(false);
    }
  };

  useEffect(() => {
    if (services.includes("TechRequest") && services.includes("ATRS")) {
      fetchChats(false, (chats) => {
        const queryParams = Object.fromEntries(new URLSearchParams(window.location.search.replace(new RegExp("^[?]"), "")));
        if (queryParams.chat) {
          const preselectedChat = chats.find((chat) => chat.caseId === queryParams.chat);
          if (preselectedChat) {
            setSelectedChat(preselectedChat);
            setIsChatAppOpen(true);
          }
        }
      });
    } else {
      setShowChatButton(false);
      setIsChatAppOpen(false);
    }
  }, [services]);

  useEffect(() => {
    if (isChatAppOpen && !selectedChat && !isLoadingChats) {
      fetchChats(true);
    }
  }, [isChatAppOpen, selectedChat, isLoadingChats]);

  useEffect(() => {
    let cometd: CometD;
    if (showChatButton && selectedChat !== null && currentTranscriptId != null) {
      cometd = new CometD();
      cometd.configure(getChatConfiguration());
      cometd.websocketEnabled = false;
      cometd.handshake((handshake) => {
        if (handshake.successful) {
          cometd.subscribe('/event/Chat_Event__e', (eventMessage: SubscriptionMessage<ChatEvent>) => {
            const event = eventMessage?.data?.payload;
            if (event?.Key__c === selectedChat.caseId) {
              if (event.Event_Type__c === "END_CHAT") {
                const closedChat: IChat = closeChat(selectedChat);
                setChats((prev) => prev.map((prevChat) => prevChat.caseId === closedChat.caseId ? closedChat : prevChat));
                setCurrentTranscriptId(null);
              } else if (event.Chat_Message_Id__c) {
                new APIFetch<IChatMessagesResponse>(`requests/transcripts/${currentTranscriptId}/messages`)
                  .get(event.Chat_Message_Id__c)
                  .then((response) => addMessage(response?.messages?.data?.[0]));
              }
            }
          });
        }
      });
    }
    return () => {
      if (cometd) {
        cometd.disconnect();
      }
    };
  }, [showChatButton, selectedChat, currentTranscriptId]);

  useEffect(() => {
    setMessages([]);
    if (selectedChat !== null) {
      (async () => {
        setIsLoadingMessages(true);
        setCurrentTranscriptId(null);
        const chatResponse = await new APIFetch<IChat>("requests/transcripts").get(selectedChat.caseId);
        if (chatResponse?.transcripts) {
          const openTranscript = chatResponse.transcripts.find(isSessionOpen) || null;
          setCurrentTranscriptId(openTranscript?.id || null);
          setChats((prev) => prev.map((p) => p.caseId === chatResponse.caseId ? chatResponse : p));

          const messageRequests = sortBy(chatResponse.transcripts, "createdDate")
            .map((transcript) => new APIFetch<IChatMessagesResponse>(`requests/transcripts/${transcript.id}/messages`).get());
          const messageResponses = await Promise.all(messageRequests);
          setMessages(messageResponses.filter((res) => res).reduce((acc, curr) => {
            return [...acc, ...curr?.messages?.data];
          }, [] as IChatMessage[]));
        }
        setIsLoadingMessages(false);
      })();
    }
  }, [selectedChat]);

  const sendMessage = async (text: string) => {
    if (currentTranscriptId) {
      const pendingId = getNextTempId();
      // Show message in chat before it was submitted to make it feel more responsive
      setMessages((prev) => [...prev, {
        id: "",
        transcriptId: currentTranscriptId,
        messageType: "Text",
        content: text,
        createdDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        commenterName: translateString("comment.you"),
        commenterId: userId,
        isPending: true,
        pendingId,
      } as ChatMessageWithPending]);
      const response = await new APIFetch<Partial<IChatMessage>[], IChatMessagesResponse>("requests/transcripts", "messages")
        .post([{
          transcriptId: currentTranscriptId,
          messageType: "Text",
          content: text,
        }], currentTranscriptId);
      addMessage(response?.messages?.data?.[0], pendingId);
    }
  };

  const attachFile = async (file: IUploadedFile) => {
    if (currentTranscriptId) {
      // Show file in chat before it was submitted to make it feel more responsive
      setMessages((prev) => [...prev, {
        id: file.id,
        transcriptId: currentTranscriptId,
        messageType: "Attachment",
        content: JSON.stringify({ name: file.name, contentVersionId: file.id }),
        createdDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        commenterName: translateString("comment.you"),
        commenterId: userId,
        isPending: true,
      } as ChatMessageWithPending]);
      await new APIFetch<string[]>("requests/transcripts", "files")
        .post([file.id], currentTranscriptId);
    }
  };

  const deleteFile = async (fileId: string) => {
    if (currentTranscriptId) {
      await new APIFetch(`requests/transcripts/${currentTranscriptId}/files`).delete(fileId);
    }
  };

  const addMessage = (newMessage?: ChatMessageWithPending, pendingId?: number) => {
    if (newMessage) {
      setMessages((prev) => {
        const index = prev.findIndex((prevMessage) => isMessageMatch(prevMessage, newMessage, pendingId));
        return index >= 0 ?
          prev.map((prevMessage, idx) => idx === index ? newMessage : prevMessage) :
          [...prev, newMessage];
      });
    }
  };

  return (
    <PremiumSupportContext.Provider
      value={{
        userId,
        messages,
        sendMessage,
        attachFile,
        deleteFile,
        reloadChats: () => fetchChats(true),
        showChatButton,
        isChatAppOpen,
        setIsChatAppOpen,
        chats,
        selectedChat,
        setSelectedChat,
        isLoadingChats,
        isLoadingMessages,
        isChatOpen: currentTranscriptId !== null,
      }}
    >
      {children}
    </PremiumSupportContext.Provider>
  );
};

export const ChatButton = () => {
  const { showChatButton, chats, setIsChatAppOpen } = usePremiumSupport();
  const handleChatClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setIsChatAppOpen((prev) => !prev);
  };

  if (!showChatButton) return null;
  return (
    <Container $margin={[0, 1]}>
      <MenuToggle onClick={handleChatClick}>
        <IndicatorBubble showIndicator={chats.some(isChatOpen)}>
          <MeetingIcon size={IconSize.Medium} />
        </IndicatorBubble>
      </MenuToggle>
    </Container>
  );
};

export const OpenChatButton = ({ caseId }: { caseId: string }) => {
  const { showChatButton, chats, setIsChatAppOpen, setSelectedChat } = usePremiumSupport();
  const chatForRequest = chats.find((chat) => chat.caseId === caseId);

  const handleOpenChat = () => {
    if (chatForRequest) {
      setSelectedChat(chatForRequest);
      setIsChatAppOpen(true);
    }
  };

  if (!showChatButton || !chatForRequest) return null;
  return (
    <Button onClick={handleOpenChat}>
      {translateString(isChatOpen(chatForRequest) ? "request.premium.openChat" : "request.premium.viewTranscript")}
    </Button>
  );
};

export const ChatReloader = () => {
  const { reloadChats } = usePremiumSupport();
  useEffect(() => {
    reloadChats();
  }, []);
  return null;
};

export const ChatWindow = () => {
  const { isChatAppOpen, setIsChatAppOpen, selectedChat } = usePremiumSupport();
  const [mode, setMode] = useState<ChatWindowMode>("sidebar");

  const onMenuStateChange = useCallback(({ isOpen }: BurgerMenuState) => setIsChatAppOpen(isOpen), []);

  useEffect(() => {
    if (!isChatAppOpen) {
      setMode("sidebar");
    }
  }, [isChatAppOpen]);

  const toggleMode = () => {
    setMode((prev) => prev === "sidebar" ? "dialog" : "sidebar");
  };

  const onCancel = () => setIsChatAppOpen(false);

  const renderContent = () => selectedChat === null ? (
    <ChatList />
  ) : (
    <Chat mode={mode} toggleMode={toggleMode} />
  );

  const chatTitle = translateString("request.premium.premiumSupportChat");
  return (
    <>
      {mode === "sidebar" && (
        <Sidebar
          isOpen={isChatAppOpen}
          onMenuStateChange={onMenuStateChange}
          background={colors.primary.white}
          hideCloseButton
        >
          <ThemeProvider theme={themes.light}>
            <StyledContainer $column $padding={[2, 3]} style={{ height: "100%" }}>
              <DialogHeader title={chatTitle} onCancel={onCancel} />
              {renderContent()}
            </StyledContainer>
          </ThemeProvider>
        </Sidebar>
      )}

      {mode === "dialog" && (
        <Modal
          isOpen={isChatAppOpen}
          width="80%"
          height="80%"
          overflowType="none"
        >
          <DialogHeader title={chatTitle} onCancel={onCancel} />
          <DialogContentWrapper>
            {renderContent()}
          </DialogContentWrapper>
        </Modal>
      )}
    </>
  );
};

const ChatList = () => {
  const { chats, setSelectedChat, isLoadingChats } = usePremiumSupport();
  const [seeAll, setSeeAll] = useState<boolean>(false);

  const openSessions = chats.filter((chat) => isChatOpen(chat));
  const closedSessions = chats.filter((chat) => !isChatOpen(chat));

  return !isLoadingChats ? (
    <Container style={{ overflow: "auto" }}>
      {openSessions.length > 0 && (
        <Container $margin={[3, 0]}>
          <LocalizedString id="request.premium.openSessions" />
          {openSessions.map((chat, key) => (
            <IconButton key={key} onClick={() => setSelectedChat(chat)}>{chat.caseNumber}</IconButton>
          ))}
        </Container>
      )}

      {closedSessions.length > 0 && (
        <Container $margin={[3, 0]}>
          <LocalizedString id="request.premium.closedSessions" />
          {(seeAll ? closedSessions : closedSessions.slice(0, 2)).map((chat, key) => (
            <IconButton key={key} onClick={() => setSelectedChat(chat)}>{chat.caseNumber}</IconButton>
          ))}
          {(!seeAll && closedSessions.length > 2) && (
            <IconButton smallText onClick={() => setSeeAll(true)}>
              <LocalizedString id="request.premium.seeAll" />
            </IconButton>
          )}
        </Container>
      )}
    </Container>
  ) : <LoadingSpinner dark disableText />;
};

interface IChatProps {
  mode: ChatWindowMode;
  toggleMode: () => void;
}

const Chat = ({ mode, toggleMode }: IChatProps) => {
  const { userId, messages, selectedChat, setSelectedChat, isLoadingMessages, isChatOpen, deleteFile } = usePremiumSupport();
  return (
    <ChatContainer>
      <FlexContainer $spaceBetween>
        <IconButton onClick={() => setSelectedChat(null)}>
          <ChevronLeftIcon size={IconSize.Base} color={colors.primary.blue} /> {selectedChat?.caseNumber}
        </IconButton>
        <IconButton onClick={toggleMode}>
          {mode === "sidebar" && (
            <ScaleUpIcon size={IconSize.Base} color={colors.primary.black} />
          )}
          {mode === "dialog" && (
            <ScaleDownIcon size={IconSize.Base} color={colors.primary.black} />
          )}
        </IconButton>
      </FlexContainer>

      <ChatMessagesContainer>
        {!isLoadingMessages ? messages.map((message, key) => {
          const nameAndDate = `${message.commenterId === userId ? translateString("comment.you") : message.commenterName} ${formatDateTime(message.createdDate)}`;
          const isPending = message.isPending || false;
          const isSelf = message.commenterId === userId;
          const isInternal = message.isInternalComment;
          switch (message.messageType) {
            case "Text":
              return (
                <CommentRow
                  key={message.id + key}
                  title={nameAndDate}
                  text={message.content}
                  transient={isPending}
                  isSelf={isSelf}
                  isInternal={isInternal}
                />
              );
            case "Attachment":
              const attachment = getChatAttachmentInfoFromJson(message.content);
              if (!attachment) return null;
              return (
                <CommentRow
                  key={message.id + key}
                  title={nameAndDate}
                  text={attachment.name}
                  fileId={attachment.contentVersionId}
                  transient={isPending}
                  isSelf={isSelf}
                  isInternal={isInternal}
                  onDeleteFile={() => deleteFile(attachment?.documentId)}
                />
              );
            case "Attachment Deleted":
              return (
                <CommentRow
                  key={message.id + key}
                  title={nameAndDate}
                  text={translateString("request.premium.attachmentDeleted")}
                  transient={isPending}
                  isSelf={isSelf}
                  isInternal={isInternal}
                  isStatus
                />
              );
            default:
              return (
                <ChatInfoMessage key={message.id + key}>
                  {message.content}
                </ChatInfoMessage>
              );
          }

        }).reverse() : <LoadingSpinner dark disableText />}
      </ChatMessagesContainer>

      {!isLoadingMessages && selectedChat && (
        <Container>
          {isChatOpen ?
            <ChatInput /> :
            <ChatInfoMessage><LocalizedString id="request.premium.chatIsClosed" /></ChatInfoMessage>
          }
        </Container>
      )}
    </ChatContainer>
  );
};

const UPLOAD_STOPPED_STATUSES = [QueueStatus.Success, QueueStatus.Failed];
const ChatInput = () => {
  const { sendMessage, attachFile } = usePremiumSupport();
  const [value, setValue] = useState<string>("");

  const send = () => {
    if (value) {
      sendMessage(value);
      setValue("");
    }
  };

  const onUploaded = (file: IUploadedFile) => {
    attachFile(file);
  };

  const sendOnEnter = (event: React.KeyboardEvent) => {
    // Ensures you can still create a new line with Shift + Enter
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      send();
    }
  };

  return (
    <ChatBoxWrapper>
      <AttachmentUploaderComponent
        onFilesChanged={() => undefined}
        onFileUploaded={onUploaded}
        renderer={({ getRootProps, getInputProps, queue }) => (
          <>
            <ChatTextArea
              onKeyPress={sendOnEnter}
              value={value}
              placeholder={translateString("request.premium.typeMessage")}
              onChange={(e) => setValue(e.target.value)}
            />
            <ChatButtons>
              {queue.every((file) => UPLOAD_STOPPED_STATUSES.includes(file.status)) ? (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <IconButton>
                    <AttachmentIcon size={IconSize.Base} color={colors.primary.blue} />
                  </IconButton>
                </div>
              ) : <Loader dark size={spinnerSize.sm} />}
              <IconButton onClick={send} disabled={!value}>
                <SendIcon size={IconSize.Base} color={Boolean(value) ? colors.primary.blue : colors.primary.gray} />
              </IconButton>
            </ChatButtons>
          </>
        )}
      />
    </ChatBoxWrapper>
  );
};