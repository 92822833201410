import * as React from "react";

import { Container, FlexContainer } from "src/design-system/Container";
import { IEquipment, ILineItem, INotification, IPartModel, IPurchase, ISalesDocumentLine } from "online-services-types";
import { ISalesLineItem, itemTypes } from "src/components/CommerceList/types";
import {
  attachmentMetadata,
  availableAttachmentsBubble,
  availableCommentBubble,
  classificationAttachment,
  commentMetadata,
  deliveredMetadata,
  dispatchDateMetadata,
  etaDateMetadata,
  icons,
  idMetadata,
  itemNoteMetadata,
  leadTextMetadata,
  lineNumber,
  markingsMetadata,
  partDescriptionMetadata,
  partWeightMetadata,
  quantityMetadata,
  salesDocColumns,
  salesDocColumnsWPrice,
  serialNumberMetadata,
  sfPriceMetadata,
  sizeAndDimensionsMetadata,
  usageTargetMetadata,
  vendorCodeMetadata,
} from "../PartsCatalogueList/PartsMetadata";

import { BasketIcon } from "src/icons/BasketIcon";
import { Checkbox } from "src/design-system/Checkbox";
import { H3 } from "src/design-system/Tokens/typography";
import { Heading } from "src/components/Heading";
import { IExtendedPartAvailability } from "src/redux/partsAvailability";
import { IconSize } from "src/design-system/Tokens/icons";
import { NotificationType } from "src/models/notifications";
import { QueueStatus } from "src/components/AttachmentUploader";
import { ResponsiveTree } from "./ResponsiveTree";
import { ResponsiveTreeSF } from "./ResponsiveTreeSF";
import { StyledHeader } from "../GenericList/ExpandableHeader";
import colors from "src/design-system/Tokens/colors";
import { formatCurrency } from "src/util/formatters";
import { getCurrencySymbol } from "src/util/currencyMapper";
import styled from "styled-components";
import { translateString } from "src/util/localization";

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const H3BlackText = styled(H3)`
  color: ${colors.primary.black};
  text-transform: initial;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${H3BlackText} {
    margin-left: 10px;
  }
`;

const DeliveriesVisibilityDisclaimer = styled.span`
  color ${colors.secondary.gray70};
`;

const REGULAR_ITEM_PARENT = "000000";

export const ResponsiveItems: React.FunctionComponent<{
  header: IPurchase;
  parts?: ILineItem[];
  items?: ISalesDocumentLine[];
  equipments: IEquipment[];
  hidePrices?: boolean;
  rowType?: string;
  rowStatus: string;
  hideAvailability?: boolean;
  partsAvailability?: IExtendedPartAvailability[];
  notifications: INotification<{ sfId?: string }>[];
  isMarine?: boolean;
}> = ({
  header,
  parts,
  items,
  equipments,
  hidePrices,
  rowType,
  rowStatus,
  hideAvailability = true,
  partsAvailability,
  notifications,
  isMarine,
}) => {
  const [showOpenLinesOnly, setShowOpenLinesOnly] = React.useState(false);
  const getSfColumns = (purchase: IPurchase) => {
    const orderClassificationNotifications = notifications.filter(
      (n) => n.type === NotificationType.SalesOrderItemClassificationAvailable
    );

    const readOnlyAttachments = attachmentMetadata(
      0,
      () => undefined,
      (row: ILineItem) => (row.attachments || []).map((attach) => ({ ...attach, status: QueueStatus.Success })),
      true,
      true
    );

    const isOrder: boolean = purchase.type === "order";
    if (isOrder && purchase.deliveryPlant && ["GLS1", "GLS2", "GLS3"].includes(purchase.deliveryPlant)) {
      if ((purchase.incoterms ?? "").startsWith("FCA")) {
        return [
          lineNumber,
          icons(purchase.type),
          partDescriptionMetadata(purchase.type),
          idMetadata(purchase.type),
          quantityMetadata,
          availableAttachmentsBubble,
          availableCommentBubble,
          leadTextMetadata(equipments, partsAvailability, rowType, rowStatus),
          dispatchDateMetadata(
            true,
            notifications.filter((notif) => notif.type === NotificationType.SalesOrderCollectionDateChanged)
          ),
          partWeightMetadata(purchase.type),
          sfPriceMetadata(purchase.type, rowStatus),
          deliveredMetadata(isOrder),
          classificationAttachment(purchase.wartsilaReference, orderClassificationNotifications),
          serialNumberMetadata,
          vendorCodeMetadata,
          markingsMetadata,
          sizeAndDimensionsMetadata,
          usageTargetMetadata,
          readOnlyAttachments,
          commentMetadata(),
          itemNoteMetadata(purchase.type),
          {
            priority: 1,
            key: "hidden",
            plannedWidth: 1,
            renderer: () => <div />,
          },
        ]
          .filter((col) => (hideAvailability ? col.key !== "leadText" : true))
          .filter((col) => (col.key === "cceDescription" ? purchase.type === itemTypes.order && isMarine : true));
      }
      return [
        lineNumber,
        icons(purchase.type),
        partDescriptionMetadata(purchase.type),
        idMetadata(purchase.type),
        quantityMetadata,
        availableAttachmentsBubble,
        availableCommentBubble,
        leadTextMetadata(equipments, partsAvailability, rowType, rowStatus),
        dispatchDateMetadata(false),
        etaDateMetadata(notifications.filter((notif) => notif.type === NotificationType.SalesOrderEtaDateChanged)),
        partWeightMetadata(purchase.type),
        sfPriceMetadata(purchase.type, rowStatus),
        deliveredMetadata(isOrder),
        classificationAttachment(purchase.wartsilaReference, orderClassificationNotifications),
        serialNumberMetadata,
        vendorCodeMetadata,
        markingsMetadata,
        sizeAndDimensionsMetadata,
        usageTargetMetadata,
        readOnlyAttachments,
        commentMetadata(),
        itemNoteMetadata(purchase.type),
        {
          priority: 1,
          key: "hidden",
          plannedWidth: 1,
          renderer: () => <div />,
        },
      ]
        .filter((col) => (hideAvailability ? col.key !== "leadText" : true))
        .filter((col) => (col.key === "cceDescription" ? purchase.type === itemTypes.order && isMarine : true));
    }
    return [
      lineNumber,
      icons(purchase.type),
      partDescriptionMetadata(purchase.type),
      idMetadata(purchase.type),
      partWeightMetadata(purchase.type),
      quantityMetadata,
      availableAttachmentsBubble,
      availableCommentBubble,
      leadTextMetadata(equipments, partsAvailability, rowType, rowStatus),
      sfPriceMetadata(purchase.type, rowStatus),
      deliveredMetadata(isOrder),
      classificationAttachment(purchase.wartsilaReference, orderClassificationNotifications),
      serialNumberMetadata,
      vendorCodeMetadata,
      markingsMetadata,
      sizeAndDimensionsMetadata,
      usageTargetMetadata,
      readOnlyAttachments,
      commentMetadata(),
      itemNoteMetadata(purchase.type),
      {
        priority: 1,
        key: "hidden",
        plannedWidth: 1,
        renderer: () => <div />,
      },
    ]
      .filter((col) => (hideAvailability ? col.key !== "leadText" : true))
      .filter((col) => (col.key === "cceDescription" ? purchase.type === itemTypes.order && isMarine : true));
  };

  let totalPriceElem = null;
  let columnsForSales = salesDocColumns(header);

  if (!hidePrices && parts !== undefined) {
    columnsForSales = salesDocColumnsWPrice(header);
    const currencyItem = parts.find((item) => item.currencyCode !== "undefined");
    const currency = currencyItem ? currencyItem.currencyCode : "EUR";
    const totalPrice = parts
      .filter((item) => item.parentRow === REGULAR_ITEM_PARENT)
      .reduce((total, item) => total + Number.parseFloat(item.totalPrice ? item.totalPrice : "0"), 0);
    totalPriceElem =
      totalPrice !== 0 ? (
        <span>
          {translateString("spareParts.goodsTotal")}: {formatCurrency(Math.round(totalPrice * 100) / 100)}{" "}
          {getCurrencySymbol(currency)}
        </span>
      ) : null;
  }

  let totalDeliveryLines: number = 0;
  let completeDeliveries: IPartModel[] = [];

  const renderItems = () => {
    if (parts !== undefined && items === undefined) {
      const groupedParts: { [key: string]: IPartModel[] } = {};

      parts.forEach((part: ILineItem) => {
        if (!Object.keys(groupedParts).includes(part.equipmentId)) {
          groupedParts[part.equipmentId] = [];
        }

        groupedParts[part.equipmentId].push({
          // Fallback to empty values if fields don't exist
          // Happens when line items come from SF (Draft).
          ...part,
          qtySection: part.qtySection ?? 0,
          sectionId: part.sectionId ?? "",
          frontendKey: part.frontendKey ?? part.lineNumber.toString(),
        });
      });

      return Object.keys(groupedParts).map((equipmentId) => {
        const equipment = equipments.find((eq) => eq.id === equipmentId);
        let equipmentHeader;

        if (equipment) {
          equipmentHeader = equipment.nickName ? equipment.nickName : equipment.description;
        } else {
          equipmentHeader = `${translateString("spareParts.productId")} ${equipmentId}`;
        }

        const items = showOpenLinesOnly
          ? groupedParts[equipmentId].filter((part: ILineItem) => part.deliveredQuantity !== part.quantity)
          : groupedParts[equipmentId];

        totalDeliveryLines = groupedParts[equipmentId]?.length;
        completeDeliveries = groupedParts[equipmentId]?.filter(
          (row: ILineItem) => row.deliveredQuantity && row.deliveredQuantity === row.quantity
        );

        return (
          <div key={equipmentId}>
            <StyledHeader>{equipmentHeader}</StyledHeader>
            <ResponsiveTreeSF purchase={header} columns={getSfColumns(header)} items={items} />
          </div>
        );
      });
    }

    const groupedItems: { [key: string]: ISalesLineItem[] } =
      items !== undefined
        ? items.reduce((coll, item, idx) => {
            if (!Object.keys(coll).includes(item.equipmentId)) {
              coll[item.equipmentId] = [];
            }
            coll[item.equipmentId].push({ ...item, id: `${idx}` });
            return coll;
          }, {})
        : {};
    return Object.keys(groupedItems).map((equipmentId) => {
      const equipment = equipments.find((eq) => eq.id === equipmentId);
      let equipmentHeader;
      if (equipment) {
        equipmentHeader = equipment.nickName ? equipment.nickName : equipment.description;
      } else {
        equipmentHeader = `${translateString("spareParts.productId")} ${equipmentId}`;
      }
      const currentItems = groupedItems[equipmentId];

      return (
        <div key={equipmentId}>
          <StyledHeader>{equipmentHeader}</StyledHeader>
          <ResponsiveTree purchase={header} items={currentItems} columns={columnsForSales} />
        </div>
      );
    });
  };

  const itemsToRender = renderItems();

  if (itemsToRender.length === 0) {
    return <Container $margin={[2, 0]}>{translateString("spareParts.noItemsAvailable")}</Container>;
  }

  const everythingDelivered = totalDeliveryLines === completeDeliveries.length;

  return (
    <Container $margin={[0, 4, 0, 0]}>
      <Heading>
        <FlexWrapper>
          <TitleContainer>
            <BasketIcon size={IconSize.Large} color="black" />

            <FlexContainer $stretchWidth={true} $spaceBetween={true}>
              <FlexContainer $centered $gap={3}>
                <H3BlackText>
                  {translateString("spareParts.lineItems")} (
                  {parts !== undefined
                    ? parts.length
                    : items !== undefined
                    ? items.filter((item) => item.parentRow === REGULAR_ITEM_PARENT).length
                    : 0}
                  )
                </H3BlackText>
                {completeDeliveries.length && !everythingDelivered ? (
                  <FlexContainer>
                    <label>
                      <Checkbox checked={showOpenLinesOnly} onChange={(e) => setShowOpenLinesOnly(e.target.checked)} />{" "}
                      {translateString("spareParts.showOpenLinesOnly")}
                    </label>{" "}
                    {showOpenLinesOnly ? (
                      <DeliveriesVisibilityDisclaimer>{` (${translateString("spareParts.outOfVisible", {
                        total: totalDeliveryLines,
                        visible: totalDeliveryLines - completeDeliveries.length,
                      })})`}</DeliveriesVisibilityDisclaimer>
                    ) : null}
                  </FlexContainer>
                ) : null}
              </FlexContainer>
              {!hidePrices && <H3BlackText>{totalPriceElem}</H3BlackText>}
            </FlexContainer>
          </TitleContainer>
        </FlexWrapper>
      </Heading>
      {itemsToRender}
    </Container>
  );
};
