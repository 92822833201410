// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_pt_BR: ITranslations = {
  pt_BR: {
    "account.accountSelected": "Conta selecionada",
    "account.selectingAccount": "Selecionando conta...",
    "addAttachment": "Adicionar um anexo",
    "addFilter": "Adicionar filtro",
    "addInstallation": "Adicionar instalação",
    "addInstallation.nameIsRequired": "O nome é obrigatório",
    "addInstallation:energy": "Adicionar usina de energia",
    "addInstallation:marine": "Adicionar embarcação",
    "applyFilter": "Aplicar filtro",
    "attachments": "Anexos",
    "auth.continueToUseOS": "Continue usando o Wärtsilä Online",
    "auth.logout": "Lougout",
    "auth.readOnlyMode": "Modo somente leitura",
    "auth.redirectingToLogin": "Redirecionando para login...",
    "auth.sessionExpired": "Limite de tempo esgotado da sessão alcançado, você será redirecionado ao login da página",
    "auth.sessionWillExpire": "A sua sessão expirará em breve",
    "button.cancel": "Cancelar",
    "button.close": "Fechar",
    "button.next": "Próximo",
    "button.previous": "Anterior",
    "button.saveForLater": "Salvar para uso posterior",
    "button.send": "Enviar",
    "button.skip": "Ignorar",
    "button.updateDraft": "Atualizar rascunho",
    "cancel": "Cancelar",
    "change": "Alterar",
    "changePasswordDialog.success": "O e-mail de instrução foi enviado com sucesso.",
    "chooseDocumentType": "Filtrar documentos",
    "chooseFilter": "Escolher filtro...",
    "clearFilters": "Limpar filtros",
    "comment.commentIsRequired": "O comentário é obrigatório",
    "comment.postComment": "Publicar comentário",
    "comment.replyComment": "Responder",
    "comment.writeYourComment": "Escrever o seu comentário",
    "comment.writeYourReply": "Escrever sua resposta",
    "comment.you": "Você",
    "commenting": "Comentando",
    "contact": "Contato",
    "contact.contactWartsilaCustomerSupport": "Contate o Atendimento ao Cliente da Wärtsilä",
    "contact.subject": "Assunto",
    "contact.subjectIsRequired": "O assunto é exigido",
    "contact.yourMessage": "A sua mensagem",
    "contact.yourMessageIsRequired": "A mensagem é exigida",
    "contactCustomerSupport": "Contatar suporte ao cliente",
    "contextHelp": "Guia rápido",
    "customerSupportTicket": "Tíquete de suporte do cliente",
    "date": "Data",
    "delete": "Excluir",
    "didYouMean": "Você quis dizer:",
    "dismissAll": "Dispensar todos",
    "download.downloadReady": "Download pronto!",
    "download.equipmentNotFound": "Não foi possível encontrar o artigo para o produto",
    "download.error": "Houve um erro com este download",
    "download.preparingDownload": "Preparando o seu download",
    "draft.confirmDelete": "Tem certeza de que deseja excluir este rascunho?",
    "draft.confirmDeleteTitle": "Excluir rascunho?",
    "draft.continueEditing": "Continuar a edição",
    "draft.delete": "Excluir rascunho",
    "edit": "Editar",
    "engine": "Motor",
    "equipment": "Produto",
    "equipment.configuration": "Configuração",
    "equipment.nickname": "Apelido do produto",
    "equipment.performanceData": "Desempenho",
    "equipment.title": "Título",
    "equipment.validation.nicknameTooLong": "O comprimento máximo para o apelido é {maxLength}",
    "equipmentNotFound": "Não existem produtos",
    "error.networkError": "Erro de rede",
    "error.networkErrorPleaseCheckConnectivity": "Verifique sua conectividade",
    "error.readOnlyErrorMsg": "Você está no modo somente leitura",
    "error.readOnlyErrorTitle": "Erro somente leitura",
    "fileUpload.attachmentRejected": "O anexo foi rejeitado. Favor verificar os limites de upload.",
    "fileUpload.noContactPerson": "Nenhuma pessoa de contato. Não foi possível enviar a mensagem!",
    "fileUpload.uploadAborted": "O upload do arquivo foi abortado",
    "fileUpload.uploadFailed": "O upload do arquivo falhou",
    "fileUpload.userHasReadOnlyAccess": "O usuário tem acesso de somente leitura",
    "filterRequests": "Solicitações de filtro",
    "iDontKnow": "Eu não sei",
    "index.installationDetails": "Detalhes da instalação",
    "installation": "Instalação",
    "installation.classificationSociety": "Sociedade de classificação",
    "installation.deliveryDate": "Data de entrega",
    "installation.description": "Descrição",
    "installation.imo": "IMO",
    "installation.inspectionDate": "Data da inspeção",
    "installation.name": "Nome",
    "installation.nextPlannedMaintenance": "Próxima manutenção planejada",
    "installation.type": "Tipo",
    "installation:energy": "Usina de energia",
    "installation:marine": "Embarcação",
    "installations": "Instalações",
    "installations:energy": "Usinas de energia",
    "installations:marine": "Embarcações",
    "language": "Idioma",
    "language.languageNotFound": "Não é possível encontrar o idioma com o código de idioma",
    "languageDisclaimer": "Submeter esta solicitação em inglês acelerará o processamento",
    "letsStart": "Iniciar",
    "loading": "Carregando...",
    "logout": "Lougout",
    "main.loadMore": "Carregar mais",
    "main.pageNotFound": "A URL que você seguiu não foi encontrada: {url}",
    "main.pageNotFoundTitle": "Não encontrado",
    "manufacturerSerialNumber": "Número de série do fabricante",
    "month.april": "Abril",
    "month.august": "Agosto",
    "month.december": "Dezembro",
    "month.february": "Fevereiro",
    "month.january": "Janeiro",
    "month.july": "Julho",
    "month.june": "Junho",
    "month.march": "Março",
    "month.may": "Maio",
    "month.november": "Novembro",
    "month.october": "Outubro",
    "month.september": "Setembro",
    "months": "meses",
    "myFleet": "Minhas instalações",
    "myFleet:marine": "Minha frota",
    "myInstallations": "Minhas instalações",
    "myInstallations:energy": "Minhas usinas de energia",
    "myInstallations:marine": "Minhas embarcações",
    "myProfile": "Meu perfil",
    "news.furtherQuestions": "Comentários ou perguntas adicionais",
    "news.productsAreRequired": "O(s) produto(s) é(são) necessário(s)",
    "news.quotationRequestSent": "Solicitação de cotação enviada.",
    "news.readMore": "Saber mais",
    "news.requestQuotation": "Solicitar cotação",
    "news.selectTheProductsForTheQuotation": "Selecione o(s) produto(s) para cotação",
    "news.sendingQuotationRequest": "Enviando solicitação de cotação.",
    "news.youAreAskingQuotationFor": "Você está solicitando uma cotação para {productName}.",
    "nickname": "Apelido",
    "no": "Não",
    "noDocumentsFound": "Não foram encontrados documentos para",
    "noVisibleInstallationsFound": "Você não definiu qualquer {sector} visível",
    "noVisibleInstallationsFoundHelp": "É possível fazer isso a partir do {sector}",
    "notApplicable": "Não se aplica",
    "notificationList.markedNotificationsAsRead": "Marcar {count} notificações como lidas",
    "notificationList.title.criticalBulletinUpdated": "Boletim para ação imediata: {title}",
    "notificationList.title.equipmentRunningHoursUpdate": "As horas de funcionamento devem ser atualizadas",
    "notificationList.title.manualUpdated": "O capítulo do manual foi atualizado: {title}",
    "notificationList.title.newAttachment": "Anexo novo",
    "notificationList.title.newCMReport": "Relatório novo",
    "notificationList.title.newComment": "Comentário novo",
    "notificationList.title.newRequest": "Foi criada uma nova solicitação",
    "notificationList.title.newSalesOrder": "Pedido de venda novo",
    "notificationList.title.newServiceQuotation": "Cotação de serviço nova",
    "notificationList.title.newSparePartClaim": "Reclamação de peça de reposição nova",
    "notificationList.title.resolvedSparePartClaim": "Reclamação de peça de reposição resolvida",
    "notificationList.title.salesOrderConfirmed": "O pedido de venda foi confirmado",
    "notificationList.title.serviceQuotationQuoted": "A cotação de serviço foi cotada",
    "notificationList.title.techRequestSolutionPlan": "Adicionado plano de ação novo",
    "notificationList.title.waitingForRating": "Aguardando classificação",
    "notificationList.title.waitingForReply": "Aguardando resposta",
    "notificationList.title.warrantyContactUpdated": "Contato de garantia atualizado",
    "notifications.lastWeek": "Semana passada",
    "notifications.markAllRead": "Marcar todos como lidos",
    "notifications.older": "Mais antigo",
    "notifications.thisWeek": "Esta semana",
    "notifications.title": "Notificações ({unreadNotifications})",
    "notifications.today": "Hoje",
    "notifications.yesterday": "Ontem",
    "ok": "OK",
    "optionalFieldTitle": "opcional",
    "orderBeingProcessed": "Pedido em processamento",
    "phone": "Telefone",
    "pleaseAddInstallation": "Favor selecionar uma instalação!",
    "pleaseAddInstallation:energy": "Favor selecionar uma usina de energia!",
    "pleaseAddInstallation:marine": "Favor selecionar uma embarcação!",
    "productNumber": "Número do produto",
    "productType": "Tipo de produto",
    "profile.updating": "Atualizando perfil...",
    "quotationBeingProcessed": "Cotação em processamento",
    "removeInstallation": "Remover instalação",
    "removeInstallation:energy": "Remover usina de energia",
    "removeInstallation:marine": "Remover embarcação",
    "request.draftSaved": "Rascunho gravado",
    "request.draftSavingError": "Erro ao gravar o rascunho",
    "request.savingDraft": "Salvando rascunho",
    "request.send": "Enviar solicitação",
    "request.sending": "Enviando solicitação...",
    "request.sendingError": "Erro ao enviar solicitação",
    "request.sent": "Solicitação enviada.",
    "routes.contactContractManager": "Contatar o gerente de contratos",
    "routes.contracts": "Contratos",
    "routes.download": "Downloads",
    "routes.equipment": "Produto",
    "routes.fluidReportDraft": "Novo relatório de fluidos",
    "routes.installation": "Instalação",
    "routes.installationSelection": "Selecionar instalação",
    "routes.installationSelection:energy": "Selecionar usina de energia",
    "routes.installationSelection:marine": "Selecionar embarcação",
    "routes.newFluidReport": "Novo relatório de fluidos",
    "routes.newRequest": "Nova solicitação",
    "routes.newSFOCReport": "Relatório de dados de desempenho SFOC novo",
    "routes.news": "Notícias",
    "routes.partsCatalogue": "Explorar peças de reposição",
    "routes.performance": "Desempenho",
    "routes.placeOrder": "Fazer pedido",
    "routes.proceedToOrder": "Prossiga para o pedido",
    "routes.reports": "Relatórios",
    "routes.requestQuotation": "Solicitar cotação",
    "routes.requests": "Suporte",
    "routes.runningHours": "Horas de operação",
    "routes.spareParts": "Comércio",
    "routes.technicalDocuments": "Documentos técnicos",
    "routes.users": "Usuários",
    "runningHours": "Horas de operação",
    "runningHours.confirmDialogMessageHigh": "Você inseriu os valores das horas de operação que são mais altos do que o tempo passado desde a última atualização. Você deseja atualizar o novo registro?",
    "runningHours.confirmDialogMessageLow": "Você inseriu o valor das horas de operação que é inferior ao valor atual. Você pode fazer uma solicitação para atualizar as horas de operação.",
    "runningHours.invalidValue": "Valor inválido",
    "runningHours.lowValue": "Você inseriu o valor das horas de operação que é inferior ao valor atual.",
    "runningHours.makeRequest": "Fazer solicitação",
    "runningHours.newRunningHours": "Novas horas de operação",
    "runningHours.reasonForLowerValue": "Razão para valor mais baixo",
    "runningHours.reasonForRequest": "Razão para a solicitação",
    "runningHours.reasonIsRequired": "A razão é exigida",
    "runningHours.runningHoursDateInPast": "A data das horas de funcionamento não pode ser anterior à última data de atualização, selecione a data correta antes de continuar.",
    "runningHours.updateRunningHours": "Atualizar horas de operação",
    "runningHours.youCanMakeRequest": "Você pode fazer uma solicitação para atualizar as horas de operação.",
    "runningHoursUpdated": "Horas de funcionamento atualizadas",
    "save": "Salvar",
    "saveVisibilitySelection": "Salvar",
    "searchInsteadFor": "Ao invés disso pesquisar",
    "searchPlaceholder": "Pesquisar...",
    "select": "Selecionar",
    "select.sortPlaceholder": "Classificar por",
    "selectInstallation": "Selecionar instalação",
    "selectInstallation:energy": "Selecionar usina de energia",
    "selectInstallation:marine": "Selecionar embarcação",
    "send": "Enviar",
    "sendingFileFailed": "Falhou",
    "services": "Serviços",
    "showMore": "Mostrar mais",
    "showingResultsFor": "Exibindo resultados para",
    "sorting.createdByAZ": "Criado por (A-Z)",
    "sorting.creationDate": "Data da criação",
    "sorting.emailShortAZ": "E-mail (A-Z)",
    "sorting.equipmentAZ": "Produto (A-Z)",
    "sorting.installationAZ": "Instalação (A-Z)",
    "sorting.modified": "Modificado",
    "sorting.nameAZ": "Nome (A-Z)",
    "sorting.newestFirst": "O mais novo primeiro",
    "sorting.oldestFirst": "O mais antigo primeiro",
    "sorting.orderNumber": "Número do pedido",
    "sorting.poNumber": "Número do pedido",
    "sorting.relevancy": "Mais relevantes",
    "sorting.requestTypeAZ": "Tipo de solicitação (A-Z)",
    "sorting.statusAZ": "Status (A-Z)",
    "sorting.subjectAZ": "Assunto (A-Z)",
    "sorting.titleAZ": "Título (A-Z)",
    "sparePartClaim": "Reclamação de peça de reposição",
    "status": "Status",
    "techRequest": "Suporte técnico",
    "technicalDocumentation": "Documentos técnicos",
    "type": "Tipo",
    "typeToFilter": "Tipo de filtro...",
    "unit.cycles": "ciclos",
    "unit.hours": "horas",
    "unit.litres": "litros",
    "unknown": "Desconhecido",
    "update": "Atualizar",
    "users": "Usuários",
    "validation.characterLimit": "Limitação de caracteres: {characters}",
    "validation.incorrectPhoneNumber": "O número de telefone deve começar com + e conter de 9 a 14 dígitos",
    "viewContracts": "Visualizar contratos",
    "visibleInstallations": "Instalações visíveis",
    "visibleInstallations.changeAction": "Faça uma solicitação para adicionar ou remover uma instalação",
    "visibleInstallations.changeAction:energy": "Faça uma solicitação para adicionar ou remover uma usina de energia",
    "visibleInstallations.changeAction:marine": "Faça uma solicitação para adicionar ou remover uma embarcação",
    "visibleInstallations.changeLabel": "São necessárias alterações na lista de instalação?",
    "visibleInstallations.changeLabel:energy": "São necessárias alterações na lista da usina de energia?",
    "visibleInstallations.changeLabel:marine": "São necessárias alterações na lista de embarcações?",
    "visibleInstallations:energy": "Usinas de energia visíveis",
    "visibleInstallations:marine": "Embarcações visíveis",
    "warrantyClaim": "Reclamação de garantia",
    "warrantyEndDate": "Data final da garantia",
    "year": "ano",
    "years": "anos",
    "yes": "Sim",
  },
};
