// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_en_US: ITranslations = {
  en_US: {
    "user.addNewUser": "Add user",
    "user.allUsersInAccount": "All users in your account",
    "user.cannotContainUsername": "Your password cannot equal or contain your username.",
    "user.changePassword": "Change password",
    "user.confirmNewPassword": "Confirm new password",
    "user.confirmYourInformation": "Confirm Your Information",
    "user.deactivationRequestSent": "Your request has been sent and we will notify you once it is processed.",
    "user.department": "Department",
    "user.digest.addAllInstallations": "Select all installations",
    "user.digest.addAllInstallations:energy": "Select all power plants",
    "user.digest.addAllInstallations:marine": "Select all vessels",
    "user.digest.period.daily": "Daily",
    "user.digest.period.monthly": "Monthly",
    "user.digest.period.never": "Never",
    "user.digest.period.weekly": "Weekly",
    "user.digest.service.reports": "Reports",
    "user.digest.service.requests": "Support Requests",
    "user.digest.service.spareParts": "Spare Parts",
    "user.digest.service.technicalDocuments": "Technical Documents",
    "user.digest.validation.noInstallationsSelected": "Installations are required.",
    "user.digest.validation.noInstallationsSelected:energy": "Power plants are required.",
    "user.digest.validation.noInstallationsSelected:marine": "Vessels are required.",
    "user.digest.validation.noServicesSelected": "Services are required.",
    "user.digestDescription.daily": "Daily for one installation",
    "user.digestDescription.daily.plural": "Daily for {numberOfInstallations} installations",
    "user.digestDescription.daily.plural:energy": "Daily for {numberOfInstallations} power plants",
    "user.digestDescription.daily.plural:marine": "Daily for {numberOfInstallations} vessels",
    "user.digestDescription.daily:energy": "Daily for one power plant",
    "user.digestDescription.daily:marine": "Daily for one vessel",
    "user.digestDescription.monthly": "Monthly for one installation",
    "user.digestDescription.monthly.plural": "Monthly for {numberOfInstallations} installations",
    "user.digestDescription.monthly.plural:energy": "Monthly for {numberOfInstallations} power plants",
    "user.digestDescription.monthly.plural:marine": "Monthly for {numberOfInstallations} vessels",
    "user.digestDescription.monthly:energy": "Monthly for one power plant",
    "user.digestDescription.monthly:marine": "Monthly for one vessel",
    "user.digestDescription.never": "Disabled",
    "user.digestDescription.weekly": "Weekly every Sunday for one installation",
    "user.digestDescription.weekly.plural": "Weekly every Sunday for {numberOfInstallations} installations",
    "user.digestDescription.weekly.plural:energy": "Weekly every Sunday for {numberOfInstallations} power plants",
    "user.digestDescription.weekly.plural:marine": "Weekly every Sunday for {numberOfInstallations} vessels",
    "user.digestDescription.weekly:energy": "Weekly every Sunday for one power plant",
    "user.digestDescription.weekly:marine": "Weekly every Sunday for one vessel",
    "user.digestEmail": "Digest email",
    "user.digestEmailSettings": "Digest email settings",
    "user.digestSettingsForm.frequencyOfDigest": "Frequency of digest",
    "user.digestSettingsForm.servicesOfInterest": "Services of interest",
    "user.email": "Email address",
    "user.emailIsRequired": "Email is required",
    "user.emailShort": "E-mail",
    "user.firstname": "Firstname",
    "user.firstnameIsRequired": "First name is required",
    "user.fullName": "Full name",
    "user.fullNameIsRequired": "Full name is required",
    "user.helpUsKeepContentRelevant": "Help us keep our communication and content relevant to you",
    "user.jobTitle": "Job title",
    "user.jobTitleIsRequired": "Job title is required",
    "user.lastname": "Lastname",
    "user.lastnameIsRequired": "Last name is required",
    "user.name": "Name",
    "user.newPassword": "New password",
    "user.notificationSettings": "Notification settings",
    "user.passwordExpiration": "Your password is about to expire",
    "user.passwordMayOnlyContainValidCharacters": "Password may only contain the following special characters: {characters}",
    "user.passwordMinimumLength": "Minimum password length is {length} characters",
    "user.passwordMustBeAlphanumeric": "Password must contain both letters and numbers",
    "user.passwordStopWorking": "Current password will stop working in {days} days.",
    "user.passwordStopWorkingToday": "Current password will stop working today.",
    "user.passwordsDontMatch": "Passwords do not match",
    "user.phone": "Phone",
    "user.phoneNumber": "Phone number",
    "user.reasonForDeactivation": "Please let us know why you would like to disable your access.",
    "user.reasonForRemoval": "Reason for removal",
    "user.reasonForRemovalIsRequired": "Reason for removal is required",
    "user.removeUser": "Remove user",
    "user.repeatedPassword": "Reuse of the old password is not allowed. Please use another password.",
    "user.selfDeactivate": "Deactivate your account",
    "user.shipyard.controlWhichAlertsToReceive": "Control which email alerts you want to receive about your delivery projects.",
    "user.shipyard.dispatch": "Dispatch",
    "user.shipyard.emailNotificationSettings": "Email notification settings",
    "user.shipyard.emailNotificationSubscription": "Email notification subscription",
    "user.shipyard.emailNotificationsForUpdates": "Email notifications for updates on {count} vessel",
    "user.shipyard.emailNotificationsForUpdates.plural": "Email notifications for updates on {count} vessels",
    "user.shipyard.etdChanges": "Estimated time on dispatch changes",
    "user.shipyard.searchInstallation": "Search vessel",
    "user.shipyard.subscribedNotifications": "Subscribed notifications",
    "user.title": "Title",
    "user.updateYourInformation": "Are your details up to date?",
    "user.userInformation": "User information",
    "user.userPassword": "Password",
    "user.profileSettings": "Profile settings",
    "user.automaticTranslationNote": "We've detected that you are using automatic translation for Wärtsilä Online. Please note that application might crash unexpectedly. Check your profile settings for available translations, and if possible, disable the translation plugin.",

  },
};
