// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_fi: ITranslations = {
  fi: {
    "reports.attachments": "Liitteet",
    "reports.conditionMonitorinReports": "Tilaraportit",
    "reports.noReportsAvailable": "Ei saatavilla olevia raportteja",
    "reports.reportPeriodStartDate": "Jakso",
    "reports.reportPublishedDate": "Julkaisupäivämäärä",
    "reports.title": "Otsikko",
  },
};
