import "react-app-polyfill/stable";
import "core-js";
import "./fonts/Roboto/roboto.css";

import * as React from "react";

import { IWindow, init, initServiceWorker } from "./util/init";

import { App } from "./components/App";
import { Provider } from "react-redux";
import { configureStore } from "./redux";
import { createRoot } from "react-dom/client";
import { install } from "ga-gtag";

async function initApp(window: IWindow) {
  await init(window);
  initServiceWorker();

  // Install Google Analytics script
  const [subdomain] = window.location.hostname.split(".");
  switch (subdomain) {
    case "online-dev":
      install("G-RP603PX4FG");
      break;
    case "online":
      install("G-WXPDLMLWR9");
      break;
  }

  const store = configureStore();
  const container = document.getElementById("root");
  const root = createRoot(container!);

  const render = (Component: any) => {
    return root.render(
      <Provider store={store}>
        <Component />
      </Provider>
    );
  };

  render(App);

  if (module.hot) {
    module.hot.accept("./components/App", () => {
      render(App);
    });
  }
}

initApp(window);
