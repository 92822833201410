// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_es_MX: ITranslations = {
  es_MX: {
    "fluid.afterSeparator": "Después de separador",
    "fluid.analysisDate": "Fecha de análisis",
    "fluid.analysisOf": "Análisis de",
    "fluid.beforeSeparator": "Antes de separador",
    "fluid.bunkeringLocation": "Ubicación de depósito (opcional)",
    "fluid.categoryISOF": "Categoría ISO-F",
    "fluid.commentsFromTheLaboratory": "Comentarios del laboratorio (opcional)",
    "fluid.common": "Común",
    "fluid.coolingWater": "Agua de enfriamiento",
    "fluid.differentPerEngine": "Diferente por motor",
    "fluid.draft.continueEditing": "Continuar editando",
    "fluid.draft.continueWithExistingDraft": "Continuar con el borrador existente",
    "fluid.draft.createNewReport": "Crear nuevo reporte de fluido",
    "fluid.draft.deleteDraft": "Eliminar borrador",
    "fluid.draft.deleted": "Borrador eliminado",
    "fluid.draft.deleting": "Eliminando borrador...",
    "fluid.draft.deletingError": "Error al eliminar el borrador",
    "fluid.draft.or": "O",
    "fluid.draft.sending": "Guardando borrador...",
    "fluid.draft.sendingError": "Error al guardar el borrador",
    "fluid.draft.sent": "Borrador guardado",
    "fluid.engineLife": "Vida del motor",
    "fluid.engines": "Motores",
    "fluid.enginesSampleData": "Datos de muestra de motores",
    "fluid.field.acid_number_mg_koh_g__c": "Número de ácido",
    "fluid.field.aluminum_al_ppm__c": "Aluminio, Al",
    "fluid.field.aluminum_silicon_mg_kg__c": "Aluminio + Sílice",
    "fluid.field.ash_m_m__c": "Ceniza",
    "fluid.field.asphaltenes_m_m__c": "Asfaltenos",
    "fluid.field.base_number_bn_mg_koh_g__c": "Número base (BN)",
    "fluid.field.boron_b_ppm__c": "Boro, B",
    "fluid.field.calcium_ca_mg_kg__c": "Calcio, Ca",
    "fluid.field.calcium_ca_ppm__c": "Calcio, Ca",
    "fluid.field.carbon_residue_micro_method_m_m__c": "Residuo de carbono, método micro",
    "fluid.field.ccai__c": "CCAI",
    "fluid.field.cetane_index__c": "Índice de cetano",
    "fluid.field.chlorides_cl_mg_l__c": "Cloruros, Cl",
    "fluid.field.chromium_cr_ppm__c": "Cromo, Cr",
    "fluid.field.copper_cu_mg_l__c": "Cobre, Cu",
    "fluid.field.copper_cu_ppm__c": "Cobre, Cu",
    "fluid.field.density_15_c_kg_m3__c": "Densidad @15 °C",
    "fluid.field.flash_point_c__c": "Punto de evaporación",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "Sulfuro de hidrógeno",
    "fluid.field.insolubes_w__c": "Insolubles",
    "fluid.field.iron_fe_mg_kg__c": "Hierro, Fe",
    "fluid.field.iron_fe_mg_l__c": "Hierro, Fe",
    "fluid.field.iron_fe_ppm__c": "Hierro, Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "Viscosidad cinemática @40 °C",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "Viscosidad cinemática @50 °C",
    "fluid.field.lead_pb_mg_kg__c": "Plomo, Pb",
    "fluid.field.lead_pb_ppm__c": "Plomo, Pb",
    "fluid.field.lower_heating_value_mj_kg__c": "Valor inferior de calentamiento",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "Lubricidad, Diámetro de cicatriz de desgaste corregido (wsd 1.4) @ 60 °C",
    "fluid.field.magnesium_mg_mg_kg__c": "Magnesio, Mg",
    "fluid.field.molybenum_mo_ppm__c": "Molibdeno, Mo",
    "fluid.field.nickel_ni_mg_kg__c": "Níquel, Ni",
    "fluid.field.nickel_ni_ppm__c": "Níquel, Ni",
    "fluid.field.nitrate_no3_mg_l__c": "Nitrato, NO₃",
    "fluid.field.nitration_abs_cm__c": "Nitración",
    "fluid.field.nitrite_no2_mg_l__c": "Nitrito, NO₂",
    "fluid.field.oxidation_abs_cm__c": "Oxidación",
    "fluid.field.oxidation_stability_g_m3__c": "Estabilidad de oxidación",
    "fluid.field.ph__c": "pH",
    "fluid.field.phosphorus_p_mg_kg__c": "Fósforo, P",
    "fluid.field.phosphorus_p_ppm__c": "Fósforo, P",
    "fluid.field.pour_point_c__c": "Punto de vertido",
    "fluid.field.silicon_si_ppm__c": "Sílice, Si",
    "fluid.field.sodium_na_mg_kg__c": "Sodio, Na",
    "fluid.field.sodium_na_ppm__c": "Sodio, Na",
    "fluid.field.sulphates_so4_mg_l__c": "Sulfatos, SO₄",
    "fluid.field.sulphur_m_m__c": "Azufre",
    "fluid.field.tin_sn_ppm__c": "Estaño, Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "Número de ácido total (TAN)",
    "fluid.field.total_hardness_ca_mg_dh__c": "Dureza total (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "Sedimento total",
    "fluid.field.vanadium_v_mg_kg__c": "Vanadio, V",
    "fluid.field.vanadium_v_ppm__c": "Vanadio, V",
    "fluid.field.viscosity_100_c_cst__c": "Viscosidad @100 °C",
    "fluid.field.viscosity_40_c_cst__c": "Viscosidad @40 °C",
    "fluid.field.water_v_v__c": "Agua",
    "fluid.field.water_vol_or_w__c": "Agua",
    "fluid.field.zinc_zn_mg_kg__c": "Zinc, Zn",
    "fluid.field.zinc_zn_ppm__c": "Zinc, Zn",
    "fluid.fuel": "Combustible",
    "fluid.gas": "Gas",
    "fluid.general": "General",
    "fluid.generalInformation": "Información general",
    "fluid.header.elemConcs": "Concentraciones elementales",
    "fluid.header.fuelProps": "Propiedades del combustible",
    "fluid.header.oilProps": "Propiedades del aceite",
    "fluid.htCircuit": "Circuito HT",
    "fluid.inputValues": "Valores de entrada",
    "fluid.laboratoryReferenceNumber": "Número de referencia de laboratorio (opcional)",
    "fluid.lastData": "Últimos datos",
    "fluid.lowSulphurFuels": "Combustibles de bajo azufre",
    "fluid.ltCircuit": "Circuito LT",
    "fluid.lubeOil": "Aceite lubricante",
    "fluid.lubricant": "Lubricante",
    "fluid.notAvailable": "N/A",
    "fluid.oilAdded": "Aceite agregado",
    "fluid.oilLife": "Vida del aceite",
    "fluid.oldLubricantDisclaimer": "Esta sección contiene datos históricos del lubricante utilizado anteriormente",
    "fluid.operationOn": "Operación encendida",
    "fluid.phase.reportData": "Datos de reporte",
    "fluid.phase.sampleData": "Datos de muestra",
    "fluid.phase.scopeSelection": "Selección de alcance",
    "fluid.phase.summary": "Resumen",
    "fluid.product": "Producto",
    "fluid.reportData": "Datos de reporte",
    "fluid.sameForAllEngines": "Igual para todos los motores",
    "fluid.sampleAggregation": "Agregación de muestra",
    "fluid.sampleDate": "Fecha de muestra",
    "fluid.samplePoint": "Punto de muestreo",
    "fluid.scopeOfReport": "Alcance de reporte",
    "fluid.sending": "Enviando informe de fluido...",
    "fluid.sendingError": "Error al enviar informe de fluido",
    "fluid.sent": "Informe de fluido enviado",
    "fluid.timeframe": "Periodo de tiempo",
    "fluid.unit.abscm": "Abs/cm",
    "fluid.unit.cst": "cSt",
    "fluid.unit.degreec": "°C",
    "fluid.unit.degreedh": "°dH",
    "fluid.unit.gm3": "g/m³",
    "fluid.unit.kgm3": "kg/m³",
    "fluid.unit.mgkg": "mg/kg",
    "fluid.unit.mgkohg": "mg KOH / g",
    "fluid.unit.mgl": "mg/l",
    "fluid.unit.mjkg": "MJ/kg",
    "fluid.unit.mm2s": "mm²/s",
    "fluid.unit.pmm": "% m/m",
    "fluid.unit.ppm": "ppm",
    "fluid.unit.pvv": "% v/v",
    "fluid.unit.um": "µm",
    "fluid.unit.volporwp": "% en volumen o % en peso",
    "fluid.unit.wp": "% en peso",
    "fluid.unspecified": "Sin especificar",
    "fluid.uptime": "Horas de operación",
    "fluid.uptime.availability": "Disponibilidad",
    "fluid.uptime.availabilityFormula": "(Operación + Espera) / (Operación + Espera + Detener + Paro)",
    "fluid.uptime.availabilityLimits": "Objetivos de disponibilidad",
    "fluid.uptime.contractLimit": "Límite de acuerdo",
    "fluid.uptime.daily": "Diariamente",
    "fluid.uptime.engineAvailability": "Disponibilidad de motor",
    "fluid.uptime.limitMustBeNumber": "¡El límite debe ser un número!",
    "fluid.uptime.limitMustBeNumberBetween": "¡El límite debe ser un número entre {min} y {max}!",
    "fluid.uptime.limits": "Editar valores objetivo",
    "fluid.uptime.monthly": "Mensualmente",
    "fluid.uptime.noAvailability": "No hay datos de disponibilidad para este periodo.",
    "fluid.uptime.noData": "Sin datos",
    "fluid.uptime.noMaintenanceData": "No hay datos de mantenimiento para este periodo.",
    "fluid.uptime.noRunningHours": "No hay datos de horas de operación para este periodo.",
    "fluid.uptime.period": "Periodo",
    "fluid.uptime.report": "Reporte",
    "fluid.uptime.running": "Operación",
    "fluid.uptime.saveLimits": "Guardar",
    "fluid.uptime.shutDown": "Paro",
    "fluid.uptime.standby": "Espera",
    "fluid.uptime.stop": "Detener",
    "fluid.uptime.sum": "SUMA",
    "fluid.uptime.yearly": "Anualmente",
    "fluid.validation.analysisDateEarlierThanSampleDate": "La fecha de análisis no puede ser anterior a la fecha de muestra",
    "fluid.validation.analysisDateInFuture": "La fecha de análisis no puede ser una fecha futura",
    "fluid.validation.analysisDateRequired": "Se requiere fecha de análisis",
    "fluid.validation.categoryISOFIsRequired": "Se requiere categoría ISO-F",
    "fluid.validation.common": "Valor inesperado ingresado (mín: {min}, máx: {max}).",
    "fluid.validation.engineIsRequired": "Se requiere motor",
    "fluid.validation.engineLifeMustBeANumber": "La vida de motor debe ser un número",
    "fluid.validation.fuelIsRequired": "Se requiere combustible",
    "fluid.validation.installationIsRequired": "Se requiere instalación",
    "fluid.validation.installationIsRequired:energy": "Se requiere planta de energía",
    "fluid.validation.installationIsRequired:marine": "Se requiere embarcación",
    "fluid.validation.lubricantIsRequired": "Se requiere lubricante",
    "fluid.validation.mustBeANumber": "{value} debe ser un número.",
    "fluid.validation.oilAdedMustBeANumber": "El aceite agregado debe ser un número",
    "fluid.validation.oilLifeMustBeANumber": "La vida de aceite debe ser un número",
    "fluid.validation.ppm": "Valor inesperado ingresado (mín: {min}, máx: {max}). Conversión de valores de % a ppm: 1% = 10000 ppm.",
    "fluid.validation.sampleAggregationIsRequired": "Se requiere agregación de muestra",
    "fluid.validation.sampleDateInFuture": "La fecha de muestra no puede ser una fecha futura",
    "fluid.validation.sampleDateRequired": "Se requiere fecha de muestra",
    "fluid.validation.samplePointIsRequired": "Se requiere punto de muestra",
  },
};
