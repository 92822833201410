import moment from "moment";
import { IClaimComment, ICombinedRequest } from "online-services-types";
import * as React from "react";

import { Comments } from "src/components/Comments";
import { LoadingSpinner, spinnerAlign, spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { fetchComments, postComment } from "src/components/RequestComments/requestComments";
import { Button, ButtonStyle } from "src/design-system/Button";
import colors from "src/design-system/Tokens/colors";
import { RequestType } from "src/models/warrantyClaim";
import { FormStyleProvider, FormTheme } from "src/design-system/Theme/form";
import styled from "styled-components";
import { CommentVerb } from "../Comments/CommentsComponent";
import { ButtonType } from "src/design-system/Button/Button";

const getLocalizedDate = (date?: string) => {
  return moment(date).fromNow();
};

const Wrapper = styled.div`
  color: ${colors.primary.black} !important;
  & textarea {
    border-color: black;
  }

  input:focus {
    border-color: black;
  }
`;

const createCommentViewModel = (comment: IClaimComment) => ({
  ...comment,
  createdDate: getLocalizedDate(comment.createdDate),
  isTransient: false,
});

export interface IAppClaimComment extends IClaimComment {
  isTransient: boolean;
}

interface IRequestCommentComponentState {
  comments: IAppClaimComment[];
  commentsLoaded: boolean;
  showAll: boolean;
}

interface IRequestCommentComponentProps {
  request: ICombinedRequest;
  userId: string;
  userName: string;
  isCommentingDisabled: boolean;
  requestCanBeReopened: boolean;
  verb?: CommentVerb;
  commentPlaceholders?: IAppClaimComment[]; // Optional external placeholder comments
  onCommentPosted?(): void;
  onCancel?(): void;
  registerCommentFn?(funct: () => void): void;
  refreshComments?(): void;
  // Optional handlers for external placeholder comments. If these aren't set the placeholders will be handled inside the component
  addPlaceholder?(comment: IAppClaimComment): void;
  popPlaceholder?(): void;
}
export class RequestCommentsComponent extends React.Component<
  IRequestCommentComponentProps,
  IRequestCommentComponentState
> {
  constructor(props: IRequestCommentComponentProps) {
    super(props);

    this.state = {
      comments: [],
      commentsLoaded: false,
      showAll: false,
    };

    this.refreshComments = this.refreshComments.bind(this);
  }

  public async componentDidMount() {
    if (this.props.registerCommentFn) {
      this.props.registerCommentFn(this.refreshComments);
    }
    const comments = await fetchComments(this.props.request.requestType as RequestType, this.props.request.id);
    this.setState({
      comments: comments.map(createCommentViewModel),
      commentsLoaded: true,
    });
  }

  public readonly refreshComments = async () => {
    const comments = await fetchComments(this.props.request.requestType as RequestType, this.props.request.id);
    this.setState({ comments: comments.map(createCommentViewModel) }, () => {
      if (this.props.popPlaceholder) {
        this.props.popPlaceholder();
      }
    });
  };

  public readonly post = async (message: string) => {
    const commentPlaceholder = {
      id: `${Math.random()}`,
      message,
      authorId: this.props.userId,
      author: this.props.userName,
      warrantyId: "",
      subject: null,
      createdDate: getLocalizedDate(),
      isTransient: true,
      isInternal: false,
    };
    if (this.props.addPlaceholder) {
      this.props.addPlaceholder(commentPlaceholder);
    } else {
      const comments = this.state.comments;
      comments.unshift(commentPlaceholder);
      this.setState({ comments });
    }

    await postComment(this.props.request.requestType as RequestType, this.props.request.id, message);
    if (this.props.refreshComments) {
      await this.props.refreshComments();
    } else {
      await this.refreshComments();
    }
    if (this.props.onCommentPosted) {
      this.props.onCommentPosted();
    }
  };

  public render() {
    const maxDisplayedCommentsWhenCompact = 4;
    const comments = this.props.commentPlaceholders
      ? this.props.commentPlaceholders.concat(this.state.comments)
      : this.state.comments;
    const displayedComments = this.state.showAll ? comments : comments.slice(0, maxDisplayedCommentsWhenCompact);
    return (
      <Wrapper>
        <FormStyleProvider theme={FormTheme.Light}>
          {!this.state.commentsLoaded && (
            <LoadingSpinner size={spinnerSize.sm} disableText={true} align={spinnerAlign.start} dark={true} />
          )}
          {this.state.commentsLoaded && (
            <Comments
              isCommentingDisabled={this.props.isCommentingDisabled}
              requestCanBeReopened={this.props.requestCanBeReopened}
              currentUserId={this.props.userId}
              comments={displayedComments}
              postComment={this.post}
              verb={this.props.verb}
              onCancel={this.props.onCancel}
            />
          )}
          {!this.state.showAll && this.state.commentsLoaded && comments.length > maxDisplayedCommentsWhenCompact && (
            <Button
              buttonType={ButtonType.Outline}
              buttonStyle={ButtonStyle.Transparent}
              onClick={() => this.setState({ showAll: true })}
            >
              <LocalizedString id="showMore" />
            </Button>
          )}
        </FormStyleProvider>
      </Wrapper>
    );
  }
}
