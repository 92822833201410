// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_pt_BR: ITranslations = {
  pt_BR: {
    "request.addContact": "Adicionar contato",
    "request.addNewContact": "Adicionar novo contato",
    "request.addNewUser": "Adicionar usuário",
    "request.addressLine1": "Linha de endereço 1",
    "request.addressLine2": "Linha de endereço 2",
    "request.attachment.Box": "Box",
    "request.attachment.Box or packing material": "Caixa ou material de embalagem",
    "request.attachment.Claimed part": "Peça reclamada",
    "request.attachment.Content of the box(es) and damaged parts": "Conteúdo da(s) caixa(s) e peças danificadas",
    "request.attachment.Corroded part": "Peça enferrujada",
    "request.attachment.Damage on part": "Danos na peça",
    "request.attachment.Entire part": "Peça inteira",
    "request.attachment.Markings and / or labels on the part": "Marcações e/ou etiquetas na peça.",
    "request.attachment.Markings on the claimed part": "Marcações na peça reclamada",
    "request.attachment.Markings on the part": "Marcações na peça",
    "request.attachment.Markings on the received part": "Marcações na peça recebida",
    "request.attachment.Markings on the required / correct part": "Marcações na peça solicitada/correta",
    "request.attachment.Other": "Outro",
    "request.attachment.Packing material": "Material de embalagem",
    "request.attachment.Packing, incl. labels": "Embalagem, incluindo etiquetas",
    "request.attachment.Pictures of leaking parts": "Fotografias das peças vazando",
    "request.attachment.Received part": "Peça recebida",
    "request.attachment.Required / correct part": "Peça solicitada/correta",
    "request.attachment.Required part": "Peça solicitada",
    "request.attachment.Test reports": "Relatórios de teste",
    "request.attachmentDescription": "Forneça fotografias gerais e de perto.",
    "request.attachmentDialog.Confirm": "Remova",
    "request.attachmentDialog.Text": "Tem certeza de que deseja remover o anexo?",
    "request.attachmentDialog.Title": "Remover o anexo",
    "request.attachments": "Anexos",
    "request.auxAttachmentDescription": "Forneça fotografias gerais, de perto e da plaqueta de registro do produto.",
    "request.backgroundInformation": "Informações do histórico",
    "request.beforeClaiming": "Antes de reclamar sobre peças faltando, assegure-se de que as seguintes verificações foram executadas:",
    "request.beforeClaiming.checkBottom": "3. Em engradados de madeira, algumas vezes existe uma chapa de madeira no fundo que pode parecer com o fundo e as peças podem ter sido colocadas embaixo dessa chapa.",
    "request.beforeClaiming.checkPackage": "1. Verifique novamente o pacote/caixa/engradado de madeira em que a remessa foi entregue.",
    "request.beforeClaiming.checkPaddings": "2. Verifique os materiais de enchimento e embalagem.",
    "request.chooseReferenceRequest": "Escolha a solicitação de referência",
    "request.city": "Cidade",
    "request.claimAllItems": "Reclamar todas as linhas da remessa pelo mesmo motivo / problema",
    "request.claimBasketText": "{basketItems} itens de linha",
    "request.claimDescription": "Descrição breve da reclamação",
    "request.claimItem": "Item da reclamação",
    "request.claimOrder": "Pedido de reclamação",
    "request.claimQuantity": "Quantidade reclamada",
    "request.claimReason": "Motivo da reclamação",
    "request.claimReasonChangeDislaimer": "Note que ao alterar o motivo da reclamação, suas respostas específicas do item serão perdidas.",
    "request.claimReasons": "Motivos da reclamação",
    "request.claimReference": "Referência da reclamação",
    "request.claimedItems": "Itens reclamados",
    "request.claimedOrder": "Ordens a serem reclamadas",
    "request.comment": "Comentário",
    "request.companyName": "Nome da empresa",
    "request.contactInformation": "Informações de contato",
    "request.contactInformationDescription": "Você é a principal pessoa de contato para esta solicitação, mas pode acrescentar pessoas interessadas adicionais que serão notificadas sobre as mudanças relevantes a esta solicitação.",
    "request.couldntFindDelivery": "Não foi possível encontrar a remessa!",
    "request.country": "País",
    "request.createdBy": "Criado por",
    "request.creationDate": "Data da criação",
    "request.customerClaimReference": "Referência da reclamação do cliente",
    "request.customerClaimReferenceOptional": "Referência da reclamação do cliente (opcional)",
    "request.customerInformation": "Informações do cliente",
    "request.daAddressLine1IsRequired": "O endereço é exigido!",
    "request.daCityIsRequired": "A cidade é exigida!",
    "request.daCompanyNameIsRequired": "O nome da empresa é exigido",
    "request.daCountryIsRequired": "O país é exigido!",
    "request.daPostalCodeIsRequired": "O código postal é exigido!",
    "request.dateOfOccurrence": "Data da ocorrência",
    "request.deliveryAddress": "Endereço de entrega",
    "request.deliveryExpected": "Data esperada de entrega da remessa",
    "request.deliveryNumber": "Número da remessa",
    "request.deliveryReceived": "Data de recebimento da remessa",
    "request.describeLeakageNoticed": "Como e quando o vazamento foi observado",
    "request.description": "Descrição",
    "request.descriptionIsRequired": "A descrição é exigida!",
    "request.descriptionLong": "Quanto mais informações detalhadas recebermos no registro, mais rápido poderemos lidar com a solicitação.",
    "request.deselect": "Remova",
    "request.detailedDescription": "Descrição detalhada",
    "request.detailedReason": "Motivo detalhado",
    "request.discardingDraft": "Descartando rascunho...",
    "request.draftDiscarded": "Rascunho descartado.",
    "request.email": "E-mail",
    "request.emailIsRequired": "O e-mail é exigido",
    "request.equipmentIsRequired": "O produto é necessário",
    "request.errorWhileDiscardingDraft": "Erro ao descartar o rascunho: {error}",
    "request.errorWhileFetchingDelivery": "Erro ao buscar a remessa: {error}",
    "request.exportAll": "Exportar tudo",
    "request.external": "Externo",
    "request.firstName": "Primeiro nome",
    "request.firstnameIsRequired": "O primeiro nome é exigido",
    "request.generalInformation": "Informações gerais",
    "request.hadBoxBeenOpened": "A caixa/engradado foi aberta por alguém antes de ser recebida (por ex. alfândega)?",
    "request.hadBoxBeenOpenedBefore": "A caixa/engradado foi aberta por alguém antes de ser recebida pelo destinatário?",
    "request.haveNotifiedFreightForwarder": "A transportadora já foi avisada ou notificada?",
    "request.haveNotifiedInsuranceCompany": "A seguradora já foi avisada ou notificada?",
    "request.howTheReasonWasIdentified": "Como o problema foi identificado e em quais circunstâncias?",
    "request.howTheReasonWasIdentifiedOptional": "Como o problema foi identificado e em quais circunstâncias? (opcional)",
    "request.ifYouWantToCommentText": "Caso deseje continuar comentando, reabra a solicitação",
    "request.installationIsRequired": "A instalação é exigida",
    "request.interestedPeople": "Pessoas interessadas",
    "request.isInWarrantyUntil": "Período de garantia ativo até {date}",
    "request.item": "Item",
    "request.itemCount": "Contagem de itens",
    "request.keptPartCondition": "Em que condições a peça reclamada foi mantida após o recebimento?",
    "request.lastModified": "Última modificação",
    "request.lastName": "Sobrenome",
    "request.lastnameIsRequired": "O sobrenome é exigido",
    "request.multipleItems": "Vários itens ({count})",
    "request.newSparePartsClaim": "Nova reclamação sobre peças de reposição",
    "request.noRequestsFound": "Nenhuma solicitação encontrada",
    "request.nonTechnical": "Atendimento ao cliente",
    "request.notification.completedSparePartClaim": "A reclamação de peça de reposição está completa",
    "request.notification.newAttachmentAdded": "Foi adicionado um {link}",
    "request.notification.newAttachmentAdded.linkText": "anexo novo",
    "request.notification.newRequest": "Foi criada uma nova solicitação",
    "request.notification.newSparePartClaim": "Foi criada uma nova reclamação de peça de reposição",
    "request.notification.solutionPlanIsProvided": "O plano de ação foi fornecido",
    "request.numberDoesNotMatchAccount": "O número do documento inserido não corresponde aos detalhes de sua conta. Verifique e tente novamente ou entre em contato com a Wärtsilä.",
    "request.openFullRequest": "Abrir solicitação inteira",
    "request.openRequests": "Solicitações",
    "request.optional": "(opcional)",
    "request.orderInformation": "Informações do pedido",
    "request.orderNumber": "Número do pedido Wärtsilä",
    "request.part": "Peça",
    "request.phone": "Telefone",
    "request.pleaseAttachDocuments": "Documentos de apoio",
    "request.pleaseEnterNumber": "Insira um número de documento",
    "request.poNumber": "Seu número do pedido",
    "request.possibleActionsAlreadyTaken": "Ações já tomadas",
    "request.possibleActionsAlreadyTakenOptional": "Ações já tomadas (opcional)",
    "request.postalCode": "Código postal",
    "request.productReferenceType": "Tipo de referência do produto",
    "request.provideDetailedDescription": "Descrição detalhada do problema",
    "request.provideDocumentNameReferenceNumber": "Nome do documento e número de referência do documento de remessa incorreto",
    "request.provideMeasurementsClaimed": "Medidas da peça reclamada",
    "request.provideMeasurementsCorrect": "Medidas da(s) peça(s) necessária(s)/correta(s).",
    "request.provideMeasurementsReceived": "Medidas da(s) peça(s) recebida(s).",
    "request.provideMeasurementsRequired": "Medidas da peça necessária (correta)",
    "request.provideTypeSerialNumber": "No caso de equipamentos auxiliares, forneça o tipo e o número de série da unidade e do componente.",
    "request.quantity": "Quantidade",
    "request.rating": "Classificação",
    "request.rating.approveLabel": "Você está satisfeito com o serviço?",
    "request.rating.customerServiceRegards": "Atenciosamente,\n\nWärtsilä",
    "request.rating.customerServiceThankYou": "Obrigado,\n\nWärtsilä",
    "request.rating.feedbackGiven": "Feedback fornecido",
    "request.rating.feedbackSent": "Feedback enviado",
    "request.rating.notSatisfied": "Insatisfeito",
    "request.rating.offlineCouldNotLoadTicket": "Não foi possível carregar o tíquete para feedback offline.",
    "request.rating.offlineRequestRatedAlready": "A solicitação já foi avaliada",
    "request.rating.offlineRequestRatedAlreadyMessage": "A solicitação \"{requestTitle}\" já foi avaliada mas você ainda pode deixar um comentário.",
    "request.rating.offlineRequestRatedSuccessfully": "Avaliação da solicitação bem sucedida",
    "request.rating.offlineRequestRatedSuccessfullyMessage": "Obrigado por avaliar \"{requestTitle}\".",
    "request.rating.offlineSendFeedback": "Enviar feedback",
    "request.rating.offlineSendingFeedback": "Enviando feedback...",
    "request.rating.offlineSendingFeedbackFailed": "O envio do feedback falhou.",
    "request.rating.offlineSendingReopenRequest": "Enviando solicitação de reabertura...",
    "request.rating.offlineSendingReopenRequestFailed": "Envio de solicitação de reabertura falhou.",
    "request.rating.openFeedback": "Abra um feedback para melhorar nossos serviços",
    "request.rating.openFeedbackPlaceholder": "Você pode deixar um comentário...",
    "request.rating.openFeedbackToImproveOurService": "Abra um feedback para melhorar nossos serviços",
    "request.rating.reasonForReopening": "Motivo para a reabertura",
    "request.rating.reasonIsRequired": "O motivo para a reabertura é necessário.",
    "request.rating.reasonPlaceholder": "Digite o motivo...",
    "request.rating.reopenButtonText": "Abrir novamente",
    "request.rating.reopenDialogTitle": "Abrir novamente a solicitação",
    "request.rating.reopenRequest": "Abrir novamente a solicitação",
    "request.rating.requestIsClosed": "A solicitação está fechada",
    "request.rating.requestIsClosedNewRequestLinkText": "nova solicitação",
    "request.rating.requestIsClosedRatingTitle": "A solicitação está fechada e não pode mais ser avaliada.",
    "request.rating.requestIsClosedTitle": "A solicitação está fechada e não pode mais ser reaberta.\nCrie uma nova {newRequestLink}.",
    "request.rating.requestReopened": "Solicitação reaberta",
    "request.rating.requestReopenedText": "A solicitação foi reaberta e entraremos em contato o mais rápido possível.",
    "request.rating.satisfied": "Satisfeito",
    "request.rating.sendFeedback": "Enviar",
    "request.rating.suggestionIsRequired": "É necessário um feedback.",
    "request.rating.thankYouForYourFeedback": "Obrigado pelo seu feedback",
    "request.rating.thankYouForYourFeedbackText": "Estamos sempre procurando maneiras de aprimorar nosso atendimento ao cliente e seu feedback é muito valioso.",
    "request.rating.weHaveAlreadyReceivedFeedback": "Já recebemos feedback para esta solicitação. Não é mais possível enviar feedback.",
    "request.rating.weWillRedirectYou": "Agora o enviaremos para {link}",
    "request.rating.whatCanWeDoBetter": "Digite o feedback...",
    "request.reason": "Motivo",
    "request.reason.9999": "Defeito de qualidade da peça",
    "request.reason.DD13": "Documentação e fatura da remessa",
    "request.reason.DD16": "Quantidade de peças errada / remessa incompleta",
    "request.reason.DD18": "Peça incorreta",
    "request.reason.DD22": "Remessa com atraso",
    "request.reason.DD23": "Remessa perdida (remessa inteira perdida)",
    "request.reason.DD24": "Embalagem de má qualidade / remessa danificada",
    "request.reason.QD02": "Peça vazando",
    "request.reason.QD03": "Peça não funciona",
    "request.reason.QD07": "Peça enferrujada",
    "request.reason.QD08": "Peça danificada",
    "request.reason.QD09": "Peça não encaixa",
    "request.reason.QD12": "Problema de classificação da peça",
    "request.reason.QD14": "Problema relacionado com a marcação / etiqueta da peça",
    "request.recentlyOpened": "Solicitações abertas recentemente",
    "request.requestId": "ID da solicitação",
    "request.requestType": "Tipo de solicitação",
    "request.requestTypeIsRequired": "Selecione um tipo de solicitação",
    "request.runningHours": "Horas de operação",
    "request.runningHoursDate": "Atualizando data",
    "request.runningHoursDateInFuture": "A data das horas de operação não pode ser no futuro!",
    "request.runningHoursMustBeBetween": "As horas de operação devem ser um número entre 0 e 9 999 999 999!",
    "request.runningHoursOptional": "Horas de operação (opcional)",
    "request.selectAddress": "Selecionar endereço",
    "request.selectCategory": "Selecione a categoria...",
    "request.selectClaimReason": "Selecione o motivo...",
    "request.selectClaimedItems": "Selecione o(s) item(ns) reclamado(s)",
    "request.solution": "Solução",
    "request.solutionPlan": "Plano de ação",
    "request.solutionPlanProvided": "O plano de ação foi fornecido",
    "request.solutionText": "Solução da Wärtsilä",
    "request.sparePartsClaim": "Reclamação de peça de reposição",
    "request.subject": "Assunto",
    "request.subjectIsRequired": "O assunto é exigido",
    "request.suggestedPartsText": "Caso já saiba quais peças devem ser substituídas, adicione-as à solicitação.",
    "request.suggestedSpareParts": "Peças de reposição sugeridas",
    "request.suggestionToReplaceParts": "Sugestão para a substituição de peças",
    "request.summary": "Resumo",
    "request.technical": "Solicitação de suporte técnico ou garantia",
    "request.title": "Título",
    "request.titleIsRequired": "O título é exigido",
    "request.track": "Rastrear",
    "request.typePartNameOrNumber": "Digite o nome ou o número da peça",
    "request.types.allRequests": "Todas as solicitações",
    "request.uploadsAreNotFinished": "Os uploads não estão terminados!",
    "request.validation.deliveryIsRequired": "É necessária a entrega!",
    "request.validation.detailedReasonIsRequired": "É necessário o motivo detalhado!",
    "request.validation.fieldIsRequired": "Campo necessário!",
    "request.validation.itemIsRequired": "É necessário o item!",
    "request.validation.quantityIsRequired": "É necessária a quantidade reclamada!",
    "request.validation.quantityMustBeNumberBetween": "Número inserido inválido! (min: {min}, max: {max})",
    "request.validation.quantityMustBeNumeric": "A quantidade reclamada deve ser um número!",
    "request.validation.reasonIsRequired": "A razão é exigida!",
    "request.wartsilaInitiated": "Iniciada pela Wärtsilä",
    "request.wasBoxVisiblyDamaged": "Se as peças dentro do pacote estavam danificadas, a caixa ou o engradado estava visivelmente danificada?",
    "request.wasForwarderInformed": "Se a caixa ou o engradado estava visivelmente danificada no recebimento, a transportadora foi informada no momento da entrega?",
    "request.wasOutsideOfBoxDamaged": "A parte externa da caixa ou engradado estava danificada?",
    "request.wasPackagingMaterialDamaged": "O material de embalagem dentro da caixa estava danificado?",
    "request.wasPackingMaterialDamagedInsufficient": "O material de embalagem dentro da caixa estava danificado ou era insuficiente?",
    "request.wasPartInUseLeakage": "A peça estava em uso quando o vazamento foi notado? Em caso afirmativo, durante quantas horas de operação?",
    "request.wasPartInUseProblem": "A peça estava em uso quando o problema foi notado? Em caso afirmativo, durante quantas horas de operação?",
    "request.wasPartInspectedByWartsila": "Se a parte estava em uso, a peça foi inspecionada e instalada por funcionários da Wärtsilä?",
    "request.whenCorrosionNoticed": "Quando a corrosão foi percebida?",
    "request.whenDeliveryExpected": "Quando a remessa era esperada?",
    "request.whenDeliveryReceived": "Quando a remessa foi recebida?",
    "request.whenWasDamageNoticed": "Quando o dano foi percebido",
    "request.yourClaimReference": "Sua referência de reclamação",
    "requestStatus.answered": "Terminado",
    "requestStatus.approved": "Aprovado",
    "requestStatus.closed": "Fechado",
    "requestStatus.closedChangedToProject": "Fechado - alterado para o projeto",
    "requestStatus.draft": "Esboço",
    "requestStatus.inProgress": "Em andamento",
    "requestStatus.partsInDelivery": "Peças na entrega",
    "requestStatus.rejected": "Rejeitado",
    "requestStatus.waitingForCustomerActions": "Aguardando ações do cliente",
  },
};
