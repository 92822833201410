import * as React from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";

class ScrollToTopComponent extends React.Component<RouteComponentProps & { children?: React.ReactNode }> {
  public componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}
export const ScrollToTop = withRouter<
  RouteComponentProps & { children?: React.ReactNode },
  typeof ScrollToTopComponent
>(ScrollToTopComponent);
