import { sortBy, uniqBy } from "lodash";
import moment from "moment";
import { IEquipment } from "online-services-types";
import React, { useCallback, useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Dot,
  Line,
  LineChart,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipPayload,
  XAxis,
  YAxis,
} from "recharts";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { Button, ButtonStyle } from "src/design-system/Button/Button";
import { Container } from "src/design-system/Container";
import { FlexContainer } from "src/design-system/Container";
import colors from "src/design-system/Tokens/colors";
import { Grid, GridRowLargeItems } from "src/design-system/Tokens/grid";
import { fontSizes } from "src/design-system/Tokens/typography";
import * as tool from "src/design-system/Tooltip";
import { IListData } from "src/design-system/Tooltip";
import { ArrowLeftIcon, ArrowRightIcon, IconSize } from "src/icons";
import { translateString } from "src/util/localization";
import {
  Baseline,
  Box,
  Circle,
  EquipmentButton,
  getDefaultColor,
  ISFOCData,
  ISFOCQuarter,
  ISFOCQuarterWithEquipment,
  MultiTooltipLabel,
  MultiTooltipLabelLong,
  MultiTooltipText,
  MultiTooltipTextLong,
  sliceToTheFirstApprovedQuarter,
  sortByNickname,
  sortQuarters,
  TitleWrapper,
  Unit,
  UnitSelector,
} from "src/util/sfoc/sfocCharts";
import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";

const chartColors: Array<string> = [
  colors.graphnode.blue,
  colors.graphnode.pink,
  colors.graphnode.purple,
  colors.graphnode.beige,
  colors.graphnode.yellow,
  colors.graphnode.lightblue,
  colors.graphnode.lightgreen,
  colors.graphnode.lightgray,
  colors.graphnode.gray,
];

interface IEquipmentWithFuel extends IEquipment {
  mgoBaseline: number;
  hfoBaseline: number;
  color: string;
}

interface IQuarterAndYear {
  quarter: string;
  year: string;
}

interface IEquipmentValueColor {
  equipment: string;
  value: number;
  color: string;
  fuelType: string;
  isNoData: boolean;
}

const Marker = (props: { cx: number; cy: number; stroke: string; payload: ISFOCQuarter; dataKey: string }) => {
  // Hide marker when no data
  if (!props.payload[props.dataKey]) return;

  return props.payload.fuelType === "HFO" ? (
    <Rectangle
      key={props.payload.fuelType + props.payload.quarter + props.payload.deltaToQBgPerkWh}
      x={props.cx - 5}
      width={10}
      y={props.cy - 5}
      height={10}
      stroke={props.stroke}
      strokeWidth={2}
    />
  ) : (
    <Dot
      key={props.payload.fuelType + props.payload.quarter + props.payload.deltaToQBgPerkWh}
      cx={props.cx}
      r={5}
      cy={props.cy}
    />
  );
};

const percentTickFormatter = (tick: number) =>
  tick === 0 ? translateString("sfoc.baseline") : `${tick < 0 ? "-" : "+"}${Math.abs(tick).toFixed(1)} %`;
const khwTickFormatter = (tick: number) =>
  tick === 0 ? translateString("sfoc.baseline") : `${tick < 0 ? "-" : "+"}${Math.abs(tick).toFixed(1)}`;

export const MeasurementsChart = (props: {
  installationId: string;
  equipments: IEquipment[];
  chartData: ISFOCData[];
  quarter: string;
}) => {
  const [unit, setUnit] = useState<Unit>("%");
  const [equipments, setEquipments] = useState<IEquipmentWithFuel[]>([]);
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<string[]>([]);
  const [monthOffset, setMonthOffset] = useState(0);

  let fieldName: keyof ISFOCQuarter;
  if (unit === "%") fieldName = "deltaToQBPercent";
  if (unit === "g/kWh") fieldName = "deltaToQBgPerkWh";
  if (unit === "SFOC") fieldName = "sfocqnWithFOLeak";
  if (unit === "ISO SFOC") fieldName = "ISOCorrectedSFOC";

  const numberOfShownQuarters = 9;
  const tickStyle = { fill: getDefaultColor(), fontSize: fontSizes.xs.min };
  const yearTickStyle = { fill: getDefaultColor(), fontSize: 9, angle: 0 };

  const randomColorNoRepeats = () => {
    let colorsCopy = chartColors.slice(0);
    return function () {
      if (colorsCopy.length < 1) {
        colorsCopy = chartColors.slice(0);
      }
      const indexOfColor = Math.floor(Math.random() * colorsCopy.length);
      const color = colorsCopy[indexOfColor];
      colorsCopy.splice(indexOfColor, 1);
      return color;
    };
  };

  const colorChooser = randomColorNoRepeats();

  useEffect(() => {
    if (props.chartData) {
      const equipmentIds = Array.from(
        new Set<string>(
          props.chartData
            .filter((dataItem) => dataItem.installationId === props.installationId)
            .map((i) => i.equipmentId)
        )
      );
      const allEquipments = props.equipments
        .filter((equipment) => equipmentIds.includes(equipment.id))
        .map((eq) => {
          const installation = props.chartData.find((item) => item.equipmentId === eq.id);
          return {
            ...eq,
            color: colorChooser(),
            mgoBaseline: installation?.mgoBaseline || 0,
            hfoBaseline: installation?.hfoBaseline || 0,
          };
        });
      allEquipments.sort(sortByNickname);
      setEquipments(allEquipments);
      setSelectedEquipmentIds(allEquipments.map((eq) => eq.id));
    }
  }, [props.chartData, props.installationId]);

  const toggleEquipment = useCallback(
    (id: string) => {
      setSelectedEquipmentIds((old) => (old.includes(id) ? old.filter((oldId) => oldId !== id) : old.concat(id)));
    },
    [setSelectedEquipmentIds]
  );

  const selectUnit = useCallback(
    (selectedUnit: Unit) => {
      setUnit(selectedUnit);
    },
    [setUnit]
  );

  const CustomSingleTT = (ac: { active: boolean; payload: TooltipPayload[] }) => {
    const equip = props.equipments.find((item) => item.id === selectedEquipmentIds[0]);
    if (ac.active && equip && ac.payload && ac.payload.length > 0) {
      let value;
      if (unit === "%") value = ac.payload[0].payload.deltaToQBPercent;
      if (unit === "g/kWh") value = ac.payload[0].payload.deltaToQBgPerkWh;
      if (unit === "SFOC") value = ac.payload[0].payload.sfocqnWithFOLeak;
      if (unit === "ISO SFOC") value = ac.payload[0].payload.ISOCorrectedSFOC;

      const measurement = value === null ? translateString("sfoc.noDataMeasurement") : `${value} ${unit}`;
      const ISOCorrectedSFOC = ac.payload[0].payload.ISOCorrectedSFOC
        ? `${ac.payload[0].payload.ISOCorrectedSFOC.toFixed(4)} ${unit}`
        : "";
      const devDiv = (
        <div>
          {ISOCorrectedSFOC}
          <Circle size={10} color={value > 0 ? colors.sfoc.red : colors.sfoc.green} />
        </div>
      );
      const date = ac.payload[0].payload.measurementDate
        ? moment(ac.payload[0].payload.measurementDate).format("ll")
        : "";
      const fuelType = ac.payload[0].payload.fuelType;
      let dataForTT: IListData[];
      if (unit === "%") {
        dataForTT = [
          {
            label: translateString("sfoc.measurementPhase.SFOCSavingQB"),
            text: measurement,
          },
          {
            label: translateString("sfoc.measurementPhase.dateOfMeasurement"),
            text: date,
          },
          {
            label: translateString("sfoc.field.fuelType"),
            text: fuelType,
          },
        ];
      } else if (unit === "SFOC") {
        dataForTT = [
          {
            label: translateString("sfoc.measurementPhase.sfocqnWithFOLeak"),
            text: measurement,
          },
          {
            label: translateString("sfoc.measurementPhase.dateOfMeasurement"),
            text: date,
          },
          {
            label: translateString("sfoc.field.fuelType"),
            text: fuelType,
          },
        ];
      } else if (unit === "ISO SFOC") {
        dataForTT = [
          {
            label: translateString("sfoc.measurementPhase.ISOCorrectedSFOC"),
            text: measurement,
          },
          {
            label: translateString("sfoc.measurementPhase.dateOfMeasurement"),
            text: date,
          },
          {
            label: translateString("sfoc.field.fuelType"),
            text: fuelType,
          },
        ];
      } else {
        dataForTT = [
          {
            label: translateString("sfoc.measurementPhase.ISOCorrectedSFOC"),
            text: devDiv,
          },
          {
            label: translateString("sfoc.measurementPhase.SFOCSavingQB"),
            text: measurement,
          },
          {
            label: translateString("sfoc.measurementPhase.dateOfMeasurement"),
            text: date,
          },
          {
            label: translateString("sfoc.field.fuelType"),
            text: fuelType,
          },
        ];
      }

      return (
        <div>
          <tool.Tooltip arrow={false} title={equip.nickName} listData={dataForTT} />
        </div>
      );
    }
    return null;
  };

  const getMeasurement = (value: number, isNoData: boolean) => {
    if (isNoData) return translateString("sfoc.noDataMeasurement");
    if (unit === "%" || unit === "g/kWh") return `${value} ${unit}`;
    return value;
  };

  const CustomMultiEquipmentTT = (ac: { active: boolean; payload: TooltipPayload[] }) => {
    if (ac.active && ac.payload && ac.payload.length > 0) {
      const selectedQuarter = ac.payload[0].payload.quarter;
      const equipmentAndValue: IEquipmentValueColor[] = [];
      ac.payload.forEach((equipmentInfo) => {
        const equipNickName =
          equipments.find((item: IEquipment) => item.id === equipmentInfo.payload.equipmentId)?.nickName || "";
        let value;
        if (unit === "%") value = equipmentInfo.payload.deltaToQBPercent;
        if (unit === "g/kWh") value = equipmentInfo.payload.deltaToQBgPerkWh;
        if (unit === "SFOC") value = equipmentInfo.payload.sfocqnWithFOLeak;
        if (unit === "ISO SFOC") value = equipmentInfo.payload.ISOCorrectedSFOC;

        const fuelType = value === null ? "" : equipmentInfo.payload.fuelType;

        equipmentAndValue.push({
          equipment: equipNickName,
          color: equipmentInfo.color || "",
          value,
          fuelType,
          isNoData: equipmentInfo.payload.isNoData || value === null,
        });
      });

      let label: string = "sfoc.measurementPhase.SFOCSavingQB";
      if (unit === "SFOC") label = "sfoc.measurementPhase.sfocqnWithFOLeak";
      if (unit === "ISO SFOC") label = "sfoc.measurementPhase.ISOCorrectedSFOC";

      return (
        <div>
          <tool.Tooltip arrow={false} title={selectedQuarter}>
            <FlexContainer>
              <MultiTooltipLabel>{translateString("sfoc.phase.engine")}</MultiTooltipLabel>
              <MultiTooltipLabelLong>{translateString(label)}</MultiTooltipLabelLong>
              <MultiTooltipLabel>{translateString("sfoc.field.fuelType")}</MultiTooltipLabel>
            </FlexContainer>
            {sortBy(equipmentAndValue, "equipment").map((eq) => (
              <FlexContainer>
                <MultiTooltipText color={eq.color}>{eq.equipment}</MultiTooltipText>
                <MultiTooltipTextLong>{getMeasurement(eq.value, eq.isNoData)}</MultiTooltipTextLong>
                <MultiTooltipText>{eq.fuelType}</MultiTooltipText>
              </FlexContainer>
            ))}
          </tool.Tooltip>
        </div>
      );
    }

    return null;
  };

  // quarter range defining
  const data: ISFOCData | undefined = sliceToTheFirstApprovedQuarter(props.chartData).find((item) =>
    selectedEquipmentIds.includes(item.equipmentId)
  );
  const quarters: IQuarterAndYear[] = data
    ? uniqBy(
        data.quarters?.sort(sortQuarters)?.map((quarter, index, array) => ({
          quarter: quarter.quarter,
          year: array[index - 1]?.year !== quarter.year ? `${quarter.year}` : "",
        })),
        (q) => q.quarter
      )
    : [];
  const startQuarter = -monthOffset - numberOfShownQuarters;
  // ensures that the length of the quarter range is always the same as the number of shown quarters (defined earlier as 9 in this case) so the chart won't break
  let endQuarter = undefined;
  if (monthOffset !== 0) {
    endQuarter =
      -monthOffset <= -quarters.length + numberOfShownQuarters
        ? -quarters.length + numberOfShownQuarters
        : -monthOffset;
  }
  const quarterRange = quarters?.slice(startQuarter, endQuarter).map((q) => q.quarter);

  const setOffset = useCallback(
    (offset: number) => {
      // Direction is reversed because we use index from the end of the array slice
      setMonthOffset((old) => {
        const result = old - offset;
        if (quarterRange.length !== numberOfShownQuarters || result < 0) return old;
        else return result;
      });
    },
    [setMonthOffset, quarterRange]
  );

  if (props.chartData === undefined) {
    return <LoadingSpinner />;
  }

  const gradientOffset = () => {
    const data: ISFOCData | undefined = sliceToTheFirstApprovedQuarter(props.chartData).find((item) =>
      selectedEquipmentIds.includes(item.equipmentId)
    );
    let value = "";
    if (unit === "%") value = "deltaToQBPercent";
    if (unit === "g/kWh") value = "deltaToQBgPerkWh";
    if (unit === "SFOC") value = "sfocqnWithFOLeak";
    if (unit === "ISO SFOC") value = "ISOCorrectedSFOC";

    if (data !== undefined && data.quarters !== undefined) {
      const slice = data.quarters?.filter((q) => quarterRange.includes(q.quarter)).map((i) => i[value]);
      const dataMax = Math.max(...slice);
      const dataMin = Math.min(...slice);
      return dataMax / (dataMax - dataMin);
    }

    return 0;
  };

  const off = gradientOffset();

  const chartSingle =
    uniqBy(
      sliceToTheFirstApprovedQuarter(props.chartData)
        .find((item) => selectedEquipmentIds.includes(item.equipmentId))
        ?.quarters?.filter((q) => quarterRange.includes(q.quarter)),
      (entry: ISFOCQuarter) => entry.quarter
    ) || [];

  const multiChart = (eqId: string) => {
    const equipData = sliceToTheFirstApprovedQuarter(props.chartData).find((item) => item.equipmentId === eqId);
    const visibleQuarters: ISFOCQuarterWithEquipment[] =
      equipData?.quarters
        ?.filter((q) => quarterRange.includes(q.quarter))
        .map((q) => {
          return { ...q, equipmentId: equipData?.equipmentId || "" };
        }) || []; // add equipment ID to each quarter info, needed for correct tooltip

    const [first] = visibleQuarters;
    quarterRange.forEach((q, index) => {
      const found = visibleQuarters.find((item) => item.quarter === q);
      const previousQuarterNumber: number = parseInt(q.split("Q")[1], 10) - 1;
      const previousQuarter = `Q${previousQuarterNumber}`;
      const previousQuarterFound = visibleQuarters.find((item) => item.quarter === previousQuarter);

      if (!found && first && previousQuarterFound) {
        // Fill in missing quarter
        visibleQuarters.push({
          ...first,
          quarter: q,
          deltaToQBPercent: previousQuarterFound.deltaToQBPercent,
          deltaToQBgPerkWh: previousQuarterFound.deltaToQBgPerkWh,
          isNoData: true,
        });
      }
    });

    const uniq = uniqBy(visibleQuarters, (entry: ISFOCQuarterWithEquipment) => entry.quarter).sort(sortQuarters);
    return uniq;
  };

  return (
    <DashboardItem
      title={
        <TitleWrapper>
          <LocalizedString id="sfoc.quarterlyVerifiedSFOCMeasurements" />
          <UnitSelector unit={unit} onChange={selectUnit} />
        </TitleWrapper>
      }
    >
      <FlexContainer $spaceBetween={true}>
        <Grid
          itemsPerRow={{ ...GridRowLargeItems, mobile: 1, mobileLarge: 1 }}
          fallbackWidth={{ min: 200, max: 200 }}
          $marginBottom={6}
        >
          {equipments.map((equipment) => (
            <EquipmentButton
              key={equipment.id}
              equipment={equipment}
              mgoBaseline={equipment.mgoBaseline}
              hfoBaseline={equipment.hfoBaseline}
              checked={selectedEquipmentIds.includes(equipment.id)}
              onClick={() => toggleEquipment(equipment.id)}
              color={equipment.color}
            />
          ))}
        </Grid>
        <Container>
          <FlexContainer>
            <Container $padding={1}>
              <FlexContainer $margin={1} $centered>
                <Box size={16} />
                <LocalizedString id="sfoc.hfoMeasurement" />
              </FlexContainer>
              <FlexContainer $margin={1} $centered>
                <Circle size={16} />
                <LocalizedString id="sfoc.mgoMeasurement" />
              </FlexContainer>
            </Container>
            <Container $padding={1}>
              <FlexContainer $margin={1} $centered>
                <Baseline />
                <LocalizedString id="sfoc.baseline" />
              </FlexContainer>
            </Container>
          </FlexContainer>
        </Container>
      </FlexContainer>
      <ResponsiveContainer minWidth={400} minHeight={400}>
        {selectedEquipmentIds.length > 1 || unit === "SFOC" || unit === "ISO SFOC" ? (
          <LineChart data={quarters.filter((q) => quarterRange.includes(q.quarter))}>
            <CartesianGrid stroke={colors.secondary.bluegray80} />
            <XAxis
              xAxisId={0}
              dataKey="quarter"
              axisLine={false}
              tickLine={false}
              tick={tickStyle}
              padding={{ left: 16, right: 16 }}
              dy={0}
              dx={-0}
              type="category"
              allowDuplicatedCategory={false}
            />
            <XAxis
              xAxisId="yearLine"
              dataKey="year"
              tickLine={false}
              axisLine={false}
              tick={yearTickStyle}
              dy={-10}
              dx={0}
              label={{ value: "", angle: 0, position: "bottom" }}
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis
              yAxisId="MultiYAxis"
              padding={{ top: 16, bottom: 16 }}
              axisLine={false}
              tickLine={false}
              interval={0}
              tickCount={6}
              domain={["dataMin", "dataMax"]}
              tick={tickStyle}
              tickFormatter={unit === "%" ? percentTickFormatter : khwTickFormatter}
            />
            <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{ x: false, y: true }}
              filterNull={false}
              offset={20}
              content={CustomMultiEquipmentTT}
            />
            <ReferenceLine
              yAxisId="MultiYAxis"
              xAxisId={0}
              y={0}
              stroke={colors.secondary.gray10}
              strokeDasharray="5 5"
            />
            {equipments
              .filter((eq) => selectedEquipmentIds.includes(eq.id))
              .map((equipment) => (
                <Line
                  xAxisId={0}
                  yAxisId="MultiYAxis"
                  key={equipment.id}
                  data={multiChart(equipment.id)}
                  dataKey={fieldName}
                  color={equipment.color}
                  stroke={equipment.color}
                  strokeWidth={2}
                  isAnimationActive={false}
                  dot={Marker}
                />
              ))}
          </LineChart>
        ) : (
          <AreaChart data={chartSingle}>
            <CartesianGrid stroke={colors.secondary.bluegray80} />
            <XAxis
              xAxisId={2}
              dataKey="quarter"
              axisLine={false}
              tickLine={false}
              tick={tickStyle}
              padding={{ left: 16, right: 16 }}
              dy={0}
              dx={-0}
              type="category"
              allowDuplicatedCategory={false}
            />
            <XAxis
              xAxisId="yearLine"
              dataKey="year"
              tickLine={false}
              axisLine={false}
              tick={yearTickStyle}
              dy={-10}
              dx={0}
              label={{ value: "", angle: 0, position: "bottom" }}
              type="category"
              allowDuplicatedCategory={false}
            />
            <YAxis
              allowDecimals={true}
              yAxisId="singleYAxis"
              padding={{ top: 16, bottom: 16 }}
              axisLine={false}
              tickLine={false}
              interval={0}
              tickCount={6}
              tick={tickStyle}
              tickFormatter={unit === "%" ? percentTickFormatter : khwTickFormatter}
            />
            <Tooltip
              isAnimationActive={false}
              allowEscapeViewBox={{ x: false, y: true }}
              filterNull={false}
              offset={20}
              content={CustomSingleTT}
            />
            <ReferenceLine
              yAxisId="singleYAxis"
              xAxisId={2}
              y={0}
              stroke={colors.secondary.gray10}
              strokeDasharray="5 5"
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor={colors.sfoc.red} opacity={1} />
                <stop offset={off} stopColor={colors.sfoc.green} opacity={1} />
              </linearGradient>
            </defs>
            {equipments
              .filter((eq) => selectedEquipmentIds.includes(eq.id))
              .map((equipment) => (
                <Area
                  yAxisId="singleYAxis"
                  xAxisId={2}
                  key={equipment.id}
                  dataKey={fieldName}
                  fill="url(#colorUv)"
                  fillOpacity={1}
                  stroke={equipment.color}
                  isAnimationActive={false}
                  dot={Marker}
                />
              ))}
          </AreaChart>
        )}
      </ResponsiveContainer>
      <FlexContainer $spaceBetween>
        <Button
          buttonStyle={ButtonStyle.Icon}
          icon={<ArrowLeftIcon size={IconSize.Small} />}
          onClick={() => setOffset(-1)}
          disabled={-monthOffset <= -quarters.length + numberOfShownQuarters}
        />
        <Button
          buttonStyle={ButtonStyle.Icon}
          icon={<ArrowRightIcon size={IconSize.Small} />}
          onClick={() => setOffset(1)}
          disabled={monthOffset === 0}
        />
      </FlexContainer>
    </DashboardItem>
  );
};
