import { IArticle, IRESTPageWrapper } from "online-services-types";
import { APIFetch } from "src/APIFetch";
import { displayError } from "src/util/error";
import { getUsingOpenSearch } from "../../util/localstorage";

export async function getArticleByEquipment(equipmentId: string, chapter: string) {
  try {
    const resource = getUsingOpenSearch().toLowerCase() === "true" ? "documents/documentsnew" : "documents/documents";
    const articles = await new APIFetch<IRESTPageWrapper<IArticle>>(resource).get(undefined, {
      chapter,
      equipment: equipmentId,
    });

    if (articles.results.length !== 1) {
      return null;
    }

    return articles.results[0];
  } catch (error) {
    displayError(error);
    return null;
  }
}

export async function getArticleAndFileByEquipment(equipmentId: string, chapter: string) {
  const article = await getArticleByEquipment(equipmentId || "", chapter || "");
  if (article) {
    const htmlFiles = article.files.filter((articleFile) => articleFile.type === "XML");
    if (htmlFiles.length === 1) {
      return {
        articleId: article.id,
        fileId: htmlFiles[0].id,
      };
    }
  }

  return null;
}
