import { IAccountUser, IUserInfo } from "online-services-types";
import React, { useEffect, useState } from "react";
import { ThemedProps } from "src/design-system/Theme/theme";
import { Tooltip } from "src/design-system/Tooltip";
import { DayIcon, IconSize, NightIcon } from "src/icons";
import { translateString } from "src/util/localization";
import { getSelectedColorTheme, setSelectedColorTheme } from "src/util/localstorage";
import { getUserProfile, patchUserProfile } from "src/views/ProfileView/userProfile";
import styled from "styled-components";

const IconComponent = styled.div`
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  width: 40px;
  background-color: inherit;
  margin: 0px;
  padding: 0px;
  border: none;
  z-index: 1;
  div {
    line-height: 1.2;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -30px;
    margin-top: 3px;
    text-transform: lowercase;
    text-align: center;
    color:  ${(props: ThemedProps) => props.theme.text};
  }
`;

export const ThemeSelectorComponent = (props: { userInfo: IUserInfo; setUserInfo: (info: IUserInfo) => void }) => {
  const [theme, setTheme] = useState<string>(getSelectedColorTheme().toLowerCase());
  const [user, setUser] = useState<IAccountUser | null>();

  useEffect(() => {
    let currentUser;
    (async function setCurrentUser() {
      currentUser = await getUserProfile();
      setUser(currentUser);
    })();
  }, []);

  let icon;
  if (theme === "light") {
    icon = (
      <>
        <NightIcon size={IconSize.Medium} />
        <StyledTooltip title={translateString("darkModeTheme")} />
      </>
    );
  } else if (theme === "dark") {
    icon = (
      <>
        <DayIcon size={IconSize.Medium} />
        <StyledTooltip title={translateString("lightModeTheme")} />
      </>
    );
  }

  const onThemeChange = async (theme: string) => {
    if (user) {
      setSelectedColorTheme(theme);
      await updateUser(user, theme);
      setTheme(theme);
    }
  };

  const updateUser = async (oldUser: IAccountUser, newTheme: string) => {
    const success = await patchUserProfile({ theme: newTheme });
    props.setUserInfo({ ...props.userInfo, theme: newTheme });
    setUser({ ...oldUser, theme: newTheme });
    if (!success) {
      setUser(oldUser);
    }
  };

  let newTheme = theme === "dark" ? "light" : "dark";

  return <IconComponent onClick={() => onThemeChange(newTheme)}>{icon}</IconComponent>;
};
