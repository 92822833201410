import * as React from "react";
import styled from "styled-components";

import { WarningIcon, IconSize } from "src/icons";
import { inputTokens, spacer, spacers } from "../Tokens/tokens";
import { P } from "../Tokens/typography";

const WarningIconWrapper = styled.div`
  margin-right: ${spacer(1)};
`;

const ErrorText = styled(P)`
  margin-bottom: 0;
`;

const ErrorWrapper = styled.div`
  display: flex;
  width: ${inputTokens.inputWidth};
  margin: ${spacers([1, 0, 1, 0])};
`;

export const ErrorComponent: React.FC<any> = (props) => (
  <ErrorWrapper className="error-component">
    <WarningIconWrapper>
      <WarningIcon size={IconSize.Small} />
    </WarningIconWrapper>
    <ErrorText>{props.children}</ErrorText>
  </ErrorWrapper>
);
