// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_ru: ITranslations = {
  ru: {
    "purchaseStatus.confirmed": "Подтверждено",
    "purchaseStatus.draft": "Эскиз",
    "purchaseStatus.expired": "Истек срок",
    "purchaseStatus.inProcess": "В процессе обработки",
    "purchaseStatus.ordered": "Заказано",
    "purchaseStatus.quoted": "Котировка предоставлена",
    "spareParts": "Запасные части",
    "spareParts.addLineItems": "Добавить товарные позиции",
    "spareParts.addManually": "Добавить вручную",
    "spareParts.addMultipleLineItems": "Добавить несколько товарных позиций",
    "spareParts.addressSaveFailure": "Ошибка сохранения адреса. Повторите попытку.",
    "spareParts.addressSaveSuccess": "Адрес успешно сохранен",
    "spareParts.addressWillBeInformedLater": "Адрес будет сообщен позднее",
    "spareParts.agreeToTermsAndConditionsIsRequired": "Вы должны согласиться с положениями и условиями",
    "spareParts.agreeWith": "Согласен с",
    "spareParts.allowPartiaDelivery": "Разрешить частичную поставку",
    "spareParts.askForQuotation": "Запросить котировку",
    "spareParts.askForQuote": "Запросить котировку",
    "spareParts.availability": "Эксплуатационная готовность",
    "spareParts.availabilitySubjectDisclamer": "Доступность обусловлена предыдущими закупками.",
    "spareParts.basketCreatedBy": "Корзина создана (кем)",
    "spareParts.basketPriceDisclaimer": "Поставщик включает в счет-фактуру минимальную сумму 200 €‚ если общая сумма вашего заказа меньше суммы в валюте, эквивалентной 200 €.",
    "spareParts.basketWillBeDiscarded": "В корзине есть несохраненные изменения. Если вы покинете эту страницу, то корзина опустеет. Вы действительно хотите продолжить?",
    "spareParts.blockedCustomerMessage": "К сожалению, нельзя продолжить. Пожалуйста, свяжитесь с компанией Wärtsilä.",
    "spareParts.browseSpareParts": "Просмотреть запасные части",
    "spareParts.chooseProduct": "Выбрать изделие...",
    "spareParts.contactInformation": "Контактная информация",
    "spareParts.copy": "Копия",
    "spareParts.copyItemsPrompt": "Скопируйте номера деталей в приведенную ниже форму",
    "spareParts.createdBy": "Создано",
    "spareParts.createfirstOrder": "Создайте свой первый заказ",
    "spareParts.creationDate": "Дата создания",
    "spareParts.currency": "Валюта",
    "spareParts.deleteBasketDraft": "Удалить черновик",
    "spareParts.deliveryAddress": "Адрес доставки",
    "spareParts.deliveryAddressIsFinalDestination": "Адрес доставки в конечном пункте назначения",
    "spareParts.deliveryInformation": "Информация о поставке",
    "spareParts.deliveryLocation": "Место доставки",
    "spareParts.deliveryLocationIsRequired": "Требуется место доставки",
    "spareParts.deliveryMode": "Режим доставки",
    "spareParts.deliveryModeIsRequired": "Требуется режим доставки",
    "spareParts.deliveryTerms": "Условия поставки",
    "spareParts.deliveryTermsIsRequired": "Требуются условия поставки",
    "spareParts.deliverymode.AirFreight": "Авиаперевозка",
    "spareParts.deliverymode.Courier": "Курьер",
    "spareParts.deliverymode.SeaFreight": "Морская перевозка",
    "spareParts.deliverymode.ToBeCollected": "Самовывоз",
    "spareParts.deliverymode.Truck": "Грузовой автомобиль",
    "spareParts.description": "Описание",
    "spareParts.discard": "Изъять",
    "spareParts.dispatchingInformation": "Информация об отгрузке",
    "spareParts.draft": "Эскиз",
    "spareParts.draftDeleteFailure": "Ошибка удаления черновика",
    "spareParts.draftDeleteSuccess": "Черновик корзины успешно удален",
    "spareParts.draftName": "Название черновика",
    "spareParts.draftNameIsRequired": "Требуется название черновика",
    "spareParts.draftSaveFailure": "Ошибка сохранения черновика корзины.",
    "spareParts.draftSaveSuccess": "Черновик корзины успешно сохранен",
    "spareParts.draftUpdateSuccess": "Черновик корзины успешно обновлен",
    "spareParts.editInformation": "Редактировать информацию",
    "spareParts.emailAddresses": "Электронные адреса",
    "spareParts.emptyBasket": "Ваша корзина для покупок пуста",
    "spareParts.errorFetchingPDF": "Ошибка при выборке подробных данных по PDF",
    "spareParts.estimatedWeight": "Расчетная масса деталей",
    "spareParts.etaDate": "Запрашиваемая дата ожидаемого времени прибытия (ETA)",
    "spareParts.etaDateIsRequired": "Требуется дата ожидаемого времени прибытия (ETA)",
    "spareParts.export": "Экспорт",
    "spareParts.exportAsCSV": "Экспортировать как CSV",
    "spareParts.exportAsPDF": "Экспортировать как PDF",
    "spareParts.failedFetchingManualParts": "Ошибка сети",
    "spareParts.filterPurchases": "Фильтровать покупки",
    "spareParts.finalDestination": "Конечный пункт назначения",
    "spareParts.finalDestinationIsDifferent": "Адрес конечного пункта назначения отличается",
    "spareParts.freeComment": "Дополнительная информация",
    "spareParts.freeCommentPlaceholder": "Например, специальные инструкции по упаковке, запросы на документацию и т. д.",
    "spareParts.freightCosts": "Стоимость фрахта",
    "spareParts.freightCostsOnlyAddedAtInvoicing": "При выставлении счета-фактуры добавлены только транспортные издержки",
    "spareParts.generalInformation": "Общая информация",
    "spareParts.goodsTotal": "Общее количество товаров",
    "spareParts.howToDispatchOrder": "Какую вы хотите отгрузку заказа?",
    "spareParts.identificationRequired": "Требуется техническая идентификация",
    "spareParts.inAccordanceWithITCode": "В соответствии со ст. 1341 и 1342 Итальянского Гражданского кодекса",
    "spareParts.inStock": "В НАЛИЧИИ",
    "spareParts.isDeliveryAddressFinalDestinationAddress": "Является ли адрес доставки конечным пунктом назначения для заказа?",
    "spareParts.lastItemDialogText": "Вы действительно хотите удалить этот товар из корзины?",
    "spareParts.lastItemDialogTitle": "Удаление товара",
    "spareParts.lastModified": "Последнее изменение",
    "spareParts.leadTime": "Срок поставки",
    "spareParts.lineItems": "Товарные позиции",
    "spareParts.loadBasketDraft": "Загрузить черновик",
    "spareParts.loadMore": "Загрузить еще",
    "spareParts.location": "Расположение",
    "spareParts.makeAnotherOrder": "Создать другой заказ",
    "spareParts.makeAnotherQuote": "Создать другую котировку",
    "spareParts.markings.optional": "Маркировка (как опция)",
    "spareParts.minimumPossibleBusinessDateLimit": "Самая ранняя возможная дата это 3 рабочих дня, начиная с настоящего момента",
    "spareParts.nextStep": "Следующий шаг",
    "spareParts.noCatalogueFound": "Извините, еще недоступен каталог изделий для",
    "spareParts.noDescription": "Нет доступного описания",
    "spareParts.noFilteredPartFound": "Извините, не найдена деталь для",
    "spareParts.noItemsAvailable": "Нет доступных товаров",
    "spareParts.noPDFinSAP": "PDF недоступен, обратитесь к своей команде, отвечающей за запасные части.",
    "spareParts.noPicturesFound": "Фотографии не найдены",
    "spareParts.noQuotationsOrOrders": "Вы не создали какие-либо котировки или заказы.",
    "spareParts.noSalesDocumentsFound": "Торговые документы не найдены",
    "spareParts.notOfficialQuotationDisclaimer": "Это неофициальная котировка. Цены устанавливаются в соответствии с мировой прейскурантной ценой согласно прейскуранту на дату покупки. Доступность обусловлена предыдущими закупками.",
    "spareParts.on": "Вкл.",
    "spareParts.onlyAllowCompleteOrder": "Разрешен только завершенный заказ",
    "spareParts.onlyNumberOrNameRequired": "В этой таблице для детали требуется указать только номер или название запасной части. На следующем экране появятся возможности добавить дополнительные подробные данные по детали.",
    "spareParts.order": "Заказ",
    "spareParts.orderConfirmed": "Теперь ваш заказ подтвержден",
    "spareParts.orderDetails": "Подробные данные по заказу",
    "spareParts.orderFullDispatch": "Ваш заказ полностью отгружен",
    "spareParts.orderNew": "Создан новый заказ",
    "spareParts.orderPartialDispatch": "Ваш заказ частично отгружен",
    "spareParts.orderReceived": "Заказ получен",
    "spareParts.partDescription": "Описание детали",
    "spareParts.partDescriptionIsRequired": "Требуется описание детали",
    "spareParts.partNameOrNumber": "Наименование/номер детали",
    "spareParts.partNotFound": "Деталь не найдена. Укажите дополнительную информацию.",
    "spareParts.partNumber": "Номер детали",
    "spareParts.partOrder.pleaseNoteMayTakeUpMinutes": "Может потребоваться несколько минут, чтобы ваш заказ появился в моих запасных частях.",
    "spareParts.partOrder.useReferenceNumber": "Вы можете использовать свой ссылочный номер для поиска вашего заказа в моих запасных частях.",
    "spareParts.partOrder.weHaveReceived": "Мы получили ваш заказ.",
    "spareParts.partOrder.weWillGetBackToYouOrder": "Мы свяжемся в вами сразу же, как только обработаем ваш заказ.",
    "spareParts.partWeight": "Масса",
    "spareParts.partWeightUnit": "Единица измерения массы",
    "spareParts.pictures": "Фотографии",
    "spareParts.price": "Цена",
    "spareParts.priceNoVatDisclaimer": "Цены без НДС, который будет добавлен на основе применяемого действующего законодательства об НДС в момент выставления счета-фактуры.",
    "spareParts.product": "Продукт",
    "spareParts.productId": "Идентификатор изделия",
    "spareParts.productIsNotSelected": "Изделие не выбрано",
    "spareParts.purchases": "Покупки",
    "spareParts.qtySection": "Количество в этом разделе",
    "spareParts.quantity": "Количество",
    "spareParts.quantityUnit": "Единица измерения количества",
    "spareParts.quotation": "Котировка",
    "spareParts.quotationCannotBeCopied": "Котировку с неизвестными деталями нельзя копировать",
    "spareParts.quotationCreated": "Создана новая котировка",
    "spareParts.quotationQuoted": "Цена по вашей котировке подтверждена",
    "spareParts.quotationReceived": "Котировка получена",
    "spareParts.quotationRequest.pleaseNoteMayTakeUpMinutes": "Может потребоваться несколько минут, чтобы ваша котировка появилась в моих запасных частях.",
    "spareParts.quotationRequest.useReferenceNumber": "Вы можете использовать свой ссылочный номер для поиска вашей котировки в моих запасных частях.",
    "spareParts.quotationRequest.weHaveReceived": "Мы получили вашу котировку.",
    "spareParts.quotationRequest.weWillGetBackToYouQuotation": "Мы свяжемся в вами сразу же, как только обработаем вашу котировку.",
    "spareParts.quoteDetails": "Подробные данные по котировке",
    "spareParts.referenceType": "Тип ссылки",
    "spareParts.requestPDF": "Запросить PDF",
    "spareParts.rows": "Строки",
    "spareParts.saveAddress": "Сохранить адрес",
    "spareParts.saveInformation": "Сохранить информацию",
    "spareParts.saveProgressForLater": "Вы хотите сохранить незавершенное выполнение для дальнейшего применения?",
    "spareParts.selectProduct": "Выбрать изделие",
    "spareParts.serialNumber.optional": "Серийный номер (как опция)",
    "spareParts.serviceGridButtonTitle": "Коммерческая деятельность",
    "spareParts.shoppingCart": "Корзина для покупок",
    "spareParts.shoppingCartEmpty": "Корзина для покупок пустая",
    "spareParts.sizeAndDimensions.optional": "Размер и габариты (как опция)",
    "spareParts.sorting.purchaseTypeAZ": "Тип покупки (A-Z)",
    "spareParts.specificClauses": "Особые оговорки",
    "spareParts.subtotal": "Промежуточная сумма",
    "spareParts.summary": "Краткое описание",
    "spareParts.termsAndConditions": "положениями и условиями",
    "spareParts.termsConditionsDisclaimerPDF": "Все продажи деталей подпадают под действие общих положений и условий – Части (2021). Если сумма, которую поставщик должен включить в счет-фактуру, будет меньше суммы в валюте, эквивалентной 200 евро, то поставщик произведет дополнительное начисление, и минимальная сумма, выставляемая в счете-фактуре, составит 200 евро, или же эквивалентную сумму в применяемой валюте, с добавлением оплаты транспортных затрат при их наличии.",
    "spareParts.termsDisclaimer": "Если вы подписали Договор поставки запасных частей с компанией Wärtsilä, который действует при оформлении заказа на поставку, то будут применяться только положения и условия такого договора.",
    "spareParts.thankYou": "Благодарим вас",
    "spareParts.thereWasErrorWhenSendingOrder": "Произошла ошибка при отправке вашего заказа.",
    "spareParts.thereWasErrorWhenSendingQuotation": "Произошла ошибка при отправке вашей котировки.",
    "spareParts.total": "Total",
    "spareParts.totalSum": "Общая сумма",
    "spareParts.typeInEmails": "Ввести адреса электронной почты",
    "spareParts.typePartNameOrNumber": "Наименование детали или номер",
    "spareParts.unsavedChanges": "Несохраненные изменения",
    "spareParts.usageTarget.optional": "Применение/Система/Узел (как опция)",
    "spareParts.vatDisclaimer": "Цены без НДС, который будет добавлен на основе применяемого действующего законодательства об НДС в момент выставления счета-фактуры.",
    "spareParts.vendorCode.optional": "Код детали поставщика (как опция)",
    "spareParts.wartsilaReference": "Ссылочный номер компании Wärtsilä",
    "spareParts.youCanAddPartsUsingFollowingAction": "Вы также можете добавлять запасные части с помощью следующего действия",
    "spareParts.yourReference": "Ваш ссылочный номер",
    "spareParts.yourReferenceIsRequired": "Требуется ваш ссылочный номер",
    "spareparts.errorFetchingDetails": "Ошибка при выборке подробных данных по заказу",
  },
};
