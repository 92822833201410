import { IAuthJWTData, IInfoJWTData, IUserInfo } from "online-services-types";
import { getRoutes, redirectBrowser, shouldRedirectToLogin } from "src/routes";

import { Sector } from "src/util/localization";
import { displayError } from "src/util/error";
import jwt_decode from "jwt-decode";
import { redirectToLogin } from "src/redux/auth";

interface IJWTParsingParams {
  setUserAsLoggedIn: () => void;
  setUserInfo(userInfo?: IUserInfo): void;
  setApplicationContext(contextData: IInfoJWTData): void;
  setSector(sector: Sector): void;
}

export function handleJWTOnLogin(params: IJWTParsingParams) {
  const token = localStorage.getItem("token");
  const info = localStorage.getItem("info");

  if (!token || !info) {
    if (shouldRedirectToLogin(window.location.pathname)) {
      redirectToLogin(window.location);
    }
  } else {
    params.setUserAsLoggedIn();
    try {
      const decodedInfoJWT: IInfoJWTData = jwt_decode(info);
      const decodedAuthJWT: IAuthJWTData = jwt_decode(token);

      if (decodedInfoJWT) {
        // Set user info from JWT
        params.setUserInfo(Object.assign({}, decodedInfoJWT.userInfo));
        params.setApplicationContext(
          Object.assign(
            {
              readonly: decodedAuthJWT.readonly,
              punchOut: decodedAuthJWT.punchOut,
              punchOutSessionId: decodedAuthJWT.punchOutSessionId,
            },
            decodedInfoJWT
          )
        );
        params.setSector(decodedInfoJWT.userInfo.mainSector as Sector);

        // Only redirect to installation details if trying to open the index view

        if (decodedInfoJWT.userInfo.singleVisibleInstallation && window.location.pathname === "/") {
          redirectBrowser(getRoutes().Installation);
        }
      } else {
        throw new Error("Could not decode JWT");
      }
    } catch (error) {
      displayError(`Error in initializing application: ${error}`);
    }
  }
}
