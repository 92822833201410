import styled from "styled-components";
import colors from "src/design-system/Tokens/colors";
import { IInstallation } from "online-services-types";
import { spacer, spacers } from "src/design-system/Tokens/tokens";
import { breakpoints } from "src/design-system/Tokens/breakpoints";

export const RelativeRow = styled.td`
  overflow: visible;
  position: relative;
  height: 3em;
  vertical-align: top;
  border-top: ${colors.secondary.bluegray100} 1px solid;
  padding: 0 1px;
`;

export const InstallationCell = styled.td<{ hideBorder?: boolean }>`
  width: 50px;
  border-right: ${({ hideBorder }) => (!hideBorder ? `${colors.primary.white} 1px solid` : "none")};
  padding-right: ${spacer(1)};
  text-align: left;
`;

export const ManagerDashboardTableWrapper = styled.div`
  padding: ${spacers([2, 6, 0, 6])};
  @media (max-width: ${breakpoints.mobileLarge}px) {
    padding: ${spacers([2, 2, 0, 2])};
  }
`;

export const getInstallationName = (installationId: string, installations: IInstallation[]) => {
  const foundInstallation = installations.find((installation) => installation.id === installationId);

  if (!foundInstallation) {
    return "";
  }

  return foundInstallation.name;
};

export const getXPosition = (date: string, startDate: string, endDate: string) => {
  const startTicks = new Date(startDate).getTime();
  const endTicks = new Date(endDate).getTime();
  return (new Date(date).getTime() - startTicks) / (endTicks - startTicks);
};
