import React, { useCallback, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { LocalizedString } from "src/components/Localization";
import { Button, ButtonStyle } from "src/design-system/Button";
import { FlexContainer } from "src/design-system/Container";
import colors from "src/design-system/Tokens/colors";
import { fontSizes } from "src/design-system/Tokens/typography";
import * as tool from "src/design-system/Tooltip";
import { ArrowLeftIcon, ArrowRightIcon, IconSize } from "src/icons";
import { filterValidSFOCs, getDefaultColor, ISFOCFleetData } from "src/util/sfoc/sfocCharts";
import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";

interface IQuarter {
  quarter: string;
  averageQBPercent: number;
  year: string;
}

export const ImprovementsHistoryChart = (props: { chartData: ISFOCFleetData[]; quarter: string }) => {
  const [quarters, setQuarters] = useState<IQuarter[]>([]);
  const [quarterOffset, setQuarterOffset] = useState(1);
  const numberOfBars = 8;
  const tickStyle = { fill: getDefaultColor(), fontSize: fontSizes.xs.min };
  const yearTickStyle = { fill: getDefaultColor(), fontSize: 9, angle: 0 };

  useEffect(() => {
    const chartData: IQuarter[] = [];

    const amountOfQuarters = parseInt(props.quarter, 10);

    for (let i = 1; i <= amountOfQuarters; i++) {
      chartData.push({
        quarter: "Q" + i,
        averageQBPercent: 0,
        year: "1970",
      });
    }

    chartData.forEach((data: IQuarter, index: number) => {
      const allQuarterData = props.chartData
        .filter(filterValidSFOCs)
        .filter((chartSet: ISFOCFleetData) => chartSet.quarter === data.quarter);
      allQuarterData.forEach((q) => {
        chartData[index].averageQBPercent = chartData[index].averageQBPercent + q.qBPercent;
        chartData[index].year = chartData[index].year === "1970" ? `${q.year}` : chartData[index].year;
      });

      chartData[index].averageQBPercent = chartData[index].averageQBPercent / allQuarterData.length;
    });

    setQuarters(chartData);
  }, [props.quarter, props.chartData]);

  const RechartsDeltaToQB = (ac: { active: boolean; label: string }) => {
    const value = quarters.find((q) => q.quarter === ac.label)?.averageQBPercent || 0;
    const formattedValue = value.toFixed(2);

    return ac.active && formattedValue ? (
      <div style={{ visibility: "visible" }}>
        <tool.Tooltip title={formattedValue + "%"} />
      </div>
    ) : null;
  };

  const setOffset = useCallback(
    (offset: number) => {
      // Direction is reversed because we use index from the end of the array slice
      setQuarterOffset((old) => {
        const result = old - offset;
        const amountOfQuarters = parseInt(props.quarter, 10);
        if (result === 0 || result >= amountOfQuarters) {
          return old;
        } else {
          return result;
        }
      });
    },
    [setQuarterOffset]
  );

  const visibleQuarters = quarters
    .slice(-quarterOffset - numberOfBars, -quarterOffset)
    .map((quarter, index, array) => ({
      ...quarter,
      year: array[index - 1]?.year !== quarter.year ? quarter.year : "",
    }));

  const formatter = (value: string) => `${value}%`;

  return (
    <DashboardItem title={<LocalizedString id="sfoc.improvementHistory" />}>
      <ResponsiveContainer minWidth={400} minHeight={400} maxHeight={400}>
        <BarChart data={visibleQuarters}>
          <CartesianGrid stroke={colors.secondary.bluegray80} strokeDasharray="3 3" />
          <XAxis
            dataKey="quarter"
            tick={tickStyle}
            interval={0}
            tickLine={false}
            type="category"
            allowDuplicatedCategory={false}
          />
          <XAxis
            dataKey="year"
            xAxisId="year"
            tickLine={false}
            axisLine={false}
            interval={0}
            type="category"
            allowDuplicatedCategory={true}
            tick={yearTickStyle}
          />
          <YAxis
            dataKey="averageQBPercent"
            tickFormatter={formatter}
            domain={[-2, 0]}
            allowDataOverflow={true}
            tick={tickStyle}
          />
          <Tooltip
            isAnimationActive={false}
            allowEscapeViewBox={{ x: true, y: true }}
            filterNull={false}
            offset={-10}
            content={RechartsDeltaToQB}
          />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="averageQBPercent">
            {visibleQuarters.map((entry, index) => (
              <Cell key={`cell-${entry.quarter}`} fill={colors.sfoc.green} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <FlexContainer $spaceBetween>
        <Button
          buttonStyle={ButtonStyle.Icon}
          icon={<ArrowLeftIcon size={IconSize.Small} />}
          onClick={() => setOffset(-1)}
        />
        <Button
          buttonStyle={ButtonStyle.Icon}
          icon={<ArrowRightIcon size={IconSize.Small} />}
          onClick={() => setOffset(1)}
        />
      </FlexContainer>
    </DashboardItem>
  );
};
