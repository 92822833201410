import { fontBase } from "src/design-system/Tokens/typography";
import { css, createGlobalStyle } from "styled-components";
import { ThemedProps } from "./theme";

export default css`
  font-family: ${fontBase};
  color: ${(props: ThemedProps) => props.theme.text};
`;

export const GlobalDefaultStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    height: 100%;
    font-family: ${fontBase};
    margin: 0;
    background-color: ${(props: ThemedProps) => props.theme.background};
  }

  #root {
    height: 100%;
  }
  
  a {
    color: inherit;
    text-decoration: inherit;
  }

  button {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
  }

  input,
  textarea {
    max-width: 630px;
    border-radius: 0;
    -webkit-appearance: none;
  }

  svg {
      pointer-events: none;
  }
`;
