import React from "react";
import { LocalizedString } from "src/components/Localization";
import { Container } from "src/design-system/Container";
import { DividerLine } from "src/design-system/Divider/Divider";
import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import { HalfStarIcon, IconSize } from "src/icons";
import { formatDate } from "src/util/formatters";
import styled from "styled-components";

export interface IReleaseNote {
  releaseVersion: string;
  releaseDate: string;
  releaseNotes: string[];
}

export const ReleaseNoteVersion = styled.p`
  margin-right: ${spacer(1)};
  margin-bottom: 0;
  padding: 0;
  font-weight: bold;
`;

export const ReleaseNoteDate = styled.p`
  margin-right: ${spacer(1)};
  margin-bottom: 0;
  padding: 0;
`;

const ReleaseNoteStar = styled.p`
  margin-right: ${spacer(1)};
  margin-bottom: 0;
  padding: 0;
`;

const ReleaseNoteTextWrapper = styled.div<ThemedProps>`
  width: 70%;
  margin: 0 0 ${spacer(2)} 0;
  color: ${(props) => props.theme.text};
`;

const VersionAndDate = styled.div`
  display: flex;
`;

const NoteItem = styled.div`
  margin: 5px 0px 5px 20px;
  display: list-item;
  font-size: 13px;
`;

export const ReleaseNoteComponent = (props: { isUnseen: boolean; releaseNote: IReleaseNote; hideDivider: boolean }) => {
  return (
    <Container>
      <ReleaseNoteTextWrapper>
        <VersionAndDate>
          <ReleaseNoteVersion>
            <LocalizedString id="releaseNotes.version" values={{ version: props.releaseNote.releaseVersion }} />
          </ReleaseNoteVersion>
          <ReleaseNoteDate>{formatDate(props.releaseNote.releaseDate)}</ReleaseNoteDate>
          {props.isUnseen && (
            <ReleaseNoteStar>
              <HalfStarIcon color={colors.primary.orange} size={IconSize.Small} />
            </ReleaseNoteStar>
          )}
        </VersionAndDate>
        {props.releaseNote.releaseNotes.map((note, index) => (
          <NoteItem key={index}>{note}</NoteItem>
        ))}
      </ReleaseNoteTextWrapper>
      {!props.hideDivider && <DividerLine $color={0} />}
    </Container>
  );
};
