import * as React from "react";

import { ArrowLeftIcon } from "./ArrowLeftIcon";
import { IIconBase } from "./IconBase";
import styled from "styled-components";

const DownIcon = styled(ArrowLeftIcon)`
  transform: rotate(270deg);
`;

export const ArrowDownIcon = ({ ...props }: IIconBase) => <DownIcon {...props} />;
