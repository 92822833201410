import Moment from "moment";
import React, { useCallback, useState } from "react";
import { LocalizedString } from "src/components/Localization";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { translateString } from "src/util/localization";
import {
  Bar,
  BlockTable,
  calculateScaleObjectsForDevelopmentCharts,
  DataCell,
  DeltaToQB,
  filterValidSFOCs,
  ISFOCFleetData,
  ISFOCQuarter,
  NameDataCell,
  TitleWrapper,
  Unit,
  UnitSelector,
  UnitTdInFooter,
} from "src/util/sfoc/sfocCharts";
import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";
import moment from "moment";
import { datesOfQuarters } from "../SFOCVesselDashboardView/datesOfQuarters";

interface IBrand {
  brandId: string;
  brandName: string;
  deltaToQBPercent: number;
  deltaToQBgPerkWh: number;
}

export const QuarterlyDevelopmentChart = (props: {
  chartData: ISFOCFleetData[];
  quarter: string;
  quarterEndDate: string;
  endMesurements: string;
  currentQuarter: string;
}) => {
  const [visibleTooltip, setVisibleTooltip] = useState<string>();
  const [dataUnit, setDataUnit] = useState<Unit>("%");

  const quarterValidationPeriodEnd = moment(
    datesOfQuarters && props.quarter
      ? datesOfQuarters.find((q) => q.quarter === props.quarter)?.endMesurements
      : props.endMesurements,
    "YYYY-MM-DD"
  ).add(45, "days");
  const currentDate = moment();

  const fleetDataForQuarter = props.chartData
    .filter(filterValidSFOCs)
    .filter((dataSet) => dataSet.quarter === "Q" + props.quarter);
  const brandDeviationsForCurrentQuarter: IBrand[] = [];
  let fleetQBPercent = 0;
  let fleetQBKwh = 0;

  fleetDataForQuarter.forEach((dataSet) => {
    const existingBrand = brandDeviationsForCurrentQuarter.find((brand) => brand.brandName === dataSet.accountName);
    fleetQBPercent += dataSet.qBPercent;
    fleetQBKwh += dataSet.qBKwh;

    // if it is a new brand we add
    if (existingBrand === undefined) {
      brandDeviationsForCurrentQuarter.push({
        brandId: dataSet.accountId,
        brandName: dataSet.accountName,
        deltaToQBPercent: dataSet.qBPercent,
        deltaToQBgPerkWh: dataSet.qBKwh,
      });
      // if it is a not a new brand we update
    } else if (existingBrand !== undefined) {
      existingBrand.deltaToQBPercent += dataSet.qBPercent;
      existingBrand.deltaToQBgPerkWh += dataSet.qBKwh;
    }
  });

  const brands = brandDeviationsForCurrentQuarter;
  const averageFleetQBPercent = fleetQBPercent / fleetDataForQuarter.length;
  const averageFleetQBKwh = fleetQBKwh / fleetDataForQuarter.length;

  const selectUnit = useCallback(
    (selectedUnit: Unit) => {
      setDataUnit(selectedUnit);
    },
    [setDataUnit]
  );

  let rangeStart = 0;
  let rangeEnd = 0;

  const values: { [accountId: string]: number } = {};

  const field: keyof ISFOCQuarter = dataUnit === "%" ? "deltaToQBPercent" : "deltaToQBgPerkWh";
  brands.forEach((brand) => {
    const totalForBrand = fleetDataForQuarter.filter((dataSet) => dataSet.accountName === brand.brandName).length;
    if (totalForBrand) {
      const value = brand[field] / totalForBrand;
      rangeStart = Math.floor(Math.min(rangeStart === 0 ? value : rangeStart, value));
      rangeEnd = Math.ceil(Math.max(rangeEnd === 0 ? value : rangeEnd, value));
      values[brand.brandId] = value;
    }
  });

  const { scale, zeroPoint, valueMultiplier } = calculateScaleObjectsForDevelopmentCharts(rangeEnd, rangeStart);

  const average = dataUnit === "%" ? averageFleetQBPercent : averageFleetQBKwh;

  //when SFOC reports are dripping in along the quarter the fleet view data before end of the quarter isn't showing reliable data;
  const currentQuarterHasNotEnded =
    props.quarterEndDate > Moment.utc().format() && props.quarter === props.currentQuarter;

  return (
    <DashboardItem
      title={
        <TitleWrapper>
          {" "}
          <LocalizedString id="sfoc.quarterlyDevelopment" values={{ quarter: props.quarter }} />
          <UnitSelector unit={dataUnit} onChange={selectUnit} />{" "}
        </TitleWrapper>
      }
    >
      {quarterValidationPeriodEnd.isAfter(currentDate) ? (
        <LocalizedStringComponent id={"sfoc.noValidation"} />
      ) : brands.length > 0 ? (
        currentQuarterHasNotEnded ? (
          <LocalizedStringComponent id={"sfoc.chartWillBeUpdated"} />
        ) : (
          <BlockTable>
            <tbody>
              <tr>
                <NameDataCell>{translateString("sfoc.fleet")}</NameDataCell>
                {scale.map((_, index) => (
                  <DataCell key={index} isBaseline={zeroPoint === index}>
                    {zeroPoint === index && (
                      <div
                        onMouseEnter={() => setVisibleTooltip("average")}
                        onMouseLeave={() => setVisibleTooltip(undefined)}
                      >
                        <Bar value={average * valueMultiplier} />
                        {visibleTooltip === "average" && <DeltaToQB value={average} unit={dataUnit} />}
                      </div>
                    )}
                  </DataCell>
                ))}
              </tr>
              {brands.map((b) => (
                <tr key={b.brandId}>
                  <NameDataCell>{b.brandName}</NameDataCell>
                  {scale.map((_, index) => (
                    <DataCell key={index} isBaseline={zeroPoint === index}>
                      {zeroPoint === index && (
                        <div
                          onMouseEnter={() => {
                            setVisibleTooltip(b.brandId);
                          }}
                          onMouseLeave={() => setVisibleTooltip(undefined)}
                        >
                          <Bar value={values[b.brandId] * valueMultiplier} />
                          {visibleTooltip === b.brandId && <DeltaToQB value={values[b.brandId]} unit={dataUnit} />}
                        </div>
                      )}
                    </DataCell>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <UnitTdInFooter>{dataUnit}</UnitTdInFooter>
                </td>
                {scale}
              </tr>
            </tfoot>
          </BlockTable>
        )
      ) : (
        <LocalizedStringComponent id={"sfoc.noData"} />
      )}
    </DashboardItem>
  );
};
