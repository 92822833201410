import * as React from "react";

import { ButtonForm, ButtonVariant, Button as UIButton } from "@wartsila/ui-kit";
import styled, { css } from "styled-components";

import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { getSelectedColorTheme } from "src/util/localstorage";
import { spacer } from "src/design-system/Tokens/tokens";

interface IButtonStyleProps {
  $buttonStyle: ButtonStyle;
  $buttonType?: ButtonType;
  disabled?: boolean;
  ref?: any;
  $fullwidth?: boolean;
}

const buttonHeight = "38px";

const theme = getSelectedColorTheme();

const ButtonStyles = {
  BrandNext: css`
    margin-right: ${spacer(4)};
    position: relative;
    border: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-top: ${buttonHeight} solid ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.brandBackground};
      border-right: 1.4em solid transparent;
      transition: all 0.1s;
    }
    :hover::after {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.brandHover};
    }
    :active::after {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.brandActive};
    }
    :disabled::after {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.disabled};
    }
  `,
  Next: css`
    margin-right: ${spacer(4)};
    position: relative;
    border: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-top: ${buttonHeight} solid ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.nextBackground};
      border-right: 1.4em solid transparent;
      transition: all 0.1s;
    }
    :hover::after {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.nextHover};
    }
    :active::after {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.nextActive};
    }
    :disabled::after {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.disabled};
    }
  `,
  Previous: css`
    margin-left: ${spacer(4)};
    position: relative;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    ::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      width: 0;
      height: 0;
      border-top: ${buttonHeight} solid
        ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.previousBackground};
      border-left: 1.4em solid transparent;
      transition: all 0.1s;
    }
    :hover::before {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.previousHover};
    }
    :active::before {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.previousActive};
    }
    :disabled::before {
      border-top-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.disabled};
    }
  `,
  Secondary: css`
    :not(:disabled) {
      svg {
        fill: ${colors.primary.black};
      }
    }
  `,
  Primary: css``,
  Brand: css``,
  Transparent: css`
    color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.outlineForeground} !important;
    border-color: ${theme === "quantiparts"
      ? colors.primary.white
      : (props: ThemedProps<IButtonStyleProps>) => props.theme.button.outlineForeground} !important;

    svg {
      fill: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.outlineForeground};
    }

    :hover:not(:disabled),
    :focus:not(:disabled) {
      color: ${(props: ThemedProps<IButtonStyleProps>) => colors.primary.white} !important;

      svg {
        fill: ${(props: ThemedProps<IButtonStyleProps>) => colors.primary.white} !important;
      }
    }

    :focus:not(:disabled) {
      box-shadow: 0 0 0 3px rgb(7 93 134 / 40%);
    }

    :disabled {
      color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.icon.disabledColor} !important;
      border-color: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.disabled} !important;
    }
  `,
  Icon: css`
    gap: 0 !important;
    min-width: 0 !important;
    padding: 0.5em !important;
    background-color: transparent;
    border-color: transparent;

    :hover:not(:disabled) {
      background-color: transparent;
      border-color: transparent;
    }
    svg {
      fill: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.button.outlineForeground};
    }
  `,
};

const mapToButtonVariant = {
  BrandNext: ButtonVariant.Warning,
  Next: ButtonVariant.Primary,
  Previous: ButtonVariant.Gray,
  Secondary: ButtonVariant.Gray,
  Primary: ButtonVariant.Primary,
  Transparent: ButtonVariant.Primary,
  Brand: ButtonVariant.Warning,
};

const mapToButtonForm = {
  Outline: ButtonForm.Outline,
  Link: ButtonForm.Link,
  Floating: ButtonForm.FloatingAction,
};

export const StyledButton = styled(UIButton)`
  ${(props: IButtonStyleProps) => ButtonStyles[props.$buttonStyle]};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 6.5em;
  white-space: nowrap;
  gap: ${spacer(2)};

  width: ${(props: IButtonStyleProps) => (props.$fullwidth ? "100%" : "auto")};

  :disabled {
    svg {
      fill: ${(props: ThemedProps<IButtonStyleProps>) => props.theme.icon.disabledColor} !important;
    }
  }
`;

export enum ButtonStyle {
  Next = "Next",
  BrandNext = "BrandNext",
  Previous = "Previous",
  Secondary = "Secondary",
  Primary = "Primary",
  Transparent = "Transparent",
  Brand = "Brand",
  Icon = "Icon",
}

export enum ButtonIconPlacement {
  Before = "before",
  After = "after",
}

export enum ButtonType {
  Outline = "Outline",
  Link = "Link",
  Floating = "Floating",
}

interface IButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
  // className is required to be able to override the button styles: https://www.styled-components.com/docs/advanced#existing-css
  className?: string;
  buttonStyle?: ButtonStyle;
  buttonType?: ButtonType;
  type?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconPlacement?: ButtonIconPlacement;
  style?: { [key: string]: string };
  _ref?: React.RefObject<{}>;
  variant?: ButtonVariant;
  form?: ButtonForm;
  loading?: boolean;
  fullwidth?: boolean;
}

export const Button: React.FC<IButtonProps> = (props) => {
  const buttonStyle = props.buttonStyle && ButtonStyles[props.buttonStyle] ? props.buttonStyle : ButtonStyle.Primary;
  const buttonType = props.buttonType ? props.buttonType : ButtonForm.Outline;
  const noOp = () => {
    /* */
  };
  const iconPlacement = props.iconPlacement || ButtonIconPlacement.After;

  return (
    <StyledButton
      ref={props._ref as any}
      disabled={props.disabled}
      className={props.className}
      $fullwidth={props.fullwidth}
      style={props.style}
      $buttonStyle={buttonStyle}
      // The type comes from ButtonHTMLAttributes which has no nice shorthand for the type
      type={(props.type || "button") as "submit" | "reset" | "button"}
      onClick={props.disabled ? noOp : props.onClick}
      variant={mapToButtonVariant[buttonStyle]}
      form={props.buttonType && mapToButtonForm[buttonType]}
      loading={props.loading}
    >
      {iconPlacement === ButtonIconPlacement.Before && props.icon}
      <div>{props.children}</div>
      {iconPlacement === ButtonIconPlacement.After && props.icon}
    </StyledButton>
  );
};
