import { css } from "styled-components";

export const PulseDot = css`
  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
`;

export const openingAnimationDuration = 400;
export const fastRouteTransition = 250; // in milliseconds
export const routeTransition = 300; // in milliseconds
