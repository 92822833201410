import * as React from "react";
import { Dialog } from "src/components/Dialog";
import { Container } from "src/design-system/Container";
import { RadioChoice } from "src/design-system/Radio";
import { IRadioChoiceOption } from "src/design-system/Radio/RadioChoiceComponent";
import { DelimiterExample } from "src/services/sfoc/sfoc.components";
import { translateString } from "src/util/localization";

export const DEFAULT_DELIMITER = ";";
export const getDelimiterFromString = (delimiterString: string) => {
  switch(delimiterString) {
    case "semicolon":
      return ";";
    case "comma":
      return ",";
    default:
      return DEFAULT_DELIMITER;
  }
};

type ExportDelimiterDialogProps = {
  isOpen: boolean;
  onSubmit: (delimiter: string) => void;
  onCancel: () => void;
  title?: string;
  example?: string[];
  useLiteralValue?: boolean;
};

export const ExportDelimiterDialog = ({ isOpen, onSubmit, onCancel, title, example, useLiteralValue = false }: ExportDelimiterDialogProps) => {
  const [selectedDelimiter, setSelectedDelimiter] = React.useState<string>("semicolon");

  return (
    <Dialog
      title={title || translateString("request.exportAll")}
      onCancel={onCancel}
      isDialogOpen={isOpen}
      submit={{
        buttonTitle: translateString("export"),
        callback: () => onSubmit(useLiteralValue ?
          getDelimiterFromString(selectedDelimiter) :
          selectedDelimiter
        ),
      }}
    >
      <Container>
        <p>{translateString("export.chooseDelimiter")}</p>
        <RadioChoice
          name="delimiter"
          selectedOptionId={selectedDelimiter}
          options={[
            {
              id: "semicolon",
              label: translateString("export.semicolon"),
              description: example ? <DelimiterExample>{example.join(";")}</DelimiterExample> : "",
            },
            {
              id: "comma",
              label: translateString("export.comma"),
              description: example ? <DelimiterExample>{example.join(",")}</DelimiterExample> : "",
            },
          ]}
          onOptionSelect={(option: IRadioChoiceOption) => setSelectedDelimiter(option.id)}
        />
      </Container>
    </Dialog>
  );
};
