import { IPartModel, ILineItem, IPartOrderLine, IPartAvailability } from "online-services-types";
import { merge } from "lodash";
import { findIndexOfItemInBasket } from "src/util/helpers";

const EMPTY_BASKET = "EMPTY_BASKET";
const UPDATE_AVAILABILITY = "UPDATE_AVAILABILITY";
const ADD_ITEM = "ADD_ITEM";
const REMOVE_ITEM = "REMOVE_ITEM";
const REDUCE_ITEM_AMOUNT = "REDUCE_ITEM_NUM";
const INCREASE_ITEM_AMOUNT = "INCREASE_ITEM_NUM";
const SET_QUOTATION_REF = "SET_QUOTATION_REF";
const SET_BASKET_DRAFT = "SET_BASKET_DRAFT";
const SET_ITEM_COMMENT = "SET_ITEM_COMMENT";
const SET_ITEM_ATTACHMENTS = "SET_ITEM_ATTACHMENTS";
const SAP_LINENUMBER = 100;

interface IAction {
  type: string;
  item: IPartModel;
  quantity?: number;
  maxQuantity?: number;
  displayValue?: string;
  lineNumber?: number;
  quotationReference?: {
    yourReference: string;
    wartsilaReference: string;
  };
  draftId?: string;
  draftName?: string;
  oldDraft?: ILineItem[];
  relatedSPBasket?: string;
  availability?: IPartAvailability[];
}

export const setQuotationReference = (yourReference: string, wartsilaReference: string): IAction => ({
  type: SET_QUOTATION_REF,
  item: {} as IPartModel,
  quotationReference: {
    yourReference,
    wartsilaReference,
  },
});

export const setBasketDraft = (
  draftId?: string,
  draftName?: string,
  oldDraft?: ILineItem[],
  relatedSPBasket?: string
): IAction => ({
  type: SET_BASKET_DRAFT,
  item: {} as IPartModel,
  draftId,
  draftName,
  oldDraft,
  relatedSPBasket,
});

export const decreaseItemNum = (item: IPartModel): IAction => ({
  type: REDUCE_ITEM_AMOUNT,
  item,
});

export const increaseItemNum = (item: IPartModel): IAction => ({
  type: INCREASE_ITEM_AMOUNT,
  item,
});

export const addItem = (
  item: IPartModel,
  quantity?: number,
  maxQuantity?: number,
  displayValue?: string,
  lineNumber?: number
): IAction => ({
  type: ADD_ITEM,
  item,
  quantity,
  maxQuantity,
  displayValue,
  lineNumber,
});

export const updateAvailability = (availability?: IPartAvailability[]): IAction => ({
  type: UPDATE_AVAILABILITY,
  item: {} as IPartModel,
  availability,
});

export const removeItem = (item: IPartModel): IAction => ({
  type: REMOVE_ITEM,
  item,
});

export const setComment = (item: IPartModel): IAction => ({
  type: SET_ITEM_COMMENT,
  item,
});

export const setAttachments = (item: IPartModel): IAction => ({
  type: SET_ITEM_ATTACHMENTS,
  item,
});

export const emptyBasket = () => ({
  type: EMPTY_BASKET,
});

export interface IBasketState {
  items: ILineItem[];
  totalItemsNum: number | null;
  lineItemsNum: number;
  totalPrice: number;
  quotationReference: {
    yourReference: string;
    wartsilaReference: string;
  };
  basketDraft: {
    id: string | null;
    name: string | null;
    oldDraft: ILineItem[];
    relatedSPBasket: string | null;
  };
}

const initialState: IBasketState = {
  items: [],
  totalItemsNum: null,
  lineItemsNum: 0,
  totalPrice: 0,
  quotationReference: {
    yourReference: "",
    wartsilaReference: "",
  },
  basketDraft: {
    id: null,
    name: null,
    oldDraft: [],
    relatedSPBasket: null,
  },
};

export const getOrderLinesFromBasket = (basket: IBasketState): IPartOrderLine[] =>
  basket.items.map((item) => ({
    lineNumber: `000${item.lineNumber}`,
    referenceEngine: item.referenceEngine,
    productID: item.productId,
    sparePartNumber: item.sparePartNumber !== undefined ? item.sparePartNumber : item.id,
    materialNumber: item.materialId,
    description: item.spnDescription,
    quantity: `${item.quantity}`,
    unitOfMeasure: item.unit,
    cylinder: item.cylinder,
    usageTarget: item.usageTarget || "",
    sizeAndDimensions: item.sizeAndDimensions || "",
    serialNumber: item.serialNumber || "",
    markings: item.markings || "",
    otherInformation: item.otherInformation || "",
    attachmentIds: item.attachmentIds || [],
    vendorCode: item.vendorCode || "",
    equipmentId: item.equipmentId,
    sfId: item.sfId,
  }));

export const priceCalc = (lineIttems: ILineItem[]) => {
  const price = lineIttems.reduce((totalPrice, curItem) => {
    if (curItem.netPrice) {
      return totalPrice + curItem.netPrice * curItem.quantity;
    }
    return totalPrice;
  }, 0);
  return Math.round(price * 100) / 100;
};

const totalItemsCount = (items: ILineItem[]) => {
  return items.reduce((acc, curVal) => acc + curVal.quantity, 0);
};

export function basketReducer(state = initialState, action: IAction): IBasketState {
  let items: ILineItem[];
  let itemIndex: number;

  // To avoid possible data mutation
  // Don't forget that spread operator doesn't provide a deep merge
  state = merge({}, state);

  switch (action.type) {
    case EMPTY_BASKET:
      return initialState;
    case SET_QUOTATION_REF:
      const { yourReference, wartsilaReference } = action.quotationReference || {
        yourReference: "",
        wartsilaReference: "",
      };
      return {
        ...state,
        quotationReference: {
          yourReference,
          wartsilaReference,
        },
      };
    case SET_BASKET_DRAFT:
      return {
        ...state,
        items: action.oldDraft
          ? state.items.map((item) => ({
              ...item,
              attachmentIds:
                action.oldDraft?.find((savedItem) => savedItem.lineNumber === item.lineNumber)?.attachmentIds ||
                item.attachmentIds,
              sfId: item.sfId || action.oldDraft?.find((draftItem) => draftItem.lineNumber === item.lineNumber)?.sfId,
              currencyCode:
                item.currencyCode ||
                action.oldDraft?.find((draftItem) => draftItem.lineNumber === item.lineNumber)?.currencyCode ||
                item.currency,
              currency:
                item.currencyCode ||
                action.oldDraft?.find((draftItem) => draftItem.lineNumber === item.lineNumber)?.currencyCode ||
                item.currency,
              attachments:
                action.oldDraft?.find((savedItem) => savedItem.lineNumber === item.lineNumber)?.attachments ||
                item.attachments,
            }))
          : state.items,
        basketDraft: {
          id: action.draftId || null,
          name: action.draftName || null,
          oldDraft: action.oldDraft ? action.oldDraft.map((item) => ({ ...item })) : [], // check olddraft length
          relatedSPBasket: action.relatedSPBasket || null, // if the draft is created from Enhanced support package
        },
      };
    case REDUCE_ITEM_AMOUNT:
      itemIndex = findIndexOfItemInBasket(action.item, state);
      if (itemIndex === -1) {
        return state;
      } else {
        items = [...state.items];
        if (items[itemIndex].quantity >= 1) {
          items[itemIndex].quantity--;
        }
      }
      return {
        ...state,
        items,
        totalItemsNum: totalItemsCount(items),
        lineItemsNum: items.length,
        totalPrice: priceCalc(items),
      };
    case UPDATE_AVAILABILITY:
      items = [...state.items];
      items = items.map((item, i) => {
        const availability = action.availability?.find(
          (partAvailability) =>
            partAvailability.equipmentId === item.equipmentId && partAvailability.item_number === item.lineNumber
        );
        if (availability) {
          return {
            ...item,
            quantity: availability.quantity,
            quantityInStock: availability.quantityInStock,
            available: availability.available,
            currency: availability.currency,
            totNetPrice: availability.totNetPrice,
            netPrice: availability.netPrice,
            leadText: availability.leadText,
            materialText: availability.materialText,
            dangerousGoodsIndicator: availability.dangerousGoodsIndicator,
            nonCancellableItem: availability.nonCancellableItem,
          };
        }
        return { ...item };
      });

      return {
        ...state,
        items,
        totalItemsNum: totalItemsCount(items),
        lineItemsNum: items.length,
        totalPrice: priceCalc(items),
      };
    case ADD_ITEM:
      const { quantity } = action;
      itemIndex = findIndexOfItemInBasket(action.item, state);

      if (itemIndex !== -1) {
        items = [...state.items];
        quantity ? (items[itemIndex].quantity = quantity) : (items[itemIndex].quantity = items[itemIndex].quantity + 1);
      } else {
        items = [
          ...state.items,
          {
            ...action.item,
            quantity: quantity ? quantity : 1,
            maxQuantity: action.maxQuantity,
            // Line number is mapped correctly below, this is a temp value
            lineNumber: action.lineNumber ? action.lineNumber : 0,
            displayValue: action.displayValue,
            otherInformation: action.item.otherInformation || "",
            attachmentIds: action.item.attachmentIds || [],
            attachments: action.item.attachments || [],
            currencyCode: action.item.currencyCode || action.item.currency,
            currency: action.item.currency || action.item.currencyCode,
          },
        ];
      }

      // Important to add proper(increment by 1) lineNumber to every item, otherwise SAP behaves incorrectly
      if (!action.lineNumber) {
        items = items.map((item, i) => ({
          ...item,
          lineNumber: item.item_number
            ? item.item_number
            : i !== 0
            ? items[i - 1].lineNumber + SAP_LINENUMBER
            : SAP_LINENUMBER,
        }));
      }

      return {
        ...state,
        items,
        totalItemsNum: totalItemsCount(items),
        lineItemsNum: items.length,
        totalPrice: priceCalc(items),
      };
    case REMOVE_ITEM:
      items = [...state.items].filter(
        (item) =>
          (item.sfId && action.item.sfId && item.sfId !== action.item.sfId) ||
          item.frontendKey !== action.item.frontendKey ||
          item.equipmentId !== action.item.equipmentId
      );
      return {
        ...state,
        items,
        totalItemsNum: totalItemsCount(items),
        lineItemsNum: items.length,
        totalPrice: priceCalc(items),
      };
    case SET_ITEM_COMMENT:
      itemIndex = findIndexOfItemInBasket(action.item, state);
      if (itemIndex === -1) {
        return state;
      } else {
        items = [...state.items];
        items[itemIndex].otherInformation = action.item.otherInformation || "";
      }
      return {
        ...state,
        items,
      };
    case SET_ITEM_ATTACHMENTS:
      itemIndex = findIndexOfItemInBasket(action.item, state);
      if (itemIndex === -1) {
        return state;
      } else {
        items = [...state.items];
        items[itemIndex].attachmentIds = action.item.attachmentIds || [];
      }
      return {
        ...state,
        items,
      };
  }
  return state;
}
