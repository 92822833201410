// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_fi: ITranslations = {
  fi: {
    "purchaseStatus.confirmed": "Vahvistettu",
    "purchaseStatus.draft": "Luonnos",
    "purchaseStatus.expired": "Vanhentunut",
    "purchaseStatus.inProcess": "Meneillään",
    "spareParts.browseSpareParts": "Selaa Varaosia",
    "spareParts.chooseProduct": "Valitse tuote...",
    "spareParts.draft": "Luonnos",
    "spareParts.filterPurchases": "Suodata ostot",
    "spareParts.goodsTotal": "Kokonaishinta (€)",
    "spareParts.lastModified": "Viimeksi muokattu",
    "spareParts.location": "Paikka",
    "spareParts.noCatalogueFound": "Laitetta ei löydy luettelosta. Lisää osanumerot manuaalisesti.",
    "spareParts.order": "Tilaus",
    "spareParts.partDescription": "Varaosien kuvaus",
    "spareParts.partNameOrNumber": "Osan nimi/numero",
    "spareParts.partNumber": "Varaosien numero",
    "spareParts.partWeight": "Paino",
    "spareParts.pictures": "Kuvat",
    "spareParts.product": "Tuote",
    "spareParts.qtySection": "Määrä tässä osassa",
    "spareParts.quotation": "Tarjous",
    "spareParts.sorting.purchaseTypeAZ": "Ostotyyppi (A-Ö)",
    "spareParts.typePartNameOrNumber": "Kirjoita osan nimi tai numero",
    "spareParts.wartsilaReference": "Wärtsilä viite",
    "spareParts.yourReference": "Viite",
  },
};
