import colors from "src/design-system/Tokens/colors";
import { ThemedStyledProps } from "styled-components";

export type ThemedProps<P = {}> = ThemedStyledProps<P, ITheme>;

export interface ITheme {
  name: "light" | "dark" | "quantiparts";

  text: string;
  background: string;
  foreground: string;
  brand: string;
  primary1: string;
  primary2: string;
  secondary1: string;
  secondary2: string;
  secondary3: string;
  attention1: string;
  attention2: string;

  button: {
    outlineForeground: string;
    nextBackground: string;
    previousBackground: string;
    brandBackground: string;
    nextHover: string;
    nextActive: string;
    previousHover: string;
    previousActive: string;
    brandHover: string;
    brandActive: string;
    disabled: string;
  };

  icon: {
    defaultColor: string;
    disabledColor: string;
  };

  navigationButton: {
    foreground: string;
    background: string;
    disabled: string;
  };

  switchButton: {
    background: string;
  };

  input: {
    background: string;
    foreground1: string;
    foreground2: string;
  };

  list: {
    selected: string;
  };

  select: {
    foreground1: string;
    foreground2: string;
    background1: string;
    background2: string;
    border1: string;
    border2: string;
    border3: string;
    attention: string;
    selected: string;
  };

  menu: {
    background: string;
  };

  table: {
    indicator: string;
  };

  equipmentCard: {
    background: string;
  };

  basketButton: {
    leftPart: string;
    rightPart: string;
  };

  sideMenu: {
    background: string;
  };
}

interface IThemes {
  light: ITheme;
  dark: ITheme;
  quantiparts: ITheme;
}

export const themes: IThemes = {
  dark: {
    name: "dark",

    text: colors.primary.white,
    background: colors.secondary.blue100,
    foreground: colors.primary.white,
    brand: colors.primary.orange,
    primary1: colors.primary.white,
    primary2: colors.primary.white,
    secondary1: colors.primary.gray,
    secondary2: colors.primary.black,
    secondary3: colors.secondary.gray40,
    attention1: colors.notification.error,
    attention2: colors.notification.ok,

    button: {
      outlineForeground: colors.primary.white,
      nextBackground: colors.primary.blue,
      previousBackground: colors.secondary.gray30,
      brandBackground: colors.primary.orange,
      nextHover: colors.secondary.blue80,
      nextActive: colors.secondary.blue90,
      previousHover: colors.secondary.gray50,
      previousActive: colors.secondary.gray60,
      brandHover: colors.secondary.orange90,
      brandActive: colors.secondary.orange100,
      disabled: colors.secondary.gray20,
    },

    icon: {
      defaultColor: colors.primary.white,
      disabledColor: colors.secondary.gray70,
    },

    navigationButton: {
      foreground: colors.primary.white,
      background: colors.secondary.bluegray100,
      disabled: colors.transparent.blue,
    },

    switchButton: {
      background: colors.secondary.bluegray100,
    },

    input: {
      background: colors.secondary.bluegray100,
      foreground1: colors.primary.black,
      foreground2: colors.primary.white,
    },

    list: {
      selected: colors.secondary.bluegray90,
    },

    select: {
      foreground1: colors.primary.black,
      foreground2: colors.primary.white,
      background1: colors.primary.white,
      background2: colors.secondary.blue100,
      border1: colors.primary.black,
      border2: colors.primary.white,
      border3: colors.transparent.full,
      attention: colors.primary.orange,
      selected: colors.primary.white,
    },

    menu: {
      background: colors.secondary.bluegray90,
    },

    table: {
      indicator: colors.primary.blue,
    },

    equipmentCard: {
      background: colors.secondary.bluegray100,
    },

    basketButton: {
      leftPart: colors.secondary.bluegray100,
      rightPart: colors.secondary.bluegray90,
    },

    sideMenu: {
      background: colors.primary.blue,
    },
  },
  light: {
    name: "light",

    text: colors.primary.black,
    background: colors.secondary.gray0,
    foreground: colors.secondary.blue100,
    brand: colors.primary.orange,
    primary1: colors.secondary.gray40,
    primary2: colors.primary.blue,
    secondary1: colors.primary.gray,
    secondary2: colors.primary.black,
    secondary3: colors.secondary.gray40,
    attention1: colors.notification.error,
    attention2: colors.notification.ok,

    button: {
      outlineForeground: colors.primary.blue,
      nextBackground: colors.primary.blue,
      previousBackground: colors.secondary.gray30,
      brandBackground: colors.primary.orange,
      nextHover: colors.secondary.blue80,
      nextActive: colors.secondary.blue90,
      previousHover: colors.secondary.gray50,
      previousActive: colors.secondary.gray60,
      brandHover: colors.secondary.orange90,
      brandActive: colors.secondary.orange100,
      disabled: colors.secondary.gray20,
    },

    icon: {
      defaultColor: colors.secondary.blue80,
      disabledColor: colors.secondary.gray70,
    },

    navigationButton: {
      foreground: colors.primary.white,
      background: colors.primary.white,
      disabled: colors.transparent.blue,
    },

    switchButton: {
      background: colors.primary.white,
    },

    input: {
      background: colors.primary.black,
      foreground1: colors.primary.black,
      foreground2: colors.primary.black,
    },

    list: {
      selected: colors.transparent.bluegray,
    },

    select: {
      foreground1: colors.primary.black,
      foreground2: colors.secondary.blue100,
      background1: colors.primary.white,
      background2: colors.secondary.gray0,
      border1: colors.primary.black,
      border2: colors.secondary.blue100,
      border3: colors.transparent.full,
      attention: colors.primary.orange,
      selected: colors.primary.white,
    },

    menu: {
      background: colors.primary.white,
    },

    table: {
      indicator: colors.primary.blue,
    },

    equipmentCard: {
      background: colors.primary.white,
    },

    basketButton: {
      leftPart: colors.secondary.bluegray100,
      rightPart: colors.secondary.bluegray90,
    },

    sideMenu: {
      background: colors.primary.blue,
    },
  },
  quantiparts: {
    name: "quantiparts",

    text: colors.primary.white,
    background: colors.quantiparts.darkBlue,
    foreground: colors.primary.white,
    brand: colors.quantiparts.blue,
    primary1: colors.secondary.gray40,
    primary2: colors.primary.white,
    secondary1: colors.primary.gray,
    secondary2: colors.primary.black,
    secondary3: colors.secondary.gray40,
    attention1: colors.notification.error,
    attention2: colors.notification.ok,

    button: {
      outlineForeground: colors.primary.white,
      nextBackground: colors.primary.blue,
      previousBackground: colors.secondary.gray30,
      brandBackground: colors.primary.orange,
      nextHover: colors.secondary.blue80,
      nextActive: colors.secondary.blue90,
      previousHover: colors.secondary.gray50,
      previousActive: colors.secondary.gray60,
      brandHover: colors.secondary.orange90,
      brandActive: colors.secondary.orange100,
      disabled: colors.secondary.gray20,
    },

    icon: {
      defaultColor: colors.primary.white,
      disabledColor: colors.secondary.gray70,
    },

    navigationButton: {
      foreground: colors.primary.white,
      background: colors.quantiparts.midBlue,
      disabled: colors.transparent.blue,
    },

    switchButton: {
      background: colors.primary.white,
    },

    input: {
      background: colors.secondary.bluegray100,
      foreground1: colors.primary.black,
      foreground2: colors.primary.white,
    },

    list: {
      selected: colors.transparent.bluegray,
    },

    select: {
      foreground1: colors.primary.black,
      foreground2: colors.secondary.blue100,
      background1: colors.primary.white,
      background2: colors.secondary.gray0,
      border1: colors.primary.black,
      border2: colors.secondary.blue100,
      border3: colors.transparent.full,
      attention: colors.primary.orange,
      selected: colors.primary.white,
    },

    menu: {
      background: colors.quantiparts.midBlue,
    },

    table: {
      indicator: colors.quantiparts.list,
    },

    equipmentCard: {
      background: colors.quantiparts.midBlue,
    },

    basketButton: {
      leftPart: colors.quantiparts.midBlue,
      rightPart: colors.quantiparts.list,
    },

    sideMenu: {
      background: colors.quantiparts.list,
    },
  },
};
