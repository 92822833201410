import moment from "moment";
import { translateString } from "src/util/localization";

import { getObjectProperty } from "./helpers";
import { isWeekend } from "./date";

// Common maximum string lengths
export const stringMaxLength = {
  veryShort: 10,
  shorter: 35,
  short: 40,
  medium: 255,
  mediumLong: 5000,
  long: 30000,
};

const subjectMaxLength = stringMaxLength.medium;
const messageMaxLength = stringMaxLength.mediumLong; // Note: The message limit is 32000 in Salesforce.

// Convert e.g "1,0" to "1.0"

export function convertComma(val: string | number): number {
  return typeof val === "string" ? Number(val.replace(",", ".")) : Number(val);
}

export const validators = {
  isNumeric: (val: string | null): boolean => val !== null && !isNaN(convertComma(val)),
  isNumberBetween: (val: string | null | number, min: number, max: number) =>
    val !== null &&
    val !== undefined &&
    val !== "" &&
    convertComma(val) >= Number(min) &&
    convertComma(val) <= Number(max),
  isNumericOrEmpty: (val: string | null): boolean =>
    val === null || val === undefined || val.length === 0 || validators.isNumeric(val),
  isIntegerOrEmpty: (val: string | null): boolean =>
    val === null ||
    val === undefined ||
    val.length === 0 ||
    (Number.isInteger(convertComma(val)) && !val.toString().includes(" ")),
  isRequired: (val: string | { value: string } | { [key: string]: string } | null | undefined): boolean => {
    if (typeof val === "string") {
      return val.trim().length > 0;
    }

    if (val !== undefined && val !== null && typeof val.value === "string") {
      return val.value.trim().length > 0;
    }

    return val !== undefined && val !== null;
  },
  // NOTE: if necessary add a boolean parameter here to regulate if we want to take into account business days or not
  minimumBusinessDaysPassedFromNow: (requiredBusinessDaysNum: number, format: string) => (val: string) => {
    const userSelectedDate = moment(val, format);
    const currentDate = moment().format(format);
    const dateDiffDays = userSelectedDate.diff(currentDate, "days");
    let tmpDate = moment();
    let allDaysNum = 0;
    let businessDaysNum = 0;

    while (allDaysNum < dateDiffDays) {
      tmpDate = tmpDate.add(1, "day");

      if (!isWeekend(tmpDate)) {
        businessDaysNum++;
      }
      allDaysNum = allDaysNum + 1;
    }

    if (businessDaysNum >= requiredBusinessDaysNum) {
      return true;
    }

    return false;
  },

  isConditionRequired: (val: boolean) => {
    return val;
  },
  isSubjectLength: (val: string | null): boolean => val !== null && val.length <= subjectMaxLength,
  isMessageLength: (val: string | null): boolean => val !== null && val.length <= messageMaxLength,
  isStringLengthBetween: (min: number, max: number): ((val: string | null) => boolean) => {
    return (val: string | null) => {
      return val !== null && val.length >= min && val.length <= max;
    };
  },
  isStringLengthMax: (max: number): ((val: string | null) => boolean) => {
    return (val: string | null) => {
      return val !== null && val !== undefined ? val.replace(/(\r\n|\n|\r)/gm, "").length <= max : true;
    };
  },
  isPhoneNumberValidOrEmpty: (val: string | null) => {
    return val === null || val === undefined || val.length === 0 || validators.isPhoneNumberValid(val);
  },
  isPhoneNumberValid: (val: string | null) => {
    if (val) {
      // Same validation as on salesforce side. Follows the E.164 standard
      const re = /^\+\d{9,14}$/;
      return re.test(val);
    } else {
      return false;
    }
  },
  atLeastOneEmail: (contacts: Array<{ value: string; label: string }>) => {
    const atLeastOneEmailExist = contacts.find((contact) => {
      return validators.isEmail(contact.label);
    });

    return atLeastOneEmailExist ? true : false;
  },
  isEmail: (str: string): boolean => {
    const re = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
    return re.test(str);
  },
  isDateInPast: (val: string | null): boolean => val !== null && moment().isSameOrAfter(moment(val)),
  isDateInFuture: (val: string | null): boolean => val !== null && moment().startOf("day").isSameOrBefore(moment(val)),
  is24HTime: (val: string | null): boolean => val !== null && moment(val, "H:mm", true).isValid(),
  atLeastOneElement: (val: any[]): boolean => val && val.length > 0,
};

interface IValidator {
  valErr: string;
  valFn: (val: string | any, values: any) => boolean;
}

export interface IValidatorMap {
  [key: string]: IValidator[];
}

export interface IErrors {
  [key: string]: string;
}

export const validateForm = (values: any, validatorMap: IValidatorMap) => {
  const errors: IErrors = {};
  Object.keys(validatorMap).forEach((fieldName) => {
    validatorMap[fieldName].forEach((validator: IValidator) => {
      const fieldValue = getObjectProperty(values, fieldName);
      if (!validator.valFn(fieldValue, values)) {
        errors[fieldName] = validator.valErr;
      }
    });
  });
  return errors;
};

/* Compare the given field values to see if field1 >= field2. If not, return the error */
// values has to be "any" in this particular case since it can be any form interface. Adding each and every one manually is very impractical later on.
export const compareFieldsLargerEqual = (
  values: any,
  field1: string,
  field2: string,
  error: string,
  allowNulls: boolean
) => {
  const errors: IErrors = {};
  if (allowNulls && (!values[field1] || !values[field2])) {
    return errors;
  }

  if (values[field1] < values[field2]) {
    errors[field1] = error;
  }
  return errors;
};

interface IKeyElement {
  key: string;
}

export function arraysMatch(array1: IKeyElement[], array2: IKeyElement[]) {
  return array1.length === array2.length && array1.every((element, index) => element.key === array2[index].key);
}

export const characterMaxLengthValidator = (max: number) => {
  return {
    valErr: translateString("validation.characterLimit", { characters: max }),
    valFn: validators.isStringLengthMax(max),
  } as IValidator;
};
