// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const error_es_MX: ITranslations = {
  es_MX: {
    "error.contactLinkText": "ponerse en contacto con Wärtsilä",
    "error.defaultTitle": "Ocurrió un error",
    "error.pleaseContact": "Si el problema persiste, {contactLink}.",
    "success.defaultMessage": "La operación se completó con éxito.",
    "success.defaultTitle": "¡Éxito!",
  },
};
