// tslint:disable:no-var-requires
const values = require("object.values");

// TODO: get rid of the require

export default function polyfills() {
  if (!(Object as any).values) {
    values.shim();
  }
}
