// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const eula_zh_CN: ITranslations = {
  zh_CN: {
    "eula.acceptPrivacyStatement": "我接受{privacyStatement}",
    "eula.acceptTermsAndConditions": "我接受{termsAndConditions}",
    "eula.agreeButtonText": "继续",
    "eula.cancelButtonText": "取消 & 退出",
    "eula.caption": "您必须阅读并同意隐私声明的条款和条件后才可使用Wärtsilä Online。",
    "eula.privacyStatement": "隐私声明",
    "eula.termsAndConditions": "条款和条件",
    "eula.title": "接受条款和条件",
    "eula.validate.acceptPrivacyStatement": "您必须接受隐私声明",
    "eula.validate.acceptTermsAndConditions": "您必须接受条款和条件",
  },
};
