import * as React from "react";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import styled from "styled-components";

import { ISwitchButtonProps } from "./SwitchButton";

interface IWrapperProps {
  fullwidth?: boolean;
  isColumn?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  ${(props: IWrapperProps) =>
    props.fullwidth &&
    `
    flex: 1;
    @media(max-width: ${breakpoints.mobileLarge}px) {
      flex-wrap: nowrap;
      div:last-child {
        margin-right: 0px;
      }
    }
  `}
  ${(props) =>
    props.isColumn &&
    `
    flex-direction: column;
    max-width: 500px;
  `}
`;

interface ISwitchButtonWrapperProps {
  fullwidth?: boolean;
  children: React.ReactChild[];
  isColumn?: boolean;
  withBorder?: boolean;
}

export class SwitchButtonWrapper extends React.Component<ISwitchButtonWrapperProps> {
  public render() {
    const isChildrenMoreThanTwo = this.props.children.length > 2;

    return (
      <Wrapper isColumn={this.props.isColumn}>
        {React.Children.map(this.props.children, (child: React.ReactElement<ISwitchButtonProps>) => {
          return React.cloneElement(child, {
            withBorder: this.props.withBorder || isChildrenMoreThanTwo,
            fullwidth: this.props.fullwidth,
            isColumn: this.props.isColumn,
          });
        })}
      </Wrapper>
    );
  }
}
