import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const WarrantyIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Warranty Icon</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 28 4 H 9 V 44 H 40 V 16 L 28 4 Z M 28 9.75 L 34.25 16 H 28 V 9.75 Z M 36 22 V 40 H 22.89 V 28.72 L 25.37 26.24 V 23.41 L 24 22 L 22.93 22.35 V 25.18 H 19.36 V 22.35 L 18.3 22 L 16.88 23.41 V 26.24 L 19.36 28.72 V 40 H 13 V 8 H 24 V 20 H 36 V 22 Z"
    />
  </IconBase>
);
