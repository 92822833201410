import * as React from "react";

import { Button, ButtonStyle } from "src/design-system/Button";
import {
  IDocumentNotification,
  INotificationContext,
  NotificationContext,
  NotificationType,
} from "src/models/notifications";
import { IEquipment, IInstallation, INotification } from "online-services-types";
import { ITimeSpan, MarkedNotificationsAsRead, NotificationListTimeSpan } from "./NotificationListTimeSpan";

import { ButtonType } from "src/design-system/Button/Button";
import { Container } from "src/design-system/Container";
import { Heading } from "src/components/Heading";
import { LoadingSpinner } from "../LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { PushPermissionNotification } from "../PushPermissionNotification";
import { ThemedProps } from "src/design-system/Theme/theme";
import moment from "moment";
import styled from "styled-components";
import { supportsServiceWorker } from "src/serviceWorker";
import { translateString } from "src/util/localization";

interface INotificationListComponentProps {
  isReady: boolean;
  notifications: INotification[];
  installations: IInstallation[];
  equipments: IEquipment[];
  onRequestCloseSidebar(): void;
  discardAndSetNotificationAsRead(ids: string[], notificationContext: INotificationContext): void;
}

const Wrapper = styled(Container).attrs({ $padding: 1 })`
  background: ${(props: ThemedProps) => props.theme.background};
`;

const ReadAllWrapper = styled(Container).attrs({ $marginBottom: 4 })`
  display: flex;
  align-items: center;
  grid-gap: 10px;
`;

export const splitNotificationsByTime = (notifications: INotification[], referenceTime = new Date()) => {
  const today = moment(referenceTime).startOf("day");
  const yesterday = moment(referenceTime).subtract(1, "days").startOf("day");
  const thisWeek = moment(referenceTime).startOf("week");
  const lastWeek = moment(referenceTime).subtract(7, "days").startOf("week");
  const splitNotifications: { [time: string]: INotification[] } = {
    today: [],
    yesterday: [],
    thisWeek: [],
    lastWeek: [],
    older: [],
  };
  const sortedNotifications = Object.assign(new Array<INotification>(), notifications);
  sortedNotifications.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());

  sortedNotifications.forEach((notification) => {
    const date = moment(notification.creationDate);
    if (date.isSame(today, "d")) {
      splitNotifications["today"].push(notification);
    } else if (date.isSame(yesterday, "d")) {
      splitNotifications["yesterday"].push(notification);
    } else if (date.isAfter(thisWeek)) {
      splitNotifications["thisWeek"].push(notification);
    } else if (date.isAfter(lastWeek)) {
      splitNotifications["lastWeek"].push(notification);
    } else {
      splitNotifications["older"].push(notification);
    }
  });

  return splitNotifications;
};

export const filterDiscardableNotifications = (notifications: INotification[]) =>
  notifications
    .filter((notification) => notification.type !== NotificationType.EquipmentRunningHoursUpdate)
    .filter((notification) => {
      return !(
        notification.type === NotificationType.NewTechnicalKnowledgeCritical &&
        (notification as IDocumentNotification).data.mainType === "ASI"
      );
    });

export const NotificationListComponent = (props: INotificationListComponentProps) => {
  const [previousDiscardedNotificationsCount, setPreviousDiscardedNotificationsCount] = React.useState<number>(0);

  const hasDiscardableNotifications = () => {
    return (
      filterDiscardableNotifications(props.notifications).filter((notification) => !notification.discarded).length > 0
    );
  };

  const markAllAsRead = (context: INotificationContext) => {
    const notificationsToBeDiscarded = filterDiscardableNotifications(props.notifications);
    props.discardAndSetNotificationAsRead(
      notificationsToBeDiscarded.map((notification) => notification.id),
      context
    );

    setPreviousDiscardedNotificationsCount(notificationsToBeDiscarded.length);
  };

  const totalNotifications = props.notifications?.length;
  React.useEffect(() => {
    if (totalNotifications === 0 && previousDiscardedNotificationsCount) setPreviousDiscardedNotificationsCount(0);
  }, [totalNotifications, previousDiscardedNotificationsCount]);

  let content;
  if (!props.isReady) {
    content = <LoadingSpinner />;
  } else {
    const splitNotifications = splitNotificationsByTime(props.notifications);

    content = (
      <NotificationContext.Consumer>
        {(context: INotificationContext) => (
          <>
            {props.notifications.length > 0 && (
              <ReadAllWrapper>
                <Button
                  onClick={() => markAllAsRead(context)}
                  buttonType={ButtonType.Outline}
                  buttonStyle={ButtonStyle.Transparent}
                  disabled={!hasDiscardableNotifications()}
                >
                  <LocalizedString id="notifications.markAllRead" />
                </Button>
                {previousDiscardedNotificationsCount > 0 && (
                  <MarkedNotificationsAsRead count={previousDiscardedNotificationsCount} />
                )}
              </ReadAllWrapper>
            )}
            {Object.keys(splitNotifications).map(
              (timespan) =>
                splitNotifications[timespan].length > 0 && (
                  <NotificationListTimeSpan
                    key={timespan}
                    context={context}
                    timespan={timespan as ITimeSpan}
                    notifications={splitNotifications[timespan]}
                    installations={props.installations}
                    equipments={props.equipments}
                    onRequestCloseSidebar={props.onRequestCloseSidebar}
                    discardAndSetNotificationAsRead={props.discardAndSetNotificationAsRead}
                  />
                )
            )}
          </>
        )}
      </NotificationContext.Consumer>
    );
  }

  const unreadNotifications =
    props.isReady && props.notifications
      ? props.notifications.filter((notification) => !notification.discarded).length
      : 0;

  return (
    <Wrapper>
      <Heading text={translateString("notifications.title", { unreadNotifications })} noTopMargin>
        {supportsServiceWorker() && <PushPermissionNotification />}
      </Heading>
      {content}
    </Wrapper>
  );
};
