// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_zh_CN: ITranslations = {
  zh_CN: {
    "purchaseStatus.confirmed": "已确认",
    "purchaseStatus.draft": "草拟",
    "purchaseStatus.expired": "已到期",
    "purchaseStatus.inProcess": "正在进行",
    "purchaseStatus.ordered": "已订购",
    "purchaseStatus.quoted": "已报价",
    "spareParts": "备件",
    "spareParts.addLineItems": "添加排列项",
    "spareParts.addManually": "手动添加",
    "spareParts.addMultipleLineItems": "添加多个排列项",
    "spareParts.addressSaveFailure": "保存地址时错误。请重试。",
    "spareParts.addressSaveSuccess": "地址成功保存",
    "spareParts.addressWillBeInformedLater": "地址将稍后提供",
    "spareParts.agreeToTermsAndConditionsIsRequired": "您必须同意条款和条件",
    "spareParts.agreeWith": "同意",
    "spareParts.allowPartiaDelivery": "允许部分交付",
    "spareParts.askForQuotation": "请求报价",
    "spareParts.askForQuote": "请求报价",
    "spareParts.availability": "可用性",
    "spareParts.availabilitySubjectDisclamer": "可用性受先前销售影响。",
    "spareParts.basketCreatedBy": "购物篮创建人",
    "spareParts.basketPriceDisclaimer": "如果您的总订单金额低于相当于200€的货币价格，供应商将收取200€最低开票金额。",
    "spareParts.basketWillBeDiscarded": "购物篮中有未保存的更改。如果您离开此页面，购物篮将清空。您确定要继续？",
    "spareParts.blockedCustomerMessage": "很遗憾，您不能继续。请联系Wärtsilä。",
    "spareParts.browseSpareParts": "浏览备件",
    "spareParts.chooseProduct": "选择产品...",
    "spareParts.contactInformation": "联系信息",
    "spareParts.copy": "复制",
    "spareParts.copyItemsPrompt": "请将备件编号复制到下表中",
    "spareParts.createdBy": "创建人",
    "spareParts.createfirstOrder": "创建您的首个订单",
    "spareParts.creationDate": "创建日期",
    "spareParts.currency": "货币",
    "spareParts.deleteBasketDraft": "删除草稿",
    "spareParts.deliveryAddress": "交付地址",
    "spareParts.deliveryAddressIsFinalDestination": "交付地址为最终目的地",
    "spareParts.deliveryInformation": "交付信息",
    "spareParts.deliveryLocation": "交付位置",
    "spareParts.deliveryLocationIsRequired": "需要填写交付位置！",
    "spareParts.deliveryMode": "交付模式",
    "spareParts.deliveryModeIsRequired": "需要填写交付模式",
    "spareParts.deliveryTerms": "交付条款",
    "spareParts.deliveryTermsIsRequired": "需要填写交付条款！",
    "spareParts.deliverymode.AirFreight": "空运",
    "spareParts.deliverymode.Courier": "快递",
    "spareParts.deliverymode.SeaFreight": "海运",
    "spareParts.deliverymode.ToBeCollected": "运费到付",
    "spareParts.deliverymode.Truck": "卡车",
    "spareParts.description": "说明",
    "spareParts.discard": "废弃",
    "spareParts.dispatchingInformation": "派发信息",
    "spareParts.draft": "草拟",
    "spareParts.draftDeleteFailure": "删除草稿时错误",
    "spareParts.draftDeleteSuccess": "购物篮草稿成功删除",
    "spareParts.draftName": "草稿名称",
    "spareParts.draftNameIsRequired": "需要填写草稿名称",
    "spareParts.draftSaveFailure": "保存购物篮草稿时错误。",
    "spareParts.draftSaveSuccess": "购物篮草稿成功保存",
    "spareParts.draftUpdateSuccess": "购物篮草稿成功更新",
    "spareParts.editInformation": "编辑信息",
    "spareParts.emailAddresses": "电子邮件地址",
    "spareParts.emptyBasket": "您的购物篮是空的",
    "spareParts.errorFetchingPDF": "提取PDF详情时错误",
    "spareParts.estimatedWeight": "估算的备件重量",
    "spareParts.etaDate": "请求的预计到达日期",
    "spareParts.etaDateIsRequired": "需要填写预计到达日期",
    "spareParts.export": "导出",
    "spareParts.exportAsCSV": "以CSV格式导出",
    "spareParts.exportAsPDF": "以PDF格式导出",
    "spareParts.failedFetchingManualParts": "网络错误",
    "spareParts.filterPurchases": "筛选采购",
    "spareParts.finalDestination": "最终目的地",
    "spareParts.finalDestinationIsDifferent": "最终目的地地址不同",
    "spareParts.freeComment": "附加信息",
    "spareParts.freeCommentPlaceholder": "例如，包装专项说明、文件请求等",
    "spareParts.freightCosts": "运费",
    "spareParts.freightCostsOnlyAddedAtInvoicing": "只在开票时添加运费",
    "spareParts.generalInformation": "一般信息",
    "spareParts.goodsTotal": "货物总量",
    "spareParts.howToDispatchOrder": "您希望如何派发订单？",
    "spareParts.identificationRequired": "需要填写技术识别号",
    "spareParts.inAccordanceWithITCode": "根据《意大利民法》第1341和1342条。",
    "spareParts.inStock": "有存货",
    "spareParts.isDeliveryAddressFinalDestinationAddress": "交付地址是否为订单的最终目的地？",
    "spareParts.lastItemDialogText": "您确定要从您的购物篮删除此物品？",
    "spareParts.lastItemDialogTitle": "删除物品",
    "spareParts.lastModified": "上一次修改",
    "spareParts.leadTime": "交付周期",
    "spareParts.lineItems": "排列项",
    "spareParts.loadBasketDraft": "加载草稿",
    "spareParts.loadMore": "加载更多",
    "spareParts.location": "位置",
    "spareParts.makeAnotherOrder": "制作另一个订单",
    "spareParts.makeAnotherQuote": "制作另一份报价",
    "spareParts.markings.optional": "标识（可选）",
    "spareParts.minimumPossibleBusinessDateLimit": "最早的可能日期可以是从现在起3个工作日",
    "spareParts.nextStep": "下一步",
    "spareParts.noCatalogueFound": "抱歉 - 产品目录仍不可用于",
    "spareParts.noDescription": "无可用说明",
    "spareParts.noFilteredPartFound": "抱歉 - 未找到适合的备件",
    "spareParts.noItemsAvailable": "无可用物品",
    "spareParts.noPDFinSAP": "PDF不可用，请联系您的备件团队。",
    "spareParts.noPicturesFound": "未找到图片",
    "spareParts.noQuotationsOrOrders": "您还没有创建任何报价或订单。",
    "spareParts.noSalesDocumentsFound": "未找到销售文件",
    "spareParts.notOfficialQuotationDisclaimer": "不是正式报价根据采购日的价格清单，价格按照全球标价确定。可用性受先前销售影响。",
    "spareParts.on": "开启",
    "spareParts.onlyAllowCompleteOrder": "仅允许整份订单",
    "spareParts.onlyNumberOrNameRequired": "此表中只需要备件编号或备件名称。下列画面包含添加更多备件细节的选项。",
    "spareParts.order": "订单",
    "spareParts.orderConfirmed": "您的订单现在已确认",
    "spareParts.orderDetails": "订单详情",
    "spareParts.orderFullDispatch": "您的订单已全部派发",
    "spareParts.orderNew": "新订单已创建",
    "spareParts.orderPartialDispatch": "您的订单已部分派发",
    "spareParts.orderReceived": "订单已收到",
    "spareParts.partDescription": "备件说明",
    "spareParts.partDescriptionIsRequired": "需要填写备件说明",
    "spareParts.partNameOrNumber": "备件名称/编号",
    "spareParts.partNotFound": "备件未找到请指定附加信息",
    "spareParts.partNumber": "备件编号",
    "spareParts.partOrder.pleaseNoteMayTakeUpMinutes": "您的订单出现在我的备件中可能需要数分钟。",
    "spareParts.partOrder.useReferenceNumber": "您可以用您的参考从我的备件查找您的订单。",
    "spareParts.partOrder.weHaveReceived": "我们已经收到您的订单。",
    "spareParts.partOrder.weWillGetBackToYouOrder": "您的订单一经处理，我们就回复您。",
    "spareParts.partWeight": "配重",
    "spareParts.partWeightUnit": "重量单位",
    "spareParts.pictures": "图片",
    "spareParts.price": "价格",
    "spareParts.priceNoVatDisclaimer": "价格不含增值税，将根据开票时适用的有效增值税法加收增值税。",
    "spareParts.product": "产品",
    "spareParts.productId": "产品ID",
    "spareParts.productIsNotSelected": "产品未选择",
    "spareParts.purchases": "采购",
    "spareParts.qtySection": "本部分的数量",
    "spareParts.quantity": "数量",
    "spareParts.quantityUnit": "数量单位",
    "spareParts.quotation": "报价",
    "spareParts.quotationCannotBeCopied": "无法复制含有未知备件的报价",
    "spareParts.quotationCreated": "新报价已创建",
    "spareParts.quotationQuoted": "您报价单的价格已确认",
    "spareParts.quotationReceived": "报价已收到",
    "spareParts.quotationRequest.pleaseNoteMayTakeUpMinutes": "您的报价出现在我的备件中可能需要数分钟。",
    "spareParts.quotationRequest.useReferenceNumber": "您可以用您的参考从我的备件查找您的报价。",
    "spareParts.quotationRequest.weHaveReceived": "我们已经收到您的报价。",
    "spareParts.quotationRequest.weWillGetBackToYouQuotation": "您的报价一经处理，我们就回复您。",
    "spareParts.quoteDetails": "报价详情",
    "spareParts.referenceType": "参考类型",
    "spareParts.requestPDF": "请求PDF",
    "spareParts.rows": "排",
    "spareParts.saveAddress": "保存地址",
    "spareParts.saveInformation": "保存信息",
    "spareParts.saveProgressForLater": "您是否要保存您的进程以便稍后使用？",
    "spareParts.selectProduct": "选择产品",
    "spareParts.serialNumber.optional": "序列号（可选）",
    "spareParts.serviceGridButtonTitle": "商务",
    "spareParts.shoppingCart": "购物车",
    "spareParts.shoppingCartEmpty": "购物车为空",
    "spareParts.sizeAndDimensions.optional": "大小和尺寸（可选）",
    "spareParts.sorting.purchaseTypeAZ": "采购类型 (A-Z)",
    "spareParts.specificClauses": "具体条款",
    "spareParts.subtotal": "小计",
    "spareParts.summary": "总结",
    "spareParts.termsAndConditions": "条款和条件",
    "spareParts.termsConditionsDisclaimerPDF": "所有备件的销售应符合《一般条款和条件 - 备件 (2021)》的规定。如果供应商的开票金额低于相当于200欧元的货币金额，供应商应收取额外费用，最低开票金额应为200欧元或适用货币的等同金额，其中包含运输费（若有）。",
    "spareParts.termsDisclaimer": "如果您与Wärtsilä签署了采购订单下发时即生效的《备件供应协议》，仅该协议的条款和条件适用。",
    "spareParts.thankYou": "感谢您",
    "spareParts.thereWasErrorWhenSendingOrder": "发送您的订单时发生错误。",
    "spareParts.thereWasErrorWhenSendingQuotation": "发送您的报价时发生错误。",
    "spareParts.total": "总计",
    "spareParts.totalSum": "总金额",
    "spareParts.typeInEmails": "输入电子邮件地址",
    "spareParts.typePartNameOrNumber": "输入备件名称或编号",
    "spareParts.unsavedChanges": "未保存的更改",
    "spareParts.usageTarget.optional": "用途/系统/单位（可选）",
    "spareParts.vatDisclaimer": "价格不含增值税，将根据开票时适用的有效增值税法加收增值税。",
    "spareParts.vendorCode.optional": "供应商零件代码（可选）",
    "spareParts.wartsilaReference": "Wärtsilä参考",
    "spareParts.youCanAddPartsUsingFollowingAction": "您也可通过下列操作添加备件",
    "spareParts.yourReference": "您的参考",
    "spareParts.yourReferenceIsRequired": "需要填写您的参考",
    "spareparts.errorFetchingDetails": "提取订单详情时错误",
  },
};
