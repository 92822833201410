// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_en_US: ITranslations = {
  en_US: {
    "fluid.additive": "Additive",
    "fluid.afterSeparator": "After Separator",
    "fluid.afterSeparatorMessage": "Please use \"{afterSeparator}\" option if there is no separator",
    "fluid.analysisDate": "Analysis date",
    "fluid.analysisOf": "Analysis of",
    "fluid.beforeSeparator": "Before Separator",
    "fluid.bunkeringLocation": "Bunkering location (optional)",
    "fluid.categoryISOF": "Category ISO-F",
    "fluid.changes.sending": "Submitting changes to fluid report...",
    "fluid.changes.sendingError": "Error while submitting changes to fluid report",
    "fluid.changes.sent": "Changes to fluid report submitted",
    "fluid.commentsFromTheLaboratory": "Comments from the laboratory (optional)",
    "fluid.commentsFromTheLaboratoryField": "Comments from the laboratory",
    "fluid.common": "Common",
    "fluid.coolingWater": "Cooling water",
    "fluid.coolingWaterAnalysis": "Cooling water analysis",
    "fluid.differentPerEngine": "Different per engine",
    "fluid.draft.continueEditing": "Continue editing",
    "fluid.draft.continueWithExistingDraft": "Continue with existing draft",
    "fluid.draft.createNewReport": "Create new fluid report",
    "fluid.draft.deleteDraft": "Delete draft",
    "fluid.draft.deleted": "Draft deleted",
    "fluid.draft.deleting": "Deleting draft...",
    "fluid.draft.deletingError": "Error while deleting draft",
    "fluid.draft.or": "OR",
    "fluid.draft.sending": "Saving draft...",
    "fluid.draft.sendingError": "Error while saving draft",
    "fluid.draft.sent": "Draft saved",
    "fluid.editFluidReport": "Edit fluid report",
    "fluid.editValues": "View/Edit Latest Values",
    "fluid.engineLife": "Engine life",
    "fluid.engines": "Engines",
    "fluid.enginesSampleData": "Engines' Sample Data",
    "fluid.field.acid_number_mg_koh_g__c": "Acid Number",
    "fluid.field.active_corrosion_inhibitor_brix__c": "Active corrosion inhibitor",
    "fluid.field.aluminum_al_ppm__c": "Aluminum, Al",
    "fluid.field.aluminum_silicon_mg_kg__c": "Aluminum + Silicon",
    "fluid.field.ammonia_content_max_mgm3__c": "Ammonia content",
    "fluid.field.ammonia_max_mgm3__c": "fluid.field.ammonia_max_mgm3__c",
    "fluid.field.argon_ar_vv__c": "Argon (Ar)",
    "fluid.field.ash_m_m__c": "Ash",
    "fluid.field.asphaltenes_m_m__c": "Asphaltenes",
    "fluid.field.barium_ba_ppm__c": "Barium, Ba",
    "fluid.field.base_number_bn_mg_koh_g__c": "Base Number (BN)",
    "fluid.field.boron_b_ppm__c": "Boron, B",
    "fluid.field.butane_heavier_alkanes_max_vv__c": "Butane (C₄H₁₀) + heavier alkanes",
    "fluid.field.calcium_ca_mg_kg__c": "Calcium, Ca",
    "fluid.field.calcium_ca_ppm__c": "Calcium, Ca",
    "fluid.field.calcium_potassium_magnesium__c": "Calcium + Potassium + Magnesium",
    "fluid.field.carbon_dioxide_co2_vv__c": "Carbon Dioxide (CO₂)",
    "fluid.field.carbon_monoxide_co_vv__c": "Carbon Monoxide (CO)",
    "fluid.field.carbon_residue_micro_method_m_m__c": "Carbon Residue, Micro Method",
    "fluid.field.ccai__c": "CCAI",
    "fluid.field.cetane_index__c": "Cetane Index",
    "fluid.field.chlorides_cl_mg_l__c": "Chlorides, Cl",
    "fluid.field.chlorine_fluorine_max_mgm3__c": "Chlorine + Fluorine content",
    "fluid.field.chromium_cr_ppm__c": "Chromium, Cr",
    "fluid.field.cloud_point_c__c": "fluid.field.cloud_point_c__c",
    "fluid.field.cloud_point_cold_filter__c": "Cloud point, max. or Cold filter plugging point, max.",
    "fluid.field.cloud_point_max_cold_filter_max__c": "cloud_point_max_cold_filter_max__c",
    "fluid.field.copper_cu_mg_l__c": "Copper, Cu",
    "fluid.field.copper_cu_ppm__c": "Copper, Cu",
    "fluid.field.copper_strip_corrosion_max_rating__c": "Copper strip corrosion",
    "fluid.field.density_15_c_kg_m3__c": "Density @15 °C",
    "fluid.field.density_kg_m3__c": "Density",
    "fluid.field.density_lb_ft3__c": "Density",
    "fluid.field.ethane_c2h6_vv__c": "Ethane (C₂H₆)",
    "fluid.field.ethene_ethylene_c2h4_vv__c": "Ethene/Ethylene (C₂H₄)",
    "fluid.field.flash_point_c__c": "Flash Point",
    "fluid.field.gas_inlet_temperature__c": "Gas inlet temperature",
    "fluid.field.helium_he_vv__c": "Helium (He)",
    "fluid.field.higher_heating_value_mj_kg__c": "Higher Heating Value",
    "fluid.field.higher_heating_value_mj_m3n__c": "Higher Heating Value",
    "fluid.field.hydrogen_h2_max_vv__c": "Hydrogen (H₂) content",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "Hydrogen Sulfide",
    "fluid.field.hydrogen_sulphide_h2s_max_vv__c": "Hydrogen sulphide (H₂S) content",
    "fluid.field.i_butane_i_c4h10_vv__c": "i-Butane (i-C₄H₁₀)",
    "fluid.field.i_pentane_i_c5h12_vv__c": "i-Pentane (i-C₅H₁₂)",
    "fluid.field.insolubes_w__c": "Insolubles",
    "fluid.field.iron_fe_mg_kg__c": "Iron, Fe",
    "fluid.field.iron_fe_mg_l__c": "Iron, Fe",
    "fluid.field.iron_fe_ppm__c": "Iron, Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "Kinematic Viscosity @40 °C",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "Kinematic Viscosity @50 °C",
    "fluid.field.lead_pb_mg_kg__c": "Lead, Pb",
    "fluid.field.lead_pb_ppm__c": "Lead, Pb",
    "fluid.field.liquid_water_hydrocarbon_max_vv__c": "LP water and hydrocarbon conden.",
    "fluid.field.lower_heating_value_min_mjm3__c": "Lower Heating Value",
    "fluid.field.lower_heating_value_mj_kg__c": "Lower Heating Value",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "Lubricity, Corrected Wear Scar Diameter (wsd 1.4) @ 60 °C",
    "fluid.field.magnesium_mg_mg_kg__c": "Magnesium, Mg",
    "fluid.field.methane_ch4_content_min_vv__c": "Methane (CH₄) content",
    "fluid.field.molybenum_mo_ppm__c": "Molybdenum, Mo",
    "fluid.field.n_butane_n_c4h10_vv__c": "n-Butane (n-C₄H₁₀)",
    "fluid.field.n_decane_n_c10h22_vv__c": "n-Decane (n-C₁₀H₂₂)",
    "fluid.field.n_heptane_n_c7h16_vv__c": "n-Heptane (n-C₇H₁₆)",
    "fluid.field.n_hexane_n_c6h14_vv__c": "n-Hexane (n-C₆H₁₄)",
    "fluid.field.n_nonane_n_c9h20_vv__c": "n-Nonane (n-C₉H₂₀)",
    "fluid.field.n_octane_n_c8h18_vv__c": "n-Octane (n-C₈H₁₈)",
    "fluid.field.n_pentane_n_c5h12_vv__c": "n-Pentane (n-C₅H₁₂)",
    "fluid.field.neo_pentane_neo_c5h12_vv__c": "neo-Pentane (neo-C₅H₁₂)",
    "fluid.field.nickel_ni_mg_kg__c": "Nickel, Ni",
    "fluid.field.nickel_ni_ppm__c": "Nickel, Ni",
    "fluid.field.nitrate_no3_mg_l__c": "Nitrate, NO₃",
    "fluid.field.nitration_abs_cm__c": "Nitration",
    "fluid.field.nitrite_no2_mg_l__c": "Nitrite, NO₂",
    "fluid.field.nitrogen_n2_vv__c": "Nitrogen (N₂)",
    "fluid.field.oil_content_max_mgm3__c": "Oil content",
    "fluid.field.oxidation_abs_cm__c": "Oxidation",
    "fluid.field.oxidation_stability_g_m3__c": "Oxidation Stability",
    "fluid.field.oxygen_o2_vv__c": "Oxygen (O₂)",
    "fluid.field.particles_solids_content_max_mgm3__c": "Particles or solids size in engine inlet, max.",
    "fluid.field.particles_solids_content_mgm3__c": "Particle-solid content in eng.",
    "fluid.field.particles_solids_in_engine__c": "Particles-solids size in engine",
    "fluid.field.particles_solids_size_max_mgm3__c": "fluid.field.particles_solids_size_max_mgm3__c",
    "fluid.field.ph__c": "pH",
    "fluid.field.phosphorus_p_mg_kg__c": "Phosphorus, P",
    "fluid.field.phosphorus_p_ppm__c": "Phosphorus, P",
    "fluid.field.pour_point_c__c": "Pour Point",
    "fluid.field.propane_c3h8_content_min_vv__c": "Propane (C₃H₈) content",
    "fluid.field.propane_c3h8_ethane_c2h6_min_vv__c": "Propane (C₃H₈) + Ethane (C₂H₆) cont.",
    "fluid.field.propene_propylene_c3h6_vv__c": "Propene/Propylene (C₃H₆)",
    "fluid.field.reid_vapour_pressure_kpa__c": "Reid vapour pressure, at 37.8 °C",
    "fluid.field.relative_density__c": "Relative Density",
    "fluid.field.silicon_si_ppm__c": "Silicon, Si",
    "fluid.field.sodium_na_mg_kg__c": "Sodium, Na",
    "fluid.field.sodium_na_ppm__c": "Sodium, Na",
    "fluid.field.sulphates_so4_mg_l__c": "Sulphates, SO₄",
    "fluid.field.sulphur_m_m__c": "Sulphur",
    "fluid.field.tin_sn_ppm__c": "Tin, Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "Total Acid Number (TAN)",
    "fluid.field.total_alkanes_content_max__c": "Total alkenes content",
    "fluid.field.total_hardness_ca_mg_dh__c": "Total Hardness (Ca + Mg)",
    "fluid.field.total_hardness_ca_mg_mgl__c": "Total Hardness (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "Total Sediment",
    "fluid.field.vanadium_v_mg_kg__c": "Vanadium, V",
    "fluid.field.vanadium_v_ppm__c": "Vanadium, V",
    "fluid.field.viscosity_100_c_cst__c": "Viscosity @100 °C",
    "fluid.field.viscosity_40_c_cst__c": "Viscosity @40 °C",
    "fluid.field.water_hydrocarbon_before_engine_max_vv__c": "Water and hydrocarbon condensate",
    "fluid.field.water_v_v__c": "Water (H₂O)",
    "fluid.field.water_vol_or_w__c": "Water",
    "fluid.field.zinc_zn_mg_kg__c": "Zinc, Zn",
    "fluid.field.zinc_zn_ppm__c": "Zinc, Zn",
    "fluid.fuel": "Fuel",
    "fluid.fuelAnalysis": "Fuel analysis",
    "fluid.fuelDisclaimer": "The gas composition unit is % v/v, but it may be entered as either volume or mole percentage as they are equal when assuming ideal gas behaviour.",
    "fluid.gas": "Gas",
    "fluid.general": "General",
    "fluid.generalInformation": "General Information",
    "fluid.header.elemConcs": "Elemental Concentrations",
    "fluid.header.fuelProps": "Fuel Properties",
    "fluid.header.oilProps": "Oil Properties",
    "fluid.htCircuit": "HT circuit",
    "fluid.inputValues": "Input values",
    "fluid.laboratoryReferenceNumber": "Laboratory reference number (optional)",
    "fluid.lastData": "Last data",
    "fluid.loadingData": "Loading data",
    "fluid.lowSulphurFuels": "Low sulphur fuels",
    "fluid.ltCircuit": "LT circuit",
    "fluid.lubeOil": "Lube oil",
    "fluid.lubeOilAnalysis": "Lube oil analysis",
    "fluid.lubricant": "Lubricant",
    "fluid.nitrite.exactLimitsVary": "Exact limits vary on additive type and manufacturer.",
    "fluid.nitrite.pleaseCheckBulletin": "Please check bulletin no. {documentId}",
    "fluid.notAvailable": "N/A",
    "fluid.oilAdded": "Oil added",
    "fluid.oilLife": "Oil life",
    "fluid.oldAdditiveDisclaimer": "This section contains historical data from previously submitted cooling water reports",
    "fluid.oldDataDisclaimer": "This section contains historical data from previously submitted fluid reports",
    "fluid.oldFuelDisclaimer": "This section contains historical data from previously submitted fuel reports",
    "fluid.oldLubricantDisclaimer": "This section contains historical data from previously submitted lube oil reports",
    "fluid.operationOn": "Operation on",
    "fluid.phase.reportData": "Report data",
    "fluid.phase.sampleData": "Sample data",
    "fluid.phase.scopeSelection": "Scope selection",
    "fluid.phase.summary": "Summary",
    "fluid.product": "Product",
    "fluid.reportData": "Report data",
    "fluid.sameForAllEngines": "Same for all engines",
    "fluid.sampleAggregation": "Sample Aggregation",
    "fluid.sampleDate": "Sample date",
    "fluid.samplePoint": "Sample point",
    "fluid.samplePointOptional": "Sample point (optional)",
    "fluid.scopeOfReport": "Scope of report",
    "fluid.sending": "Submitting fluid report...",
    "fluid.sendingError": "Error while submitting fluid report",
    "fluid.sent": "Fluid report submitted",
    "fluid.timeframe": "Timeframe",
    "fluid.unit.abscm": "Abs/cm",
    "fluid.unit.brixOfA.C": "Brix° of A.C",
    "fluid.unit.cst": "cSt",
    "fluid.unit.degreec": "°C",
    "fluid.unit.degreedh": "°dH",
    "fluid.unit.gm3": "g/m³",
    "fluid.unit.kgm3": "kg/m³",
    "fluid.unit.kpa": "kPa",
    "fluid.unit.lbft3": "lb/ft³",
    "fluid.unit.mgkg": "mg/kg",
    "fluid.unit.mgkohg": "mg KOH / g",
    "fluid.unit.mgl": "mg/l",
    "fluid.unit.mgm3": "mg/m³N",
    "fluid.unit.mjkg": "MJ/kg",
    "fluid.unit.mjm3": "MJ/m³N",
    "fluid.unit.mm": "% m/m",
    "fluid.unit.mm2s": "mm²/s",
    "fluid.unit.none": " ",
    "fluid.unit.pmm": "% m/m",
    "fluid.unit.ppm": "ppm",
    "fluid.unit.pvv": "% v/v",
    "fluid.unit.rating": "Rating",
    "fluid.unit.um": "µm",
    "fluid.unit.volporwp": "vol-% or w-%",
    "fluid.unit.vv": "% v/v",
    "fluid.unit.wp": "w-%",
    "fluid.unit.μm": "µm",
    "fluid.unspecified": "Unspecified",
    "fluid.uptime": "Running hours",
    "fluid.uptime.availability": "Availability",
    "fluid.uptime.availabilityFormula": "(Running + Standby) / (Running + Standby + Stop + Shut down)",
    "fluid.uptime.availabilityLimits": "Availability targets",
    "fluid.uptime.contractLimit": "Agreement limit",
    "fluid.uptime.daily": "Daily",
    "fluid.uptime.engineAvailability": "Engine availability",
    "fluid.uptime.limitMustBeNumber": "Limit must be a number!",
    "fluid.uptime.limitMustBeNumberBetween": "Limit must be a number between {min} and {max}!",
    "fluid.uptime.limits": "Edit target values",
    "fluid.uptime.monthly": "Monthly",
    "fluid.uptime.noAvailability": "There is no availability data for this period.",
    "fluid.uptime.noData": "No data",
    "fluid.uptime.noMaintenanceData": "There is no maintenance data for this period.",
    "fluid.uptime.noRunningHours": "There is no running hours data for this period.",
    "fluid.uptime.period": "Period",
    "fluid.uptime.report": "Report",
    "fluid.uptime.running": "Running",
    "fluid.uptime.saveLimits": "Save",
    "fluid.uptime.shutDown": "Shut down",
    "fluid.uptime.standby": "Standby",
    "fluid.uptime.stop": "Stop",
    "fluid.uptime.sum": "SUM",
    "fluid.uptime.yearly": "Yearly",
    "fluid.validation.analysisDateEarlierThanSampleDate": "Analysis date can not be a date earlier than sample date",
    "fluid.validation.analysisDateInFuture": "Analysis date can not be a future date",
    "fluid.validation.analysisDateRequired": "Analysis date is required",
    "fluid.validation.categoryISOFIsRequired": "Category ISO-F is required",
    "fluid.validation.common": "Unexpected value entered (min: {min}, max: {max}).",
    "fluid.validation.engineIsRequired": "Engine is required",
    "fluid.validation.engineLifeMustBeANumber": "Engine life must be a number",
    "fluid.validation.fuelIsRequired": "Fuel is required",
    "fluid.validation.installationIsRequired": "Installation is required",
    "fluid.validation.installationIsRequired:energy": "Power plant is required",
    "fluid.validation.installationIsRequired:marine": "Vessel is required",
    "fluid.validation.lubricantIsRequired": "Lubricant is required",
    "fluid.validation.mustBeANumber": "{value} must be a number.",
    "fluid.validation.oilAdedMustBeANumber": "Oil added must be a number",
    "fluid.validation.oilLifeMustBeANumber": "Oil life must be a number",
    "fluid.validation.ppm": "Unexpected value entered (min: {min}, max: {max}). Conversion of % values to ppm: 1% = 10000 ppm.",
    "fluid.validation.sampleAggregationIsRequired": "Sample aggregation is required",
    "fluid.validation.sampleDateInFuture": "Sample date can not be a future date",
    "fluid.validation.sampleDateRequired": "Sample date is required",
    "fluid.validation.samplePointIsRequired": "Sample point is required",
  },
};
