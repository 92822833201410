// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const onboarding_pt_BR: ITranslations = {
  pt_BR: {
    "documentsOnboarding.stepFive.content": "Visualize as informações do documento e veja detalhes sobre qual equipamento se aplica o documento.",
    "documentsOnboarding.stepFive.title": "Informações do documento",
    "documentsOnboarding.stepFour.content": "Acesse com facilidade a visualização clicando nas páginas dos documentos.",
    "documentsOnboarding.stepFour.content2": "As palavras de pesquisa utilizadas serão destacadas na visualização.",
    "documentsOnboarding.stepFour.title": "Visualização",
    "documentsOnboarding.stepOne.content": "Visualize, pesquise, filtre e classifique todos seus documentos com facilidade em uma página.",
    "documentsOnboarding.stepOne.title": "Seus documentos",
    "documentsOnboarding.stepThree.content": "Classifique sua tela para ter os documentos mais relevantes no topo.",
    "documentsOnboarding.stepThree.title": "Classificar",
    "documentsOnboarding.stepTwo.content": "Use valores de pesquisa diferentes para encontrar o que precisa.",
    "documentsOnboarding.stepTwo.content2": "Também é possível filtrar sua pesquisar com tipos de documentos.",
    "documentsOnboarding.stepTwo.title": "Pesquisar e filtrar",
    "mainOnboarding.stepFive.content": "Obrigado por assistir a esta apresentação. Caso tenha alguma pergunta ou necessite ajuda nosso Atendimento ao cliente estará sempre disponível. Esperamos que aprecie este novo ambiente.",
    "mainOnboarding.stepFive.title": "Você está pronto para começar!",
    "mainOnboarding.stepFour.content": "Todos os serviços disponíveis estão listados abaixo.",
    "mainOnboarding.stepFour.title": "Seus serviços",
    "mainOnboarding.stepOne.content": "Apresentaremos os recursos básicos por meio de um guia rápido. Isto demorará menos de um minuto.",
    "mainOnboarding.stepOne.title": "Bem-vindo à nova experiência Wärtsilä Online!",
    "mainOnboarding.stepThree.content": "Todas as instalações que escolheu para ver a partir de seu perfil estão disponíveis na sua página inicial. Clicando em uma instalação específica é possível encontrar os produtos relacionados a ela.",
    "mainOnboarding.stepThree.content:energy": "Todas as usinas de energia que escolheu para ver a partir de seu perfil estão disponíveis na sua página inicial. Clicando em uma usina de energia específica é possível encontrar os produtos relacionados a ela.",
    "mainOnboarding.stepThree.content:marine": "Todas as embarcações que escolheu para ver a partir de seu perfil estão disponíveis na sua página inicial. Clicando em uma embarcação específica é possível encontrar os produtos relacionados a ela.",
    "mainOnboarding.stepThree.title": "Suas instalações",
    "mainOnboarding.stepThree.title:energy": "Suas usinas de energia",
    "mainOnboarding.stepThree.title:marine": "Suas embarcações",
    "mainOnboarding.stepTwo.content": "A partir do perfil você poderá escolher quais instalações deseja ver em sua página inicial, atualizar suas informações básicas e as configurações do e-mail de resumo.",
    "mainOnboarding.stepTwo.content2": "Você sempre poderá repetir este guia rápido a partir do seu perfil.",
    "mainOnboarding.stepTwo.content:energy": "A partir do perfil você poderá escolher quais usinas de energia deseja ver em sua página inicial, atualizar suas informações básicas e as configurações do e-mail de resumo.",
    "mainOnboarding.stepTwo.content:marine": "A partir do perfil você poderá escolher quais embarcações deseja ver em sua página inicial, atualizar suas informações básicas e as configurações do e-mail de resumo.",
    "mainOnboarding.stepTwo.title": "Seu perfil",
    "sparePartsOnboarding.stepAddPartsToCart.content": "Clicando nos botões + e - ou digitando a quantidade é possível adicionar ou remover itens ao carrinho e ver o subtotal.",
    "sparePartsOnboarding.stepAddPartsToCart.title": "Adicione peças ao carrinho de compras",
    "sparePartsOnboarding.stepBrowseParts.content": "Selecione um produto e navegue pelas peças de reposição.",
    "sparePartsOnboarding.stepBrowseParts.content2": "É possível filtrar as peças de reposição com o nome ou o número da peça.",
    "sparePartsOnboarding.stepBrowseParts.content3": "Também é possível adicionar manualmente peças de reposição com vários itens ao mesmo tempo.",
    "sparePartsOnboarding.stepBrowseParts.title": "Explorar peças de reposição",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content": "Faça o pedido ou crie uma cotação. Selecione uma instalação e comece a navegar pelas peças de reposição.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:energy": "Faça o pedido ou crie uma cotação. Selecione uma usina de energia e comece a navegar pelas peças de reposição.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:marine": "Faça o pedido ou crie uma cotação. Selecione uma embarcação e comece a navegar pelas peças de reposição.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.title": "Novo pedido ou cotação",
    "sparePartsOnboarding.stepOrdersAndQuotations.content": "Visualize os status e detalhes de seus pedidos e cotações.",
    "sparePartsOnboarding.stepOrdersAndQuotations.content2": "A partir dessa tela, é possível fazer um pedido com facilidade a partir de uma cotação.",
    "sparePartsOnboarding.stepOrdersAndQuotations.title": "Pedidos e cotações",
    "sparePartsOnboarding.stepPartDetails.content": "Expanda a tela para ver os detalhes da peça de reposição.",
    "sparePartsOnboarding.stepPartDetails.content2": "A partir da figura, é possível ver o desenho expandido da peça de reposição mostrando mais detalhes.",
    "sparePartsOnboarding.stepPartDetails.content3": "Você encontrará também o preço, o peso e o prazo de entrega da peça.",
    "sparePartsOnboarding.stepPartDetails.title": "Detalhes das peças de reposição",
    "sparePartsOnboarding.stepShoppingCart.content": "É possível visualizar as peças de reposição selecionadas e o preço total em seu carrinho de compras.",
    "sparePartsOnboarding.stepShoppingCart.title": "Carrinho de compras",
    "sparePartsOnboarding.stepYourSpareParts.content": "Visão geral de todos seus pedidos e cotações em uma página. É possível filtrar sua tela e visualizar o histórico completo.",
    "sparePartsOnboarding.stepYourSpareParts.content2": "Navegue pelas peças de reposição, crie pedidos ou cotações novas ou crie uma reclamação de peça de reposição.",
    "sparePartsOnboarding.stepYourSpareParts.title": "Página de comércio",
    "supportOnboarding.stepFive.content": "Qualquer pessoa de sua organização pode adicionar comentários relacionados com a solicitação e entrar em contato com a Wärtsilä.",
    "supportOnboarding.stepFive.title": "Comentários",
    "supportOnboarding.stepFour.content": "Os anexos podem ser sempre adicionados enquanto a solicitação está em andamento.",
    "supportOnboarding.stepFour.title": "Anexos",
    "supportOnboarding.stepOne.content": "Visão geral de todas suas solicitações em uma página. É possível filtrar ou classificar sua tela e visualizar os status das solicitações e o histórico completo.",
    "supportOnboarding.stepOne.title": "Sua página de suporte",
    "supportOnboarding.stepSeven.content": "Para obter todas suas solicitações em um documento, basta exportá-las.",
    "supportOnboarding.stepSeven.title": "Exportar solicitações",
    "supportOnboarding.stepSix.content": "Quando a solicitação foi terminada, é possível avaliá-la e fornecer opiniões ou abrir a solicitação novamente.",
    "supportOnboarding.stepSix.title": "Avaliar o serviço",
    "supportOnboarding.stepThree.content": "Expandir para ver os detalhes da solicitação e fornecer informações adicionais",
    "supportOnboarding.stepThree.title": "Suas solicitações",
    "supportOnboarding.stepTwo.content": "Criar todas suas solicitações em um local:",
    "supportOnboarding.stepTwo.title": "Criar uma solicitação nova",
  },
};
