import { translateString } from "./localization";

export enum RequestStatus {
  Draft = "Draft",
  InProgress = "In Progress",
  Open = "Open",
  Answered = "Answered",
  Approved = "Approved",
  WaitingForCustomerActions = "Waiting for Customer Actions",
  PartsInDelivery = "Parts in Delivery",
  ClosedChangedToProject = "Closed - Changed to Project",
  Rejected = "Rejected",
  Closed = "Closed",
  SentToDistributor = "Sent to distributor",
}

export function getRequestStatusName(status: RequestStatus) {
  const requestStatusNames = {
    [RequestStatus.Answered]: translateString("requestStatus.answered"),
    [RequestStatus.Draft]: translateString("requestStatus.draft"),
    [RequestStatus.InProgress]: translateString("requestStatus.inProgress"),
    [RequestStatus.Open]: translateString("requestStatus.open"),
    [RequestStatus.Approved]: translateString("requestStatus.approved"),
    [RequestStatus.WaitingForCustomerActions]: translateString("requestStatus.waitingForCustomerActions"),
    [RequestStatus.PartsInDelivery]: translateString("requestStatus.partsInDelivery"),
    [RequestStatus.ClosedChangedToProject]: translateString("requestStatus.closedChangedToProject"),
    [RequestStatus.Rejected]: translateString("requestStatus.rejected"),
    [RequestStatus.Closed]: translateString("requestStatus.closed"),
    [RequestStatus.SentToDistributor]: translateString("requestStatus.sentToDistributor"),
  };

  return requestStatusNames[status] || status;
}
