import * as React from "react";
import styled from "styled-components";

import { Checkbox } from "src/design-system/Checkbox";
import colors from "src/design-system/Tokens/colors";

export interface IInstallationNameWrapper {
  index: number;
  lastIndex: number;
}

export const InstallationNameWrapper = styled.div`
  padding: 8px 20px;
  margin-left: 6px;
  width: 100%;
  user-select: none;
  cursor: pointer;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;

  &:nth-child(even) {
    background-color: ${colors.secondary.gray10};
  }
`;

export interface IInstallationRowProps {
  name: string;
  index: number;
  lastIndex: number;
  isChecked: boolean;
  toggleCheckboxChange(): void;
}

export class InstallationRow extends React.Component<IInstallationRowProps> {
  public render() {
    return (
      <RowWrapper>
        <Checkbox checked={this.props.isChecked} onChange={this.props.toggleCheckboxChange} />
        <InstallationNameWrapper onClick={this.props.toggleCheckboxChange}>{this.props.name}</InstallationNameWrapper>
      </RowWrapper>
    );
  }
}
