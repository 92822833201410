// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const error_zh_CN: ITranslations = {
  zh_CN: {
    "error.contactLinkText": "请联系Wärtsilä",
    "error.defaultTitle": "发生了错误",
    "error.pleaseContact": "如果问题持续存在，请{contactLink}。",
    "success.defaultMessage": "操作成功完成。",
    "success.defaultTitle": "成功!",
  },
};
