import React from "react";
import { APIFetch } from "src/APIFetch";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { Button, ButtonStyle, ButtonType } from "src/design-system/Button/Button";
import { Container } from "src/design-system/Container";
import { FlexContainer } from "src/design-system/Container";
import { Select } from "src/design-system/Select";
import { DropdownStyle, ISelectObject } from "src/design-system/Select/Select";
import { Grid, GridRowExtraLargeItems, GridSpacing } from "src/design-system/Tokens/grid";
import { ArrowRightIcon } from "src/icons";
import { getRoutes, redirectBrowser } from "src/routes";
import { translateString } from "src/util/localization";
import { calculateCurrentQuarter, getQuarterLabels, ISFOCFleetData } from "src/util/sfoc/sfocCharts";
import { AllTestPerformanceHistory } from "src/views/SFOCFleetDashboardView/AllTestPerformanceHistory";
import { ImprovementsHistoryChart } from "src/views/SFOCFleetDashboardView/ImprovementHistoryChart";
import { MeasurementStatusChart } from "src/views/SFOCFleetDashboardView/MeasurementStatusChart";
import { QuarterlyDevelopmentChart } from "src/views/SFOCFleetDashboardView/QuarterlyDevelopmentChart";
import { TestFollowingUp } from "src/views/SFOCFleetDashboardView/TestFollowingUp";
import { datesOfQuarters, IQuarterWithDates } from "src/views/SFOCVesselDashboardView/datesOfQuarters";
import { QuarterDisplay } from "src/views/SFOCVesselDashboardView/QuarterDisplay";
import styled from "styled-components";

const HeaderFilterWrapper = styled(FlexContainer)`
  width: 520px;
`;

const ViewChangeButton = styled(Button)`
  height: 100%;
`;

interface ISFOCFleetDashboardViewComponentState {
  selectedQuarter: string;
  quarterData: IQuarterWithDates;
  chartData?: ISFOCFleetData[];
  busy: boolean;
}

const fetchData = (currentQuarter: string): Promise<ISFOCFleetData[]> => {
  return new APIFetch<ISFOCFleetData[]>("service/sfoc-fleet-chart").getWithParams({
    currentQuarter,
  });
};

export class SFOCFleetDashboardViewComponent extends React.Component<ISFOCFleetDashboardViewComponentState> {
  public state: ISFOCFleetDashboardViewComponentState = {
    selectedQuarter: "",
    quarterData: { quarter: "", startMeasurements: "", endMesurements: "", quarterEndDate: "" },
    chartData: undefined,
    busy: true,
  };

  public componentDidMount = async () => {
    const quarter = calculateCurrentQuarter();
    const quarterData: IQuarterWithDates = datesOfQuarters.find((dataSet) => dataSet.quarter === quarter) || {
      quarter: "",
      startMeasurements: "",
      endMesurements: "",
      quarterEndDate: "",
    };

    this.setState({
      quarterData,
      selectedQuarter: quarterData.quarter,
    });

    this.setState({ chartData: await fetchData(quarterData.quarter) }, () => this.setState({ busy: false }));
  };

  public render() {
    const quarterLabels: string[] = getQuarterLabels(this.state.quarterData.quarter);
    return (
      <Container>
        <FlexContainer $spaceBetween $marginBottom={4}>
          <HeaderFilterWrapper $spaceBetween>
            <QuarterDisplay
              startDate={this.state.quarterData.startMeasurements}
              endDate={this.state.quarterData.endMesurements}
              quarter={"Q" + this.state.quarterData.quarter}
            />
            <Select<string>
              id="quarterNr"
              options={quarterLabels}
              value={this.state.selectedQuarter || ""}
              onChange={(value: string) => this.setState({ selectedQuarter: value })}
              getOptionLabel={(option: ISelectObject<string>) => translateString("sfoc.quarter") + option.item}
              getOptionValue={(option: ISelectObject<string>) => option.item}
              dropdownStyle={DropdownStyle.Toolbar}
            />
          </HeaderFilterWrapper>
          <ViewChangeButton
            buttonType={ButtonType.Outline}
            buttonStyle={ButtonStyle.Transparent}
            icon={<ArrowRightIcon />}
            onClick={() => redirectBrowser(getRoutes().SFOCVesselDashboard)}
          >
            {translateString("sfoc.routing.toVesselView")}
          </ViewChangeButton>
        </FlexContainer>
        {this.state.busy ? (
          <LoadingSpinner />
        ) : (
          <>
            <Grid
              spacing={GridSpacing.airy}
              itemsPerRow={GridRowExtraLargeItems}
              fallbackWidth={{ min: 500, max: 640 }}
            >
              <QuarterlyDevelopmentChart
                chartData={this.state.chartData || []}
                quarter={this.state.selectedQuarter}
                quarterEndDate={this.state.quarterData.quarterEndDate}
                endMesurements={this.state.quarterData.endMesurements}
                currentQuarter={this.state.quarterData.quarter}
              />
              <TestFollowingUp chartData={this.state.chartData || []} quarter={this.state.selectedQuarter} />
            </Grid>
            <Container $margin={[3, 0]}>
              <MeasurementStatusChart chartData={this.state.chartData || []} quarter={this.state.selectedQuarter} />
            </Container>
            <Grid
              spacing={GridSpacing.airy}
              itemsPerRow={GridRowExtraLargeItems}
              fallbackWidth={{ min: 500, max: 640 }}
            >
              <ImprovementsHistoryChart
                chartData={this.state.chartData || []}
                quarter={this.state.quarterData.quarter}
              />
              <AllTestPerformanceHistory
                chartData={this.state.chartData || []}
                quarter={this.state.quarterData.quarter}
              />
            </Grid>
          </>
        )}
      </Container>
    );
  }
}
