import { IWarrantyClaim, IJWTUserInfo } from "online-services-types";
import * as React from "react";
import { withSize } from "react-sizeme";
import styled from "styled-components";

import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import { Dialog } from "src/components/Dialog";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import {
  IWarrantyClaimListPropsNoContainer,
  SupportListComponent,
} from "src/components/SupportList/SupportListComponent";
import { Button } from "src/design-system/Button";
import colors from "src/design-system/Tokens/colors";
import { RemoveIcon } from "src/icons";
import { ICombinedRequestViewModel, IWarrantyClaimViewModel, RequestType } from "src/models/warrantyClaim";
import { StyledFormInputWrapper } from "src/design-system/Form/Form";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { translateString } from "src/util/localization";
import { RequestStatus } from "src/util/requestStatus";
import { Branch } from "src/views/CreateWarrantyClaimView/Branches";

interface IInnerListProps {
  requestType: RequestType;
  claims: IAPIResource<ICombinedRequestViewModel>;
  value: string;
  installationId?: string;
  disabled: boolean;
  onChange(id: string | null): void;
}

interface IInnerListState {
  modalOpen: boolean;
  selectedItemId: string | undefined;
  user?: IJWTUserInfo | undefined;
}

interface ISelectedItemProps {
  item: ICombinedRequestViewModel;
  onRemove(): void;
}

const SelectedItemWrapper = styled.div`
  background: ${colors.secondary.bluegray100};
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
`;

const RemoveIconWrapper = styled.div`
  padding-left: 5px;
`;

const ListWrapper = styled.div`
  flex: 1 0 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;

  max-height: 600px;

  @media (min-width: ${breakpoints.tablet}px) {
    width: 640px;
    min-height: 600px;
  }
`;

export function getSelectedReferenceClaimText(item: ICombinedRequestViewModel | undefined) {
  if (!item) {
    return "";
  }
  if (item.requestType === Branch.WarrantyClaim) {
    const { customerClaimNumber } = item as IWarrantyClaimViewModel;
    if (customerClaimNumber) {
      return `${customerClaimNumber}, ${item.subject}`;
    }
  }
  return item.subject;
}

const SelectedItem: React.FC<ISelectedItemProps> = (props) => (
  <SelectedItemWrapper>
    <div>{getSelectedReferenceClaimText(props.item)}</div>
    <RemoveIconWrapper onClick={props.onRemove}>
      <RemoveIcon />
    </RemoveIconWrapper>
  </SelectedItemWrapper>
);

const NewestFirstSorter = {
  key: "creationDate",
  asc: false,
};

const MeasuredList: React.ComponentType<Omit<IWarrantyClaimListPropsNoContainer, "isEnergy">> =
  withSize()(SupportListComponent);

export class InnerList extends React.Component<IInnerListProps, IInnerListState> {
  constructor(props: IInnerListProps) {
    super(props);

    this.state = {
      modalOpen: false,
      selectedItemId: props.value,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFinished = this.handleFinished.bind(this);
    this.selectItem = this.selectItem.bind(this);
    this.deselectItem = this.deselectItem.bind(this);
  }

  public openModal() {
    this.setState({ modalOpen: true });
  }

  public closeModal() {
    this.setState({ modalOpen: false });
  }

  public handleCancel() {
    this.closeModal();
  }

  public handleFinished() {
    if (this.state.selectedItemId) {
      this.props.onChange(this.state.selectedItemId);
      this.setState({ selectedItemId: undefined });
    }

    this.closeModal();
  }

  public selectItem(itemId: string) {
    this.setState({ selectedItemId: itemId });
  }

  public deselectItem() {
    this.props.onChange(null);
  }

  public render() {
    const isLoading = this.props.claims.status !== APIFetchStatus.Success;

    const filteredClaims = this.props.claims.data
      .filter((claim) => claim.status !== RequestStatus.Draft)
      .filter((claim) => claim.requestType === this.props.requestType)
      .filter(
        (claim) =>
          !(claim as IWarrantyClaim).installationId ||
          (claim as IWarrantyClaim).installationId === this.props.installationId
      );
    const selectedItem = filteredClaims.find((item) => item.id === this.props.value);

    return (
      <div>
        <StyledFormInputWrapper>
          {!isLoading && selectedItem && <SelectedItem item={selectedItem} onRemove={this.deselectItem} />}
          {isLoading && selectedItem && <LoadingSpinner />}
        </StyledFormInputWrapper>
        <Dialog
          isDialogOpen={this.state.modalOpen}
          title={translateString("request.chooseReferenceRequest")}
          submit={{
            buttonTitle: translateString("select"),
            callback: this.handleFinished,
          }}
          onCancel={this.handleCancel}
        >
          <ListWrapper>
            <MeasuredList
              user={this.state.user}
              includeWarrantyId={true}
              isReady={!isLoading}
              showInstallationColumn={false}
              requests={filteredClaims}
              getClaims={() => 1}
              getEquipments={() => 1}
              getInstallations={() => 1}
              notifications={[]}
              approveRequest={() => undefined}
              reopenRequest={() => undefined}
              refreshNotifications={() => undefined}
              discardNotification={() => undefined}
              discardAndSetNotificationAsRead={() => undefined}
              confirmCloseWarrantyClaim={() => undefined}
              select={this.selectItem}
              selected={this.state.selectedItemId}
              sortOrder={NewestFirstSorter}
              outline={true}
            />
          </ListWrapper>
        </Dialog>
        <StyledFormInputWrapper>
          <Button
            disabled={this.props.disabled || !this.props.installationId || this.props.installationId.length === 0}
            onClick={this.openModal}
          >
            <LocalizedStringComponent id="request.chooseReferenceRequest" />
          </Button>
        </StyledFormInputWrapper>
      </div>
    );
  }
}
