import { Link as ReactLink } from "react-router-dom";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";
import { FlexContainer } from "../Container";

export const Link = styled(ReactLink)`
  color: ${colors.primary.blue};
  text-decoration: underline;

  :hover {
    color: ${colors.primary.blue};
    text-decoration: none;
  }

  :active {
    color: ${colors.primary.blue};
  }

  :visited {
    color: ${colors.primary.blue};
  }
`;

export const LinkWrapper = styled.span`
  color: ${colors.primary.blue};
  text-decoration: underline;
  cursor: pointer;
`;

export const NotificationWrapper = styled(FlexContainer)`
  background-color: ${colors.primary.orange};
  color: ${colors.primary.white};
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
`;
