import * as React from "react";

import { IAccountUser, IInvoicesData } from "online-services-types";
import { IDocumentOpenStatus, getPersonnelLoggedInPercentage, getReadStatusForDocuments } from "./dashboard";
import {
  IManagerDashboardCMReportResponse,
  IManagerDashboardDocumentResponse,
  IManagerDashboardRFQResponse,
  IManagerDashboardRequestItem,
  IManagerDashboardRequestsResponse,
  IManagerDashboardSparePartOrderResponse,
  IRFQ,
} from "./request/interfaces";

import { getInvoices } from "src/util/invoices";
import { makeDashboardRequest } from "./request";

export const timeframeDays = 30;

export const useTimeframe = (days = timeframeDays) => {
  const timeframeEnd = new Date().toISOString();
  const timeframeStart = new Date(new Date(timeframeEnd).getTime() - days * 1000 * 24 * 60 * 60).toISOString();

  return { start: timeframeStart, end: timeframeEnd, days };
};

export const usePersonnelPercentage = (users: IAccountUser[] | undefined, timeframeStart: string): number | null => {
  return users ? getPersonnelLoggedInPercentage(users, timeframeStart) : null;
};

export const useSubmittedRequests = (installationIds: string[] | undefined, days: number) => {
  const [state, set] = React.useState<{ loading: boolean; error?: boolean; data?: IManagerDashboardRequestItem[] }>({
    loading: false,
  });

  const installationsString = installationIds?.toString();

  React.useEffect(() => {
    const request = async () => {
      set((s) => ({ ...s, loading: true }));
      try {
        const response = await makeDashboardRequest<IManagerDashboardRequestsResponse>(
          "requests",
          days,
          installationIds!
        );
        set(() => ({ data: response.requests, loading: false }));
      } catch {
        set((s) => ({ ...s, loading: false, error: true }));
      }
    };

    if (installationsString && !state.loading) {
      request();
    }

    if (!installationsString && state.data) {
      set(() => ({ data: undefined, loading: false }));
    }
  }, [installationsString]);

  return state;
};

export const useInvoices = ({ enabled = false }: { enabled: boolean }) => {
  const [state, set] = React.useState<{ loading: boolean; error?: boolean; data?: IInvoicesData }>({
    loading: false,
  });

  React.useEffect(() => {
    const request = async () => {
      set((s) => ({ ...s, loading: true }));
      try {
        const invoices = await getInvoices();
        set(() => ({ data: invoices, loading: false }));
      } catch {
        set((s) => ({ ...s, loading: false, error: true }));
      }
    };

    if (!state.data && !state.loading && enabled) {
      request();
    }
  }, [enabled, state.data, state.loading]);

  return state;
};

export const useRFQS = (installationIds: string[] | undefined, days: number) => {
  const [state, set] = React.useState<{ loading: boolean; error?: boolean; data?: IRFQ[] }>({
    loading: false,
  });

  const installationsString = installationIds?.toString();

  React.useEffect(() => {
    const request = async () => {
      set((s) => ({ ...s, loading: true }));
      try {
        const response = await makeDashboardRequest<IManagerDashboardRFQResponse>("rfqs", days, installationIds!);
        set(() => ({ data: response.rfqs, loading: false }));
      } catch {
        set((s) => ({ ...s, loading: false, error: true }));
      }
    };

    if (installationsString && !state.loading) {
      request();
    }
  }, [installationsString]);

  return state;
};

export const useSPOS = (installationIds: string[] | undefined, days: number) => {
  const [state, set] = React.useState<{
    loading: boolean;
    error?: boolean;
    data?: IManagerDashboardSparePartOrderResponse;
  }>({
    loading: false,
  });

  const installationsString = installationIds?.toString();

  React.useEffect(() => {
    const request = async () => {
      set((s) => ({ ...s, loading: true }));
      try {
        const response = await makeDashboardRequest<IManagerDashboardSparePartOrderResponse>(
          "sparePartOrders",
          days,
          installationIds!
        );
        set(() => ({ data: response, loading: false }));
      } catch {
        set((s) => ({ ...s, loading: false, error: true }));
      }
    };

    if (installationsString && !state.loading) {
      request();
    }
  }, [installationsString]);

  return state;
};

export const useDocuments = (
  installationIds: string[] | undefined,
  days: number,
  { enabled = false }: { enabled: boolean }
) => {
  const [state, set] = React.useState<{
    loading: boolean;
    error?: boolean;
    data?: {
      bulletins: IDocumentOpenStatus[];
      techDocuments: IDocumentOpenStatus[];
    };
  }>({
    loading: false,
  });

  const installationsString = installationIds?.toString();

  React.useEffect(() => {
    const request = async () => {
      set((s) => ({ ...s, loading: true }));
      try {
        const response = await makeDashboardRequest<IManagerDashboardDocumentResponse>(
          "technicaldocuments",
          days,
          installationIds!
        );

        set(() => ({
          data: {
            bulletins: getReadStatusForDocuments(
              installationIds!,
              response.technicalDocuments.filter((doc) => doc.isCriticalBulletin)
            ),
            techDocuments: getReadStatusForDocuments(
              installationIds!,
              response.technicalDocuments.filter((doc) => !doc.isCriticalBulletin)
            ),
          },
          loading: false,
        }));
      } catch {
        set((s) => ({ ...s, loading: false, error: true }));
      }
    };

    if (installationsString && !state.loading && enabled) {
      request();
    }
  }, [installationsString, enabled]);

  return state;
};

export const useReports = (
  installationIds: string[] | undefined,
  days: number,
  { enabled = false }: { enabled: boolean }
) => {
  const [state, set] = React.useState<{
    loading: boolean;
    error?: boolean;
    data?: IManagerDashboardCMReportResponse;
  }>({
    loading: false,
  });

  const installationsString = installationIds?.toString();

  React.useEffect(() => {
    const request = async () => {
      set((s) => ({ ...s, loading: true }));
      try {
        const response = await makeDashboardRequest<IManagerDashboardCMReportResponse>(
          "CMReports",
          days,
          installationIds!
        );

        set(() => ({ data: response, loading: false }));
      } catch {
        set((s) => ({ ...s, loading: false, error: true }));
      }
    };

    if (installationsString && !state.loading && enabled) {
      request();
    }
  }, [installationsString, enabled]);

  return state;
};
