import * as React from "react";

import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { IconSize, iconStyles } from "src/design-system/Tokens/icons";
import { getSelectedColorTheme } from "src/util/localstorage";
import styled from "styled-components";

export const Svg = styled.svg<{ size?: IconSize }>`
  width: ${(props) => (props.size ? iconStyles[props.size].mobile.width : iconStyles.small.mobile.width)};
  height: ${(props) => (props.size ? iconStyles[props.size].mobile.height : iconStyles.small.mobile.height)};
  fill: ${(props) => props.color};

  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: ${(props) => (props.size ? iconStyles[props.size].desktop.width : iconStyles.small.desktop.width)};
    height: ${(props) => (props.size ? iconStyles[props.size].desktop.height : iconStyles.small.desktop.height)};
  }
`;

export interface IIconBase {
  // 'className' is coming from styled-components, if width, height or fill styles are desired to be set to some custom values
  className?: string;
  viewBox?: string;
  size?: IconSize;
  color?: string;
  children?: React.ReactNode;
  transform?: string;
  desc?: string;
}

// default icon size is 'small'
export const IconBase = (props: IIconBase) => {
  const theme = getSelectedColorTheme(); //TODO: get name from context
  const defaultColor = theme === "light" ? iconStyles.defaultColorLight : iconStyles.defaultColorDark;

  const color = props.color ? props.color : defaultColor;
  const viewBox = props.viewBox ? props.viewBox : "0 0 48 48";

  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={props.className}
      size={props.size}
      color={color}
      transform={props.transform}
    >
      {props.desc && <desc>{props.desc}</desc>}
      {props.children}
    </Svg>
  );
};
