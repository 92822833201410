import * as React from "react";

import { defaultBorderRadius, defaultBoxBorder, defaultBoxShadow, spacer, spacers } from "../Tokens/tokens";

import { H4 } from "src/design-system/Tokens/typography";
import { Link } from "react-router-dom";
import { ThemedProps } from "src/design-system/Theme/theme";
import styled from "styled-components";
import { whenIE11 } from "src/util/browserCompatibility";

interface ILinkPassedData {
  pathname: string;
  state: {
    [property: string]: any; // might be any object passed via router
  };
}

interface IDetailsButton {
  label: string;
  additionalVesselInfo?: JSX.Element;
  href: string | ILinkPassedData;
  icon?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
}

interface IWrapperProps extends ThemedProps {
  $justifyCenter?: boolean;
}

// Remember to change the size of MenuItemRectangleLoader under LoadingSpinner too!
const Wrapper = styled.div<IWrapperProps>`
  color: ${(props) => props.theme.primary2};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  min-height: 100%;
  border: ${defaultBoxBorder};
  border-radius: ${defaultBorderRadius};
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.navigationButton.background};
  box-shadow: ${defaultBoxShadow};

  & svg {
    fill: ${(props) => props.theme.primary2};
  }

  & ${H4} {
    color: ${(props) => props.theme.primary2};
  }

  ${(props) => (props.$justifyCenter ? "justify-content: center;" : "")}
`;

const IconWrapper = styled.div`
  padding-top: ${spacer(2)};
`;

const Separator = styled.div`
  width: 70%;
  height: 1px;
  border-bottom: 1px solid ${(props: ThemedProps) => props.theme.primary2};
`;
const LabelWrapper = styled.div`
  min-height: ${(props: { withIcon: boolean }) => (props.withIcon ? "90px" : "78px")};
  padding: ${spacers([0, 2, 2, 2])};
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${whenIE11(`
    height: 78px;
  `)};
`;

const DetailsWrapper = styled.div`
  padding: ${spacer(2)};
`;

export class DetailsButton extends React.Component<IDetailsButton> {
  public render() {
    const hasChildren = this.props.children && React.Children.count(this.props.children) > 0;

    return (
      <Link to={this.props.href} onClick={this.props.onClick}>
        <Wrapper $justifyCenter={!hasChildren}>
          <LabelWrapper withIcon={!!this.props.icon}>
            {this.props.icon && <IconWrapper>{this.props.icon}</IconWrapper>}
            <H4>{this.props.label}</H4>
            {this.props.additionalVesselInfo}
          </LabelWrapper>

          {hasChildren && (
            <>
              <Separator />
              <DetailsWrapper>{this.props.children}</DetailsWrapper>
            </>
          )}
        </Wrapper>
      </Link>
    );
  }
}
