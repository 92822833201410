import * as React from "react";
import colors from "src/design-system/Tokens/colors";
import { getSelectedColorTheme } from "src/util/localstorage";
import styled from "styled-components";

import { fixedFontSizes, H4 } from "src/design-system/Tokens/typography";
import { IconSize } from "src/icons";
import { IIconBase } from "src/icons/IconBase";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { ThemedProps } from "src/design-system/Theme/theme";
import { Container } from "../Container";
import { FlexContainer } from "src/design-system/Container";
import { spacer } from "../Tokens/tokens";

interface IWrapperProps {
  isSelected: boolean;
  withIcon: boolean;
  withBorder?: boolean;
  fullwidth?: boolean;
  isColumn?: boolean;
  centeredText?: boolean;
}

const getColorTheme = () => {
  return getSelectedColorTheme();
}

const Wrapper = styled.div<IWrapperProps>`
  ${(props: ThemedProps<IWrapperProps>) =>
    props.isSelected
      ? `
    background: ${props.theme.foreground};
    color: ${getColorTheme() === "quantiparts" ? colors.quantiparts.list : props.theme.background};

    svg {
      fill: ${getColorTheme() === "quantiparts" ? colors.quantiparts.list : props.theme.background};
    }
  `
      : `
    background: ${props.theme.switchButton.background};
    color: ${getColorTheme() === "quantiparts" ? colors.quantiparts.list : props.theme.foreground};

    svg {
      fill: ${getColorTheme() === "quantiparts" ? colors.quantiparts.list : props.theme.foreground};
    }
  `}

  ${(props: ThemedProps<IWrapperProps>) =>
    props.withBorder
      ? `
    border: 1px ${props.theme.foreground} solid;
    margin-right: ${props.isColumn ? "0" : "15"}px;
  `
      : `
    border: none;
  `}

  ${(props: IWrapperProps) =>
    props.withIcon
      ? `
    justify-content: left;
    padding: 16px 20px 16px 8px;
    @media(max-width: ${breakpoints.mobileLarge}px) {
      padding: 8px;
    }
  `
      : `
    justify-content: center;
    padding: 16px;
  `}

  ${(props: IWrapperProps) =>
    props.centeredText &&
    `
    justify-content: center;
  `}

  min-width: 100px;
  min-height: 36px;
  margin-bottom: 20px;
  display: inline-flex;
  cursor: pointer;
  align-items: center;

  ${(props: IWrapperProps) =>
    props.fullwidth &&
    `
    flex: 1;
    min-width: initial;
    padding: 8px;
  `}

  @media(max-width: ${breakpoints.mobileLarge}px) {
    min-width: auto;
  }
`;

const SwitchButtonIcon = styled.div`
  padding: 0 0.25em;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    svg {
      width: 30px;
      height: 30px;
    }
  }
  @media (max-width: ${breakpoints.mobile}px) {
    display: none;
  }
  svg {
    fill: ${getColorTheme() === "quantiparts" ? colors.quantiparts.blue : ''};
  }
`;

interface ISwitchButtonLabelProps {
  fullwidth?: boolean;
}

const SwitchButtonLabel = styled(H4)<ISwitchButtonLabelProps>`
  text-transform: none;
  color: ${getColorTheme() === "quantiparts" ? colors.quantiparts.blue : 'inherit'};
  margin-right: ${spacer(1)};
  ${(props: ISwitchButtonLabelProps) =>
    props.fullwidth &&
    `
    @media(max-width: ${breakpoints.mobileLarge}px) {
      display: none;
    }
  `}
  @media(max-width: ${breakpoints.mobileLarge}px) {
    font-size: ${fixedFontSizes.baseFontSize}px;
  }
`;

const SwitchButtonDate = styled(Container)`
  @media (max-width: ${breakpoints.mobileLarge}px) {
    font-size: ${fixedFontSizes.baseFontSizeSmall}px;
  }
`;

export interface ISwitchButtonProps {
  withBorder?: boolean;
  icon?: React.ComponentType<IIconBase>;
  fullwidth?: boolean;
  isColumn?: boolean;
  centeredText?: boolean;
  className?: string;
  label: string;
  isSelected: boolean;
  lights?: React.ReactNode;
  date?: string;
  onClick(): void;
}

export class SwitchButton extends React.Component<ISwitchButtonProps> {
  public render() {
    const { label, icon, onClick, isSelected, withBorder, centeredText, lights, date } = this.props;
    const withIcon = icon !== undefined;

    return (
      <Wrapper
        key={label}
        onClick={onClick}
        isSelected={isSelected}
        withBorder={withBorder}
        withIcon={withIcon}
        centeredText={centeredText}
        className={this.props.className}
        isColumn={this.props.isColumn}
      >
        {!!this.props.icon && (
          <SwitchButtonIcon>
            <this.props.icon size={IconSize.Large} />
          </SwitchButtonIcon>
        )}
        <FlexContainer $column={true}>
          <FlexContainer $centered={true}>
            <SwitchButtonLabel>{label}</SwitchButtonLabel>
            {lights}
          </FlexContainer>
          {date && <SwitchButtonDate>{date}</SwitchButtonDate>}
        </FlexContainer>
      </Wrapper>
    );
  }
}
