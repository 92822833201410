import * as React from "react";
import { whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import { baseTextStyles } from "src/design-system/Tokens/typography";
import styled from "styled-components";

interface IIconButtonProps {
  icon: JSX.Element;
  label: string;
  isSelected?: boolean;
  onClick(): void;
}

interface IIconButtonWrapperProps {
  isSelected?: boolean;
}

const IconButtonWrapper = styled.div`
  width: 10.5em;
  height: 2.5em;
  border: 2px solid ${colors.primary.gray};
  background-color: ${(props: IIconButtonWrapperProps) =>
    props.isSelected ? colors.primary.blue : colors.primary.white};
  color: ${(props: IIconButtonWrapperProps) => (props.isSelected ? colors.primary.white : colors.primary.black)};
  margin: 3px 0 3px 6px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    fill: ${(props: IIconButtonWrapperProps) => props.isSelected && colors.primary.white};
  }
`;

const IconWrapper = styled.span`
  margin: auto 0;
  width: 2.5em;

  svg {
    height: 32px;
    width: 32px;
  }

  ${whenTabletOrLarger(`
    width: 3em;
  `)}
`;

const Label = styled.span`
  ${baseTextStyles};
  color: inherit;
  margin: ${spacer(1)};
`;

export const IconButton: React.FC<IIconButtonProps> = ({ icon, label, isSelected, onClick }) => {
  return (
    <IconButtonWrapper onClick={onClick} isSelected={isSelected}>
      <IconWrapper>{icon}</IconWrapper>
      <Label>{label}</Label>
    </IconButtonWrapper>
  );
};
