import * as React from "react";

import { Container, FlexContainer } from "src/design-system/Container";
import { IconSize, WarningIcon } from "src/icons";

import { AnalyticsEvent } from "../AnalyticsEvent";
import { Button } from "src/design-system/Button";
import { PurchaseWithNotification } from "src/components/CommerceList/types";
import { analyticsDocumentTypes } from "../AnalyticsEvent/AnalyticsEventContainer";
import { analyticsEvents } from "src/events";
import { displayError } from "src/util/error";
import download from "downloadjs";
import { get } from "lodash";
import { requestPdf } from "src/util/commerce";
import { translateString } from "src/util/localization";

interface IState {
  fetchingPDF: boolean;
  pdfData: string | null;
  pdfDownloadable: boolean;
  pdfRequested: boolean | null;
}

const initialState = {
  pdfData: null,
  fetchingPDF: false,
  pdfRequested: false,
  pdfDownloadable: false,
};

export const PurchasePdf = (props: { details: PurchaseWithNotification }) => {
  const [state, setState] = React.useState<IState>(initialState);

  React.useEffect(() => {
    if (state.pdfData) {
      download(`data:application/pdf;base64,${state.pdfData}`, "PartsDocument.pdf");
      setState(initialState);
    }
  }, [state.pdfData]);

  const toggleSpinner = () => {
    setState((s) => ({ ...s, fetchingPDF: !s.fetchingPDF }));
  };

  const requestPDF = async () => {
    toggleSpinner();

    try {
      const request = await requestPdf(props.details.id);

      if (request) {
        setState({
          pdfRequested: true,
          fetchingPDF: false,
          pdfData: request.pdfData,
          pdfDownloadable: Boolean(request.pdfData && request.pdfRequested),
        });
      } else {
        toggleSpinner();
      }
    } catch {
      displayError(translateString("spareParts.errorFetchingPDF"));
    }
  };

  if (state.pdfData === null) {
    if (state.pdfRequested) {
      return (
        <FlexContainer $centered $margin={[0, 1]}>
          <FlexContainer $noShrink>
            <WarningIcon size={IconSize.Small} />
          </FlexContainer>
          <Container $margin={[1, 1]}>{translateString("spareParts.noPDFinSAP")}</Container>
        </FlexContainer>
      );
    } else {
      return !state.fetchingPDF ? (
        <AnalyticsEvent
          event={({ account, user }) =>
            analyticsEvents.dcpDownloadDocument({
              dcp_user_role: user.role,
              dcp_user_seniority: user.seniority,

              dcp_account_role: account.role,
              dcp_account_sector: account.sector,
              dcp_account_country: account.customerCountry,
              dcp_account_sales_region: account.salesRegion,
              dcp_account_market_segment: account.marketSegment,
              dcp_account_value_category: account.customerValueCategory,

              dcp_account_id: account.name,
              dcp_document_id: props.details.wartsilaReference,
              dcp_document_type: get(analyticsDocumentTypes, props.details.type, props.details.type),
            })
          }
        >
          <Button onClick={requestPDF}>{translateString("spareParts.requestPDF")}</Button>
        </AnalyticsEvent>
      ) : (
        <Button loading />
      );
    }
  }

  return null;
};
