import * as React from "react";

import { Checkbox } from "src/design-system/Checkbox";
import { ICheckbox } from "src/design-system/Checkbox/Checkbox";
import { InputType, RowWrapper, ValuesWrapper } from "src/design-system/Checkbox/OptionListItem";
import { Radio } from "src/design-system/Radio";
import { IRadio } from "src/design-system/Radio/Radio";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import { Disclaimer, KeepHeightStyles } from "src/design-system/Tokens/typography";
import { getSelectedColorTheme } from "src/util/localstorage";
import styled, { css, keyframes } from "styled-components";

const LoaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ListLoaderAnimation = keyframes`
  from {
    background-position: -468px 0
  }
  to {
    background-position: 468px 0
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  margin: 1em auto;
  flex-direction: column;
  align-items: ${(props: ILoadingSpinnerProps) => (props.align ? props.align : "center")}; ;
`;

export const Loader = styled.div`
  font-size: ${(props: ILoadingSpinnerProps) => (props.size ? props.size + "em" : "inherit")};
  position: relative;
  margin: 0;
  width: 5em;
  height: 5em;
  margin-bottom: 0.5em;

  ::before,
  ::after {
    position: absolute;
    box-sizing: border-box;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.4em solid rgba(255, 255, 255, 0.1);
  }

  ::after {
    animation: ${LoaderAnimation} 0.6s linear;
    animation-iteration-count: infinite;
    border-width: ${(props: ILoadingSpinnerProps) => (props.size ? 0.2 + props.size * 0.2 + "em" : "0.4em")};
    border-color: ${(props: ILoadingSpinnerProps) => (props.dark ? colors.primary.black : colors.primary.white)}
      transparent transparent;
    border-style: solid;
    box-shadow: 0 0 0 1px transparent;
  }
`;

const ListAnimationStyle = css`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${ListLoaderAnimation};
  animation-timing-function: linear;
  background: ${colors.secondary.gray10};
  background: linear-gradient(
    to right,
    ${colors.secondary.gray10} 8%,
    ${colors.primary.white} 18%,
    ${colors.secondary.gray10} 33%
  );
  background-size: 800px 100px;
  max-width: 400px;
  position: relative;
`;

export const TextLoader = styled.div`
  ${KeepHeightStyles}
  ${ListAnimationStyle};
  background: linear-gradient(
    to right,
    ${(props: ILoadingSpinnerProps) => props.dark ? colors.secondary.bluegray100 : colors.secondary.gray10} 8%,
    ${(props: ILoadingSpinnerProps) => props.dark ? colors.secondary.blue100 : colors.primary.white} 18%,
    ${(props: ILoadingSpinnerProps) => props.dark ? colors.secondary.bluegray100 : colors.secondary.gray10} 33%
  );
  width: ${(props: ILoadingSpinnerProps) => props.size ? (props.size * 40) + "em" : "100%"};
`;

export const DisclaimerLoader = styled(Disclaimer)`
  ${KeepHeightStyles}
  ${ListAnimationStyle};
`.withComponent("div");

export const TableCellLoader = styled.div`
  padding: 6px 20px;
  height: 100%;
  ${ListAnimationStyle};
`;

const MenuItemLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  max-width: 150px;
  padding: 0 6px;
  align-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const MenuItemLoaderCircle = styled.div`
  width: 6.8em;
  height: 6.8em;
  margin-bottom: 10px;
  border-radius: 50%;
  ${ListAnimationStyle};
  background: linear-gradient(
    to right,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray20 : colors.secondary.bluegray100)} 8%,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray0 : colors.secondary.blue100)} 18%,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray20 : colors.secondary.bluegray100)} 33%
  );
  background-size: 800px 100px;
`;

const MenuItemLoaderText = styled(TextLoader)`
  background: linear-gradient(
    to right,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray20 : colors.secondary.bluegray100)} 8%,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray0 : colors.secondary.blue100)} 18%,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray20 : colors.secondary.bluegray100)} 33%
  );
  background-size: 800px 100px;
  max-width: 6.8em;
  width: 100%;
`;

const MenuItemRectangle = styled.div`
  min-height: 150px;
  min-width: 120px;

  @media (min-width: ${breakpoints.mobileLarge}px) {
    min-width: 180px;
  }

  width: 100%;
  border-radius: ${defaultBorderRadius};
  margin: 0;
  position: relative;
  display: flex;
  ${ListAnimationStyle};
  background: linear-gradient(
    to right,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray20 : colors.secondary.bluegray100)} 8%,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray0 : colors.secondary.blue100)} 18%,
    ${(props: MenuItemLoaderProps) => (props.light ? colors.secondary.gray20 : colors.secondary.bluegray100)} 33%
  );
  background-size: 800px 100px;
`;

interface IOptionListLoaderProps {
  maxWidth?: string;
  values: number;
  valueWidth?: string;
  inputType?: InputType;
}

export const OptionListLoader: React.FC<IOptionListLoaderProps> = (props) => {
  const InputComponent: React.FunctionComponent<ICheckbox | IRadio> =
    props.inputType === InputType.Radio ? Radio : Checkbox;
  return (
    <RowWrapper style={{ maxWidth: !!props.maxWidth ? props.maxWidth : "100%" }}>
      <InputComponent checked={false} onChange={() => undefined} color={colors.secondary.gray10} />
      {Array.from(Array(props.values), (e, key: number) => {
        return (
          <ValuesWrapper
            key={key}
            style={{ width: !!props.valueWidth ? props.valueWidth : "300px", cursor: "default" }}
          >
            <DisclaimerLoader />
            <TextLoader />
          </ValuesWrapper>
        );
      })}
    </RowWrapper>
  );
};

interface MenuItemLoaderProps {
  light?: boolean;
}

export const MenuItemLoader: React.FC = () => {
  const theme = getSelectedColorTheme();
  const light = theme === "light"; // if the theme is light, loader should be light
  return (
    <MenuItemLoaderWrapper>
      <MenuItemLoaderCircle light={light} />
      <MenuItemLoaderText light={light} />
    </MenuItemLoaderWrapper>
  );
};

export const MenuItemRectangleLoader: React.FC = () => {
  const theme = getSelectedColorTheme();
  const light = theme === "light"; // if the theme is light, loader should be light
  return <MenuItemRectangle light={light} />;
};

export enum spinnerSize {
  sm = 0.25,
  md = 0.5,
  lg = 1,
}
export enum spinnerAlign {
  start = "flex-start",
  end = "flex-end",
}

interface ILoadingSpinnerProps {
  text?: string;
  dark?: boolean;
  disableText?: boolean;
  size?: spinnerSize;
  align?: spinnerAlign;
}

export const LoadingSpinner = (props: ILoadingSpinnerProps) => {
  const theme = getSelectedColorTheme();
  let dark = props.dark || theme === "light"; // if the theme is light, loader should be dark
  if (props.dark === false) {
    dark = false;
  }
  return (
    <LoaderWrapper align={props.align}>
      <Loader dark={dark} size={props.size} />
      {!props.disableText &&
        (props.text ? (
          <div>{props.text}</div>
        ) : (
          <div>
            <LocalizedStringComponent id="loading" />
          </div>
        ))}
    </LoaderWrapper>
  );
};
