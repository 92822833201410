// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_ru: ITranslations = {
  ru: {
    "contracts.attachmentDocumentTypes": "Документы ({types})",
    "contracts.attachmentImageTypes": "Изображения ({types})",
    "contracts.attachmentsMessage": "Вы можете выгружать следующие типы приложений с максимальным размером файла {maxSize}.",
    "contracts.cbmExpertName": "Диагностика активов",
    "contracts.contact": "Контакты",
    "contracts.contactInformation": "Контактная информация",
    "contracts.contactPerson": "Контактное лицо",
    "contracts.contractManager": "Менеджер по контрактам",
    "contracts.contractNumber": "Номер соглашения",
    "contracts.contractType": "Тип",
    "contracts.equipmentContracts": "Соглашения по изделиям",
    "contracts.expertiseCenter": "Центр экспертиз",
    "contracts.expiry": "Дата окончания срока действия",
    "contracts.installationContracts": "Соглашения по установкам",
    "contracts.installationContracts:energy": "Соглашения по электростанциям",
    "contracts.installationContracts:marine": "Соглашения по судам",
    "contracts.installationsInScope": "Обсуждаемые установки",
    "contracts.installationsInScope:energy": "Обсуждаемые электростанции",
    "contracts.installationsInScope:marine": "Обсуждаемые суда",
    "contracts.invalidContract": "Недействительное соглашение",
    "contracts.maintenancePlannerName": "Планирование обслуживания",
    "contracts.noContactPerson": "Нет контактного лица",
    "contracts.noEquipmentContractsAvailable": "Нет доступных соглашений по изделиям",
    "contracts.noInstallationContractsAvailable": "Нет доступных соглашений по установкам",
    "contracts.noInstallationContractsAvailable:energy": "Нет доступных соглашений по электростанциям",
    "contracts.noInstallationContractsAvailable:marine": "Нет доступных соглашений по судам",
    "contracts.productNumbers": "Номера изделий",
    "contracts.productReferenceTypes": "Типы ссылочных номеров изделий",
    "contracts.remoteSupporterName": "Дистанционная поддержка",
    "contracts.startDate": "Дата начала",
    "contracts.technicalExperts": "Технические эксперты",
    "contracts.validation.messageIsRequired": "Требуется сообщение",
    "contracts.validation.subjectIsRequired": "Требуется предмет",
  },
};
