// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_es_MX: ITranslations = {
  es_MX: {
    "reports.attachments": "Archivos adjuntos",
    "reports.conditionMonitorinReports": "Reportes de diagnóstico de activos",
    "reports.newReportAvailable": "Nuevo reporte disponible",
    "reports.noReportsAvailable": "No hay reportes disponibles",
    "reports.reportPeriodStartDate": "Periodo",
    "reports.reportPublishedDate": "Fecha de emisión",
    "reports.title": "Título",
  },
};
