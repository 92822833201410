import { connect } from "react-redux";
import { IAppState } from "src/redux";
import {
  PushPermissionNotification,
  IOwnProps,
  IPushPermissionNotificationStateProps,
} from "./PushPermissionNotificationComponent";

const mapStateToProps = (state: IAppState) => ({
  userId: state.userInfo.userId,
});

export const PushPermissionNotificationContainer = connect<IPushPermissionNotificationStateProps, {}, IOwnProps>(
  mapStateToProps
)(PushPermissionNotification);
