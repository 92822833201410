import moment from "moment";
import { IAccountUser, IEquipment } from "online-services-types";
import {
  IManagerDashboardCMReportItem,
  IManagerDashboardDocumentItem,
  IManagerDashboardRequestItem,
  IRFQ,
  ISparePartOrder,
  RequestType,
} from "./request/interfaces";

export interface IGroupedEquipmentItem {
  equipmentId: string;
  name: string;
  runningHours?: number;
}

export interface IGroupedRequestItem {
  requestType: RequestType;
  count: number;
}

export interface IGroupedRFQItem {
  installationId: string;
  count: number;
}

export interface IGroupedDate<T> {
  date: string;
  items: T;
}

export interface IGroupedPurchaseItem {
  installationId: string;
  total: number;
}

export type GroupedDates<T> = Array<IGroupedDate<T>>;

export interface IUnreadDocument {
  subject: string;
  date: string;
}

export interface IDocumentOpenStatus {
  installationId: string;
  readCount: number;
  unreadDocuments: IUnreadDocument[];
}

export interface IDashboardData {
  timeframe: {
    start: string;
    end: string;
    days: number;
  };
  personnelLoggedInPercentage: number;
  invoices: {
    overdue: number;
    notOverdue: number;
    currency: string;
  };
  runningHours: Array<{
    installationId: string;
    groupedDates: GroupedDates<IGroupedEquipmentItem[]>;
  }>;
  cmReports: IDocumentOpenStatus[];
  bulletins: IDocumentOpenStatus[];
  techDocuments: IDocumentOpenStatus[];
  submittedRequests: GroupedDates<IGroupedRequestItem[]>;
  totalSubmittedRequests: number;
  sparePartOrders: GroupedDates<IGroupedPurchaseItem[]>;
  sparePartOrderCurrency: string;
  rfqs: GroupedDates<IGroupedRFQItem[]>;
}

const groupByDate = <InputType, OutputType = InputType>(
  items: InputType[],
  dateField: keyof InputType,
  itemMapper: (item: InputType) => OutputType
): GroupedDates<OutputType[]> => {
  const dates: GroupedDates<OutputType[]> = [];

  items.forEach((item) => {
    // Take just the date part from ISO date string
    const itemDate = new Date(`${item[dateField]}`).toISOString().split("T")[0];
    let foundDate = dates.find((date) => date.date === new Date(itemDate).toISOString());
    const entry = itemMapper(item);

    if (!foundDate) {
      foundDate = { items: [], date: new Date(itemDate).toISOString() };
      dates.push(foundDate);
    }

    foundDate.items.push(entry);
  });

  return dates;
};

const calculateTotalByField = <T, GroupFieldType = string>(data: T[], field: keyof T) => {
  const totalCount: { [K: string]: number } = {};
  data.forEach((item) => {
    // Force the key type to string
    const key = item[field] as {} as string;
    totalCount[key] = (totalCount[key] || 0) + 1;
  });
  return Object.keys(totalCount).map((key) => ({
    requestType: key as {} as GroupFieldType,
    count: totalCount[key],
  }));
};

const calculateSumByInstallation = <T extends { installationId: string }>(data: T[], sumField: keyof T) => {
  const totalSum: { [K: string]: number } = {};
  data.forEach((item) => {
    // Force the key type to string
    const key = item.installationId as {} as string;
    totalSum[key] = (totalSum[key] || 0) + Number(item[sumField]);
  });
  return Object.keys(totalSum).map((key) => ({
    installationId: key as {} as string,
    sum: totalSum[key],
  }));
};

export const groupEquipmentByDate = (equipments: IEquipment[], timeframeStart: string, installationId: string) =>
  groupByDate<IEquipment, IGroupedEquipmentItem>(
    equipments
      .filter((item) => item.productCategory === "Engines")
      .filter((item) => item.runningHoursDate !== null)
      .filter((equipment) => moment(equipment.runningHoursDate).toISOString() >= timeframeStart)
      .filter((equipment) => equipment.installationId === installationId),
    "runningHoursDate",
    (item) => ({
      equipmentId: item.id,
      name: item.serialNumber,
      runningHours: item.runningHours,
    })
  );

export const groupRequestsByType = (requests: IManagerDashboardRequestItem[]) =>
  groupByDate<IManagerDashboardRequestItem, IManagerDashboardRequestItem>(requests, "created", (item) => item).map(
    (groupedDate) => ({
      date: groupedDate.date,
      items: calculateTotalByField<IManagerDashboardRequestItem, RequestType>(groupedDate.items, "requestType"),
    })
  );

export const groupRFQsByType = (requests: IRFQ[]) =>
  groupByDate<IRFQ, IRFQ>(requests, "creationDate", (item) => item).map((groupedDate) => ({
    date: groupedDate.date,
    items: calculateTotalByField<IRFQ>(groupedDate.items, "installationId").map((item) => ({
      installationId: item.requestType,
      count: item.count,
    })),
  }));

export const groupPurchasesByInstallation = (orders: ISparePartOrder[]) =>
  groupByDate<ISparePartOrder, ISparePartOrder>(orders, "creationDate", (item) => item).map((groupedDate) => ({
    date: groupedDate.date,
    items: calculateSumByInstallation<ISparePartOrder>(groupedDate.items, "convertedTotal").map((item) => ({
      installationId: item.installationId,
      total: item.sum,
    })),
  }));

export const getPersonnelLoggedInPercentage = (users: IAccountUser[], timeframeStart: string) =>
  (100 * users.filter((user) => user.lastLogin >= timeframeStart).length) / Math.max(1, users.length);

export const getReadStatusForCMReports = (
  installationIds: string[],
  reports: IManagerDashboardCMReportItem[]
): IDocumentOpenStatus[] =>
  installationIds.map((installationId) => ({
    installationId,
    readCount: reports.filter((report) => report.installationId === installationId && report.isOpened).length,
    unreadDocuments: reports
      .filter((report) => report.installationId === installationId && !report.isOpened)
      .map((report) => ({ subject: report.subject, date: report.reportPublishedDate })),
  }));

export const getReadStatusForDocuments = (
  installationIds: string[],
  reports: IManagerDashboardDocumentItem[]
): IDocumentOpenStatus[] =>
  installationIds.map((installationId) => ({
    installationId,
    readCount: reports.filter((report) => report.installationId === installationId && report.isOpened).length,
    unreadDocuments: reports
      .filter((report) => report.installationId === installationId && !report.isOpened)
      .map((report) => ({ subject: report.title, date: report.documentDate })),
  }));
