import lodash from "lodash";
import React, { useEffect, useState } from "react";
import { LocalizedString } from "src/components/Localization";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { FlexContainer } from "src/design-system/Container";
import { translateString } from "src/util/localization";
import {
  DataCell,
  ISFOCFleetData,
  Legend,
  NameDataCell,
  PartOfABarWithTooltip,
  ScaleNumber,
  Table,
} from "src/util/sfoc/sfocCharts";
import { DashboardItem } from "src/views/ManagerDashboardView/ManagerDashboardViewComponent";

interface IBrandStatusesDeviation {
  brandId: string;
  brandName: string;
  approved: number;
  rejected: number;
  suspended: number;
  notReceived: number;
}

export const AllTestPerformanceHistory = (props: { chartData: ISFOCFleetData[]; quarter: string }) => {
  const [brandDeviations, setBrandDeviations] = useState<IBrandStatusesDeviation[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<string>();
  const [visibleTooltipStatus, setVisibleTooltipStatus] = useState<string>();

  useEffect(() => {
    const allBrandDeviations: IBrandStatusesDeviation[] = [];

    for (const dataSet of props.chartData) {
      if (dataSet.quarter !== `Q${props.quarter}`) {
        const existingBrand = allBrandDeviations.find((brand) => brand.brandName === dataSet.accountName);
        let approved: number = 0;
        let rejected: number = 0;
        let suspended: number = 0;
        let notReceived: number = 0;

        switch (dataSet.testValidation) {
          case "Approved":
            approved += 1;
            break;
          case "Rejected":
            rejected += 1;
            break;
          case "Suspended":
            suspended += 1;
            break;
          case "Not received":
            notReceived += 1;
            break;
        }

        // if it is a new brand we add
        if (existingBrand === undefined) {
          allBrandDeviations.push({
            brandId: dataSet.accountId,
            brandName: dataSet.accountName,
            approved: approved,
            rejected: rejected,
            suspended: suspended,
            notReceived: notReceived,
          });
          // if it is a not a new brand we update
        } else {
          existingBrand.approved = existingBrand.approved + approved;
          existingBrand.rejected = existingBrand.rejected + rejected;
          existingBrand.suspended = existingBrand.suspended + suspended;
          existingBrand.notReceived = existingBrand.notReceived + notReceived;
        }
      }
    }

    setBrandDeviations(allBrandDeviations);
  }, [props.chartData]);

  const scale: React.ReactNode[] = [];
  for (let i = 0; i <= 100; i += 10) {
    scale.push(
      i === 0 ? (
        <ScaleNumber key={i}>
          <div>{i}</div>
        </ScaleNumber>
      ) : (
        <ScaleNumber key={i}>
          <div>{i}%</div>
        </ScaleNumber>
      )
    );
  }

  const Bar = (props: {
    brandId: string;
    approved: number;
    rejected: number;
    suspended: number;
    notReceived: number;
  }) => {
    const borderWidth = 15 * (1 + 1);
    const allStatuses = {
      approved: props.approved,
      rejected: props.rejected,
      suspended: props.suspended,
      notReceived: props.notReceived,
    };
    const allStatusesValues = lodash.sum(Object.values(allStatuses));
    return (
      <FlexContainer style={{ width: `calc(1000% + ${borderWidth}px)` }}>
        {Object.keys(allStatuses).map((status) => {
          return (
            <PartOfABarWithTooltip
              status={status}
              statusValue={allStatuses[status]}
              allStatusesValues={allStatusesValues}
              borderWidth={borderWidth}
              visible={visibleTooltip === props.brandId}
              visibleTooltipStatus={visibleTooltipStatus}
              onEnter={() => setVisibleTooltipStatus(status)}
              onExit={() => setVisibleTooltipStatus(undefined)}
            />
          );
        })}
      </FlexContainer>
    );
  };

  const allStatusValues = (brand: IBrandStatusesDeviation) =>
    brand.approved + brand.rejected + brand.suspended + brand.notReceived;

  let averageApproved = 0;
  let averageRejected = 0;
  let averageSuspended = 0;
  let averageNotReceived = 0;

  brandDeviations.map((brand) => {
    return (averageApproved += brand.approved) / allStatusValues(brand);
  });
  brandDeviations.map((brand) => {
    return (averageRejected += brand.rejected) / allStatusValues(brand);
  });
  brandDeviations.map((brand) => {
    return (averageSuspended += brand.suspended) / allStatusValues(brand);
  });
  brandDeviations.map((brand) => {
    return (averageNotReceived += brand.notReceived) / allStatusValues(brand);
  });

  const average: IBrandStatusesDeviation = {
    brandId: "average",
    brandName: "average",
    approved: averageApproved,
    rejected: averageRejected,
    suspended: averageSuspended,
    notReceived: averageNotReceived,
  };

  return (
    <DashboardItem title={<LocalizedString id="sfoc.allTestHistory" />}>
      {brandDeviations.length > 0 ? (
        <Table style={{ display: "inline" }}>
          <tbody>
            <tr>
              <NameDataCell>{translateString("sfoc.fleet")}</NameDataCell>
              {scale.map((_, index) => (
                <DataCell key={index} isBaseline={false} isLastCell={index === 10}>
                  {index === 0 && (
                    <div
                      onMouseEnter={() => setVisibleTooltip("average")}
                      onMouseLeave={() => setVisibleTooltip(undefined)}
                    >
                      <Bar
                        brandId="average"
                        approved={average.approved}
                        rejected={average.rejected}
                        suspended={average.suspended}
                        notReceived={average.notReceived}
                      />
                    </div>
                  )}
                </DataCell>
              ))}
            </tr>
            {brandDeviations.map((brand) => (
              <tr key={brand.brandId}>
                <NameDataCell>{brand.brandName}</NameDataCell>
                {scale.map((_, index) => (
                  <DataCell key={index} isBaseline={false} isLastCell={index === 10}>
                    {index === 0 && (
                      <div
                        onMouseEnter={() => setVisibleTooltip(brand.brandId)}
                        onMouseLeave={() => setVisibleTooltip(undefined)}
                      >
                        <Bar
                          brandId={brand.brandId}
                          approved={brand.approved}
                          rejected={brand.rejected}
                          suspended={brand.suspended}
                          notReceived={brand.notReceived}
                        />
                      </div>
                    )}
                  </DataCell>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              {scale}
            </tr>
          </tfoot>
          <Legend chart="allTestHistory" />
        </Table>
      ) : (
        <LocalizedStringComponent id={"sfoc.noData"} />
      )}
    </DashboardItem>
  );
};
