import { AnyAction } from "redux";

export interface ILanguageState {
  languageCode: string | null;
}

const initialState: ILanguageState = {
  languageCode: null,
};

interface ISetLanguageCodeAction {
  type: string;
  payload: string;
}

export const setLanguageCode = (code: string): ISetLanguageCodeAction => ({
  type: SET_LANGUAGE_CODE,
  payload: code,
});

const SET_LANGUAGE_CODE = "SET_LANGUAGE_CODE";

export const languageReducer = (state = initialState, action: AnyAction): ILanguageState => {
  switch (action.type) {
    case SET_LANGUAGE_CODE:
      return {
        ...state,
        languageCode: (action as ISetLanguageCodeAction).payload,
      };

    default:
      return state;
  }
};
