import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const QuantiPartsIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title> QuantiParts small Q icon</title>
    <g>
      <circle fill={"rgb(9, 167, 223)"} cx={11} cy={11} r={11} />
      <g transform={"translate(5.11268, 4.95775)"}>
        <path
          fill={"rgb(255, 255, 255)"}
          d="M 9.0603 7.73966 C 8.94629 8.13309 8.76194 8.50436 8.51567 8.83745 L 7.13913 7.62993 L 5.89828 8.90182 L 7.04196 9.89662 C 6.90565 9.9556 6.76123 9.99527 6.61306 10.0146 C 6.39861 10.0376 6.18276 10.0472 5.9669 10.0435 C 5.43584 10.0589 4.90947 9.94422 4.43736 9.71008 L 4.43456 9.70724 C 4.02593 9.49361 3.67641 9.18926 3.41533 8.81949 C 3.14894 8.44028 2.95523 8.01826 2.84356 7.57364 C 2.72378 7.1076 2.66389 6.6293 2.66481 6.14934 C 2.66358 5.65037 2.72347 5.15305 2.84356 4.66755 C 2.95336 4.21245 3.14691 3.7798 3.41533 3.39012 C 3.6747 3.0172 4.02437 2.71016 4.43456 2.49548 C 4.9065 2.26119 5.43304 2.14636 5.9641 2.16208 C 6.49515 2.14636 7.02169 2.26104 7.49364 2.49548 C 7.90398 2.71105 8.25381 3.019 8.51286 3.39281 C 8.78112 3.78354 8.97483 4.21709 9.08463 4.67309 C 9.20456 5.15949 9.26461 5.65771 9.2632 6.15772 C 9.2749 6.69157 9.20643 7.22437 9.0603 7.73966 M 11.5219 8.53145 C 11.7993 7.7714 11.9371 6.97107 11.9293 6.16581 C 11.9372 5.33689 11.7975 4.51276 11.5161 3.7289 C 11.2614 3.01196 10.8632 2.34967 10.3439 1.77929 C 9.8267 1.21924 9.18865 0.774015 8.47418 0.474304 C 7.67986 0.147497 6.82237 -0.0138851 5.95832 0.000935763 C 5.09725 -0.0120886 4.24288 0.149144 3.45104 0.474304 L 3.45962 0.484035 C 2.74188 0.782099 2.10071 1.22747 1.58119 1.78887 C 1.06261 2.3564 0.664122 3.01526 0.407716 3.7289 C 0.1301 4.50872 -0.00777208 5.32821 0.000338056 6.15218 C -0.00746015 6.96179 0.130412 7.76676 0.407716 8.53145 C 0.66225 9.23986 1.06058 9.89272 1.57994 10.4523 C 2.10211 11.0044 2.74297 11.4413 3.45822 11.7325 C 4.25145 12.0524 5.1052 12.2108 5.9655 12.1976 C 7.12151 12.1976 8.10158 11.975 8.90589 11.5295 L 10.6213 13.0141 L 11.862 11.7792 L 10.3497 10.4523 C 10.8645 9.88973 11.2625 9.23761 11.5219 8.53145"
        />
      </g>
    </g>
  </IconBase>
);
