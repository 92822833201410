// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fieldServices_en_US: ITranslations = {
  en_US: {
    "fieldServices.actualFinishDate": "Actual finish date",
    "fieldServices.actualStartDate": "Actual start date",
    "fieldServices.areYouInterested": "Are you interested?",
    "fieldServices.changedMindContactMe": "Changed my mind, contact me",
    "fieldServices.contactRequestReceivedFrom": "Contact request received from {interestedName}",
    "fieldServices.description": "Description of work",
    "fieldServices.estimatedFinishDate": "Estimated finish date",
    "fieldServices.estimatedStartDate": "Estimated start date",
    "fieldServices.notNow": "Not now",
    "fieldServices.poNumber": "PO Number",
    "fieldServices.productReferenceType": "Product reference type",
    "fieldServices.recommendationExpires": "This recommendation expires {expirationDate}",
    "fieldServices.recommendations": "Recommendations",
    "fieldServices.serviceWorkCoordinator": "Service work coordinator",
    "fieldServices.yesContactMe": "Yes, contact me",
  },
};
