import { translateString } from "src/util/localization";

export enum PurchaseStatus {
  Draft = "Draft",
  Quoted = "Quoted",
  Created = "Created",
  Ordered = "Ordered",
  Expired = "Expired",
  Cancelled = "Cancelled",
  Confirmed = "Confirmed",
  InProcess = "In Process",
  ToBeConfirmed = "To be confirmed",
  PartiallyCollected = "Partially collected",
  PartiallyDelivered = "Partially delivered",
  Collected = "Collected",
  ReadyToCollect = "Ready to be collected",
  Delivered = "Delivered",
  Packed = "Packed",
  SentToDistributor = "Sent to distributor",
  Dispatched = "Dispatched",
}

export const purchaseFilterStatuses = {
  all: [
    PurchaseStatus.Created,
    PurchaseStatus.InProcess,
    PurchaseStatus.ToBeConfirmed,
    PurchaseStatus.Confirmed,
    PurchaseStatus.Quoted,
    PurchaseStatus.Ordered,
    PurchaseStatus.Expired,
    PurchaseStatus.PartiallyCollected,
    PurchaseStatus.PartiallyDelivered,
    PurchaseStatus.Collected,
    PurchaseStatus.Delivered,
    PurchaseStatus.Cancelled,
    PurchaseStatus.SentToDistributor,
    PurchaseStatus.Draft,
  ],
  order: [
    PurchaseStatus.Created,
    PurchaseStatus.ToBeConfirmed,
    PurchaseStatus.Confirmed,
    PurchaseStatus.PartiallyCollected,
    PurchaseStatus.PartiallyDelivered,
    PurchaseStatus.Collected,
    PurchaseStatus.Delivered,
    PurchaseStatus.Cancelled,
    PurchaseStatus.SentToDistributor,
  ],
  quotation: [
    PurchaseStatus.Created,
    PurchaseStatus.InProcess,
    PurchaseStatus.Quoted,
    PurchaseStatus.Ordered,
    PurchaseStatus.Expired,
    PurchaseStatus.SentToDistributor,
  ],
  draft: [PurchaseStatus.Draft],
};

export const purchaseStatusNames = {
  [PurchaseStatus.Draft]: translateString("purchaseStatus.draft"),
  [PurchaseStatus.Quoted]: translateString("purchaseStatus.quoted"),
  [PurchaseStatus.Created]: translateString("purchaseStatus.created"),
  [PurchaseStatus.Ordered]: translateString("purchaseStatus.ordered"),
  [PurchaseStatus.Expired]: translateString("purchaseStatus.expired"),
  [PurchaseStatus.Cancelled]: translateString("purchaseStatus.cancelled"),
  [PurchaseStatus.InProcess]: translateString("purchaseStatus.inProcess"),
  [PurchaseStatus.ToBeConfirmed]: translateString("purchaseStatus.toBeConfirmed"),
  [PurchaseStatus.Confirmed]: translateString("purchaseStatus.confirmed"),
  [PurchaseStatus.Collected]: translateString("purchaseStatus.collected"),
  [PurchaseStatus.Delivered]: translateString("purchaseStatus.delivered"),
  [PurchaseStatus.SentToDistributor]: translateString("spareParts.sentToDistributor"),
  [PurchaseStatus.PartiallyCollected]: translateString("purchaseStatus.partiallyCollected"),
  [PurchaseStatus.PartiallyDelivered]: translateString("purchaseStatus.partiallyDelivered"),
};
