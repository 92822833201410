import jwt_decode from "jwt-decode";
import { IAuthJWTData, IInitUploadRequest, IInitUploadResponse, IUploadStatusResponse } from "online-services-types";
import { APIFetch } from "src/APIFetch";
import { IUploadedFile, QueueStatus } from "src/components/AttachmentUploader/AttachmentUploaderComponent";
import { displayError } from "./error";
import { translateString } from "./localization";

const uploadFailedTranslationKey = "fileUpload.uploadFailed";

const checkUploadStatus = async (uploadId: string): Promise<IUploadStatusResponse> => {
  const status = await new APIFetch<IUploadStatusResponse>("files/upload").get(uploadId);
  if (status.status === "busy" || status.status === "scanning") {
    return new Promise((resolve) => setTimeout(() => resolve(checkUploadStatus(uploadId)), 2000));
  }

  return status;
};

async function fileAsBinary(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onabort = () => reject(new Error(translateString("fileUpload.uploadAborted")));
    reader.onerror = () => reject(new Error(translateString(uploadFailedTranslationKey)));
    reader.readAsArrayBuffer(file);
  });
}

/* Upload file to SF
 * @param {File} file - File object from Dropzone
 * @param {string} [claimId] - Claim ID the file will be attached
 */

export async function uploadFileToSalesforce(
  file: File,
  claimId?: string,
  isAttachmentRecord = false,
  description?: string
): Promise<IUploadedFile | null> {
  try {
    const authJWT: string = localStorage.getItem("token") || "";
    const token: IAuthJWTData = jwt_decode(authJWT);

    if (token.readonly) {
      displayError(translateString("fileUpload.userHasReadOnlyAccess"));
      return null;
    }

    // Initialize upload
    const upload = await new APIFetch<IInitUploadRequest, IInitUploadResponse>("files/upload").post({
      filename: file.name,
      description,
      isAttachment: isAttachmentRecord,
      claimId,
    });

    if (upload) {
      // Upload file
      const response = await fetch(upload.uploadUrl, {
        method: "PUT",
        body: Buffer.from(await fileAsBinary(file), "binary").toString("base64"),
        headers: {
          "x-amz-acl": "bucket-owner-full-control",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Upload is now being processed in the lambda
      const status = await checkUploadStatus(upload.uploadId);

      return {
        id: status.fileId,
        status: status.status === "ok" ? QueueStatus.Success : QueueStatus.Failed,
        description,
        name: file.name,
      };
    } else {
      displayError(translateString(uploadFailedTranslationKey));
    }
    return null;
  } catch (error) {
    displayError(`${translateString(uploadFailedTranslationKey)}: ${(error as Error).message}`);
    return null;
  }
}

export const kB = 1024;
export const maxFileSizeMB = 25;
export const maxFileSize = maxFileSizeMB * kB * kB; // Max file size in bytes. File size limit for attachments is 25MB.
