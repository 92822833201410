import { CustomStyledTextInput, SearchButton } from "src/design-system/SearchInput";
import { H2, H4 } from "src/design-system/Tokens/typography";
import { IconSize, SearchIcon } from "src/icons";
import React, { useEffect, useState } from "react";
import { spacer, spacers } from "src/design-system/Tokens/tokens";
import styled, { ThemeProvider } from "styled-components";

import { ButtonStyle } from "src/design-system/Button";
import { Checkbox } from "src/design-system/Checkbox";
import { Dialog } from "../Dialog";
import { IInstallation } from "online-services-types";
import { InstallationRow } from "./InstallationRow";
import { LocalizedString } from "../Localization";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { getSelectedColorTheme } from "src/util/localstorage";
import { themes } from "src/design-system/Theme/theme";
import { translateString } from "src/util/localization";

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  max-height: calc(100vh - 300px);

  @media (min-width: ${breakpoints.tablet}px) {
    min-width: 600px;
  }
`;
const theme = getSelectedColorTheme();
const InstallationListHeader = styled.div`
  display: flex;
  align-items: center;
  min-height: 56px;
  padding-left: 20px;
  background: ${theme === "quantiparts" ? colors.quantiparts.blue : colors.primary.blue};
`;

const InstallationListHeaderNameColumn = styled(H4)`
  padding: ${spacers([1, 3])};
  margin: ${spacers([0, 0, 0, 1])};
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  user-select: none;
`;

const InstallationListWrapper = styled.div`
  width: 100%;
  min-height: 150px;
  overflow-y: auto;
  border: 1px solid black;
  border-top: none;
  box-sizing: border-box;


  @media (min-width: ${breakpoints.tablet}px) {
    flex: 1;
  }
`;

const InstallationSearchWrapper = styled(H2)`
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: ${spacer(2)};
  padding: ${spacers([1, 0])};
  max-width: 380px;
  min-width: 250px;

  @media (min-width: ${breakpoints.mobileLarge}px) {
    margin-left: auto;
    margin-bottom: 0;
    border-bottom: none;
  }
`.withComponent("div");

const InstallationList = ({
  displayedInstallations,
  selectedInstallations,
  onRowSelect,
}: {
  displayedInstallations: IInstallation[];
  selectedInstallations: string[];
  onRowSelect(id: string): void;
}) => (
  <InstallationListWrapper>
    {displayedInstallations.map((item: IInstallation, index: number) => (
      <InstallationRow
        key={item.id}
        index={index}
        lastIndex={displayedInstallations.length - 1}
        name={item.name}
        isChecked={selectedInstallations.includes(item.id)}
        toggleCheckboxChange={() => onRowSelect(item.id)}
      />
    ))}
  </InstallationListWrapper>
);

export const InstallationSelectionDialog = ({
  title,
  buttonTitle,
  extraInfo,
  onCancel,
  isDialogOpen,
  onSubmit,
  installations,
  maxNumberOfInstallations,
  initialDisplayedInstallations,
  initialSelectedInstallationIds,
}: {
  title: string;
  buttonTitle: string;
  maxNumberOfInstallations?: number;
  initialSelectedInstallationIds: string[];
  initialDisplayedInstallations: IInstallation[];
  isDialogOpen: boolean;
  extraInfo?: React.ReactNode;
  installations?: IInstallation[];
  onCancel: () => void;
  onSubmit: (selectedInstallations: string[]) => void;
}) => {
  const [selectedInstallations, setSelectedInstallations] = useState<string[]>([]);
  const [displayedInstallations, setDisplayedInstallations] = useState<IInstallation[]>([]);
  const [isSelectAllRowsChecked, setIsSelectAllRowsChecked] = useState(false);



  useEffect(() => {
    setSelectedInstallations(initialSelectedInstallationIds);
  }, [initialSelectedInstallationIds, isDialogOpen]);

  useEffect(() => {
    setDisplayedInstallations(initialDisplayedInstallations);
  }, [initialDisplayedInstallations]);

  useEffect(() => {
    setIsSelectAllRowsChecked(
      selectedInstallations.length === displayedInstallations.length && selectedInstallations.length > 0
    );
  }, [selectedInstallations, displayedInstallations]);

  const onRowSelect = (id: string) => {
    if (selectedInstallations.includes(id)) {
      setSelectedInstallations(selectedInstallations.filter((itemId: string) => itemId !== id));
    } else {
      setSelectedInstallations([...selectedInstallations, id]);
    }
  };

  const toggleSelectAllRows = () => {
    let newSelectedInstallations: string[] = [];

    // if 'select all rows' checkbox is not checked
    if (!isSelectAllRowsChecked) {
      newSelectedInstallations = displayedInstallations.map((item: IInstallation) => item.id);
    }

    setIsSelectAllRowsChecked(!isSelectAllRowsChecked);
    setSelectedInstallations(newSelectedInstallations);
  };

  const onSearchChange = (e: any) => {
    const searchValue = e.target.value;

    if (searchValue.trim() === "") {
      setDisplayedInstallations(installations ? installations : []);

      return;
    }

    setDisplayedInstallations(
      installations
        ? installations.filter(
            (item: IInstallation) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
          )
        : []
    );
  };

  return (
    <Dialog
      title={title}
      onCancel={onCancel}
      isDialogOpen={isDialogOpen}
      submit={{
        buttonTitle,
        callback: () => onSubmit(selectedInstallations),
        buttonDisabled: maxNumberOfInstallations ? selectedInstallations.length > maxNumberOfInstallations : false,
      }}
    >
      <MainContentWrapper>
        <InstallationSearchWrapper>
          <CustomStyledTextInput
            type="search"
            placeholder={translateString("searchPlaceholder")}
            onChange={onSearchChange}
          />
          <SearchButton
            onClick={() => onSearchChange}
            buttonStyle={ButtonStyle.Icon}
            icon={<SearchIcon size={IconSize.Small} />}
          />
        </InstallationSearchWrapper>

        <ThemeProvider theme={themes.dark}>
          <InstallationListHeader>
            {installations && (maxNumberOfInstallations === undefined || maxNumberOfInstallations >= installations.length) ? (
              <Checkbox color={colors.primary.white} checked={isSelectAllRowsChecked} onChange={toggleSelectAllRows} />
            ) : null}
            <InstallationListHeaderNameColumn>
              <LocalizedString id="installation" />
            </InstallationListHeaderNameColumn>
          </InstallationListHeader>
        </ThemeProvider>

        <InstallationList
          displayedInstallations={displayedInstallations}
          selectedInstallations={selectedInstallations}
          onRowSelect={onRowSelect}
        />

        {extraInfo}
      </MainContentWrapper>
    </Dialog>
  );
};
