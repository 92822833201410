import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const NewRequestIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>New request</title>
    <path d="M27.5,4H8.5V19h4V8h11V20h12V40h-23V38h-4v6h31V16Zm0,12V9.75L33.75,16Z" />
    <polygon points="8.53 35.46 12.53 35.46 12.53 30.59 17.48 30.59 17.48 26.59 12.53 26.59 12.53 21.46 8.53 21.46 8.53 26.59 3.48 26.59 3.48 30.59 8.53 30.59 8.53 35.46" />
  </IconBase>
);
