import * as React from "react";

import { RouteComponentProps, withRouter } from "react-router-dom";

import { ButtonLinkMenu } from "src/design-system/Button/ButtonLink";

interface IDelayMenuItemProps extends RouteComponentProps {
  delay: number;
  to: string;
  onClick: () => void;
  children?: React.ReactNode;
}

class DelayMenuItem extends React.Component<IDelayMenuItemProps> {
  public delayedOnClick = () => {
    this.props.onClick();

    setTimeout(() => {
      this.props.history.push(this.props.to);
    }, this.props.delay);
  };
  public render() {
    return (
      <ButtonLinkMenu
        onClick={() => {
          this.delayedOnClick();
        }}
      >
        {this.props.children}
      </ButtonLinkMenu>
    );
  }
}

export default withRouter<IDelayMenuItemProps, typeof DelayMenuItem>(DelayMenuItem);
