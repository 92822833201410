// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_pt_BR: ITranslations = {
  pt_BR: {
    "user.addNewUser": "Adicionar usuário",
    "user.allUsersInAccount": "Todos os usuários em sua conta",
    "user.department": "Departamento",
    "user.digest.addAllInstallations": "Adicionar todas as instalações",
    "user.digest.addAllInstallations:energy": "Adicionar todas as usinas de energia",
    "user.digest.addAllInstallations:marine": "Adicionar todas as embarcações",
    "user.digest.period.daily": "Diariamente",
    "user.digest.period.monthly": "Mensalmente",
    "user.digest.period.never": "Nunca",
    "user.digest.period.weekly": "Semanalmente",
    "user.digest.service.reports": "Relatórios",
    "user.digest.service.requests": "Solicitações",
    "user.digest.service.spareParts": "Peças de reposição",
    "user.digest.service.technicalDocuments": "Documentos técnicos",
    "user.digest.validation.noInstallationsSelected": "São necessárias as instalações.",
    "user.digest.validation.noInstallationsSelected:energy": "São necessárias as usinas de energia.",
    "user.digest.validation.noInstallationsSelected:marine": "São necessárias as embarcações.",
    "user.digest.validation.noServicesSelected": "São necessários os serviços.",
    "user.digestDescription.daily": "Diariamente para uma instalação",
    "user.digestDescription.daily.plural": "Diariamente para {numberOfInstallations} instalações",
    "user.digestDescription.daily.plural:energy": "Diariamente para {numberOfInstallations} usinas de energia",
    "user.digestDescription.daily.plural:marine": "Diariamente para {numberOfInstallations} embarcações",
    "user.digestDescription.daily:energy": "Diariamente para uma usina de energia",
    "user.digestDescription.daily:marine": "Diariamente para uma embarcação",
    "user.digestDescription.monthly": "Mensalmente para uma instalação",
    "user.digestDescription.monthly.plural": "Mensalmente para {numberOfInstallations} instalações",
    "user.digestDescription.monthly.plural:energy": "Mensalmente para {numberOfInstallations} usinas de energia",
    "user.digestDescription.monthly.plural:marine": "Mensalmente para {numberOfInstallations} embarcações",
    "user.digestDescription.monthly:energy": "Mensalmente para uma usina de energia",
    "user.digestDescription.monthly:marine": "Mensalmente para uma embarcação",
    "user.digestDescription.never": "Desativado",
    "user.digestDescription.weekly": "Semanalmente aos domingos para uma instalação",
    "user.digestDescription.weekly.plural": "Semanalmente aos domingos para {numberOfInstallations} instalações",
    "user.digestDescription.weekly.plural:energy": "Semanalmente aos domingos para {numberOfInstallations} usinas de energia",
    "user.digestDescription.weekly.plural:marine": "Semanalmente aos domingos para {numberOfInstallations} embarcações",
    "user.digestDescription.weekly:energy": "Semanalmente aos domingos para uma usina de energia",
    "user.digestDescription.weekly:marine": "Semanalmente aos domingos para uma embarcação",
    "user.digestEmail": "E-mail de resumo",
    "user.digestEmailSettings": "Configurações do e-mail de resumo",
    "user.digestSettingsForm.frequencyOfDigest": "Frequência do resumo",
    "user.digestSettingsForm.servicesOfInterest": "Serviços de interesse",
    "user.email": "Endereço de e-mail",
    "user.emailIsRequired": "O e-mail é exigido",
    "user.emailShort": "E-mail",
    "user.firstname": "Primeiro nome",
    "user.firstnameIsRequired": "O primeiro nome é exigido",
    "user.lastname": "Sobrenome",
    "user.lastnameIsRequired": "O sobrenome é exigido",
    "user.name": "Nome",
    "user.notificationSettings": "Configuração de notificações",
    "user.phone": "Telefone",
    "user.phoneNumber": "Número de telefone",
    "user.reasonForRemoval": "Razão para remoção",
    "user.reasonForRemovalIsRequired": "A razão da remoção é exigida",
    "user.removeUser": "Usuário remoto",
    "user.title": "Título",
    "user.userInformation": "Informações do usuário",
    "user.userPassword": "Senha",
  },
};
