import { IAttachment, IJWTUserInfo } from "online-services-types";
import * as React from "react";
import { DocumentDownloadComponent } from "src/components/DownloadDocument";
import { AttahcmentListRowComponent } from "src/components/SupportList/AttahcmentListRowComponent";
import colors from "src/design-system/Tokens/colors";
import { AttachmentSource } from "src/models/attachments";
import { RequestType } from "src/models/warrantyClaim";
import { getAttachments } from "src/redux/actions";
import styled from "styled-components";
import { LoadingSpinner } from "../LoadingSpinner";
import { spinnerSize, spinnerAlign } from "../LoadingSpinner/LoadingSpinner";
import { Container } from "src/design-system/Container";
import { translateString } from "src/util/localization";
import moment from "moment";

export const Filelink = styled(Container)`
  color: ${(props) => props.color || colors.primary.blue};
  text-decoration: ${(props) => (props.color === colors.primary.white ? "underline" : "none")};
`;

interface IAttachmentListProps {
  attachmentSource: string;
  isUploaderBusy: boolean;
  sourceId: string;
  user?: IJWTUserInfo;
  isAttachmentUploadDisabled?: boolean;
  iconColor?: string;
  linkColor?: string;
  showTitle?: boolean;
  handleDelete?: () => void;
  onOpenFile?: () => void;
}

interface IAttachmentListState {
  files: IAttachment[] | null;
}

const getLocalizedDate = (date?: string) => {
  return moment(date).fromNow();
};

export class AttachmentList extends React.Component<IAttachmentListProps, IAttachmentListState> {
  constructor(props: IAttachmentListProps) {
    super(props);

    this.state = {
      files: null,
    };

    this.loadAttachments = this.loadAttachments.bind(this);
  }

  public loadAttachments() {
    this.setState({ files: null }, async () => {
      if (this.props.sourceId) {
        const files = await getAttachments(this.props.attachmentSource as AttachmentSource, this.props.sourceId);
        if (files) {
          this.setState({ files });
        }
      } else {
        this.setState({ files: [] });
      }
    });
  }

  public UNSAFE_componentWillMount() {
    this.loadAttachments();
  }

  public componentDidUpdate(prevProps: IAttachmentListProps) {
    if (!this.props.isUploaderBusy && prevProps.isUploaderBusy) {
      this.loadAttachments();
    }
  }

  public render() {
    return (
      <div>
        {this.state.files === null ? (
          <LoadingSpinner
            size={spinnerSize.sm}
            disableText={true}
            align={spinnerAlign.start}
            dark={this.props.linkColor !== colors.primary.white}
          />
        ) : (
          <Container $margin={[1, 0]}>
            {this.state.files.length
              ? this.state.files.map((file) => (
                  <div key={file.id}>
                    {this.props.attachmentSource === AttachmentSource.ConditionMonitoringReports ? (
                      <DocumentDownloadComponent fileId={file.id} cmReportId={this.props.sourceId}>
                        <Filelink onClick={this.props.onOpenFile}>{file.name}</Filelink>
                      </DocumentDownloadComponent>
                    ) : (
                      <AttahcmentListRowComponent
                        isAttachmentUploadDisabled={this.props.isAttachmentUploadDisabled}
                        loadAttachments={this.loadAttachments}
                        handleDelete={this.props.handleDelete}
                        user={this.props.user}
                        createdBy={file.creatorId}
                        fileId={file.id}
                        fileName={file.name}
                        requestType={this.props.attachmentSource as RequestType}
                        requestId={this.props.sourceId}
                        iconColor={this.props.iconColor}
                        hideTitle={!this.props.showTitle || !file.creator}
                        isInternal={file.isInternal}
                        title={`${
                          file.creatorId === this.props.user?.userId ? translateString("comment.you") : file.creator
                        } ${getLocalizedDate(file.creationDate)}`}
                      >
                        <Filelink color={this.props.linkColor}>{file.name}</Filelink>
                      </AttahcmentListRowComponent>
                    )}
                  </div>
                ))
              : "-"}
          </Container>
        )}
      </div>
    );
  }
}
