// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_es_MX: ITranslations = {
  es_MX: {
    "documents.allDocuments": "Todos los documentos",
    "documents.answer": "Respuesta",
    "documents.appliesTo": "Aplica a",
    "documents.applyFilter": "Aplicar filtro",
    "documents.button.download": "Descargar",
    "documents.button.open": "Abrir",
    "documents.button.print": "Imprimir",
    "documents.clearFilter": "Borrar filtro",
    "documents.documentIssue": "Edición de documento",
    "documents.documentNumber": "Número de documento",
    "documents.documentType": "Tipo de Documento",
    "documents.documentTypes.ASI": "Boletín",
    "documents.documentTypes.CMREPORT": "Reportes de Diagnóstico de activos",
    "documents.documentTypes.FAQ": "Preguntas frecuentes",
    "documents.documentTypes.MAN": "Manual",
    "documents.documentTypes.SPC": "Catálogo de repuestos",
    "documents.documentTypes.SW": "Software",
    "documents.documentTypes.SWR": "Reporte de trabajo",
    "documents.documentTypes.TECHREQUEST": "Soporte técnico",
    "documents.documentTypes.TR": "Reporte técnico",
    "documents.documentTypesLongText.MAN": "Manual de operación y mantenimiento",
    "documents.documentTypesLongText.SPC": "Catálogo de repuestos",
    "documents.documentTypesLongText.couldntFindArticle": "¡No se pudo encontrar el artículo!",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "Error al recuperar el archivo: {error}",
    "documents.emptyDataText": "Para estrechar el alcance de búsqueda, use texto abierto o filtros o los alcances predefinidos a continuación:",
    "documents.files": "Archivos",
    "documents.filterBasedOnDocumentType": "Filtro basado en tipo de documento",
    "documents.forImmediateAction": "Para Atención Inmediata",
    "documents.listHeader": "Documentos ({totalItems})",
    "documents.listHeaderEmptyPattern": "Documentos de interés",
    "documents.listHeaderNoItems": "Documentos",
    "documents.loadMore": "Cargar más",
    "documents.nickName": "Sobrenombre",
    "documents.productReferenceType": "Tipo de referencia de producto",
    "documents.question": "Pregunta",
    "documents.releaseNotes": "Notas de publicación",
    "documents.scopeButton.ASI": "Boletines recientes",
    "documents.scopeButton.MAN": "Manuales recientes",
    "documents.search": "Buscar",
    "documents.sections.attachments": "Archivos adjuntos",
    "documents.sections.chapters": "Capítulos",
    "documents.sections.workcards": "Tarjetas de trabajo",
    "documents.shared.documentTypes.MF_CL_Analysis": "Análisis",
    "documents.shared.documentTypes.MF_CL_Claims": "Reclamos",
    "documents.shared.documentTypes.MF_CL_Failure": "Falla",
    "documents.shared.documentTypes.MF_CL_Inventories": "Inventarios",
    "documents.shared.documentTypes.MF_CL_MaintenancePlans": "Planes de mantenimiento",
    "documents.shared.documentTypes.MF_CL_MeetingMinutes": "Minutas de reunión",
    "documents.shared.documentTypes.MF_CL_Report": "Reportes",
    "documents.shared.documentTypes.MF_CL_SFOCReport": "Reporte SFOC",
    "documents.shared.documentTypes.MF_CL_Training": "Capacitación",
    "documents.shared.from": "Desde",
    "documents.shared.to": "Hasta",
    "documents.sorting.documentNumberAZ": "Número de documento (A-Z)",
    "documents.sorting.documentTypeAZ": "Tipo de documento (A-Z)",
    "documents.sorting.titleAZ": "Título (A-Z)",
    "documents.title": "Título",
    "documents.typeToSearch": "Escribir para buscar...",
    "documents.updateNotificationText.ASI": "El boletín requiere su acción",
    "documents.updateNotificationText.MAN": "Capítulo actualizado",
    "documents.updateNotificationText.TR": "Se publicó el nuevo reporte técnico",
    "documents.updateNotificationText.other": "Se actualizó el documento",
  },
};
