import { FieldProps } from "formik";
import * as React from "react";
import { ErrorComponent } from "src/design-system/Error";
import { StyledFormField, StyledFormInputWrapper } from "src/design-system/Form/Form";
import { getObjectProperty } from "src/util/helpers";
import {
  AttachmentUploaderComponent,
  IAttachmentUploaderComponentProps,
  IUploadedFile,
} from "./AttachmentUploaderComponent";

type AttachmentFieldProps = {
  useVersionIdIfAvailable?: boolean,
};

export class AttachmentField extends React.Component<IAttachmentUploaderComponentProps & AttachmentFieldProps & FieldProps> {
  public render() {
    const { form, field, maxSize, useVersionIdIfAvailable, onBusy } = this.props;
    const fieldTouched = getObjectProperty(form.touched, field.name);
    const fieldErrors = getObjectProperty(form.errors, field.name);
    return (
      <StyledFormField>
        <StyledFormInputWrapper>
          <AttachmentUploaderComponent
            {...this.props}
            maxSize={maxSize}
            onFilesChanged={(files: IUploadedFile[]) => {
              form.setFieldValue(
                field.name,
                files.map((file) => useVersionIdIfAvailable ? file["versionId"] ?? file.id : file.id)
              );
              this.props.onFilesChanged(files);
            }}
            onBusy={onBusy ? (busy: boolean) => {
              onBusy(busy);
              if (!busy) form.setFieldTouched(field.name, false);
            } : undefined}
          />
        </StyledFormInputWrapper>
        {fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
      </StyledFormField>
    );
  }
}
