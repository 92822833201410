// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_es_MX: ITranslations = {
  es_MX: {
    "contracts.attachmentDocumentTypes": "Documentos ({types})",
    "contracts.attachmentImageTypes": "Imágenes ({types})",
    "contracts.attachmentsMessage": "Puede cargar los siguientes tipos de archivos adjuntos con un tamaño máximo de archivo de {maxSize}.",
    "contracts.cbmExpertName": "Diagnóstico de activos",
    "contracts.contact": "Contacto",
    "contracts.contactInformation": "Información del contacto",
    "contracts.contactPerson": "Persona de contacto",
    "contracts.contractManager": "Gerente de contrato",
    "contracts.contractNumber": "Número de contrato",
    "contracts.contractType": "Tipo",
    "contracts.equipmentContracts": "Acuerdos de producto",
    "contracts.expertiseCenter": "Centro de especialidad",
    "contracts.expiry": "Fecha de vencimiento",
    "contracts.installationContracts": "Acuerdos de instalación",
    "contracts.installationContracts:energy": "Acuerdos de planta de energía",
    "contracts.installationContracts:marine": "Acuerdos de embarcación",
    "contracts.installationsInScope": "Instalaciones en alcance",
    "contracts.installationsInScope:energy": "Plantas de energía en alcance",
    "contracts.installationsInScope:marine": "Embarcaciones en alcance",
    "contracts.invalidContract": "Acuerdo no válido",
    "contracts.maintenancePlannerName": "Planeación de mantenimiento",
    "contracts.noContactPerson": "No hay persona de contacto",
    "contracts.noEquipmentContractsAvailable": "No hay acuerdos de producto disponibles",
    "contracts.noInstallationContractsAvailable": "No hay acuerdos de instalación disponibles",
    "contracts.noInstallationContractsAvailable:energy": "No hay acuerdos de plantas de energía disponibles",
    "contracts.noInstallationContractsAvailable:marine": "No hay acuerdos de embarcación disponibles",
    "contracts.productNumbers": "Números de producto",
    "contracts.productReferenceTypes": "Tipos de referencia de producto",
    "contracts.remoteSupporterName": "Partidario remoto",
    "contracts.startDate": "Fecha de inicio",
    "contracts.technicalExperts": "Expertos técnicos",
    "contracts.validation.messageIsRequired": "Se requiere mensaje",
    "contracts.validation.subjectIsRequired": "Se requiere asunto",
  },
};
