import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const ScaleUpIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Scale Up</title>
    <path d="M7,7V41H41V7ZM37,37H11V11H37Z"/><polygon points="21 31 18.41 31 26.55 22.86 27.63 21.79 31 18.41 31 21 33 21 33 15 27 15 27 17 29.59 17 26.21 20.38 25.14 21.45 17 29.59 17 27 15 27 15 33 21 33 21 31"/>
  </IconBase>
);

