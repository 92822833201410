import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const InvisibleIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Invisible</title>
    <path d="M16.9,14.56A17.21,17.21,0,0,1,24.1,13,16.7,16.7,0,0,1,40,24a16.49,16.49,0,0,1-2.19,4.13l3.36,2.18A20.5,20.5,0,0,0,44,24.6l.18-.6L44,23.4A20.67,20.67,0,0,0,24.1,9a21.08,21.08,0,0,0-11,3.08Z" />
    <path d="M21.65,17.64A5,5,0,0,1,29.1,22c0,.15,0,.31,0,.46l3.58,2.32A9.14,9.14,0,0,0,33.1,22a9,9,0,0,0-15-6.68Z" />
    <path d="M4.81,11.47h0L2.63,14.83,7,17.68A20.26,20.26,0,0,0,4.19,23.4L4,24l.19.6A20.65,20.65,0,0,0,24.1,39a21.17,21.17,0,0,0,11-3.07l6.08,4,2.18-3.35ZM24.1,35A16.69,16.69,0,0,1,8.21,24a16.46,16.46,0,0,1,2.18-4.13L15.15,23a9,9,0,0,0,11.71,7.6l4.43,2.88A17.32,17.32,0,0,1,24.1,35Z" />
  </IconBase>
);

