// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_en_US: ITranslations = {
  en_US: {
    "documents.allDocuments": "All documents",
    "documents.answer": "Answer",
    "documents.appliesTo": "Applies to",
    "documents.applyFilter": "Apply filter",
    "documents.button.download": "Download",
    "documents.button.downloadAllFiles": "Download all files",
    "documents.button.open": "Open",
    "documents.button.print": "Print",
    "documents.chapterIsOutdated": "Some instructions in this chapter have been superseded by revised instructions in service bulletin(s)",
    "documents.clearFilter": "Clear filter",
    "documents.documentIssue": "Document issue",
    "documents.documentNumber": "Document number",
    "documents.documentType": "Document type",
    "documents.documentTypes.ASI": "Bulletins",
    "documents.documentTypes.CD": "Certificates & Declarations",
    "documents.documentTypes.CMREPORT": "Maintenance and Condition Report",
    "documents.documentTypes.DD": "Design Documents",
    "documents.documentTypes.DES": "Descriptions",
    "documents.documentTypes.DWG": "Diagrams & Drawings",
    "documents.documentTypes.FAQ": "FAQ",
    "documents.documentTypes.II": "Installation Instructions",
    "documents.documentTypes.LIS": "Lists",
    "documents.documentTypes.MAN": "Manuals",
    "documents.documentTypes.REP": "Reports",
    "documents.documentTypes.SPC": "Spare Parts Catalogues",
    "documents.documentTypes.SPE": "Specifications",
    "documents.documentTypes.SW": "Software",
    "documents.documentTypes.SWR": "Work report",
    "documents.documentTypes.TECHREQUEST": "Technical Support",
    "documents.documentTypes.TR": "Technical Report",
    "documents.documentTypes.TS": "Technical Support",
    "documents.documentTypes.TestReport": "Test Report (FAT, HAT, SAT, ...)",
    "documents.documentTypes.allDocuments": "All documents",
    "documents.documentTypes.brochures": "Brochures",
    "documents.documentTypes.cadDrawings": "Layout drawings",
    "documents.documentTypes.otherDocuments": "Other documents",
    "documents.documentTypes.productGuides": "Product guides",
    "documents.documentTypes.technicalDataSheets": "Technical data sheets",
    "documents.documentTypesLongText.MAN": "Operation and maintenance manual",
    "documents.documentTypesLongText.SPC": "Spare Parts Catalogue",
    "documents.documentTypesLongText.couldntFindArticle": "Couldn't find article!",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "Error while fetching article: {error}",
    "documents.downloadPrintFile": "Download print file",
    "documents.emptyDataText": "To narrow down search scope use open text or filters or predefined scopes below:",
    "documents.files": "Files",
    "documents.filter.date": "Date",
    "documents.filterBasedOnDocumentType": "Filter based on document type",
    "documents.forImmediateAction": "For Immediate Attention",
    "documents.listHeader": "Documents ({totalItems})",
    "documents.listHeaderEmptyPattern": "Documents of interest",
    "documents.listHeaderNoItems": "Documents",
    "documents.loadMore": "Load more",
    "documents.nickName": "Nickname",
    "documents.productReferenceType": "Product reference type",
    "documents.question": "Question",
    "documents.releaseNotes": "Release notes",
    "documents.requestForTechnicalSupport": "Request for Technical Support",
    "documents.resetDate": "Reset date",
    "documents.resetRange": "Reset range selection",
    "documents.scopeButton.ASI": "Recent bulletins",
    "documents.scopeButton.MAN": "Recent manuals",
    "documents.search": "Search",
    "documents.sections.attachments": "Attachments",
    "documents.sections.chapters": "Chapters",
    "documents.sections.documents": "Documents",
    "documents.sections.workcards": "Work cards",
    "documents.select": "Select...",
    "documents.shared.documentTypes.MF_CL_Analysis": "Analysis",
    "documents.shared.documentTypes.MF_CL_Claims": "Claims",
    "documents.shared.documentTypes.MF_CL_Failure": "Failure",
    "documents.shared.documentTypes.MF_CL_Inventories": "Inventories",
    "documents.shared.documentTypes.MF_CL_MaintenancePlans": "Maintenance plans",
    "documents.shared.documentTypes.MF_CL_MeetingMinutes": "Meeting minutes",
    "documents.shared.documentTypes.MF_CL_Report": "Reports",
    "documents.shared.documentTypes.MF_CL_SFOCReport": "SFOC report",
    "documents.shared.documentTypes.MF_CL_Training": "Training",
    "documents.shared.from": "From",
    "documents.shared.to": "To",
    "documents.sorting.documentNumberAZ": "Document number (A-Z)",
    "documents.sorting.documentTypeAZ": "Document type (A-Z)",
    "documents.sorting.titleAZ": "Title (A-Z)",
    "documents.subType": "Sub type",
    "documents.title": "Title",
    "documents.typeToSearch": "Type to search...",
    "documents.updateNotificationText.ASI": "Bulletin requires your attention",
    "documents.updateNotificationText.MAN": "Chapter updated",
    "documents.updateNotificationText.TR": "New technical report has been published",
    "documents.updateNotificationText.other": "Document has been updated",
    "documents.viewListOfDocuments": "View list of documents",
  },
};
