import { IInfoJWTData } from "online-services-types";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { fetchNotifications, clearNotifications } from "src/models/notifications";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import { setApplicationContext, setPartsBlockingStatus } from "src/redux/applicationContext";
import {
  decrementOnboardingStep,
  incrementOnboardingStep,
  pauseOnboarding,
  resumeOnboarding,
  startOnboarding,
  stopOnboarding,
} from "src/redux/onboarding";
import { setSelectedInstallation, setUserInfo } from "src/redux/user";
import { AppComponent, IAppDispatchProps, IReduxStateProps } from "./AppComponent";
import { selectCurrentAccount } from "src/redux/actions";

const mapStateToProps = (state: IAppState): IReduxStateProps => ({
  equipments: state.equipments.data || [],
  tokenExpirationDate: state.auth.tokenExpirationDate,
  isSessionExpired: state.auth.isSessionExpired,
  selectedInstallationName: state.userInfo.selectedInstallationName || "",
  selectedInstallationId: state.userInfo.selectedInstallationId || "",
  services: state.applicationContext.services || [],
  readonly: state.applicationContext.readonly || false,
  punchOut: state.applicationContext.punchOut || false,
  currentUserEmail: state.userInfo.email,
  isOnboardingFinished: state.onboarding.isOnboardingFinished,
  isOnboardingRunning: state.onboarding.isOnboardingRunning,
  onboardingStep: state.onboarding.step,
  onboardingSteps: state.onboarding.currentSteps,
  onboardingComponent: state.onboarding.component,
  userInfo: state.userInfo,
  accounts: state.accounts.data || [],
  isEnergyConsultant: state.userInfo.isDesigner && state.userInfo.mainSector === "Energy",
  isDesigner: state.userInfo.isDesigner && state.userInfo.mainSector === "Marine",
  punchOutContact: state.userInfo.punchOutContact,
  isQuantipartsDistributor: state.userInfo.isQPDistributor,
  isQPDistributorViewDisabled: state.userInfo.isQPDistributorViewDisabled,
  isShipyard: state.userInfo.isShipyard && state.userInfo.mainSector === "Marine",
  news: state.newsnew.data || [],
});

const mapDispatchToProps = (dispatch: Dispatch): IAppDispatchProps =>
  bindActionCreators(
    {
      selectCurrentAccount,
      pauseOnboarding,
      resumeOnboarding,
      incrementOnboardingStep,
      decrementOnboardingStep,
      startOnboarding,
      stopOnboarding,
      fetchNotifications,
      clearNotifications,
      setSelectedInstallation,
      setUserInfo,
      getNews: APIResourceState.newsnew.get,
      getEquipments: APIResourceState.equipments.get,
      setApplicationContext: (contextData: IInfoJWTData) => setApplicationContext(contextData),
      setPartsBlockingStatus: (blocked: boolean) => setPartsBlockingStatus(blocked),
      dispatch: dispatch as any,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
