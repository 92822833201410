// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const trainings_en_US: ITranslations = {
  en_US: {
    "trainings.date": "Date",
    "trainings.description": "Description",
    "trainings.filterTrainings": "Filter trainings",
    "trainings.invalid.email": "Please enter valid email",
    "trainings.invalid.phone": "Please enter valid phone number",
    "trainings.location": "Location",
    "trainings.noTrainingsFound": "No trainings found.",
    "trainings.onlineCourse": "Online course",
    "trainings.participant": "Participant",
    "trainings.participant.add": "Add colleague",
    "trainings.participant.email": "Email",
    "trainings.participant.info": "Participant Info",
    "trainings.participant.name": "Name",
    "trainings.participant.other": "Other information (optional)",
    "trainings.participant.phone": "Phone number",
    "trainings.pdf": "Course specification leaflet",
    "trainings.price": "Price",
    "trainings.prt": "Product Reference Types",
    "trainings.register": "Register",
    "trainings.register.colleauge": "I register my colleague",
    "trainings.register.myself": "I register myself",
    "trainings.required": "This field is required",
    "trainings.send": "Send",
    "trainings.status.sending": "Sending Participants...",
    "trainings.success.message": "Wärtsilä Land & Sea Academy will contact you",
    "trainings.success.title": "Your registration was successful!",
    "trainings.title": "Title",
  },
};
