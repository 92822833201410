import * as React from "react";
import { TrafficLightWithLabel } from ".";
import { translateString } from "src/util/localization";

const trafficLights: Array<{ translation: string; evaluationsSuffix: string }> = [
  {
    translation: "fluid.uptime.engineAvailability",
    evaluationsSuffix: "engineAvailability",
  },
  {
    translation: "fluid.coolingWater",
    evaluationsSuffix: "coolingWater",
  },
  {
    translation: "fluid.lubeOil",
    evaluationsSuffix: "lubeOil",
  },
  {
    translation: "fluid.fuel",
    evaluationsSuffix: "fuel",
  },
];

export const getTrafficLightSet = (itemId: string, evaluationsGrouped: { [key: string]: string }) =>
  trafficLights
    .map((trafficLight) =>
      evaluationsGrouped[`${itemId}_${trafficLight.evaluationsSuffix}`] ? (
        <TrafficLightWithLabel
          key={trafficLight.evaluationsSuffix}
          label={translateString(trafficLight.translation)}
          color={evaluationsGrouped[`${itemId}_${trafficLight.evaluationsSuffix}`]}
        />
      ) : null
    )
    .filter((trafficLight) => trafficLight !== null);
