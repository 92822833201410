import { Container } from "src/design-system/Container";
import styled from "styled-components";

interface IFlexContainerProps {
  $row?: boolean;
  $column?: boolean;
  $wrap?: boolean;
  $centered?: boolean;
  $justified?: boolean;
  $justifiedEnd?: boolean;
  $spaceBetween?: boolean;
  $spaceEvenly?: boolean;
  $stretchHeight?: boolean;
  $stretchWidth?: boolean;
  $noShrink?: boolean;
  $border?: string;
  $alignedStart?: boolean;
  $alignedEnd?: boolean;
  $gridGap?: string;
}

export const FlexContainer = styled(Container)`
  display: flex;
  ${(props: IFlexContainerProps) => props.$row && `flex-direction: row;`};
  ${(props: IFlexContainerProps) => props.$column && `flex-direction: column;`};
  ${(props: IFlexContainerProps) => props.$wrap && `flex-wrap: wrap;`};
  ${(props: IFlexContainerProps) => props.$stretchHeight && `height: 100%;`};
  ${(props: IFlexContainerProps) => props.$stretchWidth && `width: 100%;`};
  ${(props: IFlexContainerProps) => props.$noShrink && `flex-shrink: 0;`};
  ${(props: IFlexContainerProps) =>
    props.$spaceBetween &&
    `
    justify-content: space-between;
  `};
  ${(props: IFlexContainerProps) =>
    props.$spaceEvenly &&
    `
    justify-content: space-evenly;
  `};
  ${(props: IFlexContainerProps) =>
    props.$centered &&
    `
    align-items: center;
  `};
  ${(props: IFlexContainerProps) =>
    props.$justified &&
    `
    justify-content: center;
  `};
  ${(props: IFlexContainerProps) =>
    props.$alignedStart &&
    `
    align-items: start;
  `};
  ${(props: IFlexContainerProps) =>
    props.$alignedEnd &&
    `
    align-items: end;
  `};
  ${(props: IFlexContainerProps) =>
    props.$justifiedEnd &&
    `
    justify-content: flex-end;
  `};
  ${(props: IFlexContainerProps) =>
    props.$border &&
    `
    border: ${props.$border};
  `};
  ${(props: IFlexContainerProps) => 
    props.$gridGap &&
          `
    grid-gap: ${props.$gridGap};
  `};
`;
