import { translateString } from "src/util/localization";

export enum Rating {
  Satisfied = "Satisfied",
  PartlySatisfied = "PartlySatisfied",
  NotSatisfied = "NotSatisfied",
}

export enum RatingReason {
  HealthIndices = "Health indices",
  LoadAndRunningHoursStatistics = "Load and running hours statistics",
  DiagnosticResultsOfIndividualSignals = "Diagnostic results of individual signals",
  TimelinessOfReport = "Timeliness of report",
  AmountOfContentInTheReport = "Amount of content in the report",
  ClarityAndOverview = "Clarity and overview",
}

export function getRatingNames() {
  return {
    [Rating.Satisfied]: translateString("request.rating.satisfied"),
    [Rating.PartlySatisfied]: translateString("request.rating.partlySatisfied"),
    [Rating.NotSatisfied]: translateString("request.rating.notSatisfied"),
  };
}

export function getRatingReasons() {
  return {
    [RatingReason.HealthIndices]: translateString("request.rating.healthIndices"),
    [RatingReason.LoadAndRunningHoursStatistics]: translateString("request.rating.loadAndRunningHoursStatistics"),
    [RatingReason.DiagnosticResultsOfIndividualSignals]: translateString(
      "request.rating.diagnosticResultsOfIndividualSignals"
    ),
    [RatingReason.TimelinessOfReport]: translateString("request.rating.timelinessOfReport"),
    [RatingReason.AmountOfContentInTheReport]: translateString("request.rating.amountOfContentInTheReport"),
    [RatingReason.ClarityAndOverview]: translateString("request.rating.clarityAndOverview"),
  };
}
