import download from "downloadjs";
import { Field, Form, Formik } from "formik";
import * as React from "react";

import { LocalizedString } from "src/components/Localization";
import { IAppClaimComment } from "src/components/RequestComments/RequestCommentsComponent";
import { Button, ButtonStyle } from "src/design-system/Button";
import { FlexContainer } from "src/design-system/Container";
import { TextArea } from "src/design-system/TextArea";
import { breakpoints, whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { Details } from "src/design-system/Tokens/typography";
import { IconSize, TrashIcon, UserIcon } from "src/icons";
import { getFileTransferStatus, startFileTransfer } from "src/redux/actions";
import { getPathWithParams, getRoutes, redirectBrowserToURL } from "src/routes";
import { RouteType } from "src/routeType";
import { translateString } from "src/util/localization";
import { validateForm, validators } from "src/util/validators";
import styled from "styled-components";

export enum CommentVerb {
  Post,
  Reply,
}

const mobileMargin = "10px";
const desktopMargin = "60px";

enum TriangleDirection {
  Left = "Left",
  Right = "Right",
}

interface ITitleProps {
  isSelf: boolean;
}

const Title = styled(Details)`
  color: ${colors.primary.black};
  margin-top: 2px;
  margin-left: ${mobileMargin};
  margin-right: 12px;
  ${whenTabletOrLarger(`
    margin-left: ${desktopMargin};
  `)};
  margin-bottom: 8px;
  ${(p: ITitleProps) => (p.isSelf ? `text-align: right;` : "text-align: left;")}
`;

interface ICommentContainerProps {
  isSelf: boolean;
  isInternal: boolean;
  transient: boolean;
  isSingleRow: boolean;
}

interface IColoredBorderProps {
  isInternal: boolean;
  direction: TriangleDirection;
  isSelf: boolean;
  isSingleRow: boolean;
}

const getBorderColor = (p: IColoredBorderProps, colorIfNotInternal: string) =>
  p.isInternal && p.direction === TriangleDirection.Left ? colors.primary.orange : colorIfNotInternal;

const ColoredBorder = styled.div`
  ${(p: IColoredBorderProps) =>
    `background-color: ${getBorderColor(p, p.isSingleRow ? colors.secondary.gray10 : colors.secondary.blue100)};`}
  ${(p: IColoredBorderProps) =>
    (p.direction === TriangleDirection.Left) !== p.isSelf ? "width: .75em;" : "width: 1px;"};
`;

const CommentContainer = styled.div`
  border-color: ${(p: ICommentContainerProps) => (p.isSingleRow ? colors.secondary.gray10 : colors.secondary.blue100)};
  border-style: solid;

  ${(p: ICommentContainerProps) => (p.transient ? `color: ${colors.secondary.gray40}` : "")}
  ${(p: ICommentContainerProps) =>
    p.isSelf
      ? `background-color: ${colors.secondary.gray10}; margin-left: 54px; border-width: 1px 0 1px 1px;`
      : `margin-right: 18px; border-width: 1px 1px 1px 0;`}
  flex: 1;
`;

interface ITextContainerProps {
  isSelf: boolean;
  isSingleRow: boolean;
}

const TextContainer = styled.div`
  padding: 8px 16px;
  ${(p: ITextContainerProps) => (p.isSelf ? "padding-left: 24px;" : "")};

  & > * {
    margin: auto 0;
    ${(p: ITextContainerProps) =>
      p.isSingleRow ? "white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" : ""}
  }
`;

interface ITriangleProps {
  direction: TriangleDirection;
  isInternal: boolean;
  isSelf: boolean;
}

const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  ${(p: ITriangleProps) =>
    p.direction === TriangleDirection.Left ? `border-width: 5px 9px 5px 0;` : `border-width: 5px 0 5px 9px;`};
  ${(p: ITriangleProps) =>
    p.direction === TriangleDirection.Left
      ? `border-color: transparent ${
          p.isInternal ? colors.primary.orange : colors.secondary.blue100
        } transparent transparent;`
      : `border-color: transparent transparent transparent ${
          p.isInternal && !p.isSelf ? colors.primary.orange : colors.secondary.blue100
        };`};
  margin-top: 13px;
`;

interface ISingleRow {
  isSingleRow: boolean;
}

const CommentWrapper = styled.div`
  display: flex;
`;

const Avatar = styled.div`
  display: none;

  ${whenTabletOrLarger(`
    display: block;
    `)}

  width: 32px;
  height: 32px;
  border-radius: 50%;
  ${(p: ISingleRow) => (!p.isSingleRow ? `border: 1px solid ${colors.secondary.blue100};` : "")};
  ${(p: ISingleRow) => (p.isSingleRow ? "margin: 0 8px 0 4px;" : "margin: 0 8px;")}
  overflow: hidden;

  & > * {
    margin: auto auto;
    display: block;
    width: 32px;
    height: 32px;
  }
`;

const Author = styled.div`
  display: flex;
  justify-content: right;
`;

const RowWrapper = styled.div<{ isSingleRow: boolean, isSelf: boolean }>`
  width: 100%;
  max-width: 620px;
  ${({ isSingleRow }) => (!isSingleRow ? "margin-top: 16px;" : "")};
  ${({ isSelf }) => (isSelf ? "align-self: flex-end" : "")};
`;

export const CommentTextFieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const CommentTextFieldElement = styled.div`
  & > * {
    margin-right: 8px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    width: 250px;
  }
`;

const PreWrapText = styled.div`
  white-space: pre-line;
  word-break: break-word;
`;
const ButtonWithMargin = styled(Button)`
  margin-left: 15px;
`;

const FilePreviewContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileLink = styled.button`
  display: flex;
  flex-direction: column;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: ${colors.primary.blue};
  text-align: left;
  
  > img {
    max-height: 200px;
    max-width: 200px;
  }
`;

const IconWrapper = styled.div`
  align-self: end;
  cursor: pointer;
  
  svg {
    fill: ${colors.primary.black};
  }
`;

const IMAGE_FILE_EXTENSIONS: string[] = [".jpg", ".jpeg", ".png"];
const POLLING_INTERVAL_MS = 1000;
const MAX_RETRIES = 10;
const FilePreview = ({ fileName, fileId, allowDelete, onDeleteFile }: { fileName: string, fileId?: string, allowDelete: boolean, onDeleteFile?: () => void }) => {
  const [imageData, setImageData] = React.useState<string>("");

  const fetchFileBlob = async (url: string) => {
    try {
      const dataResponse = await fetch(url, { method: "GET" });
      if (dataResponse.ok) {
        return await dataResponse.blob();
      }
      return null;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const pollDocumentStatus = async (transferId: string, dataUrl: string, retries: number, onload: (e: ProgressEvent<FileReader>) => any) => {
    const status = await getFileTransferStatus(transferId, { disableToast: true });
    if (status === "success") {
      if (dataUrl) {
        try {
          const blob = await fetchFileBlob(dataUrl);
          if (blob) {
            const reader = new FileReader();
            reader.onload = onload;
            reader.readAsDataURL(blob);
          }
        } catch (e) {
          console.error(e);
        }
      }
    } else if (status === "busy") {
      if (retries < MAX_RETRIES) {
        setPollTimeout(transferId, dataUrl, retries + 1, onload);
      }
    }
  };

  const setPollTimeout = (transferId: string, url: string, retries: number, onload: (e: ProgressEvent<FileReader>) => any) => {
    setTimeout(() => pollDocumentStatus(transferId, url, retries, onload), POLLING_INTERVAL_MS);
  };

  const getImagePreview = async () => {
    if (fileId && IMAGE_FILE_EXTENSIONS.some((ext) => fileName.toLowerCase().endsWith(ext))) {
      const downloadLink = await startFileTransfer({ fileId, disableToast: true });
      if (downloadLink?.url) {
        setPollTimeout(downloadLink.id, downloadLink.url, 0, (e) => setImageData(e.target?.result as string));
      }
    }
  };

  React.useEffect(() => {
    setImageData("");
    getImagePreview();
  }, [fileId]);

  const downloadFile = async () => {
    if (imageData) {
      download(imageData, fileName);
      return;
    }
    if (fileId) {
      const path = getPathWithParams(getRoutes(RouteType.Standalone).Download, { fileId });
      redirectBrowserToURL(path, true);
    }
  };

  return (
    <FilePreviewContainer>
      <FileLink onClick={downloadFile}>
        {imageData && <img src={imageData} alt={fileName} />}
        <PreWrapText>
          {fileName}
        </PreWrapText>
      </FileLink>
      {allowDelete && onDeleteFile && (
        <IconWrapper onClick={onDeleteFile}>
          <TrashIcon size={IconSize.Small} />
        </IconWrapper>
      )}
    </FilePreviewContainer>
  );
};

interface ICommentRowProps {
  title: string;
  text: string;
  transient: boolean;
  isSelf: boolean;
  isInternal: boolean;
  hideTitle?: boolean;
  isSingleRow?: boolean;
  isStatus?: boolean;
  fileId?: string;
  onDeleteFile?: () => void;
}

const TitleRow = (props: { hideTitle: boolean; isSelf: boolean; isInternal: boolean; title: string }) =>
  !props.hideTitle ? (
    <Title isSelf={props.isSelf}>{(props.isInternal && !props.title.includes("Wärtsilä")) ? `Wärtsilä / ${props.title}` : props.title}</Title>
  ) : null;

export const CommentRow = ({
  title,
  text,
  transient,
  isSelf,
  isInternal,
  hideTitle = false,
  isSingleRow = false,
  isStatus = false,
  fileId,
  onDeleteFile,
}: ICommentRowProps) => (
  <RowWrapper isSingleRow={isSingleRow} isSelf={isSelf}>
    <CommentWrapper>
      {!isSelf && (
        <>
          <Author>
            <Avatar isSingleRow={isSingleRow}>
              <UserIcon color={colors.secondary.blue100} size={IconSize.Medium} />
            </Avatar>
          </Author>
          <Triangle isInternal={isInternal} isSelf={isSelf} direction={TriangleDirection.Left} />
          <ColoredBorder
            isInternal={isInternal}
            isSelf={isSelf}
            direction={TriangleDirection.Left}
            isSingleRow={isSingleRow}
          />
        </>
      )}

      <CommentContainer transient={transient} isSelf={isSelf} isInternal={isInternal} isSingleRow={isSingleRow}>
        <TextContainer isSelf={isSelf} isSingleRow={isSingleRow}>
          {(fileId) ? (
            <FilePreview fileName={text} fileId={fileId} onDeleteFile={onDeleteFile} allowDelete={isSelf} />
          ) : (
            <PreWrapText>{isStatus ? <em>{text}</em> : text}</PreWrapText>
          )}
        </TextContainer>
      </CommentContainer>
      {isSelf && !isSingleRow && (
        <>
          <ColoredBorder
            isInternal={isInternal}
            isSelf={isSelf}
            direction={TriangleDirection.Right}
            isSingleRow={isSingleRow}
          />
          <Author>
            <Triangle isInternal={isInternal} isSelf={isSelf} direction={TriangleDirection.Right} />
          </Author>
        </>
      )}
    </CommentWrapper>
    <TitleRow hideTitle={hideTitle} isSelf={isSelf} isInternal={isInternal} title={title} />
  </RowWrapper>
);

interface ICommentsComponentProps {
  isCommentingDisabled: boolean;
  requestCanBeReopened: boolean;
  currentUserId: string;
  comments: IAppClaimComment[];
  verb?: CommentVerb;
  postComment(comment: string): void;
  onCancel?(): void;
}

export class CommentsComponent extends React.Component<ICommentsComponentProps> {
  public onFormSubmit = (values: any, { resetForm }: { resetForm(): void }) => {
    this.props.postComment(values.comment);
    resetForm();
  };
  public render() {
    const postButtonLabel = this.props.verb === CommentVerb.Reply ? "comment.replyComment" : "comment.postComment";
    const textAreaPlaceholder =
      this.props.verb === CommentVerb.Reply ? "comment.writeYourReply" : "comment.writeYourComment";

    return (
      <div>
        {this.props.isCommentingDisabled ? (
          this.props.requestCanBeReopened ? (
            translateString("request.ifYouWantToCommentText")
          ) : (
            ""
          )
        ) : (
          <Formik
            initialValues={{ comment: "" }}
            validate={(values: any) =>
              validateForm(values, {
                comment: [{ valErr: translateString("comment.commentIsRequired"), valFn: validators.isRequired }],
              })
            }
            onSubmit={this.onFormSubmit}
          >
            <Form>
              <CommentTextFieldWrapper>
                <CommentTextFieldElement>
                  <Field
                    name="comment"
                    autoHeight={true}
                    rows={1}
                    placeholder={translateString(textAreaPlaceholder)}
                    component={TextArea}
                  />
                </CommentTextFieldElement>
                <CommentTextFieldElement>
                  <FlexContainer>
                    <Button type="submit">
                      <LocalizedString id={postButtonLabel} />
                    </Button>
                    {!!this.props.onCancel && (
                      <ButtonWithMargin buttonStyle={ButtonStyle.Secondary} onClick={this.props.onCancel}>
                        <LocalizedString id="cancel" />
                      </ButtonWithMargin>
                    )}
                  </FlexContainer>
                </CommentTextFieldElement>
              </CommentTextFieldWrapper>
            </Form>
          </Formik>
        )}

        {this.props.comments.map((comment) => (
          <CommentRow
            key={comment.id}
            isInternal={comment.isInternal}
            title={`${
              comment.authorId === this.props.currentUserId ? translateString("comment.you") : comment.author
            } ${comment.createdDate}`}
            text={comment.message}
            transient={comment.isTransient}
            isSelf={comment.authorId === this.props.currentUserId}
          />
        ))}
      </div>
    );
  }
}
