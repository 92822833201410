import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { removeDiscardedNotifications } from "src/models/notifications";
import { IAppState } from "src/redux";
import { setUserInfo } from "src/redux/user";
import { HeaderComponent, IHeaderDispatchProps, IHeaderOwnProps, IHeaderStateProps } from "./HeaderComponent";

const mapStateToProps = (state: IAppState): IHeaderStateProps => ({
  externalTitle: state.header.externalTitle,
  userInfo: state.userInfo,
  notifications: removeDiscardedNotifications(state.notifications.data || []),
  isEnergyConsultant: state.userInfo.isDesigner && state.userInfo.mainSector === "Energy",
  isDesigner: state.userInfo.isDesigner && state.userInfo.mainSector === "Marine",
  isShipyard: state.userInfo.isShipyard && state.userInfo.mainSector === "Marine",
  isQuantipartsDistributor: state.userInfo.isQPDistributor && !state.userInfo.isQPDistributorViewDisabled,
});

const mapDispatchToProps = (dispatch: Dispatch): IHeaderDispatchProps =>
  bindActionCreators(
    {
      setUserInfo,
    },
    dispatch
  );

export const HeaderContainer = connect<IHeaderStateProps, IHeaderDispatchProps, IHeaderOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
