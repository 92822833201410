import * as React from "react";

import { IconSize, PdfIcon } from "src/icons";
import { Loader, spinnerSize } from "../../components/LoadingSpinner/LoadingSpinner";

import { AnalyticsEvent } from "src/components/AnalyticsEvent";
import { FlexContainer } from "src/design-system/Container";
import { analyticsDocumentTypes } from "src/components/AnalyticsEvent/AnalyticsEventContainer";
import { analyticsEvents } from "src/events";
import colors from "src/design-system/Tokens/colors";
import { displayError } from "src/util/error";
import { get } from "lodash";
import { getInvoiceAttachmentUrl } from "src/util/invoices";
import styled from "styled-components";

interface ILinkProps {
  type?: string;
  invoiceId?: string;
}

interface ILinkState {
  isDownloading: boolean;
}

const IconWrapper = styled(FlexContainer)`
  height: 50px;
  cursor: pointer;
`;

export class AttachmentIconDownload extends React.Component<ILinkProps, ILinkState> {
  constructor(props: ILinkProps) {
    super(props);

    this.state = {
      isDownloading: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.downloadAttachment = this.downloadAttachment.bind(this);
  }

  public async downloadAttachment() {
    try {
      let downloadUrl;
      if (this.props.invoiceId && this.props.type) {
        downloadUrl = await getInvoiceAttachmentUrl(this.props.invoiceId, this.props.type);
      }
      if (downloadUrl) {
        window.open(downloadUrl, "_blank");
        this.setState({ isDownloading: false });
      }
    } catch (e) {
      displayError(e);
      this.setState({ isDownloading: false });
    }
  }

  public async handleClick() {
    if (!this.state.isDownloading) {
      this.setState({ isDownloading: true });
      await this.downloadAttachment();
    }
  }

  public render() {
    return (
      <AnalyticsEvent
        event={({ account, user }) =>
          analyticsEvents.dcpDownloadDocument({
            dcp_user_role: user.role,
            dcp_user_seniority: user.seniority,

            dcp_account_role: account.role,
            dcp_account_sector: account.sector,
            dcp_account_country: account.customerCountry,
            dcp_account_sales_region: account.salesRegion,
            dcp_account_market_segment: account.marketSegment,
            dcp_account_value_category: account.customerValueCategory,

            dcp_account_id: account.name,
            dcp_document_id: this.props.invoiceId!,
            dcp_document_type: get(analyticsDocumentTypes, this.props.type!, this.props.type!),
          })
        }
      >
        <IconWrapper $centered onClick={this.handleClick}>
          {!this.state.isDownloading ? <PdfIcon size={IconSize.Large} color={colors.primary.black} /> : null}
          {this.state.isDownloading && <Loader dark size={spinnerSize.sm} />}
        </IconWrapper>
      </AnalyticsEvent>
    );
  }
}
