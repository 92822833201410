import { Field } from "formik";
import * as React from "react";
import { FormDialog } from "src/components/Dialog";
import { Modal, ModalSize } from "src/components/Dialog/Modal";
import { FormCheckbox } from "src/design-system/Checkbox";
import { StyledFormField } from "src/design-system/Form/Form";
import { ThemedProps } from "src/design-system/Theme/theme";
import { baseTextStyles, P } from "src/design-system/Tokens/typography";
import { translateString } from "src/util/localization";
import { validateForm } from "src/util/validators";
import styled from "styled-components";
import { LocalizedString } from "../Localization";

export const termsAndConditionsLink =
  "https://wartsila.my.site.com/online/resource/1544007182000/Portal_Online_Services_Terms_and_conditions";
export const privacyStatementLink = "https://www.wartsila.com/legal-privacy/privacy";

interface IEULAComponentProps {
  isOpen: boolean;
  isBlocking: boolean;
  onAgree: () => void;
  onCancel: () => void;
  isConsultant: boolean;
}

interface IEULAForm {
  acceptTermsAndConditions: boolean;
  acceptPrivacyStatement: boolean;
}

const CheckboxWrapper = styled(StyledFormField)`
  margin-top: 24px;
`;

export const ThemedA = styled.a`
  ${baseTextStyles};
  text-decoration: none;
  border-bottom: 1px solid ${(props: ThemedProps) => props.theme.text};
`;

export class EULAComponent extends React.Component<IEULAComponentProps> {
  public render() {
    if (this.props.isBlocking) {
      return (
        <Modal isOpen={true} size={ModalSize.Large} overflowType={"auto"} isTransparentContentBackground={true}>
          <div />
        </Modal>
      );
    }
    return (
      <FormDialog<IEULAForm>
        isDialogOpen={this.props.isOpen}
        onCancel={this.props.onCancel}
        title={translateString("eula.title")}
        submitButtonTitle={translateString("eula.agreeButtonText")}
        cancelButtonTitle={translateString("eula.cancelButtonText")}
        size={ModalSize.Small}
        initialValues={{
          acceptTermsAndConditions: false,
          acceptPrivacyStatement: false,
        }}
        validate={(values) =>
          validateForm(values, {
            acceptTermsAndConditions: [
              { valErr: translateString("eula.validate.acceptTermsAndConditions"), valFn: (value) => !!value },
            ],
            acceptPrivacyStatement: [
              { valErr: translateString("eula.validate.acceptPrivacyStatement"), valFn: (value) => !!value },
            ],
          })
        }
        onSubmit={(values) => {
          this.props.onAgree();
        }}
      >
        <>
          <P>
            <LocalizedString id="eula.caption" />
          </P>
          <CheckboxWrapper>
            <Field
              name="acceptTermsAndConditions"
              label={
                <LocalizedString
                  id="eula.acceptTermsAndConditions"
                  values={{
                    termsAndConditions: (
                      <ThemedA href={termsAndConditionsLink} target="_blank">
                        <LocalizedString id="eula.termsAndConditions" />
                      </ThemedA>
                    ),
                  }}
                />
              }
              component={FormCheckbox}
            />
          </CheckboxWrapper>
          {this.props.isConsultant && (
            <P style={{ fontStyle: "italic" }}>
              <LocalizedString id="energy.EULA" />
            </P>
          )}
          <CheckboxWrapper>
            <Field
              name="acceptPrivacyStatement"
              label={
                <LocalizedString
                  id="eula.acceptPrivacyStatement"
                  values={{
                    privacyStatement: (
                      <ThemedA href={privacyStatementLink} target="_blank">
                        <LocalizedString id="eula.privacyStatement" />
                      </ThemedA>
                    ),
                  }}
                />
              }
              component={FormCheckbox}
            />
          </CheckboxWrapper>
        </>
      </FormDialog>
    );
  }
}
