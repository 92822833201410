// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const eula_pt_BR: ITranslations = {
  pt_BR: {
    "eula.acceptPrivacyStatement": "Eu aceito a {privacyStatement}",
    "eula.acceptTermsAndConditions": "Eu aceito os {termsAndConditions}",
    "eula.agreeButtonText": "Continuar",
    "eula.cancelButtonText": "Cancelar e sair",
    "eula.caption": "Antes de usar o Wärtsilä Online é necessário ler e concordar com os Termos e condições da declaração de privacidade.",
    "eula.privacyStatement": "Política de privacidade",
    "eula.termsAndConditions": "Termos e condições",
    "eula.title": "Aceitar os termos e condições",
    "eula.validate.acceptPrivacyStatement": "É necessário aceitar a Política de privacidade",
    "eula.validate.acceptTermsAndConditions": "É necessário aceitar os Termos e condições",
  },
};
