// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const sfoc_ru: ITranslations = {
  ru: {
    "sfoc.aBank": "Ряд A",
    "sfoc.bBank": "Ряд B",
    "sfoc.error.valueHasToBe24Hourformat": "Время должно быть в 24-часовом формате (ЧЧ:ММ).",
    "sfoc.error.valueHasToBeBetween": "Значение должно быть от {min} до {max}",
    "sfoc.error.valueHasToBeNumeric": "Значение должно быть числовым.",
    "sfoc.error.valueIsRequired": "Требуется значение.",
    "sfoc.field.ISOCorrectedSFOC": "SFOC с корректировкой по ISO",
    "sfoc.field.LTWaterTemperature": "Температура НТ-воды",
    "sfoc.field.absoluteHumidity": "Абсолютная влажность",
    "sfoc.field.activePower": "Активная мощность",
    "sfoc.field.afterTestTakeFuelSample": "После испытания отберите пробу топлива, запишите номер пломбы, измерьте содержание воды с помощью испытательного комплекта",
    "sfoc.field.ambientConditionValuesOnSFOCCabinetSameAsLocalWeatherStation": "Значения параметров окружающей среды в шкафу SFOC идентичны значениям местной метеорологической станции",
    "sfoc.field.averageFiringPressureBar": "Среднее давление сгорания {bank}",
    "sfoc.field.averageFiringPressureBarHeading": "Среднее давление сгорания",
    "sfoc.field.barometricPressure": "Барометрическое давление",
    "sfoc.field.betaFactor": "Бета-фактор",
    "sfoc.field.calculatedISOCorrectedSFOC": "Расчетный SFOC с корректировкой по ISO",
    "sfoc.field.calculatedSFOC": "Расчетный SFOC",
    "sfoc.field.chargeAirPressure": "Давление нагнетаемого воздуха",
    "sfoc.field.chargeAirTemperature": "Температура нагнетаемого воздуха",
    "sfoc.field.checkThatSFOCSystemIsWorkingProperly": "Перед началом испытания убедитесь в надлежащем функционировании системы SFOC",
    "sfoc.field.cleanLeakMeasurementTime": "Время измерения",
    "sfoc.field.consumedFuel": "Расходуемое топливо",
    "sfoc.field.cylinder": "Цилиндр {cylinderNr}",
    "sfoc.field.deltaPOverCAC": "Δp на CAC",
    "sfoc.field.deltaPOverTCAirFilter": "Δp на воздушном фильтре турбонагнетателя",
    "sfoc.field.differentialAutoZeroValue": "Автоматическая установка нулевого значения дифференциала",
    "sfoc.field.engine": "Двигатель",
    "sfoc.field.engineRoomTemperature": "Температура в моторном отделении",
    "sfoc.field.engineRoomVentilationIsOn": "Вентиляция моторного отделения включена",
    "sfoc.field.engineSpeed": "Частота вращения коленвала",
    "sfoc.field.exhaustGasBackPressureAfterTC": "Обратное давление выхлопных газов после турбонагнетателя",
    "sfoc.field.exhaustGasCleaningSystemOff": "Система очистки выхлопных газов (скруббер) отключена",
    "sfoc.field.exhaustGasTemperatureAfterTC": "Температура выхлопных газов после турбонагнетателя",
    "sfoc.field.exhaustGasTemperatureBeforeTC": "Температура выхлопных газов перед турбонагнетателем",
    "sfoc.field.exhaustTemperatureAfterCylinder": "Температура выхлопных газов после цилиндра {bank}",
    "sfoc.field.exhaustTemperatureAfterCylinderHeading": "Температура выхлопных газов после цилиндра",
    "sfoc.field.foSampleSealNumber": "Номер пломбы на пробе жидкого топлива",
    "sfoc.field.fuelCalorificValueLHV": "Теплотворная способность топлива LHV",
    "sfoc.field.fuelFlowInlet": "Поток топлива на впуске",
    "sfoc.field.fuelFlowReturn": "Возвратный поток топлива",
    "sfoc.field.fuelInletFlowRatioToConsumption": "Коэффициент потребляемости потока топлива на впуске",
    "sfoc.field.fuelNetSpecificEnergyLHV": "Полезная энергия сгорания топлива LHV",
    "sfoc.field.fuelPressureBeforeEngine": "Давление топлива перед двигателем",
    "sfoc.field.fuelTemperatureBeforeEngine": "Температура топлива перед двигателем",
    "sfoc.field.fuelType": "Тип топлива",
    "sfoc.field.fuelTypeIsTheSameAsUsedDuringTheBaselineTest": "Тип топлива идентичен используемому во время базового испытания (обычно HFO)",
    "sfoc.field.fuelViscosityBeforeEngine": "Вязкость топлива перед двигателем",
    "sfoc.field.generatorPowerFactor": "Коэффициент вырабатываемой мощности (Cos Φ)",
    "sfoc.field.loadIndicatorPosition": "Положение индикатора нагрузки",
    "sfoc.field.measuredCleanLeakWeight": "Измеренная масса чистой утечки",
    "sfoc.field.measuredFOC": "Измеренная FOC",
    "sfoc.field.measuredFuelOilCleanLeak": "Измеренная чистая утечка жидкого топлива",
    "sfoc.field.nickname": "Псевдоним",
    "sfoc.field.priorStartTestEngineShouldRunWithStableLoadFor20MinInLoadControl": "Перед началом испытания двигатель должен поработать с устойчивой нагрузкой 20 мин. в режиме управления нагрузкой",
    "sfoc.field.producedEnergy": "Вырабатываемая энергия",
    "sfoc.field.pumpRackPosition": "Положение рейки насоса {bank}",
    "sfoc.field.pumpRackPositionHeader": "Положение рейки насоса",
    "sfoc.field.quarterNr": "Номер квартала",
    "sfoc.field.repeatTestWhenProducedEnergyIsAboveBaseline": "Повторите испытание, когда вырабатываемая энергия на ± {tolerance} % выше базового значения",
    "sfoc.field.runHoursAtCACOH": "Наработка в часах в момент последнего капремонта CAC",
    "sfoc.field.runHoursAtLastCACWash": "Наработка в часах в момент последнего промывания CAC",
    "sfoc.field.runHoursAtLastInjectionPumpOH": "Наработка в часах в момент последнего капремонта инжекторного насоса",
    "sfoc.field.runHoursAtLastInjectorOH": "Наработка в часах в момент последнего капремонта инжектора",
    "sfoc.field.runHoursAtLastMajorOH": "Наработка в часах в момент последнего полного капремонта",
    "sfoc.field.runHoursAtLastTCMaintenance": "Наработка в часах в момент последнего техобслуживания турбонагнетателя",
    "sfoc.field.runHoursAtLastTCWash": "Наработка в часах в момент последнего промывания турбонагнетателя",
    "sfoc.field.samplingPosition": "Положение при отборе пробы",
    "sfoc.field.sulfur": "Содержание серы",
    "sfoc.field.testEndTime": "Время окончания испытания",
    "sfoc.field.testEngineInLoadControlModeConfirmThatActivePowerIsTheSameAtBaseline": "Проведите испытание двигателя в режиме управления нагрузкой и удостоверьтесь в том, что активная мощность такая же, как и при базовом испытании",
    "sfoc.field.testFailedDueTo": "Испытание не выполнено из-за",
    "sfoc.field.testStartTime": "Время начала испытания",
    "sfoc.field.totalRunningHours": "Общая наработка в часах",
    "sfoc.field.turboChargerSpeed": "Частота вращения турбонагнетателя",
    "sfoc.field.typeOfSFOCTest": "Тип испытания SFOC",
    "sfoc.field.waterContent": "Содержание воды",
    "sfoc.field.waterContentMeasured": "Содержание воды, измеренное испытательным комплектом",
    "sfoc.field.waterContentMeasuredFromFuel": "Содержание воды, измеренное в топливе",
    "sfoc.field.waterFuelEmulsionOff": "Подача водотопливной эмульсии (ВТЭ) отключена минимум за 6 рабочих часов до начала испытания",
    "sfoc.info.sendingReport": "Отправление отчета по SFOC.",
    "sfoc.info.sendingReportFailed": "Отправление отчета по SFOC не выполнено.",
    "sfoc.info.sendingReportSuccess": "Отчет по SFOC успешно отправлен.",
    "sfoc.meanValue": "Среднее значение",
    "sfoc.phase.automation": "Автоматика",
    "sfoc.phase.cabinet": "Шкаф",
    "sfoc.phase.engine": "Двигатель",
    "sfoc.phase.general": "Общая информация",
    "sfoc.phase.section.dataCollection": "Сбор данных для вычислений по топливу",
    "sfoc.phase.section.engineOverHaulHistory": "Предыстория капитального ремонта двигателя",
    "sfoc.phase.section.general": "Общая информация",
    "sfoc.phase.section.performanceTest": "Эксплуатационные испытания двигателя",
    "sfoc.phase.section.sfocPerformanceCheckList": "Проверочный лист эксплуатационных испытаний SFOC",
    "sfoc.samplingPosition.AtFOBoosterUnite": "В бустерном блоке жидкого топлива",
    "sfoc.samplingPosition.BeforeEngine": "Перед двигателем",
    "sfoc.samplingPosition.Other": "Прочее",
    "sfoc.testFailure.DiffFlowFluctuationOver2": "Колебание дифф. потока свыше > ±2 %",
    "sfoc.testFailure.LoadAndDiffFlowFluctuationOver2": "Колебание нагрузки и дифф. потока свыше > ±2 %",
    "sfoc.testFailure.LoadFluctuationOver2": "Колебание нагрузки свыше > ±2 %",
    "sfoc.unit.MJPerKg": "МДж/кг",
    "sfoc.unit.bar": "бар",
    "sfoc.unit.gPerKg": "г/кг",
    "sfoc.unit.gPerkWh": "г/кВт-ч",
    "sfoc.unit.hours": "ч",
    "sfoc.unit.kW": "кВт",
    "sfoc.unit.kWh": "кВт-ч",
    "sfoc.unit.kg": "кг",
    "sfoc.unit.kgPerHour": "кг/ч",
    "sfoc.unit.kgPerM3": "кг/м3",
    "sfoc.unit.mbar": "мбар",
    "sfoc.unit.mgPerKg": "мг/кг",
    "sfoc.unit.min": "мин",
    "sfoc.unit.mm": "мм",
    "sfoc.unit.mm2PerSec": "мм2/сек",
    "sfoc.unit.percent": "%",
    "sfoc.unit.rpm": "об/мин",
    "sfoc.value.afterOH": "После капитального ремонта",
    "sfoc.value.audit": "Аудит",
    "sfoc.value.na": "ОТСУТСТВУЕТ",
    "sfoc.value.no": "Нет",
    "sfoc.value.off": "Выкл.",
    "sfoc.value.on": "Вкл.",
    "sfoc.value.other": "Прочее",
    "sfoc.value.quarterly": "Ежеквартально",
    "sfoc.value.yes": "Да",
  },
};
