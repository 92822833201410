import * as translations from "src/util/translations";

export interface ITranslationLanguageStrings {
  [stringKey: string]: string;
}

export interface ITranslations {
  [languageCode: string]: ITranslationLanguageStrings;
}

export const languageLocales = {
  ar: "Arabic",
  zh_CN: "简体中文",
  "zh-cn": "Chinese (Simplified)",
  zh_TW: "Chinese (Traditional)",
  da: "Danish",
  nl_NL: "Dutch",
  en_US: "English",
  en_GB: "English (UK)",
  et: "Estonian",
  // Left here for debug purposes
  // 'fi': 'Finnish',
  fr: "French",
  de: "German",
  el: "Greek",
  es_MX: "Español Latinoamericano",
  id: "Indonesian (Bahasa Indonesia)",
  hu: "Hungarian",
  it: "Italian",
  ja: "日本語",
  ko: "Korean",
  no: "Norwegian",
  pl: "Polish",
  pt_BR: "Português Brasileiro",
  pt_PT: "Portuguese (European)",
  ro: "Romanian",
  ru: "Русский язык",
  es: "Spanish",
  sv: "Swedish",
  tr: "Turkish",
};

export enum Sector {
  Marine = "Marine",
  Energy = "Energy",
  MarineAndEnergy = "Marine&Energy",
  Other = "Other",
}

let currentSector = Sector.Marine;
let currentLanguage = "en_US";

export function getGlobalLocalizationInfo() {
  return { currentSector, currentLanguage, defaultLang };
}

export function setSector(sector: Sector) {
  currentSector = sector;
}

export function setLanguage(language: string) {
  if (Object.keys(languageLocales).includes(language)) {
    currentLanguage = language;
  } else {
    const message = `${translateString("language.languageNotFound")}: ${language}`;
    throw new Error(message);
  }
}

export interface IKeyValuePair {
  [key: string]: string | number;
}

function mergeTranslations(translationSources: ITranslations[]) {
  const merged: ITranslations = {};
  translationSources.forEach((translationSource) => {
    Object.keys(translationSource).forEach((language) => {
      merged[language] = Object.assign(merged[language] || {}, translationSource[language]);
    });
  });
  return merged;
}

export const mergedTranslations = mergeTranslations(Object.keys(translations).map((key) => translations[key]));
export const defaultLang = "en_US";

export function getSupportedLanguages() {
  const locales = Object.keys(languageLocales);
  return Object.keys(mergedTranslations).filter((lang) => locales.includes(lang));
}

export function getSectorPostFix(sector: Sector) {
  switch (sector) {
    case Sector.Marine:
      return "marine";

    case Sector.Energy:
      return "energy";

    default:
      return undefined;
  }
}

export function findStringFromTranslations(languageCode: string, key: string, localTranslations?: ITranslations) {
  if ((localTranslations || mergedTranslations)[languageCode] === undefined) {
    return (localTranslations || mergedTranslations)[defaultLang][key];
  }

  return (localTranslations || mergedTranslations)[languageCode][key];
}

export function findStringForSector<T, ValuePairType>(
  language: string,
  key: string,
  sectorName: string | undefined,
  values: ValuePairType,
  findAndReplaceString: (
    languageCode: string,
    key: string,
    values: ValuePairType,
    localTranslations?: ITranslations
  ) => T | undefined,
  localTranslations?: ITranslations
) {
  // Try to find a translation for current language and sector -
  // if sector is not found, fall back to "installation" and finally
  // to default language.

  return (
    findAndReplaceString(language, `${key}:${sectorName}`, values, localTranslations) ||
    findAndReplaceString(language, key, values, localTranslations) ||
    findAndReplaceString(defaultLang, `${key}:${sectorName}`, values, localTranslations) ||
    findAndReplaceString(defaultLang, key, values, localTranslations)
  );
}

function getLocalizedString(
  languageCode: string,
  key: string,
  values: IKeyValuePair = {},
  localTranslations?: ITranslations
) {
  let localizedString = findStringFromTranslations(languageCode, key, localTranslations);

  if (!localizedString) {
    return undefined;
  }

  Object.keys(values).forEach((valueKey) => {
    localizedString = localizedString.replace(`{${valueKey}}`, `${values[valueKey]}`);
  });

  return localizedString;
}

export function translateStringInner(
  key: string,
  values: IKeyValuePair = {},
  language: string,
  sector: Sector,
  localTranslations?: ITranslations
): string {
  const sectorName = getSectorPostFix(sector);

  const translatedString = findStringForSector<string, IKeyValuePair>(
    language,
    key,
    sectorName,
    values,
    getLocalizedString,
    localTranslations
  );

  if (!translatedString) {
    // If we are already in default language, throw
    if (language === defaultLang) {
      throw new Error(`Translation for key ${key} was not found.`);
    }

    // Fall back to default language
    return translateString(key, values, defaultLang, sector, localTranslations);
  }

  return translatedString;
}

export function translateString(
  key: string,
  values: IKeyValuePair = {},
  languageCode?: string,
  sector?: Sector,
  localTranslations?: ITranslations
) {
  const sectorName = sector || currentSector || Sector.Marine;
  const language = languageCode || currentLanguage || defaultLang;

  if (key && key.startsWith("request.reason")) {
    if (!findStringFromTranslations(language, key, localTranslations)) {
      return "Unknown reason [" + key + "]";
    }
  }

  return translateStringInner(key, values, language, sectorName, localTranslations);
}
