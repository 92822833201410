import { Field, Form, Formik } from "formik";
import * as React from "react";
import { Button } from "src/design-system/Button";
import { Container } from "src/design-system/Container";
import { TextArea } from "src/design-system/TextArea";
import { translateString } from "src/util/localization";
import { IErrors } from "src/util/validators";
import { CMReportRatingSurvey } from "src/views/ReportsView/SubmitRating/CMReportRatingSurvey";
import styled from "styled-components";
import { Rating, RatingReason } from "./ratingNames";

const SendButtonWrapper = styled.div`
  padding: 20px 0px;
`;
export interface IApprovalDialogProps {
  isOpen: boolean;
  selectedRating: Rating;
  isReport?: boolean;
  onApprove(data: IApprovalFormFields): void;
  onCancel(): void;
}

export interface IApprovalFormFields {
  ratingReason: RatingReason[];
  ratingFeedback: string;
}

export class ApprovalComponent extends React.Component<IApprovalDialogProps> {
  public validate = (values: IApprovalFormFields): IErrors => {
    const errors: IErrors = {};

    if (
      (this.props.selectedRating === Rating.NotSatisfied || this.props.selectedRating === Rating.PartlySatisfied) &&
      values.ratingFeedback.trim().length === 0
    ) {
      errors.ratingFeedback = translateString("request.rating.suggestionIsRequired");
    }

    return errors;
  };

  public render() {
    const { selectedRating, isOpen, isReport } = this.props;
    const satisfied = selectedRating === Rating.Satisfied;
    const optionalFeedbackLabel = `${translateString("request.rating.openFeedback")} (${translateString(
      "optionalFieldTitle"
    )})`;
    const feedbackLabel =
      selectedRating === Rating.NotSatisfied || selectedRating === Rating.PartlySatisfied
        ? translateString("request.rating.openFeedback")
        : optionalFeedbackLabel;

    return (
      <Container>
        {isOpen && (
          <Formik<IApprovalFormFields>
            initialValues={{ ratingFeedback: "", ratingReason: [] }}
            validate={this.validate}
            onSubmit={this.props.onApprove}
          >
            <Form>
              <Container>
                {isReport && <Field name="ratingReason" isSatisfied={satisfied} component={CMReportRatingSurvey} />}
                <Field
                  name="ratingFeedback"
                  label={isReport ? optionalFeedbackLabel : feedbackLabel}
                  component={TextArea}
                  placeholder={translateString("request.rating.whatCanWeDoBetter")}
                />
              </Container>
              <SendButtonWrapper>
                <Button type="submit">{translateString("request.rating.sendFeedback")}</Button>
              </SendButtonWrapper>
            </Form>
          </Formik>
        )}
      </Container>
    );
  }
}
