import { IEquipment } from "online-services-types";
import * as React from "react";
import { getPathWithParams, getRoutes, IRouteParams, redirectBrowserToURL } from "src/routes";
import { RouteType } from "src/routeType";
import styled from "styled-components";
import { getUsingOpenSearch } from "../../util/localstorage";

/* USE for now:
  <DocumentDownloadComponent fileId="068000123456789" articleId="123454"/>
  <DocumentDownloadComponent fileId="068000123456789"/>
*/

export const DownloadComponentWrapper = styled.span`
  cursor: pointer;
`;

interface IDocumentDownloadComponentProps {
  children: React.ReactNode | undefined;
  fileId: string;
  fileIds?: string;
  articleId?: string;
  articleName?: string;
  requestType?: string;
  requestId?: string;
  cmReportId?: string;
  courseId?: string;
  energyDocumentId?: string;
  appliesToEquipments?: IEquipment[];
  onOpenFile?(): void;
  searchText?: string;
}

export class DocumentDownloadComponent extends React.Component<IDocumentDownloadComponentProps> {
  public render() {
    return (
      <span>
        <DownloadComponentWrapper onClick={this.openFile}>{this.props.children}</DownloadComponentWrapper>
      </span>
    );
  }

  private readonly openFile = () => {
    if (this.props.onOpenFile) {
      this.props.onOpenFile();
    }

    this.redirect();
  };

  private readonly redirect = () => {
    const params: IRouteParams = { fileId: this.props.fileId };
    let path = getPathWithParams(getRoutes(RouteType.Standalone).Download, params);

    if (
      this.props.articleId ||
      this.props.articleName ||
      this.props.requestId ||
      this.props.requestType ||
      this.props.cmReportId ||
      this.props.courseId ||
      this.props.searchText ||
      this.props.energyDocumentId
    ) {
      const queryParams: { [param: string]: string } = {};
      const equipmentId =
        this.props.appliesToEquipments && this.props.appliesToEquipments.length > 0
          ? this.props.appliesToEquipments[0].id
          : undefined;

      if (this.props.fileIds) {
        queryParams.fileIds = this.props.fileIds;
      }

      if (this.props.articleId) {
        queryParams.articleId = this.props.articleId;
      }
      if (this.props.articleName) {
        queryParams.articleName = this.props.articleName.replace(/&/g, "_").trim();
      }
      if (this.props.requestId) {
        queryParams.requestId = this.props.requestId;
      }
      if (this.props.requestType) {
        queryParams.requestType = this.props.requestType;
      }
      if (this.props.cmReportId) {
        queryParams.cmReportId = this.props.cmReportId;
      }
      if (this.props.courseId) {
        queryParams.courseId = this.props.courseId;
      }
      if (this.props.energyDocumentId) {
        queryParams.energyDocumentId = this.props.energyDocumentId;
      }
      if (this.props.searchText) {
        queryParams.searchText = this.props.searchText;
      }
      if (equipmentId) {
        queryParams.equipmentId = equipmentId;
      }
      if (getUsingOpenSearch().toLowerCase() === "true") {
        queryParams.openSearch = "true";
      }
      path += `?${Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&")}`;
    }
    redirectBrowserToURL(path, true);
  };
}
