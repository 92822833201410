// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_fi: ITranslations = {
  fi: {
    "documents.applyFilter": "Käytä rajausta",
    "documents.button.download": "Lataa",
    "documents.button.open": "Avaa",
    "documents.button.print": "Tulosta",
    "documents.clearFilter": "Poista rajaus",
    "documents.documentNumber": "Dokumentin numero",
    "documents.documentType": "Dokumentin tyyppi",
    "documents.documentTypes.ASI": "Tiedotuslehti",
    "documents.documentTypes.CMREPORT": "Tilaraportti",
    "documents.documentTypes.FAQ": "UKK",
    "documents.documentTypes.MAN": "Ohje",
    "documents.documentTypes.SPC": "Varaosaluettelo",
    "documents.documentTypes.SW": "Ohjelmisto",
    "documents.documentTypes.TECHREQUEST": "Tekninen tuki",
    "documents.documentTypesLongText.MAN": "Käyttö- ja huolto-ohje",
    "documents.documentTypesLongText.SPC": "Varaosaluettelo",
    "documents.documentTypesLongText.couldntFindArticle": "Artikkelia ei löytynyt",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "Virhe artikkelin haussa: {error}",
    "documents.files": "Tiedostot",
    "documents.filterBasedOnDocumentType": "Rajaa dokumenttityypin mukaan",
    "documents.forImmediateAction": "Vaatii välitöntä huomiota",
    "documents.listHeader": "Dokumentteja ({totalItems})",
    "documents.loadMore": "Lataa lisää",
    "documents.nickName": "Lempinimi",
    "documents.productReferenceType": "Tuoteviittaustyyppi",
    "documents.search": "Hae",
    "documents.sorting.documentNumberAZ": "Dokumentin numero (A-Ö)",
    "documents.sorting.documentTypeAZ": "Dokumentin tyyppi (A-Ö)",
    "documents.sorting.titleAZ": "Otsikko (A-Ö)",
    "documents.title": "Otsikko",
    "documents.typeToSearch": "Kirjoita etsiäksesi..",
  },
};
