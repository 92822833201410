import { Field, Form, Formik } from "formik";
import { ICombinedRequest, IContact, IWarrantyClaim } from "online-services-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { IAPIResource } from "src/APIFetch";
import { Dialog } from "src/components/Dialog";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { spinnerAlign, spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { EmailMultiSelect } from "src/components/PersonSelector";
import { Button, ButtonLink, ButtonStyle } from "src/design-system/Button";
import { Container } from "src/design-system/Container";
import { themes } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { Disclaimer, IDisclaimerTheme } from "src/design-system/Tokens/typography";
import { translateString } from "src/util/localization";
import { RequestStatus } from "src/util/requestStatus";
import styled, { ThemeProvider } from "styled-components";

export interface IInterestedPeopleOwnProps {
  request: ICombinedRequest;
}

export interface IInterestedPeopleStateProps {
  contacts: IAPIResource<IContact>;
}

export interface IInterestedPeopleDispatchProps {
  getAccountContacts(): void;
  updateInterestedParties(request: ICombinedRequest, contacts: IContact[], onSuccess: () => void): void;
}

type IInterestedPeopleProps = IInterestedPeopleOwnProps & IInterestedPeopleStateProps & IInterestedPeopleDispatchProps;

const VISIBLE_ROWS = 2;

export const InterestedPeopleComponent = ({ request, contacts, getAccountContacts, updateInterestedParties }: IInterestedPeopleProps) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const interestedParties = (request as IWarrantyClaim).contacts || [];
  const autoAddedPeople = (request as IWarrantyClaim).autoNotifiedCustomersEmails || [];
  const allEmails = [...interestedParties.map((contact) => contact.email), ...autoAddedPeople];

  useEffect(() => {
    if (!contacts.data || contacts.data.length === 0) {
      getAccountContacts();
    }
  }, []);

  const onSubmitContacts = (values: { contacts: IContact[] }) => {
    setSaving(true);
    updateInterestedParties(request, values.contacts, () => {
      setEditing(false);
      setSaving(false);
    });
  };

  return (
    <ThemeProvider theme={themes.light}>
      {allEmails.length > 0 && allEmails.slice(0, VISIBLE_ROWS).map((email) => (
        <EmailRow key={email}>{email}</EmailRow>
      ))}
      {allEmails.length > VISIBLE_ROWS && (
        <DialogButton onClick={() => setIsDialogOpen(true)}>
          <LocalizedString id="request.plusOthers" values={{ count: allEmails.length - 2 }} />
        </DialogButton>
      )}
      {(request.status === RequestStatus.InProgress) && (
        <>
          {editing ? (
            <Formik<{ contacts: IContact[] }> initialValues={{ contacts: interestedParties }} onSubmit={onSubmitContacts}>
              <Form>
                <Field
                  id="contacts"
                  name="contacts"
                  label={translateString("spareParts.emailAddresses")}
                  component={EmailMultiSelect}
                  contacts={contacts}
                  requireName={true}
                />
                <ButtonWrapper>
                  {saving ? (
                    <LoadingSpinner size={spinnerSize.sm} disableText dark align={spinnerAlign.start} />
                  ) : (
                    <>
                      <Button buttonStyle={ButtonStyle.Secondary} onClick={() => setEditing(false)}>
                        <LocalizedString id="cancel" />
                      </Button>
                      <Button type="submit">
                        <LocalizedString id="save" />
                      </Button>
                    </>
                  )}
                </ButtonWrapper>
              </Form>
            </Formik>
          ) : (
            <ButtonWrapper>
              <Button onClick={() => setEditing(true)}>
                <LocalizedString id="request.addMorePeople" />
              </Button>
            </ButtonWrapper>
          )}
        </>
      )}
      <Dialog
        title={translateString("request.interestedPeople")}
        isDialogOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        cancelButtonTitle={translateString("button.close")}
      >
        {interestedParties.length > 0 && (
          <Container $margin={[2,0]}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}><LocalizedString id="request.peopleAddedByUser" /></Disclaimer>
            {interestedParties.length > 0 && interestedParties.map((contact) => (
              <EmailRow key={contact.id}>{contact.email}</EmailRow>
            ))}
          </Container>
        )}
        {autoAddedPeople.length > 0 && (
          <Container $margin={[2,0]}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}><LocalizedString id="request.peopleAddedAutomatically" /></Disclaimer>
            {autoAddedPeople.length > 0 && autoAddedPeople.map((email) => (
              <EmailRow key={email}>{email}</EmailRow>
            ))}
          </Container>
        )}
      </Dialog>
    </ThemeProvider>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  margin-top: 0.5em;
`;

const EmailRow = styled.div`
  margin-bottom: 5px;
`;

const DialogButton = styled(ButtonLink)`
  color: ${colors.primary.blue};
  text-transform: none;
`;