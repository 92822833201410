import * as React from "react";

import { FlexContainer } from "src/design-system/Container";
import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import styled from "styled-components";

interface IStyledHeaderProps {
  backgroundColor?: string;
}

export const StyledHeader = styled.div<IStyledHeaderProps>`
  background-color: ${(props: ThemedProps & IStyledHeaderProps) => (props.backgroundColor ? props.backgroundColor : (props.theme.table ? props.theme.table.indicator : colors.primary.blue))};
  color: ${colors.primary.white};
  cursor: pointer;
  padding: 5px;
  margin: 4px 0;
  border-radius: ${defaultBorderRadius};
`;

interface IExpandableHeaderProps {
  static?: boolean;
  opened?: boolean;
  logo?: JSX.Element;
  title?: string | JSX.Element;
  children?: React.ReactNode;
  backgroundColor?: string;
}

interface IExpandableHeaderState {
  isOpen: boolean;
}

export class ExpandableHeader extends React.Component<IExpandableHeaderProps, IExpandableHeaderState> {
  constructor(props: IExpandableHeaderProps) {
    super(props);

    this.toggleOpen = this.toggleOpen.bind(this);

    this.state = {
      isOpen: this.props.opened ? this.props.opened : true,
    };
  }

  public toggleOpen() {
    if (this.props.static) return;
    this.setState({ isOpen: !this.state.isOpen });
  }

  public render() {
    return (
      <>
        <StyledHeader onClick={this.toggleOpen} backgroundColor={this.props.backgroundColor}>
          <FlexContainer>
            {this.state.isOpen ? "-" : "+"} {this.props.title} {this.props.logo}
          </FlexContainer>
        </StyledHeader>
        {this.state.isOpen && this.props.children}
      </>
    );
  }
}
