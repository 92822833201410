import * as React from "react";

import { Disclaimer, P } from "src/design-system/Tokens/typography";
import { Tooltip, TooltipArrow } from "@wartsila/ui-kit";
import { defaultBoxBorder, defaultBoxShadow, spacer } from "src/design-system/Tokens/tokens";

import { Container } from "../Container";
import { Link } from "react-router-dom";
import { PortalTooltip } from "src/components/Portal";
import { ThemedProps } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";

export interface ILinkPassedData {
  pathname: string;
  state: {
    [property: string]: any; // might be any object passed via router
  };
}

export enum NavigationButtonIndicator {
  Online,
  Offline,
  HasNotifications,
}

export const indicatorColors: { [indicatorType: string]: string } = {
  [NavigationButtonIndicator.Online]: colors.notification.ok,
  [NavigationButtonIndicator.Offline]: colors.secondary.gray40,
  [NavigationButtonIndicator.HasNotifications]: colors.primary.orange,
};

interface INavigationButton {
  isDisabled?: boolean;
  indicator?: NavigationButtonIndicator;
  subscript?: string;
  className?: string;
  icon: JSX.Element;
  label: string;
  href?: string | ILinkPassedData;
  externalURL?: string;
  notificationMessage?: string;
  notificationCount?: number | string;
  onClick?: () => void;
  onEnter?: () => void;
  onExit?: () => void;
}

interface INavigationButtonIconCircle {
  indicator?: NavigationButtonIndicator;
  indicatorText?: string;
}

const MenuLabel = styled(P)`
  margin-top: ${spacer(2)};
  text-transform: uppercase;
`;

export const NavigationButtonIconCircle = styled.div`
  position: relative;

  width: 5em;
  height: 5em;

  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: 6em;
    height: 6em;
  }
  background: ${(props: ThemedProps<INavigationButtonIconCircle>) => props.theme.navigationButton.background};
  border: ${defaultBoxBorder};
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;
  box-shadow: ${defaultBoxShadow};
`;

interface INavigationButtonWrapper {
  isDisabled?: boolean;
}

const Wrapper = styled.div<INavigationButtonWrapper>`
  display: inline-block;
  width: 100%;
  text-align: center;

  & svg {
    fill: ${(props: ThemedProps) => props.theme.primary2};
  }

  & ${MenuLabel} {
    color: ${(props: ThemedProps) => props.theme.primary2};
  }

  & ${Disclaimer} {
    color: ${(props: ThemedProps) => props.theme.primary2};
  }

  ${(props: ThemedProps<INavigationButtonWrapper>) =>
    props.isDisabled &&
    `
    & svg {
      fill: ${props.theme.navigationButton.disabled};
    }

    & ${MenuLabel} {
      color: ${props.theme.navigationButton.disabled};
    }
  `}
`;

export const NavigationButtonIcon = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 auto;
  width: 2em;
  height: 2em;

  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: 3em;
    height: 3em;
  }
`;

const SimpleButtonWrapper = styled.span`
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const IndicatorContainer = styled(Container)`
  top: 0;
  right: 0;
  position: absolute;
`;

const Indicator = styled(Container)<{ indicator: string }>`
  position: relative;

  padding: 0 7px;
  min-width: 11px;
  height: 25px;
  line-height: 25px;
  border-radius: 12.5px;

  color: ${colors.primary.white};
  background-color: ${(props) => props.indicator};
`;

const InfoTooltip = styled(Tooltip)`
  transform: translate(-50%, -70%);
`;

export class NavigationButton extends React.Component<INavigationButton> {
  public render() {
    const {
      onClick,
      icon,
      isDisabled,
      label,
      subscript,
      href,
      externalURL,
      notificationCount = "",
      onEnter,
      onExit,
      notificationMessage,
    } = this.props;
    let { indicator } = this.props;

    if (notificationCount) {
      indicator = NavigationButtonIndicator.HasNotifications;
    }

    const itemContent = (
      <Wrapper isDisabled={isDisabled} className={this.props.className}>
        <NavigationButtonIconCircle>
          {indicator ? (
            <IndicatorContainer>
              <PortalTooltip
                alignment="top"
                component={
                  notificationMessage ? (
                    <InfoTooltip arrow={TooltipArrow.Bottom}>{notificationMessage}</InfoTooltip>
                  ) : (
                    <span />
                  )
                }
              >
                <Indicator indicator={indicatorColors[indicator]}>{notificationCount}</Indicator>
              </PortalTooltip>
            </IndicatorContainer>
          ) : null}
          <NavigationButtonIcon>{icon}</NavigationButtonIcon>
        </NavigationButtonIconCircle>
        <MenuLabel>{label}</MenuLabel>
        {subscript && <Disclaimer>{subscript}</Disclaimer>}
      </Wrapper>
    );

    if (href) {
      return (
        <Link to={href} onMouseEnter={onEnter} onMouseLeave={onExit}>
          {itemContent}
        </Link>
      );
    } else if (externalURL) {
      return (
        <a href={externalURL} target="_blank" rel="noopener noreferrer" onMouseEnter={onEnter} onMouseLeave={onExit}>
          {itemContent}
        </a>
      );
    } else {
      return (
        <SimpleButtonWrapper onClick={onClick} onMouseEnter={onEnter} onMouseLeave={onExit}>
          {itemContent}
        </SimpleButtonWrapper>
      );
    }
  }
}
