import { defaultBorderRadius, defaultBoxShadow, spacer, spacers, thinBorder } from "src/design-system/Tokens/tokens";

import { FlexContainer } from "src/design-system/Container";
import { IColumn } from "./interfaces";
import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";
import { whenIE11 } from "src/util/browserCompatibility";

export const Expand = styled.div<{ $background?: string }>`
  display: flex;
  align-items: center;
  min-width: ${spacer(1)};
  margin-left: ${spacer(1)};

  ${(props) => {
    return props.$background && `background: ${props.$background};`;
  }}

  &:hover {
    cursor: pointer;
  }
`;

export const PlusMinus = styled.div<{ expanded: boolean; $hasChildren: boolean; $showToggleWidth?: number }>`
  display: block;
  position: relative;
  min-width: ${spacer(5)};
  height: 30px;

  ${(props) => {
    return !props.$hasChildren && props.$showToggleWidth
      ? `@media (min-width: ${props.$showToggleWidth}px) {
          z-index:-1;
      }`
      : ``;
  }}

  ${(props) => {
    return (
      props.expanded &&
      `&:after {
        transform: rotate(90deg);
      }`
    );
  }};

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: ${(props: ThemedProps) => (props.theme.table ? props.theme.table.indicator : colors.primary.blue)};
    transition: 150ms all ease-out;
  }

  &:before {
    top: 13px;
    left: 9px;
    right: 9px;
    height: 4px;
  }

  &:after {
    top: 9px;
    left: 13px;
    height: 12px;
    width: 4px;
  }
`;

interface IRowElementProps extends ThemedProps {
  hasAlert?: boolean;
  alertColor?: string;
}

export const RowElement = styled.div<IRowElementProps & ThemedProps>`
  display: block;
  position: relative;
  white-space: pre-wrap;
  margin: ${spacer(0.5)} 0;
  border-radius: 4px;
  box-shadow: ${defaultBoxShadow};
  background: ${colors.primary.white};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: ${(props: IRowElementProps & ThemedProps) =>
      props.hasAlert
        ? props.alertColor || colors.primary.orange
        : props.theme.table
        ? props.theme.table.indicator
        : colors.primary.blue};
    width: 6px;
    height: 100%;
    border-radius: ${defaultBorderRadius} 0 0 ${defaultBorderRadius};
  }
`;

export const VisibleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
`;

export const SubRow = styled.div`
  display: block;
  margin: ${spacers([0, 0, 0, 6])};
  padding-bottom: ${spacer(3)};
`;

export const ExpandedRow = styled.div<IColumn>`
  padding: ${spacers([1, 1, 3, 1])}
    ${(props: IColumn) => {
      if (props.$alwaysShow) {
        return "display: none";
      } else if (props.$widthToShow) {
        return `display: block;
      @media (min-width: ${props.$widthToShow}px) {
      display: none;
    }`;
      } else {
        return "display: block;";
      }
    }};
`;

export const Column = styled.div<IColumn>`
  display: block;
  flex-basis: ${(props: IColumn) => props.$width}px;
  padding-left: ${spacer(1)};
  flex-grow: ${({ $grow }: IColumn) => ($grow ? "1" : "0")};

  ${(props: IColumn) => {
    return (
      !props.$alwaysShow &&
      `@media(max-width: ${props.$widthToShow}px) {
      display: none;
    }`
    );
  }}

  ${(props: IColumn) => {
    return props.$backgroundColor && `background: ${props.$backgroundColor};`;
  }}

  ${(props: IColumn) => {
    if (props.$staticWidth) {
      return `
      max-width: ${props.$width}px;
      word-break: break-word;
      `;
    }
    return "";
  }}

  :last-child:not(:first-child) {
    margin-left: auto;
  }
`;

export const RowContent = styled.div<{ $accumulator: number }>`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.$accumulator}px) {
    flex-wrap: wrap;
    ${Column} {
      padding-bottom: ${spacer(2)};
    }
  }
`;

export const TableBlock = styled.div`
  position: relative;
  flex-grow: 1;
  color: ${colors.secondary.blue100};
`;

// This div is used to produce the thin white vertical line connecting the grouped rows together.
export const VerticalLine = styled.div`
  ${(props: ThemedProps) => `border-left: ${thinBorder`${props.theme.foreground}`};`};

  position: absolute;
  height: 100%;
  top: -${spacer(0.5)};
  width: 2px;
  left: -8px;

  ${whenIE11(`
    display: none;
  `)}
`;

export const SubList = styled.div`
  margin-left: ${spacer(2)};
`;

interface IRowProps extends ThemedProps {
  $isSubList?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
}

// Wrapper to add sublist decorations
export const Row = styled.div<IRowProps>`
  position: relative;
  ${(props: IRowProps) => {
    return (
      props.isSelectable &&
      props.isSelected &&
      `
      background-color: ${colors.secondary.blue100};
      padding: 5px;
    `
    );
  }}

  ${(props: IRowProps) => {
    return (
      props.$isSubList &&
      `::before {
        content: '';
        border-top: ${thinBorder`${props.theme.foreground}`};
        position: absolute;
        top: 29px;
        left: -8px;
        width: 9px;
      }
      :nth-last-child(2)::after {
        content: '';
        border-left: 2px solid ${props.theme.background};
        width: 1px;
        position: absolute;
        left: -8px;
        height: calc(100% - 29px);
        top: 30px;
        z-index: 1;
      }
      `
    );
  }};
`;

export const FallbackBox = styled.div`
  margin: ${spacers([4, 0])};
  padding: ${spacers([2, 7])};
  border: ${thinBorder`${colors.primary.blue}`};

  ${(props: IRowProps) => {
    return `color: ${props.theme.text};`;
  }}
`;

export const SortingContainer = styled(FlexContainer)`
  color: ${(props) => props.theme.text};
  user-select: none;
  font-size: 0.9rem;

  & > div {
    cursor: pointer;
  }
`;
