import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const GasSolutionIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>GasSolution</title>{" "}
    <g id="Icon_Text" data-name="Icon Text">
      <path d="M11,35c2,5,6,9,13,9a14.25,14.25,0,0,0,13-9ZM36,20A67,67,0,0,0,24,4,67,67,0,0,0,12,20ZM12.63,30.87H17.2v2H10V22.15h2.63Zm14.79,2H24.8L20.94,26.2v6.66H18.31V22.15h2.63l3.86,6.66V22.15h2.62ZM38,31.53a4.24,4.24,0,0,1-1.77,1.07,7.78,7.78,0,0,1-2.56.4,4.61,4.61,0,0,1-3.42-1.29,5.15,5.15,0,0,1-1.37-3.58v-.93a6.28,6.28,0,0,1,.57-2.76,4.17,4.17,0,0,1,1.63-1.82A4.68,4.68,0,0,1,33.52,22a4.89,4.89,0,0,1,3.16.91A3.85,3.85,0,0,1,38,25.64H35.46a2,2,0,0,0-.58-1.28A1.91,1.91,0,0,0,33.61,24a1.72,1.72,0,0,0-1.53.82,4.33,4.33,0,0,0-.55,2.35v.65a4.24,4.24,0,0,0,.56,2.41,2,2,0,0,0,1.76.8,2.24,2.24,0,0,0,1.53-.45V28.91H33.55V27.14H38Z" />
    </g>
  </IconBase>
);
