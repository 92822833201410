import * as React from "react";
import styled from "styled-components";
import { Checkbox as UICheckbox } from "@wartsila/ui-kit";

import { ThemedProps } from "src/design-system/Theme/theme";
import { spacer } from "src/design-system/Tokens/tokens";
import { fixedFontSizes, Label } from "src/design-system/Tokens/typography";
import { ErrorComponent } from "src/design-system/Error";
import { getObjectProperty } from "src/util/helpers";
import { FieldProps } from "formik";
import { NotificationCircle } from "src/components/DocumentFilters/NotificationCircle";

interface ICheckboxWrapper {
  color?: string;
}

export const CheckboxWrapper = styled.label`
  margin-right: ${spacer(1)};
`;

const CheckboxInput = styled(UICheckbox)`
  label {
    font-size: ${fixedFontSizes.baseFontSize}px;
    line-height: 1.5;
    ${(props: ThemedProps) => `
      color: ${props.theme.input.foreground2};
    `}
  }

  .icon {
    left: 0 !important;
    width: 1.2em;
    height: 1.2em;
  }
`;

export interface ICheckbox {
  color?: string;
  checked: boolean;
  disabled?: boolean;
  invalid?: boolean;
  label?: string | React.ReactNode;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

/* Standard checkbox without any label to be used e.g. in tables columns */

export const Checkbox = ({ checked, disabled, invalid, onChange, color, ...props }: ICheckbox) => (
  <CheckboxInput
    checked={checked}
    disabled={disabled === undefined ? false : disabled}
    invalid={invalid === undefined ? false : invalid}
    onChange={onChange}
    {...props}
  />
);

interface ICheckBoxProps {
  label: string;
  checked: boolean;
  disabled?: boolean;
  invalid?: boolean;
  color?: string;
  notificationColor?: string;
  nested?: boolean;
  notificationCount?: number;
  icon?: JSX.Element;
  onChange(e?: React.ChangeEvent<HTMLInputElement>): void;
}

const ClickableLabel = styled(Label)`
  width: 100%;
  cursor: pointer;
  padding: ${spacer(1)};
  display: flex;
  align-items: center;
  color: ${(props: ThemedProps<ICheckboxWrapper>) => (props.color ? props.color : props.theme.text)};
`;

const CheckboxListElement = styled.li`
  list-style: none;
`;

const CheckboxListIconWrapper = styled.div`
  margin-left: auto;
`;

/* Checkbox with label in a list */

export const ListCheckbox = (props: ICheckBoxProps) => {
  const { label, disabled, invalid, checked, onChange, notificationColor, notificationCount, color } = props;
  return (
    <CheckboxListElement>
      <ClickableLabel color={color}>
        <Checkbox
          label={label}
          checked={checked}
          onChange={onChange}
          disabled={disabled === undefined ? false : disabled}
          invalid={invalid === undefined ? false : invalid}
        />
        {props.notificationCount ? (
          <NotificationCircle color={notificationColor} count={notificationCount || 0} />
        ) : null}
        {props.icon && <CheckboxListIconWrapper>{props.icon}</CheckboxListIconWrapper>}
      </ClickableLabel>
    </CheckboxListElement>
  );
};

export interface ICheckboxInputProps extends FieldProps<{ [key: string]: string }> {
  label: React.ReactNode;
  withMargin?: boolean;
  disabled?: boolean;
}

/* Checkbox with label in a form with validation */

export const FormCheckbox = (props: ICheckboxInputProps) => {
  const { label, form, field } = props;
  const fieldTouched = getObjectProperty(form.touched, field.name);
  const fieldErrors = getObjectProperty(form.errors, field.name);
  return (
    <>
      <Checkbox
        label={label}
        checked={!!form.values[field.name]}
        onChange={() => form.setFieldValue(field.name, !form.values[field.name])}
        disabled={props.disabled}
      />
      {fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
    </>
  );
};

interface IMultiCheckboxProps extends FieldProps {
  options: Array<{ key: string; label: string }>;
  allOptionsLabel: string;
  onChange?: (options: string[]) => void;
}

const UnsortedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

/* Renders multiple checkboxes with a "all options" checkbox at the top.
   This Formik-compatible version was made to be used in the ListFilter component */
export const MultiCheckbox: React.FC<IMultiCheckboxProps> = ({ form, field, options, allOptionsLabel, onChange }) => {
  const fieldTouched = getObjectProperty(form.touched, field.name);
  const fieldErrors = getObjectProperty(form.errors, field.name);
  const fieldValue: string[] = getObjectProperty(form.values, field.name) || [];

  return (
    <>
      <UnsortedList>
        <ListCheckbox
          key={`typefilter-all`}
          label={allOptionsLabel}
          checked={fieldValue.length === 0}
          onChange={() => {
            const value = [...fieldValue];
            value.splice(0, fieldValue.length);
            form.setFieldValue(field.name, value);
            if (onChange) {
              onChange(value);
            }
          }}
        />
        {options.map((option) => (
          <ListCheckbox
            key={`typefilter-${option.key}`}
            label={option.label}
            checked={fieldValue.includes(option.key)}
            onChange={() => {
              const value = [...fieldValue];
              const index = fieldValue.indexOf(option.key);
              if (index !== -1) {
                value.splice(index, 1);
              } else {
                value.push(option.key);
              }
              form.setFieldValue(field.name, value);
              if (onChange) {
                onChange(value);
              }
            }}
          />
        ))}
      </UnsortedList>
      {fieldTouched && fieldErrors && <ErrorComponent>{fieldErrors}</ErrorComponent>}
    </>
  );
};
