// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_fi: ITranslations = {
  fi: {
    "contracts.attachmentDocumentTypes": "Tiedostot ({types})",
    "contracts.attachmentImageTypes": "Kuvat ({types})",
    "contracts.attachmentsMessage": "Voit lähettää seuraavanlaisia tiedostoja. Maksimi tiedoston koko on {maxSize}.",
    "contracts.cbmExpertName": "CBM-asiantuntija",
    "contracts.contact": "Ota yhteyttä",
    "contracts.contactInformation": "Yhteystiedot",
    "contracts.contactPerson": "Yhteyshenkilö",
    "contracts.contractManager": "Sopimuksen hoitaja",
    "contracts.contractNumber": "Sopimusnumero",
    "contracts.contractType": "Tyyppi",
    "contracts.equipmentContracts": "TUOTESOPIMUKSET",
    "contracts.expertiseCenter": "Osaamiskeskus",
    "contracts.expiry": "Vanhenee",
    "contracts.installationsInScope": "Sopimuksen piirissä olevat installaatiot",
    "contracts.installationsInScope:energy": "Sopimuksen piirissä olevat voimalat",
    "contracts.installationsInScope:marine": "Sopimuksen piirissä olevat alukset",
    "contracts.invalidContract": "Epäkelpo sopimus",
    "contracts.maintenancePlannerName": "Ylläpidon hoitaja",
    "contracts.noContactPerson": "Ei yhteyshenkilöä",
    "contracts.noEquipmentContractsAvailable": "Ei sopimuksia tuotteille",
    "contracts.noInstallationContractsAvailable": "Ei sopimuksia installaatioille",
    "contracts.noInstallationContractsAvailable:energy": "Ei sopimuksia voimaloille",
    "contracts.noInstallationContractsAvailable:marine": "Ei sopimuksia aluksille",
    "contracts.productNumbers": "Tuotenumerot",
    "contracts.productReferenceTypes": "Tuoteviittaustyypit",
    "contracts.remoteSupporterName": "Etätuki",
    "contracts.startDate": "Aloituspäivämäärä",
    "contracts.technicalExperts": "Tekniset asiantuntijat",
    "contracts.validation.messageIsRequired": "Viesti vaaditaan",
    "contracts.validation.subjectIsRequired": "Otsikko vaaditaan",
  },
};
