import { filter, get, isEqual, map, set, sortBy } from "lodash";
import { IDelivery, IDeliveryLineItem, IInvoiceAttachment, INotification } from "online-services-types";
import * as React from "react";
import { LoadingSpinner, TableCellLoader } from "src/components/LoadingSpinner";

import { Container, FlexContainer } from "src/design-system/Container";
import { LinkWrapper } from "src/design-system/Link";
import { NotificationWrapper } from "src/design-system/Link/Link";
import colors from "src/design-system/Tokens/colors";
import { Disclaimer, IDisclaimerTheme } from "src/design-system/Tokens/typography";
import { IconSize, TriangleWarningIcon, TruckIcon } from "src/icons";
import { PurchaseStatus } from "src/services/commerce/commerce.purchase-status";
import { deliveryModeByCode, getTranslationIdFromDeliveryStatus, isDeliveryTrackingVisible } from "src/util/commerce";
import { translateString } from "src/util/localization";
import { IconWithTooltip } from "src/views/CreateFluidReportView/phases/IconWithTooltip";

import { AttachmentIconDownload } from "src/views/InvoicesView/AttachmentIconDownload";
import styled from "styled-components";
import { StyledHeader } from "../GenericList/ExpandableHeader";
import { LocalizedString } from "../Localization";
import { ResponsiveTable } from "../ResponsiveTable";
import { IResponsiveTableColumn } from "../ResponsiveTable/interfaces";
import { DeliveryAttachmentDownload } from "./DeliveryAttachmentDownload";

interface IResponsiveDeliveryTreeProps {
  customerId?: string;
  deliveries: IDelivery[];
  notifications: INotification<IDelivery>[];
  fetching?: boolean;
}

enum DeliverySource {
  PL = "PL",
  COC = "COC",
  IHM = "IHM",
  ROHS = "ROHS",
  REACH = "REACH",
  MSDS = "MSDS",
  DGR = "DGR",
}

enum DeliveryDocumentType {
  PACKING_LIST = "Packing list",
  PROFORMA_INVOICE = "Proforma invoice",
  IHM = "IHM document",
  REACH = "REACH document",
  ROHS = "ROHS document",
  DELIVERY_CERTIFICATE = "Declaration of Compliance",
  MSDS = "Material Safety Data Sheet",
  DGR = "DGR document",
  DELIVERY_CERTIFICATE_OLD = "Delivery certificate", // Type was renamed, old data may still depend on this name. Can be removed after some time.
}

export const FixedWidthContainer = styled(Container)<{ width?: number }>`
  width: ${(props) => props.width || 185}px;
`;

const ValueAndTooltipWrapper = styled.div`
  display: flex;
`;

export const LabelNew = styled.div`
  background: #d4f0e6;
  color: #1e7053;
  border-radius: 10px;
  padding: 0 5px 0 5px;
  display: inline-block;
  font-weight: bold;
`;

const renderLabelNew = () => <LabelNew>{translateString("new")}</LabelNew>;

const renderLabelNewIfNecessary = (availableDocumentTypes: { [key: string]: boolean }, documentType: string) => {
  return availableDocumentTypes[documentType] ? renderLabelNew() : null;
};

const columnsForDeliveryLineItem: IResponsiveTableColumn<IDeliveryLineItem>[] = [
  {
    priority: 10,
    plannedWidth: 200,
    alwaysShown: true,
    key: "lineNumber",
    valueRenderer: ({ value }) => value,
    label: translateString("spareParts.lineNumber"),
  },
  {
    priority: 10,
    plannedWidth: 250,
    alwaysShown: true,
    key: "materialNumberDescription",
    valueRenderer: ({ value }) => value,
    label: translateString("spareParts.partDescription"),
  },
  {
    priority: 10,
    plannedWidth: 150,
    key: "codeNumber",
    valueRenderer: ({ value, row }) => value || row.materialNumber,
    label: translateString("spareParts.partNumber"),
  },
  {
    priority: 10,
    plannedWidth: 150,
    key: "claimableQuantity",
    valueRenderer: ({ value }) => value,
    label: translateString("spareParts.quantity"),
  },
  // The ResponsiveTable always aligning the last column to the right side of the row
  // probably it is a bug and when it will be fixed this column can be deleted
  {
    priority: 1,
    key: "hidden",
    plannedWidth: 1,
    renderer: () => <div />,
  },
];

const columnsForDelivery = ({
  customerId,
  notifications,
  fetching,
}: {
  customerId?: string;
  notifications: INotification<IDelivery>[];
  fetching?: boolean;
}): IResponsiveTableColumn<IDelivery>[] => [
  {
    key: "id",
    priority: 2,
    staticWidth: true,
    plannedWidth: 180,
    valueRenderer: ({ value, row }) => (
      <ValueAndTooltipWrapper>
        <span>{value}</span>{" "}
        {row.isDangerous ? (
          <IconWithTooltip
            top="35%"
            width="150px"
            iconProps={{
              margin: "-1px 0 0 6rem",
              width: "25px",
            }}
            icon={<TriangleWarningIcon color="#ed731a" size={IconSize.Small} />}
          >
            <Container>
              <div>{translateString("spareParts.DGRDelivery")}</div>
            </Container>
          </IconWithTooltip>
        ) : (
          ""
        )}
      </ValueAndTooltipWrapper>
    ),
    label: translateString("spareParts.delivery"),
  },
  {
    key: "quantity",
    priority: 2,
    plannedWidth: 180,
    label: translateString("spareParts.lineItems"),
    valueRenderer: ({ row }) => (fetching ? <TableCellLoader /> : <span>{row.lineItems?.length}</span>),
  },

  {
    key: "weight",
    priority: 2,
    plannedWidth: 180,
    label: `${translateString("spareParts.partNetWeight")} (kg)`,
    hideEmptyValues: true,
    valueRenderer: ({ row }) => <span>{row.weight}</span>,
  },
  {
    key: "deliveryMode",
    priority: 2,
    plannedWidth: 250,
    label: translateString("spareParts.deliveryMode"),
    hideEmptyValues: true,
    valueRenderer: ({ row }) => <span>{get(deliveryModeByCode, row.deliveryMode || "", row.deliveryMode)}</span>,
  },
  {
    priority: 1,
    key: "trackTrace",
    alwaysShown: true,
    plannedWidth: 300,
    label: "",
    renderer: ({ row }) => {
      if (!isDeliveryTrackingVisible(row.deliveryPlant) && !row.trackAndTraceLink) return <div />;

      const relatedNotifications = notifications.filter((n) => n.data?.id === row.id);

      const href =
        row.trackAndTraceLink ||
        `https://wartsilaportal.cevalogistics.com/portal/wartsila/trackIdSearch.action?orderTypeSelection=1&CustomerNr=${customerId}&orderNr=${row.id}`;
      return (
        <FlexContainer $centered={true} $margin={[0, 4]}>
          <Container $margin={[0, 2, 0, 0]}>
            <TruckIcon color={colors.secondary.blue100} size={IconSize.Large} />
          </Container>
          <FlexContainer $column={true}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.status")}</Disclaimer>
            <FlexContainer $centered>
              <LinkWrapper>
                <a target="_blank" rel="noopener noreferrer" href={href}>
                  <LocalizedString id={getTranslationIdFromDeliveryStatus(row.displayStatus)} />
                </a>
              </LinkWrapper>
              {relatedNotifications.length ? (
                <NotificationWrapper $marginLeft={2} $noShrink $centered $justified>
                  {relatedNotifications.length}
                </NotificationWrapper>
              ) : null}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      );
    },
  },
  {
    label: "",
    priority: 2,
    key: "proformaInfo",
    plannedWidth: 200,
    alwaysHidden: true,
    renderer: ({ row }) => {
      const hidden =
        row.displayStatus !== PurchaseStatus.Collected &&
        row.displayStatus !== PurchaseStatus.Dispatched &&
        row.displayStatus !== PurchaseStatus.Delivered &&
        row.displayStatus !== PurchaseStatus.Packed &&
        row.displayStatus !== PurchaseStatus.ReadyToCollect;

      const dgrDisabled = true;

      if (hidden) return;
      let prioritizedProforma: IInvoiceAttachment | null = null;
      let prioritizedDeliveryNote: IInvoiceAttachment | null = null;
      if (row.invoice?.attachments.length) {
        const allowedDeliveryNotesTypes = ["ZPR0", "ZPR1"];
        const allowedProformaTypes = ["ZRD0", "ZRC0", "ZRP0", "ZRD1", "ZRC1", "ZRP1"];

        const allowedProformas = row.invoice.attachments.filter((attachment) =>
          allowedProformaTypes.includes(attachment.type)
        );

        const allowedDeliveryNotes = row.invoice.attachments.filter((attachment) =>
          allowedDeliveryNotesTypes.includes(attachment.type)
        );

        if (allowedProformas.length) {
          [prioritizedProforma] = sortBy(allowedProformas, (attachment) =>
            allowedProformaTypes.indexOf(attachment.type)
          );
        }

        if (allowedDeliveryNotes.length) {
          [prioritizedDeliveryNote] = sortBy(allowedDeliveryNotes, (attachment) =>
            allowedDeliveryNotesTypes.indexOf(attachment.type)
          );
        }
      }

      const relatedNotifications = notifications.filter((n) => n.data?.id === row.id);
      const notificationDocTypes = {};
      relatedNotifications.forEach((n) => {
        if (n.data.notificationDocumentType) {
          notificationDocTypes[n.data.notificationDocumentType] = true;
        }
      });

      return (
        <FlexContainer $margin={[4, 6, 6, 1]} $wrap>
          {prioritizedProforma && row.invoice ? (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.proformaDocument")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.PROFORMA_INVOICE)}
              </Disclaimer>
              <AttachmentIconDownload invoiceId={row.invoice.id} type={prioritizedProforma.type} />
            </FixedWidthContainer>
          ) : null}
          {row.isPacked && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.PL")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.PACKING_LIST)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.PL} />
            </FixedWidthContainer>
          )}
          {row.hasCOC && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.COC")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.DELIVERY_CERTIFICATE)}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.DELIVERY_CERTIFICATE_OLD)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.COC} />
            </FixedWidthContainer>
          )}
          {row.hasIHM && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.IHM")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.IHM)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.IHM} />
            </FixedWidthContainer>
          )}
          {row.hasREACH && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.REACH")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.REACH)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.REACH} />
            </FixedWidthContainer>
          )}
          {row.hasROHS && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.ROHS")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.ROHS)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.ROHS} />
            </FixedWidthContainer>
          )}
          {row.hasMSDS && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.MSDS")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.MSDS)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.MSDS} />
            </FixedWidthContainer>
          )}
          {row.hasDGR && !dgrDisabled && (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryDoc.DGR")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.DGR)}
              </Disclaimer>
              <DeliveryAttachmentDownload delivery={row.id} source={DeliverySource.DGR} />
            </FixedWidthContainer>
          )}
          {prioritizedDeliveryNote && row.invoice ? (
            <FixedWidthContainer $marginBottom={2}>
              <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
                {translateString("spareParts.deliveryNote")}{" "}
                {renderLabelNewIfNecessary(notificationDocTypes, DeliveryDocumentType.PROFORMA_INVOICE)}
              </Disclaimer>
              <AttachmentIconDownload invoiceId={row.invoice.id} type={prioritizedDeliveryNote.type} />
            </FixedWidthContainer>
          ) : null}
        </FlexContainer>
      );
    },
  },
  {
    label: "",
    priority: 1,
    key: "deliveryInfo",
    plannedWidth: 200,
    alwaysHidden: true,
    isEnabled: (row) =>
      row.shippingAddress !== undefined || row.consolidationPoint !== undefined || row.incoterms !== undefined,
    renderer: ({ row }) => (
      <FlexContainer $margin={[4, 6, 6, 1]} $wrap>
        <FixedWidthContainer $marginBottom={2}>
          <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.shippingAddress")}</Disclaimer>
          <div>{row.shippingAddress}</div>
        </FixedWidthContainer>
        {row.consolidationPoint ? (
          <FixedWidthContainer $marginBottom={2}>
            <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
              {translateString("spareParts.consolidationPoint")}
            </Disclaimer>
            <div>{row.consolidationPoint}</div>
          </FixedWidthContainer>
        ) : null}
        <FixedWidthContainer $marginBottom={2}>
          <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>{translateString("spareParts.incoterms")}</Disclaimer>
          <div>{row.incoterms}</div>
        </FixedWidthContainer>
      </FlexContainer>
    ),
  },
  // A delivery can include different equipment
  // We should group them by equipment id to render properly
  {
    label: "",
    priority: 1,
    key: "lineItems",
    plannedWidth: 200,
    alwaysHidden: true,
    hideEmptyValues: true,
    renderer: ({ row: delivery }) => {
      if (fetching) return <LoadingSpinner dark />;
      const equipmentIdsToNameMapping: Record<string, string> = {};
      const equipmentIdsInDelivery: string[] = Array.from(
        new Set(
          map(delivery.lineItems, (lineItem) => {
            set(equipmentIdsToNameMapping, lineItem.equipmentId, lineItem.equipmentText);
            return lineItem.equipmentId;
          })
        )
      );

      return (
        <>
          {map(equipmentIdsInDelivery, (equipmentId) => {
            const REGULAR_ITEM_PARENT = "000000";
            const lineItems = filter(delivery.lineItems, (lineItem) => isEqual(lineItem.equipmentId, equipmentId));
            const regularLineItems = lineItems.filter((lineItem) => lineItem.parentRow === REGULAR_ITEM_PARENT);
            const bomElements = lineItems.filter((lineItem) => lineItem.parentRow !== REGULAR_ITEM_PARENT);

            const renderSubList = () => {
              return (
                <ResponsiveTable<IDeliveryLineItem>
                  columns={columnsForDeliveryLineItem}
                  rows={bomElements}
                  isSubList={true}
                />
              );
            };

            const isSublistNeeded = (item: IDeliveryLineItem) => {
              if (item !== undefined && item.isBomHeader) {
                return renderSubList();
              }
              return null;
            };

            return (
              <Container $margin={[4, 6, 4, 0]}>
                <StyledHeader>{get(equipmentIdsToNameMapping, equipmentId)}</StyledHeader>
                <ResponsiveTable<IDeliveryLineItem>
                  columns={columnsForDeliveryLineItem}
                  rows={regularLineItems}
                  sublistRenderer={isSublistNeeded}
                />
              </Container>
            );
          })}
        </>
      );
    },
  },
];

export const ResponsiveDeliveryTree = (props: IResponsiveDeliveryTreeProps) => {
  return (
    <Container $margin={[2, 6, 4, 0]}>
      <ResponsiveTable<IDelivery>
        columns={columnsForDelivery({
          customerId: props.customerId,
          notifications: props.notifications,
          fetching: props.fetching,
        })}
        rows={props.deliveries}
      />
    </Container>
  );
};
