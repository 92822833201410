// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_pt_BR: ITranslations = {
  pt_BR: {
    "fluid.afterSeparator": "Após o separador",
    "fluid.analysisDate": "Data da análise",
    "fluid.analysisOf": "Análise de",
    "fluid.beforeSeparator": "Antes do separador",
    "fluid.bunkeringLocation": "Local de abastecimento (opcional)",
    "fluid.categoryISOF": "ISO-F da categoria",
    "fluid.commentsFromTheLaboratory": "Comentários do laboratório (opcional)",
    "fluid.common": "Comum",
    "fluid.coolingWater": "Água de resfriamento",
    "fluid.differentPerEngine": "Diferente para cada motor",
    "fluid.draft.continueEditing": "Continuar a edição",
    "fluid.draft.continueWithExistingDraft": "Continuar com o rascunho existente",
    "fluid.draft.createNewReport": "Criar novo relatório de fluidos",
    "fluid.draft.deleteDraft": "Excluir rascunho",
    "fluid.draft.deleted": "Rascunho excluído",
    "fluid.draft.deleting": "Excluindo rascunho...",
    "fluid.draft.deletingError": "Erro ao excluir rascunho",
    "fluid.draft.or": "OU",
    "fluid.draft.sending": "Salvando rascunho",
    "fluid.draft.sendingError": "Erro ao gravar o rascunho",
    "fluid.draft.sent": "Rascunho gravado",
    "fluid.engineLife": "Vida útil do motor",
    "fluid.engines": "Motores",
    "fluid.enginesSampleData": "Dados da amostra do motor",
    "fluid.field.acid_number_mg_koh_g__c": "Número do ácido",
    "fluid.field.aluminum_al_ppm__c": "Alumínio, AI",
    "fluid.field.aluminum_silicon_mg_kg__c": "Alumínio + Silicone",
    "fluid.field.ash_m_m__c": "Cinza",
    "fluid.field.asphaltenes_m_m__c": "Asfaltenos",
    "fluid.field.base_number_bn_mg_koh_g__c": "Número da base (BN)",
    "fluid.field.boron_b_ppm__c": "Boro, B",
    "fluid.field.calcium_ca_mg_kg__c": "Cálcio, Ca",
    "fluid.field.calcium_ca_ppm__c": "Cálcio, Ca",
    "fluid.field.carbon_residue_micro_method_m_m__c": "Resíduo de carbono, Micro Método",
    "fluid.field.ccai__c": "CCAI",
    "fluid.field.cetane_index__c": "Índice de cetano",
    "fluid.field.chlorides_cl_mg_l__c": "Cloretos, Cl",
    "fluid.field.chromium_cr_ppm__c": "Cromo, Cr",
    "fluid.field.copper_cu_mg_l__c": "Cobre, Cu",
    "fluid.field.copper_cu_ppm__c": "Cobre, Cu",
    "fluid.field.density_15_c_kg_m3__c": "Densidade a 15 °C",
    "fluid.field.flash_point_c__c": "Ponto de centelha",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "Sulfato de hidrogênio",
    "fluid.field.insolubes_w__c": "Insolúveis",
    "fluid.field.iron_fe_mg_kg__c": "Ferro, Fe",
    "fluid.field.iron_fe_mg_l__c": "Ferro, Fe",
    "fluid.field.iron_fe_ppm__c": "Ferro, Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "Viscosidade cinemática a 40 °C",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "Viscosidade cinemática a 50 °C",
    "fluid.field.lead_pb_mg_kg__c": "Chumbo, Pb",
    "fluid.field.lead_pb_ppm__c": "Chumbo, Pb",
    "fluid.field.lower_heating_value_mj_kg__c": "Valor de aquecimento baixo",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "Lubricidade, Diâmetro de desgaste corrigido (wsd 1.4) a 60 °C",
    "fluid.field.magnesium_mg_mg_kg__c": "Magnésio, Mg",
    "fluid.field.molybenum_mo_ppm__c": "Molibdênio, Mo",
    "fluid.field.nickel_ni_mg_kg__c": "Níquel, Ni",
    "fluid.field.nickel_ni_ppm__c": "Níquel, Ni",
    "fluid.field.nitrate_no3_mg_l__c": "Nitrato, NO₃",
    "fluid.field.nitration_abs_cm__c": "Nitração",
    "fluid.field.nitrite_no2_mg_l__c": "Nitrito, NO₂",
    "fluid.field.oxidation_abs_cm__c": "Oxidação",
    "fluid.field.oxidation_stability_g_m3__c": "Estabilidade da oxidação",
    "fluid.field.ph__c": "pH",
    "fluid.field.phosphorus_p_mg_kg__c": "Fósforo, P",
    "fluid.field.phosphorus_p_ppm__c": "Fósforo, P",
    "fluid.field.pour_point_c__c": "Ponto de fluidez",
    "fluid.field.silicon_si_ppm__c": "Silicone, Si",
    "fluid.field.sodium_na_mg_kg__c": "Sódio, Na",
    "fluid.field.sodium_na_ppm__c": "Sódio, Na",
    "fluid.field.sulphates_so4_mg_l__c": "Sulfatos, SO₄",
    "fluid.field.sulphur_m_m__c": "Enxofre",
    "fluid.field.tin_sn_ppm__c": "Estanho, Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "Número total de ácido (TAN)",
    "fluid.field.total_hardness_ca_mg_dh__c": "Dureza total (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "Sedimento total",
    "fluid.field.vanadium_v_mg_kg__c": "Vanádio, V",
    "fluid.field.vanadium_v_ppm__c": "Vanádio, V",
    "fluid.field.viscosity_100_c_cst__c": "Viscosidade a 100 °C",
    "fluid.field.viscosity_40_c_cst__c": "Viscosidade a 40 °C",
    "fluid.field.water_v_v__c": "Água",
    "fluid.field.water_vol_or_w__c": "Água",
    "fluid.field.zinc_zn_mg_kg__c": "Zinco, Zn",
    "fluid.field.zinc_zn_ppm__c": "Zinco, Zn",
    "fluid.fuel": "Combustível",
    "fluid.gas": "Gás",
    "fluid.general": "Geral",
    "fluid.generalInformation": "Informações gerais",
    "fluid.header.elemConcs": "Concentrações elementares",
    "fluid.header.fuelProps": "Propriedades do combustível",
    "fluid.header.oilProps": "Propriedades do óleo",
    "fluid.htCircuit": "Circuito HT",
    "fluid.inputValues": "Valores de entrada",
    "fluid.laboratoryReferenceNumber": "Número de referência do laboratório (opcional)",
    "fluid.lastData": "Dados mais atuais",
    "fluid.lowSulphurFuels": "Combustíveis com baixo teor de enxofre",
    "fluid.ltCircuit": "Circuito BT",
    "fluid.lubeOil": "Óleo de lubrificação",
    "fluid.lubricant": "Lubrificante",
    "fluid.notAvailable": "N/A",
    "fluid.oilAdded": "Óleo acrescentado",
    "fluid.oilLife": "Vida útil do óleo",
    "fluid.oldLubricantDisclaimer": "Esta seção contém os dados históricos de um lubrificante utilizado anteriormente",
    "fluid.operationOn": "Operação ativada",
    "fluid.phase.reportData": "Dados do relatório",
    "fluid.phase.sampleData": "Dados da amostra",
    "fluid.phase.scopeSelection": "Seleção do escopo",
    "fluid.phase.summary": "Resumo",
    "fluid.product": "Produto",
    "fluid.reportData": "Dados do relatório",
    "fluid.sameForAllEngines": "Igual para todos os motores",
    "fluid.sampleAggregation": "Agregação da amostra",
    "fluid.sampleDate": "Data da amostra",
    "fluid.samplePoint": "Ponto da amostra",
    "fluid.scopeOfReport": "Escopo do relatório",
    "fluid.sending": "Enviando o relatório de fluidos...",
    "fluid.sendingError": "Erro ao enviar o relatório de fluidos",
    "fluid.sent": "Relatório de fluidos enviado",
    "fluid.timeframe": "Prazo",
    "fluid.unit.abscm": "Abs/cm",
    "fluid.unit.cst": "cSt",
    "fluid.unit.degreec": "°C",
    "fluid.unit.degreedh": "°dH",
    "fluid.unit.gm3": "g/m³",
    "fluid.unit.kgm3": "kg/m³",
    "fluid.unit.mgkg": "mg/kg",
    "fluid.unit.mgkohg": "mg KOH / g",
    "fluid.unit.mgl": "mg/l",
    "fluid.unit.mjkg": "MJ/kg",
    "fluid.unit.mm2s": "mm²/s",
    "fluid.unit.pmm": "% m/m",
    "fluid.unit.ppm": "ppm",
    "fluid.unit.pvv": "% v/v",
    "fluid.unit.um": "µm",
    "fluid.unit.volporwp": "vol-% ou w-%",
    "fluid.unit.wp": "w-%",
    "fluid.unspecified": "Não especificado",
    "fluid.uptime": "Horas de operação",
    "fluid.uptime.availability": "Disponibilidade",
    "fluid.uptime.availabilityFormula": "(Funcionando + Espera) / (Funcionando + Espera + Parado + Desligado)",
    "fluid.uptime.availabilityLimits": "Objetivos de disponibilidade",
    "fluid.uptime.contractLimit": "Limite do contrato",
    "fluid.uptime.daily": "Diariamente",
    "fluid.uptime.engineAvailability": "Disponibilidade do motor",
    "fluid.uptime.limitMustBeNumber": "O limite deve ser um número!",
    "fluid.uptime.limitMustBeNumberBetween": "O limite deve ser um número entre {min} e {max}!",
    "fluid.uptime.limits": "Editar os valores fixados",
    "fluid.uptime.monthly": "Mensalmente",
    "fluid.uptime.noAvailability": "Não existem dados de disponibilidade para esse período.",
    "fluid.uptime.noData": "Sem dados",
    "fluid.uptime.noMaintenanceData": "Não existem dados de manutenção para esse período.",
    "fluid.uptime.noRunningHours": "Não existem dados de horas de operação para esse período.",
    "fluid.uptime.period": "Período",
    "fluid.uptime.report": "Relatório",
    "fluid.uptime.running": "Em marcha",
    "fluid.uptime.saveLimits": "Salvar",
    "fluid.uptime.shutDown": "Desligamento",
    "fluid.uptime.standby": "Espera",
    "fluid.uptime.stop": "Parada",
    "fluid.uptime.sum": "SOMA",
    "fluid.uptime.yearly": "Anualmente",
    "fluid.validation.analysisDateEarlierThanSampleDate": "A data da análise não pode ser uma data anterior à data da amostra",
    "fluid.validation.analysisDateInFuture": "A data da análise não pode ser uma data futura",
    "fluid.validation.analysisDateRequired": "A data da análise exigida",
    "fluid.validation.categoryISOFIsRequired": "É necessária categoria ISO-F",
    "fluid.validation.common": "Valor inesperado inserido (mín: {min}, max: {max}).",
    "fluid.validation.engineIsRequired": "O motor é exigido",
    "fluid.validation.engineLifeMustBeANumber": "A vida útil do motor precisa ser um número",
    "fluid.validation.fuelIsRequired": "O combustível é exigido",
    "fluid.validation.installationIsRequired": "A instalação é exigida",
    "fluid.validation.installationIsRequired:energy": "A usina de energia é exigida",
    "fluid.validation.installationIsRequired:marine": "A embarcação é exigida",
    "fluid.validation.lubricantIsRequired": "É necessário lubrificante",
    "fluid.validation.mustBeANumber": "{value} precisa ser um número.",
    "fluid.validation.oilAdedMustBeANumber": "O óleo acrescentado precisa ser um número",
    "fluid.validation.oilLifeMustBeANumber": "A vida útil do óleo precisa ser um número",
    "fluid.validation.ppm": "Valor inesperado inserido (mín: {min}, max: {max}). Conversão de % de valores para ppm: 1% = 10000 ppm.",
    "fluid.validation.sampleAggregationIsRequired": "A agregação da amostra é exigida",
    "fluid.validation.sampleDateInFuture": "A data da amostra não pode ser uma data futura",
    "fluid.validation.sampleDateRequired": "A data da amostra é exigida",
    "fluid.validation.samplePointIsRequired": "É necessário um ponto de amostra",
  },
};
