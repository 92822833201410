import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const SparePartClaimIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>SparePartClaim</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 9 4 H 28 L 40 16 V 44 H 9 V 38 V 4 Z M 34.25 16 L 28 9.75 V 16 H 34.25 Z M 36 22 V 40 H 13 V 38 V 8 H 24 V 20 H 36 V 22 Z M 18 28 H 22 V 24 H 26 V 28 H 30 V 32 H 26 V 36 H 22 V 32 H 18 V 28 Z"
    />
  </IconBase>
);
