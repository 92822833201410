import * as React from "react";
import { IconSize, WarningIcon } from "src/icons";
import styled from "styled-components";

// The icon is supposed to be shown on the left side of the content for the row without affecting the content
const RowNotificationIcon = styled(WarningIcon).attrs({ size: IconSize.Small })`
  position: absolute;
  margin-left: -28px;
`;

interface IRowNotificationProps {
  onUnmount: () => void;
}

export class RowNotificationComponent extends React.Component<IRowNotificationProps> {
  public componentWillUnmount() {
    this.props.onUnmount();
  }

  public render() {
    return <RowNotificationIcon />;
  }
}
