import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setInstallationVisibility } from "src/redux/actions";
import { APIResourceState, IAPIResourceState } from "src/redux/api";
import {
  IComponentProps,
  IDispatchProps,
  VisibleInstallationsPreferences,
} from "./VisibleInstallationsPreferencesComponent";

const mapStateToProps = (state: IAPIResourceState): IComponentProps => ({
  installations: state.installations,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps =>
  bindActionCreators(
    {
      getInstallations: APIResourceState.installations.get,
      setInstallationVisibility,
    },
    dispatch
  );

const VisibleInstallationsPreferencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VisibleInstallationsPreferences);
export { VisibleInstallationsPreferencesContainer };
