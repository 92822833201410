import map from "lodash/map";
import * as React from "react";
import styled from "styled-components";
import colors from "src/design-system/Tokens/colors";
import { spacer } from "src/design-system/Tokens/tokens";
import { fontSizes } from "src/design-system/Tokens/typography";

type Props<T> = {
  rows: T[];
  footers?: string[];
  footersColSpans?: number[];
  columns: string[];
  headers?: string[];
  headersColSpans?: number[];
  row: (value: T) => React.ReactNode;
};

// A simple table with a minimalistic interface
// can be a good replacement of ResponsiveTable
// in some cases
export const Table = <T,>(props: Props<T>) => (
  <TableContainer>
    <thead>
      {props.headers && props.headers.filter((h) => Boolean(h)).length ? (
        <tr>
          {map(props.headers, (header, idx) => (
            <th key={idx} colSpan={props.headersColSpans ? props.headersColSpans[idx] : 0}>{header}</th>
          ))}
        </tr>
      ) : null}

      <tr>
        {map(props.columns, (column, idx) => (
          <th key={idx}>{column}</th>
        ))}
      </tr>
    </thead>
    <tbody>{map(props.rows, props.row)}</tbody>
    {props.footers ? (
      <tfoot>
        {props.footers ? (
          <tr>
            {map(props.footers, (footer, idx) => (
              <td key={idx} colSpan={props.footersColSpans ? props.footersColSpans[idx] : 0}>{footer}</td>
            ))}
          </tr>
        ) : null}
      </tfoot>
    ) : null}
  </TableContainer>
);

const TableContainer = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: ${fontSizes.xs.min}px;

  td:first-child,
  th:first-child {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  td:first-child,
  th:first-child,
  td:last-child,
  th:last-child,
  tfoot td:first-child,
  tfoot td:last-child {
    border-right: 1.5px solid white;
  }

  td,
  th {
    font-weight: 400;
    padding: ${spacer(1)};
    border-right: 1px solid ${colors.secondary.bluegray80};
    border-bottom: 1px solid ${colors.secondary.bluegray80};
  }

  & tfoot td {
    border: none;
    border-bottom: 1px solid ${colors.secondary.bluegray80};
  }
`;
