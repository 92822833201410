// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fluid_zh_CN: ITranslations = {
  zh_CN: {
    "fluid.afterSeparator": "分离器后",
    "fluid.analysisDate": "分析日期",
    "fluid.analysisOf": "相关分析",
    "fluid.beforeSeparator": "分离器前",
    "fluid.bunkeringLocation": "加燃油位置（可选）",
    "fluid.categoryISOF": "ISO-F类",
    "fluid.commentsFromTheLaboratory": "来自实验室的注释（可选）",
    "fluid.common": "一般",
    "fluid.coolingWater": "冷却水",
    "fluid.differentPerEngine": "因发动机而异",
    "fluid.draft.continueEditing": "继续编辑",
    "fluid.draft.continueWithExistingDraft": "继续现有草稿",
    "fluid.draft.createNewReport": "创建新油液报告",
    "fluid.draft.deleteDraft": "删除草稿",
    "fluid.draft.deleted": "草稿已删除",
    "fluid.draft.deleting": "正在删除草稿...",
    "fluid.draft.deletingError": "删除草稿时错误",
    "fluid.draft.or": "或",
    "fluid.draft.sending": "正在保存草稿...",
    "fluid.draft.sendingError": "保存草稿时错误",
    "fluid.draft.sent": "草稿已保存",
    "fluid.engineLife": "发动机寿命",
    "fluid.engines": "发动机",
    "fluid.enginesSampleData": "发动机的样本数据",
    "fluid.field.acid_number_mg_koh_g__c": "酸值",
    "fluid.field.aluminum_al_ppm__c": "铝，Al",
    "fluid.field.aluminum_silicon_mg_kg__c": "铝 + 硅",
    "fluid.field.ash_m_m__c": "灰分",
    "fluid.field.asphaltenes_m_m__c": "沥青烯",
    "fluid.field.base_number_bn_mg_koh_g__c": "基值（BN）",
    "fluid.field.boron_b_ppm__c": "硼，B",
    "fluid.field.calcium_ca_mg_kg__c": "钙，Ca",
    "fluid.field.calcium_ca_ppm__c": "钙，Ca",
    "fluid.field.carbon_residue_micro_method_m_m__c": "残炭，微量法",
    "fluid.field.ccai__c": "CCAI",
    "fluid.field.cetane_index__c": "十六烷指数",
    "fluid.field.chlorides_cl_mg_l__c": "氯化物，Cl",
    "fluid.field.chromium_cr_ppm__c": "铬，Cr",
    "fluid.field.copper_cu_mg_l__c": "铜，Cu",
    "fluid.field.copper_cu_ppm__c": "铜，Cu",
    "fluid.field.density_15_c_kg_m3__c": "15 °C时的密度",
    "fluid.field.flash_point_c__c": "闪点",
    "fluid.field.hydrogen_sulfide_mg_kg__c": "硫化氢",
    "fluid.field.insolubes_w__c": "不溶解物质",
    "fluid.field.iron_fe_mg_kg__c": "铁，Fe",
    "fluid.field.iron_fe_mg_l__c": "铁，Fe",
    "fluid.field.iron_fe_ppm__c": "铁，Fe",
    "fluid.field.kinematic_viscosity_40_c_mm2_s__c": "40°C时的运动粘度",
    "fluid.field.kinematic_viscosity_50_c_mm2_s__c": "50°C时的运动粘度",
    "fluid.field.lead_pb_mg_kg__c": "铅，Pb",
    "fluid.field.lead_pb_ppm__c": "铅，Pb",
    "fluid.field.lower_heating_value_mj_kg__c": "热值较低",
    "fluid.field.lubricity_corrected_wear_scar_diameter__c": "润滑性，60°C时的校正磨斑直径 (wsd 1.4)",
    "fluid.field.magnesium_mg_mg_kg__c": "镁，Mg",
    "fluid.field.molybenum_mo_ppm__c": "钼，Mo",
    "fluid.field.nickel_ni_mg_kg__c": "镍，Ni",
    "fluid.field.nickel_ni_ppm__c": "镍，Ni",
    "fluid.field.nitrate_no3_mg_l__c": "硝酸盐，NO₃",
    "fluid.field.nitration_abs_cm__c": "硝化度",
    "fluid.field.nitrite_no2_mg_l__c": "亚硝酸盐，NO₂",
    "fluid.field.oxidation_abs_cm__c": "氧化度",
    "fluid.field.oxidation_stability_g_m3__c": "氧化稳定性",
    "fluid.field.ph__c": "pH",
    "fluid.field.phosphorus_p_mg_kg__c": "磷，P",
    "fluid.field.phosphorus_p_ppm__c": "磷，P",
    "fluid.field.pour_point_c__c": "倾点",
    "fluid.field.silicon_si_ppm__c": "硅，Si",
    "fluid.field.sodium_na_mg_kg__c": "钠，Na",
    "fluid.field.sodium_na_ppm__c": "钠，Na",
    "fluid.field.sulphates_so4_mg_l__c": "硫酸盐，SO₄",
    "fluid.field.sulphur_m_m__c": "硫磺",
    "fluid.field.tin_sn_ppm__c": "锡，Sn",
    "fluid.field.total_acid_number_tan_mg_koh_g__c": "总酸度 (TAN)",
    "fluid.field.total_hardness_ca_mg_dh__c": "总硬度 (Ca + Mg)",
    "fluid.field.total_sediment_m_m__c": "泥沙总量",
    "fluid.field.vanadium_v_mg_kg__c": "钒，V",
    "fluid.field.vanadium_v_ppm__c": "钒，V",
    "fluid.field.viscosity_100_c_cst__c": "100 °C时的粘度",
    "fluid.field.viscosity_40_c_cst__c": "40 °C时的粘度",
    "fluid.field.water_v_v__c": "水",
    "fluid.field.water_vol_or_w__c": "水",
    "fluid.field.zinc_zn_mg_kg__c": "锌，Zn",
    "fluid.field.zinc_zn_ppm__c": "锌，Zn",
    "fluid.fuel": "燃油",
    "fluid.gas": "气体",
    "fluid.general": "概述",
    "fluid.generalInformation": "一般信息",
    "fluid.header.elemConcs": "基本浓度",
    "fluid.header.fuelProps": "燃油特性",
    "fluid.header.oilProps": "机油特性",
    "fluid.htCircuit": "高温回路",
    "fluid.inputValues": "输入值",
    "fluid.laboratoryReferenceNumber": "实验室参考号（可选）",
    "fluid.lastData": "最近的数据",
    "fluid.lowSulphurFuels": "低硫燃油",
    "fluid.ltCircuit": "低温回路",
    "fluid.lubeOil": "润滑机油",
    "fluid.lubricant": "润滑剂",
    "fluid.notAvailable": "N/A",
    "fluid.oilAdded": "添加的机油",
    "fluid.oilLife": "机油寿命",
    "fluid.oldLubricantDisclaimer": "本部分包含之前用过的润滑剂的历史数据",
    "fluid.operationOn": "运行燃油",
    "fluid.phase.reportData": "报告数据",
    "fluid.phase.sampleData": "样本数据",
    "fluid.phase.scopeSelection": "范围选择",
    "fluid.phase.summary": "总结",
    "fluid.product": "产品",
    "fluid.reportData": "报告数据",
    "fluid.sameForAllEngines": "所有发动机相同",
    "fluid.sampleAggregation": "样本聚集",
    "fluid.sampleDate": "样本日期",
    "fluid.samplePoint": "样本点",
    "fluid.scopeOfReport": "报告范围",
    "fluid.sending": "正在提交油液报告...",
    "fluid.sendingError": "提交油液报告时错误",
    "fluid.sent": "油液报告已提交",
    "fluid.timeframe": "时间段",
    "fluid.unit.abscm": "Abs/cm",
    "fluid.unit.cst": "cSt（粘度单位）",
    "fluid.unit.degreec": "°C",
    "fluid.unit.degreedh": "&deg;dH",
    "fluid.unit.gm3": "g/m³",
    "fluid.unit.kgm3": "kg/m³",
    "fluid.unit.mgkg": "mg/kg",
    "fluid.unit.mgkohg": "mg KOH/g",
    "fluid.unit.mgl": "mg/l",
    "fluid.unit.mjkg": "MJ/kg",
    "fluid.unit.mm2s": "mm²/s",
    "fluid.unit.pmm": "% m/m",
    "fluid.unit.ppm": "ppm",
    "fluid.unit.pvv": "% v/v",
    "fluid.unit.um": "µm",
    "fluid.unit.volporwp": "体积百分数%或重量百分数%",
    "fluid.unit.wp": "重量百分数%",
    "fluid.unspecified": "未规定",
    "fluid.uptime": "运行小时数",
    "fluid.uptime.availability": "可用性",
    "fluid.uptime.availabilityFormula": "（运行 + 待机）/（运行＋待机＋停机 + 关停）",
    "fluid.uptime.availabilityLimits": "可用性目标",
    "fluid.uptime.contractLimit": "协议限值",
    "fluid.uptime.daily": "每日",
    "fluid.uptime.engineAvailability": "发动机可用性",
    "fluid.uptime.limitMustBeNumber": "限值必须是一个数字！",
    "fluid.uptime.limitMustBeNumberBetween": "限值必须是{min}和{max}之间的一个数字！",
    "fluid.uptime.limits": "编辑目标值",
    "fluid.uptime.monthly": "每月",
    "fluid.uptime.noAvailability": "这期间没有可用性数据。",
    "fluid.uptime.noData": "无数据",
    "fluid.uptime.noMaintenanceData": "这期间没有维护保养数据。",
    "fluid.uptime.noRunningHours": "这期间没有运行小时数据。",
    "fluid.uptime.period": "期间",
    "fluid.uptime.report": "报告",
    "fluid.uptime.running": "正在运行",
    "fluid.uptime.saveLimits": "保存",
    "fluid.uptime.shutDown": "停车",
    "fluid.uptime.standby": "备用",
    "fluid.uptime.stop": "停止",
    "fluid.uptime.sum": "总和",
    "fluid.uptime.yearly": "每年",
    "fluid.validation.analysisDateEarlierThanSampleDate": "分析日期不能比样本日期更早",
    "fluid.validation.analysisDateInFuture": "分析日期不能是未来日期",
    "fluid.validation.analysisDateRequired": "需要填写分析日期",
    "fluid.validation.categoryISOFIsRequired": "需要填写类别ISO-F",
    "fluid.validation.common": "输入了意外值 (min: {min}, max: {max})。",
    "fluid.validation.engineIsRequired": "需要填写发动机",
    "fluid.validation.engineLifeMustBeANumber": "发动机寿命必须是一个数字",
    "fluid.validation.fuelIsRequired": "需要填写燃油",
    "fluid.validation.installationIsRequired": "需要填写设施",
    "fluid.validation.installationIsRequired:energy": "需要填写发电厂",
    "fluid.validation.installationIsRequired:marine": "需要填写船舶",
    "fluid.validation.lubricantIsRequired": "需要填写润滑剂",
    "fluid.validation.mustBeANumber": "{value}必须是一个数字。",
    "fluid.validation.oilAdedMustBeANumber": "添加的机油必须是一个数字",
    "fluid.validation.oilLifeMustBeANumber": "机油寿命必须是一个数字",
    "fluid.validation.ppm": "输入了意外值 (min: {min}, max: {max})。%值转换成ppm：1% = 10000 ppm。",
    "fluid.validation.sampleAggregationIsRequired": "需要填写样本聚集",
    "fluid.validation.sampleDateInFuture": "样本日期不能是未来日期",
    "fluid.validation.sampleDateRequired": "需要填写样本日期",
    "fluid.validation.samplePointIsRequired": "需要填写样本点",
  },
};
