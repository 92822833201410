import { connect } from "react-redux";
import { IAppState } from "src/redux";
import {
  ILocalizedStringComponentOwnProps,
  ILocalizedStringComponentStateProps,
  LocalizedStringComponent,
} from "./LocalizedStringComponent";

const mapStateToProps = (state: IAppState): ILocalizedStringComponentStateProps => ({
  // This should ensure the component is rerendered on language change.
  // Please note that strings translated with translateString() (for props) will not magically change.
  languageCode: state.language.languageCode,
});

export const LocalizedStringContainer = connect<ILocalizedStringComponentStateProps, ILocalizedStringComponentOwnProps>(
  mapStateToProps
)(LocalizedStringComponent);
