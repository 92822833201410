export const departmentList = [
  "Designer",
  "Finance",
  "Legal",
  "Logistics",
  "Management",
  "Operations",
  "Other",
  "Procurement",
  "Production",
  "Quality Assurance",
  "Support",
  "Technical",
  "Automation",
  "Crewing",
  "Safety",
  "Insurance",
];
