import { IEquipment } from "online-services-types";
import React, { useCallback, useEffect, useState } from "react";
import { LocalizedString } from "src/components/Localization";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { translateString } from "src/util/localization";
import {
  Bar,
  BlockTable,
  calculateScaleObjectsForDevelopmentCharts,
  DataCell,
  DeltaToQB,
  IInstalledEquipment,
  ISFOCDataImprovements,
  ISFOCQuarter,
  NameDataCell,
  TitleWrapper,
  Unit,
  UnitSelector,
  UnitTdInFooter,
} from "src/util/sfoc/sfocCharts";
import { DashboardItem } from "../ManagerDashboardView/ManagerDashboardViewComponent";

export const QuarterlyDevelopmentChart = (props: {
  chartData: ISFOCDataImprovements[];
  quarter: string;
  installationId: string;
  equipments: IEquipment[];
}) => {
  const [installedEquipments, setInstalledEquipments] = useState<IInstalledEquipment[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<string>();
  const [dataUnit, setDataUnit] = useState<Unit>("%");

  useEffect(() => {
    const quarterData = props.chartData.filter((inst) => inst.installationId === props.installationId);
    //Filter equipment only for current installation.
    const installedEquip = props.equipments.filter((eq) => eq.installationId === props.installationId);

    //Find quarter measurement data for each equipment.
    props.equipments
      .filter((eq) => eq.installationId === props.installationId)
      .forEach((equip) => {
        let quarters = quarterData.find((qd) => qd.equipmentId === equip.id);
        if (quarters) {
          let currentQuarter = quarters.quarters.find((q) => q.quarter === "Q" + props.quarter);
          equip["currentQuarter"] = currentQuarter;
        }
        equip["quarterData"] = quarters;
      });

    //Sort equipment by nick name.
    installedEquip.sort((a, b) => (a.nickName > b.nickName ? 1 : -1));

    setInstalledEquipments(installedEquip as IInstalledEquipment[]);
  }, [props.quarter, props.chartData, props.installationId]);

  const selectUnit = useCallback(
    (selectedUnit: Unit) => {
      setDataUnit(selectedUnit);
    },
    [setDataUnit]
  );

  let rangeStart = 0;
  let rangeEnd = 0;

  const values: { [equipmentId: string]: number } = {};

  const field: keyof ISFOCQuarter = dataUnit === "%" ? "deltaToQBPercent" : "deltaToQBgPerkWh";
  installedEquipments.forEach((eq, i) => {
    const quarter = eq["currentQuarter"];
    if (quarter) {
      const value = quarter[field];
      rangeStart = Math.floor(Math.min(rangeStart === 0 ? value : rangeStart, value));
      rangeEnd = Math.ceil(Math.max(rangeEnd === 0 ? value : rangeEnd, value));
      values[eq.id] = value;
    }
  });

  const { scale, zeroPoint, valueMultiplier } = calculateScaleObjectsForDevelopmentCharts(rangeEnd, rangeStart);

  const average = installedEquipments.reduce((prev, curr) => prev + values[curr.id], 0) / installedEquipments.length;

  return (
    <DashboardItem
      title={
        <TitleWrapper>
          {" "}
          <LocalizedString id="sfoc.quarterlyDevelopment" values={{ quarter: props.quarter }} />
          <UnitSelector unit={dataUnit} onChange={selectUnit} />{" "}
        </TitleWrapper>
      }
    >
      {installedEquipments.length > 1 ? (
        <BlockTable>
          <tbody>
            <tr>
              <NameDataCell>{translateString("sfoc.engine")}</NameDataCell>
              {scale.map((_, index) => (
                <DataCell key={index} isBaseline={zeroPoint === index}>
                  {zeroPoint === index && (
                    <div
                      onMouseEnter={() => setVisibleTooltip("average")}
                      onMouseLeave={() => setVisibleTooltip(undefined)}
                    >
                      <Bar value={average * valueMultiplier} />
                      {visibleTooltip === "average" && <DeltaToQB value={average} unit={dataUnit} />}
                    </div>
                  )}
                </DataCell>
              ))}
            </tr>
            {installedEquipments.map((eq) => (
              <tr key={eq.id}>
                <NameDataCell>{eq.nickName || eq.description}</NameDataCell>
                {scale.map((_, index) => (
                  <DataCell key={index} isBaseline={zeroPoint === index}>
                    {zeroPoint === index && (
                      <div
                        onMouseEnter={() => setVisibleTooltip(eq.id)}
                        onMouseLeave={() => setVisibleTooltip(undefined)}
                      >
                        <Bar value={values[eq.id] * valueMultiplier} />
                        {visibleTooltip === eq.id && <DeltaToQB value={values[eq.id]} unit={dataUnit} />}
                      </div>
                    )}
                  </DataCell>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <UnitTdInFooter>{dataUnit}</UnitTdInFooter>
              </td>
              {scale}
            </tr>
          </tfoot>
        </BlockTable>
      ) : (
        <LocalizedStringComponent id={"sfoc.noData"} />
      )}
    </DashboardItem>
  );
};
