// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const onboarding_zh_CN: ITranslations = {
  zh_CN: {
    "documentsOnboarding.stepFive.content": "查看文档信息，查看与文档适用于什么设备有关的详细说明。",
    "documentsOnboarding.stepFive.title": "文档信息",
    "documentsOnboarding.stepFour.content": "单击文档页，轻松进入预览。",
    "documentsOnboarding.stepFour.content2": "使用的搜索词将在预览中高亮显示。",
    "documentsOnboarding.stepFour.title": "预览",
    "documentsOnboarding.stepOne.content": "在一个页面上轻松查看、搜索、筛选和排序您的所有文档。",
    "documentsOnboarding.stepOne.title": "您的文档",
    "documentsOnboarding.stepThree.content": "对您的查看内容排序，最相关的文档显示在最上面。",
    "documentsOnboarding.stepThree.title": "排序",
    "documentsOnboarding.stepTwo.content": "用不同搜索值查找您需要的。",
    "documentsOnboarding.stepTwo.content2": "您也可按文档类型筛选您的搜索结果。",
    "documentsOnboarding.stepTwo.title": "搜索并筛选",
    "mainOnboarding.stepFive.content": "感谢您与我们一起浏览快速指南。如果您有任何问题或者需要帮助，我们的客服随时恭候，为您提供帮助。我们希望您喜欢新环境。",
    "mainOnboarding.stepFive.title": "您可随时开始!",
    "mainOnboarding.stepFour.content": "所有可提供的服务列在下面。",
    "mainOnboarding.stepFour.title": "您的服务",
    "mainOnboarding.stepOne.content": "我们将通过快速指南让您了解基本功能。这将花费不到一分钟的时间。",
    "mainOnboarding.stepOne.title": "欢迎您体验全新的Wärtsilä Online!",
    "mainOnboarding.stepThree.content": "您从配置文件中选出的所有设施，可以在您的首页上轻易的查看。单击具体设施，您可找到与其相关的产品。",
    "mainOnboarding.stepThree.content:energy": "您从配置文件中选出的所有发电厂，可以在您的首页上轻易的查看。单击具体发电厂，您可找到与其相关的产品。",
    "mainOnboarding.stepThree.content:marine": "您从配置文件中选出的所有船舶，可以在您的首页上轻易的查看。单击具体船舶，您可找到与其相关的产品。",
    "mainOnboarding.stepThree.title": "您的设施",
    "mainOnboarding.stepThree.title:energy": "您的发电厂",
    "mainOnboarding.stepThree.title:marine": "您的船舶",
    "mainOnboarding.stepTwo.content": "您可从配置文件选择您要在首页看到的设施、更新您的基本信息、完成摘要电子邮件设置。",
    "mainOnboarding.stepTwo.content2": "任何时候您都可以从您的配置文件重新查看此快速指南。",
    "mainOnboarding.stepTwo.content:energy": "您可从配置文件选择您要在首页看到的发电厂、更新您的基本信息、完成摘要电子邮件设置。",
    "mainOnboarding.stepTwo.content:marine": "您可从配置文件选择您要在首页看到的船舶、更新您的基本信息、完成摘要电子邮件设置。",
    "mainOnboarding.stepTwo.title": "您的配置文件",
    "sparePartsOnboarding.stepAddPartsToCart.content": "您可通过单击 + 和 - 按钮或者输入数量来添加或删除购物中的物品并查看小计数量。",
    "sparePartsOnboarding.stepAddPartsToCart.title": "添加备件到购物车",
    "sparePartsOnboarding.stepBrowseParts.content": "选择产品，浏览备件。",
    "sparePartsOnboarding.stepBrowseParts.content2": "您可按备件名称或编号筛选备件。",
    "sparePartsOnboarding.stepBrowseParts.content3": "您也可一次以多个排列项手动添加备件。",
    "sparePartsOnboarding.stepBrowseParts.title": "浏览备件",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content": "下订单或者创建报价。选择设施，开始浏览您的备件。",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:energy": "下订单或者创建报价。选择发电厂，开始浏览您的备件。",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:marine": "下订单或者创建报价。选择船舶，开始浏览您的备件。",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.title": "新订单或报价",
    "sparePartsOnboarding.stepOrdersAndQuotations.content": "查看您的订单和报价的状态与详情。",
    "sparePartsOnboarding.stepOrdersAndQuotations.content2": "您可从该视图中根据报价轻松下订单。",
    "sparePartsOnboarding.stepOrdersAndQuotations.title": "订单和报价",
    "sparePartsOnboarding.stepPartDetails.content": "展开视图，查看备件细节。",
    "sparePartsOnboarding.stepPartDetails.content2": "您可从图中看到展开的备件图纸，显示更多细节。",
    "sparePartsOnboarding.stepPartDetails.content3": "您也可查找备件的价格、重量和交付周期。",
    "sparePartsOnboarding.stepPartDetails.title": "备件细节",
    "sparePartsOnboarding.stepShoppingCart.content": "您可查看您购物车中的选中备件及总价。",
    "sparePartsOnboarding.stepShoppingCart.title": "购物车",
    "sparePartsOnboarding.stepYourSpareParts.content": "您的所有订单和报价在一个页面上轻松概览。您可对您查看的内容进行筛选，查看完整历史记录。",
    "sparePartsOnboarding.stepYourSpareParts.content2": "浏览备件、创建新订单或报价或者创建备件索赔。",
    "sparePartsOnboarding.stepYourSpareParts.title": "商务页面",
    "supportOnboarding.stepFive.content": "您组织内的任何人员可添加与请求有关的注释，与Wärtsilä联系。",
    "supportOnboarding.stepFive.title": "注释",
    "supportOnboarding.stepFour.content": "处理请求的整个过程中可添加附件。",
    "supportOnboarding.stepFour.title": "附件",
    "supportOnboarding.stepOne.content": "您的所有请求在一个页面上轻松概览。您可对您查看的内容进行筛选或排序、查看请求状态和完整历史记录。",
    "supportOnboarding.stepOne.title": "您的支持页面",
    "supportOnboarding.stepSeven.content": "如要在一个文档中显示您的所有请求，您可轻松导出这些请求。",
    "supportOnboarding.stepSeven.title": "导出请求",
    "supportOnboarding.stepSix.content": "请求已完成时，您可评定并提供反馈，或者重新打开请求。",
    "supportOnboarding.stepSix.title": "评定维修",
    "supportOnboarding.stepThree.content": "展开以查看请求详情，提供额外信息",
    "supportOnboarding.stepThree.title": "您的请求",
    "supportOnboarding.stepTwo.content": "在一个位置创建您的所有请求：",
    "supportOnboarding.stepTwo.title": "创建新请求",
  },
};
