// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_en_US: ITranslations = {
  en_US: {
    "contracts.appliesTo": "Applies to",
    "contracts.attachmentDocumentTypes": "Documents ({types})",
    "contracts.attachmentImageTypes": "Images ({types})",
    "contracts.attachmentsMessage": "You can upload the following types of attachments with a maximum file size of {maxSize}.",
    "contracts.cbmExpertName": "Asset diagnostics",
    "contracts.contact": "Contact",
    "contracts.contactInformation": "Contact information",
    "contracts.contactPerson": "Contact person",
    "contracts.contractManager": "Contract manager",
    "contracts.contractNumber": "Agreement number",
    "contracts.contractType": "Type",
    "contracts.emailSupport": "Email support",
    "contracts.expertiseCenter": "Expertise center",
    "contracts.expiry": "Expiry date",
    "contracts.installationAndProductContracts": "Installation and product agreements",
    "contracts.installationAndProductContracts:energy": "Power plant and product agreements",
    "contracts.installationAndProductContracts:marine": "Vessel and product agreements",
    "contracts.invalidContract": "Invalid agreement",
    "contracts.maintenancePlannerName": "Maintenance planning",
    "contracts.noContactPerson": "No contact person",
    "contracts.noContractsAvailable": "No agreements available",
    "contracts.phoneNumber": "Phone number",
    "contracts.phonePINNumber": "Phone PIN number",
    "contracts.remoteOperationalSupportContactInformation": "Remote Operational Support Contact Information",
    "contracts.remoteSupporterName": "Remote supporter",
    "contracts.service": "Service",
    "contracts.showAll": "Show all",
    "contracts.showLess": "Show less",
    "contracts.startDate": "Start date",
    "contracts.technicalExperts": "Technical experts",
    "contracts.validation.messageIsRequired": "Message is required",
    "contracts.validation.subjectIsRequired": "Subject is required",
  },
};
