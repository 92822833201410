import * as React from "react";
import styled from "styled-components";

import { IIconBase } from "./IconBase";
import { ArrowLeftIcon } from "./ArrowLeftIcon";

const RightIcon = styled(ArrowLeftIcon)`
  transform: rotate(180deg);
`;

export const ArrowRightIcon = ({ ...props }: IIconBase) => <RightIcon {...props} />;
