import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { APIFetchStatus } from "src/APIFetch";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import {
  ISFOCVesselDashboardViewComponentDispatchProps,
  ISFOCVesselDashboardViewComponentStateProps,
  SFOCVesselDashboardViewComponent,
} from "./SFOCVesselDashboardViewComponent";

const mapToStateProps = (state: IAppState): ISFOCVesselDashboardViewComponentStateProps => ({
  equipments: state.equipments.data || [],
  isBusy: state.equipments.status === APIFetchStatus.Busy,
});

const mapToDispatchProps = (dispatch: Dispatch): ISFOCVesselDashboardViewComponentDispatchProps =>
  bindActionCreators(
    {
      getEquipments: APIResourceState.equipments.get,
    },
    dispatch
  );

export const SFOCVesselDashboardViewContainer = connect<
  ISFOCVesselDashboardViewComponentStateProps,
  ISFOCVesselDashboardViewComponentDispatchProps
>(
  mapToStateProps,
  mapToDispatchProps
)(SFOCVesselDashboardViewComponent);
export default SFOCVesselDashboardViewContainer;
