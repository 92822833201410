import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import { setSelectedInstallation } from "src/redux/user";
import {
  IIndexViewComponentDispatchProps,
  IIndexViewComponentStateProps,
  IndexViewComponent,
} from "./IndexViewComponent";
import { APIFetchStatus } from "src/APIFetch";

const mapStateToProps = (state: IAppState): IIndexViewComponentStateProps => {
  return {
    accounts: state.accounts.data || [],
    notifications: state.notifications.data || [],
    loadingNotifications: state.notifications.status === APIFetchStatus.Busy,
    selectedInstallationName: state.userInfo.selectedInstallationName,
    selectedInstallationId: state.userInfo.selectedInstallationId,
    visibleInstallations: state.userInfo.visibleInstallations,
    services: state.applicationContext.services,
    isEnergy: state.userInfo.mainSector === "Energy",
    isMarine: state.userInfo.mainSector === "Marine",
    isManager: state.userInfo.isManager,
    isDesigner: state.userInfo.isDesigner && state.userInfo.mainSector === "Marine",
    isShipyard: state.userInfo.isShipyard && state.userInfo.mainSector === "Marine",
    isEnergyConsultant: state.userInfo.isDesigner && state.userInfo.mainSector === "Energy",
    isQuantipartsDistributor: state.userInfo.isQPDistributor && !state.userInfo.isQPDistributorViewDisabled,
    installations: state.installations,
    fluidReportEvaluations: state.fluidReportEvaluations,
    equipments: state.equipments,
    uptimeEquipmentCharts: state.applicationContext.services.includes("Uptime")
      ? state.uptimeEquipmentCharts.data || []
      : [],
    isUptimeEquipmentChartsBusy: state.applicationContext.services.includes("Uptime")
      ? state.uptimeEquipmentCharts.status === APIFetchStatus.Busy
      : false,
    news: state.newsnew.data || [],
    isOnboardingFinished: state.onboarding.isOnboardingFinished,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInstallations: APIResourceState.installations.get,
      getFluidReportEvaluations: APIResourceState.fluidReportEvaluations.get,
      getEquipments: APIResourceState.equipments.get,
      getUptimeEquipmentCharts: APIResourceState.uptimeEquipmentCharts.get,
      setSelectedInstallation,
    },
    dispatch
  );

export const IndexViewContainer = connect<IIndexViewComponentStateProps, IIndexViewComponentDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(IndexViewComponent);
export default IndexViewContainer;
