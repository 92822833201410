import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const ShipCommonIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Ship Common Systems</title>
    <path d="M24.04 10.57l9.17 5.29v-3.93L29 9.5V5.98h-2v-2h-6.03v2h-2.01v3.54l-4.17 2.41v3.93l9.17-5.29h.08z" />
    <path d="M24 13l-14 8v4l8 20h12l8-20v-4zm0 28V17.58l10 5.77v.92L27.29 41z" />
  </IconBase>
);
