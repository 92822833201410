const colors = {
  // Primary colors
  primary: {
    black: "#000000",
    white: "#ffffff",
    blue: "#086795",
    gray: "#cccccc",
    orange: "#ff7321",
  },
  /* Seconday colors
     Based on Wärtsilä brand colors: blue, gray and orange.
     Numbering from 0 to 100 indicates lightness or darkness of the color.
     The smaller the number the lighter the color.
  */
  secondary: {
    blue80: "#07587f",
    blue90: "#064d70",
    blue100: "#0f344a",
    bluegray80: "#738592",
    bluegray90: "#335264",
    bluegray100: "#1f4256",
    gray0: "#f2f2f2",
    gray10: "#eeeeee",
    gray20: "#d9d9d9",
    gray30: "#c7c9cb",
    gray40: "#737473",
    gray50: "#a9abad",
    gray60: "#959798",
    gray70: "#a3a3a3",
    orange10: "#ffe6d7",
    orange90: "#d9621c",
    orange100: "#bf5619",
    yellow: "#FFC501",
  },
  // Notification status colors
  notification: {
    ok: "#25961c",
    warning: "#ffc400",
    error: "#dc3127",
  },
  // Fluid colors
  fluid: {
    red: "#ff7f7f",
    yellow: "#fff690",
    green: "#8be57f",
    blue: "#146e93",
  },
  // Uptime colors
  uptime: {
    running: "#16ce25",
    standby: "#b1ff57",
    stop: "#2f8fff",
    shutdown: "#ff0000",
    nodata: "#9f9f9f",
  },
  // SFOC colors
  sfoc: {
    red: "#eb1d1d",
    green: "#15b200",
  },
  // QUANTIPARTS colors
  quantiparts: {
    blue: "#128abe",
    midBlue: "#0a84bc",
    darkBlue: "#007bb6",
    list: "#019FFE",
    wizardPhase: "#d6d6d6",
  },
  // Graph colors
  graphnode: {
    blue: "#066694",
    pink: "#f3a9cb",
    purple: "#b84db8",
    beige: "#e3c9b7",
    yellow: "#dddc02",
    lightblue: "#4f81bc",
    lightgreen: "#bce3e6",
    lightgray: "#eef0f2",
    gray: "#838383",
  },
  // Transparent colors
  transparent: {
    black: "rgba(0, 0, 0, 0.3)",
    blue: "rgba(44, 101, 144, 0.5)",
    bluegray: "rgba(185, 207, 221, 0.3)",
    white: "rgba(255, 255, 255, 0.33)",
    full: "transparent",
  },
};

export default colors;
