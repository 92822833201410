export const inputTokens = {
  inputDropdownWidth: "17.9em",
  inputToolbarWidth: "11.25em",
  inputWidth: "17.9rem",
  inputNarrowwidth: "5rem",
  inputTextAreaWidth: "24rem",
  pageMargin: "0",
};

/*Baseline is 6px 
  Multipliers of this should always be used when working 
  with paddings or margins
  NEVER use spacingTokens directly for values: margin: ${spacingTokens.spacer} ${spacingTokens.unit};
  ALWAYS use spacer or spacers for values: margin: ${spacer(1)}; or margin: ${spacers([1, 2])};
*/
export const spacingTokens = {
  spacer: 6,
  unit: "px",
};

export const spacer = (multiplier = 1): string => {
  return spacingTokens.spacer * multiplier + spacingTokens.unit;
};

export const spacers = (multipliers: number[]): string => {
  return multipliers.map((multiplier) => spacer(multiplier)).join(" ");
};

export const spacings = Array.from(new Array(11), (x, i) => spacingTokens.spacer * i);

export const defaultBorderRadius = `${spacer(0.5)}`;

export const defaultBoxShadow = `2px 2px 6px rgba(0, 0, 0, 0.2)`;

export const defaultBoxBorder = `1px solid rgba(0, 0, 0, 0.3)`;

/* usage:
  With static colors

  border-top: ${thinBorder`${colors.secondary.blue100}`};

  With Themed colors 
  
  ${(props: ThemedProps) =>
    `border-left: ${thinBorder`${props.theme.foreground}`}`
  };


*/

export function thinBorder(template: TemplateStringsArray, color: string): string {
  return `1px solid ${color}`;
}
