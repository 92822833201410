// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const energy_en_US: ITranslations = {
  en_US: {
    "energy.EULA": "The information contained in the Wärtsilä Online portal is confidential.  On registration, you will be given a username and password to access the confidential information. Your credentials and the confidential information may only be accessed by you and not shared, even within your own company.  If additional users wish to use the confidential information, they must complete their own registration.",
    "energy.contactExpert": "Contact Expert",
    "energy.contactExpert.sending": "Sending contact request...",
    "energy.contactExpert.sendingError": "Error while sending contact request",
    "energy.contactExpert.sent": "Contact request sent",
    "energy.engineLeaflets": "Engine Leaflets",
    "energy.general": "General",
    "energy.message": "Message",
    "energy.noDocumentsFound": "No documents found",
    "energy.performance": "Performance",
    "energy.plantLayouts": "Plant Layouts",
    "energy.plantPerformance": "Plant Performance",
    "energy.plantPrices": "Plant Prices",
    "energy.powerPlants": "Power Plants",
    "energy.pricingIndications": "Pricing Indications",
    "energy.searchResults": "Search results ({number})",
    "energy.solutions": "Solutions",
    "energy.storageAndOptimization": "Energy Storage & Optimization",
    "energy.wartsilaEnergyProductPortfolio": "Wärtsilä Energy Solutions Portfolio",
    "energy.wartsilaMarineProductPortfolio": "Wärtsilä Marine Product Portfolio",
  },
};
