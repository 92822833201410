// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_ru: ITranslations = {
  ru: {
    "request.addContact": "Добавить контакт",
    "request.addNewContact": "Добавить новый контакт",
    "request.addNewUser": "Добавить пользователя",
    "request.addressLine1": "Строка адреса 1",
    "request.addressLine2": "Строка адреса 2",
    "request.attachment.Box": "Коробка",
    "request.attachment.Box or packing material": "Коробка или упаковочный материал",
    "request.attachment.Claimed part": "Заявленная в рекламации деталь",
    "request.attachment.Content of the box(es) and damaged parts": "Содержимое коробки (коробок) и поврежденные детали",
    "request.attachment.Corroded part": "Корродированная деталь",
    "request.attachment.Damage on part": "Повреждение детали",
    "request.attachment.Entire part": "Деталь полностью",
    "request.attachment.Markings and / or labels on the part": "Маркировка и / или этикетки на детали",
    "request.attachment.Markings on the claimed part": "Маркировка на заявленной в рекламации детали",
    "request.attachment.Markings on the part": "Маркировка на детали",
    "request.attachment.Markings on the received part": "Маркировка на полученной детали",
    "request.attachment.Markings on the required / correct part": "Маркировка на требуемой / правильной детали",
    "request.attachment.Other": "Прочее",
    "request.attachment.Packing material": "Упаковочный материал",
    "request.attachment.Packing, incl. labels": "Упаковка, включая этикетки",
    "request.attachment.Pictures of leaking parts": "Фотографии подтекающих деталей",
    "request.attachment.Received part": "Полученная деталь",
    "request.attachment.Required / correct part": "Требуемая / правильная деталь",
    "request.attachment.Required part": "Требуемая деталь",
    "request.attachment.Test reports": "Отчеты об испытаниях",
    "request.attachmentDescription": "Предоставьте обзорные фотографии и изображения крупным планом.",
    "request.attachmentDialog.Confirm": "Снимите",
    "request.attachmentDialog.Text": "Вы действительно хотите удалить приложение?",
    "request.attachmentDialog.Title": "Удалить приложение",
    "request.attachments": "Приложения",
    "request.auxAttachmentDescription": "Предоставьте обзорные фотографии, изображения крупным планом и фотографии регистрационного знака изделия.",
    "request.backgroundInformation": "Вводная информация",
    "request.beforeClaiming": "Перед тем как подавать рекламацию об отсутствующих деталях, обязательно выполните следующие проверки:",
    "request.beforeClaiming.checkBottom": "3. В деревянном решетчатом ящике на дне иногда бывает деревянный лист, который могут воспринимать как пол/дно, и могут помещать детали под этот лист.",
    "request.beforeClaiming.checkPackage": "1. Дважды проверьте посылку/коробку/деревянный решетчатый ящик, в котором прибыла поставка.",
    "request.beforeClaiming.checkPaddings": "2. Проверьте набивочный материал и упаковочный материал.",
    "request.chooseReferenceRequest": "Выбрать запрос со ссылкой",
    "request.city": "Город",
    "request.claimAllItems": "Создать рекламацию на поставку, указав во всех строках одну и ту же причину / проблему",
    "request.claimBasketText": "{basketItems} товарных позиций",
    "request.claimDescription": "Краткое описание рекламации",
    "request.claimItem": "Создать рекламацию на товар",
    "request.claimOrder": "Создать рекламацию на заказ",
    "request.claimQuantity": "Заявленное в рекламации количество",
    "request.claimReason": "Причина рекламации",
    "request.claimReasonChangeDislaimer": "Имейте в виду, что при изменении причины рекламации ответы по конкретному товару будут утеряны.",
    "request.claimReasons": "Причины рекламации",
    "request.claimReference": "Создать ссылку на рекламацию",
    "request.claimedItems": "Заявленные в рекламации товары",
    "request.claimedOrder": "Заказы, заявленные в рекламации",
    "request.comment": "Примечание",
    "request.companyName": "Название компании",
    "request.contactInformation": "Контактная информация",
    "request.contactInformationDescription": "Вы являетесь первичным контактным лицом по этому запросу, но можете добавить дополнительных заинтересованных людей, которые будет извещаться об актуальных изменениях по данному запросу.",
    "request.couldntFindDelivery": "Не удается найти поставку!",
    "request.country": "Страна",
    "request.createdBy": "Создано",
    "request.creationDate": "Дата создания",
    "request.customerClaimReference": "Ссылка на рекламацию заказчика",
    "request.customerClaimReferenceOptional": "Ссылка на рекламацию заказчика (как опция)",
    "request.customerInformation": "Информация о заказчике",
    "request.daAddressLine1IsRequired": "Требуется адрес!",
    "request.daCityIsRequired": "Требуется город!",
    "request.daCompanyNameIsRequired": "Требуется название компании!",
    "request.daCountryIsRequired": "Требуется страна!",
    "request.daPostalCodeIsRequired": "Требуется почтовый индекс!",
    "request.dateOfOccurrence": "Дата случившегося",
    "request.deliveryAddress": "Адрес доставки",
    "request.deliveryExpected": "Поставка ожидалась",
    "request.deliveryNumber": "Номер поставки",
    "request.deliveryReceived": "Поставка получена",
    "request.describeLeakageNoticed": "Когда и как обнаружена течь",
    "request.description": "Описание",
    "request.descriptionIsRequired": "Требуется описание!",
    "request.descriptionLong": "Чем подробнее информацию мы получим при регистрации, тем быстрее сможем обработать запрос",
    "request.deselect": "Снимите",
    "request.detailedDescription": "Подробное описание",
    "request.detailedReason": "Подробная причина",
    "request.discardingDraft": "Изъятие черновика...",
    "request.draftDiscarded": "Черновик изъят.",
    "request.email": "Электронная почта",
    "request.emailIsRequired": "Требуется электронная почта",
    "request.equipmentIsRequired": "Требуется изделие",
    "request.errorWhileDiscardingDraft": "Ошибка при изъятии черновика: {error}",
    "request.errorWhileFetchingDelivery": "Ошибка при выборке поставки: {error}",
    "request.exportAll": "Экспортировать все",
    "request.external": "Внешний",
    "request.firstName": "Имя",
    "request.firstnameIsRequired": "Требуется имя",
    "request.generalInformation": "Общая информация",
    "request.hadBoxBeenOpened": "Открывал ли кто-либо коробку/решетчатый ящик до того, как вы его получили (например, таможенный перевозчик)?",
    "request.hadBoxBeenOpenedBefore": "Открывал ли кто-либо коробку/решетчатый ящик до того, как был получен грузополучателем?",
    "request.haveNotifiedFreightForwarder": "Вы уже направляли рекламацию или извещение экспедитору?",
    "request.haveNotifiedInsuranceCompany": "Вы уже направляли рекламацию или извещение страховой компании?",
    "request.howTheReasonWasIdentified": "Как и при каких обстоятельствах выявлена проблема?",
    "request.howTheReasonWasIdentifiedOptional": "Как и при каких обстоятельствах выявлена проблема? (как опция)",
    "request.ifYouWantToCommentText": "Если хотите продолжить добавление примечаний, возобновите запрос",
    "request.installationIsRequired": "Требуется установка",
    "request.interestedPeople": "Заинтересованные люди",
    "request.isInWarrantyUntil": "Период гарантии активен до {date}",
    "request.item": "Позиция",
    "request.itemCount": "Счетчик товаров",
    "request.keptPartCondition": "В каких условиях хранилась заявленная в рекламации деталь после получения?",
    "request.lastModified": "Последнее изменение",
    "request.lastName": "Фамилия",
    "request.lastnameIsRequired": "Требуется фамилия",
    "request.multipleItems": "Несколько товаров ({count})",
    "request.newSparePartsClaim": "Рекламация на новые запасные части",
    "request.noRequestsFound": "Запросы не найдены.",
    "request.nonTechnical": "Служба поддержки заказчика",
    "request.notification.completedSparePartClaim": "Рекламация на запасную часть завершена",
    "request.notification.newAttachmentAdded": "Добавлено {link}",
    "request.notification.newAttachmentAdded.linkText": "новое приложение",
    "request.notification.newRequest": "Новый запрос создан",
    "request.notification.newSparePartClaim": "Создана новая рекламация на запасную часть",
    "request.notification.solutionPlanIsProvided": "Предоставлен план решений",
    "request.numberDoesNotMatchAccount": "Введенный номер документа не соответствует подробным данным о вашей учетной записи. Перепроверьте и повторите попытку или свяжитесь с компанией Wärtsilä.",
    "request.openFullRequest": "Открыть полностью запрос",
    "request.openRequests": "Запросы",
    "request.optional": "(По выбору)",
    "request.orderInformation": "Информация о заказе",
    "request.orderNumber": "Номер заказа компании Wärtsilä",
    "request.part": "Часть",
    "request.phone": "Телефон",
    "request.pleaseAttachDocuments": "Оправдательные документы",
    "request.pleaseEnterNumber": "Введите номер документа.",
    "request.poNumber": "Ваш номер заказа на поставку",
    "request.possibleActionsAlreadyTaken": "Уже выполненные действия",
    "request.possibleActionsAlreadyTakenOptional": "Уже выполненные действия (как опция)",
    "request.postalCode": "Почтовый индекс",
    "request.productReferenceType": "Тип ссылочного номера изделия",
    "request.provideDetailedDescription": "Подробное описание проблемы",
    "request.provideDocumentNameReferenceNumber": "Название документа и ссылочный номер документа о рекламационной поставке",
    "request.provideMeasurementsClaimed": "Измерения заявленной в рекламации детали",
    "request.provideMeasurementsCorrect": "Измерения требуемой / правильной детали (деталей).",
    "request.provideMeasurementsReceived": "Измерения полученной детали (деталей).",
    "request.provideMeasurementsRequired": "Измерения требуемой (правильной) детали",
    "request.provideTypeSerialNumber": "В случае со вспомогательным оборудованием, предоставьте тип и серийный номер узла и компонента.",
    "request.quantity": "Количество",
    "request.rating": "Оценка",
    "request.rating.approveLabel": "Вы удовлетворены обслуживанием?",
    "request.rating.customerServiceRegards": "С наилучшими пожеланиями,\n\nКомпания Wärtsilä",
    "request.rating.customerServiceThankYou": "Благодарим вас,\n\nКомпания Wärtsilä",
    "request.rating.feedbackGiven": "Отзыв предоставлен",
    "request.rating.feedbackSent": "Отзыв отправлен",
    "request.rating.notSatisfied": "Не удовлетворен(а)",
    "request.rating.offlineCouldNotLoadTicket": "Не удается загрузить запрос для отзыва в автономном режиме.",
    "request.rating.offlineRequestRatedAlready": "Запрос уже оценен",
    "request.rating.offlineRequestRatedAlreadyMessage": "Запрос \"{requestTitle}\" уже оценен, но вы все еще можете оставить отзыв.",
    "request.rating.offlineRequestRatedSuccessfully": "Запрос успешно оценен",
    "request.rating.offlineRequestRatedSuccessfullyMessage": "Благодарим вас за оценку \"{requestTitle}\".",
    "request.rating.offlineSendFeedback": "Отправить отзыв",
    "request.rating.offlineSendingFeedback": "Отправление отзыва...",
    "request.rating.offlineSendingFeedbackFailed": "Отправление отзыва не выполнено.",
    "request.rating.offlineSendingReopenRequest": "Отправление возобновленного запроса...",
    "request.rating.offlineSendingReopenRequestFailed": "Отправление возобновленного запроса не выполнено.",
    "request.rating.openFeedback": "Предоставьте отзыв, чтобы улучшить наше обслуживание",
    "request.rating.openFeedbackPlaceholder": "Вы также можете оставить открытый отзыв...",
    "request.rating.openFeedbackToImproveOurService": "Предоставьте отзыв, чтобы улучшить наше обслуживание",
    "request.rating.reasonForReopening": "Причина для возобновления",
    "request.rating.reasonIsRequired": "Требуется причина для возобновления.",
    "request.rating.reasonPlaceholder": "Ввести причину...",
    "request.rating.reopenButtonText": "Возобновить",
    "request.rating.reopenDialogTitle": "Возобновить запрос",
    "request.rating.reopenRequest": "Возобновить запрос",
    "request.rating.requestIsClosed": "Запрос закрыт",
    "request.rating.requestIsClosedNewRequestLinkText": "новый запрос",
    "request.rating.requestIsClosedRatingTitle": "Запрос закрыт и больше не может быть оценен.",
    "request.rating.requestIsClosedTitle": "Запрос закрыт и больше не может быть возобновлен.\nСоздайте {newRequestLink}.",
    "request.rating.requestReopened": "Запрос возобновлен",
    "request.rating.requestReopenedText": "Запрос возобновлен, и мы свяжемся в вами как можно скорее.",
    "request.rating.satisfied": "Удовлетворен(а)",
    "request.rating.sendFeedback": "Отправьте",
    "request.rating.suggestionIsRequired": "Требуется отзыв.",
    "request.rating.thankYouForYourFeedback": "Благодарим вас за предоставление отзыва",
    "request.rating.thankYouForYourFeedbackText": "Мы постоянно ищем способы улучшить обслуживание наших заказчиков, и ваш отзыв представляет для нас большую ценность.",
    "request.rating.weHaveAlreadyReceivedFeedback": "Мы уже получили отзыв на этот запрос. Отзывы больше не принимаются.",
    "request.rating.weWillRedirectYou": "Теперь мы направим вас {link}",
    "request.rating.whatCanWeDoBetter": "Ввести отзыв...",
    "request.reason": "Причина",
    "request.reason.9999": "Неудовлетворительное качество детали",
    "request.reason.DD13": "Документация на поставку и счет-фактура",
    "request.reason.DD16": "Неправильное количество деталей / недопоставка",
    "request.reason.DD18": "Неправильная деталь",
    "request.reason.DD22": "Поставка с опозданием",
    "request.reason.DD23": "Потерянный груз (поставка полностью утеряна)",
    "request.reason.DD24": "Неудовлетворительная упаковка / поставка повреждена",
    "request.reason.QD02": "Деталь подтекает",
    "request.reason.QD03": "Деталь не функционирует",
    "request.reason.QD07": "Деталь со следами коррозии",
    "request.reason.QD08": "Деталь повреждена",
    "request.reason.QD09": "Деталь не подходит",
    "request.reason.QD12": "Проблема классификации детали",
    "request.reason.QD14": "Маркировка деталей / проблема с этикетками",
    "request.recentlyOpened": "Недавно открытые запросы",
    "request.requestId": "Идентификатор запроса",
    "request.requestType": "Тип запроса",
    "request.requestTypeIsRequired": "Выберите тип запроса",
    "request.runningHours": "Часы наработки",
    "request.runningHoursDate": "Дата обновления",
    "request.runningHoursDateInFuture": "Дата наработки в часах не может быть в будущем!",
    "request.runningHoursMustBeBetween": "Наработка в часах должна быть числом от 0 до 9 999 999 999!",
    "request.runningHoursOptional": "Наработка в часах (как опция)",
    "request.selectAddress": "Выбрать адрес",
    "request.selectCategory": "Выбрать категорию...",
    "request.selectClaimReason": "Выбрать причину...",
    "request.selectClaimedItems": "Выбрать заявленный в рекламации товар(ы)",
    "request.solution": "Решение",
    "request.solutionPlan": "План решений",
    "request.solutionPlanProvided": "План решений предоставлен",
    "request.solutionText": "Решение компании Wärtsilä",
    "request.sparePartsClaim": "Рекламация на запасную часть",
    "request.subject": "Предмет",
    "request.subjectIsRequired": "Требуется предмет",
    "request.suggestedPartsText": "Если уже известны подлежащие замене детали, то добавьте их в запрос.",
    "request.suggestedSpareParts": "Предлагаемые запасные части",
    "request.suggestionToReplaceParts": "Предложение по замене деталей",
    "request.summary": "Краткое описание",
    "request.technical": "Техническая поддержка или гарантийный запрос",
    "request.title": "Название",
    "request.titleIsRequired": "Требуется форма обращения",
    "request.track": "Отследить",
    "request.typePartNameOrNumber": "Наименование детали или номер",
    "request.types.allRequests": "Все запросы",
    "request.uploadsAreNotFinished": "Выгрузки не закончены!",
    "request.validation.deliveryIsRequired": "Требуется поставка!",
    "request.validation.detailedReasonIsRequired": "Требуется подробная причина!",
    "request.validation.fieldIsRequired": "Обязательное поле!",
    "request.validation.itemIsRequired": "Требуется товар!",
    "request.validation.quantityIsRequired": "Требуется заявленное в рекламации количество!",
    "request.validation.quantityMustBeNumberBetween": "Введено недействительное количество! (мин.: {min}, макс.: {max})",
    "request.validation.quantityMustBeNumeric": "Заявленное в рекламации количество должно быть числом!",
    "request.validation.reasonIsRequired": "Требуется причина!",
    "request.wartsilaInitiated": "Инициировано компанией Wärtsilä",
    "request.wasBoxVisiblyDamaged": "Если детали внутри посылки повреждены, имели ли коробка или решетчатый ящик видимое повреждение?",
    "request.wasForwarderInformed": "Если коробка или решетчатый ящик имели видимое повреждение при приемке, был ли информирован экспедитор в момент поставки?",
    "request.wasOutsideOfBoxDamaged": "Повреждены ли коробка или решетчатый ящик снаружи?",
    "request.wasPackagingMaterialDamaged": "Поврежден ли упаковочный материал внутри коробки?",
    "request.wasPackingMaterialDamagedInsufficient": "Поврежден ли упаковочный материал внутри коробки или его недостаточно?",
    "request.wasPartInUseLeakage": "Использовалась ли деталь, когда была обнаружена течь? Если да, то сколько рабочих часов?",
    "request.wasPartInUseProblem": "Использовалась ли деталь, когда была обнаружена проблема? Если да, то сколько рабочих часов?",
    "request.wasPartInspectedByWartsila": "Если деталь использовалась, то осматривалась ли и устанавливалась деталь персоналом компании Wärtsilä?",
    "request.whenCorrosionNoticed": "Когда обнаружена коррозия?",
    "request.whenDeliveryExpected": "Когда ожидалась поставка?",
    "request.whenDeliveryReceived": "Когда была получена поставка?",
    "request.whenWasDamageNoticed": "Когда обнаружено повреждение",
    "request.yourClaimReference": "Ссылка на вашу рекламацию",
    "requestStatus.answered": "Завершено",
    "requestStatus.approved": "Утверждено",
    "requestStatus.closed": "Закрыт",
    "requestStatus.closedChangedToProject": "Закрыт - Изменение в проекте",
    "requestStatus.draft": "Эскиз",
    "requestStatus.inProgress": "Не завершено",
    "requestStatus.partsInDelivery": "Детали в поставке",
    "requestStatus.rejected": "Отклонен",
    "requestStatus.waitingForCustomerActions": "Ожидание действий заказчика",
  },
};
