import * as React from "react";

import { push as Menu, State } from "react-burger-menu";

import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { fixedFontSizes } from "src/design-system/Tokens/typography";
import styled from "styled-components";

const StyledMenu = styled.div`
  /* Position and sizing of burger button */
  & .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  & .bm-burger-bars {
    background: ${colors.primary.white};
  }

  /* Position and sizing of clickable cross button */
  & .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  & .bm-cross {
    background: ${colors.primary.white};
  }

  /* General sidebar styles */
  & .bm-menu {
    background: ${(props: ThemedProps) => props.theme.sideMenu.background};
    padding: 1em 2em 0;
    font-size: ${fixedFontSizes.baseFontSizeMedium}px;
  }

  & .bm-menu-wrap {
    z-index: 90 !important;
  }

  /* Wrapper for item list */
  & .bm-item-list {
    & .menu-item {
      padding-top: 0.8em;
    }
  }

  & .bm-item {
    outline: none;
  }

  /* Styling of overlay */
  & .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`;

interface ISideMenuProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  menuIcon?: JSX.Element | false;
  pageWrapId?: string;
  outerContainerId?: string;
  onMenuStateChange?(state: State): void;
}

export const SideMenu: React.FC<ISideMenuProps> = (props) => (
  <StyledMenu>
    <Menu
      burgerButtonClassName="burgerButton"
      onStateChange={props.onMenuStateChange}
      right={true}
      noOverlay={true}
      isOpen={props.isOpen}
      customBurgerIcon={props.menuIcon}
      pageWrapId={props.pageWrapId}
      outerContainerId={props.outerContainerId}
    >
      {props.children}
    </Menu>
  </StyledMenu>
);

export const MenuItem = (props: { children?: React.ReactNode }) => <div className="menu-item">{props.children}</div>;
