// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const documents_ru: ITranslations = {
  ru: {
    "documents.allDocuments": "Все документы",
    "documents.answer": "Ответ",
    "documents.appliesTo": "Применяется к",
    "documents.applyFilter": "Применить фильтр",
    "documents.button.download": "Скачать",
    "documents.button.open": "Открыт",
    "documents.button.print": "Печать",
    "documents.clearFilter": "Очистить фильтр",
    "documents.documentIssue": "Аспект документа",
    "documents.documentNumber": "Номер документа",
    "documents.documentType": "Тип документа",
    "documents.documentTypes.ASI": "Бюллетень",
    "documents.documentTypes.CMREPORT": "Отчет о диагностике активов",
    "documents.documentTypes.FAQ": "ЧаВо",
    "documents.documentTypes.MAN": "Руководство",
    "documents.documentTypes.SPC": "Каталог запасных частей",
    "documents.documentTypes.SW": "Программное обеспечение",
    "documents.documentTypes.SWR": "Отчет о работе",
    "documents.documentTypes.TECHREQUEST": "Техническая поддержка",
    "documents.documentTypes.TR": "Технический отчет",
    "documents.documentTypesLongText.MAN": "Руководство по эксплуатации и обслуживанию",
    "documents.documentTypesLongText.SPC": "Каталог запасных частей",
    "documents.documentTypesLongText.couldntFindArticle": "Не удается найти артикул!",
    "documents.documentTypesLongText.errorWhileFetchingArticle": "Ошибка при выборке артикула: {error}",
    "documents.emptyDataText": "Чтобы сузить область поиска, используйте открытый текст или фильтры, или заранее установленные области, указанные ниже:",
    "documents.files": "Файлы",
    "documents.filterBasedOnDocumentType": "Фильтровать на основе типа документа",
    "documents.forImmediateAction": "Требуются срочные меры",
    "documents.listHeader": "ДОКУМЕНТЫ ({totalItems})",
    "documents.listHeaderEmptyPattern": "ДОКУМЕНТЫ, ПРЕДСТАВЛЯЮЩИЕ ИНТЕРЕС",
    "documents.listHeaderNoItems": "ДОКУМЕНТЫ",
    "documents.loadMore": "Загрузить еще",
    "documents.nickName": "Псевдоним",
    "documents.productReferenceType": "Тип ссылочного номера изделия",
    "documents.question": "Вопрос",
    "documents.releaseNotes": "Примечания к выпуску",
    "documents.scopeButton.ASI": "Недавние бюллетени",
    "documents.scopeButton.MAN": "Недавние руководства",
    "documents.search": "Поиск",
    "documents.sections.attachments": "Приложения",
    "documents.sections.chapters": "Главы",
    "documents.sections.workcards": "Рабочие карты",
    "documents.shared.documentTypes.MF_CL_Analysis": "Анализ",
    "documents.shared.documentTypes.MF_CL_Claims": "Рекламации",
    "documents.shared.documentTypes.MF_CL_Failure": "Обнаружение",
    "documents.shared.documentTypes.MF_CL_Inventories": "Товарно-материальные запасы",
    "documents.shared.documentTypes.MF_CL_MaintenancePlans": "Планы технического обслуживания",
    "documents.shared.documentTypes.MF_CL_MeetingMinutes": "Протокол собрания",
    "documents.shared.documentTypes.MF_CL_Report": "Отчеты",
    "documents.shared.documentTypes.MF_CL_SFOCReport": "Отчет по SFOC",
    "documents.shared.documentTypes.MF_CL_Training": "Обучение",
    "documents.shared.from": "Из",
    "documents.shared.to": "Куда",
    "documents.sorting.documentNumberAZ": "Номер документа (A-Z)",
    "documents.sorting.documentTypeAZ": "Тип документа (A-Z)",
    "documents.sorting.titleAZ": "Название (A-Z)",
    "documents.title": "Название",
    "documents.typeToSearch": "Ввести для поиска...",
    "documents.updateNotificationText.ASI": "Бюллетень требует вашего действия",
    "documents.updateNotificationText.MAN": "Глава обновлена",
    "documents.updateNotificationText.TR": "Опубликован новый технический отчет",
    "documents.updateNotificationText.other": "Документ обновлен",
  },
};
