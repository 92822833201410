import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const SadFace = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Sad face</title>
    <path d="M24,4A20,20,0,1,0,44,24,20,20,0,0,0,24,4Zm0,36A16,16,0,1,1,40,24,16,16,0,0,1,24,40Z" />
    <rect x="22.27" y="31.5" width="8.31" height="2" rx="1" ry="1" transform="translate(-10.43 12.6) rotate(-22.52)" />
    <circle cx="18.33" cy="22.49" r="3" />
    <rect x="11.75" y="16.09" width="9.74" height="2" rx="1" ry="1" transform="translate(-5.28 7.67) rotate(-22.52)" />
    <circle cx="29.67" cy="22.49" r="3" />
    <rect x="26.5" y="16.09" width="9.74" height="2" rx="1" ry="1" transform="translate(53.81 44.9) rotate(-157.48)" />
  </IconBase>
);
