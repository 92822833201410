// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_fi: ITranslations = {
  fi: {
    "request.addContact": "Lisää kontakti",
    "request.addNewContact": "Lisää uusi kontakti",
    "request.addNewUser": "Lisää uusi käyttäjä",
    "request.addressLine1": "Osoiterivi 1",
    "request.addressLine2": "Osoiterivi 2",
    "request.attachmentDescription": "Esimerkiksi kuvat vikatilanteesta.",
    "request.attachments": "Liiteet",
    "request.auxAttachmentDescription": "Esimerkiksi kuvat vikatilanteesta. Kuva tuotteen rekisterikilvestä, kun kyse on apulaitteesta.",
    "request.backgroundInformation": "Taustatietoa",
    "request.chooseReferenceRequest": "Valitse referenssipyyntö",
    "request.city": "Kaupunki",
    "request.claimReference": "Vaatimusreferenssi",
    "request.companyName": "Yhtiön nimi",
    "request.contactInformation": "Yhteystiedot",
    "request.contactInformationDescription": "Olet pyynnön ensisijainen yhteyshenkilö, mutta voit lisätä muita kiinnostuneita henkilöitä, joille ilmoitetaan asiaankuuluvista muutoksista tähän pyyntöön.",
    "request.country": "Maa",
    "request.createdBy": "Luoja",
    "request.creationDate": "Luontipäivä",
    "request.customerClaimReference": "Asiakkaan vaatimusreferenssi",
    "request.customerClaimReferenceOptional": "Asiakkaan vaatimusreferenssi (vapaaehtoinen)",
    "request.customerInformation": "Asiakkaan tiedot",
    "request.daAddressLine1IsRequired": "Osoite on pakollinen!",
    "request.daCityIsRequired": "Kaupunki on pakollinen!",
    "request.daCompanyNameIsRequired": "Yrityksen nimi on pakollinen!",
    "request.daCountryIsRequired": "Maa on pakollinen!",
    "request.daPostalCodeIsRequired": "Postinumero on pakollinen!",
    "request.dateOfOccurrence": "Tapahtumapäivä",
    "request.deliveryAddress": "Toimitusosoite",
    "request.description": "Kuvaus",
    "request.descriptionIsRequired": "Kuvaus on pakollinen!",
    "request.descriptionLong": "Mitä yksityiskohtaisempaa tietoa saamme pyynnön rekisteröintivaiheessa, sitä nopeammin pyynnön käsittely voi jatkua.",
    "request.detailedDescription": "Yksityiskohtainen kuvaus",
    "request.discardingDraft": "Poistetaan luonnosta...",
    "request.draftDiscarded": "Luonnos poistettu.",
    "request.email": "Sähköposti",
    "request.emailIsRequired": "Sähköposti on pakollinen",
    "request.equipmentIsRequired": "Laite on pakollinen",
    "request.errorWhileDiscardingDraft": "Virhe poistettaessa luonnosta: {error}",
    "request.exportAll": "Vie kaikki",
    "request.external": "Ulkoinen",
    "request.faulthyEmail": "Tarkista että kaikki sähköpostiosoitteet ovat kunnollisia.",
    "request.firstName": "Etunimi",
    "request.firstnameIsRequired": "Etunimi on pakollinen",
    "request.generalInformation": "Yleistä tietoa",
    "request.howTheReasonWasIdentified": "Syy ongelmalle. Miten se tunnistettiin ja missä tilanteessa?",
    "request.howTheReasonWasIdentifiedOptional": "Syy ongelmalle. Miten se tunnistettiin ja missä tilanteessa? (Vapaaehtoinen)",
    "request.ifYouWantToCommentText": "Jos haluat jatkaa kommentointia, avaa pyyntö uudelleen",
    "request.installationIsRequired": "Installaatio on pakollinen",
    "request.interestedPeople": "Kiinnostuneet ihmiset",
    "request.isInWarrantyUntil": "Takuu on voimassa {date} asti",
    "request.lastModified": "Viimeisin muutos",
    "request.lastName": "Sukunimi",
    "request.lastnameIsRequired": "Sukunimi on pakollinen",
    "request.noRequestsFound": "Pyyntöjä ei löytynyt.",
    "request.nonTechnical": "Ei tekninen",
    "request.openFullRequest": "Avaa koko pyyntö",
    "request.openRequests": "Avoimet pyynnöt",
    "request.phone": "Puhelinnumero",
    "request.possibleActionsAlreadyTaken": "Tehdyt toimenpiteet",
    "request.possibleActionsAlreadyTakenOptional": "Tehdyt toimenpiteet (vapaaehtoinen)",
    "request.postalCode": "Postinumero",
    "request.productReferenceType": "Tuoteviittaustyyppi",
    "request.rating": "Luokitus",
    "request.rating.approveLabel": "Oletko tyytyväinen vastaukseen?",
    "request.rating.notSatisfied": "Ei tyytyväinen",
    "request.rating.partlySatisfied": "Osin tyytyväinen",
    "request.rating.reasonForReopening": "Syy uudelleen avaamiselle",
    "request.rating.reasonIsRequired": "Syy uudelleen avaamiselle on pakollinen.",
    "request.rating.reopenButtonText": "Avaa uudelleen",
    "request.rating.reopenDialogTitle": "Avaa ongelma uudelleen",
    "request.rating.reopenRequest": "Avaa pyyntö uudelleen",
    "request.rating.satisfied": "Tyytyväinen",
    "request.rating.sendFeedback": "Lähetä palaute",
    "request.rating.suggestionIsRequired": "Ehdotus on pakollinen.",
    "request.rating.whatCanWeDoBetter": "Kerro kuinka voimme parantaa toimintaamme...",
    "request.recentlyOpened": "Viimeisimmät avatut pyynnöt",
    "request.runningHours": "Ajotunnit",
    "request.runningHoursOptional": "Ajotunnit (vapaaehtoinen)",
    "request.selectAddress": "Valitse osoite",
    "request.solution": "Ratkaisu",
    "request.solutionText": "Wärtsilän ratkaisu",
    "request.subject": "Aihe",
    "request.subjectIsRequired": "Aihe on pakollinen",
    "request.summary": "Yhteenveto",
    "request.technical": "Tekninen",
    "request.title": "Titteli",
    "request.titleIsRequired": "Titteli on pakollinen",
    "request.uploadsAreNotFinished": "Tiedostojen lataus on kesken!",
    "request.wartsilaInitiated": "Wärtsilän aloittama",
    "requestStatus.answered": "Vastattu",
    "requestStatus.approved": "Hyväksytty",
    "requestStatus.closed": "Suljettu",
    "requestStatus.closedChangedToProject": "Suljettu - Vaihdettu projektiin",
    "requestStatus.draft": "Luonnos",
    "requestStatus.inProgress": "Kesken",
    "requestStatus.partsInDelivery": "Osat toimituksessa",
    "requestStatus.rejected": "Hylätty",
    "requestStatus.waitingForCustomerActions": "Odotetaan asiakkaan toimia",
  },
};
