import moment from "moment";

export const isWeekend = (date: moment.Moment) => {
  return date.isoWeekday() === 6 || date.isoWeekday() === 7;
};

export const getDateFromBusinessDaysNum = (daysToGet: number) => {
  const currentDate = moment();
  let tmpDate = moment();
  let allDaysNum = 0;
  let businessDaysNum = 0;

  while (true) {
    tmpDate = tmpDate.add(1, "day");

    if (!isWeekend(tmpDate)) {
      businessDaysNum = businessDaysNum + 1;
    }
    allDaysNum = allDaysNum + 1;

    if (daysToGet === businessDaysNum) {
      break;
    }
  }

  return currentDate.add(allDaysNum, "days");
};
