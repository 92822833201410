import { gtag } from "ga-gtag";

const createEvent = <T>(name: string) => {
  return (params: T) => gtag("event", name, params);
};

type DCPDownloadEvents = {
  dcp_account_id: string;
  dcp_document_id: string;
  dcp_document_type: string;

  dcp_account_role: string;
  dcp_account_sector: string;
  dcp_account_country: string;
  dcp_account_sales_region: string;
  dcp_account_market_segment: string;
  dcp_account_value_category: string;

  dcp_user_role: string;
  dcp_user_seniority: string;
};

type DCPReleaseNotes = {
  dcp_notes_click_at: "banner" | "news-page";
};

export const analyticsEvents = {
  dcpReleaseNotes: createEvent<DCPReleaseNotes>("dcp_release_notes"),
  dcpDownloadDocument: createEvent<DCPDownloadEvents>("dcp_document_download"),
};
