import * as React from "react";
import { IBaseStep } from "src/redux/onboarding";
import { OnboardingStepWrapper } from "./styles";
import { H2, P } from "src/design-system/Tokens/typography";
import { LocalizedString } from "../Localization";
import { Container } from "src/design-system/Container";
import { redirectBrowser, getRoutes } from "src/routes";

export const onboardingSupportSteps: IBaseStep[] = [
  {
    className: "support-onboarding-your-support-page",
    isFixed: true,
    disableOverlayClose: true,
    disableBeacon: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepOne.title" />
        </H2>
        <P>
          <LocalizedString id="supportOnboarding.stepOne.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "support-onboarding-new-request",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepTwo.title" />
        </H2>
        <Container $paddingTop={3}>
          <LocalizedString id="supportOnboarding.stepTwo.content" />
          <ul>
            <li>
              <LocalizedString id="techRequest" />
            </li>
            <li>
              <LocalizedString id="warrantyClaim" />
            </li>
            <li>
              <LocalizedString id="sparePartClaim" />
            </li>
            <li>
              <LocalizedString id="customerSupportTicket" />
            </li>
          </ul>
        </Container>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "support-onboarding-your-requests",
    disableOverlayClose: true,
    onStepActivate: () => {
      redirectBrowser(getRoutes().Requests, { requestId: "1" });
    },
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepThree.title" />
        </H2>
        <P>
          <LocalizedString id="supportOnboarding.stepThree.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "support-onboarding-attachments",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepFour.title" />
        </H2>
        <P>
          <LocalizedString id="supportOnboarding.stepFour.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "support-onboarding-comments",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepFive.title" />
        </H2>
        <P>
          <LocalizedString id="supportOnboarding.stepFive.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "support-onboarding-rate-service",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepSix.title" />
        </H2>
        <P>
          <LocalizedString id="supportOnboarding.stepSix.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
  {
    className: "support-onboarding-export-requests",
    disableOverlayClose: true,
    content: (
      <OnboardingStepWrapper>
        <H2>
          <LocalizedString id="supportOnboarding.stepSeven.title" />
        </H2>
        <P>
          <LocalizedString id="supportOnboarding.stepSeven.content" />
        </P>
      </OnboardingStepWrapper>
    ),
  },
];
