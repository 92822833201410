import * as React from "react";

import { Dialog } from "src/components/Dialog";
import { ModalSize } from "src/components/Dialog/Modal";
import { LocalizedString } from "src/components/Localization";
import { translateString } from "src/util/localization";

interface IConfirmDeleteDialogProps {
  isOpen: boolean;
  onCancel(): void;
  onOk(): void;
}

export class ConfirmDeleteDialog extends React.Component<IConfirmDeleteDialogProps> {
  public render() {
    return (
      <Dialog
        size={ModalSize.Small}
        title={translateString("draft.confirmDelete")}
        isDialogOpen={this.props.isOpen}
        onCancel={this.props.onCancel}
        submit={{
          buttonTitle: translateString("delete"),
          callback: this.props.onOk,
        }}
      >
        <LocalizedString id="draft.confirmDelete" />
      </Dialog>
    );
  }
}
