import moment from "moment";
import {
  IAttachment,
  ICombinedRequest,
  IContact,
  IEquipment,
  IInstallation,
  IJWTUserInfo,
  ILineItem,
  IResource,
} from "online-services-types";
import * as React from "react";

import { IUploadedFile } from "src/components/AttachmentUploader/AttachmentUploaderComponent";
import { Heading } from "src/components/Heading";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { getSelectedReferenceClaimText } from "src/components/PreviousClaimList/InnerList";
import { Filelink } from "src/components/SupportList/AttachmentList";
import { AttahcmentListRowComponent } from "src/components/SupportList/AttahcmentListRowComponent";
import {
  FlexContainer,
  PageSection,
  SummaryAddress,
  SummaryAddressRow,
  SummaryAttachmentWrapper,
  SummaryDate,
  SummaryItemWrapper,
  SummaryLabel,
  SummaryNumber,
  SummaryText,
  WrappedP,
} from "src/design-system/Container";
import { QuantiPartsLogoWhite } from "src/images/QuantiPartsLogo";
import { ISuggestedPart } from "src/models/catalogueSections";
import { ICombinedRequestViewModel, RequestType } from "src/models/warrantyClaim";
import { formatEquipmentName } from "src/util/formatters";
import { translateString } from "src/util/localization";
import { RequestStatus } from "src/util/requestStatus";
import { Branch } from "src/views/CreateWarrantyClaimView/Branches";
import { ICombinedRequestModel } from "src/views/CreateWarrantyClaimView/CreateWarrantyClaimViewComponent";
import styled from "styled-components";
import {
  cylinderMetadata,
  netWeightMetadata,
  qtySectionMetadata,
  quantityMetadata,
  spnDescriptionMetadata,
} from "../PartsCatalogueList/PartsMetadata";
import { ResponsiveTable } from "../ResponsiveTable";
import { IResponsiveTableColumn } from "../ResponsiveTable/interfaces";

export interface IRequestSummaryStateProps {
  installations: IInstallation[];
  equipments: IEquipment[];
  contacts: IContact[];
  requests: ICombinedRequestViewModel[];
  isQuantipartsDistributor: boolean;
}

export interface IRequestSummaryOwnProps {
  attachments: IUploadedFile[];
  claimRequest: ICombinedRequestModel;
  branch: string;
  basketParts: ISuggestedPart[];
  getFormValue(field: string): string | string[] | number | undefined | Date;
  savedRequest: ICombinedRequest | null;
}

export type IRequestSummaryProps = IRequestSummaryOwnProps & IRequestSummaryStateProps;

interface ISummaryItemProps {
  label: string;
  textValue?: string;
  textValues?: string[];
  dateValue?: Date | string;
  numberValue?: number;
  unit?: string;
}

const QPLogoWrapper = styled.div`
  display: inline-block;

  svg {
    margin-bottom: -50px;
    margin-left: -55px;
    height: 50px;
    width: 180px;
  }
`;

export const SummaryItem: React.FC<ISummaryItemProps> = ({
  label,
  textValue,
  textValues,
  dateValue,
  numberValue,
  unit,
}) => (
  <SummaryItemWrapper>
    <SummaryLabel>{label}</SummaryLabel>
    {textValue && <SummaryText>{`${textValue} ${unit ? unit : ""}`}</SummaryText>}
    {textValues && textValues.map((value, idx) => <SummaryText key={`${idx}-${value}`}> {value}</SummaryText>)}
    {dateValue && <SummaryDate>{moment(dateValue).format("ll")}</SummaryDate>}
    {numberValue !== undefined && <SummaryNumber>{`${numberValue} ${unit ? unit : ""}`}</SummaryNumber>}
  </SummaryItemWrapper>
);

interface ISummaryAttachmentProps {
  attachment: IUploadedFile | IAttachment;
}

export const SummaryAttachment: React.FC<ISummaryAttachmentProps> = ({ attachment }) => (
  <SummaryAttachmentWrapper>
    <SummaryText>{attachment.name}</SummaryText>
  </SummaryAttachmentWrapper>
);

interface ISummaryAttachmentsProps {
  sourceId: string;
  attachments: IAttachment[];
  userInfo: IJWTUserInfo;
  attachmentsColor?: string;
}

export const SummaryAttachments = ({ sourceId, attachments, userInfo, attachmentsColor }: ISummaryAttachmentsProps) => {
  if (!attachments || attachments.length === 0) return null;
  return (
    <SummaryItemWrapper>
      <SummaryLabel>{translateString("request.attachments")}</SummaryLabel>
      {attachments.map((attachment) => (
        <AttahcmentListRowComponent
          loadAttachments={() => undefined}
          fileId={attachment.id}
          fileName={attachment.name}
          requestType={RequestStatus.Draft as unknown as RequestType}
          requestId={sourceId}
          iconColor={attachmentsColor}
          user={userInfo}
          createdBy={attachment.creatorId}
          title=""
          hideTitle
          isInternal={false}
          isAttachmentUploadDisabled
        >
          <Filelink color={attachmentsColor}>{attachment.name}</Filelink>
        </AttahcmentListRowComponent>
      ))}
    </SummaryItemWrapper>
  );
};

const suggestedPartsColumns: Array<IResponsiveTableColumn<ILineItem>> = [
  spnDescriptionMetadata(),
  netWeightMetadata,
  quantityMetadata,
  cylinderMetadata,
  qtySectionMetadata,
];

interface ISuggestedPartViewModel extends IResource {
  materialText: string;
  lineNumber: number;
  quantity: number;
  weightUnit: string;
  netWeight: number;
  cylinder: string;
}

const getYesNoNA = (value?: string) => {
  switch (value) {
    case "Yes":
      return translateString("yes");
    case "No":
      return translateString("no");
    case "NA":
      return translateString("na");
    default:
      return "";
  }
};

export class RequestSummaryComponent extends React.Component<IRequestSummaryProps> {
  public render() {
    const claim = this.props.claimRequest;
    const { installation, equipment } = this.getInstallationAndEquipmentForClaim(claim);
    const isSelectedEquipmentFourStroke = equipment?.productReferenceTypeCategory === "4-S Engines";

    return (
      <div>
        <PageSection>
          <Heading text={translateString("request.generalInformation")} />
          <SummaryItem label={translateString("request.subject")} textValue={claim.subject} />
          <SummaryItem label={translateString("installation")} textValue={installation ? installation.name : ""} />
          <FlexContainer>
            <SummaryItem
              label={translateString("equipment")}
              textValue={equipment ? formatEquipmentName(equipment) : ""}
            />
            {equipment?.isQuantiparts && (
              <QPLogoWrapper>
                <QuantiPartsLogoWhite />
              </QPLogoWrapper>
            )}
          </FlexContainer>
        </PageSection>
        {this.props.branch === Branch.WarrantyClaim && (
          <>
            <PageSection>
              <Heading text={translateString("request.customerInformation")} />
              <SummaryItem
                label={translateString("request.customerClaimReference")}
                textValue={claim.customerClaimNumber}
              />
            </PageSection>
            <PageSection>
              <Heading text={translateString("request.backgroundInformation")} />
              <SummaryItem label={translateString("request.dateOfOccurrence")} dateValue={claim.failureDate} />
              <SummaryItem label={translateString("request.runningHours")} numberValue={claim.runningHours} />
              <SummaryItem
                label={translateString("request.claimReference")}
                textValue={getSelectedReferenceClaimText(
                  this.props.requests.find((req) => req.id === claim.referenceWarrantyId)
                )}
              />
            </PageSection>
          </>
        )}

        <PageSection>
          <Heading text={translateString("request.description")} />
          <SummaryItem label={translateString("request.detailedDescription")} textValue={claim.description} />
          {this.props.branch === Branch.WarrantyClaim && (
            <SummaryItem
              label={translateString("request.howTheReasonWasIdentified")}
              textValue={claim.howTheReasonWasIdentified}
            />
          )}
          {this.props.branch !== Branch.CustomerSupportTicket && (
            <SummaryItem
              label={translateString("request.possibleActionsAlreadyTaken")}
              textValue={claim.whatWasDoneToCorrectTheProblem}
            />
          )}
          {this.props.branch === Branch.WarrantyClaim && isSelectedEquipmentFourStroke && (
            <>
              <SummaryItem
                label={translateString("request.engineShutdownBlackout")}
                textValue={getYesNoNA(claim.engineShutdownBlackout)}
              />
              <SummaryItem
                label={translateString("request.machineryOrEquipmentBreakdown")}
                textValue={getYesNoNA(claim.machineryOrEquipmentBreakdown)}
              />
              <SummaryItem
                label={translateString("request.decreaseInAvailabilityReliability")}
                textValue={getYesNoNA(claim.decreaseInAvailabilityReliability)}
              />
            </>
          )}
          {this.props.branch === Branch.TechRequest && this.props.getFormValue("runningHours") !== undefined && (
            <>
              <SummaryItem
                label={translateString("request.runningHours")}
                numberValue={this.props.getFormValue("runningHours") as number}
              />
              <SummaryItem
                label={translateString("request.dateOfMeasurement")}
                dateValue={this.props.getFormValue("runningHoursDate") as string}
              />
            </>
          )}
        </PageSection>
        {this.props.attachments.length > 0 && (
          <PageSection>
            <Heading text={translateString("request.attachments")} />
            {this.props.attachments.map((attachment) => (
              <SummaryAttachment key={attachment.id} attachment={attachment} />
            ))}
          </PageSection>
        )}
        {this.props.branch === Branch.WarrantyClaim && (
          <PageSection>
            <Heading text={translateString("request.contactInformation")} />
            <SummaryItemWrapper>
              <SummaryLabel>
                <LocalizedStringComponent id="request.interestedPeople" />
              </SummaryLabel>
              {(claim.contacts || [])
                .filter((contact) => contact)
                .map((contact) => (
                  <WrappedP>{`${contact.firstName} ${contact.lastName} (${contact.email})`}</WrappedP>
                ))}
            </SummaryItemWrapper>
            {this.props.savedRequest?.autoNotifiedCustomersEmails !== undefined &&
              this.props.savedRequest?.autoNotifiedCustomersEmails?.length > 0 && (
                <SummaryItemWrapper>
                  <SummaryLabel>
                    <LocalizedStringComponent id="request.automaticallyNotifiedCustomers" />
                  </SummaryLabel>
                  {this.props.savedRequest?.autoNotifiedCustomersEmails?.map((email: string) => (
                    <WrappedP>{email}</WrappedP>
                  ))}
                </SummaryItemWrapper>
              )}
            <>
              <Heading text={translateString("request.deliveryAddress")} />
              <SummaryAddress>
                <SummaryAddressRow>{claim.daCompanyName}</SummaryAddressRow>
                <SummaryAddressRow>{claim.daAddressLine1}</SummaryAddressRow>
                <SummaryAddressRow>{claim.daAddressLine2}</SummaryAddressRow>
                <SummaryAddressRow>
                  {claim.daPostalCode} {claim.daCity}
                </SummaryAddressRow>
                <SummaryAddressRow>{claim.daCountry}</SummaryAddressRow>
              </SummaryAddress>
            </>
          </PageSection>
        )}
        {this.props.branch === Branch.WarrantyClaim && claim.suggestedParts.length > 0 && (
          <PageSection>
            <Heading text={translateString("request.suggestedSpareParts")} />

            <ResponsiveTable<ISuggestedPartViewModel>
              columns={suggestedPartsColumns}
              rows={this.props.basketParts.filter(
                (basketPart) => !!claim.suggestedParts.find((part) => basketPart.productId === part.productId)
              )}
            />
          </PageSection>
        )}
      </div>
    );
  }

  private readonly getInstallationAndEquipmentForClaim = (claim: ICombinedRequestModel) => {
    const installation: IInstallation | undefined = this.props.installations
      ? this.props.installations.find((item) => item.id === claim.installationId)
      : undefined;
    const equipment: IEquipment | undefined = this.props.equipments
      ? this.props.equipments.find((item) => item.id === claim.equipmentId)
      : undefined;
    return { installation, equipment };
  };
}
