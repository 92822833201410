// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_ja: ITranslations = {
  ja: {
    "reports.attachments": "添付書類",
    "reports.conditionMonitorinReports": "資産診断レポート",
    "reports.newReportAvailable": "新しいレポートを利用できます",
    "reports.noReportsAvailable": "利用可能なレポートはありません",
    "reports.reportPeriodStartDate": "期間",
    "reports.reportPublishedDate": "発行日",
    "reports.title": "タイトル",
  },
};
