import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const AlarmIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Alarm</title>
    <path d="M24.3846154,39.8974359 C23.2517236,39.8974359 22.3333333,38.9790456 22.3333333,37.8461538 L18.2307692,37.8461538 C18.2307692,41.2448292 20.98594,44 24.3846154,44 C27.7832908,44 30.5384615,41.2448292 30.5384615,37.8461538 L26.4358974,37.8461538 C26.4358974,38.9790456 25.5175072,39.8974359 24.3846154,39.8974359 Z" />
    <path d="M35.6666667,31.6 L35.6666667,17.2410256 L35.6666667,17.2410256 C35.6666667,12.1430126 31.5339105,8.01025641 26.4358974,8.01025641 L26.4358974,4 L22.3333333,4 L22.3333333,8.02051282 C17.2353203,8.02051282 13.1025641,12.153269 13.1025641,17.2512821 L13.1025641,28.6153846 L13.1025641,28.6153846 L13.1025641,31.6923077 L9,35.7948718 L39.7692308,35.7948718 L35.6666667,31.6 Z M31.5641026,19.2923077 L31.5641026,31.6 L17.2051282,31.6 L17.2051282,18.2666667 L17.2051282,18.2666667 L17.2051282,17.2410256 C17.2051282,14.4087962 19.5011038,12.1128205 22.3333333,12.1128205 L26.4358974,12.1128205 C29.2681269,12.1128205 31.5641026,14.4087962 31.5641026,17.2410256 L31.5641026,19.2923077 Z" />
  </IconBase>
);
