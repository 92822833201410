export const breakpoints = {
  desktop: 1280,
  desktopLarge: 1920,
  mobile: 320,
  mobileLarge: 450,
  tablet: 768,
};

export const whenLargerThan = (breakPoint: number, styles: string) => {
  return `
    @media (min-width: ${breakPoint}px) {
      ${styles}
    }
  `;
};

/*
  example when used inside a styled-component styling

  ${whenPhoneLargeOrLarger(`
    display: flex;
  `)}
*/

export const whenPhoneLargeOrLarger = (styles: string) => {
  return whenLargerThan(breakpoints.mobileLarge, styles);
};

export const whenTabletOrLarger = (styles: string) => {
  return whenLargerThan(breakpoints.tablet, styles);
};

export const whenDesktopOrLarger = (styles: string) => {
  return whenLargerThan(breakpoints.desktop, styles);
};

export const whenDesktopLargeOrLarger = (styles: string) => {
  return whenLargerThan(breakpoints.desktopLarge, styles);
};

const params = { whenPhoneLargeOrLarger, whenTabletOrLarger, whenDesktopOrLarger, whenDesktopLargeOrLarger };
export default params;
