import * as React from "react";
import styled from "styled-components";

import { breakpoints, whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";
import { Disclaimer, fixedFontSizes } from "src/design-system/Tokens/typography";
import { spacer } from "src/design-system/Tokens/tokens";

const KEY_CODE_E = 69;
const KEY_CODE_UP_ARROW = 38;
const KEY_CODE_DOWN_ARROW = 40;

const Wrapper = styled.span`
  margin: ${spacer(1)};
  display: flex;
  flex-grow: 1;
  flex-basis: 55px;
  flex-direction: column;
  align-items: start;
  ${whenTabletOrLarger(`
    max-width: 350px;
  `)};
`;

const InputField = styled.input`
  border: none;
  width: 55px;
  font-size: ${fixedFontSizes.baseFontSize}px;
  /* the following is for disabling default browser spinner buttons */
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    width: 80%;
  }
`;

export interface IInfoBoxProps {
  title: string;
  value: number | string;
  inBasket: boolean;
  inputHandler?: (quantity: number, inBasket: boolean) => void;
}

interface IInfoBoxState {
  editing: boolean;
  editValue: string;
}

export class InfoBox extends React.Component<IInfoBoxProps, IInfoBoxState> {
  public readonly state = {
    editing: false,
    editValue: this.props.value.toString(),
  };

  public render() {
    const { title, value, inputHandler } = this.props;
    let displayedValue: string | number;
    if (this.state.editing) {
      displayedValue = this.state.editValue;
    } else {
      displayedValue = value;
    }
    return (
      <Wrapper>
        <Disclaimer>{title}</Disclaimer>
        {inputHandler ? (
          <InputField
            type="number"
            min="0"
            value={displayedValue}
            onKeyDown={this.specialKeysHandling}
            onKeyPress={this.onKeyPressHandler}
            onBlur={this.onBlurHandler}
            onFocus={this.onFocusHandler}
            onChange={this.onChangeHandler}
            pattern="[0-9]*"
          />
        ) : (
          <div>{value}</div>
        )}
      </Wrapper>
    );
  }

  private readonly onFocusHandler = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ editing: true, editValue: this.props.value.toString() });
  };

  private readonly specialKeysHandling = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === KEY_CODE_E || e.keyCode === KEY_CODE_UP_ARROW || e.keyCode === KEY_CODE_DOWN_ARROW) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  private readonly setQuantity = (value: string) => {
    const quantity = parseInt(value, 10);
    if (this.props.inputHandler) {
      if (!isNaN(quantity)) {
        this.props.inputHandler(quantity, this.props.inBasket);
      } else {
        // User removed all text from input
        this.props.inputHandler(0, this.props.inBasket);
      }
    }
  };

  private readonly onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.setQuantity(e.currentTarget.value);
    }
  };

  private readonly onBlurHandler = (e: React.FormEvent<HTMLInputElement>) => {
    this.setQuantity(e.currentTarget.value);
    this.setState({ editing: false });
  };

  private readonly onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editValue: e.currentTarget.value });
  };
}
