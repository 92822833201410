import { Container, FlexContainer } from "src/design-system/Container";
import { IReleaseNote, ReleaseNoteComponent } from "src/views/ReleaseNotesView/ReleaseNote";
import React, { useContext, useState } from "react";
import { displayError, displaySuccess } from "src/util/error";

import { APIFetch } from "src/APIFetch";
import { Button } from "src/design-system/Button";
import { Checkbox } from "src/design-system/Checkbox";
import { Dialog } from "src/components/Dialog";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { ModalSize } from "src/components/Dialog/Modal";
import { VersionContext } from "src/views/ReleaseNotesView/VersionContext";
import styled from "styled-components";
import { translateString } from "src/util/localization";

const ReleaseNoteWrapper = styled.div`
  width: 800px;
`;

const features = ["commerce", "techdoc", "support", "agreements", "fulldemo"] as const;

const dictionary = {
  fulldemo: translateString("requestDemoFeature.demo"),
  techdoc: translateString("requestDemoFeature.techdoc"),
  support: translateString("requestDemoFeature.support"),
  commerce: translateString("requestDemoFeature.commerce"),
  agreements: translateString("requestDemoFeature.agreements"),
} as const;

const sortReleaseDates = (a: string, b: string) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA < dateB) {
    return 1;
  } else if (dateA > dateB) {
    return -1;
  } else {
    return 0;
  }
};

export const ReleaseNotesPage = () => {
  const [itemsToShow, setItemsToShow] = useState(3);
  const version = useContext(VersionContext);
  const notesToShow = version.releaseNotes
    .slice(0, itemsToShow)
    .sort((a, b) => sortReleaseDates(a.releaseDate, b.releaseDate));
  const showButton = version.releaseNotes.length > itemsToShow;

  const [dialogVisible, setDialogVisibility] = React.useState(false);
  const [selectedFeatures, setFeatures] = React.useState<string[]>([]);

  const [release] = version.releaseNotes;

  const setFeature = (feature: string) => {
    return setFeatures((s) => {
      if (!s.includes(feature)) return s.concat(feature);
      return s.filter((f) => f !== feature);
    });
  };

  return (
    <>
      {version.isReady ? (
        <ReleaseNoteWrapper>
          <Container $marginBottom={3}>
            <Container $marginBottom={2}>{translateString("releaseNotes.interestedNewFetures")}</Container>
            <Button onClick={() => setDialogVisibility(true)}>{translateString("releaseNotes.requestDemo")}</Button>
          </Container>
          {notesToShow.map((releaseNote: IReleaseNote, index) => (
            <ReleaseNoteComponent
              // If we have unseen release notes and this is the first note then this should be displayed as unseen
              isUnseen={version.hasNewReleaseNotes && index === 0}
              releaseNote={releaseNote}
              hideDivider={index === notesToShow.length - 1}
            />
          ))}
          {showButton && (
            <Button onClick={() => setItemsToShow(itemsToShow + 3)}>
              <LocalizedStringComponent id="main.loadMore" />
            </Button>
          )}
        </ReleaseNoteWrapper>
      ) : (
        <LoadingSpinner />
      )}
      <Dialog
        size={ModalSize.Small}
        submit={{
          buttonDisabled: !selectedFeatures.length,
          callback: () => {
            new APIFetch(`service/requestdemo`)
              .post({ topics: selectedFeatures, releaseNotesVersion: notesToShow[0]?.releaseVersion })
              .then(() => {
                setFeatures([]);
                setDialogVisibility(false);
                displaySuccess();
              })
              .catch(() => {
                displayError();
              });
          },
          buttonTitle: "Send request",
        }}
        isDialogOpen={dialogVisible}
        onCancel={() => setDialogVisibility(false)}
        subtitle={
          release?.releaseVersion
            ? `${translateString("releaseNotes.requestDemoFor")} ${release.releaseVersion}`
            : translateString("releaseNotes.requestDemo")
        }
      >
        <Container>
          <Container $marginBottom={2}>{translateString("releaseNotes.selectFeatures")}</Container>

          <FlexContainer $column $gap={1}>
            {features.map((feature) => (
              <Checkbox
                key={feature}
                label={dictionary[feature]}
                onChange={() => {
                  setFeature(feature);
                }}
                checked={selectedFeatures.includes(feature)}
              />
            ))}
          </FlexContainer>

          <Container $margin={[3, 0]}>{translateString("releaseNotes.contactDisclaimer")}</Container>
        </Container>
      </Dialog>
    </>
  );
};
