import * as React from "react";

import {
  BasketButtonsRenderer,
  ExternalBasketButtonsRenderer,
  attachmentMetadata,
  availableAttachmentsBubble,
  availableCommentBubble,
  commentMetadata,
  cylinderMetadata,
  hideAttachmentsColumnFilter,
  hideLineNumberColumnFilter,
  idMetadata,
  leadTextMetadata,
  lineNumber,
  netPriceMetadata,
  netWeightMetadata,
  qtySectionMetadata,
  specialMarks,
  spnDescriptionMetadata,
} from "../PartsCatalogueList/PartsMetadata";
import { Button, ButtonStyle } from "src/design-system/Button";
import { Container, FlexContainer } from "src/design-system/Container";
import { Disclaimer, IDisclaimerTheme } from "src/design-system/Tokens/typography";
import { IEquipment, ILineItem, IPartModel } from "online-services-types";
import { IconSize, WarningIcon } from "src/icons";

import { AttachmentUploader } from "../AttachmentUploader";
import { COMPATIBLE_SERVICE_LEVELS, DEFAULT_MATERIAL_ID } from "src/util/commerce";
import { EditItemsDialog } from "./EditItemsDialog";
import { ExpandableHeader } from "src/components/GenericList/ExpandableHeader";
import { IBasketState } from "src/redux/basket";
import { IExtendedPartAvailability } from "src/redux/partsAvailability";
import { IResponsiveTableColumn } from "src/components/ResponsiveTable/interfaces";
import { IUploadedFile } from "src/components/AttachmentUploader/AttachmentUploaderComponent";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "../Localization";
import { QuantiPartsLogoWhite } from "src/images/QuantiPartsLogo";
import { ResponsiveTable } from "../ResponsiveTable";
import { ThemedProps } from "src/design-system/Theme/theme";
import colors from "src/design-system/Tokens/colors";
import { spinnerAlign } from "src/components/LoadingSpinner/LoadingSpinner";
import styled from "styled-components";
import { translateString } from "src/util/localization";

export interface IBasketItemsListComponentStateProps {
  basket: IBasketState;
  equipments: IEquipment[];
  isReady: boolean;
  partsAvailability: IExtendedPartAvailability[];
}

export interface IBasketItemsListComponentOwnProps {
  fileMetadata: { [id: string]: IUploadedFile[] };
  setFileMetadata: (id: string, files: IUploadedFile[]) => void;
  renderAttentionRow?: (row: IPartModel, cb: () => void) => JSX.Element | null;
  disableAttachmentEdit?: boolean;
  externalBasket?: IBasketState;
  showTrashButton?: boolean;
  hideButtons?: boolean;
  hidePrices?: boolean;
  hideAttachment?: boolean;
  hideLineNumber?: boolean;
  hideAvailability?: boolean;
  useOnboardingMockData?: boolean;
  getFiles(row: ILineItem): IUploadedFile[];
  onChangeItemNumber?(item: IPartModel, amount: number): void;
  setAttachmentsBusyState?(state: boolean): void;
  setRecommendations?: React.Dispatch<React.SetStateAction<IPartModel[]>>;
  onUpdatePart?: (row: IPartModel | IPartModel[]) => void;
  isWarranty?: boolean;
}

export interface IBasketItemsListDispatchProps {
  setComment: (item: ILineItem) => void;
  setAttachments: (item: ILineItem) => void;
}

interface SortedRows {
  [key: string]: ILineItem[];
}

const LogoWrapper = styled.div`
  height: 30px;

  svg {
    width: 120px;
    height: 45px;
  }
`;

const DescriptionWarning = styled(Container)`
  color: ${(props: ThemedProps) => props.theme.text};
`;

export const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export type IBasketItemsListProps = IBasketItemsListComponentStateProps &
  IBasketItemsListComponentOwnProps &
  IBasketItemsListDispatchProps;

export type IBasketItemsState = {
  editingInformation: boolean;
  activeRow: IPartModel | null;
};

export class BasketItemsListComponent extends React.Component<IBasketItemsListProps, IBasketItemsState> {
  public state = {
    activeRow: null,
    editingInformation: false,
  };

  private readonly setLineItemAttachments = (item: ILineItem, files: IUploadedFile[]) => {
    this.props.setFileMetadata(item.sfId!, files);
    this.props.setAttachments({ ...item, attachmentIds: files.map((file) => file.id) });
  };

  // tslint:disable: member-ordering
  private readonly columnsWithoutPrice = (
    partsAvailability: IExtendedPartAvailability[],
    { marksHidden }: { marksHidden: boolean }
  ): Array<IResponsiveTableColumn<IPartModel>> => {
    return [
      lineNumber,
      specialMarks(partsAvailability),
      spnDescriptionMetadata(),
      idMetadata(),
      netWeightMetadata,
      availableAttachmentsBubble,
      availableCommentBubble,
      {
        key: "buttons",
        priority: 11,
        plannedWidth: 165,
        alwaysShown: true,
        renderer: ({ row }: { row: IPartModel }) => (
          <ExternalBasketButtonsRenderer
            part={row}
            onChangeItemNumber={this.props.onChangeItemNumber}
            externalBasket={this.props.externalBasket}
            hidePrices={this.props.hidePrices}
            showTrashButton={this.props.showTrashButton}
            setRecommendations={this.props.setRecommendations}
          />
        ),
      },
      cylinderMetadata,
      qtySectionMetadata,
      attachmentMetadata(
        MAX_FILE_SIZE_BYTES,
        this.setLineItemAttachments,
        this.props.getFiles,
        this.props.disableAttachmentEdit || false,
        false,
        this.props.setAttachmentsBusyState
      ),
      commentMetadata(this.props.setComment),
    ]
      .filter((col) => hideAttachmentsColumnFilter(this.props.hideAttachment, col))
      .filter((col) => hideLineNumberColumnFilter(this.props.hideLineNumber, col));
  };

  private readonly columnsForIncompatibleParts = (): Array<IResponsiveTableColumn<IPartModel>> => {
    return [
      lineNumber,
      spnDescriptionMetadata(),
      idMetadata(),
      {
        key: "buttons",
        priority: 11,
        plannedWidth: 165,
        alwaysShown: true,
        renderer: ({ row }: { row: IPartModel }) => (
          <ExternalBasketButtonsRenderer
            part={row}
            hidePrices={true}
            showTrashButton={true}
            inactive={true}
          />
        ),
      },
    ]
  };

  private readonly columns = (
    partsAvailability: IExtendedPartAvailability[],
    { marksHidden }: { marksHidden: boolean }
  ): Array<IResponsiveTableColumn<IPartModel>> => {
    return [
      lineNumber,
      specialMarks(partsAvailability),
      spnDescriptionMetadata(),
      idMetadata(),
      leadTextMetadata(this.props.equipments, this.props.partsAvailability),
      netPriceMetadata(true),
      netWeightMetadata,
      availableAttachmentsBubble,
      availableCommentBubble,
      {
        key: "buttons",
        priority: 11,
        plannedWidth: this.props.showTrashButton ? 400 : 340,
        alwaysShown: true,
        renderer: ({ row }: { row: IPartModel }) => (
          <BasketButtonsRenderer
            part={row}
            onChangeItemNumber={this.props.onChangeItemNumber}
            externalBasket={this.props.externalBasket}
            hidePrices={this.props.hidePrices}
            showTrashButton={this.props.showTrashButton}
            setRecommendations={this.props.setRecommendations}
          />
        ),
      },
      cylinderMetadata,
      qtySectionMetadata,
      attachmentMetadata(
        MAX_FILE_SIZE_BYTES,
        this.setLineItemAttachments,
        this.props.getFiles,
        this.props.disableAttachmentEdit || false,
        false,
        this.props.setAttachmentsBusyState
      ),
      commentMetadata(this.props.setComment),
    ];
  };

  public render() {
    let sortedBasketRows: SortedRows = {};
    let sortedTechIdRows: SortedRows = {};
    let sortedAttentionRows: SortedRows = {};
    let sortedIncompatibleRows: SortedRows = {};

    const basket = this.props.externalBasket || this.props.basket;

    if (basket.items.length === 0 && this.props.isReady) {
      return (
        <Disclaimer forcedTheme={IDisclaimerTheme.Dark}>
          <LocalizedString id="spareParts.emptyBasket" />
        </Disclaimer>
      );
    }

    if (basket.items.length > 0) {
      const basketRows: ILineItem[] = [];
      const techIdRows: ILineItem[] = [];
      const attentionRows: ILineItem[] = [];
      const incompatibleRows: ILineItem[] = [];

      basket.items.forEach((item) => {
        const hasDescription = item.materialText;
        const isFound = item.materialId !== DEFAULT_MATERIAL_ID;
        const isIncompatible = item.serviceLevel ? !COMPATIBLE_SERVICE_LEVELS.includes(item.serviceLevel) : false;

        if (!isFound) {
          techIdRows.push(item);
        }

        if (isIncompatible) {
          incompatibleRows.push(item);
        } else if (isFound || hasDescription) {
          basketRows.push(item);
        } else {
          attentionRows.push(item);
        }
      });

      sortedBasketRows = this.sortBasket(basketRows);
      sortedTechIdRows = this.sortBasket(techIdRows);
      sortedAttentionRows = this.sortBasket(attentionRows);
      sortedIncompatibleRows = this.sortBasket(incompatibleRows);

      const lineNumberSorter = {
        key: "lineNumber",
        asc: true,
      };

      return (
        <div>
          <Container>
            <FlexContainer $centered={true} $marginBottom={2} $gap={2}>
              {incompatibleRows.length ? (
                <FlexContainer $centered={true} $gap={2}>
                  <FlexContainer $noShrink={true}>
                    <WarningIcon size={IconSize.Base} />
                  </FlexContainer>
                  <DescriptionWarning>
                    {translateString("spareParts.incompatibleParts", {
                      lines: incompatibleRows.map((row) => row.lineNumber).join(", "),
                    })}
                  </DescriptionWarning>
                </FlexContainer>
              ) : null}
              {attentionRows.length ? (
                <FlexContainer $centered={true} $gap={2}>
                  <FlexContainer $noShrink={true}>
                    <WarningIcon size={IconSize.Base} />
                  </FlexContainer>
                  <DescriptionWarning>
                    {translateString("spareParts.addDescription", {
                      lines: attentionRows.map((row) => row.lineNumber).join(", "),
                    })}
                  </DescriptionWarning>
                </FlexContainer>
              ) : null}
              {techIdRows.length > 1 ? (
                <Button buttonStyle={ButtonStyle.Brand} onClick={() => this.setState({ editingInformation: true })}>
                  {translateString("spareParts.editAllLines")}
                </Button>
              ) : null}
              {this.props.onUpdatePart ? (
                <EditItemsDialog
                  isWarranty={this.props.isWarranty}
                  items={sortedTechIdRows}
                  focusOnRow={this.state.activeRow}
                  onSubmit={(parts) => {
                    this.props.onUpdatePart!(parts);
                    this.setState({ editingInformation: false, activeRow: null });
                  }}
                  equipments={this.props.equipments}
                  opened={this.state.editingInformation}
                  onCancel={() => this.setState({ editingInformation: false, activeRow: null })}
                  uploadButton={(row) => (
                    <AttachmentUploader
                      relatedId={row.sfId}
                      disabled={!row.sfId}
                      iconColor={colors.primary.black}
                      onBusy={this.props.setAttachmentsBusyState}
                      onFilesChanged={(files) => {
                        this.props.setFileMetadata(row.sfId!, files);
                        this.props.setAttachments({ ...row, attachmentIds: files.map((file) => file.id) });
                      }}
                      files={this.props.getFiles(row)}
                      downloadAllowed
                      maxSize={MAX_FILE_SIZE_BYTES}
                    />
                  )}
                />
              ) : null}
            </FlexContainer>

            {incompatibleRows.length
              ? Object.keys(sortedIncompatibleRows).map((equipmentId, index) => {
                const equipmentHeader = this.getEquipmentName(equipmentId, this.props.equipments);

                return (
                  <Container $marginBottom={6} key={equipmentId}>
                    <ExpandableHeader
                      static
                      opened
                      title={equipmentHeader || ""}
                      logo={this.getLogo(equipmentId, this.props.equipments)}
                      backgroundColor={"#7f7f7f"}
                    >
                      <ResponsiveTable<IPartModel>
                        rows={incompatibleRows}
                        sort={lineNumberSorter}
                        keyExtractor={(row, idx) => row.frontendKey || row.sfId || `${row.id}-${idx}`}
                        columns={this.columnsForIncompatibleParts()}
                      />
                    </ExpandableHeader>
                  </Container>
                );
              })
              : null}

            {attentionRows.length
              ? Object.keys(sortedAttentionRows).map((equipmentId, index) => {
                  const equipmentHeader = this.getEquipmentName(equipmentId, this.props.equipments);
                  const availability = this.props.partsAvailability.filter((a) =>
                    attentionRows.find((p) => p.materialId === a.materialId)
                  );

                  const marksHidden =
                    availability.every((row) => !row.dangerousGoodsIndicator && !row.nonCancellableItem) &&
                    attentionRows.every((row) => !row.dangerousGoodsIndicator && !row.nonCancellableItem);

                  return (
                    <Container $marginBottom={6} key={equipmentId}>
                      <ExpandableHeader
                        static
                        opened
                        title={equipmentHeader || ""}
                        logo={this.getLogo(equipmentId, this.props.equipments)}
                      >
                        <ResponsiveTable<IPartModel>
                          rows={attentionRows}
                          sort={lineNumberSorter}
                          keyExtractor={(row, idx) => row.frontendKey || row.sfId || `${row.id}-${idx}`}
                          columns={
                            this.props.hidePrices
                              ? this.columnsWithoutPrice(this.props.partsAvailability, { marksHidden })
                              : this.columns(this.props.partsAvailability, { marksHidden })
                          }
                          rowExtension={(row) =>
                            this.props.renderAttentionRow
                              ? this.props.renderAttentionRow(row, () =>
                                  this.setState({
                                    editingInformation: true,
                                    activeRow: row,
                                  })
                                )
                              : null
                          }
                        />
                      </ExpandableHeader>
                    </Container>
                  );
                })
              : null}
          </Container>

          {Object.keys(sortedBasketRows).map((equipmentId, index) => {
            const equipmentHeader = this.getEquipmentName(equipmentId, this.props.equipments);
            const rows = sortedBasketRows[equipmentId];
            const availability = this.props.partsAvailability.filter((a) =>
              rows.find((p) => p.materialId === a.materialId)
            );

            const marksHidden =
              availability.every((row) => !row.dangerousGoodsIndicator && !row.nonCancellableItem) &&
              rows.every((row) => !row.dangerousGoodsIndicator && !row.nonCancellableItem);

            return (
              <div key={equipmentId}>
                <ExpandableHeader
                  static
                  opened
                  title={equipmentHeader || ""}
                  logo={this.getLogo(equipmentId, this.props.equipments)}
                >
                  <ResponsiveTable<IPartModel>
                    rows={rows}
                    sort={lineNumberSorter}
                    keyExtractor={(row, idx) => row.frontendKey || row.sfId || `${row.id}-${idx}`}
                    columns={
                      this.props.hidePrices
                        ? this.columnsWithoutPrice(this.props.partsAvailability, { marksHidden })
                        : this.columns(this.props.partsAvailability, { marksHidden })
                    }
                    rowExtension={(row) =>
                      this.props.renderAttentionRow
                        ? this.props.renderAttentionRow(row, () => {
                            this.setState({
                              editingInformation: true,
                              activeRow: row,
                            });
                          })
                        : null
                    }
                  />
                </ExpandableHeader>
              </div>
            );
          })}
        </div>
      );
      // Do not remove extra div below, without it react-sizeme throws runtime error.
      // see https://github.com/ctrlplusb/react-sizeme/issues/145
    }

    return (
      <div>
        <LoadingSpinner align={spinnerAlign.start} disableText={true} />
      </div>
    );
  }

  private readonly sortBasket = (items: ILineItem[]) => {
    // The .map() patches in the sfId from the draft (fetched after patching). Not sure why this receives the redux
    // basket in oldDraft.
    return items
      .map((item) => ({
        ...item,
        sfId: item.sfId || this.props.basket.basketDraft.oldDraft.find((oldItem) => oldItem.id === item.id)?.sfId,
      }))
      .reduce((acc: { [key: string]: ILineItem[] }, currVal: ILineItem) => {
        if (!acc[currVal.equipmentId]) {
          acc[currVal.equipmentId] = [currVal];
        } else {
          acc[currVal.equipmentId].push(currVal);
        }
        return acc;
      }, {});
  };

  private readonly getEquipmentName = (equipmentId: string, equipments: IEquipment[]) => {
    const equipment = equipments.find((eq) => eq.id === equipmentId);
    if (equipment) {
      return equipment.nickName ? equipment.nickName : equipment.description;
    } else {
      return `${translateString("spareParts.productId")} ${equipmentId}`;
    }
  };

  private readonly getLogo = (equipmentId: string, equipments: IEquipment[]) => {
    const equipment = equipments.find((eq) => eq.id === equipmentId);
    if (equipment && equipment.isQuantiparts) {
      return (
        <LogoWrapper>
          <QuantiPartsLogoWhite color={colors.primary.white} />
        </LogoWrapper>
      );
    }
    return;
  };
}
