import { IClaimComment, INewClaimComment } from "online-services-types";
import { APIFetch } from "src/APIFetch";
import { RequestType } from "src/models/warrantyClaim";
import { displayError } from "src/util/error";

export const fetchComments = async (requestType: RequestType, requestId: string) => {
  const handlers = {
    [RequestType.TechRequest]: new APIFetch<IClaimComment[]>("requests/techrequests", "comments"),
    [RequestType.WarrantyClaim]: new APIFetch<IClaimComment[]>("requests/warrantyclaims", "comments"),
    [RequestType.CustomerSupportTicket]: new APIFetch<IClaimComment[]>("requests/customer-support-tickets", "comments"),
    [RequestType.SparePartClaim]: new APIFetch<IClaimComment[]>("requests/sparepartclaims", "comments"),
    [RequestType.OperationalSupportRequest]: new APIFetch<IClaimComment[]>("requests/operationalsupportrequests", "comments"),
  };

  try {
    return await handlers[requestType].get(requestId);
  } catch (error) {
    displayError((error as Error).message, undefined, undefined, error.awsRequestId);
  }
  return [];
};

export const postComment = async (
  requestType: RequestType,
  requestId: string,
  message: string,
  isInternal?: boolean
) => {
  const handlers = {
    [RequestType.TechRequest]: new APIFetch<INewClaimComment>("requests/techrequests", "comments"),
    [RequestType.WarrantyClaim]: new APIFetch<INewClaimComment>("requests/warrantyclaims", "comments"),
    [RequestType.CustomerSupportTicket]: new APIFetch<INewClaimComment>(
      "requests/customer-support-tickets",
      "comments"
    ),
    [RequestType.SparePartClaim]: new APIFetch<INewClaimComment>("requests/sparepartclaims", "comments"),
    [RequestType.OperationalSupportRequest]: new APIFetch<INewClaimComment>("requests/operationalsupportrequests", "comments"),
  };

  try {
    return await handlers[requestType].post({ message, subject: null, isInternal }, requestId);
  } catch (error) {
    displayError(`Error while posting comment: ${(error as Error).message}`, undefined, undefined, error.awsRequestId);
  }
  return [];
};

export const patchComment = async (requestType: RequestType, commentId: string) => {
  const handlers = {
    [RequestType.SparePartClaim]: new APIFetch<INewClaimComment>("service/comments"),
  };

  try {
    return await handlers[requestType].patch({}, commentId);
  } catch (error) {
    displayError(`Error while patching comment: ${(error as Error).message}`, undefined, undefined, error.awsRequestId);
  }
  return [];
};

export const deleteComment = async (requestType: RequestType, commentId: string) => {
  const handlers = {
    [RequestType.SparePartClaim]: new APIFetch<INewClaimComment>("service/comments"),
  };

  try {
    return await handlers[requestType].delete(commentId);
  } catch (error) {
    displayError(`Error while deleting comment: ${(error as Error).message}`, undefined, undefined, error.awsRequestId);
  }
  return [];
};
