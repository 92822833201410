import {
  IAccount,
  IDelivery,
  IEquipment,
  IInstallation,
  IInvoice,
  IJWTUserInfo,
  INotification,
  INotificationSubscription,
  IPartsAvailabilityQueryParams,
  IPurchase,
  IResource,
  ISalesDocumentLine,
} from "online-services-types";

import { INotificationContext } from "src/models/notifications";
import { IPartAvailabilityState } from "src/redux/partsAvailability";
import { ISizeListener } from "src/components/GenericList/GenericList";
import { ISort } from "src/components/ResponsiveTable/interfaces";
import { RouteComponentProps } from "react-router-dom";

export enum itemTypes {
  quotation = "quotation",
  order = "order",
  draft = "draft",
}

export const orderStatusToShowPDF = "GLS5";

export enum notificationStatuses {
  ordered = "Ordered",
  quoted = "Quoted",
  created = "Created",
  new = "New",
  confirmed = "Confirmed",
  toBeConfirmed = "To be confirmed",
  partiallyDispatched = "Partially Dispatched",
  fullyDispatched = "Fully Dispatched",
  sentToDistributor = "Sent to distributor",
  cancelled = "Cancelled",
  expired = "Expired",
}

export interface INotificationRow {
  translationId: string;
  values?: { [key: string]: string };
  moreNotifsNum?: number;
  discarded?: boolean;
  onMoreClick?: () => void;
  discard?: () => void;
}

export interface IPurchaseHistoryListStateProps {
  user: IJWTUserInfo;
  accounts: IAccount[];
  notifications: INotification[];
  purchases: IPurchase[];
  equipments: IEquipment[];
  installations: IInstallation[];
  isReady?: boolean;
  installationsReady: boolean;
  customerIsBlocked: boolean;
  isReadOnlyMode: boolean;
  isMarine?: boolean;
  partsAvailability: IPartAvailabilityState;
}

export interface IPurchaseHistoryListComponentState {
  visiblePurchasesCount: number;
  firstChunkReady: boolean;
  recentlyDeletedPurchases: string[];
  sortItemKey?: string;
  displayPurchase?: IPurchase;
  notificationHistoryItem: PurchaseWithNotification | null;
}

export interface IPurchaseHistoryListDispatchProps {
  getPurchases(): void;
  getEquipments(): void;
  getInstallations(): void;
  refreshNotifications(): void;
  discardNotification(ids: string[], notificationContext: INotificationContext | null): void;
  updateSubscription(purchase: IPurchase, subscription: INotificationSubscription | null): void;
}

export interface IPurchaseListState {
  invoices: Record<string, { status: "loading" | "success" | "error"; data?: IInvoice }>;
  visibleItems: number;
  displayedId?: string;
  subscriptionDialogRow: IPurchase | null;
}

export interface IPurchaseHistoryListOwnProps extends RouteComponentProps {
  user: IJWTUserInfo;
  accounts: IAccount[];
  isQuantipartsDistributor?: boolean;
  size: {
    width: number;
    height: number;
  };
  isReady: boolean;
  uploaderBusy: boolean;
  visiblePurchasesCount: number;
  equipments: IEquipment[];
  visibilitySensorIntervalDelay: number;
  customerIsBlocked: boolean;
  partsAvailability: IPartAvailabilityState;
  isReadOnlyMode: boolean;
  isMarine?: boolean;
  purchases: PurchaseWithNotification[];
  sortOrder: ISort;
  filters?: { [key: string]: string | string[] };
  useOnboardingMockData?: boolean;
  displayedItem?: PurchaseWithNotification;
  displayedItemId?: string;
  extraRows(item: PurchaseWithNotification): JSX.Element[];
  clearFilters(): void;
  onLoadPurchases(): void;
  discardNotification(ids: string[], notificationContext: INotificationContext | null): void;
  onLoadPurchasesDetails(item: PurchaseWithNotification): Promise<PurchaseWithNotification>;
  setRecentlyDeletedPurchases(id: string, cb: () => number | string): void;
  getMultiplePartAvailability(
    equipmentId: string,
    parts: IPartsAvailabilityQueryParams[],
    codeResolution?: boolean
  ): void;
  updateSubscription(purchase: IPurchase, subscription: INotificationSubscription | null): void;
}

export interface IValueMappers {
  [key: string]: (item: IPurchase) => string | boolean | JSX.Element | undefined;
}

export type NotificationContentMap = {
  [id: string]: {
    translationId: string;
    values?: { [key: string]: string };
  };
};

interface ICustomProps {
  notifications: INotification[];
}

export type PurchaseWithNotification = IPurchase & ICustomProps;
export type NotificationWithMultiple = INotification<IDelivery> & { multiple?: boolean };

export type ISalesLineItem = ISalesDocumentLine & IResource;

export type IPurchaseHistoryListProps = ISizeListener & IPurchaseHistoryListPropsNoContainer & RouteComponentProps;

type IPurchaseHistoryListPropsNoContainer = IPurchaseHistoryListStateProps &
  IPurchaseHistoryListOwnProps &
  IPurchaseHistoryListDispatchProps;
