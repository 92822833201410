import * as React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { RequestId } from "src/components/ErrorToast/ErrorToast";
import { LocalizedString } from "src/components/Localization";
import { Container } from "src/design-system/Container";
import { baseTextStyles, fixedFontSizes, H2, P } from "src/design-system/Tokens/typography";
import { IconSize, SadFace } from "src/icons";
import { getPathWithParams, getRoutes } from "src/routes";
import { FlexContainer } from "src/design-system/Container";
import { ThemedProps } from "src/design-system/Theme/theme";
import { translateString } from "src/util/localization";
import styled from "styled-components";

const ErrorLink = styled(Link)`
  ${baseTextStyles};
  text-decoration: none;
  border-bottom: 1px solid ${(props: ThemedProps) => props.theme.text};
`;

const CustomLink = styled.a`
  ${baseTextStyles}
  font-size: ${fixedFontSizes.baseFontSize}px;
  font-weight: 400;
  text-decoration: underline;
`;

const BodyWrapper = styled.div`
  padding-bottom: 1em;
`;

interface IErrorScreenProps extends RouteComponentProps {
  errorUUID?: string;
  errorName?: string;
  errorStack?: string;
  dismissError: () => void;
}

class ErrorScreenComponent extends React.Component<IErrorScreenProps> {
  public componentDidUpdate(prevProps: Readonly<IErrorScreenProps>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.dismissError();
    }
  }

  public render() {
    const isNetworkError = this.props.errorName === "ChunkLoadError";

    let title = "error.defaultTitle";
    let body = null;

    if (isNetworkError) {
      title = "error.networkError";
      body = (
        <BodyWrapper>
          <P>
            <LocalizedString id="error.networkErrorPleaseCheckConnectivity" />
          </P>
          <CustomLink href={window.location.href}>{translateString("error.networkErrorRefreshPrompt")}</CustomLink>
        </BodyWrapper>
      );
    }

    return (
      <FlexContainer $centered={true}>
        <SadFace size={IconSize.Large} />
        <Container $margin={[4, 4]}>
          <H2>
            <LocalizedString id={title} />
          </H2>
          {body}
          <P>
            <LocalizedString
              id="error.pleaseContact"
              values={{
                contactLink: (
                  <ErrorLink to={{
                    pathname: getPathWithParams(getRoutes().Contact),
                    state: {
                      errorUUID: this.props.errorUUID,
                      errorStack: this.props.errorStack,
                    }
                  }}>
                    {translateString("error.contactLinkText")}
                  </ErrorLink>
                ),
              }}
            />
          </P>
          {this.props.errorUUID !== undefined && <RequestId text={this.props.errorUUID} />}
        </Container>
      </FlexContainer>
    );
  }
}

export const ErrorScreen = withRouter(ErrorScreenComponent);
