import { breakpoints } from "src/design-system/Tokens/breakpoints";
import styled from "styled-components";
import { spacer } from "../Tokens/tokens";

export const PageSection = styled.div`
  padding-bottom: ${spacer(6)};

  @media (min-width: ${breakpoints.tablet}px) {
    padding-bottom: ${spacer(10)};
  }
`;
