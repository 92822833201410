// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const main_zh_CN: ITranslations = {
  zh_CN: {
    "account.accountSelected": "账户已选择",
    "account.selectingAccount": "正在选择账户...",
    "addAttachment": "添加附件",
    "addFilter": "添加筛选器",
    "addInstallation": "添加设施",
    "addInstallation.nameIsRequired": "需要填写名称",
    "addInstallation:energy": "添加发电厂",
    "addInstallation:marine": "添加船舶",
    "applyFilter": "应用筛选器",
    "attachments": "附件",
    "auth.continueToUseOS": "继续使用Wärtsilä Online",
    "auth.logout": "注销",
    "auth.readOnlyMode": "只读模式",
    "auth.redirectingToLogin": "正在重新转至登录...",
    "auth.sessionExpired": "已达到对话超时极限，将您重新转至登录页面",
    "auth.sessionWillExpire": "您的会话即将终止",
    "button.cancel": "取消",
    "button.close": "关闭",
    "button.next": "下一步",
    "button.previous": "上一步",
    "button.saveForLater": "保存供日后使用",
    "button.send": "发送",
    "button.skip": "跳过",
    "button.updateDraft": "更新草稿",
    "cancel": "取消",
    "change": "更改",
    "changePasswordDialog.success": "说明邮件成功发送。",
    "chooseDocumentType": "筛选文档",
    "chooseFilter": "选择筛选器...",
    "clearFilters": "清除筛选器",
    "comment.commentIsRequired": "需要填写注释",
    "comment.postComment": "发表注释",
    "comment.replyComment": "回复",
    "comment.writeYourComment": "撰写您的注释",
    "comment.writeYourReply": "撰写您的回复",
    "comment.you": "您",
    "commenting": "注释",
    "contact": "联系方式",
    "contact.contactWartsilaCustomerSupport": "请联系Wärtsilä客服",
    "contact.subject": "主题",
    "contact.subjectIsRequired": "需要填写主题",
    "contact.yourMessage": "您的消息",
    "contact.yourMessageIsRequired": "需要填写消息",
    "contactCustomerSupport": "请联系客服",
    "contextHelp": "快速指南",
    "customerSupportTicket": "客服工单",
    "date": "日期",
    "delete": "删除",
    "didYouMean": "您是否要：",
    "dismissAll": "取消全部",
    "download.downloadReady": "下载就绪!",
    "download.equipmentNotFound": "无法找到与该产品有关的文章",
    "download.error": "发生下载错误",
    "download.preparingDownload": "正在准备您的下载",
    "draft.confirmDelete": "您确定要删除此草稿？",
    "draft.confirmDeleteTitle": "删除草稿?",
    "draft.continueEditing": "继续编辑",
    "draft.delete": "删除草稿",
    "edit": "编辑",
    "engine": "发动机",
    "equipment": "产品",
    "equipment.configuration": "配置",
    "equipment.nickname": "产品昵称",
    "equipment.performanceData": "性能",
    "equipment.title": "标题",
    "equipment.validation.nicknameTooLong": "昵称最大长度为{maxLength}",
    "equipmentNotFound": "无产品存在",
    "error.networkError": "网络错误",
    "error.networkErrorPleaseCheckConnectivity": "请检查您的网络连接。",
    "error.readOnlyErrorMsg": "您处于只读模式。",
    "error.readOnlyErrorTitle": "只读错误",
    "fileUpload.attachmentRejected": "附件被拒绝。请核对上传限制。",
    "fileUpload.noContactPerson": "无联系人。无法发送消息!",
    "fileUpload.uploadAborted": "文件上传中止",
    "fileUpload.uploadFailed": "文件上传失败",
    "fileUpload.userHasReadOnlyAccess": "用户有只读访问权限",
    "filterRequests": "筛选请求",
    "iDontKnow": "我不知道",
    "index.installationDetails": "设施详情",
    "installation": "安装",
    "installation.classificationSociety": "船级社",
    "installation.deliveryDate": "交付日期",
    "installation.description": "说明",
    "installation.imo": "IMO",
    "installation.inspectionDate": "检查日期",
    "installation.name": "名称",
    "installation.nextPlannedMaintenance": "下一次计划的维护保养",
    "installation.type": "类型",
    "installation:energy": "发电厂",
    "installation:marine": "船舶",
    "installations": "设施",
    "installations:energy": "发电厂",
    "installations:marine": "船舶",
    "language": "语言",
    "language.languageNotFound": "无法用语言代码查找语言",
    "languageDisclaimer": "以英文提交此请求将加快处理进程",
    "letsStart": "让我们开始",
    "loading": "正在加载...",
    "logout": "注销",
    "main.loadMore": "加载更多",
    "main.pageNotFound": "您跟随的URL未找到：{url}",
    "main.pageNotFoundTitle": "未找到",
    "manufacturerSerialNumber": "制造商序列号",
    "month.april": "四月",
    "month.august": "八月",
    "month.december": "十二月",
    "month.february": "二月",
    "month.january": "一月",
    "month.july": "七月",
    "month.june": "六月",
    "month.march": "三月",
    "month.may": "五月",
    "month.november": "十一月",
    "month.october": "十月",
    "month.september": "九月",
    "months": "月",
    "myFleet": "我的设施",
    "myFleet:marine": "我的船队",
    "myInstallations": "我的设施",
    "myInstallations:energy": "我的发电厂",
    "myInstallations:marine": "我的船舶",
    "myProfile": "我的配置文件",
    "news.furtherQuestions": "附加注释或问题",
    "news.productsAreRequired": "需要产品",
    "news.quotationRequestSent": "报价请求已发送。",
    "news.readMore": "阅读更多",
    "news.requestQuotation": "请求报价",
    "news.selectTheProductsForTheQuotation": "选择要报价的产品",
    "news.sendingQuotationRequest": "正在发送报价请求。",
    "news.youAreAskingQuotationFor": "您正在请求{productName}的报价。",
    "nickname": "昵称",
    "no": "不是",
    "noDocumentsFound": "未找到适用文档",
    "noVisibleInstallationsFound": "您还未定义任何可见{sector}",
    "noVisibleInstallationsFoundHelp": "从{sector}起，你可以这样做",
    "notApplicable": "不适用",
    "notificationList.markedNotificationsAsRead": "{count}份通知已标记为已读",
    "notificationList.title.criticalBulletinUpdated": "立即行动通告：{title}",
    "notificationList.title.equipmentRunningHoursUpdate": "运行小时数应该更新",
    "notificationList.title.manualUpdated": "手册章节已更新：{title}",
    "notificationList.title.newAttachment": "新附件",
    "notificationList.title.newCMReport": "新报告",
    "notificationList.title.newComment": "新注释",
    "notificationList.title.newRequest": "新请求已创建",
    "notificationList.title.newSalesOrder": "新销售订单",
    "notificationList.title.newServiceQuotation": "新维修报价",
    "notificationList.title.newSparePartClaim": "新备件索赔",
    "notificationList.title.resolvedSparePartClaim": "已解决的备件索赔",
    "notificationList.title.salesOrderConfirmed": "销售订单已确认",
    "notificationList.title.serviceQuotationQuoted": "维修报价已报出",
    "notificationList.title.techRequestSolutionPlan": "新解决方案计划已添加",
    "notificationList.title.waitingForRating": "等待评定",
    "notificationList.title.waitingForReply": "等待回复",
    "notificationList.title.warrantyContactUpdated": "保修联系方式已更新",
    "notifications.lastWeek": "上周",
    "notifications.markAllRead": "全部标记为已读",
    "notifications.older": "更早",
    "notifications.thisWeek": "本周",
    "notifications.title": "通知 ({unreadNotifications})",
    "notifications.today": "今天",
    "notifications.yesterday": "昨天",
    "ok": "正常",
    "optionalFieldTitle": "可选",
    "orderBeingProcessed": "订单正在处理",
    "phone": "电话",
    "pleaseAddInstallation": "请选择设施！",
    "pleaseAddInstallation:energy": "请选择发电厂！",
    "pleaseAddInstallation:marine": "请选择船舶！",
    "productNumber": "产品编号",
    "productType": "产品类型",
    "profile.updating": "正在更新配置文件...",
    "quotationBeingProcessed": "报价正在处理",
    "removeInstallation": "删除设施",
    "removeInstallation:energy": "删除发电厂",
    "removeInstallation:marine": "删除船舶",
    "request.draftSaved": "草稿已保存",
    "request.draftSavingError": "保存草稿时错误",
    "request.savingDraft": "正在保存草稿...",
    "request.send": "发送请求",
    "request.sending": "正在发送请求...",
    "request.sendingError": "发送请求时错误",
    "request.sent": "请求已发送。",
    "routes.contactContractManager": "请联系合同经理",
    "routes.contracts": "协议",
    "routes.download": "下载",
    "routes.equipment": "产品",
    "routes.fluidReportDraft": "新油液报告",
    "routes.installation": "安装",
    "routes.installationSelection": "选择设施",
    "routes.installationSelection:energy": "选择发电厂",
    "routes.installationSelection:marine": "选择船舶",
    "routes.newFluidReport": "新油液报告",
    "routes.newRequest": "新请求",
    "routes.newSFOCReport": "新的SFOC性能数据报告",
    "routes.news": "新闻",
    "routes.partsCatalogue": "浏览备件",
    "routes.performance": "性能",
    "routes.placeOrder": "下订单",
    "routes.proceedToOrder": "继续订购",
    "routes.reports": "报告",
    "routes.requestQuotation": "请求报价",
    "routes.requests": "支持",
    "routes.runningHours": "运行小时数",
    "routes.spareParts": "商务",
    "routes.technicalDocuments": "技术文件",
    "routes.users": "用户",
    "runningHours": "运行小时数",
    "runningHours.confirmDialogMessageHigh": "您输入的运行小时数值比上一次更新后过去的时间要大。您是否要更新新记录？",
    "runningHours.confirmDialogMessageLow": "您输入的运行小时数值低于当前值。您可请求更新运行小时数。",
    "runningHours.invalidValue": "无效值",
    "runningHours.lowValue": "您输入的运行小时数值低于当前值。",
    "runningHours.makeRequest": "提出请求",
    "runningHours.newRunningHours": "新的运行小时数",
    "runningHours.reasonForLowerValue": "值较低的原因",
    "runningHours.reasonForRequest": "请求的原因",
    "runningHours.reasonIsRequired": "需要填写原因",
    "runningHours.runningHoursDateInPast": "运行小时数的日期不能早于上一次更新日期，请选择正确的日期后再继续。",
    "runningHours.updateRunningHours": "更新运行小时数",
    "runningHours.youCanMakeRequest": "您可请求更新运行小时数",
    "runningHoursUpdated": "运行小时数已更新",
    "save": "保存",
    "saveVisibilitySelection": "保存",
    "searchInsteadFor": "而不是搜索",
    "searchPlaceholder": "搜索...",
    "select": "选择",
    "select.sortPlaceholder": "排序方式",
    "selectInstallation": "选择设施",
    "selectInstallation:energy": "选择发电厂",
    "selectInstallation:marine": "选择船舶",
    "send": "发送",
    "sendingFileFailed": "失败",
    "services": "服务",
    "showMore": "显示更多",
    "showingResultsFor": "显示结果",
    "sorting.createdByAZ": "创建人 (A-Z)",
    "sorting.creationDate": "创建日期",
    "sorting.emailShortAZ": "电子邮件 (A-Z)",
    "sorting.equipmentAZ": "产品 (A-Z)",
    "sorting.installationAZ": "设施 (A-Z)",
    "sorting.modified": "已修改",
    "sorting.nameAZ": "名称 (A-Z)",
    "sorting.newestFirst": "最新的第一个",
    "sorting.oldestFirst": "最旧的第一个",
    "sorting.orderNumber": "订购号",
    "sorting.poNumber": "PO编号",
    "sorting.relevancy": "最相关",
    "sorting.requestTypeAZ": "请求类型 (A-Z)",
    "sorting.statusAZ": "状态 (A-Z)",
    "sorting.subjectAZ": "主题 (A-Z)",
    "sorting.titleAZ": "标题 (A-Z)",
    "sparePartClaim": "备件索赔",
    "status": "状态",
    "techRequest": "技术支持",
    "technicalDocumentation": "技术文件",
    "type": "类型",
    "typeToFilter": "要筛选的类型...",
    "unit.cycles": "周期",
    "unit.hours": "小时",
    "unit.litres": "升",
    "unknown": "未知",
    "update": "更新",
    "users": "用户",
    "validation.characterLimit": "字符限制：{characters}",
    "validation.incorrectPhoneNumber": "电话号码应该用 + 开头，包含9-14个数字",
    "viewContracts": "查看协议",
    "visibleInstallations": "可见设施",
    "visibleInstallations.changeAction": "请求添加或删除设施",
    "visibleInstallations.changeAction:energy": "请求添加或删除发电厂",
    "visibleInstallations.changeAction:marine": "请求添加或删除船舶",
    "visibleInstallations.changeLabel": "设施清单是否需要更改？",
    "visibleInstallations.changeLabel:energy": "发电厂清单是否需要更改？",
    "visibleInstallations.changeLabel:marine": "船舶清单是否需要更改？",
    "visibleInstallations:energy": "可见发电厂",
    "visibleInstallations:marine": "可见船舶",
    "warrantyClaim": "保修索赔",
    "warrantyEndDate": "保修结束日期",
    "year": "年",
    "years": "年",
    "yes": "是",
  },
};
