import * as React from "react";
import { LocalizedString } from "src/components/Localization";
import styled from "styled-components";

interface IPunchOutNotificationProps {
  mockedUser: string | null;
}

const ThinBlueLine = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: #2f8fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const PunchOutNotification: React.FC<IPunchOutNotificationProps> = (props: IPunchOutNotificationProps) =>
  props.mockedUser !== null ? (
    <ThinBlueLine>
      <LocalizedString id="auth.punchOutMode" />: {props.mockedUser}
    </ThinBlueLine>
  ) : null;
