import * as React from "react";

import { Document, Image, Page, Text, View, pdf } from "@react-pdf/renderer";
import { IExportDocumentHeader, IExportDocumentProps } from "./types";

import { Button } from "@wartsila/ui-kit";
import { ExportPartModel } from "./ExportPartModel";
import { LocalizedString } from "../Localization";
import logoQP from "src/images/QuantiPartsLogo/quantiparts_logo_color.png";
import logoWartsila from "src/images/WartsilaLogo/w-logo.jpg";
import moment from "moment";
import { saveAs } from "file-saver";
import styles from "./styles";
import { translateString } from "src/util/localization";
import { formatCurrency } from "src/util/formatters";

const BasketExportDocument = ({
  headers,
  data,
  totalPrice,
  currency,
  author,
  totalWeight,
  totalPriceNote,
  selectedInstallationName,
  isQuantipartsDistributor,
}: IExportDocumentProps) => {
  const columnsWidth = {};
  const disclaimers = {
    notOfficialQuotation: translateString("spareParts.notOfficialQuotationDisclaimer"),
    noVAT: translateString("spareParts.priceNoVatDisclaimer"),
    terms: translateString("spareParts.termsConditionsDisclaimerPDF"),
    createdBy: `${translateString("spareParts.basketCreatedBy")} ${author}`,
    onTime: `${translateString("spareParts.on").toLowerCase()} ${moment().format("D/MM/YYYY hh:mm:ss")}`,
  };

  // if user is a QP distributor, use QP logo instead of Wärtsilä
  const usedLogo = isQuantipartsDistributor ? logoQP : logoWartsila;
  const usedImageStyle = isQuantipartsDistributor ? styles.imageQP : styles.image;

  const table = data.map((item: ExportPartModel) => {
    return item.forPDF();
  });

  // Remove unused headers
  const filteredHeaders = headers.filter((header: IExportDocumentHeader) => {
    return Object.keys(table[0]).find((key: string) => key === header.key);
  });

  // Calculate max length of row entries in each column
  table.forEach((row) => {
    filteredHeaders.forEach((header: IExportDocumentHeader) => {
      const rowContent = row[header.key];

      if (!rowContent) {
        if (!columnsWidth[header.key]) columnsWidth[header.key] = 1;
      } else if (!columnsWidth[header.key] || columnsWidth[header.key] < rowContent.length) {
        columnsWidth[header.key] = rowContent.length;
      }
    });
  });

  const getColumnWidth = (header: IExportDocumentHeader) => ({
    flex: Math.min(Math.max(header.label.length, columnsWidth[header.key]), 15),
  });

  const headersContent = filteredHeaders.map((header: IExportDocumentHeader) => (
    <Text key={header.key} style={getColumnWidth(header)}>
      {header.label}
    </Text>
  ));

  const tableContent = table.map((row, i) => {
    return (
      <View key={i} style={styles.contentRow}>
        {filteredHeaders.map((header: IExportDocumentHeader, key: number) => {
          const rowContent = row[header.key];

          const isPrice = header.key === "price" || header.key === "subtotal";

          return (
            <Text key={key} style={{ ...getColumnWidth(header), ...styles.contentRowValue }}>
              {isPrice ? rowContent || "-" : rowContent}
            </Text>
          );
        })}
      </View>
    );
  });

  const document = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Image style={usedImageStyle} src={usedLogo} />
          <Text style={styles.disclaimer}>{`${disclaimers.createdBy} ${disclaimers.onTime}`}</Text>
        </View>
        <View style={styles.spaceBetween}>
          <Text style={styles.installation}>
            {translateString("installation")}: {selectedInstallationName}
          </Text>
          <Text style={styles.installation}>
            {translateString("spareParts.totalPrice")}:{" "}
            {totalPrice === 0 ? "-" : `${formatCurrency(totalPrice)} ${currency}`}
          </Text>
        </View>
        <View style={styles.flexEnd}>
          <Text style={styles.weight}>
            {translateString("spareParts.estimatedWeight")}: {!totalWeight ? "-" : totalWeight}
          </Text>
        </View>
        <View style={styles.flexEnd}>
          <Text style={styles.note}>{totalPriceNote}</Text>
        </View>
        <Text style={styles.disclaimer}>{disclaimers.notOfficialQuotation}</Text>
        <View style={styles.section}>
          <View style={styles.headers}>{headersContent}</View>
          <View style={styles.content}>{tableContent}</View>
        </View>
        <Text style={styles.disclaimerPrice}>{disclaimers.noVAT}</Text>
        <View style={styles.disclaimerBottom} wrap={false}>
          <Text style={styles.disclaimer}>{disclaimers.terms}</Text>
        </View>
      </Page>
    </Document>
  );

  return (
    <Button
      onClick={async () => {
        const blob = await pdf(document).toBlob();
        saveAs(blob, "basket.pdf");
      }}
    >
      <LocalizedString id="spareParts.exportAsPDF" />
    </Button>
  );
};

export { BasketExportDocument };
