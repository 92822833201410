import { IHeartbeat, IServiceAnnouncement } from "online-services-types";
import { useEffect, useState } from "react";

import { APIFetch } from "src/APIFetch";

let heartbeatTimer: number;
const heartbeatInterval = 180000;

export const useHeartbeat = ({ enabled }: { enabled: boolean }) => {
  const [serviceAnnouncements, setServiceAnnouncements] = useState<IServiceAnnouncement[]>([]);

  useEffect(() => {
    if (enabled) {
      fetchHeartbeat();
      setHeartbeatTimeout();
    } else {
      if (heartbeatTimer) clearTimeout(heartbeatTimer);
    }
  }, [enabled]);

  const setHeartbeatTimeout = () => {
    if (heartbeatTimer) clearTimeout(heartbeatTimer);

    heartbeatTimer = window.setTimeout(() => {
      if (enabled) fetchHeartbeat();
      setHeartbeatTimeout();
    }, heartbeatInterval);
  };

  const fetchHeartbeat = async () => {
    const heartbeat = await new APIFetch<IHeartbeat>(
      "service/heartbeat",
      undefined,
      undefined,
      undefined,
      undefined,
      true
    ).get();
    setServiceAnnouncements(heartbeat?.announcements || []);
  };

  return [serviceAnnouncements];
};

// https://github.com/facebook/react/issues/11538#issuecomment-1890055300
export const useHtmlElementClassObserver = (element: HTMLElement | null) => {
  const [classNames, setClassNames] = useState(Array.from(element ? element.classList : []));

  useEffect(() => {
    if (!element) return;

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "attributes" && mutation.attributeName === "class") {
          setClassNames(Array.from(element.classList));
        }
      });
    });

    observer.observe(element, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return classNames;
};
