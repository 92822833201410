// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const eula_ja: ITranslations = {
  ja: {
    "eula.acceptPrivacyStatement": "{privacyStatement}に同意します",
    "eula.acceptTermsAndConditions": "{termsAndConditions}に同意します",
    "eula.agreeButtonText": "続行する",
    "eula.cancelButtonText": "キャンセル & ログアウト",
    "eula.caption": "Wärtsilä Onlineを使用する前に、プライバシーに関する声明の条項をよく読み、同意する必要があります。",
    "eula.privacyStatement": "プライバシーに関する声明",
    "eula.termsAndConditions": "利用規約",
    "eula.title": "利用規約に同意する",
    "eula.validate.acceptPrivacyStatement": "プライバシーに関する声明に同意する必要があります",
    "eula.validate.acceptTermsAndConditions": "利用規約に同意する必要があります",
  },
};
