import { AnyAction } from "redux";

export interface IHeaderState {
  externalTitle: string;
}

const initialState: IHeaderState = {
  externalTitle: "",
};

interface ISetTitleAction {
  type: string;
  payload: string;
}

export const setHeaderTitle = (title: string): ISetTitleAction => ({
  type: SET_HEADER_TITLE,
  payload: title,
});

const SET_HEADER_TITLE = "SET_HEADER_TITLE";

export const headerReducer = (state = initialState, action: AnyAction): IHeaderState => {
  switch (action.type) {
    case SET_HEADER_TITLE:
      return {
        ...state,
        externalTitle: (action as ISetTitleAction).payload,
      };

    default:
      return state;
  }
};
