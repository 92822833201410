// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const onboarding_ja: ITranslations = {
  ja: {
    "documentsOnboarding.stepFive.content": "ドキュメント情報を表示し、文書が適用される機器の詳細を確認します。",
    "documentsOnboarding.stepFive.title": "文書情報",
    "documentsOnboarding.stepFour.content": "文書ページをクリックしてプレビューに簡単にアクセスできます。",
    "documentsOnboarding.stepFour.content2": "使用済みの検索語はプレビューで強調表示されます。",
    "documentsOnboarding.stepFour.title": "プレビュー",
    "documentsOnboarding.stepOne.content": "1ページですべての文書を簡単に表示、検索、フィルター処理、並べ替えすることができます。",
    "documentsOnboarding.stepOne.title": "あなたの文書",
    "documentsOnboarding.stepThree.content": "ビューを並べ替えて、最も関連性の高い文書を一番上に表示します。",
    "documentsOnboarding.stepThree.title": "並べ替え",
    "documentsOnboarding.stepTwo.content": "さまざまな検索値を使用して、必要なものを見つけます。",
    "documentsOnboarding.stepTwo.content2": "文書タイプで検索をフィルター処理することもできます。",
    "documentsOnboarding.stepTwo.title": "検索とフィルター処理",
    "mainOnboarding.stepFive.content": "ツアーにご参加いただき、ありがとうございます。ご質問がある場合やサポートが必要な場合は、いつでもカスタマーサポートをご利用いただけます。新しい環境をお楽しみください。",
    "mainOnboarding.stepFive.title": "開始の準備ができました!",
    "mainOnboarding.stepFour.content": "利用可能なすべてのサービスを以下に示します。",
    "mainOnboarding.stepFour.title": "あなたのサービス",
    "mainOnboarding.stepOne.content": "基本的な機能をクイックツアーで説明します。これには1分もかかりません。",
    "mainOnboarding.stepOne.title": "新しいWärtsilä Onlineエクスペリエンスへようこそ!",
    "mainOnboarding.stepThree.content": "プロファイルから表示するために選択したすべての設備は、フロントページで簡単に利用できます。特定の設備をクリックすると、それに関連する製品を見つけることができます。",
    "mainOnboarding.stepThree.content:energy": "プロファイルから表示するために選択したすべての発電プラントは、フロントページで簡単に利用できます。特定の発電プラントをクリックすると、それに関連する製品を見つけることができます。",
    "mainOnboarding.stepThree.content:marine": "プロファイルから表示するために選択したすべての船舶は、フロントページで簡単に利用できます。特定の船舶をクリックすると、それに関連する製品を見つけることができます。",
    "mainOnboarding.stepThree.title": "あなたの設備",
    "mainOnboarding.stepThree.title:energy": "あなたの発電プラント",
    "mainOnboarding.stepThree.title:marine": "あなたの船舶",
    "mainOnboarding.stepTwo.content": "プロファイルでは、フロントページに表示する設備を選択し、基本情報を更新し、電子メール設定を整理できます。",
    "mainOnboarding.stepTwo.content2": "このクイックツアーは、あなたのプロファイルからいつでも繰り返すことができます。",
    "mainOnboarding.stepTwo.content:energy": "プロファイルでは、フロントページに表示する発電プラントを選択し、基本情報を更新し、電子メール設定を整理できます。",
    "mainOnboarding.stepTwo.content:marine": "プロファイルでは、フロントページに表示する船舶を選択し、基本情報を更新し、電子メール設定を整理できます。",
    "mainOnboarding.stepTwo.title": "あなたのプロファイル",
    "sparePartsOnboarding.stepAddPartsToCart.content": "「+」ボタンおよび「-」ボタンをクリックするか、数量を入力すると、カートに品目を追加または削除して、小計を表示できます。",
    "sparePartsOnboarding.stepAddPartsToCart.title": "ショッピングカートに部品を追加する",
    "sparePartsOnboarding.stepBrowseParts.content": "製品を選択し、予備部品を閲覧します。",
    "sparePartsOnboarding.stepBrowseParts.content2": "部品名または番号を使用して、予備部品をフィルター処理できます。",
    "sparePartsOnboarding.stepBrowseParts.content3": "また複数の品目を使用して、一度に手動で予備部品を追加することもできます。",
    "sparePartsOnboarding.stepBrowseParts.title": "予備部品を閲覧する",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content": "注文するか、見積を作成します。設備を選択し、予備部品の閲覧を開始します。",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:energy": "注文するか、見積を作成します。発電プラントを選択し、予備部品の閲覧を開始します。",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:marine": "注文するか、見積を作成します。船舶を選択し、予備部品の閲覧を開始します。",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.title": "新しい注文または見積",
    "sparePartsOnboarding.stepOrdersAndQuotations.content": "注文と見積のステータスおよび詳細を表示します。",
    "sparePartsOnboarding.stepOrdersAndQuotations.content2": "このビューでは、見積を介して簡単に注文できます。",
    "sparePartsOnboarding.stepOrdersAndQuotations.title": "注文と見積",
    "sparePartsOnboarding.stepPartDetails.content": "ビューを展開して、予備部品の詳細を表示します。",
    "sparePartsOnboarding.stepPartDetails.content2": "写真から、より詳細な予備部品の拡大図を表示することができます。",
    "sparePartsOnboarding.stepPartDetails.content3": "また、部品の価格、重量、リードタイムも確認できます。",
    "sparePartsOnboarding.stepPartDetails.title": "予備部品の詳細",
    "sparePartsOnboarding.stepShoppingCart.content": "選択した予備部品および合計金額をショッピングカートに表示できます。",
    "sparePartsOnboarding.stepShoppingCart.title": "ショッピングカート",
    "sparePartsOnboarding.stepYourSpareParts.content": "すべての注文および見積の概要を1ページで簡単に確認できます。ビューをフィルター処理し、すべての履歴を表示することができます。",
    "sparePartsOnboarding.stepYourSpareParts.content2": "予備部品を閲覧したり、新しい注文や見積を作成したり、予備部品請求を作成したりします。",
    "sparePartsOnboarding.stepYourSpareParts.title": "コマースページ",
    "supportOnboarding.stepFive.content": "組織の誰でも、リクエストに関するコメントを追加することができ、またWärtsiläに問い合わせることができます。",
    "supportOnboarding.stepFive.title": "備考",
    "supportOnboarding.stepFour.content": "添付ファイルは、リクエストの進行中にいつでも追加できます。",
    "supportOnboarding.stepFour.title": "添付書類",
    "supportOnboarding.stepOne.content": "すべてのリクエストの概要を1ページで簡単に確認できます。ビューをフィルター処理または並べ替えて、リクエストのステータスおよびすべての履歴を表示できます。",
    "supportOnboarding.stepOne.title": "あなたのサポートページ",
    "supportOnboarding.stepSeven.content": "1つの文書ですべてのリクエストを取得するには、それらを簡単にエクスポートできます。",
    "supportOnboarding.stepSeven.title": "リクエストをエクスポートする",
    "supportOnboarding.stepSix.content": "リクエストが完了したら、評価してフィードバックを送信するか、リクエストを再度開くことができます。",
    "supportOnboarding.stepSix.title": "サービスを評価する",
    "supportOnboarding.stepThree.content": "展開すると、リクエストの詳細や追加情報が表示されます",
    "supportOnboarding.stepThree.title": "あなたのリクエスト",
    "supportOnboarding.stepTwo.content": "すべてのリクエストを1か所で作成します。",
    "supportOnboarding.stepTwo.title": "新しいリクエストを作成する",
  },
};
