import { Field } from "formik";
import React, { useState } from "react";

import { Button } from "src/design-system/Button";
import { Container } from "src/design-system/Container";
import { TextArea } from "src/design-system/TextArea";
import { translateString } from "src/util/localization";
import { FormDialog } from "../Dialog";
import { LocalizedString } from "../Localization";

interface ICommmentFieldProps {
  comment: string;
  limit?: number;
  onChange?: (comment: string) => void;
}

interface ICommentForm {
  comment: string;
}

export const CommentField = ({ comment, limit, onChange }: ICommmentFieldProps) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <FormDialog<ICommentForm>
        isDialogOpen={isDialogOpen}
        onSubmit={(data) => {
          if (onChange) onChange(data.comment);
          setDialogOpen(false);
        }}
        initialValues={{ comment }}
        title={translateString("spareParts.editAdditionalInformation")}
        submitButtonTitle={translateString("spareParts.saveInformation")}
        validate={() => ({})}
        onCancel={() => setDialogOpen(false)}
      >
        <Field label={translateString("spareParts.comment")} component={TextArea} name="comment" maxlength={limit}/>
      </FormDialog>
      <Container $margin={[0.5, 0, 3, 0]} $padding={[0, 0, 3, 0]}>
        {comment}
      </Container>
      {onChange && (
        <Button onClick={() => setDialogOpen(true)}>
          <LocalizedString id="spareParts.addAdditionalInformation"/>
        </Button>
      )}
    </>
  );
};
