// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const user_es_MX: ITranslations = {
  es_MX: {
    "user.addNewUser": "Agregar usuario",
    "user.allUsersInAccount": "Todos los usuarios en su cuenta",
    "user.department": "Departamento",
    "user.digest.addAllInstallations": "Agregar todas las instalaciones",
    "user.digest.addAllInstallations:energy": "Agregar todas las plantas de energía",
    "user.digest.addAllInstallations:marine": "Agregar todas las embarcaciones",
    "user.digest.period.daily": "Diariamente",
    "user.digest.period.monthly": "Mensualmente",
    "user.digest.period.never": "Nunca",
    "user.digest.period.weekly": "Semanalmente",
    "user.digest.service.reports": "Reportes",
    "user.digest.service.requests": "Solicitudes",
    "user.digest.service.spareParts": "Partes de repuesto",
    "user.digest.service.technicalDocuments": "Documentos técnicos",
    "user.digest.validation.noInstallationsSelected": "Se requieren instalaciones.",
    "user.digest.validation.noInstallationsSelected:energy": "Se requieren plantas de energía.",
    "user.digest.validation.noInstallationsSelected:marine": "Se requieren embarcaciones.",
    "user.digest.validation.noServicesSelected": "Se requieren servicios",
    "user.digestDescription.daily": "Diario para una instalación",
    "user.digestDescription.daily.plural": "Diario para {numberOfInstallations} instalaciones",
    "user.digestDescription.daily.plural:energy": "Diario para {numberOfInstallations} plantas de energía",
    "user.digestDescription.daily.plural:marine": "Diario para {numberOfInstallations} embarcaciones",
    "user.digestDescription.daily:energy": "Diario para una planta de energía",
    "user.digestDescription.daily:marine": "Diario para una embarcación",
    "user.digestDescription.monthly": "Mensual para una instalación",
    "user.digestDescription.monthly.plural": "Mensual para {numberOfInstallations} instalaciones",
    "user.digestDescription.monthly.plural:energy": "Mensual para {numberOfInstallations} plantas de energía",
    "user.digestDescription.monthly.plural:marine": "Mensual para {numberOfInstallations} embarcaciones",
    "user.digestDescription.monthly:energy": "Mensual para una planta de energía",
    "user.digestDescription.monthly:marine": "Mensual para una embarcación",
    "user.digestDescription.never": "Desactivado",
    "user.digestDescription.weekly": "Semanal cada domingo para una instalación",
    "user.digestDescription.weekly.plural": "Semanal cada domingo para {numberOfInstallations} instalaciones",
    "user.digestDescription.weekly.plural:energy": "Semanal cada domingo para {numberOfInstallations} plantas de energía",
    "user.digestDescription.weekly.plural:marine": "Semanal cada domingo para {numberOfInstallations} embarcaciones",
    "user.digestDescription.weekly:energy": "Semanal cada domingo para una planta de energía",
    "user.digestDescription.weekly:marine": "Semanal cada domingo para una embarcación",
    "user.digestEmail": "Resumen de correo electrónico",
    "user.digestEmailSettings": "Ajustes de resumen de correo electrónico",
    "user.digestSettingsForm.frequencyOfDigest": "Frecuencia de resumen",
    "user.digestSettingsForm.servicesOfInterest": "Servicios de interés",
    "user.email": "Dirección de correo electrónico",
    "user.emailIsRequired": "Se requiere correo electrónico",
    "user.emailShort": "Correo electrónico",
    "user.firstname": "Nombre",
    "user.firstnameIsRequired": "Se requiere nombre",
    "user.lastname": "Apellido",
    "user.lastnameIsRequired": "Se requiere apellido",
    "user.name": "Nombre",
    "user.notificationSettings": "Ajustes de notificación",
    "user.phone": "Teléfono",
    "user.phoneNumber": "Número de teléfono",
    "user.reasonForRemoval": "Razón de eliminación",
    "user.reasonForRemovalIsRequired": "Se requiere razón para la eliminación",
    "user.removeUser": "Eliminar usuario",
    "user.title": "Título",
    "user.userInformation": "Información del usuario",
    "user.userPassword": "Contraseña",
  },
};
