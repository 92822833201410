import * as React from "react";

import { ActionsWrapper, ContentWrapper, DialogHeader } from "./Dialog";
import { Button, ButtonStyle } from "src/design-system/Button";
import { Form, Formik, FormikTouched } from "formik";
import { Modal, ModalSize } from "./Modal";

import { FormikProps } from "formik/dist/types";
import { IErrors } from "src/util/validators";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import styled from "styled-components";

/* --------- example of using FormDialog -------------

  interface IMyForm {
    name: string;
    email: string;
  }

  export const SomeComponent = () => {
    return (
      <FormDialog<IMyForm>
        title="My form"
        submitButtonTitle="Submit"
        isDialogOpen={true}
        onCancel={() => console.log('cancel')}
        onSubmit={values => console.log(values)}
        initialValues={{ name: '', email: '' }}
        validate={values => validateForm(values, {
          'name': [{valErr: 'Name is required', valFn: validators.isRequired}],
        })}
      >
        <Field name="name" label="Your name" component={TextInput}/>
        <Field name="email" label="Your email (optional)" component={TextInput}/>
      </FormDialog>
    );
  };

*/

const FormModalWrapper = styled.div`
  & form {
    height: 100%;
  }
`;

export interface IFormDialogProps<T> {
  initialValues: T;
  title: string;
  submitButtonTitle: string;
  cancelButtonTitle?: string;
  isDialogOpen: boolean;
  children: ((props: FormikProps<T>) => React.ReactChild[] | React.ReactChild) | React.ReactChild[] | React.ReactChild;
  size?: ModalSize;
  hideButtons?: boolean;
  noOverflow?: boolean;
  overflowType?: string;
  isLoading?: boolean;
  initialTouched?: FormikTouched<T>;
  validate(values: T): IErrors;
  onSubmit(values: T): void;
  onCancel(): void;
  onAfterOpen?(): void;
  isSubmitDisabled?: boolean;
}

export const FormDialog = <T,>(props: IFormDialogProps<T>) => {
  return (
    <Modal
      size={props.size}
      isOpen={props.isDialogOpen}
      onAfterOpen={props.onAfterOpen}
      overflowType={props.overflowType}
    >
      <DialogHeader title={props.title} onCancel={props.onCancel} />
      {!props.isLoading ? (
        <Formik
          validateOnMount
          enableReinitialize
          validate={props.validate}
          onSubmit={props.onSubmit}
          initialValues={props.initialValues}
          initialTouched={props.initialTouched}
        >
          {(formProps) => (
            <FormModalWrapper>
              <Form>
                <ContentWrapper noOverflow={props.noOverflow} overflowType={props.overflowType}>
                  {typeof props.children === "function" ? props.children(formProps) : props.children}
                </ContentWrapper>

                {props.hideButtons ? null : (
                  <ActionsWrapper>
                    <Button buttonStyle={ButtonStyle.Secondary} onClick={props.onCancel}>
                      {props.cancelButtonTitle || <LocalizedStringComponent id="button.cancel" />}
                    </Button>
                    <Button disabled={props.isSubmitDisabled} type="submit">
                      {props.submitButtonTitle}
                    </Button>
                  </ActionsWrapper>
                )}
              </Form>
            </FormModalWrapper>
          )}
        </Formik>
      ) : (
        <LoadingSpinner dark={true} />
      )}
    </Modal>
  );
};
