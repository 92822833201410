import { APIFetch } from "src/APIFetch";
import { IManagerDashboardRequest, IManagerDashboardResponseBase, ManagerDashboardRequestType } from "./interfaces";
import { displayError } from "src/util/error";
import { translateString } from "src/util/localization";
import { IError } from "online-services-types";

export const makeDashboardRequest = async <T extends IManagerDashboardResponseBase>(
  type: ManagerDashboardRequestType,
  days: number,
  installationIds: string[]
) => {
  const params: IManagerDashboardRequest = {
    type,
    days,
    installations: installationIds.join(","),
  };

  try {
    return (await new APIFetch<T[]>("service/manager-dashboard").get(undefined, params))[0];
  } catch (error) {
    displayError(translateString("managerDashboard.error"), undefined, undefined, (error as IError).awsRequestId);
    throw error;
  }
};
