import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";
export const DayIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Day</title>
    <path
      d="M24.0114286,39.5542857 L26.2057143,40.6628571 L26.2285714,40.6628571 L26.2285714,
    44 L21.7828571,44 L21.7828571,40.6628571 L21.7942857,40.6628571 L24.0114286,
    39.5542857 Z M10.6628571,34.2171429 L13.0057143,35.0057143 L13.7828571,37.3371429 L13.7942857,
    37.3485714 L11.4285714,39.7142857 L8.29714286,36.5714286 L10.6514286,34.2171429 L10.6628571,
    34.2171429 Z M37.3485714,34.2057143 L39.7142857,36.5714286 L36.5714286,39.7028571 L34.2171429,
    37.3485714 L34.2171429,37.3371429 L35.0057143,34.9942857 L37.3371429,34.2171429 L37.3485714,
    34.2057143 Z M24,10.6857143 C31.3506652,10.6920158 37.3079842,16.6493348 37.3142857,
    24 C37.3142857,31.353277 31.353277,37.3142857 24,37.3142857 C16.646723,37.3142857 10.6857143,
    31.353277 10.6857143,24 C10.6857143,16.646723 16.646723,10.6857143 24,10.6857143 Z M24,
    15.12 C19.0983218,15.1262963 15.1262963,19.0983218 15.12,24 C15.12,28.9042886 19.0957114,
    32.88 24,32.88 C28.9042886,32.88 32.88,28.9042886 32.88,24 C32.88,19.0957114 28.9042886,
    15.12 24,15.12 Z M7.34857143,21.7828571 L7.34857143,21.7942857 L8.44571429,24.0114286 L7.34857143,
    26.2057143 L7.34857143,26.2285714 L4,26.2285714 L4,21.7828571 L7.34857143,21.7828571 Z M44,
    21.7714286 L44,26.2171429 L40.6628571,26.2171429 L40.6628571,26.2057143 L39.5542857,
    23.9885714 L40.6628571,21.7942857 L40.6628571,21.7714286 L44,21.7714286 Z M11.4285714,
    8.29714286 L13.7828571,10.6514286 L13.7828571,10.6628571 L12.9942857,13.0057143 L10.6628571,
    13.7828571 L10.6514286,13.7942857 L8.28571429,11.4285714 L11.4285714,8.29714286 Z M36.5714286,
    8.28571429 L39.7028571,11.4285714 L37.3485714,13.7828571 L37.3371429,13.7828571 L34.9942857,
    12.9942857 L34.2171429,10.6628571 L34.2057143,10.6514286 L36.5714286,8.28571429 Z M26.2171429,
    4 L26.2171429,7.34857143 L26.2057143,7.34857143 L23.9885714,8.44571429 L21.7942857,
    7.34857143 L21.7714286,7.34857143 L21.7714286,4 L26.2171429,4 Z"
    ></path>
  </IconBase>
);
