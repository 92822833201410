// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const reports_zh_CN: ITranslations = {
  zh_CN: {
    "reports.attachments": "附件",
    "reports.conditionMonitorinReports": "资产诊断报告",
    "reports.newReportAvailable": "新报告可用",
    "reports.noReportsAvailable": "无报告可用",
    "reports.reportPeriodStartDate": "期间",
    "reports.reportPublishedDate": "发布日期",
    "reports.title": "标题",
  },
};
