// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const contracts_pt_BR: ITranslations = {
  pt_BR: {
    "contracts.attachmentDocumentTypes": "Documentos ({types})",
    "contracts.attachmentImageTypes": "Imagens ({types})",
    "contracts.attachmentsMessage": "Você pode atualizar os tipos a seguir de anexos com um tamanho máximo de arquivo de {maxSize}.",
    "contracts.cbmExpertName": "Diagnóstico do ativo",
    "contracts.contact": "Contato",
    "contracts.contactInformation": "Informações de contato",
    "contracts.contactPerson": "Pessoa de contato",
    "contracts.contractManager": "Gerente de contrato",
    "contracts.contractNumber": "Número do contrato",
    "contracts.contractType": "Tipo",
    "contracts.equipmentContracts": "Contratos de produto",
    "contracts.expertiseCenter": "Centro de expertise",
    "contracts.expiry": "Data de vencimento",
    "contracts.installationContracts": "Contratos de instalação",
    "contracts.installationContracts:energy": "Contratos de usinas de energia",
    "contracts.installationContracts:marine": "Contratos de embarcações",
    "contracts.installationsInScope": "Instalações no escopo",
    "contracts.installationsInScope:energy": "Usinas de energia no escopo",
    "contracts.installationsInScope:marine": "Embarcações no escopo",
    "contracts.invalidContract": "Contrato inválido",
    "contracts.maintenancePlannerName": "Planejamento de manutenção",
    "contracts.noContactPerson": "Nenhuma pessoa de contato",
    "contracts.noEquipmentContractsAvailable": "Nenhum contrato de produto disponível",
    "contracts.noInstallationContractsAvailable": "Nenhum contrato de instalação disponível",
    "contracts.noInstallationContractsAvailable:energy": "Nenhum contrato de usina de energia disponível",
    "contracts.noInstallationContractsAvailable:marine": "Nenhum contrato de embarcação disponível",
    "contracts.productNumbers": "Números do produto",
    "contracts.productReferenceTypes": "Tipos de referência do produto",
    "contracts.remoteSupporterName": "Suporte remoto",
    "contracts.startDate": "Data inicial",
    "contracts.technicalExperts": "Especialistas técnicos",
    "contracts.validation.messageIsRequired": "A mensagem é exigida",
    "contracts.validation.subjectIsRequired": "O assunto é exigido",
  },
};
