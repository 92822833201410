import { Dispatch, bindActionCreators } from "redux";
import { IDispatch, IOwnProps, IStoreProps } from "./types";
import { addItem, decreaseItemNum, removeItem } from "src/redux/basket";

import { AddToBasketButtonsComponent } from "./AddToBasketButtonsComponent";
import { IAppState } from "src/redux";
import { connect } from "react-redux";

const mapStateToProps = (state: IAppState, ownProps: IOwnProps): IStoreProps => ({
  basket: ownProps.externalBasket || state.basket,
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatch =>
  bindActionCreators(
    {
      addItem: (item, quantity) => addItem(item, quantity),
      removeItem: (item) => removeItem(item),
      decreaseItemNum: (item) => decreaseItemNum(item),
    },
    dispatch
  );

export const AddToBasketButtonsContainer = connect<IStoreProps, IDispatch>(
  mapStateToProps,
  mapDispatchToProps
)(AddToBasketButtonsComponent);
