import * as React from "react";

import {
  BasketButtonsRenderer,
  ExternalBasketButtonsRenderer,
  cylinderMetadata,
  descriptionMetadata,
  idMetadata,
  netPriceMetadata,
  netWeightMetadata,
  pageMetadata,
  positionMetadata,
  qtySectionMetadata,
  spnDescriptionMetadata,
} from "./PartsMetadata";
import { IEquipment, ILineItem, IPart, IPartModel, IPartsAvailabilityQueryParams } from "online-services-types";

import { APIAbortHandle } from "src/redux/APIAbortHandle";
import { Button } from "src/design-system/Button";
import { Container } from "src/design-system/Container";
import { IAPICallParams } from "src/APIFetch";
import { IBasketState } from "src/redux/basket";
import { IPartAvailabilityState } from "src/redux/partsAvailability";
import { IPartsCatalogSectionViewModel } from "src/models/catalogueSections";
import { IResponsiveTableColumn } from "src/components/ResponsiveTable/interfaces";
import { LeadText } from "src/components/PartsCatalogueList/index";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { LocalizedString } from "../Localization";
import { PartImages } from "src/components/PartsCatalogueList/PartImagesContainer";
import { ResponsiveTable } from "src/components/ResponsiveTable";
import SystemHeader from "./SystemHeader";
import colors from "src/design-system/Tokens/colors";
import moment from "moment";
import { sparePartsDateFormat } from "src/util/formatters";
import styled from "styled-components";
import { translateString } from "src/util/localization";

const MessageBox = styled(Container)`
  margin-top: 10px;
  padding: 10px 16px;
  line-height: 1.5;
  border: 1px solid ${colors.primary.blue};
`;

const NoPartsMessage = styled(Container)`
  color: ${(props) => props.theme.text};
`;

export interface IPartsCatalogueListOwnProps {
  disableManualAdd?: boolean;
  hidePricesAndAvailability?: boolean;
  manualInput?: boolean;
  // delay loading spinner until actual API requests are fired
  filterSubmitted?: boolean;
  select?: (itemId: string) => void;
  selectAddManuallySwitchButton: () => void;
  onToggleEditDialog?: () => void;
  selected?: string;
  filters: {
    equipment: IEquipment | null;
    freeText?: string;
  };
  externalBasket?: IBasketState;
  useOnboardingMockData?: boolean;
  onChangeItemNumber?(item: IPartModel, amount: number): void;
}

export interface IPartsCatalogueListStateProps {
  sections: IPartsCatalogSectionViewModel[];
  partsAvailability: IPartAvailabilityState;
  isReady?: boolean;
  equipments: IEquipment[];
}

export interface IPartsCatalogueListDispatchProps {
  addItem: (item: IPartModel, quantity?: number) => void;
  getSections(params: IAPICallParams): void;
  getParts(params: IAPICallParams, abortHandle?: APIAbortHandle, callback?: (result: IPart[]) => void): void;
  getMultiplePartAvailability(
    equipmentId: string,
    parts: IPartsAvailabilityQueryParams[],
    codeResolution?: boolean
  ): void;
}

export type IPartsCatalogueListPropsNoContainer = IPartsCatalogueListStateProps &
  IPartsCatalogueListOwnProps &
  IPartsCatalogueListDispatchProps;
export type IPartsCatalogueListProps = IPartsCatalogueListPropsNoContainer;

const isHugeSubSection = (
  equipment: IEquipment | undefined,
  isHugePartsList: boolean,
  sections: IPartsCatalogSectionViewModel[]
): boolean => {
  return equipment
    ? equipment.hasCodeResolution &&
        sections.filter((s) => s.upperLevelSectionId === "").length === 1 &&
        isHugePartsList
    : false;
};

export class PartsCatalogueListComponent extends React.Component<IPartsCatalogueListProps> {
  state = {
    fetchingPartsFor: null,
  };

  private readonly columns: IResponsiveTableColumn[] = [
    descriptionMetadata,
    {
      key: "images",
      label: translateString("spareParts.pictures"),
      alwaysHidden: true,
      hideEmptyValues: false,
      priority: 2,
      plannedWidth: 100,
      renderer: ({ row }) => {
        const section = row as IPartsCatalogSectionViewModel;
        return section !== undefined ? (
          <PartImages
            sectionId={section.id}
            productReferenceType={this.props.filters.equipment && this.props.filters.equipment.productReferenceType}
            images={section.images}
            useOnboardingMockData={!!this.props.useOnboardingMockData}
          />
        ) : (
          ""
        );
      },
    },
  ];

  private readonly subListColumns = (): Array<IResponsiveTableColumn<ILineItem>> => {
    return [
      spnDescriptionMetadata(),
      pageMetadata,
      positionMetadata,
      idMetadata(),
      {
        key: "leadText",
        label: translateString("spareParts.leadTime"),
        priority: 4,
        plannedWidth: 160,
        valueRenderer: ({ row }) => {
          return <LeadText row={row} equipment={this.props.equipments} />;
        },
      },
      netWeightMetadata,
      netPriceMetadata(),
      {
        key: "buttons",
        priority: 11,
        plannedWidth: 420,
        alwaysShown: true,
        staticWidth: true,
        renderer: ({ row }) => {
          return (
            <BasketButtonsRenderer
              part={row}
              onChangeItemNumber={this.props.onChangeItemNumber}
              externalBasket={this.props.externalBasket}
              hidePrices={this.props.hidePricesAndAvailability}
              className={"spareparts-onboarding-add-parts-to-cart"}
            />
          );
        },
      },
      cylinderMetadata,
      qtySectionMetadata,
    ];
  };

  private readonly subListColumnsWithoutPrice = (isHugeSection: boolean): Array<IResponsiveTableColumn<ILineItem>> => {
    return [
      spnDescriptionMetadata(),
      pageMetadata,
      positionMetadata,
      idMetadata(),
      netWeightMetadata,
      {
        key: "buttons",
        priority: 11,
        plannedWidth: 165,
        alwaysShown: true,
        renderer: ({ row }) => {
          return (
            <ExternalBasketButtonsRenderer
              part={row}
              onChangeItemNumber={this.props.onChangeItemNumber}
              externalBasket={this.props.externalBasket}
              hidePrices={this.props.hidePricesAndAvailability || isHugeSection}
              className={"spareparts-onboarding-add-parts-to-cart"}
            />
          );
        },
      },
      cylinderMetadata,
      qtySectionMetadata,
    ];
  };

  public sortByPartNumber = (partA: IPartModel, partB: IPartModel) => {
    if (partA.id < partB.id) {
      return -1;
    }

    if (partA.id > partB.id) {
      return 1;
    }

    return 0;
  };

  public readonly renderSubList = (item: IPartsCatalogSectionViewModel): JSX.Element => {
    let isFetching = false;

    const equipment: IEquipment | undefined = this.props.equipments.find(
      (equipment) => equipment.id === item.equipmentId
    );
    const isHugeSection: boolean = isHugeSubSection(
      equipment,
      item.parts !== null && item.parts.length > 60,
      this.props.sections
    );

    if (this.state.fetchingPartsFor) {
      const { sectionId, equipmentId } = this.state.fetchingPartsFor;
      isFetching = sectionId === item.id && equipmentId === item.equipmentId;
    }

    if (!isFetching && item.parts === null) {
      return <NoPartsMessage $margin={[2, 0]}>{translateString("spareParts.noPartsForPurchase")}</NoPartsMessage>;
    }

    return (
      <ResponsiveTable
        isSubList={true}
        isSublistSortingHidden
        keyExtractor={(row, idx) => row.frontendKey || `${row.id}-${idx}`}
        rows={item.parts?.sort(this.sortByPartNumber)}
        isItemOpen={(listItem) => !!this.props.useOnboardingMockData && listItem.id === "101"}
        columns={
          this.props.hidePricesAndAvailability || isHugeSection
            ? this.subListColumnsWithoutPrice(isHugeSection)
            : this.subListColumns()
        }
      />
    );
  };

  public readonly fetchDetails = async (item: IPartsCatalogSectionViewModel) => {
    if (!this.props.useOnboardingMockData) {
      if (item.parts !== null) {
        const params: IPartsAvailabilityQueryParams[] = [];
        item.parts.forEach((part: IPart) => {
          if (!this.props.hidePricesAndAvailability) {
            const availability = this.props.partsAvailability.availability.find(
              (el) => el.materialId === part.materialId && el.equipmentId === part.equipmentId
            );
            if (!availability) {
              params.push({
                partId: part.id,
                cylinder: part.cylinder,
                materialId: part.materialId,
                date: moment().format(sparePartsDateFormat),
                quantity: 1,
                equipmentId: part.equipmentId,
              });
            }
          }
        });
        const equipment = this.props.equipments.find((equipment) => equipment.id === item.equipmentId);
        const isHugeSection: boolean = isHugeSubSection(equipment, item?.parts.length > 60, this.props.sections);
        if (!isHugeSection) {
          this.props.getMultiplePartAvailability(
            item.equipmentId,
            params,
            equipment !== undefined ? equipment.hasCodeResolution : undefined
          );
        }
      } else {
        const params: IPartsAvailabilityQueryParams[] = [];
        this.setState({
          fetchingPartsFor: {
            sectionId: item.id,
            equipmentId: item.equipmentId,
          },
        });

        this.props.getParts({ equipment_id: item.equipmentId, section_id: item.id }, undefined, (parts: IPart[]) => {
          if (!this.props.hidePricesAndAvailability) {
            parts.forEach((part: IPart, index) => {
              params.push({
                partId: part.id,
                cylinder: part.cylinder,
                materialId: part.materialId,
                date: moment().format(sparePartsDateFormat),
                quantity: 1,
                equipmentId: part.equipmentId,
                item_number: index,
              });
            });

            const equipment = this.props.equipments.find((equipment) => equipment.id === item.equipmentId);
            const isHugeSection: boolean = isHugeSubSection(equipment, parts.length > 60, this.props.sections);
            if (!isHugeSection) {
              this.props.getMultiplePartAvailability(
                item.equipmentId,
                params,
                equipment !== undefined ? equipment.hasCodeResolution : undefined
              );
            }
          }

          this.setState({
            fetchingPartsFor: null,
          });
        });
      }
    }

    return null;
  };

  public render() {
    let sortedSections = {};
    let renderList: React.ReactNode[] = [];
    let information: React.ReactNode;
    const { sections, isReady, filters, filterSubmitted = true } = this.props;
    if (!this.props.useOnboardingMockData && (!filters.equipment || !filterSubmitted)) {
      information = (
        <MessageBox>
          <LocalizedString id={"spareParts.pleaseSelectProductFromList"} />
        </MessageBox>
      );
    } else if (isReady) {
      if (sections.length === 0) {
        if (filters.freeText) {
          information = (
            <>
              <MessageBox $marginBottom={2}>
                {`${translateString("spareParts.noFilteredPartFound")} ${filters.freeText}.`}
                <br />
                {translateString("spareParts.checkTypingMistakes")}
                {this.props.onToggleEditDialog ? (
                  <Container $margin={[1, 0]}>
                    <Button onClick={this.props.onToggleEditDialog}>
                      {translateString("request.noSparePartNumber")}
                    </Button>
                  </Container>
                ) : null}
              </MessageBox>
            </>
          );
        } else {
          information = (
            <div>
              <MessageBox>{`${translateString("spareParts.noCatalogueFound")} ${
                !!filters.equipment && filters.equipment.description
              }`}</MessageBox>
              {!this.props.disableManualAdd && (
                <Container $margin={[3, 0, 0, 7]}>
                  <div>{translateString("spareParts.youCanAddPartsUsingFollowingAction")}:</div>
                  <Container $margin={[2, 0, 0, 0]}>
                    <Button onClick={() => this.props.selectAddManuallySwitchButton()}>
                      {translateString("spareParts.addManually")}
                    </Button>
                  </Container>
                </Container>
              )}
            </div>
          );
        }
      } else {
        sortedSections = this.sortSections(sections);

        renderList = Object.keys(sortedSections)
          .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
          .filter((system) => sortedSections[system].sections.length !== 0)
          .map((system) => {
            return (
              <div key={system}>
                <SystemHeader title={`${system} ${sortedSections[system].title}`} />
                <ResponsiveTable
                  sort={{ key: "id", asc: true }}
                  columns={this.columns}
                  rows={sortedSections[system].sections}
                  sublistRenderer={this.renderSubList}
                  getRowDetails={this.fetchDetails}
                  isItemOpen={(item) => !!this.props.useOnboardingMockData && item.id === "999-001"}
                  className={"spareparts-onboarding-part-details"}
                />
              </div>
            );
          });
      }
    }

    return information || (isReady && renderList) || <LoadingSpinner />;
  }

  private readonly sortSections = (sections: IPartsCatalogSectionViewModel[]) => {
    return sections.reduce((systems, section) => {
      if (section.upperLevelSectionId) {
        if (!systems[section.upperLevelSectionId]) {
          systems[section.upperLevelSectionId] = {
            title: "",
            sections: [section],
          };
        } else {
          systems[section.upperLevelSectionId].sections.push(section);
        }
      } else {
        if (!systems[section.id]) {
          systems[section.id] = {
            title: section.description,
            sections: [],
          };
        } else {
          systems[section.id].title = section.description;
        }
      }

      return systems;
    }, {});
  };
}
