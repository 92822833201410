import * as React from "react";
import { getCurrentRoute, getRoutes, redirectBrowser } from "src/routes";
import { displayError } from "src/util/error";
import { translateString } from "src/util/localization";

export class RedirectToIndex extends React.Component<{ location: string }> {
  public componentWillMount() {
    // Manually check for an existing route since Switch is rendering this component
    // even though other routes match before this.
    if (!getCurrentRoute()) {
      displayError(
        translateString("main.pageNotFound", {
          url: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        }),
        translateString("main.pageNotFoundTitle")
      );
      redirectBrowser(getRoutes().Index);
    }
  }

  public render() {
    return null;
  }
}
