import * as React from "react";
import { IconBase, IIconBase } from "src/icons/IconBase";

export const WaterSolutionIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Water Solution</title>
    <path d="M23.9743996,10.11 C26.9331694,13.5160902 29.6087348,17.1582036 31.9743996,21 C32.9162022,22.5560818 33.7257113,24.1884804 34.3943996,25.88 C35.0207438,27.4501909 35.1464815,29.1755918 34.7543996,30.82 L34.7543996,30.88 L34.7543996,30.94 C34.3463794,33.0133604 33.3740097,34.933877 31.9443996,36.49 C31.6366133,36.8325159 31.302239,37.1501715 30.9443996,37.44 L30.8543996,37.52 L30.7543996,37.59 C28.8458016,39.1615386 26.4467012,40.0143162 23.9743996,40.0001774 C18.4247072,39.8975751 13.7954611,35.728696 13.1143996,30.22 L13.1143996,30.13 C13.1143996,29.93 13.0543996,29.74 13.0343996,29.54 C12.9216886,28.1365616 13.1731321,26.7277894 13.7643996,25.45 C14.9641033,22.6929619 16.4595282,20.0742918 18.2243996,17.64 C19.9443996,15.15 21.8543996,12.64 24.0043996,10.11 M23.9743996,4 C23.8543996,4.15 23.7943996,4.25 23.7243996,4.33 C20.5648137,7.80979483 17.6257538,11.4836198 14.9243996,15.33 C12.995745,18.0055866 11.3628699,20.8823973 10.0543996,23.91 C9.20100947,25.8090475 8.85638797,27.8974538 9.05439962,29.97 C9.05439962,30.23 9.11439962,30.48 9.15439962,30.73 C10.0793804,38.2377823 16.4104275,43.9066753 23.9743996,44 C27.3739494,43.9986195 30.6677904,42.8182087 33.2943996,40.66 L33.3543996,40.66 L33.5343996,40.51 C34.0201872,40.0976839 34.4778739,39.6533603 34.9043996,39.18 C36.8072177,37.0881544 38.1006862,34.5150514 38.6443996,31.74 C39.22477,29.3135728 39.0396258,26.7669668 38.1143996,24.45 C37.371813,22.5431889 36.4615807,20.705992 35.3943996,18.96 C32.8696455,14.8330556 30.0064696,10.9229474 26.8343996,7.27 C25.9143996,6.19 24.9743996,5.14 23.9743996,4 Z"></path>
    <path d="M32.9743996,29 L28.9743996,29 C28.9743996,31.7614237 26.7358234,34 23.9743996,34 L23.9743996,38 C26.361348,38 28.6505332,37.0517884 30.3383606,35.363961 C32.0261881,33.6761336 32.9743996,31.3869484 32.9743996,29 L32.9743996,29 Z"></path>
  </IconBase>
);
