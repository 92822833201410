import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const AimingIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>aiming</title>
    <path d="M24,15a9,9,0,1,0,9,9A9,9,0,0,0,24,15Zm0,14a5,5,0,1,1,5-5A5,5,0,0,1,24,29Z" />
    <path d="M44,22H39.86A16,16,0,0,0,26,8.14V4H22V8.14A16,16,0,0,0,8.14,22H4v4H8.14A16,16,0,0,0,22,39.86V44h4V39.86A16,16,0,0,0,39.86,26H44ZM24,36A12,12,0,1,1,36,24,12,12,0,0,1,24,36Z" />
  </IconBase>
);
