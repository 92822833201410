import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const ManagerDashboardIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Dashboard</title>
    <path d="M24,9A17,17,0,0,0,12.73,38.72l2.83-2.83h0a13,13,0,0,1-4.17-6.76v0c-.08-.32-.14-.65-.2-1v0A12.6,12.6,0,0,1,11,26a13,13,0,0,1,26,0,12.6,12.6,0,0,1-.18,2.05v0c-.06.34-.12.67-.2,1v0a13,13,0,0,1-4.17,6.76h0l2.83,2.83A17,17,0,0,0,24,9Z" />
    <path d="M25,24.28V15H23v9.28A2,2,0,0,0,22,26a2,2,0,0,0,4,0A2,2,0,0,0,25,24.28Z" />
  </IconBase>
);
