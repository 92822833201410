import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const VideoIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>video</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 32V17L30 24.5L19 32ZM44 9V40H37V38H11V40H4V9H11V11H37V9H44ZM9 35H6V38H9V35ZM9 29H6V32H9V29ZM9 23H6V26H9V23ZM9 17H6V20H9V17ZM9 11H6V14H9V11ZM37 15H11V34H37V15ZM42 35H39V38H42V35ZM42 29H39V32H42V29ZM42 23H39V26H42V23ZM42 17H39V20H42V17ZM42 11H39V14H42V11Z"
      fill={props.color}
    />
  </IconBase>
);
