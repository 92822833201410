import * as React from "react";
import {
  findStringForSector,
  findStringFromTranslations,
  getGlobalLocalizationInfo,
  getSectorPostFix,
  ITranslations,
  Sector,
} from "src/util/localization";
import { isString, isNumber } from "util";

/**
 * LocalizedStringComponen takes the string key (as in translations.ts)
 * as a prop and an optional value hashmap (values marked like {this} are
 * replaced with the map values).
 *
 * The component will try to find a relevant string for the current sector
 * "context", as in using the key "stringName.marine" and if that is not found
 * "stringName" will be used instead.
 *
 * The same logic goes for languages, if a string key is not found for the
 * current language, the component will fall back to the default language.
 *
 * The component will use the language and sector context settings as set by
 * setSector() and setLanguage() on init time.
 */

interface IKeyValueElementPair {
  [key: string]: string | number | JSX.Element;
}

export interface ILocalizedStringComponentStateProps {
  languageCode?: string | null;
}

export interface ILocalizedStringComponentOwnProps {
  // String key
  id: string;
  // Optional string replacement values
  values?: IKeyValueElementPair;
  // Translation table (for tests mainly)
  translations?: ITranslations;
}

export type ILocalizedStringComponentProps = ILocalizedStringComponentOwnProps & ILocalizedStringComponentStateProps;

// Replaces JSX elements inside a translation

function replaceElements(
  languageCode: string,
  key: string,
  values: IKeyValueElementPair,
  localTranslations?: ITranslations
): JSX.Element | undefined {
  const localizedString = findStringFromTranslations(languageCode, key, localTranslations);

  if (!localizedString) {
    return undefined;
  }

  // The following first splits the incoming translation at the injected value:
  //    "Test string {value} test." -> "Test string |{value}| test." ->
  //    ["Test string ", "{value}", " test."]

  const splitter = "|";
  const splitMatch = new RegExp("([{}])", "g");
  const valueMatcher = new RegExp("{([^}]+)}", "g");
  const replacedWords = localizedString
    .replace(splitMatch, (character) => {
      // "}" -> "}|", "{" -> "|{"

      if (character === "{") {
        return `${splitter}${character}`;
      }
      return `${character}${splitter}`;
    })
    // Split at "|"
    .split(splitter)
    // We now have all {values} in their own segments and text in their own segments
    .map((segment, index) => {
      // Replace segment with the value if the segment is of format:
      //    "{valueName}"
      const matchResult = valueMatcher.exec(segment);
      if (matchResult) {
        const value = values[matchResult[1]];
        if (isString(value) || isNumber(value)) {
          return value;
        }
        return <span key={index}>{value}</span>;
      }
      return segment;
    });

  return <>{replacedWords}</>;
}

function translateElementString(
  key: string,
  values?: IKeyValueElementPair,
  languageCode?: string,
  sector?: Sector,
  localTranslations?: ITranslations
) {
  const { currentLanguage, currentSector, defaultLang } = getGlobalLocalizationInfo();
  const language = languageCode || currentLanguage || defaultLang;
  const sectorName = getSectorPostFix(sector || currentSector || Sector.Marine);

  const translatedString = findStringForSector<JSX.Element | string, IKeyValueElementPair>(
    language,
    key,
    sectorName,
    values || {},
    replaceElements,
    localTranslations
  );

  if (!translatedString) {
    throw new Error(`Translation for key ${key} was not found.`);
  }

  return <span>{translatedString}</span>;
}

export class LocalizedStringComponent extends React.PureComponent<ILocalizedStringComponentProps> {
  public render() {
    return translateElementString(this.props.id, this.props.values, undefined, undefined, this.props.translations);
  }
}
