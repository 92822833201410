import { IUserInfo } from "online-services-types";
import { AnyAction, Dispatch } from "redux";
import { setSelectedInstallationId, setSelectedInstallationName } from "src/util/localstorage";

const SET_USER = "SET_USER";
const SET_USER_COLOR_THEME = "SET_USER_COLOR_THEME";
const SET_USER_INSTALLATION = "SET_USER_INSTALLATION_ACTION";

export const setSelectedInstallation = (selectedInstallationId?: string, selectedInstallationName?: string) => {
  return (dispatch: Dispatch) => {
    setSelectedInstallationId(selectedInstallationId);
    setSelectedInstallationName(selectedInstallationName);
    dispatch(setUserInstallationAction(selectedInstallationId, selectedInstallationName));
  };
};

export const setUserInfo = (userInfo: IUserInfo) => {
  return async (dispatch: Dispatch) => {
    dispatch(setUserAction(userInfo));
  };
};

export const setSelectedColorTheme = (theme: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(setUserColorThemeAction(theme));
  };
};

const setUserInstallationAction = (selectedInstallationId?: string, selectedInstallationName?: string) => ({
  payload: {
    selectedInstallationId,
    selectedInstallationName,
  },
  type: SET_USER_INSTALLATION,
});

const setUserAction = (user: IUserInfo) => ({
  type: SET_USER,
  user,
});

export const setUserColorThemeAction = (theme: string) => ({
  payload: theme,
  type: SET_USER_COLOR_THEME,
});

export interface IUserState extends IUserInfo {
  selectedInstallationName?: string;
  userId: string;
  isManager: boolean;
  isDesigner: boolean;
  isShipyard: boolean;
  theme: string;
}

const initialState: IUserState = {
  name: "",
  companyName: "",
  selectedInstallationId: undefined,
  selectedInstallationName: undefined,
  email: "",
  mainSector: "",
  userId: "",
  contactId: "",
  isManager: false,
  isDesigner: false,
  isShipyard: false,
  runningHoursCampaignStartDate: "",
  runningHoursCampaignEndDate: "",
  runningHoursCampaignUrl: "",
  singleVisibleInstallation: null,
  theme: "dark", //initial state should be given, it will be changed according to users preference
  punchOutContact: "",
  visibleInstallations: 0,
  isQPDistributor: false,
  isQPDistributorViewDisabled: false,
  isQPAndLifecycleUser: false,
  daysUntilPasswordExpiry: null,
  role: "",
  seniority: "",
  isDetailsUpdateNeeded: false,
  prefCurrency: "EUR",
  title: "",
};

export const userReducer = (state = initialState, action: AnyAction): IUserState => {
  switch (action.type) {
    case SET_USER_INSTALLATION:
      return {
        ...state,
        selectedInstallationId: action.payload.selectedInstallationId,
        selectedInstallationName: action.payload.selectedInstallationName,
      };

    case SET_USER_COLOR_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };

    case SET_USER:
      return { ...state, ...action.user };

    default:
      return state;
  }
};
