import { ICombinedRequest } from "online-services-types";
import * as React from "react";
import { ICombinedRequestViewModel } from "src/models/warrantyClaim";
import { IReopenFormFields } from "../ReopenDialog/ReopenDialogComponent";
import { ReopenDialog } from "../ReopenDialog";
import { LockIcon, IconSize } from "src/icons";
import { IconButton } from "./IconButton";
import { translateString } from "src/util/localization";
import colors from "src/design-system/Tokens/colors";
import { Button } from "src/design-system/Button";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";

export interface IReopenComponentProps {
  item: ICombinedRequestViewModel;
  standalone: boolean;
  displayReopenButton: boolean;
  reopenRequest(request: ICombinedRequest, data: IReopenFormFields): void;
}

export interface IReopenComponentState {
  isReopenDialogOpen: boolean;
}

export class ReopenComponent extends React.Component<IReopenComponentProps, IReopenComponentState> {
  constructor(props: IReopenComponentProps) {
    super(props);
    this.state = {
      isReopenDialogOpen: false,
    };
  }

  public hideReopenDialog = () => {
    this.setState({ isReopenDialogOpen: false });
  };

  public handleReopen = (data: IReopenFormFields) => {
    this.props.reopenRequest(this.props.item, data);
    this.hideReopenDialog();
  };

  public displayReopenDialog = () => {
    this.setState({ isReopenDialogOpen: true });
  };

  public render() {
    if (!this.props.displayReopenButton) {
      return null;
    }
    return (
      <>
        {this.props.standalone ? (
          <Button onClick={this.displayReopenDialog}>
            <LocalizedStringComponent id="request.rating.reopenButtonText" />
          </Button>
        ) : (
          <IconButton
            label={translateString("request.rating.reopenButtonText")}
            onClick={this.displayReopenDialog}
            icon={<LockIcon color={colors.primary.black} size={IconSize.Large} />}
          />
        )}
        <ReopenDialog
          isOpen={this.state.isReopenDialogOpen}
          onApprove={this.handleReopen}
          onCancel={this.hideReopenDialog}
        />
      </>
    );
  }
}
