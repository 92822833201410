import { INewsItem, IQuotationRequest } from "online-services-types";
import { Dispatch } from "redux";
import { APIFetch } from "src/APIFetch";
import { APIResourceState } from "src/redux/api";
import { getRoutes, redirectBrowser } from "src/routes";
import { dismissMessage, displayError, displayProgress, displaySuccess } from "src/util/error";
import { translateString } from "src/util/localization";
import { getNewsSeenInModal } from "src/util/localstorage";

export function markNewsAsRead(items: INewsItem[]) {
  return async (dispatch: Dispatch) => {
    try {
      const data = items
        .filter((item) => !item.isRead)
        .map((item) => ({ id: item.id, title: item.title, isRead: true }));
      if (data.length > 0) {
        let endpoint, resource;
        endpoint = "service/newsnew";
        resource = APIResourceState.newsnew;
        await new APIFetch<INewsItem>(endpoint).patch(data);
        dispatch(resource.patchAction(data));
      }
    } catch (error) {
      displayError(error);
    }
  };
}

export async function requestQuotation(item: INewsItem, equipmentIds: string[], message: string) {
  try {
    const toastId = displayProgress(translateString("news.sendingQuotationRequest"));
    await new APIFetch<IQuotationRequest>("service/request/new-quotation").post({
      newsItemId: item.id,
      newsTitle: item.title,
      newsDescription: item.fullText,
      equipmentIds,
      message,
      status: "",
      linkHref: item.linkHref,
    });
    dismissMessage(toastId);
    displaySuccess(translateString("news.quotationRequestSent"));
    redirectBrowser(getRoutes().News);
  } catch (error) {
    displayError(error);
  }
}

export function filterUnsplashedNews(newsItems: INewsItem[]) {
  const seenNews = getNewsSeenInModal();

  return newsItems.filter((item) => seenNews.indexOf(item.id) === -1);
}

export function filterModalNewsItems(newsItems: INewsItem[], selectedInstallationId?: string) {
  return filterUnsplashedNews(filterUnreadNews(newsItems, selectedInstallationId));
}

export const filterUnreadNews = (news: INewsItem[], selectedInstallationId?: string) => {
  if (!selectedInstallationId) {
    return news.filter((item) => !item.isRead);
  }

  return news
    .filter((item) => !item.isRead)
    .filter((item) => item.installationIds.indexOf(selectedInstallationId) !== -1);
};
