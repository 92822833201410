import * as React from "react";
import * as ReactDOM from "react-dom";
// Styles
import * as s from "./styles";

import {
  IColumnWidth,
  IColumnsWidth,
  IResponsiveTableColumn,
  IResponsiveTableProps,
  IResponsiveTableState,
  ISort,
  ISortOption,
} from "./interfaces";

// Interfaces
import { IResource } from "online-services-types";
import { ISelectObject } from "src/design-system/Select/Select";
import { LoadingSpinner } from "../LoadingSpinner";
import { Row } from "./Row";
import { Select } from "src/design-system/Select";
import { ThemeProvider } from "styled-components";
import { debounce } from "lodash";
import { spinnerSize } from "../LoadingSpinner/LoadingSpinner";
import { themes } from "src/design-system/Theme/theme";
import { translateString } from "src/util/localization";

export class ResponsiveTable<T extends IResource> extends React.Component<
  IResponsiveTableProps<T>,
  IResponsiveTableState
> {
  public debouncedResize: () => void;

  private readonly ref: React.RefObject<any>;
  private readonly scrollToItemRef: React.RefObject<Row<T>>;
  private hasScrolledToPreselectedItem = false;

  constructor(props: IResponsiveTableProps<T>) {
    super(props);
    this.ref = React.createRef();
    this.scrollToItemRef = React.createRef();
    this.state = {
      offset: 0,
      sortOption: this.sortSubdocumentsOptions[0],
      columnWidths: {
        columnWidthsInOrder: [],
        accumulator: 0,
      },
    };
    this.resize = this.resize.bind(this);
    this.debouncedResize = debounce(this.resize, 300);
  }

  public componentDidMount() {
    const offset = this.getOffset() || 0;
    this.setState(
      {
        offset,
        columnWidths: this.getColumnsWidth(this.props.columns, offset),
      },
      this.resize
    );

    if (this.props.customSublistSorting) {
      this.setState({
        sortOption: this.props.customSublistSorting[0],
      });
    }

    window.addEventListener("resize", this.debouncedResize, false);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedResize, false);
  }

  public scrollToPreselectedItem() {
    // Prevent scrolling on normal prop changes after initial scroll
    if (!this.props.scrollToOpenItem || this.hasScrolledToPreselectedItem) {
      return;
    }
    const currentRef = this.scrollToItemRef.current;
    if (currentRef) {
      const node = ReactDOM.findDOMNode(currentRef);
      if (node instanceof Element) {
        // Scrolling was successfull, stop trying to scroll on update
        this.hasScrolledToPreselectedItem = true;
        node.scrollIntoView();
      }
    }
  }

  public componentDidUpdate(prevProps: IResponsiveTableProps<T>) {
    if (prevProps.displayedItemId !== this.props.displayedItemId) {
      this.hasScrolledToPreselectedItem = false;
    }
    this.scrollToPreselectedItem();
  }

  public getOffset(): number {
    // New offset with inner expand icon
    if (this.ref && this.ref.current) {
      return window.innerWidth - this.ref.current.offsetWidth;
    } else {
      return window.innerWidth;
    }
  }

  public resize() {
    if (this.ref && this.ref.current && this.ref.current.offsetWidth > 0) {
      const newOffset = this.getOffset();

      // If the surrounding space of list changes, we need to calculate again the breakpoints where columns (dis)appear
      if (newOffset !== this.state.offset) {
        this.setState({
          offset: newOffset,
          columnWidths: this.getColumnsWidth(this.props.columns, newOffset),
        });
      }
    }
  }

  private readonly sortSubdocumentsOptions: ISortOption[] = [
    {
      key: "relevancy",
      sortData: {
        key: "relevancy",
        asc: true,
      },
      label: translateString("sorting.relevancy"),
    },
    {
      key: "dateDesc",
      sortData: {
        key: "documentDate",
        asc: false,
      },
      label: translateString("sorting.newestFirst"),
    },
    {
      key: "dateAsc",
      sortData: {
        key: "documentDate",
        asc: true,
      },
      label: translateString("sorting.oldestFirst"),
    },
  ];

  public render() {
    const {
      rows,
      columns,
      sort,
      filter,
      nesting = 0,
      keyExtractor,
      rowExtension,
      isSubList = false,
      getRowDetails,
      fallback,
      onRowExpand,
      isSublistSortingHidden = false,
    } = this.props;

    let visibleRows: T[] = [];

    if (rows && rows.length > 0) {
      if (isSubList) {
        visibleRows = this.handleEnsureVisible(this.sortAndFilter(rows, this.state.sortOption.sortData));
      } else {
        visibleRows = this.handleEnsureVisible(this.sortAndFilter(rows, sort, filter));
      }
    }

    return (
      <s.TableBlock ref={this.ref}>
        {(() => {
          if (!isSubList) return;
          if (!rows?.length) return;
          if (isSublistSortingHidden) return;

          return (
            <s.SortingContainer $padding={[2, 0, 1, 1]}>
              <Select<ISortOption>
                name="sortOption"
                onChange={(sortOption: ISortOption) => {
                  this.setState({
                    sortOption,
                  });
                }}
                value={this.state.sortOption}
                placeholder={translateString("select.sortPlaceholder")}
                getOptionValue={(option: ISelectObject<ISortOption>) => option.item.key}
                options={this.props.customSublistSorting || this.sortSubdocumentsOptions}
                getOptionLabel={(option: ISelectObject<ISortOption>) => option.item.label}
              />
            </s.SortingContainer>
          );
        })()}

        {rows &&
          rows.length > 0 &&
          visibleRows.map((row, idx) => {
            let key = keyExtractor ? keyExtractor(row, idx) : row.id;
            // Fallback. In some cases row.id is missing.
            if (!key) key = String(idx);

            return (
              <Row
                idx={idx}
                key={key}
                row={row}
                rows={rows}
                nesting={nesting}
                columns={columns}
                columnWidths={this.state.columnWidths}
                isSubList={isSubList}
                getRowDetails={getRowDetails}
                initialOpenState={this.props.isItemOpen && this.props.isItemOpen(row)}
                ref={this.props.isItemOpen && this.props.isItemOpen(row) ? this.scrollToItemRef : undefined}
                sublistRenderer={this.props.sublistRenderer}
                selectedId={this.props.selectedId}
                onSelect={this.props.onSelect}
                ensureVisibleId={this.props.ensureVisibleId}
                getHasAlert={this.props.getHasAlert}
                getAlertColor={this.props.getAlertColor}
                className={this.props.className}
                firstColumnBackground={this.state.firstColumnBackground}
                onExpand={() => onRowExpand?.(row)}
                controlledOpenState={this.props.controlledOpenState}
                hideFetchSpinner={this.props.hideFetchSpinner}
              >
                {rowExtension && (
                  <ThemeProvider theme={themes.light}>
                    <div>{rowExtension(row)}</div>
                  </ThemeProvider>
                )}
              </Row>
            );
          })}
        {rows && isSubList && <s.VerticalLine />}
        {!rows && <LoadingSpinner disableText={true} size={spinnerSize.md} />}
        {rows && visibleRows.length === 0 && fallback && <s.FallbackBox>{fallback}</s.FallbackBox>}
      </s.TableBlock>
    );
  }

  public getVisibleColumns(columns: IResponsiveTableColumn[]) {
    return columns
      .filter((column) => !column.alwaysHidden)
      .filter((column) => column.isEnabled === undefined || column.isEnabled);
  }

  public getColumnsWidth(columns: IResponsiveTableColumn[], offset: number): IColumnsWidth {
    const columnsToShow = this.getVisibleColumns(columns);
    const sortedColumns = columnsToShow.sort((a: IResponsiveTableColumn, b: IResponsiveTableColumn) => {
      return b.priority - a.priority;
    });

    const columnWidths: IColumnWidth[] = [];
    let currentWidth = offset;
    let accumulator = offset;

    sortedColumns.forEach((column, index) => {
      const { key, alwaysShown, plannedWidth } = column;

      // If first column is colored, we need to also color the toggle plus/minus
      if (index === 0 && column.backgroundColor) {
        this.setState({ firstColumnBackground: column.backgroundColor });
      }

      if (alwaysShown) {
        accumulator += plannedWidth;
      }

      currentWidth += plannedWidth;
      columnWidths.push({ key, width: currentWidth });
    });

    const columnWidthsInOrder: IColumnWidth[] = [];
    this.getVisibleColumns(columns).forEach((column) => {
      const cW = columnWidths.find((sc) => sc.key === column.key);
      if (cW) {
        columnWidthsInOrder.push({ key: column.key, width: cW.width });
      }
    });

    const columnWidth: IColumnsWidth = { columnWidthsInOrder, accumulator };
    // Calculate width when and if we should hide expand toggle,
    // i.e. when all the columns are there anre there is nothing to expand
    const allColumnsShowWidth = currentWidth;
    const hasHiddenCols = columns.length !== columnsToShow.length;

    if (!hasHiddenCols) {
      columnWidth.maxWidthToShowToogle = allColumnsShowWidth;
    }

    return columnWidth;
  }

  public sortAndFilter(rows: T[] | null, sort?: ISort, filter?: (row: T) => boolean): T[] {
    let returnArray = rows?.concat(); // create a new array object to avoid mutation of the original
    if (returnArray) {
      if (filter) {
        returnArray = returnArray.filter(filter);
      }

      if (sort) {
        if (sort.fallbackToDefault) return returnArray;
        returnArray.sort(this.createSortPredicate(sort));
      }

      if (this.props.limit !== undefined) {
        if (rows && rows.length > 0 && this.props.ensureVisibleId) {
          // when preselected id of row is included, then overwrite the index to always be first
          const foundItemIndex = rows.findIndex((row) => row.id === this.props.ensureVisibleId);
          const foundItem = rows.find((row) => row.id === this.props.ensureVisibleId);
          if (foundItem && foundItemIndex !== -1) {
            returnArray.splice(foundItemIndex, 1);
            returnArray.splice(0, 0, foundItem);
          }
        }
        returnArray = returnArray.slice(0, this.props.limit);
      }
    }

    return returnArray || [];
  }

  private createSortPredicate(sort: ISort) {
    const sortColumn = (aValue: string | Date | number, bValue: string | Date | number): number => {
      let order = 0;

      if (aValue < bValue) {
        order = -1;
      }

      if (aValue > bValue) {
        order = 1;
      }

      if (!sort.asc) {
        order = -order;
      }

      return order;
    };

    if (sort.sortCustom) {
      return sort.sortCustom;
    }

    return (row1: T, row2: T): number => {
      const aContent = row1[sort.key];
      const bContent = row2[sort.key];
      const aValue = aContent || "";
      const bValue = bContent || "";

      return sortColumn(aValue, bValue);
    };
  }

  private readonly handleEnsureVisible = (rows: T[]) => {
    if (this.props.ensureVisibleId) {
      const foundItem = rows.find((row) => row.id === this.props.ensureVisibleId);
      if (foundItem) {
        return [foundItem].concat(rows.filter((row) => row.id !== this.props.ensureVisibleId));
      }
    }

    return rows;
  };
}
