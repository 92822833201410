import { get } from "lodash";
import { ILineItem, IPartModel, IResource } from "online-services-types";
import { IBasketState } from "src/redux/basket";

/*
  Gets object property by string. Is able to get nested properties.
  Examples:
    const obj = {
      name: 'Peter',
      address: {
        city: 'New-York',
        country: 'USA'
      }
    };

    getObjectProperty(obj, 'name') === 'Peter'
    getObjectProperty(obj, 'address.city') === 'New-York'
    getObjectProperty(obj, 'address.country') === 'USA'
*/
export const getObjectProperty = (obj: any, path: string, defaultValue?: string | string[]) => {
  return get(obj, path, defaultValue);
};

export function removeNullsAndUndefined<T>(array: Array<T | null>) {
  return array.filter((arrayItem) => arrayItem !== null && arrayItem !== undefined) as T[];
}

export const noValidators = () => ({});

const getFindBasketItemPredicate = (item: ILineItem | IPartModel) => (basketItem: ILineItem | IPartModel) => {
  if (basketItem.sfId && item.sfId) {
    return basketItem.sfId === item.sfId && basketItem.id === item.id;
  }
  if (basketItem.item_number && item.item_number) {
    return basketItem.item_number === item.item_number;
  }
  if (basketItem.frontendKey && item.frontendKey) {
    return basketItem.frontendKey === item.frontendKey;
  }
  return false;
};

// if we are working on draft, use SF id, otherwise SAP line number/frontendkey
export const findIndexOfItemInBasket = (item: ILineItem | IPartModel, basket: IBasketState) => {
  return basket.items.findIndex(getFindBasketItemPredicate(item));
};

export const findItemInBasket = (item: ILineItem | IPartModel, basket: IBasketState) => {
  return basket?.items?.find(getFindBasketItemPredicate(item));
};

// Split string to searchable search terms by splitting spaces. Preserves spaces for strings inside quotation marks
export const getSearchTermArrayFromString = (inputText: string): string[] => {
  if (!inputText) {
    return [];
  }
  const searchWords: string[] = [];

  const searchSplitByQuotes = inputText.toLowerCase().split('"');

  for (let i = 0; i < searchSplitByQuotes.length; i++) {
    // We can assume that every even-numbered element in the above array is supposed to be split by spaces
    if (i % 2 === 0) {
      searchWords.push(...searchSplitByQuotes[i].split(" "));
    }
    // Likewise, we can assume that every odd-numbered element is inside quotation marks and is supposed to be searched as-is
    else {
      searchWords.push(searchSplitByQuotes[i]);
    }
  }

  return searchWords;
};

// Returns true if each search term in the input array matches at least one given field in the given resource
export const doSearchTermsMatchResourceFields = <T extends IResource>(
  searchTermArray: string[],
  resource: T,
  matchFields: Array<keyof T>
): boolean => {
  return searchTermArray.every((term) => {
    return matchFields.some((field) => {
      return !!resource[field] && (resource[field] as {} as string).toLowerCase().indexOf(term) > -1;
    });
  });
};

export const uniqueArray = (array: string[]) => {
  return array.filter((v, i, a) => a.indexOf(v) === i);
};

export const toggleArrayValue = (array: string[], value: string) => {
  if (array.indexOf(value) !== -1) {
    return array.filter((arrayValue) => arrayValue !== value);
  }

  return array.concat(value);
};

export const getFileExtension = (filenameWithExtention: string) => {
  const extensions = filenameWithExtention.split(".");
  return extensions[extensions.length - 1];
};

export const removeFileExtension = (filenameWithExtention: string) => {
  return filenameWithExtention.replace(/\.[^/.]+$/, "");
};
