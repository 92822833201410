import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import {
  IEquipment,
  ILineItem,
  IPart,
  IPartModel,
  IPartsCatalogSection,
  ISectionBase64Image,
} from "online-services-types";
import { IExtendedPartAvailability, IPartAvailabilityState } from "src/redux/partsAvailability";

import { IBasketState } from "src/redux/basket";

export interface ISavedSuggestedPart {
  productId: string;
  quantity: number;
  description?: string;
}

export interface ISuggestedPart extends ISavedSuggestedPart, IPart, ILineItem {}

interface ISectionBase64URLImage extends ISectionBase64Image {
  url?: string;
}

export interface IPartsCatalogSectionViewModel extends IPartsCatalogSection {
  parts: IPartModel[] | null;
  images: ISectionBase64URLImage[];
}

export function getPartsCatalogSectionViewModel(
  catalogueSections: IAPIResource<IPartsCatalogSection>,
  catalogueParts: IAPIResource<IPart>,
  sectionImages: IAPIResource<ISectionBase64Image>,
  equipments: IAPIResource<IEquipment>,
  isFreetextFiltering: boolean,
  freeText: string | null,
  partsAvailability: IPartAvailabilityState,
  basket: IBasketState,
  selectedEquipment: IEquipment | null
): IAPIResource<IPartsCatalogSectionViewModel> {
  if (catalogueSections.status === APIFetchStatus.Failed) {
    return {
      data: [],
      errorMessage: catalogueSections.errorMessage,
      status: APIFetchStatus.Failed,
    };
  }

  if (catalogueSections.status !== APIFetchStatus.Success) {
    return {
      data: [],
      status: catalogueSections.status,
    };
  }

  // when no parts have been fetched yet i.e. redux is empty
  if (!catalogueParts.data || (catalogueParts.data.length === 0 && !isFreetextFiltering)) {
    return {
      data: catalogueSections.data.map((section) => ({ ...section, parts: null, images: [] })),
      status: catalogueSections.status,
    };
  }

  const filteredSections = isFreetextFiltering
    ? filterSectionsByParts(catalogueSections.data, catalogueParts.data, freeText)
    : catalogueSections.data;

  return {
    data: filteredSections.map((section) => ({
      ...section,
      parts: getSectionParts(
        catalogueParts.data,
        section.id,
        partsAvailability.availability,
        equipments.data.find((e) => e.id === section.equipmentId),
        freeText,
        basket,
        selectedEquipment
      ),
      images: getSectionImages(sectionImages.data, equipments.data, section),
    })),
    status: APIFetchStatus.Success,
  };
}

/**
 * Sections cannot be filtered by freetext search, but parts can. So we fetch the parts and filter sections based on them.
 */
const filterSectionsByParts = (
  catalogueSections: IPartsCatalogSection[],
  catalogueParts: IPart[],
  freeText: string | null
) => {
  let filteredParts;
  if (freeText) {
    filteredParts = catalogueParts.filter((part) => {
      return part.id.toLowerCase().includes(freeText)
        || (part.spnDescription ? part.spnDescription.toLowerCase().includes(freeText) : part.materialText.toLowerCase().includes(freeText));
    });
  } else {
    filteredParts = catalogueParts;
  }
  const sectionIds = filteredParts.map((part) => part.sectionId);
  const parentIds = sectionIds.reduce((parentIdSet, sectionId) => {
    const section = catalogueSections.find((sec) => sec.id === sectionId);
    if (section && section.upperLevelSectionId) {
      parentIdSet.add(section.upperLevelSectionId);
    }
    return parentIdSet;
  }, new Set<string>());

  const filteredIds = sectionIds.concat(Array.from(parentIds));
  return catalogueSections.filter((section) => filteredIds.indexOf(section.id) >= 0);
};

const getSectionParts = (
  partsCatalogue: IPart[],
  sectionId: string,
  availability: IExtendedPartAvailability[],
  equipment?: IEquipment,
  freeText?: string | null,
  basket?: IBasketState,
  selectedEquipment?: IEquipment | null
) => {
  if (!equipment) {
    throw new Error("No equipment found for a part.");
  }
  let filteredParts;
  if (freeText) {
    filteredParts = partsCatalogue.filter((part) => {
      return part.id.toLowerCase().includes(freeText)
        || (part.spnDescription ? part.spnDescription.toLowerCase().includes(freeText) : part.materialText.toLowerCase().includes(freeText));
    });
  } else {
    filteredParts = partsCatalogue;
  }

  const parts = filteredParts
    .filter((part) => part.equipmentId === equipment.id && part.sectionId === sectionId)
    .map((part) => {
      let avblData = availability.find(
        (el) => el.materialId === part.materialId && el.equipmentId === part.equipmentId
      );

      // It is possible that SAP returns a different material number other than we received in part info
      // for outdated material numbers. So for these particular cases we need to use part.
      // This workaround can bring additional issues because we are using data from 2 different materials.
      if (!avblData) {
        avblData = availability.find((el) => el.id === part.id && el.equipmentId === part.equipmentId);
      }

      const partWithProductId = { ...part, productId: equipment.serialNumber };
      const basketItem = basket ? basket.items.find((bi) => part.materialId === bi.materialId) : null;
      const quantity = basketItem ? basketItem.quantity : 0;
      if (avblData) {
        return {
          ...partWithProductId,
          quantity,
          leadText: avblData.leadText,
          netPrice: avblData.netPrice,
          netWeight: avblData.netWeight,
          available: avblData.available,
          totNetPrice: avblData.totNetPrice,
          currency: avblData.currency,
          quantityInStock: avblData.quantityInStock,
        };
      }
      return { ...partWithProductId, quantity };
    });

  return parts.length !== 0 ? parts : null;
};

const getSectionImages = (
  sectionImages: ISectionBase64Image[],
  equipments: IEquipment[],
  section: IPartsCatalogSection
) => {
  const relatedEquipment = equipments.find((equipment) => equipment.id === section.equipmentId);
  if (relatedEquipment && sectionImages) {
    return sectionImages.filter(
      (image) => image.productReferenceType === relatedEquipment.productReferenceType && image.sectionId === section.id
    );
  }
  return [];
};
