import * as React from "react";

import { IAttachment, IEquipment, IJWTUserInfo } from "online-services-types";
import { IRouteParams, getPathWithParams, getRoutes, redirectBrowserToURL } from "src/routes";
import { IconSize, TrashIcon } from "src/icons";
import styled, { ThemeProvider } from "styled-components";

import { APIFetch } from "src/APIFetch";
import { Details } from "src/design-system/Tokens/typography";
import { Dialog } from "src/components/Dialog";
import { LocalizedString } from "src/components/Localization";
import { RequestType } from "src/models/warrantyClaim";
import { RouteType } from "src/routeType";
import colors from "src/design-system/Tokens/colors";
import { themes } from "src/design-system/Theme/theme";
import { translateString } from "src/util/localization";

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20em;
  margin: 5px 0;
`;

export const DownloadComponentWrapper = styled.span`
  cursor: pointer;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const Title = styled(Details)`
  color: ${colors.primary.black};
  margin-top: 2px;
  margin-bottom: 8px;
`;

const TitleRow = (props: { hideTitle: boolean; isInternal: boolean; title: string }) =>
  !props.hideTitle ? <Title>{props.isInternal ? `Wärtsilä / ${props.title}` : props.title}</Title> : null;

interface IAttahcmentListRowComponentProps {
  fileId: string;
  fileName: string;
  articleId?: string;
  requestType?: RequestType;
  requestId?: string;
  cmReportId?: string;
  createdBy: string;
  appliesToEquipments?: IEquipment[];
  user?: IJWTUserInfo;
  iconColor?: string;
  isAttachmentUploadDisabled?: boolean;
  hideTitle: boolean;
  isInternal: boolean;
  title: string;
  handleDelete?: () => void;
  loadAttachments(): void;
  children?: React.ReactNode;
}

interface IAttahcmentListRowComponentState {
  equipmentId?: string;
  isEquipmentDialogOpen: boolean;
  isConfirmDialogOpen: boolean;
}

export class AttahcmentListRowComponent extends React.Component<
  IAttahcmentListRowComponentProps,
  IAttahcmentListRowComponentState
> {
  constructor(props: IAttahcmentListRowComponentProps) {
    super(props);

    // Consider a lone equipment as the only option and preselect it

    this.state = {
      equipmentId:
        props.appliesToEquipments && props.appliesToEquipments.length === 1
          ? props.appliesToEquipments[0].id
          : undefined,
      isEquipmentDialogOpen: false,
      isConfirmDialogOpen: false,
    };

    this.deleteFile = this.deleteFile.bind(this);
  }

  public async deleteFile(fileId: string) {
    this.closeConfirmationDialog();
    await new APIFetch<IAttachment>("service/attachments").delete(fileId);
    this.props.loadAttachments();
    if (this.props.handleDelete !== undefined) {
      this.props.handleDelete();
    }
  }

  public render() {
    return (
      <div>
        {this.renderConfirmDialog()}
        {this.renderAttachments()}
      </div>
    );
  }

  private readonly renderAttachments = () => {
    return (
      <span>
        <FileRow>
          <DownloadComponentWrapper onClick={this.openFile}>{this.props.children}</DownloadComponentWrapper>
          {this.props.user &&
            this.props.user.userId === this.props.createdBy &&
            !this.props.isAttachmentUploadDisabled && (
              <IconWrapper onClick={this.openConfirmationDialog}>
                <TrashIcon color={this.props.iconColor || colors.primary.black} size={IconSize.Small} />
              </IconWrapper>
            )}
        </FileRow>
        <TitleRow hideTitle={this.props.hideTitle} isInternal={this.props.isInternal} title={this.props.title} />
      </span>
    );
  };

  private readonly renderConfirmDialog = () => {
    return (
      <Dialog
        title={translateString("request.attachmentDialog.Title")}
        isDialogOpen={this.state.isConfirmDialogOpen}
        onCancel={this.closeConfirmationDialog}
        submit={{
          buttonTitle: translateString("request.attachmentDialog.Confirm"),
          callback: () => this.deleteFile(this.props.fileId),
        }}
      >
        <>
          <ThemeProvider theme={themes.light}>
            <LocalizedString id="request.attachmentDialog.Text" />
          </ThemeProvider>
        </>
      </Dialog>
    );
  };

  private readonly openConfirmationDialog = () => {
    this.setState({ isConfirmDialogOpen: true });
  };

  private readonly closeConfirmationDialog = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  private readonly displayEquipmentDialog = () => {
    this.setState({ isEquipmentDialogOpen: true });
  };

  private readonly hideEquipmentDialog = () => {
    this.setState({ isEquipmentDialogOpen: false });
  };

  private readonly openFile = () => {
    // Skip equipment dialog in case an equipment is selected or there are no related equipments

    const noRelatedEquipment = !this.props.appliesToEquipments || this.props.appliesToEquipments.length === 0;

    if (this.state.equipmentId || noRelatedEquipment) {
      this.redirect();
      this.resetEquipmentSelection();
      this.hideEquipmentDialog();
    } else {
      this.displayEquipmentDialog();
    }
  };

  private readonly resetEquipmentSelection = () => {
    this.setState({ equipmentId: undefined });
  };

  private readonly redirect = () => {
    const params: IRouteParams = { fileId: this.props.fileId };
    let path = getPathWithParams(getRoutes(RouteType.Standalone).Download, params);

    if (this.props.articleId || this.props.requestId || this.props.cmReportId) {
      const queryParams: { [param: string]: string } = {};
      if (this.props.articleId) {
        queryParams.articleId = this.props.articleId;
      }
      if (this.props.requestId) {
        queryParams.requestId = this.props.requestId;
      }
      if (this.props.requestType) {
        queryParams.requestType = this.props.requestType;
      }
      if (this.props.cmReportId) {
        queryParams.cmReportId = this.props.cmReportId;
      }
      if (this.state.equipmentId) {
        queryParams.equipmentId = this.state.equipmentId;
      }
      path += `?${Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&")}`;
    }
    redirectBrowserToURL(path, true);
  };
}
