import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const RunningHoursIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Running hours</title>
    <path d="M40,24a16,16,0,1,1-5.29-11.89L23.93,22.83l-2.78-2.78-2.83,2.83,5.6,5.6L40.27,12.22,39,10.81A20,20,0,1,0,44,24H40Z" />
    <rect x="22.98" y="10.06" width="2" height="4" />
    <rect x="22.98" y="33.94" width="2" height="4" />
    <rect x="10.03" y="23" width="4" height="2" />
    <rect x="33.92" y="23" width="4" height="2" />
    <rect x="14.53" y="13.55" width="2" height="4" transform="translate(-6.45 15.54) rotate(-45)" />
    <rect x="31.42" y="30.45" width="2" height="4" transform="translate(-13.45 32.43) rotate(-45)" />
    <rect x="13.53" y="31.45" width="4" height="2" transform="translate(-18.39 20.48) rotate(-45)" />
  </IconBase>
);
