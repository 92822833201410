import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { IInstallation, ISFOCHistoryResponse } from "online-services-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { APIFetch } from "src/APIFetch";
import { IAppState } from "src/redux";
import { ISFOCHistoryViewStateProps, SFOCHistoryFilters } from "./sfoc.types";

export const useSFOCHistoryViewStateProps = () => useSelector<IAppState, ISFOCHistoryViewStateProps>((state) => ({
  userInfo: state.userInfo,
}));

const getSFOCHistory = async (params: SFOCHistoryFilters = {}) => {
  const [response] = await new APIFetch<ISFOCHistoryResponse[]>("service/sfoc-history").get(undefined, params);
  return response.sfocHistoryRows;
};

const getInstallations = () => {
  return new APIFetch<IInstallation[]>("service/installations").get();
};

export const useInstallations = () => useQuery(["installations"], getInstallations);

export const useSFOCHistory = (params: SFOCHistoryFilters = {}) => {
  const defaultLimit = params.limit || 10;
  return useInfiniteQuery(
    ["sfocHistory", params],
    ({ pageParam = 1 }) => {
      const prevPage = pageParam - 1;
      const firstPage = pageParam === 1;
      const offset = firstPage ? 0 : prevPage * defaultLimit;
      return getSFOCHistory({ ...params, offset });
    },
    {
      getNextPageParam: (_, allPages) => {
        const lastPage = allPages[allPages.length - 1];
        const isLastPage = lastPage.length < defaultLimit;

        return isLastPage ? null : allPages.length + 1;
      },
    }
  );
};

export const useAllowExportAll = () => {
  const [allowed, setAllowed] = useState<boolean>(false);
  useEffect(() => {
    new APIFetch<ISFOCHistoryResponse[]>("service/sfoc-history")
      .get(undefined, { limit: 0 })
      .then(([response]) => {
        setAllowed(response.exportAll || false);
      });
  }, []);
  return allowed;
};
