// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const error_ru: ITranslations = {
  ru: {
    "error.contactLinkText": "связь с Wärtsilä",
    "error.defaultTitle": "Что-то пошло не так",
    "error.pleaseContact": "Если проблема не устраняется, {contactLink}.",
    "success.defaultMessage": "Операция завершена успешно.",
    "success.defaultTitle": "Успех!",
  },
};
