// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const onboarding_ru: ITranslations = {
  ru: {
    "documentsOnboarding.stepFive.content": "Просмотрите информацию о документе, чтобы подробнее узнать, к какому оборудованию относится документ.",
    "documentsOnboarding.stepFive.title": "Информация о документе",
    "documentsOnboarding.stepFour.content": "Простой доступ к предварительному просмотру щелчком по страницам документов.",
    "documentsOnboarding.stepFour.content2": "Используемые для поиска слова будут выделены подсветкой в предварительном просмотре.",
    "documentsOnboarding.stepFour.title": "Предварительный просмотр",
    "documentsOnboarding.stepOne.content": "Все ваши документы можно легко просматривать, искать, фильтровать и сортировать на одной странице.",
    "documentsOnboarding.stepOne.title": "Ваши документы",
    "documentsOnboarding.stepThree.content": "Сортируйте свое экранное изображение так, чтобы наиболее актуальное документы оказались вверху.",
    "documentsOnboarding.stepThree.title": "Сортировка",
    "documentsOnboarding.stepTwo.content": "Используйте различные значения поиска, чтобы найти то, что вам надо.",
    "documentsOnboarding.stepTwo.content2": "При поиске вы также можете использовать фильтр по типам документов.",
    "documentsOnboarding.stepTwo.title": "Поиск и фильтрация",
    "mainOnboarding.stepFive.content": "Благодарим вас за участие в обзоре. Если у вас есть какие-либо вопросы или вам требует справка, наша служба поддержки заказчика всегда готова помочь вам. Мы надеемся, что новая среда вам понравится.",
    "mainOnboarding.stepFive.title": "Вы уже на старте!",
    "mainOnboarding.stepFour.content": "Все доступные виды сервисного обслуживания перечислены ниже",
    "mainOnboarding.stepFour.title": "Ваше сервисное обслуживание",
    "mainOnboarding.stepOne.content": "Мы собираемся пройтись с вами по основным возможностям в ходе краткого обзора. Это займет меньше минуты.",
    "mainOnboarding.stepOne.title": "Получите новый опыт с помощью Wärtsilä Online!",
    "mainOnboarding.stepThree.content": "Все установки, которые вы выбрали в своем профиле для отображения, легко доступны на вашей титульной странице. Щелкнув по определенной установке, можно найти изделия, относящиеся к ней.",
    "mainOnboarding.stepThree.content:energy": "Все электростанции, которые вы выбрали в своем профиле для отображения, легко доступны на вашей титульной странице. Щелкнув по определенной электростанции, можно найти изделия, относящиеся к ней.",
    "mainOnboarding.stepThree.content:marine": "Все суда, которые вы выбрали в своем профиле для отображения, легко доступны на вашей титульной странице. Щелкнув по определенному судну, можно найти изделия, относящиеся к нему.",
    "mainOnboarding.stepThree.title": "Ваши установки",
    "mainOnboarding.stepThree.title:energy": "Ваши электростанции",
    "mainOnboarding.stepThree.title:marine": "Ваши суда",
    "mainOnboarding.stepTwo.content": "В профиле вы можете выбрать, какую из установок отображать на титульной странице, обновить свою базовую информацию и настройки краткого изложения электронной почты.",
    "mainOnboarding.stepTwo.content2": "Вы всегда можете повторить этот краткий обзор, перейдя в свой профиль.",
    "mainOnboarding.stepTwo.content:energy": "В профиле вы можете выбрать, какую из электростанций отображать на титульной странице, обновить свою базовую информацию и настройки краткого изложения электронной почты.",
    "mainOnboarding.stepTwo.content:marine": "В профиле вы можете выбрать, какое из судов отображать на титульной странице, обновить свою базовую информацию и настройки краткого изложения электронной почты.",
    "mainOnboarding.stepTwo.title": "Ваш профиль",
    "sparePartsOnboarding.stepAddPartsToCart.content": "Нажимая кнопки + и –, или вводя количество, можно добавлять в корзину или удалять товары и видеть промежуточную сумму.",
    "sparePartsOnboarding.stepAddPartsToCart.title": "Добавление деталей в корзину для покупок",
    "sparePartsOnboarding.stepBrowseParts.content": "Выбор изделия и просмотр запасных частей.",
    "sparePartsOnboarding.stepBrowseParts.content2": "Вы можете фильтровать запасные части по наименованию детали или номеру.",
    "sparePartsOnboarding.stepBrowseParts.content3": "Вы также можете добавлять запасные части вручную, вводя сразу несколько товарных позиций.",
    "sparePartsOnboarding.stepBrowseParts.title": "Просмотреть запасные части",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content": "Разместите свой заказ или создайте котировку. Выберите установку и начните просматривать свои запасные части.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:energy": "Разместите свой заказ или создайте котировку. Выберите электростанцию и начните просматривать свои запасные части.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.content:marine": "Разместите свой заказ или создайте котировку. Выберите судно и начните просматривать свои запасные части.",
    "sparePartsOnboarding.stepNewOrdersAndQuotations.title": "Новый заказ или котировка",
    "sparePartsOnboarding.stepOrdersAndQuotations.content": "Просматривайте состояния и подробные данные по своим заказам и котировкам.",
    "sparePartsOnboarding.stepOrdersAndQuotations.content2": "На этом экранном изображении можно легко разместить заказ с учетом котировки.",
    "sparePartsOnboarding.stepOrdersAndQuotations.title": "Заказы и котировки",
    "sparePartsOnboarding.stepPartDetails.content": "Расширьте экранное изображение, чтобы видеть подробные данные по запасным частям.",
    "sparePartsOnboarding.stepPartDetails.content2": "На фотографии можно видеть расширенный чертеж запасной части, отображающий больше подробностей.",
    "sparePartsOnboarding.stepPartDetails.content3": "Вы также найдете цену, массу и срок поставки детали.",
    "sparePartsOnboarding.stepPartDetails.title": "Подробные данные по запасным частям",
    "sparePartsOnboarding.stepShoppingCart.content": "Вы можете видеть выбранные запасные части и итоговую цену в своей корзине для покупок.",
    "sparePartsOnboarding.stepShoppingCart.title": "Корзина для покупок",
    "sparePartsOnboarding.stepYourSpareParts.content": "Обзор всех ваших заказов и котировок доступен на одной странице. Вы можете фильтровать свое экранное изображение, а также видеть полную предысторию.",
    "sparePartsOnboarding.stepYourSpareParts.content2": "Просматривайте запасные части, создавайте новые заказы или котировки или создавайте рекламацию на запасную часть.",
    "sparePartsOnboarding.stepYourSpareParts.title": "Страница \"Коммерческая деятельность\"",
    "supportOnboarding.stepFive.content": "Любой из вашей организации может добавлять примечания, относящиеся к запросу, и обращаться в компанию Wärtsilä.",
    "supportOnboarding.stepFive.title": "Примечания",
    "supportOnboarding.stepFour.content": "Приложения можно добавлять все то время, пока запрос находится в процессе обработки.",
    "supportOnboarding.stepFour.title": "Приложения",
    "supportOnboarding.stepOne.content": "Обзор всех ваших запросов доступен на одной странице. Вы можете фильтровать или сортировать свое экранное изображение, а также просматривать состояния запросов и полную предысторию.",
    "supportOnboarding.stepOne.title": "Ваша страница поддержки",
    "supportOnboarding.stepSeven.content": "Чтобы получить все ваши запросы в одном документе, их можно легко экспортировать.",
    "supportOnboarding.stepSeven.title": "Экспорт запросов",
    "supportOnboarding.stepSix.content": "После того как запрос завершен, вы можете оценить это и предоставить отзыв или возобновить запрос.",
    "supportOnboarding.stepSix.title": "Оценка обслуживания",
    "supportOnboarding.stepThree.content": "Расширьте, чтобы увидеть подробные данные по запросу и предоставить дополнительную информацию",
    "supportOnboarding.stepThree.title": "Ваши запросы",
    "supportOnboarding.stepTwo.content": "Создавайте все свои запросы в одном месте:",
    "supportOnboarding.stepTwo.title": "Создать новый запрос",
  },
};
