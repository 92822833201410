import * as React from "react";

import { getPathWithParams, getRoutes } from "src/routes";

import { Link } from "react-router-dom";
import logo from "./w-logo.png";
import styled from "styled-components";

const Img = styled.img`
  width: 130px;
  margin-left: 10px;
`;

export const WartsilaLogo = ({ isLinkDisabled }: { isLinkDisabled: boolean }) =>
  isLinkDisabled ? (
    <Img src={logo} alt="Wärtsilä Logo" />
  ) : (
    <Link to={getPathWithParams(getRoutes().Index)}>
      <Img src={logo} alt="Wärtsilä Logo" />
    </Link>
  );
