import { P, fixedFontSizes } from "src/design-system/Tokens/typography";
import styled from "styled-components";
import { spacer, spacers } from "src/design-system/Tokens/tokens";

export const SummaryItemWrapper = styled.div`
  margin: 0 0 ${spacer(4)};
`;

export const SummaryLabel = styled.p`
  font-size: ${fixedFontSizes.baseFontSize}px;
  font-weight: 700;
  margin: ${spacers([0.5, 0])};
`;

export const SummaryText = styled.div`
  white-space: pre-line;
`;

export const SummaryDate = styled.p`
  margin: 0;
`;

export const SummaryNumber = styled.p`
  margin: 0;
`;

export const SummaryAddress = styled.div``;

export const SummaryAddressRow = styled.div``;

export const WrappedP = styled(P)`
  max-width: 600px;
`;

export const SummaryAttachmentWrapper = styled.div`
  margin: ${spacers([2, 0])};
`;
