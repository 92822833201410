import lodash from "lodash";
import React, { useEffect, useState } from "react";
import { LocalizedString } from "src/components/Localization";
import { LocalizedStringComponent } from "src/components/Localization/LocalizedStringComponent";
import { FlexContainer } from "src/design-system/Container";
import { translateString } from "src/util/localization";
import {
  DataCell,
  ISFOCFleetData,
  Legend,
  NameDataCell,
  PartOfABarWithTooltip,
  ScaleNumber,
  Table,
} from "src/util/sfoc/sfocCharts";
import { DashboardItem } from "src/views/ManagerDashboardView/ManagerDashboardViewComponent";

interface IBrandStatusesDeviation {
  brandId: string;
  brandName: string;
  received: number;
  suspended: number;
  notReceived: number;
}

export const TestFollowingUp = (props: { chartData: ISFOCFleetData[]; quarter: string }) => {
  const [brandDeviations, setBrandDeviations] = useState<IBrandStatusesDeviation[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<string>();
  const [visibleTooltipStatus, setVisibleTooltipStatus] = useState<string>();

  useEffect(() => {
    const fleetDataForQuarter = props.chartData.filter((dataSet) => dataSet.quarter === "Q" + props.quarter);
    const brandDeviationsForCurrentQuarter: IBrandStatusesDeviation[] = [];

    fleetDataForQuarter.forEach((dataSet) => {
      const existingBrand = brandDeviationsForCurrentQuarter.find((brand) => brand.brandName === dataSet.accountName);

      let received: number = 0;
      let suspended: number = 0;
      let notReceived: number = 0;

      if (!dataSet.testValidation && dataSet.qBKwh && dataSet.qBPercent) {
        // Count as received even if it hasn't been handled, but values have been filled
        received += 1;
      } else {
        switch (dataSet.testValidation) {
          case "Approved":
            received += 1;
            break;
          case "Rejected":
            received += 1;
            break;
          case "Validation pending":
            received += 1;
            break;
          case "Suspended":
            suspended += 1;
            break;
          case "Not received":
            notReceived += 1;
            break;
        }
      }
      // if it is a new brand we add
      if (existingBrand === undefined) {
        brandDeviationsForCurrentQuarter.push({
          brandId: dataSet.accountId,
          brandName: dataSet.accountName,
          received: received,
          suspended: suspended,
          notReceived: notReceived,
        });
        // if it is a not a new brand we update
      } else {
        existingBrand.received = existingBrand.received + received;
        existingBrand.suspended = existingBrand.suspended + suspended;
        existingBrand.notReceived = existingBrand.notReceived + notReceived;
      }
    });

    setBrandDeviations(brandDeviationsForCurrentQuarter);
  }, [props.quarter, props.chartData]);

  const scale: React.ReactNode[] = [];
  for (let i = 0; i <= 100; i += 10) {
    scale.push(
      i === 0 ? (
        <ScaleNumber key={i}>
          <div>{i}</div>
        </ScaleNumber>
      ) : (
        <ScaleNumber key={i}>
          <div>{i}%</div>
        </ScaleNumber>
      )
    );
  }

  const Bar = (props: { brandId: string; received: number; suspended: number; notReceived: number }) => {
    const borderWidth = 15 * (1 + 1);
    const allStatuses = { received: props.received, suspended: props.suspended, notReceived: props.notReceived };
    const allStatusesValues = lodash.sum(Object.values(allStatuses));
    return (
      <FlexContainer style={{ width: `calc(1000% + ${borderWidth}px)` }}>
        {Object.keys(allStatuses).map((status) => {
          return (
            <PartOfABarWithTooltip
              status={status}
              statusValue={allStatuses[status]}
              allStatusesValues={allStatusesValues}
              borderWidth={0}
              visible={visibleTooltip === props.brandId}
              visibleTooltipStatus={visibleTooltipStatus}
              onEnter={() => setVisibleTooltipStatus(status)}
              onExit={() => setVisibleTooltipStatus(undefined)}
            />
          );
        })}
      </FlexContainer>
    );
  };

  const allStatusValues = (brand: IBrandStatusesDeviation) => brand.received + brand.suspended + brand.notReceived;

  let averageReceived = 0;
  let averageSuspended = 0;
  let averageNotReceived = 0;

  brandDeviations.map((brand) => {
    return (averageReceived += brand.received) / allStatusValues(brand);
  });
  brandDeviations.map((brand) => {
    return (averageSuspended += brand.suspended) / allStatusValues(brand);
  });
  brandDeviations.map((brand) => {
    return (averageNotReceived += brand.notReceived) / allStatusValues(brand);
  });

  const average: IBrandStatusesDeviation = {
    brandId: "average",
    brandName: "average",
    received: averageReceived,
    suspended: averageSuspended,
    notReceived: averageNotReceived,
  };

  return (
    <DashboardItem title={<LocalizedString id="sfoc.testFollowingUp" values={{ quarter: props.quarter }} />}>
      {brandDeviations.length > 0 ? (
        <Table style={{ display: "inline" }}>
          <tbody>
            <tr>
              <NameDataCell>{translateString("sfoc.fleet")}</NameDataCell>
              {scale.map((_, index) => (
                <DataCell key={index} isBaseline={false} isLastCell={index === 10}>
                  {index === 0 && (
                    <div
                      onMouseEnter={() => setVisibleTooltip("average")}
                      onMouseLeave={() => setVisibleTooltip(undefined)}
                    >
                      <Bar
                        brandId="average"
                        received={average.received}
                        suspended={average.suspended}
                        notReceived={average.notReceived}
                      />
                    </div>
                  )}
                </DataCell>
              ))}
            </tr>
            {brandDeviations.map((brand) => {
              return (
                <tr key={brand.brandId}>
                  <NameDataCell>{brand.brandName}</NameDataCell>
                  {scale.map((_, index) => (
                    <DataCell key={index} isBaseline={false} isLastCell={index === 10}>
                      {index === 0 && (
                        <div
                          onMouseEnter={() => setVisibleTooltip(brand.brandId)}
                          onMouseLeave={() => setVisibleTooltip(undefined)}
                        >
                          <Bar
                            brandId={brand.brandId}
                            received={brand.received / allStatusValues(brand)}
                            suspended={brand.suspended / allStatusValues(brand)}
                            notReceived={brand.notReceived / allStatusValues(brand)}
                          />
                        </div>
                      )}
                    </DataCell>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              {scale}
            </tr>
          </tfoot>
          <Legend chart="testFollowUp" />
        </Table>
      ) : (
        <LocalizedStringComponent id={"sfoc.noData"} />
      )}
    </DashboardItem>
  );
};
