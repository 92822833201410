import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const VesselIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>vessel</title>
    <polygon points="24.04 10.57 33.21 15.86 33.21 13.9 33.21 13.88 33.21 11.93 29 9.5 29 5.98 27 5.98 27 3.98 20.97 3.98 20.97 5.98 18.96 5.98 18.96 9.52 14.79 11.93 14.79 13.88 14.79 13.9 14.79 15.86 23.96 10.57 24.04 10.57" />
    <path d="M24,13,10,21v4l8,20H30l8-20V21Zm0,28V17.58l10,5.77v.92L27.29,41Z" />
  </IconBase>
);
