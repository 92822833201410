import React from "react";
import { ErrorCode } from "react-dropzone";
import { Dialog } from "src/components/Dialog";
import { LocalizedString } from "src/components/Localization";
import { Container, FlexContainer } from "src/design-system/Container";
import { Link } from "src/design-system/Link";
import colors from "src/design-system/Tokens/colors";
import { fixedFontSizes, P } from "src/design-system/Tokens/typography";
import { IconSize, WarningIcon } from "src/icons";
import { getPathWithParams, getRoutes } from "src/routes";
import { translateString } from "src/util/localization";
import styled from "styled-components";
import { CustomErrorCode, RejectionReasons } from "./types";

interface InvalidFileDialogProps {
  rejectionReasons: RejectionReasons | null;
  onCancel: () => void;
  maxSize?: number;
  allowedTypes: string;
}

const SmallP = styled(P)`
  font-size: ${fixedFontSizes.baseFontSizeSmall}px;
`;

const NormP = styled(P)`
  font-weight: 400;
  margin: 10px 0;
  font-size: 15px;
`;

const BigP = styled(P)`
  font-size: ${fixedFontSizes.baseFontSizeMedium}px;
`;

const DialogContent = styled.div`
  width: 570px;
`;

const MessageContainer = styled(Container)`
  :first-child {
    margin-top: 0;
  }
`;

export const InvalidFileDialog = ({ rejectionReasons, onCancel, maxSize = Infinity, allowedTypes }: InvalidFileDialogProps) => {
  // maxSize is given in bytes, convert to MB
  const maxSizeMB = (maxSize / 1024) / 1024;
  return (
    <Dialog
      title={translateString("fileUpload.errors.attachmentRejected")}
      isDialogOpen={rejectionReasons !== null}
      onCancel={onCancel}
      cancelButtonTitle={translateString("ok").toUpperCase()}
    >
      <DialogContent>
        {rejectionReasons?.[ErrorCode.FileTooLarge] && (
          <>
            <MessageContainer $margin={[3, 0]}>
              <FlexContainer $margin={[0, 0, 2]} $gap={1} $centered>
                <WarningIcon color={colors.primary.orange} size={IconSize.Small} />
                <NormP>{translateString("fileUpload.errors.fileTooBig")}</NormP>
              </FlexContainer>
              <BigP>{translateString("fileUpload.errors.uploadLimit", { limit: maxSizeMB })}</BigP>
            </MessageContainer>
          </>
        )}

        {rejectionReasons?.[CustomErrorCode.DuplicateName] && (
          <>
            <MessageContainer $margin={[3, 0]}>
              <FlexContainer $margin={[0, 0, 2]} $gap={1} $centered>
                <WarningIcon color={colors.primary.orange} size={IconSize.Small} />
                <NormP>{translateString("fileUpload.errors.duplicateFilename")}</NormP>
              </FlexContainer>
              <BigP>{translateString("fileUpload.errors.removeDuplicates")}</BigP>
            </MessageContainer>
          </>
        )}

        {rejectionReasons?.[ErrorCode.FileInvalidType] && (
          <>
            <MessageContainer $margin={[4, 0]}>
              <FlexContainer $margin={[0, 0, 2]} $gap={1} $centered>
                <WarningIcon color={colors.primary.orange} size={IconSize.Small} />
                <NormP>{translateString("fileUpload.errors.typeIsNotSupported")}</NormP>
              </FlexContainer>
              <BigP>{translateString("fileUpload.errors.supportedFileTypes")}</BigP>
              <NormP>{allowedTypes}</NormP>
            </MessageContainer>
          </>
        )}

        <SmallP>
          <LocalizedString
            id="error.pleaseContact"
            values={{
              contactLink: (
                <Link to={{ pathname: getPathWithParams(getRoutes().Contact) }}>
                  {translateString("error.contactLinkText")}
                </Link>
              ),
            }}
          />
        </SmallP>
      </DialogContent>
    </Dialog>
  );
};
