import { IInstallation } from "online-services-types/index";
import * as React from "react";
import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import { Heading } from "src/components/Heading";
import { LoadingSpinner, MenuItemRectangleLoader } from "src/components/LoadingSpinner";
import { LocalizedString } from "src/components/Localization";
import { Container } from "src/design-system/Container";
import { DetailsButton } from "src/design-system/DetailsButton";
import { NavigationButton } from "src/design-system/NavigationButton";
import { Grid, GridRowMediumItems, GridRowSmallItems, GridSpacing } from "src/design-system/Tokens/grid";
import { H4, P } from "src/design-system/Tokens/typography";
import { BasketIcon, CompassIcon, IconSize, RequestsIcon, TechnicalDocumentation } from "src/icons";
import { getPathWithParams, getRoutes } from "src/routes";
import { maxInstallations } from "src/util/formatters";
import { translateString } from "src/util/localization";

export interface IQuantipartsViewStateProps {
  selectedInstallationName?: string;
  selectedInstallationId?: string;
  isReady: boolean;
  installations: IAPIResource<IInstallation>;
  visibleInstallations: number;
}

export interface IQuantipartsViewDispatchProps {
  setSelectedInstallation(selectedInstallationId?: string, selectedInstallationName?: string): void;
}

export type IQuantipartsViewProps = IQuantipartsViewDispatchProps & IQuantipartsViewStateProps;

export class QuantipartsViewComponent extends React.Component<IQuantipartsViewProps> {
  public render() {
    let installationsContent = null;
    const routes = getRoutes();

    let filteredInstallations: IInstallation[] = [];
    if (this.props.installations.data) {
      filteredInstallations = this.props.installations.data.filter(
        (installation: IInstallation) => !installation.hidden
      );
    }

    // Show spinner if busy and there are no old values from previous fetch
    if (this.props.visibleInstallations > maxInstallations) {
      const browseType: string = translateString("installations");
      installationsContent = (
        <Grid itemsPerRow={GridRowSmallItems} fallbackWidth={{ min: 125, max: 140 }}>
          <NavigationButton
            href={getPathWithParams(routes.Installations)}
            icon={<CompassIcon size={IconSize.Large} />}
            label={translateString("browse", { type: browseType })}
          />
        </Grid>
      );
    } else {
      if (Object.keys(filteredInstallations).length > 0 || this.props.installations.status === APIFetchStatus.Busy) {
        if (this.props.installations.status === APIFetchStatus.Busy) {
          installationsContent = <LoadingSpinner />;
        } else {
          installationsContent = (
            <Container $margin={[0, 0, 3, 0]}>
              <Grid itemsPerRow={GridRowMediumItems} fallbackWidth={{ min: 200, max: 200 }} $marginBottom={6}>
                {this.props.installations.status === APIFetchStatus.Success ? (
                  Object.keys(filteredInstallations).map((key) => {
                    return (
                      <div key={key}>
                        <DetailsButton
                          href={getPathWithParams(routes.Installation)}
                          onClick={() => {
                            this.props.setSelectedInstallation(
                              filteredInstallations[key].id,
                              filteredInstallations[key].name
                            );
                          }}
                          label={filteredInstallations[key].name}
                          additionalVesselInfo={undefined}
                        ></DetailsButton>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <MenuItemRectangleLoader />
                  </div>
                )}
              </Grid>
            </Container>
          );
        }
      } else {
        // No visible installations defined
        installationsContent = (
          <Container $padding={[0, 0, 6]}>
            <P>
              <LocalizedString id="noVisibleInstallationsFound" values={{ sector: translateString("installations") }} />
            </P>
            <H4>
              <LocalizedString
                id="noVisibleInstallationsFoundHelp"
                values={{ sector: translateString("myInstallations") }}
              />
            </H4>
          </Container>
        );
      }
    }

    return (
      <>
        <Heading text={`${translateString("installations")}`} className={"main-onboarding-my-installation"} />
        {installationsContent}

        <Heading text={translateString("services")} />
        <Grid itemsPerRow={GridRowSmallItems} fallbackWidth={{ min: 125, max: 140 }} spacing={GridSpacing.airy}>
          <NavigationButton
            href={getPathWithParams(routes.SpareParts)}
            icon={<BasketIcon size={IconSize.Large} />}
            label={translateString("spareParts.serviceGridButtonTitle")}
          />
          <NavigationButton
            href={getPathWithParams(routes.Documents)}
            icon={<TechnicalDocumentation size={IconSize.Large} />}
            label={translateString("routes.technicalDocuments")}
          />
          <NavigationButton
            href={getPathWithParams(routes.Requests)}
            icon={<RequestsIcon size={IconSize.Large} />}
            label={translateString("routes.requests")}
          />
        </Grid>
      </>
    );
  }
}
