import * as React from "react";

import { APIFetchStatus, IAPIResource } from "src/APIFetch";
import { IEquipment, IInstallation, INotification, IResource } from "online-services-types";
import { INotificationContext, NotificationsDataRefresh } from "src/models/notifications";

import { NotificationList } from "src/components/NotificationList";
import { Sidebar } from "../SideMenu/Sidebar";
import { State } from "react-burger-menu";

export interface INotificationSideBarComponentOwnProps {
  isOpen: boolean;
  toggleMenu(): void;
  onMenuStateChange(state: State): void;
}

export interface INotificationSideBarComponentStateProps {
  installations: IAPIResource<IInstallation>;
  notifications: IAPIResource<INotification>;
  equipments: IAPIResource<IEquipment>;
}

export interface INotificationSideBarComponentDispatchProps {
  discardAndSetNotificationAsRead(ids: string[], notificationContext: INotificationContext): void;
  fetchNotifications: (refreshRequests?: NotificationsDataRefresh) => void;
}

export type INotificationSideBarComponentProps = INotificationSideBarComponentOwnProps &
  INotificationSideBarComponentStateProps &
  INotificationSideBarComponentDispatchProps;

// Check if IAPIResource fetch is in Success state or has some earlier result
const isResourceReadyOrPrefetched = (resource: IAPIResource<IResource>) =>
  resource.status === APIFetchStatus.Success || resource?.data?.length > 0;

export const NotificationSideBarComponent = ({
  notifications,
  installations,
  equipments,
  isOpen,
  onMenuStateChange,
  discardAndSetNotificationAsRead,
  fetchNotifications,
}: INotificationSideBarComponentProps) => {
  const isReady =
    isResourceReadyOrPrefetched(notifications) &&
    isResourceReadyOrPrefetched(installations) &&
    isResourceReadyOrPrefetched(equipments);

  React.useEffect(() => {
    if (isOpen && !isReady) {
      fetchNotifications("all");
    }
  }, [isOpen, isReady]);

  const closeSidebar = React.useCallback(() => onMenuStateChange({ isOpen: false }), [onMenuStateChange]);

  return (
    <Sidebar isOpen={isOpen} onMenuStateChange={onMenuStateChange} hideCloseButton>
      <NotificationList
        isReady={isReady}
        notifications={notifications.data}
        installations={installations.data}
        equipments={equipments.data}
        discardAndSetNotificationAsRead={discardAndSetNotificationAsRead}
        onRequestCloseSidebar={closeSidebar}
      />
    </Sidebar>
  );
};
