import * as React from "react";
import { IconBase, IIconBase } from "./IconBase";

export const HalfStarIcon = ({ ...props }: IIconBase) => (
  <IconBase {...props}>
    <title>Half star</title>
    <path
      fill={props.color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 19.5556L30.1778 17.5L24 4.97778L17.8222 17.5L4 19.5556L14 29.3L11.6444 43.0222L24 36.5222L36.3556 43.0222L34 29.2556L44 19.5556ZM29.6222 30.0111L30.4556 34.9L26.0667 32.5889L24 31.4778V15.0222L26.2222 19.4667L27.2556 21.5667L29.5667 21.9L34.4667 22.6111L30.9222 26.0778L29.2222 27.7111L29.6222 30.0111Z"
    />
  </IconBase>
);
