// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const sfoc_es_MX: ITranslations = {
  es_MX: {
    "sfoc.aBank": "Banco A",
    "sfoc.bBank": "Banco B",
    "sfoc.error.valueHasToBe24Hourformat": "El tiempo tiene que estar en formato de 24 horas (HH:MM).",
    "sfoc.error.valueHasToBeBetween": "El valor tiene que estar entre {min} y {max}",
    "sfoc.error.valueHasToBeNumeric": "El valor tiene que ser numérico.",
    "sfoc.error.valueIsRequired": "Se requiere valor.",
    "sfoc.field.ISOCorrectedSFOC": "SFOC corregido ISO",
    "sfoc.field.LTWaterTemperature": "Temperatura de agua LT",
    "sfoc.field.absoluteHumidity": "Humedad absoluta",
    "sfoc.field.activePower": "Potencia activa",
    "sfoc.field.afterTestTakeFuelSample": "Después de la prueba, tome la muestra de combustible, observe el No. de sello, mida con el juego de prueba el contenido de agua",
    "sfoc.field.ambientConditionValuesOnSFOCCabinetSameAsLocalWeatherStation": "Los valores de Condición ambiente en el Gabinete SFOC son los mismos que la Estación Climatológica Local.",
    "sfoc.field.averageFiringPressureBar": "Presión de encendido promedio {bank}",
    "sfoc.field.averageFiringPressureBarHeading": "Presión de encendido promedio",
    "sfoc.field.barometricPressure": "Presión barométrica",
    "sfoc.field.betaFactor": "Factor beta",
    "sfoc.field.calculatedISOCorrectedSFOC": "SFOC corregido ISO calculado",
    "sfoc.field.calculatedSFOC": "SFOC calculado",
    "sfoc.field.chargeAirPressure": "Presión de aire de carga",
    "sfoc.field.chargeAirTemperature": "Temperatura de aire de carga",
    "sfoc.field.checkThatSFOCSystemIsWorkingProperly": "Antes de comenzar esta prueba, revise que el sistema SFOC esté funcionando adecuadamente",
    "sfoc.field.cleanLeakMeasurementTime": "Tiempo de medición",
    "sfoc.field.consumedFuel": "Combustible consumido",
    "sfoc.field.cylinder": "Cilindro {cylinderNr}",
    "sfoc.field.deltaPOverCAC": "Δp sobre CAC",
    "sfoc.field.deltaPOverTCAirFilter": "Δp sobre filtro de aire de T/C",
    "sfoc.field.differentialAutoZeroValue": "Valor cero automático diferencial",
    "sfoc.field.engine": "Motor",
    "sfoc.field.engineRoomTemperature": "Temperatura de compartimiento de motor",
    "sfoc.field.engineRoomVentilationIsOn": "La ventilación del compartimiento del motor está encendida",
    "sfoc.field.engineSpeed": "Velocidad de motor",
    "sfoc.field.exhaustGasBackPressureAfterTC": "Contrapresión de gas de escape después de T/C",
    "sfoc.field.exhaustGasCleaningSystemOff": "Sistema de limpieza de gas de escape (raspador) apagado",
    "sfoc.field.exhaustGasTemperatureAfterTC": "Temperatura de gas de escape después de T/C",
    "sfoc.field.exhaustGasTemperatureBeforeTC": "Temperatura de gas de escape antes de T/C",
    "sfoc.field.exhaustTemperatureAfterCylinder": "Temperatura de escape después de cilindro {bank}",
    "sfoc.field.exhaustTemperatureAfterCylinderHeading": "Temperatura de escape después de cilindro",
    "sfoc.field.foSampleSealNumber": "Número de sello de muestra FO",
    "sfoc.field.fuelCalorificValueLHV": "Valor calorífico de combustible LHV",
    "sfoc.field.fuelFlowInlet": "Entrada de flujo de combustible",
    "sfoc.field.fuelFlowReturn": "Retorno de flujo de combustible",
    "sfoc.field.fuelInletFlowRatioToConsumption": "Relación de flujo de entrada de combustible a consumo",
    "sfoc.field.fuelNetSpecificEnergyLHV": "LVH de Energía específica neta de combustible",
    "sfoc.field.fuelPressureBeforeEngine": "Presión de combustible antes del motor",
    "sfoc.field.fuelTemperatureBeforeEngine": "Temperatura de combustible antes del motor",
    "sfoc.field.fuelType": "Tipo de combustible",
    "sfoc.field.fuelTypeIsTheSameAsUsedDuringTheBaselineTest": "El tipo de combustible es el mismo que el usado durante la prueba de línea base (por lo general HFO)",
    "sfoc.field.fuelViscosityBeforeEngine": "Viscosidad de combustible antes del motor",
    "sfoc.field.generatorPowerFactor": "Factor de potencia generada (Cos Φ)",
    "sfoc.field.loadIndicatorPosition": "Posición de indicador de carga",
    "sfoc.field.measuredCleanLeakWeight": "Peso de fuga limpia medido",
    "sfoc.field.measuredFOC": "FOC medido",
    "sfoc.field.measuredFuelOilCleanLeak": "Fuga limpia de combustible aceite medido",
    "sfoc.field.nickname": "Sobrenombre",
    "sfoc.field.priorStartTestEngineShouldRunWithStableLoadFor20MinInLoadControl": "Antes de comenzar la prueba, el motor debe operar con carga estable por 20 min. en control de carga",
    "sfoc.field.producedEnergy": "Energía producida",
    "sfoc.field.pumpRackPosition": "Posición de bastidor de bomba {bank}",
    "sfoc.field.pumpRackPositionHeader": "Posición de bastidor de bomba",
    "sfoc.field.quarterNr": "Número de trimestre",
    "sfoc.field.repeatTestWhenProducedEnergyIsAboveBaseline": "Repita la prueba cuando la Energía producida sea mayor a ± {tolerance}% de la línea base",
    "sfoc.field.runHoursAtCACOH": "Horas de operación al momento de último OH de CAC",
    "sfoc.field.runHoursAtLastCACWash": "Horas de operación al momento de último lavado de CAC",
    "sfoc.field.runHoursAtLastInjectionPumpOH": "Horas de operación al momento de último OH de bomba de inyección",
    "sfoc.field.runHoursAtLastInjectorOH": "Horas de operación al momento de último OH de inyector",
    "sfoc.field.runHoursAtLastMajorOH": "Horas de operación al momento de último OH mayor",
    "sfoc.field.runHoursAtLastTCMaintenance": "Horas de operación al momento de último mantenimiento de T/C",
    "sfoc.field.runHoursAtLastTCWash": "Horas de operación al momento de último lavado de T/C",
    "sfoc.field.samplingPosition": "Posición de muestreo",
    "sfoc.field.sulfur": "Azufre",
    "sfoc.field.testEndTime": "Tiempo de finalización de prueba",
    "sfoc.field.testEngineInLoadControlModeConfirmThatActivePowerIsTheSameAtBaseline": "Pruebe el motor en Modo de control de carga y confirme que la Potencia activa sea la misma que en la línea base.",
    "sfoc.field.testFailedDueTo": "Prueba fallida debida a",
    "sfoc.field.testStartTime": "Tiempo de inicio de prueba",
    "sfoc.field.totalRunningHours": "Horas de operación totales",
    "sfoc.field.turboChargerSpeed": "Velocidad de turbocargador",
    "sfoc.field.typeOfSFOCTest": "Tipo de prueba SFOC",
    "sfoc.field.waterContent": "Contenido de agua",
    "sfoc.field.waterContentMeasured": "Contenido de agua medido con juego de prueba",
    "sfoc.field.waterContentMeasuredFromFuel": "Contenido de agua medido de combustible",
    "sfoc.field.waterFuelEmulsionOff": "Emulsión de agua combustible (WFE) apagada un mínimo de 6 horas de operación antes de comenzar la prueba.",
    "sfoc.info.sendingReport": "Enviando reporte SFOC.",
    "sfoc.info.sendingReportFailed": "Falló el envío de reporte SFOC.",
    "sfoc.info.sendingReportSuccess": "El reporte SFOC se envió con éxito.",
    "sfoc.meanValue": "Promedio",
    "sfoc.phase.automation": "Automatización",
    "sfoc.phase.cabinet": "Gabinete",
    "sfoc.phase.engine": "Motor",
    "sfoc.phase.general": "General",
    "sfoc.phase.section.dataCollection": "Recolección de datos para cálculo de combustible",
    "sfoc.phase.section.engineOverHaulHistory": "Historial de reparación de motor",
    "sfoc.phase.section.general": "General",
    "sfoc.phase.section.performanceTest": "Prueba de desempeño de motor",
    "sfoc.phase.section.sfocPerformanceCheckList": "Lista de verificación de prueba de desempeño SFOC",
    "sfoc.samplingPosition.AtFOBoosterUnite": "En unidad de reforzador FO",
    "sfoc.samplingPosition.BeforeEngine": "Antes de motor",
    "sfoc.samplingPosition.Other": "Otros",
    "sfoc.testFailure.DiffFlowFluctuationOver2": "Fluctuación de flujo diferencial sobre > ±2%",
    "sfoc.testFailure.LoadAndDiffFlowFluctuationOver2": "Fluctuación de carga y flujo de diferencial sobre > ±2%",
    "sfoc.testFailure.LoadFluctuationOver2": "Fluctuación de carga sobre > ±2%",
    "sfoc.unit.MJPerKg": "MJ/kg",
    "sfoc.unit.bar": "bar",
    "sfoc.unit.gPerKg": "g/kg",
    "sfoc.unit.gPerkWh": "g/kWh",
    "sfoc.unit.hours": "h",
    "sfoc.unit.kW": "kW",
    "sfoc.unit.kWh": "kWh",
    "sfoc.unit.kg": "kg",
    "sfoc.unit.kgPerHour": "kg/h",
    "sfoc.unit.kgPerM3": "kg/m3",
    "sfoc.unit.mbar": "mbar",
    "sfoc.unit.mgPerKg": "mg/kg",
    "sfoc.unit.min": "min",
    "sfoc.unit.mm": "mm",
    "sfoc.unit.mm2PerSec": "mm2/s",
    "sfoc.unit.percent": "%",
    "sfoc.unit.rpm": "rpm",
    "sfoc.value.afterOH": "Después de OH",
    "sfoc.value.audit": "Auditoría",
    "sfoc.value.na": "N/A",
    "sfoc.value.no": "No",
    "sfoc.value.off": "Off (apagado)",
    "sfoc.value.on": "On (encendido)",
    "sfoc.value.other": "Otros",
    "sfoc.value.quarterly": "Trimestral",
    "sfoc.value.yes": "Sí",
  },
};
