import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";

export enum DividerColor {
  Orange,
  White,
  Gray,
}

interface IDividerLineProps {
  $color: DividerColor;
}

export const DividerLine = styled.div`
  height: 1px;
  border-bottom: 1px solid
    ${(props: IDividerLineProps) => {
      switch (props.$color) {
        case DividerColor.Gray:
          return colors.primary.gray;
        case DividerColor.Orange:
          return colors.primary.orange;
        case DividerColor.White:
          return colors.primary.white;
        default:
          return colors.primary.gray;
      }
    }};
`;

export const VerticalDividerLine = styled.div`
  height: 100%;
  border-left: 1px solid
    ${(props: IDividerLineProps) => {
      switch (props.$color) {
        case DividerColor.Gray:
          return colors.primary.gray;
        case DividerColor.Orange:
          return colors.primary.orange;
        case DividerColor.White:
          return colors.primary.white;
        default:
          return colors.primary.gray;
      }
    }};
`;
