import * as React from "react";

export enum FormTheme {
  Dark = "dark",
  Light = "light",
}

interface IFormStyleProviderProps {
  theme?: string;
  children?: React.ReactNode;
}

export class FormStyleProvider extends React.Component<IFormStyleProviderProps> {
  public render() {
    return React.Children.map(
      this.props.children,
      (child: React.ReactElement<any>) => child && React.cloneElement(child, { theme: this.props.theme })
    );
  }
}
