// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const request_en_US: ITranslations = {
  en_US: {
    "request.ETADateInPast": "ETA date cannot be set to past!",
    "request.addContact": "Add contact",
    "request.addMorePeople": "Add more people",
    "request.addNewContact": "Add new contact",
    "request.addNewEmailAddress": "Add new email address",
    "request.addNewUser": "Add user",
    "request.addPartsCatalogue": "Add spare parts from catalogue",
    "request.addPartsManually": "Add spare parts manually",
    "request.addressLine1": "Address line 1",
    "request.addressLine2": "Address line 2",
    "request.attachment.Box": "Box",
    "request.attachment.Box or packing material": "Box or packing material",
    "request.attachment.Claimed part": "Claimed part",
    "request.attachment.Communication with forwarder/airline": "Communication with forwarder/airline",
    "request.attachment.Content of the box(es) and damaged parts": "Content of the box(es) and damaged parts",
    "request.attachment.Corroded part": "Corroded part",
    "request.attachment.Damage on part": "Damage on part",
    "request.attachment.Damaged shipment report": "Damaged shipment report",
    "request.attachment.Entire part": "Entire part",
    "request.attachment.Markings and / or labels on the part": "Markings and / or labels on the part",
    "request.attachment.Markings on the claimed part": "Markings on the claimed part",
    "request.attachment.Markings on the part": "Markings on the part",
    "request.attachment.Markings on the received part": "Markings on the received part",
    "request.attachment.Markings on the required / correct part": "Markings on the required / correct part",
    "request.attachment.Other": "Other",
    "request.attachment.Other related communication": "Other related communication",
    "request.attachment.Other supporting materials": "Other supporting materials",
    "request.attachment.Packing material": "Packing material",
    "request.attachment.Packing, incl. labels": "Packing, incl. labels",
    "request.attachment.Photo / video of leaking part(s)": "Photo / video of leaking part(s)",
    "request.attachment.Photo of box / packing material": "Photo of box / packing material",
    "request.attachment.Photo of claimed part incl. tuning label": "Photo of claimed part incl. tuning label",
    "request.attachment.Photo of claimed part with markings or name plate": "Photo of claimed part with markings or name plate",
    "request.attachment.Photo of corroded part": "Photo of corroded part",
    "request.attachment.Photo of marking on received part": "Photo of marking on received part",
    "request.attachment.Photo of marking on required/correct part": "Photo of marking on required/correct part",
    "request.attachment.Photo of the class markings on the claimed part": "Photo of the class markings on the claimed part",
    "request.attachment.Photo of the label/markings on the claimed part": "Photo of the label/markings on the claimed part",
    "request.attachment.Photos of packaging, incl. label": "Photos of packaging, incl. label",
    "request.attachment.Photos of received not fitting part": "Photos of received not fitting part",
    "request.attachment.Photos of received wrong part": "Photos of received wrong part",
    "request.attachment.Photos of required part": "Photos of required part",
    "request.attachment.Photos of required/correct part": "Photos of required/correct part",
    "request.attachment.Photos of the box / packing material": "Photos of the box / packing material",
    "request.attachment.Photos of the content of the box": "Photos of the content of the box",
    "request.attachment.Photos of the damaged parts": "Photos of the damaged parts",
    "request.attachment.Photos of the entire part": "Photos of the entire part",
    "request.attachment.Photos showing the damage": "Photos showing the damage",
    "request.attachment.Picture of displayed error message": "Picture of displayed error message",
    "request.attachment.Pictures of leaking parts": "Pictures of leaking parts",
    "request.attachment.RFQ and PO provided to Wartsila": "RFQ and PO provided to Wärtsilä",
    "request.attachment.Received part": "Received part",
    "request.attachment.Related communication": "Related communication",
    "request.attachment.Required / correct part": "Required / correct part",
    "request.attachment.Required part": "Required part",
    "request.attachment.Test report(s)": "Test report(s)",
    "request.attachment.Test reports": "Test reports",
    "request.attachment.Test/work reports": "Test/work reports",
    "request.attachmentDescription": "Please, provide overview pictures, and closeup pictures with a maximum file size of {maxSize}.",
    "request.attachmentDialog.Confirm": "Remove",
    "request.attachmentDialog.Text": "Are you sure you want to remove attachment?",
    "request.attachmentDialog.Title": "Remove attachment",
    "request.attachments": "Attachments",
    "request.attachmentsTooltip": "Please add all relevant documentation (measurement records, pictures, reports, etc.) to support efficient handling of your request.",
    "request.automaticallyNotifiedCustomers": "These people will be automatically notified",
    "request.auxAttachmentDescription": "Please, provide overview pictures, closeup pictures, and pictures of product register plate with a maximum file size of {maxSize}.",
    "request.backgroundInformation": "Background information",
    "request.beforeClaiming": "Before claiming missing parts, please ensure that the following checks have been done:",
    "request.beforeClaiming.checkBottom": "3. In wooden crates there is sometimes a wooden sheet in the bottom that might appear as the floor/bottom and the parts might be placed under this sheet.",
    "request.beforeClaiming.checkPackage": "1. Double check the parcel/box/wooden crate in which the delivery came.",
    "request.beforeClaiming.checkPaddings": "2. Check paddings and packing materials.",
    "request.chooseAccounts": "Choose accounts",
    "request.chooseReferenceRequest": "Choose reference request",
    "request.city": "City",
    "request.claimAllItems": "Claim all delivery lines for the same reason / issue",
    "request.claimBasketText": "{basketItems} line items",
    "request.claimDescription": "Short claim description",
    "request.claimItem": "Claim item",
    "request.claimOrder": "Claim order",
    "request.claimQuantity": "Claimed quantity",
    "request.claimReason": "Claim reason",
    "request.claimReasonChangeDislaimer": "Please note that when changing the claim reason, your item specific answers will be lost.",
    "request.claimReasons": "Claim reasons",
    "request.claimReference": "Reference claim",
    "request.claimedItems": "Claimed items",
    "request.claimedOrder": "Orders to claim",
    "request.claimedOrderDisclaimer": "If you are not able to find your PO in orders to claim history, please use the search field and look for your PO or delivery number",
    "request.classMarkingsOnReceivedPart": "What class marking(s) does the received part have?",
    "request.closeClaim": "Close Claim",
    "request.closeClaim.areYouSure": "Are you sure you want to close this claim? The claim will no longer be progressed.",
    "request.closeClaim.closed": "Your request to close this claim has been received",
    "request.closeClaim.disclaimer": "Let us know if you no longer need us to progress this claim",
    "request.comment": "Comment",
    "request.companyName": "Company name",
    "request.confirmClosedClaim": "Confirm closed claim",
    "request.contactInformation": "Contact information",
    "request.contactInformationDescription": "You are the primary contact person for this request, but you can add additional interested people who will be notified about relevant changes to this request.",
    "request.contactReason": "Contact reason",
    "request.contactReason.helpUsingOnline": "Help using Wärtsilä Online",
    "request.contactReason.helpUsingOnline.explanation": "Request support in using Wärtsilä Online platform, finding the necessary information and functionalities",
    "request.contactReason.helpUsingQuantiParts": "Help using QuantiParts portal",
    "request.contactReason.helpUsingQuantiParts.explanation": "Request support in using QuantiParts portal, finding the necessary information and functionalities",
    "request.contactReason.improvementSuggestion": "Improvement suggestion",
    "request.contactReason.improvementSuggestion.explanation": "Give us feedback and improvement ideas for Wärtsilä Online platform",
    "request.contactReason.improvementSuggestion.explanation.quantiparts": "Give us feedback and improvement ideas for QuantiParts portal",
    "request.contactReason.onlineNotWorking": "Wärtsilä Online not working properly",
    "request.contactReason.onlineNotWorking.explanation": "Report an issue or a bug in Wärtsilä Online platform",
    "request.contactReason.quantipartsNotWorking": "QuantiParts portal not working properly",
    "request.contactReason.quantipartsNotWorking.explanation": "Report an issue or a bug in QuantiParts portal",
    "request.contactRequestSent": "Contact request sent!",
    "request.couldntDownloadFile": "Could not download file",
    "request.couldntFindDelivery": "Couldn't find delivery!",
    "request.couldntStartFileDownload": "Could not start file download",
    "request.country": "Country",
    "request.createdBy": "Created by",
    "request.creationDate": "Created date",
    "request.creatorCompany": "Creator company",
    "request.creatorName": "Creator name",
    "request.customerClaimReference": "Customer claim reference",
    "request.customerClaimReferenceOptional": "Customer claim reference (optional)",
    "request.customerInformation": "Customer information",
    "request.daAddressLine1IsRequired": "Address is required!",
    "request.daCityIsRequired": "City is required!",
    "request.daCompanyNameIsRequired": "Company name is required!",
    "request.daCountryIsRequired": "Country is required!",
    "request.daPostalCodeIsRequired": "Postal code is required!",
    "request.dateOfMeasurement": "Date of measurement",
    "request.dateOfMeasurementOptional": "Date of measurement (optional)",
    "request.dateOfOccurrence": "Date of occurrence",
    "request.decreaseInAvailabilityReliability": "A decrease in installation availability or reliability",
    "request.decreaseInAvailabilityReliability:energy": "A decrease in plant availability or reliability",
    "request.decreaseInAvailabilityReliability:marine": "A decrease in vessel availability or reliability",
    "request.deliveryAddress": "Delivery address",
    "request.deliveryExpected": "Delivery expected",
    "request.deliveryNumber": "Delivery number",
    "request.deliveryReceived": "Delivery received",
    "request.deliveryToDestination": "Has the claimed delivery been delivered to the destination?",
    "request.describeLeakageNoticed": "When and how was the leakage noticed",
    "request.describeTechnicalProblemInDetail": "Describe your technical problem in detail",
    "request.description": "Description",
    "request.descriptionIsRequired": "Description is required!",
    "request.descriptionLong": "The more detailed information we receive at the registration, the quicker we can handle the request",
    "request.deselect": "Remove",
    "request.detailedDescription": "Detailed description",
    "request.detailedDescriptionTooltip": "What is the problem?\nHow is it identified?\nWhat could be the reason?\nWas maintenance carried out recently?\nUnder what circumstances/condition did it occur?",
    "request.detailedReason": "Detailed reason",
    "request.didThisIssueCauseFollowing": "Did this issue cause any of the following? Select all that apply",
    "request.discardingDraft": "Discarding draft...",
    "request.disclaimerLeakage": "If this claim is for leaking, dripping or not atomizing injection nozzle(s), fuel/injection pump, fuel injectors, etc.",
    "request.disclaimerLeakageLink": "please consult with bulletin {documentId} before the claim is created.",
    "request.distributor": "QuantiParts distributor",
    "request.distributorInfo": "QuantiParts distributor contact information",
    "request.doesPartHaveTunedLabel": "Does the part have a \"TUNED\" label on it?",
    "request.draftDiscarded": "Draft discarded.",
    "request.email": "Email",
    "request.emailIsRequired": "Email is required",
    "request.engineShutdownBlackout": "An engine shutdown or a installation blackout",
    "request.engineShutdownBlackout:energy": "An engine shutdown or a plant blackout",
    "request.engineShutdownBlackout:marine": "An engine shutdown or a vessel blackout",
    "request.equipmentIsRequired": "Product is required",
    "request.erroWhileFetchingSpareParts": "Error while fetching spare parts",
    "request.errorWhileDiscardingDraft": "Error while discarding draft: {error}",
    "request.errorWhileFetchingAccounts": "Error while fetching accounts",
    "request.errorWhileFetchingAttachments": "Error while fetching attachments",
    "request.errorWhileFetchingDelivery": "Error while fetching delivery: {error}",
    "request.errorWhileSelectingAccount": "Error while selecting account",
    "request.errorWhileSendingContactRequest": "Error while sending contact request",
    "request.errorWhileSendingInstallationRequest": "Error while sending installation request",
    "request.errorWhileSendingMessage": "Error while sending message",
    "request.errorWhileSendingRunningHoursRequest": "Error while sending running hours request",
    "request.errorWhileSettingNotificationRead": "Error while setting notification read",
    "request.errorWhileUpdating": "Error while updating",
    "request.errorWhileUpdatingInstallationVisibility": "Error while updating installation visibility",
    "request.expectedReceiveDateInPast": "Expected date of receiving cannot be set to past!",
    "request.exportAll": "Export all",
    "request.exportVisible": "Export visible",
    "request.external": "External",
    "request.faulthyEmail": "Please input a valid e-mail address before you can continue",
    "request.filterAccountsForSparePartsClaims": "Filter accounts for spare parts claims",
    "request.firstName": "First name",
    "request.firstnameIsRequired": "First name is required",
    "request.generalInformation": "General information",
    "request.hadBoxBeenOpened": "Had the box/crate been opened by anyone before you received it (e.g. customs, forwarding agent)?",
    "request.hadBoxBeenOpenedBefore": "Had the box/crate been opened by anyone before it was received by the consignee?",
    "request.handledByDistributor": "Request is sent to and handled by distributor",
    "request.haveNotifiedFreightForwarder": "Have you already claimed or notified the freight forwarder?",
    "request.haveNotifiedInsuranceCompany": "Have you already claimed or notified the insurance company?",
    "request.haveTSBeenContacted": "Have Technical Services been contacted already?",
    "request.help.sparePartClaim": "Spare part claim",
    "request.help.sparePartClaim.description": "Create a spare part claim in case of an issue with a Wärtsilä spare part delivery",
    "request.help.sparePartClaim.description.quantiparts": "Create a spare part claim in case of an issue with a QuantiParts spare part delivery",
    "request.help.sparePartIdentification": "Spare part identification",
    "request.help.sparePartIdentification.description": "Request support with identification of spare parts, finding correct spare parts and spare part numbers",
    "request.help.sparePartIdentification.withCommerce": "If you need help with identification of spare parts, finding correct spare parts and spare part numbers, please proceed to \"Browse spare parts\". If you are unable to find what you need by searching the catalogue, please use \"Add spare parts manually\" or \"I don't have a spare part number\" features. Please proceed to fill in any information about the spare parts you need and request a quotation. Our spare parts team will then assist you further.",
    "request.help.sparePartIdentification.withoutCommerce": "Please contact your nearest {link} to request a spare parts quotation, to send us your purchase order or if you need assistance with identification of spare parts, finding correct spare parts and spare part numbers.",
    "request.help.technicalDocuments": "Technical documents",
    "request.help.technicalDocuments.description": "Ask questions about the content or applicability of the technical documents, request missing documents",
    "request.help.technicalQuestions": "Technical questions",
    "request.help.technicalQuestions.description": "Request for technical support and ask technical questions related to Wärtsilä equipment during its lifecycle",
    "request.help.technicalQuestions.description.quantiparts": "Request for technical support and ask technical questions related to QuantiParts equipment during its lifecycle",
    "request.help.tryTheseLinks": "Not what you are looking for? Try these links instead.",
    "request.help.warrantyQuestions": "Warranty questions",
    "request.help.warrantyQuestions.description": "Create warranty claims and ask technical questions related to Wärtsilä equipment under active warranty",
    "request.help.warrantyQuestions.description.quantiparts": "Create warranty claims and ask technical questions related to QuantiParts equipment under active warranty",
    "request.howTheReasonWasIdentified": "How issue was identified and in what circumstances?",
    "request.howTheReasonWasIdentifiedOptional": "How issue was identified and in what circumstances? (optional)",
    "request.ifYouWantToCommentText": "If you want to continue commenting, re-open request",
    "request.installationIsRequired": "Installation is required",
    "request.installationRequestSent": "Installation request sent!",
    "request.interestedPeople": "Interested people",
    "request.isInWarrantyUntil": "Warranty period active until {date}",
    "request.item": "Item",
    "request.itemCount": "Item count",
    "request.keptPartCondition": "Has the part been stored in rust protective packaging after receipt?",
    "request.lastModified": "Last modified",
    "request.lastName": "Last name",
    "request.lastnameIsRequired": "Last name is required",
    "request.linked.lastComment": "Last comment: {lastCommentDate} by {lastCommentBy}",
    "request.linked.lastModified": "Last modified: {lastModified}",
    "request.linked.status": "Status: {status}",
    "request.linked.techRequestHasWarranty": "This Technical Support Request has a linked Warranty Request: {link}",
    "request.linked.warrantyHasTechRequest": "This Warranty Request has a linked Technical Support Request: {link}",
    "request.machineryOrEquipmentBreakdown": "A machinery or an equipment breakdown",
    "request.makeRequest": "Make request",
    "request.messageSent": "Message sent!",
    "request.multipleItems": "Multiple items ({count})",
    "request.newSparePartsClaim": "New spare part claim",
    "request.newTechnicalSupport": "New Technical support request",
    "request.newWarranty": "New Warranty request",
    "request.noRequestsFound": "No requests found.",
    "request.noSparePartNumber": "I don't have a spare part number",
    "request.nonTechnical": "Need help with Wärtsilä Online?",
    "request.nonTechnical.description": "Ask questions about usage of Wärtsilä Online platform or share feedback and development ideas",
    "request.nonTechnicalQP": "Need help with QuantiParts portal?",
    "request.nonTechnicalQP.description": "Ask a question about usage of QuantiParts portal or share feedback and development ideas",
    "request.notification.completedSparePartClaim": "Spare part claim is completed",
    "request.notification.newAttachmentAdded": "A {link} has been added",
    "request.notification.newAttachmentAdded.linkText": "new attachment",
    "request.notification.newComment": "A new comment has been added",
    "request.notification.newRequest": "A new request has been created",
    "request.notification.newRequestDistributor": "Request was sent to a distributor who handles it",
    "request.notification.newSparePartClaim": "A new spare part claim has been created",
    "request.notification.solutionPlanIsProvided": "Solution plan is provided",
    "request.numberDoesNotMatchAccount": "The entered document number does not correspond with your account details. Please double check and try again or contact Wärtsilä.",
    "request.officiallyReportedLost": "Has the shipment been officially reported as lost by the freight forwarder or airline?",
    "request.openFullRequest": "Open full request",
    "request.openRequests": "Requests",
    "request.option.afterInstallation": "After installation",
    "request.option.beforeInstallation": "Before installation",
    "request.option.duringInstallation": "During installation",
    "request.option.duringTesting": "During testing",
    "request.option.duringUse": "During use",
    "request.option.other": "Other",
    "request.option.whenBoxWasOpened": "When box was opened",
    "request.option.wrongCustomerName": "Wrong Customer name and/or address",
    "request.option.wrongDiscount": "Wrong/missing discount",
    "request.option.wrongExportDeclaration": "Wrong export declaration",
    "request.option.wrongFreight": "Wrong/missing freight",
    "request.option.wrongInvoiceAddress": "Wrong Invoice address",
    "request.option.wrongPrice": "Wrong price",
    "request.option.wrongVAT": "Wrong VAT",
    "request.optional": "(optional)",
    "request.orderInformation": "Order information",
    "request.orderNumber": "Wärtsilä order number",
    "request.part": "Part",
    "request.partsClaimAttachmentDescription": "Provide good quality and clear photos!",
    "request.pendingCustomerConfirmation": "Pending Customer Confirmation",
    "request.peopleAddedAutomatically": "People added automatically",
    "request.peopleAddedByUser": "People added by user",
    "request.phone": "Phone",
    "request.phone.optional": "Phone (optional)",
    "request.pleaseAttachDocuments": "Attachments",
    "request.pleaseEnterNumber": "Please enter document number.",
    "request.plusOthers": "+{count} others",
    "request.poNumber": "Your PO number",
    "request.possibleActionsAlreadyTaken": "Actions already taken",
    "request.possibleActionsAlreadyTakenOptional": "Actions already taken (optional)",
    "request.possibleActionsAlreadyTakenOptionalTooltip": "What was done to correct it?\nWhat tests/measurements have been done?\nWhich parts have been replaced?",
    "request.postalCode": "Postal code",
    "request.premium.accept": "Accept",
    "request.premium.additionalInformation": "In order to process your request faster we will ask to provide additional information.",
    "request.premium.agreement": "By agreeing to the above GTC you confirm that you are authorized to purchase the Premium Technical Request Service.",
    "request.premium.attachmentDeleted": "File Deleted",
    "request.premium.billingAccount": "Billing account",
    "request.premium.chatIsClosed": "This chat is closed",
    "request.premium.closedSessions": "Closed sessions",
    "request.premium.goPremium": "Go Premium",
    "request.premium.goPremiumExplanation": "Go Premium will provide you direct access to the highest technical support level in Wärtsilä. The Premium service is not intended to be used for emergency situations. We will acknowledge your request and match your availability to collaborate within one business day. Please note that due to the specific product knowledge our technical experts are available only from Monday to Friday during standard working hours from 7:00 to 17:00 UTC+1 (CET).",
    "request.premium.gtcDisclaimer": "You must read and accept the GTC before proceeding",
    "request.premium.gtcLink": "GTC",
    "request.premium.howMuchItCosts": "How much it costs: {price}",
    "request.premium.invoiceEmail": "Invoice email address",
    "request.premium.invoiceEmail.description": "The invoice for this service will be delivered to your registered email address \"{email}\".\nIf you wish to specify a different recipient, please use the text box below",
    "request.premium.invoiceEmail.invalid": "Please enter a valid email address or leave the field empty",
    "request.premium.leaveBlank": "Leave blank for soonest possible contact",
    "request.premium.openChat": "Open Chat",
    "request.premium.openSessions": "Open sessions",
    "request.premium.or": "OR",
    "request.premium.premiumSupportChat": "Premium Support chat",
    "request.premium.premiumTechnicalRequest": "Premium Technical Request",
    "request.premium.premiumTechnicalRequestATRS": "Premium Technical Request - ATRS",
    "request.premium.purchase": "Purchase",
    "request.premium.schedule": "Schedule",
    "request.premium.seeAll": "See all",
    "request.premium.selectChatDate": "Select date and hours you would like Wärtsilä to contact via chat.",
    "request.premium.step": "Step",
    "request.premium.timeMustBeBetween": "Requested time must be between 07:00 CET and 17:00 CET",
    "request.premium.typeMessage": "Type message...",
    "request.premium.viewProcedure": "View procedure",
    "request.premium.viewTranscript": "View Transcript",
    "request.premium.wartsilaWillContact": "Wärtsilä will contact you within 24 hours, or on a specific time most suitable for you.",
    "request.premium.yourPOReference": "Your purchase order reference",
    "request.productForTuning": "What product number was the tuning originally requested for?",
    "request.productReferenceType": "Product reference type",
    "request.provideAnyAdditionalDetails": "Provide any additional details",
    "request.provideDetailedDescription": "Detailed description of the problem",
    "request.provideDetailedDescriptionDisclaimerCorroded": "Answer at least to these questions:\n- Detailed description of the issue and how it was discovered?\n- Where on the part is corrosion found?\n- Has anything been done to correct it?",
    "request.provideDetailedDescriptionDisclaimerGeneric": "Answer at least to these questions:\n- What is the problem and how is it identified?\n- Under what conditions did it occur?\n- What could be the reason?\n- What has already been done to correct it?\n- What tests/measurements have been done?",
    "request.provideDocumentNameReferenceNumber": "Inform the claimed document name and its reference number, e.g. Invoice, packing list, certificate, etc.",
    "request.provideMeasurementsClaimed": "Provide measurements / markings / specifications / type plate details of the received not fitting part",
    "request.provideMeasurementsCorrect": "Provide measurements / markings / specifications / type plate details of the required (correct) part.",
    "request.provideMeasurementsReceived": "Provide measurements / markings / specifications / type plate details of the received part.",
    "request.provideMeasurementsRequired": "Provide measurements / markings / specifications / type plate details of the required (correct) part",
    "request.provideMoreDetails": "Provide more details",
    "request.provideTRNumber": "Provide TechRequest number",
    "request.provideTypeSerialNumber": "In case of auxiliary equipment, provide the type and serial number of the unit and component.",
    "request.quantity": "Quantity",
    "request.rating": "Rating",
    "request.rating.amountOfContentInTheReport": "Amount of content in the report",
    "request.rating.approveLabel": "Are you satisfied with the service?",
    "request.rating.clarityAndOverview": "Clarity and overview",
    "request.rating.customerServiceRegards": "Best Regards,\n\nWärtsilä",
    "request.rating.customerServiceThankYou": "Thank you,\n\nWärtsilä",
    "request.rating.diagnosticResultsOfIndividualSignals": "Diagnostic results of individual signals",
    "request.rating.feedbackGiven": "Feedback given",
    "request.rating.feedbackSent": "Feedback sent",
    "request.rating.healthIndices": "Health indices",
    "request.rating.loadAndRunningHoursStatistics": "Load and running hours statistics",
    "request.rating.notSatisfied": "Not satisfied",
    "request.rating.offlineCouldNotLoadTicket": "Could not load ticket for offline feedback.",
    "request.rating.offlineRequestRatedAlready": "Request rated already",
    "request.rating.offlineRequestRatedAlreadyMessage": "The request \"{requestTitle}\" has already been rated but you can still leave feedback.",
    "request.rating.offlineRequestRatedSuccessfully": "Request rated successfully",
    "request.rating.offlineRequestRatedSuccessfullyMessage": "Thank you for rating \"{requestTitle}\".",
    "request.rating.offlineSendFeedback": "Send feedback",
    "request.rating.offlineSendingFeedback": "Sending feedback...",
    "request.rating.offlineSendingFeedbackFailed": "Sending feedback failed.",
    "request.rating.offlineSendingReopenRequest": "Sending re-open request...",
    "request.rating.offlineSendingReopenRequestFailed": "Sending re-open request failed.",
    "request.rating.openFeedback": "Open feedback to improve our service",
    "request.rating.openFeedbackPlaceholder": "You can also leave open feedback...",
    "request.rating.openFeedbackToImproveOurService": "Open feedback to improve our service",
    "request.rating.partlySatisfied": "Partly satisfied",
    "request.rating.reasonForNotFullySatisfiedRating": "I am not fully satisfied with...",
    "request.rating.reasonForReopening": "Reason for re-opening",
    "request.rating.reasonForSatisfiedRating": "I am satisfied with...",
    "request.rating.reasonIsRequired": "Reason for re-opening is required.",
    "request.rating.reasonPlaceholder": "Type reason...",
    "request.rating.reopenButtonText": "Re-open",
    "request.rating.reopenDialogTitle": "Re-open request",
    "request.rating.reopenRequest": "Re-open request",
    "request.rating.requestIsClosed": "Request is closed",
    "request.rating.requestIsClosedNewRequestLinkText": "new request",
    "request.rating.requestIsClosedRatingTitle": "Request is closed and cannot be rated any longer.",
    "request.rating.requestIsClosedTitle": "Request is closed and cannot be re-opened any longer.\nPlease create a {newRequestLink}.",
    "request.rating.requestReopened": "Request re-opened",
    "request.rating.requestReopenedText": "The request is re-opened, and we will get back to you as soon as possible.",
    "request.rating.satisfied": "Satisfied",
    "request.rating.sendFeedback": "Send satisfaction feedback",
    "request.rating.suggestionIsRequired": "Feedback is required.",
    "request.rating.thankYouForYourFeedback": "Thank you for your feedback",
    "request.rating.thankYouForYourFeedbackText": "We are all the time looking for ways to improve our customer service, and your feedback is the most most valuable.",
    "request.rating.timelinessOfReport": "Timeliness of report",
    "request.rating.weHaveAlreadyReceivedFeedback": "We have already received feedback for this request. No further feedback can be given.",
    "request.rating.weWillRedirectYou": "We will now forward you to {link}",
    "request.rating.whatCanWeDoBetter": "Type feedback...",
    "request.reason": "Reason",
    "request.reason.9999": "Quality deficiency of part",
    "request.reason.DD02": "Invoice / other delivery document issue",
    "request.reason.DD06": "Invoice / other delivery document issue",
    "request.reason.DD07": "Invoice / other delivery document issue",
    "request.reason.DD08": "Invoice / other delivery document issue",
    "request.reason.DD11": "Invoice / other delivery document issue",
    "request.reason.DD13": "Invoice / other delivery document issue",
    "request.reason.DD14": "Invoice / other delivery document issue",
    "request.reason.DD16": "Incomplete / excess delivery",
    "request.reason.DD18": "Wrong part received",
    "request.reason.DD22": "Late delivery",
    "request.reason.DD23": "Lost shipment (complete delivery lost)",
    "request.reason.DD24": "Poor packaging / delivery damaged",
    "request.reason.QD02": "Part is leaking",
    "request.reason.QD03": "Part is non functional",
    "request.reason.QD05": "Part with wrong / missing tuning",
    "request.reason.QD07": "Part is corroded",
    "request.reason.QD08": "Part is damaged",
    "request.reason.QD09": "Part does not fit",
    "request.reason.QD12": "Part classification issue",
    "request.reason.QD14": "Part markings / label related issue",
    "request.reason.returnUnusedParts": "Return of unused parts",
    "request.reasonForNewInvoice": "Is the new invoice needed for export/import or only for payment?",
    "request.recentlyOpened": "Recently opened requests",
    "request.requestId": "Request ID",
    "request.requestType": "Request type",
    "request.requestTypeIsRequired": "Please select request type",
    "request.requestedClass": "What class was requested at the time of order?",
    "request.requestedProductIsQPAndHandledByDistributor": "Requested product is Quantiparts product and request is sent to and handled by distributor",
    "request.runningHours": "Running hours",
    "request.runningHoursDate": "Date",
    "request.runningHoursDateInFuture": "Running hours date cannot be in the future!",
    "request.runningHoursDateInPast": "Running hours date cannot be set to earlier date than the date of the last update!",
    "request.runningHoursIsMustBeInteger": "Running hours must be an integer with no whitespaces!",
    "request.runningHoursMustBeBetween": "Running hours must be a number between 0 and 9 999 999 999!",
    "request.runningHoursOptional": "Running hours (optional)",
    "request.runningHoursRequestSent": "Running hours request sent!",
    "request.sameProduct": "Is this the same product where the ordered part is being installed on?",
    "request.selectAccounts": "Select account(s)",
    "request.selectAddress": "Select address",
    "request.selectCategory": "Select category...",
    "request.selectClaimReason": "Select reason...",
    "request.selectClaimedItems": "Select claimed item(s)",
    "request.selectDetailedReason": "Select detailed reason...",
    "request.sendingContactRequest": "Sending contact request...",
    "request.sendingMessageToContractManager": "Sending message to contract manager...",
    "request.sendingRunningHoursRequest": "Sending running hours request...",
    "request.solution": "Solution",
    "request.solutionPlan": "Solution plan",
    "request.solutionPlanProvided": "Solution plan provided",
    "request.solutionText": "Wärtsilä solution",
    "request.sparePartClaim.description": "Create spare part claims related to Wärtsilä spare part deliveries",
    "request.sparePartsClaim": "Spare part claim",
    "request.specifyWhatIssue": "Specify what issue is this claim concerning",
    "request.subject": "Subject",
    "request.subjectIsRequired": "Subject is required",
    "request.submittedDate": "Submitted date",
    "request.successfullyUpdated": "Successfully updated!",
    "request.suggestedPartsText": "If you already know which spare parts should be replaced, please add them to the request.",
    "request.suggestedSpareParts": "Suggested spare parts",
    "request.suggestionToReplaceParts": "Suggestion to replace spare parts",
    "request.summary": "Summary",
    "request.techRequest.description": "Create technical support requests during lifecycle of Wärtsilä equipment",
    "request.technical": "Technical support request",
    "request.technical.noTechRequest": "Warranty request",
    "request.title": "Job title",
    "request.titleIsRequired": "Job title is required",
    "request.track": "Track",
    "request.typePartNameOrNumber": "Type part name or number",
    "request.typeToSearch": "Type to search",
    "request.types.allRequests": "All requests",
    "request.underWhatConditions": "Under what conditions did the issue occur?",
    "request.unknownSource": "Unknown source",
    "request.update": "Update",
    "request.updateAndMakeRequest": "Update / make request",
    "request.updating": "Updating...",
    "request.uploadsAreNotFinished": "Uploads are not finished!",
    "request.validation.attachmentIsRequiredAny": "At least one attachment is required!",
    "request.validation.contactReasonIsRequired": "Contact reason is required!",
    "request.validation.deliveryIsRequired": "Delivery is required!",
    "request.validation.detailedReasonIsRequired": "Detailed reason is required!",
    "request.validation.fieldIsRequired": "Required field!",
    "request.validation.itemIsRequired": "Item is required!",
    "request.validation.quantityIsRequired": "Claimed quantity is required!",
    "request.validation.quantityMustBeNumberBetween": "Invalid quantity entered! (min: {min}, max: {max})",
    "request.validation.quantityMustBeNumeric": "Claimed quantity must be number!",
    "request.validation.reasonIsRequired": "Reason is required!",
    "request.validation.returnUnusedParts": "If you want to return unused parts without any errors in part, delivery or documents, please contact spare parts coordination team instead of starting a spare part claim. For any error occurred please select more suitable claim reason.",
    "request.visibleToShipyard": "Visible to Shipyard",
    "request.warranty.description": "Create warranty requests for Wärtsilä equipment under warranty",
    "request.warrantyEndDateForShipyard": "Warranty end date for the Yard",
    "request.wartsilaInitiated": "Wärtsilä initiated",
    "request.wartsilaSparePartsDepartment": "Wärtsilä spare parts department",
    "request.wasBoxVisiblyDamaged": "If the parts inside the parcel were damaged, was the box or crate visibly damaged?",
    "request.wasForwarderInformed": "If the box or crate was visibly damaged at receipt, was the forwarder informed at the time of delivery?",
    "request.wasOutsideOfBoxDamaged": "Was the outside of the box or crate damaged?",
    "request.wasPackagingMaterialDamaged": "Was the packing material inside the box damaged or insufficient?",
    "request.wasPackingMaterialDamagedInsufficient": "Was the packing material inside the box damaged or insufficient?",
    "request.wasPartInUseLeakage": "Was the part in use when the leakage was noticed? If yes, for how many running hours?",
    "request.wasPartInUseProblem": "Was the part in use when the problem was noticed? If yes, for how many running hours?",
    "request.wasPartInspectedByWartsila": "If the part was in use, was the part inspected and installed by Wärtsilä personnel?",
    "request.whatIsDisplayed": "What is displayed on the unit when installed / tested?",
    "request.whatIsTheProblem": "What is the problem and how was it identified?",
    "request.whatReason": "What could be the reason?",
    "request.whatTestsDone": "What tests/measurements have been done?",
    "request.whatWasDoneToCorrect": "What was done to correct it?",
    "request.whenCorrosionNoticed": "When was the corrosion noticed?",
    "request.whenDeliveryExpected": "When was the delivery expected?",
    "request.whenDeliveryReceived": "When was the delivery received?",
    "request.whenWasDamageNoticed": "When was the damage noticed?",
    "request.whichProductNumber": "Which product number is the item being installed on?",
    "request.wrongPartExtraInformation": "Specify possible spare part list, manual or Service Bulletins according to which the part(s) were ordered",
    "request.yourClaimReference": "Your claim reference",
    "requestStatus.answered": "Completed",
    "requestStatus.approved": "Approved",
    "requestStatus.closed": "Closed",
    "requestStatus.closedChangedToProject": "Closed - Changed to project",
    "requestStatus.draft": "Draft",
    "requestStatus.inProgress": "In progress",
    "requestStatus.inProgressOrOpen": "In progress / Open",
    "requestStatus.open": "Open",
    "requestStatus.partsInDelivery": "Parts in delivery",
    "requestStatus.rejected": "Rejected",
    "requestStatus.sentToDistributor": "Sent to distributor",
    "requestStatus.waitingForCustomerActions": "Waiting for customer actions",
  },
};
