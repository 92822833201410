import * as React from "react";

import { IconSize, PdfIcon } from "src/icons";
import { Loader, spinnerSize } from "../../components/LoadingSpinner/LoadingSpinner";

import { AnalyticsEvent } from "../AnalyticsEvent";
import { FlexContainer } from "src/design-system/Container";
import { analyticsDocumentTypes } from "../AnalyticsEvent/AnalyticsEventContainer";
import { analyticsEvents } from "src/events";
import colors from "src/design-system/Tokens/colors";
import { displayError } from "src/util/error";
import { get } from "lodash";
import { getDeliveryAttachmentDownloadUrl } from "src/util/commerce";
import styled from "styled-components";

interface IDownloadProps {
  delivery: string;
  source: string;
}

const IconWrapper = styled(FlexContainer)`
  height: 50px;
  cursor: pointer;
`;

export const DeliveryAttachmentDownload = (props: IDownloadProps) => {
  const [isDownloading, setDownloading] = React.useState(false);
  const downloadAttachment = async () => {
    try {
      let downloadUrl;
      const { delivery, source } = props;
      downloadUrl = await getDeliveryAttachmentDownloadUrl(delivery, source);

      if (downloadUrl) {
        window.open(downloadUrl, "_blank");
        setDownloading(false);
      }

      setDownloading(false);
    } catch (e) {
      displayError(e);
      setDownloading(false);
    }
  };

  const handleClick = async () => {
    if (!isDownloading) {
      setDownloading(true);
      await downloadAttachment();
    }
  };

  return (
    <AnalyticsEvent
      event={({ account, user }) =>
        analyticsEvents.dcpDownloadDocument({
          dcp_user_role: user.role,
          dcp_user_seniority: user.seniority,

          dcp_account_role: account.role,
          dcp_account_sector: account.sector,
          dcp_account_country: account.customerCountry,
          dcp_account_sales_region: account.salesRegion,
          dcp_account_market_segment: account.marketSegment,
          dcp_account_value_category: account.customerValueCategory,

          dcp_account_id: account.name,
          dcp_document_id: props.delivery,
          dcp_document_type: get(analyticsDocumentTypes, props.source, props.source),
        })
      }
    >
      <IconWrapper $centered onClick={handleClick}>
        {!isDownloading ? <PdfIcon size={IconSize.Large} color={colors.primary.black} /> : null}
        {isDownloading && <Loader dark size={spinnerSize.sm} />}
      </IconWrapper>
    </AnalyticsEvent>
  );
};
