import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IAppState } from "src/redux";

import { ContactComponent } from "src/components/Contact/ContactComponent";

const mapStateToProps = (state: IAppState) => ({
  services: state.applicationContext.services,
  isQuantipartsDistributor: state.userInfo.isQPDistributor && !state.userInfo.isQPDistributorViewDisabled,
});

export const ContactContainer = withRouter(connect(mapStateToProps)(ContactComponent));
