import { BarRow, ChartTable } from "./GroupedDatesChart";
import { IManagerDashboardRequestItem, RequestType } from "../request/interfaces";

import { Legend } from "./Legend";
import { ManagerDashboardTableWrapper } from "./shared";
import React from "react";
import { TimelineRow } from "./TimelineRow";
import { groupRequestsByType } from "../dashboard";
import { translateString } from "src/util/localization";

const translateCategories = (categories: RequestType[]) => {
  const categoryTranslationMap: { [key in RequestType]: string } = {
    CSC: translateString("customerSupportTicket"),
    "Technical Support": translateString("techRequest"),
    "Spare Part Claim": translateString("sparePartClaim"),
    "Warranty Claim": translateString("warrantyClaim"),
  };
  return categories.map((category) => categoryTranslationMap[category] || category);
};

export const RequestsChart = (props: {
  end: string;
  start: string;
  submittedRequests: IManagerDashboardRequestItem[];
}) => {
  const requestsByType = groupRequestsByType(props.submittedRequests);
  const categories = Array.from(
    new Set<RequestType>(requestsByType.flatMap((request) => request.items.map((item) => item.requestType)))
  );

  return (
    <ManagerDashboardTableWrapper>
      <Legend categories={translateCategories(categories)} />
      <ChartTable>
        <tbody>
          <BarRow
            categories={categories}
            data={requestsByType.map((date) => ({
              date: date.date,
              items: date.items.map((item) => ({ category: item.requestType, count: item.count })),
            }))}
            timeframeStart={props.start}
            timeframeEnd={props.end}
          />
          <TimelineRow start={new Date(props.start)} end={new Date(props.end)} />
        </tbody>
      </ChartTable>
    </ManagerDashboardTableWrapper>
  );
};
