import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";
import React from "react";
import { GroupedDates } from "../dashboard";
import { defaultLegendColors } from "./Legend";
import { getXPosition } from "./shared";
import { fixedFontSizes } from "src/design-system/Tokens/typography";

// Max height in ems
const scaleHeight = 10;

export const ChartTable = styled.table`
  position: relative;
  border-collapse: collapse;
  width: 100%;
`;

const BarContainer = styled.div`
  position: absolute;
  width: 1em;
  height: ${scaleHeight}em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

interface IBarProps {
  color: string;
  height: string;
}

const Bar = styled.div<IBarProps>`
  width: 0.7em;
  height: ${(props) => props.height};
  background: ${(props) => props.color};
`;

const ScaleWrapper = styled.td`
  position: relative;
  width: 3em;
`;

const ScaleNumber = styled.div`
  position: absolute;
  width: 100%;
  text-align: right;
  left: -0.5em;
  font-size: ${fixedFontSizes.baseFontSizeSmall};
`;

const UnitLabel = styled.div`
  position: absolute;
  transform: rotateZ(-90deg);
  left: -25.5em;
  width: 50em;
  text-align: center;
  font-size: ${fixedFontSizes.baseFontSizeSmall};
`;

const Scale = (props: { scalePoints: number[]; postfix?: string; unitLabel?: string }) => {
  const [maxValue] = props.scalePoints;
  // Move numbers .5em up
  const adjust = -0.25;
  return (
    <ScaleWrapper>
      {props.unitLabel && <UnitLabel>{props.unitLabel}</UnitLabel>}
      {props.scalePoints.map((y) => (
        <ScaleNumber key={y} style={{ top: `${((maxValue - y) * scaleHeight) / maxValue + adjust}rem` }}>
          {y}
          {props.postfix}
        </ScaleNumber>
      ))}
    </ScaleWrapper>
  );
};

const ScaleLine = styled.div`
  position: absolute;
  border-top: 1px solid ${colors.transparent.bluegray};
  height: 1px;
  width: 100%;
`;

const Chart = styled.td`
  height: ${scaleHeight}em;
  overflow: visible;
  position: relative;
  vertical-align: top;
  border-left: ${colors.primary.white} solid 1px;
`;

const BarWrapper = styled.div`
  position: relative;
`;

const getScalePoints = (maxValue: number) => {
  const numbers: number[] = [];
  const granularity = maxValue > 50 ? 100 : 20;
  const roundedUp = Math.ceil(maxValue / granularity) * granularity;
  const steps = 4;
  const step = Math.ceil(roundedUp / steps);

  for (let n = 0; n <= roundedUp; n += step) {
    numbers.push(n);
  }

  return numbers.reverse();
};

export const BarRow = (props: {
  categories: string[];
  data: GroupedDates<Array<{ category: string; count: number }>>;
  timeframeStart: string;
  timeframeEnd: string;
  postfix?: string;
  unitLabel?: string;
}) => {
  // Max total count per day
  const maxValueForScale = props.data.reduce(
    (prev, curr) =>
      Math.max(
        prev,
        curr.items.reduce((prevCount, currItem) => prevCount + currItem.count, 0)
      ),
    // To avoid divide-by-zero below
    1
  );
  const scalePoints = getScalePoints(maxValueForScale);
  const [maxValue] = scalePoints;

  return (
    <tr>
      <Scale scalePoints={scalePoints} postfix={props.postfix} unitLabel={props.unitLabel} />
      <Chart>
        {scalePoints
          .filter((y) => y > 0)
          .map((y) => (
            <ScaleLine key={y} style={{ top: `${((maxValue - y) * scaleHeight) / maxValue}em` }} />
          ))}
        <BarWrapper>
          {props.data.map((date) => (
            <BarContainer
              key={date.date}
              style={{ left: `${getXPosition(date.date, props.timeframeStart, props.timeframeEnd) * 100}%` }}
            >
              {date.items.reverse().map((item) => (
                <Bar
                  key={item.category}
                  color={defaultLegendColors[props.categories.indexOf(item.category) % defaultLegendColors.length]}
                  height={`${(item.count * scaleHeight) / maxValue}em`}
                />
              ))}
            </BarContainer>
          ))}
        </BarWrapper>
      </Chart>
    </tr>
  );
};
