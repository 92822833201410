import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { APIFetchStatus } from "src/APIFetch";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";
import {
  EnergyPortfolioViewComponent,
  IEnergyPortfolioViewDispatchProps,
  IEnergyPortfolioViewStateProps,
} from "./EnergyPortfolioViewComponent";

const mapStateToProps = (state: IAppState): IEnergyPortfolioViewStateProps => {
  return {
    services: state.applicationContext.services,
    news: state.newsnew.data || [],
    energyDocumentTypes: state.energyDocumentTypes.data || [],
    isReady: state.energyDocumentTypes.status === APIFetchStatus.Success,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IEnergyPortfolioViewDispatchProps =>
  bindActionCreators(
    {
      getEnergyDocumentTypes: APIResourceState.energyDocumentTypes.get,
    },
    dispatch
  );

export const EnergyPortfolioViewContainer = connect<
  IEnergyPortfolioViewStateProps,
  IEnergyPortfolioViewDispatchProps,
  {}
>(
  mapStateToProps,
  mapDispatchToProps
)(EnergyPortfolioViewComponent);
export default EnergyPortfolioViewContainer;
