import { ICombinedRequest, ITechRequest } from "online-services-types";
import * as React from "react";
import { LocalizedString } from "src/components/Localization";
import colors from "src/design-system/Tokens/colors";
import { Disclaimer } from "src/design-system/Tokens/typography";
import { ICombinedRequestViewModel } from "src/models/warrantyClaim";
import { KeyValuePair, KeyValueValue } from "src/design-system/Table/Table";
import styled from "styled-components";
import { ApprovalDialog } from "../Approval";
import { IApprovalFormFields } from "src/components/SupportList/Approval/ApprovalComponent";
import { IApprovalData } from "../Approval/rating";
import { RatingComponent } from "../Approval/RatingComponent";
import { Rating } from "../Approval/ratingNames";
import { IReopenFormFields } from "../ReopenDialog/ReopenDialogComponent";

export interface IRatingButtonsProps {
  item: ICombinedRequestViewModel;
  approveRequest(request: ICombinedRequest, data: IApprovalData): void;
  reopenRequest(request: ICombinedRequest, data: IReopenFormFields): void;
}

const SolutionWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: wrap;
`;

interface IRatingButtonsState {
  isApprovalDialogOpen: boolean;
  isReopenDialogOpen: boolean;
  selectedRating: Rating | null;
}

const ButtonsWrapper = styled.div``;

const SolutionText = styled.div`
  flex: 1 0 auto;
`;

const ApproveRow = styled.div`
  border-top: 1px solid ${colors.secondary.gray10};
  padding-top: 1em;
  margin-top: 1em;
  flex-wrap: wrap;
  display: flex;
`;

const ApproveText = styled.div`
  width: 200px;
`;

const RatingWrapper = styled.div`
  flex: 1;
  margin-bottom: 10px;
`;

export class RatingButtonsComponent extends React.Component<IRatingButtonsProps, IRatingButtonsState> {
  constructor(props: IRatingButtonsProps) {
    super(props);
    this.state = {
      isApprovalDialogOpen: false,
      isReopenDialogOpen: false,
      selectedRating: null,
    };
  }

  public displayApprovalDialog = (rating: Rating) => {
    this.setState({ isApprovalDialogOpen: true, selectedRating: rating });
  };

  public hideApprovalDialog = () => {
    this.setState({ isApprovalDialogOpen: false });
  };

  public handleApprove = (data: IApprovalFormFields) => {
    this.props.approveRequest(this.props.item, { ...data, rating: this.state.selectedRating });
    this.hideApprovalDialog();
  };

  public handleRating = (rating: Rating) => {
    this.displayApprovalDialog(rating);
  };

  public render() {
    const solutionText = (this.props.item as ITechRequest).solutionText;
    const reopenAllowed = (this.props.item as ITechRequest).reopenAllowed || false;
    return (
      <ButtonsWrapper>
        <SolutionWrapper>
          <SolutionText>
            <KeyValuePair>
              <Disclaimer>
                <LocalizedString id="request.solution" />
              </Disclaimer>
              <KeyValueValue dangerouslySetInnerHTML={{ __html: solutionText }} />
            </KeyValuePair>
          </SolutionText>
        </SolutionWrapper>
        <ApproveRow>
          <ApproveText>
            <LocalizedString id="request.rating.approveLabel" />
          </ApproveText>
          <RatingWrapper>
            <RatingComponent
              onChange={this.handleRating}
              item={this.props.item}
              reopenRequest={this.props.reopenRequest}
              displayReopenButton={reopenAllowed}
            />
          </RatingWrapper>
        </ApproveRow>
        {this.state.selectedRating && (
          <ApprovalDialog
            isOpen={this.state.isApprovalDialogOpen}
            onApprove={this.handleApprove}
            onCancel={this.hideApprovalDialog}
            selectedRating={this.state.selectedRating}
          />
        )}
      </ButtonsWrapper>
    );
  }
}
