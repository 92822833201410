import { combineReducers, ReducersMapObject } from "redux";
import { availabilityLimitReducer } from "src/redux/availabilityLimit";
import { partsAvailabilityReducer } from "src/redux/partsAvailability";
import { runningHoursReducer } from "src/redux/runningHours";
import { injectAPIReducers } from "./api";
import { applicationContextReducer } from "./applicationContext";
import { authReducer } from "./auth";
import { basketReducer } from "./basket";
import { headerReducer } from "./header";
import { onboardingReducer } from "./onboarding";
import { userReducer } from "./user";
import { languageReducer } from "./localization";

const reducers: ReducersMapObject = {
  userInfo: userReducer,
  auth: authReducer,
  basket: basketReducer,
  applicationContext: applicationContextReducer,
  runningHours: runningHoursReducer,
  onboarding: onboardingReducer,
  partsAvailability: partsAvailabilityReducer,
  availabilityLimit: availabilityLimitReducer,
  header: headerReducer,
  language: languageReducer,
};

export default combineReducers(injectAPIReducers(reducers));
