import React, { createContext, useCallback, useEffect, useState } from "react";
import { APIFetch } from "src/APIFetch";
import { getLastSeenVersion, setLastSeenVersion } from "src/util/localstorage";
import { IReleaseNote } from "src/views/ReleaseNotesView/ReleaseNote";

export interface IVersionContext {
  releaseNotes: IReleaseNote[];
  isReady: boolean;
  isSidebarOpen: boolean;
  hasNewReleaseNotes: boolean;
  setIsSidebarOpen(state: boolean): void;
  markVersionSeen(): void;
}

export const VersionContext = createContext<IVersionContext>({
  releaseNotes: [],
  isReady: false,
  setIsSidebarOpen: () => 0,
  isSidebarOpen: false,
  markVersionSeen: () => {},
  hasNewReleaseNotes: false,
});

export const VersionContextWrapper = (props: { children: React.ReactChild }) => {
  const [releaseNotes, setReleaseNotes] = useState<IReleaseNote[]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [hasNewReleaseNotes, setHasNewReleaseNotes] = useState(false);

  useEffect(() => {
    async function fetchReleaseNotes() {
      const notes = await new APIFetch<IReleaseNote[]>("service/release-notes").get();
      setReleaseNotes(notes);
      setIsReady(true);
      setHasNewReleaseNotes(notes.length > 0 && notes[0].releaseVersion !== getLastSeenVersion());
    }

    fetchReleaseNotes();
  }, []);

  const markVersionSeen = useCallback(() => {
    if (releaseNotes[0]) {
      setLastSeenVersion(releaseNotes[0].releaseVersion);
    }
    setHasNewReleaseNotes(false);
  }, [releaseNotes]);

  return (
    <VersionContext.Provider
      value={{ releaseNotes, hasNewReleaseNotes, isReady, setIsSidebarOpen, isSidebarOpen, markVersionSeen }}
    >
      {props.children}
    </VersionContext.Provider>
  );
};
