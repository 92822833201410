import * as React from "react";
import { DocumentDownloadComponent } from "src/components/DownloadDocument";
import colors from "src/design-system/Tokens/colors";

import { IQueuedFile, IUploadedFile, QueueStatus } from "./AttachmentUploaderComponent";
import { IconSize, RemoveIcon, WarningIcon } from "src/icons";
import { Loader, spinnerSize } from "src/components/LoadingSpinner/LoadingSpinner";

import { FlexContainer } from "src/design-system/Container";
import styled from "styled-components";
import { translateString } from "src/util/localization";

interface IFilesListProps {
  isQueue?: boolean;
  iconColor?: string;
  hideRemoveLink?: boolean;
  files: Array<IQueuedFile | IUploadedFile>;
  allowDownload?: boolean;
  onRemove(file: IQueuedFile | IUploadedFile): void;
}

const IconButton = styled(FlexContainer)`
  cursor: pointer;
`;

const FileRow = styled.span`
  display: inline-flex;
`;

const FileName = styled.span`
  color: ${colors.primary.blue};
`;

const isUploadedFile = (file: IQueuedFile | IUploadedFile): file is IUploadedFile =>
  (file as IUploadedFile).id !== undefined;

export const FilesList = ({ files, hideRemoveLink, iconColor, allowDownload, onRemove, isQueue }: IFilesListProps) => {
  return (
    <FlexContainer $column $margin={files.length ? [2, 0, 1, 0] : 0}>
      {files.map((file, index) => {
        const filename = `${file.name} ${file.description ? `(${file.description})` : ""}`;
        const removeIcon = !hideRemoveLink ? (
          <IconButton
            $marginRight={2}
            onClick={(event) => {
              event.preventDefault();
              onRemove(file);
            }}
          >
            <RemoveIcon viewBox="0 0 26 26" color={iconColor} size={IconSize.Small} />
          </IconButton>
        ) : null;

        if (file.status === QueueStatus.Failed) {
          return (
            <FlexContainer $paddingBottom={1} $gap={1} key={index} $centered>
              {filename} {removeIcon} <WarningIcon size={IconSize.Small} />{" "}
              <span>{translateString("sendingFileFailed")}</span>{" "}
            </FlexContainer>
          );
        }

        if (isQueue && file.status === QueueStatus.Success) {
          return null;
        }

        if (file.status === QueueStatus.Scanning || file.status === QueueStatus.Uploading) {
          return (
            <FlexContainer $paddingBottom={1} $gap={1} key={index} $centered>
              {filename} {removeIcon} <Loader dark size={spinnerSize.sm} />
            </FlexContainer>
          );
        }

        return (
          <FlexContainer $paddingBottom={1} $gap={1} key={index} $centered>
            {allowDownload && isUploadedFile(file) ? (
              <FileRow>
                <DocumentDownloadComponent fileId={file.id}>
                  <FileName>{filename}</FileName>
                </DocumentDownloadComponent>{" "}
                {removeIcon}
              </FileRow>
            ) : (
              <FileRow>
                {filename} {removeIcon}
              </FileRow>
            )}
          </FlexContainer>
        );
      })}
    </FlexContainer>
  );
};
