import * as React from "react";

import { ArrowLeftIcon, FlagIcon, IconSize, UserIcon } from "src/icons";
import { Container, FlexContainer } from "src/design-system/Container";
import { H1, H2Styles, smallTextStyles } from "src/design-system/Tokens/typography";
import { IEquipment, INotification, IServiceAnnouncement, IUserInfo } from "online-services-types";
import { IRoute, getHeaderNavigationInfo, getPathWithParams, getRoutes, redirectBrowser } from "src/routes";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";

import { AccountSelector } from "src/components/AccountSelector";
import { ButtonLink } from "src/design-system/Button/ButtonLink";
import { ChatButton } from "src/services/atrs/atrs.components";
import { PunchOutNotification } from "src/components/Header/PunchOutNotification";
import { QuantiPartsLogo } from "src/images/QuantiPartsLogo";
import { ReadOnlyNotification } from "src/components/Header/ReadOnlyNotification";
import { ServiceUnavailableNotification } from "src/components/Header/ServiceUnavailableNotification";
import { ThemeSelectorComponent } from "src/components/ThemeSelector/ThemeSelectorComponent";
import { ThemedProps } from "src/design-system/Theme/theme";
import { WOHasBeenUpdatedNotification } from "src/views/ReleaseNotesView/WOHasBeenUpdatedNotification";
import { WOViewSelector } from "src/components/WOViewSelector";
import { WartsilaLogo } from "src/images/WartsilaLogo";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { capitalizeAllWords } from "src/util/formatters";
import colors from "src/design-system/Tokens/colors";
import styled from "styled-components";
import { switchOpenSearch } from "src/util/localstorage";
import { translateString } from "src/util/localization";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4%;
  min-height: 50px;
  padding-top: 20px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0 4% 0 0;
    padding-top: 0;
  }
`;

export const Logo = styled.span`
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    display: block;
    align-self: flex-start;
    background-color: ${colors.primary.white};
    padding: 0 10px;
    margin-right: 60px;
    height: 100px;
    min-width: 150px;
    position: relative;
    ::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 100%;
      width: 0;
      height: 0;
      border-top: 100px solid ${colors.primary.white};
      border-right: 60px solid transparent;
    }
  }
`;

export const LinkHeading = styled(H1)`
  flex-grow: 1;
  margin-bottom: 0;
  text-transform: none;

  a {
    color: ${(props: ThemedProps) => props.theme.foreground};
    text-decoration: none;
    display: flex;
    align-items: center;
  }
`;

export const GoBackLink = styled(FlexContainer)`
  cursor: pointer;
`;

interface IIndicatorProps {
  showIndicator: boolean;
}

const MenuToggle = styled(ButtonLink)`
  ${H2Styles};
  line-height: 0;
`;

const IndicatorBubble = styled.span`
  position: relative;

  ${(props: IIndicatorProps) =>
    props.showIndicator &&
    `
    ::after {
      ${smallTextStyles};
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: -12px;
      right: 0;
      border-radius: 50%;
      background-color: ${colors.primary.orange};
      line-height: 1.5;
    }
  `}
`;

const DismissErrorLink = styled(FlexContainer).attrs({ $centered: true })`
  cursor: pointer;
`;

export interface IHeaderOwnProps {
  selectedInstallationName: string;
  currentUserEmail: string;
  readonly: boolean;
  punchOut: boolean;
  serviceAnnouncements: IServiceAnnouncement[];
  isLoggedIn: boolean;
  isErrorState?: boolean;
  punchOutContact: string;
  dismissError?: () => void;
  toggleMenu(): void;
  toggleNotifications(): void;
  setSelectedInstallation(): void;
}

export interface IHeaderStateProps {
  externalTitle: string;
  notifications: INotification[];
  isEnergyConsultant: boolean;
  isDesigner: boolean;
  isShipyard: boolean;
  isQuantipartsDistributor: boolean;
  userInfo: IUserInfo;
}

export interface IHeaderDispatchProps {
  setUserInfo(userInfo?: IUserInfo): void;
}

type IHeaderProps = IHeaderOwnProps & IHeaderStateProps & IHeaderDispatchProps;

const headerText = (consultant: boolean, designer: boolean, shipyard: boolean) => {
  if (consultant) {
    return "energy.wartsilaEnergyProductPortfolio";
  }
  if (designer) {
    return "energy.wartsilaMarineProductPortfolio";
  }
  if (shipyard) {
    return "project.myProjects";
  }
  return "myFleet";
};

export const HeaderComponent: React.FC<IHeaderProps & RouteComponentProps> = (props) => {
  const history = useHistory();
  const { pathname, state: locationState } = useLocation<{ equipment: IEquipment }>();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    props.toggleMenu();
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    props.toggleNotifications();
  };

  const navigationInfo = getHeaderNavigationInfo(pathname);
  let routeName = navigationInfo?.routeName ?? "";
  const routes = getRoutes();

  const isOfflineFeedbackPage = pathname.startsWith("/offline-feedback");

  if (navigationInfo?.useExternalHeaderTitle) {
    routeName = props.externalTitle;
  } else {
    // TODO: make a helper functions out of this and move it somewhere else
    // get the equipment type from the url
    if (pathname.includes("/equipments/")) {
      const equipmentTypeRaw = pathname.split("/equipments/")[1].split("/")[0];
      const equipmentType = equipmentTypeRaw.split("-").join(" ");
      routeName = props.selectedInstallationName + `: ${equipmentType}`;
    }

    if (pathname.startsWith("/loading")) {
      const loadingInfoRaw = pathname.substr(1).split("/")[1];
      // converts from format 'order-being-processed' to format 'orderBeingProcessed' and 'quotation-being-processed' to 'quotationBeingProcessed'
      let localizationKey = loadingInfoRaw
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join("");
      localizationKey = localizationKey.charAt(0).toLowerCase() + localizationKey.substring(1);
      routeName = translateString(localizationKey);
    }

    if (pathname.startsWith("/commerce/quote-received") || pathname.startsWith("/commerce/order-received")) {
      routeName = `${props.selectedInstallationName}: ${routeName}`;
    }

    // when entereing these paths from another than main route then set it as parentroute.
    if (
      locationState?.equipment &&
      (pathname.startsWith("/requests/new-request") ||
        pathname.startsWith("/commerce/catalogue") ||
        pathname.startsWith("/documents") ||
        pathname.startsWith("/spareparts"))
    ) {
      const eqType = locationState?.equipment.productCategory.toLowerCase();
      const path = getPathWithParams(routes.Equipments, { equipmentType: eqType });
      const differentParent: IRoute | null = getHeaderNavigationInfo(path);
      if (navigationInfo && differentParent) {
        navigationInfo.parentRoute = differentParent;
        navigationInfo.parentRoute.path = path;
      }
    }

    if (pathname.startsWith("/commerce/catalogue")) {
      routeName = `${props.selectedInstallationName || `${translateString("installation")}`}: ${routeName}`;
    }
    if (pathname.startsWith(`/${translateString("installation").toLowerCase()}-details`)) {
      routeName = `${props.selectedInstallationName}: ${translateString("installation")} Details`;
    } else if (pathname.startsWith("/installation") && !pathname.startsWith("/installations")) {
      routeName = `${props.selectedInstallationName}`;
    }
    if (pathname === "/installations") {
      routeName = translateString("routes.installations");
    }
    if (pathname.startsWith("/energy-documents") && !pathname.endsWith("/energy-documents")) {
      routeName = translateString("energy.wartsilaEnergyProductPortfolio");
    }
  }

  const isMobile = window.screen.width < breakpoints.mobileLarge;
  let showNotifications = true;
  let showThemeSwitch = true;
  switchOpenSearch("true");
  if (props.isEnergyConsultant) {
    showNotifications = false;
  }
  if (props.userInfo?.isQPDistributor) {
    showNotifications = false;
    showThemeSwitch = false;
    if (props.userInfo?.isQPDistributorViewDisabled) {
      showNotifications = true;
    }
  }
  if (props.isDesigner) {
    showNotifications = false;
  }

  const logo = props.isQuantipartsDistributor ? (
    <Logo>
      <QuantiPartsLogo />
    </Logo>
  ) : (
    <Logo>
      <WartsilaLogo isLinkDisabled={isOfflineFeedbackPage} />
    </Logo>
  );

  return (
    <>
      <ServiceUnavailableNotification serviceAnnouncements={props.serviceAnnouncements} />
      <div>
        <ReadOnlyNotification mockedUser={props.readonly ? props.currentUserEmail : null} />
        <PunchOutNotification mockedUser={props.punchOut ? props.punchOutContact : null} />
        <WOHasBeenUpdatedNotification />
        <Wrapper>
          {logo}
          {props.isLoggedIn && !(navigationInfo && navigationInfo.useExternalHeaderTitle) ? (
            <>
              <LinkHeading className={"main-onboarding-start technical-documentation-onboarding-your-documents"}>
                {props.isErrorState && (
                  <DismissErrorLink
                    onClick={() => {
                      if (props.dismissError !== undefined) {
                        props.dismissError();
                      }
                      redirectBrowser(routes.Index);
                    }}
                  >
                    <ArrowLeftIcon size={IconSize.Medium} />
                    <span>{capitalizeAllWords(translateString("error.backToHomePage"))}</span>
                  </DismissErrorLink>
                )}
                {!props.isErrorState && (!navigationInfo || !navigationInfo.parentRoute) && (
                  <FlexContainer $centered>
                    <span>
                      {capitalizeAllWords(
                        translateString(headerText(props.isEnergyConsultant, props.isDesigner, props.isShipyard))
                      )}
                    </span>
                  </FlexContainer>
                )}
                {!props.isErrorState &&
                  navigationInfo &&
                  navigationInfo.parentRoute &&
                  (navigationInfo.cantGoBack ? (
                    capitalizeAllWords(routeName, props.selectedInstallationName)
                  ) : (
                    <GoBackLink
                      $centered
                      // When the history.state exists, users navigate inside the app, and we can utilize the standard browser behaviour.
                      // If doesn't - user came from the "outside" and we need the button to use app level navigation
                      onClick={() =>
                        window.history.state
                          ? window.history.back()
                          : history.push(getPathWithParams(navigationInfo.parentRoute!))
                      }
                    >
                      <ArrowLeftIcon size={IconSize.Medium} />
                      {capitalizeAllWords(routeName, props.selectedInstallationName)}
                    </GoBackLink>
                  ))}
              </LinkHeading>
              {props.isLoggedIn && (
                <>
                  {!isMobile && (
                    <div style={{ marginRight: 20 }}>
                      <AccountSelector />
                    </div>
                  )}
                  {!props.punchOut && (
                    <div style={{ marginRight: 20 }}>
                      <WOViewSelector />
                    </div>
                  )}
                  {showThemeSwitch && !props.punchOut && (
                    <Container $margin={[0, 1]}>
                      <MenuToggle>
                        <ThemeSelectorComponent userInfo={props.userInfo} setUserInfo={props.setUserInfo} />
                      </MenuToggle>
                    </Container>
                  )}
                  {!props.punchOut && (
                    <Container $margin={[0, 1]}>
                      <MenuToggle onClick={handleMenuClick} className={"main-onboarding-your-profile"}>
                        <UserIcon size={IconSize.Medium} />
                      </MenuToggle>
                    </Container>
                  )}
                  {showNotifications && !props.punchOut && (
                    <Container $margin={[0, 1]}>
                      <MenuToggle onClick={handleNotificationsClick}>
                        <IndicatorBubble showIndicator={props.notifications.length > 0}>
                          <FlagIcon size={IconSize.Medium} />
                        </IndicatorBubble>
                      </MenuToggle>
                    </Container>
                  )}
                  <ChatButton />
                </>
              )}
            </>
          ) : (
            navigationInfo &&
            navigationInfo.alwaysDisplayHeaderTitle && (
              <LinkHeading>{capitalizeAllWords(routeName, props.selectedInstallationName)}</LinkHeading>
            )
          )}
        </Wrapper>
      </div>
    </>
  );
};
