// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const fieldServices_zh_CN: ITranslations = {
  zh_CN: {
    "fieldServices.actualFinishDate": "实际完成日",
    "fieldServices.actualStartDate": "实际起始日",
    "fieldServices.description": "工作说明",
    "fieldServices.estimatedFinishDate": "估算的完成日",
    "fieldServices.estimatedStartDate": "估算的起始日",
    "fieldServices.poNumber": "PO编号",
    "fieldServices.productReferenceType": "产品参考类型",
    "fieldServices.recommendations": "建议",
    "fieldServices.serviceWorkCoordinator": "维修工作协调员",
  },
};
