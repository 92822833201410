import * as React from "react";
import { ISalesLineItem } from "src/components/CommerceList/types";
import { IResponsiveTableColumn } from "../ResponsiveTable/interfaces";
import { ResponsiveTable } from "../ResponsiveTable";
import {
  descMetadata,
  lineNumber,
  partNumberMetadata,
  partQtyMetadata,
  partWeightMetadata,
} from "src/components/PartsCatalogueList/PartsMetadata";
import { IPurchase } from "online-services-types";

interface IResponsiveTreeProps {
  purchase: IPurchase;
  items: ISalesLineItem[];
  columns: IResponsiveTableColumn[];
}

const REGULAR_ITEM_PARENT = "000000";

export class ResponsiveTree extends React.Component<IResponsiveTreeProps> {
  public render() {
    const colmsWithoutPrice = [
      lineNumber,
      descMetadata,
      partNumberMetadata,
      partWeightMetadata(this.props.purchase.type),
      partQtyMetadata,
    ];
    const regularLineItems = this.props.items.filter((lineItem) => lineItem.parentRow === REGULAR_ITEM_PARENT);
    const bomElements = this.props.items.filter((lineItem) => lineItem.parentRow !== REGULAR_ITEM_PARENT);

    const isSublistNeeded = (item: ISalesLineItem) => {
      if (item !== undefined && item.isBomHeader) {
        return renderSubList();
      }
      return null;
    };

    const renderSubList = () => {
      return <ResponsiveTable<ISalesLineItem> columns={colmsWithoutPrice} rows={bomElements} isSubList={true} />;
    };

    return (
      <ResponsiveTable<ISalesLineItem>
        rows={regularLineItems}
        columns={this.props.columns}
        sublistRenderer={isSublistNeeded}
      />
    );
  }
}
