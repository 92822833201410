import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { APIFetchStatus } from "src/APIFetch";
import {
  IPartImagesComponentDispatchProps,
  IPartImagesComponentOwnProps,
  IPartImagesComponentStateProps,
  PartImagesComponent,
} from "src/components/PartsCatalogueList/PartImagesComponent";
import { IAppState } from "src/redux";
import { APIResourceState } from "src/redux/api";

const mapStateToProps = (state: IAppState, ownProps: IPartImagesComponentOwnProps): IPartImagesComponentStateProps => {
  return {
    isReady: state.sectionImages.status === APIFetchStatus.Success || !!ownProps.useOnboardingMockData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IPartImagesComponentDispatchProps =>
  bindActionCreators(
    {
      getImages: APIResourceState.sectionImages.getWithParams,
    },
    dispatch
  );

const PartImagesContainer = connect<
  IPartImagesComponentStateProps,
  IPartImagesComponentDispatchProps,
  IPartImagesComponentOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(PartImagesComponent);
export { PartImagesContainer as PartImages };
