import styled from "styled-components";
import colors from "src/design-system/Tokens/colors";
import * as React from "react";

interface INotificationCircleShapeProps {
  indicatorText: string | number;
  color?: string;
}

const NotificationCircleShape = styled.span`
  &::after {
    display: inline-block;
    color: ${colors.primary.white};
    content: ${(props: INotificationCircleShapeProps) => `'${props.indicatorText}'`};
    min-width: 11px;
    height: 25px;
    top: 0;
    right: 0;
    border-radius: 12.5px;
    background-color: ${(props: INotificationCircleShapeProps) => props.color || colors.primary.orange};
    line-height: 25px;
    padding: 0 7px;
    text-align: center;
    margin-left: 1em;
  }
`;

export const NotificationCircle = (props: { color?: string; count: number }) =>
  props.count !== 0 ? <NotificationCircleShape color={props.color} indicatorText={props.count} /> : null;
