import { merge } from "lodash";
import { IClaimComment } from "online-services-types";
import * as React from "react";
import { DropzoneState, FileRejection, useDropzone } from "react-dropzone";
import { deleteComment, patchComment, postComment } from "src/components/RequestComments/requestComments";
import { Button, ButtonStyle } from "src/design-system/Button";
import { ButtonType } from "src/design-system/Button/Button";
import { Container, FlexContainer } from "src/design-system/Container";
import { RequestType } from "src/models/warrantyClaim";
import { uploadFileToSalesforce } from "src/util/fileUpload";
import { translateString } from "src/util/localization";
import styled from "styled-components";
import { FilesList } from "./FilesList";
import { InvalidFileDialog } from "./InvalidFileDialog";
import { CustomErrorCode, RejectionReasons } from "./types";

const DropZoneContainer = styled(FlexContainer)<{ dragging: boolean }>`
  box-sizing: border-box;
  width: 100%;
  max-width: 700px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1.5em;
  border: 1px dashed ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.text};
  background: ${({ dragging, theme }) => dragging ? theme.list.selected : "none"};
`;

export enum QueueStatus {
  Idle,
  Uploading,
  Scanning,
  Success,
  Failed,
}

export interface IQueuedFile {
  file: File;
  name: string;
  status: QueueStatus;
  description?: string;
  uploadedFile?: IUploadedFile;
}

export interface IUploadedFile extends Omit<IQueuedFile, "file"> {
  id: string;
}

type IAttachmentUploaderRendererProps = {
  getRootProps: DropzoneState["getRootProps"];
  getInputProps: DropzoneState["getInputProps"];
  queue: IQueuedFile[];
  onRemove: (fileToRemove: IUploadedFile | IQueuedFile) => void;
};

export type IAttachmentUploaderComponentProps = {
  maxSize?: number;
  relatedId?: string;
  disabled?: boolean;
  iconColor?: string;
  requestType?: string;
  files?: IUploadedFile[];
  nonRemovable?: boolean;
  downloadAllowed?: boolean;
  contextDescription?: string;
  useAttachmentRecords?: boolean;
  filenamePrefix?: string;
  onBusy?: (busy: boolean) => void;
  onFilesChanged: (files: IUploadedFile[]) => void;
  onFileUploaded?: (file: IUploadedFile) => void;
  renderer?: (props: IAttachmentUploaderRendererProps) => React.ReactNode;
};

const allowedTypes = [
  ".7z",
  ".bmp",
  ".csv",
  ".csv",
  ".doc",
  ".docx",
  ".eml",
  ".gif",
  ".jpeg",
  ".jpg",
  ".log",
  ".mov",
  ".mp3",
  ".mp4",
  ".msg",
  ".pdf",
  ".png",
  ".rar",
  ".sopas",
  ".trendgroup",
  ".txt",
  ".unitool",
  ".uzp",
  ".wet",
  ".wt3",
  ".wxml",
  ".xlm",
  ".xls",
  ".xlsx",
  ".zip",
];

const uploadFile = async (
  file: IQueuedFile,
  onUploaded: (uploadedFile: IUploadedFile) => void,
  onError: (file: IQueuedFile) => void,
  relatedId?: string,
  isAttachmentRecord?: boolean
) => {
  const uploadedFile = await uploadFileToSalesforce(file.file, relatedId, isAttachmentRecord, file.description);

  if (uploadedFile) {
    uploadedFile.description = file.description;
    onUploaded(uploadedFile);
  } else {
    onError(file);
  }
};

/**
 * Separate logic for spare part claim attachments uploaded via the request list.
 * Spare part claim attachments cannot be related directly to the request/claim as with other types.
 * Instead, attachments have to be related to comments, which are in turn related to the spare part claim.
 *
 * Workflow (after user has clicked Add an attachment):
 *   1. Create a dummy comment (comment related to the spare part claim).
 *   2. Upload an attachment and relate it to the dummy comment.
 *   3. After upload, patch the dummy comment to have "Contains attachments" flag true in SF.
 *   4. If upload failed, delete the dummy comment.
 */
const uploadSparePartClaimFile = async (
  file: IQueuedFile,
  onUploaded: (uploadedFile: IUploadedFile) => void,
  onError: (file: IQueuedFile) => void,
  relatedId?: string
) => {
  if (relatedId) {
    const comments = await postComment(RequestType.SparePartClaim, relatedId, `Attachment added: ${file.name}`, true);
    if (comments) {
      const commentId = (comments[0] as IClaimComment).id;
      const uploadedFile = await uploadFileToSalesforce(file.file, commentId, true, file.description);

      if (uploadedFile) {
        await patchComment(RequestType.SparePartClaim, commentId);
        uploadedFile.description = file.description;
        onUploaded(uploadedFile);
      } else {
        await deleteComment(RequestType.SparePartClaim, commentId);
        onError(file);
      }
    }
  }
};

export const AttachmentUploaderComponent = (props: IAttachmentUploaderComponentProps) => {
  const [rejectionReasons, setRejectionReasons] = React.useState<RejectionReasons | null>(null);
  const [queue, setQueue] = React.useState<IQueuedFile[]>([]);

  const onDrop = React.useCallback((files: File[]) => {
    const newFiles = files.map((file) => {
      const filename = `${props.filenamePrefix || ""}${file.name}`;
      // Rename file if prefix was given
      const fileObject = filename === file.name ? file : new File([file], filename);
      return {
        file: fileObject,
        name: filename,
        status: QueueStatus.Idle,
        description: props.contextDescription,
      };
    });

    setQueue((q) => q.concat(newFiles));
  }, []);

  const onDropRejected = React.useCallback((rejections: FileRejection[]) => {
    const reasons: RejectionReasons = {};
    rejections.forEach((rejection) => {
      rejection.errors.forEach((error) => {
        reasons[error.code] = true;
      });
    });
    setRejectionReasons(reasons);
  }, []);

  const handleUploadError = (file: IQueuedFile) => {
    setQueue((q) =>
      q.map((qf) => {
        if (qf.name !== file.name) return qf;
        return { ...qf, status: QueueStatus.Failed };
      })
    );
  };

  const handleFileUploaded = (uploadedFile: IUploadedFile) => {
    setQueue((queue) => {
      return queue.map((qf) => {
        if (qf.name !== uploadedFile.name) return qf;
        return { ...qf, status: QueueStatus.Success, uploadedFile };
      });
    });
    props.onFileUploaded?.(uploadedFile);
  };

  React.useEffect(() => {
    const newUploadedFilesQueue = queue.filter((file) => file.status === QueueStatus.Success);

    const newUploadedFiles = newUploadedFilesQueue
      .map((file) => file.uploadedFile)
      .filter((file) => Boolean(file)) as IUploadedFile[];

    if (newUploadedFiles.length > 0) {
      props.onFilesChanged(newUploadedFiles);
    }
  }, [queue]);

  React.useEffect(() => {
    const queueCopy = queue.map((file) => merge({}, file));
    const idleFile = queueCopy.find((file) => file.status === QueueStatus.Idle);

    if (idleFile) {
      idleFile.status = QueueStatus.Uploading;

      setQueue(queueCopy);

      if (props.requestType === RequestType.SparePartClaim) {
        uploadSparePartClaimFile(idleFile, handleFileUploaded, handleUploadError, props.relatedId);
        return;
      }

      uploadFile(idleFile, handleFileUploaded, handleUploadError, props.relatedId, props.useAttachmentRecords);
    }
  }, [queue]);

  React.useEffect(() => {
    const idle = queue.some((file) => file.status === QueueStatus.Idle);
    const scanning = queue.some((file) => file.status === QueueStatus.Scanning);
    const uploading = queue.some((file) => file.status === QueueStatus.Uploading);
    props.onBusy?.(idle || scanning || uploading);
  }, [queue]);

  const duplicateNameValidator = (file: File) => {
    const hasDuplicate = queue.some((qf) => qf.name === file.name);
    if (hasDuplicate) {
      return {
        code: CustomErrorCode.DuplicateName,
        message: "Filename must be unique",
      };
    }

    return null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    maxSize: props.maxSize,
    disabled: props.disabled,
    accept: { "*": allowedTypes },
    validator: duplicateNameValidator,
  });

  const onRemove = (fileToRemove: IUploadedFile | IQueuedFile) => {
    const files = props.files?.filter((file) => file.name !== fileToRemove.name);

    if (files) props.onFilesChanged(files);
    setQueue((q) => q.filter((qf) => qf.name !== fileToRemove.name));
  };

  return (
    <>
      <InvalidFileDialog
        rejectionReasons={rejectionReasons}
        onCancel={() => setRejectionReasons(null)}
        maxSize={props.maxSize}
        allowedTypes={allowedTypes.join(", ")}
      />

      {props.renderer ? props.renderer({ getRootProps, getInputProps, queue, onRemove }) : (
        <Container $fullwidth>
          <FilesList isQueue iconColor={props.iconColor} files={queue} onRemove={onRemove} />

          {props.files ? (
            <FilesList
              files={props.files}
              onRemove={onRemove}
              iconColor={props.iconColor}
              hideRemoveLink={props.nonRemovable}
              allowDownload={props.downloadAllowed}
            />
          ) : null}

          {props.disabled ? null : (
            <DropZoneContainer {...getRootProps()} dragging={isDragActive}>
              <input {...getInputProps()} />
              <div>{translateString("attachments.drop")}</div>
              <Button buttonStyle={ButtonStyle.Transparent} buttonType={ButtonType.Outline}>
                {translateString("attachments.browse")}
              </Button>
            </DropZoneContainer>
          )}
        </Container>
      )}
    </>
  );
};